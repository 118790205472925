import React, { Component, createRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { injectIntl } from 'react-intl'
import { FormContext } from 'components/form/controller'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import isEqual from 'react-fast-compare'
import { Toggler } from './s'
import { Field, Label, Control, Error } from 'components/form/field/s'
import DisplaySymbol from 'components/symbol'
import SymbolPicker from 'modals/symbol-picker'

const empty = null

class EditSymbol extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const {
            name,
            field = {},
            forwardedRef
        } = props

        let {
            value,
            required = false,
            include = 'touched'
        } = field

        if(!value) {
            value = empty
        }

        this.state = {
            value,
            picking: false
        }

        context.registerField(name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                value: empty,
                picking: false
            }),

            validator: value => required ?
                !!value :
                true
        })

        this.field = createRef()
        this.control = createRef()

        this.combinedControlRef = mergeRefs([
            this.control,
            forwardedRef
        ])
    }

    componentDidMount() {
        this.context.triggerChange(this.props.name, { touched: false })
    }

    componentDidUpdate = ({ forwardedRef, name }, { value }) => {
        const forwardedRefChanged = forwardedRef !== this.props.forwardedRef
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)

        if(forwardedRefChanged) {
            this.combinedControlRef = mergeRefs([
                this.control,
                this.props.forwardedRef
            ])
        }

        if(nameChanged) {
            this.context.unregisterField(name)
            this.register()
        }

        if(valueChanged) {
            this.context.triggerChange(this.props.name)
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name)
    }

    openPicker = () => this.setState({ picking: true })
    closePicker = () => this.setState({ picking: false })
    unset = () => this.onPicked(empty)

    onPicked = value => {
        this.setState({ value })

        const { onChange, name } = this.props
        onChange?.({ [name]: value })

        this.context.triggerChange(name)
    }

    render() {
        const {
            value = empty,
            picking
        } = this.state

        const {
            className,
            controlProps = {},
            symbolProps = {},
            label,
            salt,
            name,
            enabled = true,
            loading = false,
            field = {},
            picker = {},
            intl: { formatMessage },
            error: outsideError,
            ...props
        } = this.props

        const touched = this.context.touched.includes(name)
        const error = ((name in this.context.errors) && touched) || !!outsideError

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const id = `${salt}:${name}`

        const {
            required,
            softRequired,
            optional,
            unsettable = true
        } = field

        return (
            <Field
                {...(fieldClassName ? { className: fieldClassName } : null)}
                ref={this.field}>
                {!!label && (
                    <Label
                        htmlFor={id}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    {(!!value?.emoji && !!value?.colorToken) && (
                        <>
                            <input
                                name={`${name}[emoji]`}
                                defaultValue={value?.emoji}
                                type="hidden"
                                key={`${id}:input:emoji:${value?.emoji ?? 'empty'}`} />
                            <input
                                name={`${name}[colorToken]`}
                                defaultValue={value?.colorToken}
                                type="hidden"
                                key={`${id}:input:color-token:${value?.colorToken ?? 'empty'}`} />
                        </>
                    )}
                    {(!value?.emoji || !value?.colorToken) && (
                        <input
                            name={name}
                            defaultValue=""
                            type="hidden"
                            key={`${id}:input:empty`} />
                    )}
                    <Toggler
                        {...controlProps}
                        {...omit(props, 'field')}
                        id={id}
                        onClick={this.openPicker}
                        active={picking}
                        unset={(!!value && unsettable) && this.unset}
                        unsettable={unsettable}
                        disabled={!enabled}
                        ref={this.combinedControlRef}>
                        {!!value && (
                            <DisplaySymbol
                                symbol={value}
                                {...symbolProps} />
                        )}
                    </Toggler>
                    <SymbolPicker
                        show={picking}
                        salt={id}
                        label={label}
                        symbol={value}
                        {...picker}
                        dismiss={this.closePicker}
                        cancelAction={() => ({
                            label: formatMessage({
                                id: 'action_cancel',
                                defaultMessage: 'Cancel'
                            }),
                            effect: 'neutral',
                            onClick: this.closePicker
                        })}
                        doneAction={({ picked }) => ({
                            label: formatMessage({
                                id: 'action_done',
                                defaultMessage: 'Done'
                            }),
                            effect: 'constructive',
                            onClick: () => this.onPicked(picked)
                        })}
                        key={`${id}:picker:${value}`} />
                    <Error animate={outsideError ? 'reveal' : 'hide'}>
                        {outsideError}
                    </Error>
                </Control>
            </Field>
        )
    }
}

export default injectIntl(EditSymbol)

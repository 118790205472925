import styled, { css } from 'styled-components'
import {
    interpointDivider, svgStroke, flashKeyframes,
    fromPhone, belowPhone
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { Naked, Plain } from 'components/button'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import { captionSmallCss } from 'components/typography/caption'
import ExpiryStatusBase from 'components/expiry-status'
import { InlineMessage as InlineMessageBase } from 'components/message'
import NoteBase from 'components/note'

export const RecordWrapper = styled(Grid)`
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) 40px;
    align-items: start;
    row-gap: 8px;
    column-gap: 24px;

    width: 100%;
    padding-block: 24px;

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }

    &:first-child {
        margin-block-start: -24px;
    }

    &:not(:last-child) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    ${belowPhone`
        grid-template-columns: 1fr 40px;
        grid-auto-flow: row;
        grid-auto-rows: auto;
        row-gap: 8px;
    `}
`

export const CompetenceCell = styled.div`
    text-align: start;
`

export const NameDetails = styled.div`
    display: grid;
    grid-template-rows: 40px repeat(var(--sub-headings), 20px);
    align-items: center;
    justify-items: start;

    margin-block:
        calc(var(--offset-top) * 12px);
        calc(var(--sub-headings) * -12px);
`

const competenceCellCss = css`
    display: grid;
    grid-template-columns: 40px minmax(0, 1fr);
    align-items: center;
    column-gap: 16px;
`

export const CompetenceCellClickable = styled(Naked)`
    ${competenceCellCss}
`

export const CompetenceCellWrapper = styled.div`
    ${competenceCellCss}
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(CompetenceCellClickable)}
`

export const Name = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    margin-block-start: calc(var(--sub-headings) * -20px);

    line-height: 24px;

    ${CompetenceCellClickable}:where(:hover, :focus) & {
        text-decoration: underline;
    }
`

export const SubHeading = styled.h4`
    ${captionSmallCss}
    ${interpointDivider()}
    overflow: hidden;

    margin-block-start: calc(var(--sub-headings) * -20px - 12px);
    max-width: 100%;
    padding-inline-start: 56px;

    &:empty {
        display: none;
    }
`

export const AddLevelButton = styled(Plain)`
    margin-block-start: calc(var(--sub-headings) * -20px - 12px);
    margin-inline-start: 56px;
    height: auto;

    font-size: 14px;
    line-height: 18px;
`

const cellCss = css`
    display: grid;
    align-items: start;
    padding: 8px 0;
`

export const TypeCell = styled.div`
    ${cellCss}

    color: var(--huma-color-foreground-subtle);

    ${fromPhone`
        line-height: 24px;
    `}

    ${belowPhone`
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        grid-template-columns: auto 1fr;

        padding-inline-start: 56px;
    `}
`

export const StatusCell = styled.div`
    ${cellCss}

    & > :last-child {
        margin-bottom: 0;
    }

    ${belowPhone`
        grid-row: 2;
        grid-column: 1 / 2;

        padding-inline-start: 56px;

        &:empty {
            display: none;
        }
    `}
`

export const ExpiryStatus = styled(ExpiryStatusBase)`
    & p {
        line-height: 20px;
    }
`

export const InlineMessage = styled(InlineMessageBase)`
    & p {
        line-height: 20px;
    }
`

export const ActionsCell = styled.div`
    justify-content: center;

    ${belowPhone`
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    `}

    svg {
        ${svgStroke()}
    }
`

export const Note = styled(NoteBase)`
    margin-block-end: calc(var(--sub-headings) * 12px);
    margin-inline-start: 56px;
`
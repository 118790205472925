import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

const Byline = styled.p`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin: 0 0 24px;
`

export default ({ name, ...props }) => (
    <Byline {...props}>
        <FormattedMessage
            id="byline"
            defaultMessage="By {name}"
            values={{ name }} />
    </Byline>
)

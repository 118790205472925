import React, { Component, createRef } from 'react'
import { useIntl } from 'react-intl'
import { useForm } from 'components/form/controller'
import { mergeRefs } from 'react-merge-refs'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { capitalize } from 'utilities/string'
import isEqual from 'react-fast-compare'
import { Field, Label, Control, Placeholder, ValueDisplay, Emoji, Symbol } from 'components/form/field/s'
import DefaultToggler, { WithActions } from 'components/form/input/toggler'
import EntityPicker from 'modals/entity-picker'
import { symbol as equipmentSymbolFallback } from 'pages/equipment'
import { Type as CustomFieldType } from 'pages/settings/pages/custom-profile-fields/components/type'
import { Type as CompetenceType } from 'pages/competence/components/type'
import AbsenceType from 'pages/absence/components/type'
import { SmartTemplate } from 'pages/documents/components/smart-template'

const empty = null

class EditEntity extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this.valueStateFromProps(props),
            picking: false
        }

        this.register()

        this.control = createRef()
        this.combinedControlRef = mergeRefs([
            this.control,
            props.forwardedRef
        ])
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ forwardedRef, name, field, whistle }, { value }) => {
        const forwardedRefChanged = forwardedRef !== this.props.forwardedRef
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(forwardedRefChanged) {
            this.combinedControlRef = mergeRefs([
                this.control,
                this.props.forwardedRef
            ])
        }

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                value: empty,
                picking: false
            }),

            validator: value => required ?
                !!value :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value || empty
    })

    openPicker = () => {
        this.setState({ picking: true })
        this.props.onToggle?.()
    }

    closePicker = () => {
        this.setState({ picking: false })
        this.props.onToggle?.()
    }

    unset = () => this.onPicked(empty)

    onPicked = value => {
        this.setState({ value })
        this.props.onChange?.({ [this.props.name]: value })
    }

    render() {
        const {
            value = {},
            picking
        } = this.state

        const {
            className,
            controlProps = {},
            toggler = {},
            picker = {},
            entity = {},
            label,
            salt,
            name,
            enabled = true,
            loading = false,
            field = {},
            formatMessage,
            ...props
        } = this.props

        const { mode: togglerMode = 'default' } = toggler

        const {
            heading,
            outer = true,
            creatable = false
        } = picker

        const {
            type,
            path,
            params = {},
            filter = null,
            mapper,
            showIcon = true
        } = entity

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const togglerClassName = cls([
            controlProps.className,
            ['customFieldType', 'competenceType', 'absenceType', 'equipmentType', /*'equipmentTypeVariant', */'smartTemplate'].includes(type) && 'tall'
        ])

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        let {
            required,
            softRequired,
            optional,
            placeholder,
            unsettable = true
        } = field

        if(!placeholder) {
            if(type === 'team') {
                placeholder = formatMessage({
                    id: 'team_action_pick',
                    defaultMessage: 'Pick a team'
                })
            }

            if(type === 'location') {
                placeholder = formatMessage({
                    id: 'location_action_pick',
                    defaultMessage: 'Pick a location'
                })
            }

            if(type === 'group') {
                placeholder = formatMessage({
                    id: 'group_action_pick',
                    defaultMessage: 'Pick group'
                })
            }

            if(type === 'jobTitle') {
                placeholder = formatMessage({
                    id: 'jobtitle_action_pick',
                    defaultMessage: 'Pick job title'
                })
            }

            if(type === 'competenceType') {
                placeholder = formatMessage({
                    id: 'competence_type_action_pick',
                    defaultMessage: 'Pick competence type'
                })
            }

            if(type === 'equipmentType') {
                placeholder = formatMessage({
                    id: 'equipment_type_action_pick',
                    defaultMessage: 'Pick equipment type'
                })
            }

            if(type === 'equipmentTypeVariant') {
                placeholder = formatMessage({
                    id: 'equipment_variant_action_pick',
                    defaultMessage: 'Pick variant'
                })
            }
        }

        const id = `${salt}:${name}`

        const Toggler = {
            'with-actions': WithActions
        }[togglerMode] ?? DefaultToggler

        const Value = () => {
            let partial = null

            if(type === 'customFieldType') {
                return (
                    <CustomFieldType
                        field={{
                            ...value,
                            type: value.id
                        }}
                        size={40} />
                )
            }

            if(type === 'country' && !!value?.emoji) {
                partial = <Emoji>{value.emoji}</Emoji>
            }

            if(type === 'competenceType') {
                return (
                    <CompetenceType
                        field={{
                            ...value,
                            type: value.id
                        }}
                        size={40} />
                )
            }

            if(type === 'absenceType') {
                return (
                    <AbsenceType
                        type={value}
                        className="simple"
                        size={40} />
                )
            }

            if(type === 'smartTemplate') {
                return (
                    <SmartTemplate
                        template={value}
                        size={40} />
                )
            }

            // if(['equipmentType', 'equipmentTypeVariant'].includes(type)) {
            if(type === 'equipmentType') {
                partial = (
                    <Symbol
                        symbol={value.symbol ?? equipmentSymbolFallback}
                        size={26}
                        inline />
                )
            }

            if(type === 'role') {
                return value.name ?
                    formatMessage({
                        id: `role_${value.name}_name`,
                        defaultMessage: value.deletable ?
                            value.name :
                            capitalize(name)
                    }) :
                    value.name
            }

            return (
                <>
                    {partial}
                    {value?.name ?? ''}
                </>
            )
        }

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={id}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <input
                        type="hidden"
                        name={name}
                        value={value?.id || ''} />
                    <Toggler
                        {...controlProps}
                        {...(togglerClassName ? { className: togglerClassName } : null)}
                        {...omit(props, 'field', 'form', 'whistle')}
                        id={id}
                        onClick={this.openPicker}
                        active={picking}
                        unset={(!!value?.id && unsettable) && this.unset}
                        unsettable={unsettable}
                        type={type}
                        showIcon={showIcon}
                        disabled={!enabled}
                        ref={this.combinedControlRef}>
                        {!!value?.id && (
                            <ValueDisplay {...(!enabled ? { className: 'soft' } : null)}>
                                <Value />
                            </ValueDisplay>
                        )}
                        {(!value?.id && placeholder) && (
                            <Placeholder>
                                {placeholder}
                            </Placeholder>
                        )}
                    </Toggler>
                    <EntityPicker
                        {...picker}
                        type={type}
                        path={path}
                        params={params}
                        filter={filter}
                        mapper={mapper}
                        creatable={creatable}
                        heading={heading}
                        outer={outer}
                        show={picking}
                        picked={value}
                        dismiss={this.closePicker}
                        cancelAction={() => ({
                            label: formatMessage({
                                id: 'action_cancel',
                                defaultMessage: 'Cancel'
                            }),
                            effect: 'neutral',
                            onClick: this.closePicker
                        })}
                        doneAction={({ picked }) => ({
                            label: formatMessage({
                                id: 'action_done',
                                defaultMessage: 'Done'
                            }),
                            effect: 'constructive',
                            onClick: () => this.onPicked(picked)
                        })}
                        salt={salt} />
                </Control>
            </Field>
        )
    }
}

export default props => {
    const { formatMessage } = useIntl()
    const form = useForm()

    return (
        <EditEntity
            {...props}
            form={form}
            formatMessage={formatMessage} />
    )
}
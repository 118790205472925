import React from 'react'
import { Header } from './s'
import ModuleTitle from 'components/module-title'
import { ModalHeader } from 'modals/generic'

const AssistantPanelHeader = ({ dismiss }) => (
    <Header>
        <ModalHeader
            heading={(
                <ModuleTitle
                    which="content-assistant"
                    sub={true}
                    beta={true} />
            )}
            compact={true}
            dismiss={dismiss} />
    </Header>
)

export default AssistantPanelHeader

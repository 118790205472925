import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Meta } from './s'

const AssignedToDynamicSupervisor = () => (
    <Meta className="compact">
        <FormattedMessage
            id="employee_onboarding_template_assigned_to"
            defaultMessage="<misty>Assigned to</misty> {who}"
            values={{
                misty: text => text,
                who: (
                    <span>
                        <FormattedMessage
                            id="task_assign_to_supervisor"
                            defaultMessage="Supervisor" />
                    </span>
                )
            }} />
    </Meta>
)

export default AssignedToDynamicSupervisor
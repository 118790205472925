import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePeople } from 'contexts/people'
import { useStorage } from 'hooks/storage'
import { useTheme, ThemeProvider } from 'styled-components'
import { clamp } from 'utilities/math'
import { pick } from 'utilities/object'
import { compact } from 'utilities/array'
import {
    Wrap, Header,
    GridColumnHeader, GridColumnHeaderSortable, GridColumnActionsHeader,
    LoadingContainer, Tutorial
} from './s'
import Person from './person'
import Loader from 'components/loader'
import { SkeletonListRow, SkeletonCell, SkeletonAvatarAndFullName, SkeletonStrings } from 'components/skeleton'
import { Ghost } from 'components/button'
import ColumnConfigurator from 'components/column-configurator'
import { Generic as Empty } from 'components/empty'

const salt = 'people:list:configurator'

const People = ({ actions, mode = 'default', header = false, overrideEmpty = false, className }) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()

    const peopleColumns = [
        {
            id: 'locations',
            title: formatMessage({
                id: 'person_label_locations',
                defaultMessage: 'Locations'
            })
        },
        {
            id: 'teams',
            title: formatMessage({
                id: 'person_label_teams',
                defaultMessage: 'Teams'
            })
        },
        {
            id: 'email',
            title: formatMessage({
                id: 'person_label_emailaddress',
                defaultMessage: 'Email address'
            })
        },
        {
            id: 'phonenumber',
            title: formatMessage({
                id: 'person_label_phonenumber',
                defaultMessage: 'Phone number'
            })
        }
    ]
    const defaultColumnsOrder = ['locations', 'teams']

    const [columnsOrder, setColumnsOrder] = useStorage({
        key: `${salt}:${header ? 'configurable' : 'default'}`,
        defaultValue: defaultColumnsOrder,
        type: 'session'
    })
    const [skeletonLength, setSkeletonLength] = useState(5)

    const {
        people,
        intersecter,
        loading,
        sorting = {},
        toggleSorting,
        paging = {},
        fetchPeople,
        fetching,
        hasFetched,
        autoFetch,
        filter = {}
    } = usePeople()

    const hasActions = !!actions?.length
    const { hasNextPage } = paging

    theme.people = {
        mode,
        actions: hasActions,
        header
    }

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(people.length, 1, 10))
        }
    }, [hasFetched, people?.length])

    const filtersApplied = !!compact(Object.values(pick(filter, ...filterFields))).flatMap(a => a).length
    const allByMyself = people.length === 1 && hasFetched && !filtersApplied
    const noResults = !people.length && hasFetched && !!filtersApplied

    return (
        <ThemeProvider theme={theme}>
            <Wrap {...(className ? { className } : null)}>
                {!!header && (
                    <Header>
                        <GridColumnHeaderSortable
                            field="name"
                            sort={sorting}
                            toggle={toggleSorting}
                            text={formatMessage({
                                id: 'person_label_name',
                                defaultMessage: 'Name'
                            })} />
                        {columnsOrder.map(value => {
                            const column = peopleColumns.find(({ id }) => id === value)
                            const { id, title } = column

                            return (
                                <GridColumnHeader key={id}>
                                    <FormattedMessage
                                        id={`person_label_${id}`}
                                        defaultMessage={title}
                                    />
                                </GridColumnHeader>
                            )
                        })}
                        <GridColumnActionsHeader>
                            <ColumnConfigurator
                                columns={peopleColumns}
                                defaultColumnsOrder={defaultColumnsOrder}
                                columnsOrder={columnsOrder}
                                configureColumns={order => setColumnsOrder([...order])}
                                salt={salt} />
                        </GridColumnActionsHeader>
                    </Header>
                )}
                {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow key={`list:people:skeleton:${index}`} columns={3} hasActions={!!header}>
                        <SkeletonCell>
                            <SkeletonAvatarAndFullName size={40} showSecondLine={index % 2 === 0} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings size={20} count={index % 2 === 0 ? 2 : 1} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings size={20} />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {people.map((person, index) => {
                    const last = index + 1 === people.length
                    const includedColumns = columnsOrder.map(value => {
                        return { ...peopleColumns.find(({ id }) => id === value) }
                    })

                    return (
                        <Person
                            person={person}
                            actions={actions}
                            columns={includedColumns}
                            {...((!!intersecter && last) ? { ref: intersecter } : null)}
                            key={person.id} />
                    )
                })}
                {(!!intersecter && !!hasNextPage) && (
                    <LoadingContainer>
                        {(!loading && !autoFetch) && (
                            <Ghost
                                className="constructive"
                                onClick={fetchPeople}>
                                <FormattedMessage
                                    id="action_load_more"
                                    defaultMessage="Load more…"
                                />
                            </Ghost>
                        )}
                        {!!loading && <Loader />}
                    </LoadingContainer>
                )}
                {!overrideEmpty && (
                    <>
                        {allByMyself && <Tutorial which="people" />}
                        {noResults && <Empty />}
                    </>
                )}
            </Wrap>
        </ThemeProvider>
    )
}

const filterFields = ['search', 'jobTitles', 'locations', 'teams', 'supervisor', 'isActive']

export default People
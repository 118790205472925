import React, { useCallback, useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import { useLocation, useNavigate } from 'react-router-dom'
import { get } from 'api'
import { getListRepresentationFromProfile } from 'utilities/person'
import { pick, omit } from 'utilities/object'
import { compact } from 'utilities/array'
import { cls } from 'utilities/dom'
import isEqual from 'react-fast-compare'
import { unoptionize } from 'components/form/field/share'
import { Plain } from 'components/button'
import { Filter, Count, Arrow, Widget, WidgetContentScroller, MobileHeader, ResetWrap } from '../s'
import Form from 'components/form/controller'
import Field from 'components/form/field/share'
import { QuickSelectButton } from './s'

const defaultTypes = ['user', 'team', 'location']

const SharesField = ({ filter, setFilter, allResetAt, name, field = null, picker = null, types = defaultTypes, label, quickSelectMe = false, salt }) => {
    const getDefaultValue = () => field?.defaultValue ?? []
    const getValue = () => filter[name] ?? getDefaultValue()

    const {
        me,
        isItMyOwnId
    } = useMe()

    const location = useLocation()
    const navigate = useNavigate()

    const getIsResettable = useCallback(() => !isEqual(
        getValue().sort(),
        getDefaultValue().sort()
    ), [getValue(), getDefaultValue()])

    const [previousAllResetAt, setPreviousAllResetAt] = useState(allResetAt)
    const [configuring, setConfiguring] = useState(false)
    const [hasInteracted, setHasInteracted] = useState(false)
    const [count, setCount] = useState(getValue().length)

    const [units, setUnits] = useState([])
    const [fetching, setFetching] = useState(false)

    // Resolve units on page load
    useEffect(() => {
        if(!!filter[name]?.length && !units?.length) {
            const users = filter[name].filter(({ type }) => type === 'user')
            const teams = filter[name].filter(({ type }) => type === 'team')
            const locations = filter[name].filter(({ type }) => type === 'location')

            const fetchUsers = !!users.length && types.includes('user')
            const fetchTeams = !!teams.length && types.includes('team')
            const fetchLocations = !!locations.length && types.includes('location')

            const fetch = async () => {
                setFetching(true)

                const results = await Promise.all(compact([
                    fetchUsers && get({
                        path: '/users',
                        params: { ids: users.map(({ id }) => id) }
                    }),
                    fetchTeams && get({ path: '/teams' }),
                    fetchLocations && get({ path: '/locations' }),
                ]))

                const ok = results.every(({ ok }) => ok)
                let resolvedUnits = []

                if(ok) {
                    if(fetchUsers) {
                        const { response: resolvedUsers } = results.shift()
                        resolvedUnits = [...resolvedUnits, ...resolvedUsers.items]
                    }

                    if(fetchTeams) {
                        const { response: resolvedTeams } = results.shift()

                        resolvedUnits = [
                            ...resolvedUnits,
                            ...teams.map(({ id }) => resolvedTeams.find(team => team.id === id))
                        ]
                    }

                    if(fetchLocations) {
                        const { response: resolvedLocations } = results.shift()

                        resolvedUnits = [
                            ...resolvedUnits,
                            ...locations.map(({ id }) => resolvedLocations.find(team => team.id === id))
                        ]
                    }
                }

                setFetching(false)

                if(ok && resolvedUnits.length) {
                    setUnits(resolvedUnits)
                }
            }

            fetch()
        }
    }, [getValue().join('+'), units.map(({ id }) => id).join('+')])

    // Unset the units when value is unset
    useEffect(() => {
        (!getValue().length && !!units.length) && setUnits([])
    }, [getValue().length, units.length])

    useEffect(() => {
        if(allResetAt !== previousAllResetAt) {
            if(allResetAt.endsWith?.(':update')) {
                setCount(getValue().length)
            } else {
                reset({ silent: true })
            }

            setPreviousAllResetAt(allResetAt)
        }
    }, [allResetAt, previousAllResetAt])

    const control = useRef()
    const widget = useRef()

    const update = update => {
        if(name in update) {
            setFilter(update)
            setCount(update[name]?.length)
        }
    }

    const reset = (options = {}) => {
        if(name in (location.state?.filter ?? {})) {
            navigate({
                ...location,
                state: {
                    ...location.state,
                    filter: omit(location.state.filter, name)
                },
                replace: true
            })
        }

        const { silent = false } = options
        !silent && setFilter({ [name]: [] })

        setUnits([])
        setCount(0)
    }

    const filterClassName = cls([
        'constructive',
        configuring && 'active'
    ])

    return (
        <>
            <Filter
                className={filterClassName}
                onClick={() => {
                    setConfiguring(configuring => !configuring)
                    setHasInteracted(true)
                }}
                ref={control}>
                {label}
                <Count {...(configuring ? { className: 'active' } : null)}>
                    {count || ''}
                </Count>
                <Arrow
                    {...(configuring ? { className: 'active' } : null)}
                    size={16} />
            </Filter>
            <Widget
                show={configuring}
                clickOutside={{
                    inside: [control],
                    action: () => setConfiguring(false)
                }}
                position={{
                    origin: control,
                    direction: {
                        x: {
                            where: 'inside',
                            to: 'right'
                        },
                        y: {
                            where: 'outside',
                            to: 'down',
                            adjust: 8
                        }
                    }
                }}
                closeButton={false}
                constrain={true}
                blocking={true}
                scrollable={false}
                salt={`${salt}:${name}:widget`}
                ref={widget}>
                <MobileHeader>
                    <Plain
                        className="constructive"
                        onClick={() => setConfiguring(false)}>
                        <FormattedMessage
                            id="action_done"
                            defaultMessage="Done" />
                    </Plain>
                    <Plain
                        className="destructive"
                        onClick={reset}
                        disabled={!getIsResettable()}>
                        <FormattedMessage
                            id="action_reset"
                            defaultMessage="Reset" />
                    </Plain>
                </MobileHeader>
                <WidgetContentScroller className="lax">
                    <Form layout="vertical">
                        <Field
                            salt={salt}
                            name={name}
                            className="compact"
                            label={false}
                            field={{
                                ...field,
                                value: units
                            }}
                            enabled={!fetching}
                            controlProps={{ autoFocus: true }}
                            picker={{
                                ...picker,
                                types
                            }}
                            onChange={({ [name]: units = [] }) => {
                                if(!hasInteracted) {
                                    return
                                }

                                units = units?.map(unoptionize)
                                update({ [name]: units.map(unit => pick(unit, 'id', 'type')) })
                                setUnits(units)
                            }}
                            key={[
                                salt,
                                name,
                                units.map(({ id }) => id).join('+') ?? 'empty',
                                allResetAt,
                                configuring,
                                fetching
                            ].join(':')} />
                    </Form>
                    {(!!quickSelectMe && !getValue().some(isItMyOwnId)) && (
                        <QuickSelectButton
                            onClick={() => {
                                update({
                                    [name]: [
                                        ...getValue(),
                                        pick(me, 'id', 'type')
                                    ]
                                })

                                setUnits(units => [
                                    ...units,
                                    {
                                        ...getListRepresentationFromProfile(me),
                                        type: 'user'
                                    }
                                ])
                            }}>
                            <FormattedMessage
                                id="action_select_me"
                                defaultMessage="Select me" />
                        </QuickSelectButton>
                    )}
                </WidgetContentScroller>
                {!!getIsResettable() && (
                    <ResetWrap>
                        <Plain
                            className="destructive"
                            onClick={reset}>
                            <FormattedMessage
                                id="action_reset"
                                defaultMessage="Reset" />
                        </Plain>
                    </ResetWrap>
                )}
            </Widget>
        </>
    )
}

export default SharesField

import React, { Component } from 'react'
import { useForm } from 'components/form/controller'
import isEqual from 'react-fast-compare'
import { encode } from 'he'
import { empty } from 'components/form/field/share'
import { map, pick } from 'utilities/object'
import { prune } from 'utilities/array'
import { cls } from 'utilities/dom'
import { isInRange } from 'utilities/math'
import { accessUnitTypes } from 'pages/documents/utilities'
import { Field, Label, Control } from 'components/form/field/s'
import Search from './search'
import AccessUnits from './access-units'

class EditAccessUnits extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this.valueStateFromProps(props),
            focus: false
        }

        this.register()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        let {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        let {
            min = 0,
            max = Infinity
        } = this.props.picker ?? {}

        if(required && min === 0) {
            min = 1
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                value: empty,
                focus: false
            }),

            validator: value => required ?
                isInRange({ min, max, value: value.length }) :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => {
        const value = prune(props.field?.value || empty)
        return { value }
    }

    onChange = () => this.props.onChange?.({ [this.props.name]: this.state.value })

    addAccessUnit = unit => this.setState(({ value }) => ({
        value: [
            {
                unit,
                access: {
                    share: unit.type === 'user',
                    owner: false
                }
            },
            ...value
        ],
        focus: false
    }), () => (unit.type === 'user') && this.onChange())

    updateAccessUnit = (id, update) => this.setState(({ value }) => {
        const unitIndex = value.findIndex(unit => getAccessUnitId(unit) === id)
        if(!~unitIndex) {
            return
        }

        const unit = value[unitIndex]
        if(unit.locked) {
            return
        }

        if(unit.type === 'user') {
            unit.access = update
        } else {
            unit.access = {
                ...unit.access,
                ...update
            }
        }

        return { value: value.with(unitIndex, unit) }
    }, this.onChange)

    removeAccessUnit = accessUnitId => {
        let removed = null

        this.setState(({ value }) => {
            removed = value.find(({ unit, locked }) => accessUnitId === unit.id && !locked)
            if(!removed) {
                return null
            }

            return { value: value.filter(({ unit }) => accessUnitId !== unit.id) }
        }, () => {
            if(Object.values(removed?.access ?? {}).some(Boolean)) {
                this.onChange()
            }
        })
    }

    render() {
        const {
            value,
            focus
        } = this.state

        const {
            className,
            label,
            salt,
            name,
            enabled = true,
            loading = false,
            field = {},
            controlProps = {},
            picker = {},
            params = {},
            formatAdminTooltip,
            belowSearchContent = null,
            forwardedRef
        } = this.props

        const { types = accessUnitTypes } = picker

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const fakeTextClassName = cls([
            focus && 'focus',
            !enabled && 'disabled',
            !value?.length && 'empty',
            error && 'error'
        ])

        const valueMapperContext = { name, salt }
        const mapValues = this.props.form.mode === 'json' ?
            jsonMapper(valueMapperContext) :
            multipartMapper(valueMapperContext)

        const {
            required,
            softRequired,
            optional
        } = field

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    {mapValues(value).map(({ key, ...unit }) => (
                        <input
                            type="hidden"
                            {...unit}
                            key={key} />
                    ))}
                    <Search
                        {...controlProps}
                        {...(fakeTextClassName ? { className: fakeTextClassName } : null)}
                        id={salt}
                        value={value}
                        types={types}
                        params={{
                            ...(params ?? null),
                            ...(picker?.params ?? null)
                        }}
                        addAccessUnit={this.addAccessUnit}
                        onFocus={() => this.setState({ focus: true })}
                        onBlur={() => this.setState({ focus: false })}
                        disabled={!enabled}
                        forwardedRef={forwardedRef} />
                    {belowSearchContent}
                    <AccessUnits
                        value={value}
                        updateAccessUnit={this.updateAccessUnit}
                        removeAccessUnit={this.removeAccessUnit}
                        formatAdminTooltip={formatAdminTooltip}
                        salt={salt} />
                </Control>
            </Field>
        )
    }
}

const jsonMapper = ({ name, salt }) => units => units
    .flatMap(({ unit, access }, index) => [
        ...map({
            ...pick(unit, 'type', 'id'),
            ...access
        }, (value, key) => ({
            name: `${name}[${index}][${key}]`,
            value: value,
            key: `${salt}:value:${unit.type}:access:${key}:${unit.id}:${index}`
        }))
    ])

const multipartMapper = ({ name, salt }) => units => {
    const values = units.flatMap(({ unit, access }) => {
        return {
            ...pick(unit, 'type', 'id'),
            ...access
        }
    })

    const value = `encoded:${encode(JSON.stringify(values))}`

    return [{
        name,
        value,
        key: `${salt}:value`
    }]
}

export const getAccessUnitId = ({ unit }) => unit.id

export default props => {
    const form = useForm()

    return (
        <EditAccessUnits
            {...props}
            form={form} />
    )
}
import React from 'react'
import { useIntl } from 'react-intl'
import { useEntities } from 'contexts/entities'
import {
    categories as competenceCategories,
    categoryToMetaMap as competenceCategoryToMetaMap
} from 'pages/competence/constants/category'
import { FilterRow } from './s'
import Filter from 'components/filter'

const EntityPickerFilter = ({ overrides, namespace, salt }) => {
    const { formatMessage } = useIntl()

    const {
        path,
        params,

        filter,
        entities: items,
        fetching,
        hasFetched,

        setEntitiesFilter: setFilter
    } = useEntities()

    const fields = getFilterFields({ path, params, overrides, formatMessage })
    if(!fields) {
        return null
    }

    return (
        <FilterRow>
            <Filter
                namespace={namespace}
                filter={filter}
                setFilter={setFilter}
                {...fields}
                items={items}
                fetching={fetching}
                hasFetched={hasFetched}
                showerride={true}
                expandable={true}
                memoryFilterOptions={{ enabled: false }}
                salt={salt} />
        </FilterRow>
    )
}

const searchable = [
    '/groups',
    '/job-titles',
    '/job-titles/paged',
    '/competence-types',
    '/equipment-types',
    '/documents/templates'
]

const getFilterFields = ({ path, params, overrides, formatMessage }) => {
    const search = {
        type: 'search',
        name: 'search',
        controlProps: {
            placeholder: formatMessage({
                id: 'search_placeholder',
                defaultMessage: 'Start typing to search…'
            })
        },
        ...(overrides?.search ?? null)
    }

    const hideNames = Object.keys(params)

    let basicFields = []
    let advancedFields = []

    if(['/teams', '/locations'].includes(path)) {
        return null
    }

    // These endpoints supports filters that are considered
    // too advanced and/or undesirable for a user to use
    if([
        '/roles', // domain, assignable, and deletable
        '/absence/types' // includeArchivedPolicies, includePolicies
    ].includes(path)) {
        return null
    }

    if(searchable.includes(path) && !hideNames?.includes('search')) {
        basicFields.push(search)
    }

    if(path === '/groups') {
        if(!hideNames?.includes('types')) {
            advancedFields.push({
                type: 'checkboxes',
                name: 'types',
                label: formatMessage({
                    id: 'noun_types',
                    defaultMessage: 'Types'
                }),
                field: {
                    options: [
                        {
                            id: 'team',
                            name: formatMessage({
                                id: 'noun_teams',
                                defaultMessage: 'Teams'
                            })
                        },
                        {
                            id: 'location',
                            name: formatMessage({
                                id: 'noun_locations',
                                defaultMessage: 'Locations'
                            })
                        }
                    ]
                },
                ...(overrides?.types ?? null)
            })
        }
    }

    if(path === '/competence-types') {
        if(!hideNames?.includes('category')) {
            advancedFields.push({
                type: 'checkboxes',
                name: 'category',
                label: formatMessage({
                    id: 'noun_category',
                    defaultMessage: 'Category'
                }),
                field: {
                    options: competenceCategories.map(({ name }) => ({
                        id: name,
                        name: formatMessage(competenceCategoryToMetaMap[name].name)
                    }))
                },
                ...(overrides?.category ?? null)
            })
        }
    }

    if(!basicFields.length && !advancedFields.length) {
        return null
    }

    return {
        basicFields,
        advancedFields
    }
}

export default EntityPickerFilter
import styled from 'styled-components'
import { Columns, FlexChildShrink } from 'components/flex'

export const DigitColumns = styled(Columns)`
    justify-content: space-between;

    max-width: 376px;
`

export const DigitColumn = styled(FlexChildShrink)`
    position: relative;

    width: calc((100% - 40px) / 6);

    &::before {
        content: "";

        display: block;

        padding-top: calc(9 / 7 * 100%); /* 7:9 */
    }
`

export const DigitInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    width: 100%;
    height: 100%;
    padding: 0;
    background: none;

    transition: box-shadow .1s ease-in-out;

    font-size: 20px;
    font-weight: 500;
    line-height: 100%;
    text-align: center;
    color: var(--huma-color-foreground-default);

    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:hover {
        border: 1px solid var(--huma-color-border-default-hover);
    }

    &:focus {
        outline: none;
        box-shadow:
            0 0 0 1px var(--huma-color-surface-default),
            0 0 0 3px var(--huma-color-border-constructive-focus);
    }

    &:invalid,
    &.error,
    .error & {
        border: 1px solid var(--huma-color-border-destructive-focus);
    }
`
import React from 'react'
import { useIntl } from 'react-intl'
import { useClipboard } from 'hooks/clipboard'
import { BlockWrapper, Highlighter, CopyCode, Code } from './s'
import { Copy, Check } from 'styled-icons/feather'

export const Block = ({ language = 'text', code, wrapLongLines = false, showLineNumbers = false, ...props }) => {
    const { formatMessage } = useIntl()
    const { ref, copy, copied } = useClipboard({ text: code })

    if(!code) {
        return null
    }

    return (
        <BlockWrapper {...props}>
            <Highlighter
                className={showLineNumbers ? 'show-line-numbers' : ''}
                language={language}
                wrapLongLines={wrapLongLines}
                showLineNumbers={showLineNumbers}>
                {code}
            </Highlighter>
            <CopyCode
                className={copied ? 'success' : 'neutral'}
                onClick={copy}
                data-tooltip={formatMessage({
                    id: 'action_copied',
                    defaultMessage: 'Copied'
                })}
                ref={ref}>
                {!copied && <Copy size={20} />}
                {!!copied && <Check size={20} />}
            </CopyCode>
        </BlockWrapper>
    )
}

export const Inline = Code
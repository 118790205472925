import React from 'react'
import { View } from '@react-pdf/renderer'
import {
    styles,
    Title, Heading2, Heading3, Heading4,
    Paragraph, Mention, Variable, VariableLabeled, Anchor,
    Strong, Italic, Underline, Strikethrough, Superscript, Subscript, Annotation, Blockquote, Code,
    UnorderedList, OrderedList, ListItem,
    Hr, Br,
    EmojiText
} from './s'

const Content = ({ content = [], getVariableData }) => {
    const contentMapper = getContentMapper(getVariableData)

    return (
        <View style={styles.section}>
            {content.map(contentMapper)}
        </View>
    )
}

const getMarkApplicator = index => (item, text) => item.marks.reduce((applied, mark, markIndex) => {
    const key = `${item.type}:${mark.type}:${index}:${markIndex}`

    if(mark.type === 'bold') {
        return <Strong key={key}>{applied}</Strong>
    }

    if(mark.type === 'italic') {
        return <Italic key={key}>{applied}</Italic>
    }

    if(mark.type === 'underline') {
        return <Underline key={key}>{applied}</Underline>
    }

    if(mark.type === 'strike') {
        return <Strikethrough key={key}>{applied}</Strikethrough>
    }

    if(mark.type === 'superscript') {
        return <Superscript key={key}>{applied}</Superscript>
    }

    if(mark.type === 'subscript') {
        return <Subscript key={key}>{applied}</Subscript>
    }

    if(mark.type === 'highlight') {
        return <Annotation key={key}>{applied}</Annotation>
    }

    if(mark.type === 'link') {
        return <Anchor href={mark.attrs.href} key={key}>{applied}</Anchor>
    }

    if(mark.type === 'code') {
        return (
            <View key={key}>
                <Code>{applied}</Code>
            </View>
        )
    }

    return applied
}, text)

const getVariableRenderer = getVariableData => ({ item, index }) => {
    const {
        variable,
        Element: tagName,
        descriptor
    } = getVariableData(item)

    if(!!item.attrs.value) {
        if(item.marks) {
            return getMarkApplicator(index)(item, item.attrs.value)
        }

        return item.attrs.value
    }

    const Element = (tagName === 'span') ?
        VariableLabeled :
        Variable

    return (
        <Element key={`variable:${index}`}>
            {variable.options.renderHTML?.({
                ...item.attrs,
                descriptor
            }) ?? ''}
        </Element>
    )
}

const getContentMapper = getVariableData => {
    const mapper = (item, index) => {
        const children = item.content?.map(mapper)
        let key = `${item.type}:${index}`

        if(item.type === 'title') {
            return <Title key={key}>{children}</Title>
        }

        if(item.type === 'heading') {
            const Heading = {
                2: Heading2,
                3: Heading3,
                4: Heading4
            }[item.attrs.level] ?? Heading4

            return <Heading key={key}>{children}</Heading>
        }

        if(item.type === 'paragraph') {
            const itemProps = {
                ...(item?.compact ? { compact: true } : null),
                ...(item?.attrs?.indent ? { indent: item.attrs.indent } : null)
            }

            return (
                <Paragraph
                    {...itemProps}
                    key={key}>
                    {children}
                </Paragraph>
            )
        }

        if(item.type === 'mention') {
            return <Mention key={key}>@{item.attrs.name}</Mention>
        }

        if(item.type === 'variable') {
            return getVariableRenderer(getVariableData)({ item, index })
        }

        if(item.type === 'text') {
            // Replace tabs with 4 spaces
            if(item.text.includes('\t')) {
                item.text = item.text.replaceAll('\t', '    ')
            }

            if(item.preserveWhitespace) {
                item.text = item.text.replaceAll(' ', '\u00a0')
            }

            if(item.marks) {
                return getMarkApplicator(index)(item, item.text)
            }

            return item.text
        }

        if(item.type === 'codeBlock') {
            return (
                <View key={key}>
                    <Code style={{ minHeight: 22 }}>
                        {item.content?.map(content => mapper({
                            ...content,
                            preserveWhitespace: true,
                            compact: true
                        }))}
                    </Code>
                </View>
            )
        }

        if(item.type === 'blockquote') {
            return <Blockquote key={key}>{item.content.map(content => mapper({ ...content, compact: true }))}</Blockquote>
        }

        if(item.type === 'bulletList') {
            return <UnorderedList key={key}>{item.content.map(content => mapper({ ...content, compact: true }))}</UnorderedList>
        }

        if(item.type === 'orderedList') {
            return (
                <OrderedList
                    start={item.attrs.start}
                    key={key}>
                    {item.content.map(content => mapper({ ...content, compact: true }))}
                </OrderedList>
            )
        }

        if(item.type === 'listItem') {
            return <ListItem key={key}>{item.content.map(content => mapper({ ...content, compact: true }))}</ListItem>
        }

        if(item.type === 'horizontalRule') {
            return <Hr key={key} />
        }

        if(item.type === 'hardBreak') {
            return <Br key={key} />
        }

        if(item.type === 'emoji') {
            return (
                <EmojiText
                    emoji={item.attrs.name}
                    key={key} />
            )
        }

        if(['youtube', 'vimeo'].includes(item.type)) {
            const itemProps = {
                ...(item?.compact ? { compact: true } : null),
                ...(item?.attrs?.indent ? { indent: item.attrs.indent } : null)
            }

            return (
                <Paragraph
                    {...itemProps}
                    key={key}>
                    <Anchor href={item.attrs.src}>{item.attrs.src}</Anchor>
                </Paragraph>
            )
        }

        return null
    }

    return mapper
}

export default Content
import React from 'react'
import { OrderedList } from 'components/list'
import Person from './person'

const People = ({ people, salt }) => (
    <OrderedList>
        {people.map(person => {
            const key = `${salt}:people:${person.id}`

            return (
                <Person
                    person={person}
                    salt={key}
                    key={key} />
            )
        })}
    </OrderedList>
)

export default People
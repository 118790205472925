import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormattedAddress } from 'components/address'
import { getPluralizedType } from 'pages/people/utilities'
import { getPeopleTeamUrl, getPeopleLocationUrl } from 'utilities/url'
import { getCategoryIcon } from 'utilities/categories'
import {
    GridCell,
    IconWrapper, Heading, Name,
    Address, MemberCount,
    Avatars, ArrowWrapper
} from './s'
import ContextMenu from 'widgets/context-menu'
import { ArrowRight } from 'styled-icons/feather'

const Group = forwardRef(({ group, actions }, ref) => {
    const {
        type,
        name,
        address,
        members = []
    } = group

    const pluralizedType = getPluralizedType(type)
    const id = `list:${pluralizedType}:${type}:${group.id}`
    const Icon = getCategoryIcon(pluralizedType)

    const getUrl = ({
        team: getPeopleTeamUrl,
        location: getPeopleLocationUrl
    }[type])

    return (
        <GridCell
            to={getUrl(group)}
            ref={ref}>
            <IconWrapper>
                <Icon size={24} />
            </IconWrapper>
            <Heading>
                <Name>{name}</Name>
                {(!!actions?.length) && (
                    <ContextMenu
                        salt={id}
                        context={group}
                        actions={actions} />
                )}
            </Heading>
            {!!address && (
                <FormattedAddress
                    address={address}
                    multiline={true}
                    as={Address} />
            )}
            <MemberCount>
                <FormattedMessage
                    id="group_members_count"
                    defaultMessage="{count, plural, =0 {No members} one {1 member} other {{count} members}}"
                    values={{ count: members.length }} />
            </MemberCount>
            <Avatars
                people={members}
                max={3}
                salt={id} />
            <ArrowWrapper>
                <ArrowRight size={24} />
            </ArrowWrapper>
        </GridCell>
    )
})

export default Group
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { ListItem } from 'components/list'
import { flexColumnsCss, flexChildCss, FlexChildShrink } from 'components/flex'
import { TypeSymbol as CustomFieldTypeSymbolBase } from 'pages/settings/pages/custom-profile-fields/components/type'
import { SmartTemplateSymbol as SmartTemplateSymbolBase } from 'pages/documents/components/smart-template'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import { CaptionSmall } from 'components/typography/caption'
import { Simple } from 'components/button'

export const EntityItem = styled(ListItem)`
    &:not(:last-child) {
        margin: 0 32px 16px;

        &.large {
            margin-bottom: 32px;
        }
    }

    &:last-child {
        margin: 0 32px 32px;
    }

    svg {
        ${svgStroke()}
    }
`

export const EntityColumns = styled.label`
    ${flexColumnsCss}
    align-items: center;
    column-gap: 24px;

    min-height: 40px;

    &:not(.disabled) {
        cursor: pointer;
    }
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(EntityItem)}
`

export const CustomFieldTypeSymbol = styled(CustomFieldTypeSymbolBase)`
    ${ancestorHover(EntityItem)}
`

export const SmartTemplateSymbol = styled(SmartTemplateSymbolBase)`
    ${ancestorHover(EntityItem)}
`

export const IconColumn = styled(FlexChildShrink)`
    min-width: 20px;

    font-size: 20px;

    ${EntityItem}.sub-entity & {
        position: relative;

        height: 40px;

        &::before {
            content: "";
            position: absolute;
            inset-inline-start: 50%;

            width: 1px;
            height: calc(100% + 16px);
            background-color: var(--huma-color-border-default);
        }
    }

    ${EntityItem}.sub-entity:last-child & {
        margin-block-end: 16px;

        &::before
            height: 100%;
        }
    }
`

export const NameColumn = styled.div`
    ${flexChildCss}

    line-height: 20px;
`

export const SmartTemplateCaption = styled(CaptionSmall)`
    margin-inline-start: -4px;
`

export const ToggleButton = styled(Simple)`
    svg {
        transition: rotate .15s;
    }

    &.expanded svg {
        transform: rotate(-180deg);
    }
`

export const Drawer = styled.div`
    overflow: hidden;
    position: relative;

    max-height: 100%;
    height: 0;

    &.expanded {
        overflow: visible;

        height: auto;
    }
`
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Layout, Circle, Completed, Details } from './s'
import { Check } from 'styled-icons/feather'
import { CaptionSmall } from 'components/typography/caption'

const Progress = ({ completed, total, mode = 'full', size = 24, children, ...props }) => {
    let progress = parseInt(completed / total * 100)

    if(isNaN(progress)) {
        progress = 0
    }

    const showChildren = mode === 'children' && children

    if(mode === 'children' && !children) {
        mode = 'compact'
    }

    return (
        <Layout
            $mode={mode}
            $size={size}
            {...props}>
            {(progress === 100) && (
                <Completed size={size}>
                    <Check size={size / 1.5} />
                </Completed>
            )}
            {(progress < 100) && (
                <Circle
                    progress={progress}
                    size={size} />
            )}
            {['full', 'short'].includes(mode) && (
                <Details className="compact">
                    {(mode === 'full') && (
                        <>
                            <span className="percentage">
                                <FormattedMessage
                                    id="value_percent"
                                    defaultMessage="{percent} %"
                                    values={{ percent: progress }} />
                            </span>
                            {' '}
                            <CaptionSmall
                                as="span"
                                className="compact">
                                {`(${completed} / ${total})`} {/* eslint-disable-line no-irregular-whitespace */}
                            </CaptionSmall>
                        </>
                    )}
                    {(mode === 'short') && (
                        `${completed} / ${total}` // eslint-disable-line no-irregular-whitespace
                    )}
                </Details>
            )}
            {showChildren && (
                <Details className="compact">
                    {children}
                </Details>
            )}
        </Layout>
    )
}

export default Progress
import React from 'react'
import { compact } from 'utilities/array'
import { Wrap, Name, Meta } from './s'

const WorkScheduleDisplay = ({ name, country, workdayNamesFormatted }) => (
    <Wrap>
        <Name className="compact">{name}</Name>
        <Meta className="compact">
            {compact([
                country?.name,
                workdayNamesFormatted
            ]).join(' · ')}
        </Meta>
    </Wrap>
)

export default WorkScheduleDisplay
import styled, { css } from 'styled-components'
import { fromPhone, belowPhone } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Naked } from 'components/button'
import { Play as PlayBase } from 'styled-icons/boxicons-regular'

export const aspectRatio = {
    width: 16,
    height: 10
}

export const Wrapper = styled(Grid)`
    gap: 24px;
    align-items: center;

    ${fromPhone`
        grid-template-columns: 128px 1fr;
    `}

    ${belowPhone`
        grid-template-rows: repeat(2, auto);
    `}
`

export const ThumbnailWrapper = styled(Naked)`
    position: relative;

    border-radius: 8px;
    aspect-ratio: ${aspectRatio.width} / ${aspectRatio.height};

    &:focus-visible {
        outline: 1px auto var(--huma-color-border-constructive-bold);
        outline-offset: 1px;
    }
`

export const PlayButton = styled.div`
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    min-width: 40px;
    width: 18%;
    min-height: 40px;
    height: calc((100% * ${aspectRatio.width / aspectRatio.height}) * 0.18);
    background-color: var(--huma-color-surface-default);

    transform: translate(-50%, -50%);
    transition: transform .1s ease-in-out;

    ${ThumbnailWrapper}:where(:hover, :focus) & {
        transform: translate(-50%, -50%) scale(1.1);
    }
`

export const Play = styled(PlayBase)`
    margin-inline-start: 2px;
    width: 50%;
    height: 50%;
`

const thumbnailCss = css`
    position: absolute;
    top: 0;
    left: 0;

    border-radius: 8px;
    width: 100%;
    height: 100%;
`

export const Thumbnail = styled.img`
    ${thumbnailCss}

    transition: filter 0.1s ease-in-out;

    ${ThumbnailWrapper}:where(:hover, :focus) & {
        filter: contrast(0.8);
    }
`

export const ThumbnailFallback = styled.div`
    ${thumbnailCss}

    background-color: var(--huma-color-border-default);

    transition: all .1s ease-in-out;

    ${ThumbnailWrapper}:where(:hover, :focus) & {
        background-color: var(--huma-color-border-default-focus);
        box-shadow: var(--huma-shadow-lowest);
    }
`
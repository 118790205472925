import React, { forwardRef, isValidElement, cloneElement } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { cls } from 'utilities/dom'
import {
    StyledNakedButton, StyledButton, StyledOnDarkButton, StyledBenjaminButton, StyledHumaButton, StyledGhostButton, StyledSimpleButton, StyledPlainButton, StyledPlainOnDarkButton, StyledRaisedButton,
    StyledFilterButton,
    halfNakedCss, closeButtonCss,
    InlineButtonNode
} from './s'
import {
    ArrowLeft as ArrowLeftBase,
    ArrowUpCircle as Upgrade,
    X
} from 'styled-icons/feather'

const buttonAttributes = ({ tabIndex = 0 }) => ({
    tabIndex,
    type: 'button'
})

const submitAttributes = ({ tabIndex = 0 }) => ({
    tabIndex,
    type: 'submit'
})

const createButton = attributes => Button => forwardRef(({
    as: Component = 'button',
    children,
    icon: Icon = null,
    size = 'regular',
    reversed = false,
    upgradable = false,
    className,
    ...props
}, ref) => {
    let iconNode = null

    const iconSizes = {
        small: 16,
        medium: 20,
        regular: 24
    }

    let iconSize = iconSizes[size]

    if(!!children) {
        iconSize = iconSizes.small
    }

    if(upgradable) {
        Icon = Upgrade
    }

    if(!!Icon) {
        if(isValidElement(Icon)) {
            iconNode = cloneElement(
                Icon,
                {
                    size: iconSize,
                    ...Icon.props
                }
            )
        } else {
            iconNode = <Icon size={iconSize} />
        }
    }

    className = cls([
        className,
        !!children && 'has-label',
        !!Icon && 'has-icon',
        (!!Icon && reversed) && 'reversed',
        size
    ])

    return (
        <Button
            {...attributes(props)}
            {...props}
            className={className}
            as={Component}
            ref={ref}>
            {iconNode}
            {children}
        </Button>
    )
})

const createButtonButton = createButton(buttonAttributes)
const createSubmitButton = createButton(submitAttributes)

export const Naked = createButtonButton(StyledNakedButton)
export const NakedSubmit = createSubmitButton(StyledNakedButton)

export const Button = createButtonButton(StyledButton)
export const ButtonSubmit = createSubmitButton(StyledButton)

export const ButtOnDark = createButtonButton(StyledOnDarkButton)
export const ButtOnDarkSubmit = createSubmitButton(StyledOnDarkButton)

export const Benjamin = createButtonButton(StyledBenjaminButton)
export const BenjaminSubmit = createSubmitButton(StyledBenjaminButton)

export const Huma = createButtonButton(StyledHumaButton)
export const HumaSubmit = createSubmitButton(StyledHumaButton)

export const Ghost = createButtonButton(StyledGhostButton)
export const GhostSubmit = createSubmitButton(StyledGhostButton)

export const Simple = createButtonButton(StyledSimpleButton)
export const SimpleSubmit = createSubmitButton(StyledSimpleButton)

export const Plain = createButtonButton(StyledPlainButton)
export const PlainSubmit = createSubmitButton(StyledPlainButton)

export const PlainOnDark = createButtonButton(StyledPlainOnDarkButton)
export const PlainOnDarkSubmit = createSubmitButton(StyledPlainOnDarkButton)

export const Raised = createButtonButton(StyledRaisedButton)
export const RaisedSubmit = createSubmitButton(StyledRaisedButton)

export const Filter = createButtonButton(StyledFilterButton)
export const FilterSubmit = createSubmitButton(StyledFilterButton)

const ArrowLeft = styled(ArrowLeftBase)`
    transition: transform .1s ease-in-out;
    transform: translateX(0);

    ${StyledPlainButton}:is(:hover, :focus-visible) & {
        transform: translateX(-2px);
    }
`

export const BackButton = ({ text, ...props }) => (
    <Plain
        icon={<ArrowLeft size={24} />}
        {...props}>
        {text}
        {!text && (
            <FormattedMessage
                id="action_back"
                defaultMessage="Back" />
        )}
    </Plain>
)

export const HalfNakedButton = styled(Naked)`
    ${halfNakedCss}
`

const CloseButtonNode = styled(Naked)`
    ${closeButtonCss()}
`

export const CloseButton = props => (
    <CloseButtonNode
        {...props}
        icon={X} />
)

export const fakeButtonProps = {
    tabIndex: 0,
    role: 'button'
}

export const InlineButton = ({ children, onClick, disabled = false, ...props }) => (
    <InlineButtonNode
        {...fakeButtonProps}
        {...props}
        className={cls([props.className, disabled && 'disabled'])}
        onClick={() => (!disabled && typeof onClick === 'function') && onClick()}
        onKeyDown={event => {
            if([' ', 'Enter', 'Space'].includes(event.key)) {
                !disabled && onClick()
            }
        }}>
        {children}
    </InlineButtonNode>
)

export const UnsetValueButton = styled(Naked)`
    position: absolute;
    top: 50%;
    right: 8px;

    display: grid;
    place-items: center;

    width: 24px;
    height: 24px;

    transform: translateY(-50%);
    transition: all .1s ease-in-out;

    color: var(--huma-color-foreground-default);
`

export const UnsetValueButtonXCircle = styled.div`
    display: grid;
    place-items: center;

    border-radius: 100%;
    width: 16px;
    height: 16px;
    background-color: var(--huma-color-surface-subtle);

    transition: background-color .1s ease-in-out;

    ${UnsetValueButton}:where(:hover, :active) & {
        background: var(--huma-color-surface-subtle-hover);
    }

    svg {
        ${svgStroke()}
    }
`

export * as buttonStyles from './s'
export { ButtonArrow } from './s'
import { useUnloadPrompt } from 'hooks/unload-prompt'

const UnloadPrompt = ({ active, message, ...props }) => {
    useUnloadPrompt(
        (active && !!message) ?
            message :
            false,
        props
    )

    return null
}

export default UnloadPrompt
import styled, { css } from 'styled-components'
import {
    svgStroke, flashKeyframes,
    fromPhone, belowPhone
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { Naked } from 'components/button'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import { InlineMessage as InlineMessageBase } from 'components/message'
import { CaptionSmall } from 'components/typography/caption'

export const RecordWrapper = styled(Grid)`
    grid-template-columns: minmax(0, 2fr) repeat(2, 1fr) 40px;
    column-gap: 24px;

    width: 100%;
    padding-block: 24px;
    box-shadow: 0 -1px var(--huma-color-border-default);

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }

    ${belowPhone`
        grid-template-columns: 1fr 40px;
        grid-auto-flow: row;
        grid-auto-rows: auto;
        row-gap: 12px;
    `}
`

const competenceCellCss = css`
    display: grid;
    grid-template-rows: 24px 16px;
    grid-template-columns: 40px minmax(0, 1fr);
    column-gap: 16px;
    align-items: center;

    height: 40px;

    text-align: start;

    ${belowPhone`
        grid-row: 1 / 2;
    `}
`

export const CompetenceButton = styled(Naked)`
    ${competenceCellCss}

    &:hover,
    &:focus {
        text-decoration: none;
    }
`

export const CompetenceWrapper = styled.div`
    ${competenceCellCss}
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(CompetenceButton)}

    grid-row: 1 / 3;
    grid-column: 1 / 2;
`

export const CompetenceTypeTitle = styled.div`
    grid-row: 1 / 2;
    grid-column: 2 / 3;

    line-height: 24px;

    ${CompetenceButton}:is(:hover,:focus) & {
        text-decoration: underline;
    }
`

export const Category = styled(CaptionSmall)`
    grid-row: 2 / 3;
    grid-column: 2 / 3;

    line-height: 16px;
`

const cellCss = css`
    display: grid;
    align-items: start;
`

export const BelongsToCell = styled.div`
    ${cellCss}

    color: var(--huma-color-foreground-subtle);

    ${fromPhone`
        line-height: 24px;
    `}

    ${belowPhone`
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        grid-template-columns: auto 1fr;
        align-items: center;

        padding: 0 0 0 56px;

        font-size: 14px;
    `}
`

export const StatusCell = styled.div`
    ${cellCss}

    & > :last-child {
        margin-bottom: 0;
    }

    ${fromPhone`
        & > :first-child {
            margin-top: 4px;
        }
    `}

    ${belowPhone`
        grid-column: 1 / 2;
        grid-row: 3 / 4;

        padding: 0 0 0 56px;

        &:empty {
            display: none;
        }
    `}
`

export const InlineMessage = styled(InlineMessageBase)`
    & p {
        line-height: 20px;
    }
`

export const ActionsCell = styled.div`
    justify-content: center;

    ${belowPhone`
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    `}

    svg {
        ${svgStroke()}
    }
`

import styled, { css } from 'styled-components'
import { fromPhone, fromContainer } from 'utilities/styled'
import { CaptionSmall } from 'components/typography/caption'

const labelCss = css`
    margin-block-end: 0;

    @layer mobile-label {
        &::after {
            content: ":";
        }

        &.endspace::after {
            margin-inline-end: 4px;
        }
    }
`

const MobileLabel = styled(CaptionSmall).attrs({ as: 'span' })`
    ${labelCss}

    @layer mobile-label {
        ${fromPhone`
            display: none;
        `}
    }
`

export const ContainerLabel = styled(CaptionSmall).attrs(props => ({ as: 'span', ...props }))`
    ${labelCss}

    @layer mobile-label {
        ${props => fromContainer(props.$containerProps)`
            display: none;
        `}
    }
`

export default MobileLabel
import React, { useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useContentAssistant } from 'contexts/content-assistant'
import { useStorage } from 'hooks/storage'
import { Field, StringField, Commit, Button, InlineButton } from './s'
import Form from 'components/form/controller'
import Loader from 'components/loader'
import { ArrowUp } from 'styled-icons/feather'
import { Helper } from 'components/form/field/s'

const CustomPrompt = ({ addPrompt, upgradable, salt }) => {
    const { formatMessage } = useIntl()

    const messageRef = useRef(null)

    const { loading } = useContentAssistant()

    const [helperDismissed, setHelperDismissed] = useStorage({
        key: `${salt}:helper:dismissed`,
        defaultValue: false
    })

    const handleSubmit = async ({ message }, { reset }) => {
        await addPrompt({
            prompt: message,
            displayPrompt: message
        })

        reset()
        messageRef.current?.focus()
    }

    return (
        <Field>
            <Form onSubmit={handleSubmit}>
                {({ trigger }) => (
                    <>
                        <Commit>
                            <Button
                                className="constructive"
                                disabled={loading || upgradable}
                                ref={trigger}>
                                {!loading && <ArrowUp size={16} />}
                                {!!loading && <Loader className="xs inverted" />}
                            </Button>
                        </Commit>
                        <StringField
                            salt={salt}
                            label={false}
                            name="message"
                            field={{ include: 'always' }}
                            enabled={!loading && !upgradable}
                            controlProps={{
                                placeholder: formatMessage({
                                    id: 'assistant_input_prompt_placeholder',
                                    defaultMessage: '✨ Ask AI to edit or generate…'
                                })
                            }}
                            ref={messageRef} />
                        {!helperDismissed && (
                            <Helper className="compact">
                                <span>
                                    <FormattedMessage
                                        id="assistant_intro_chatgpt_model_info"
                                        defaultMessage="AI may produce inaccurate information. Don’t add any sensitive information." />
                                </span>
                                <InlineButton
                                    onClick={() => setHelperDismissed(false)}
                                    className="constructive">
                                    <FormattedMessage
                                        id="action_hide"
                                        defaultMessage="Hide" />
                                </InlineButton>
                            </Helper>
                        )}
                    </>
                )}
            </Form>
        </Field>
    )
}

export default CustomPrompt

import React from 'react'
import View from './view'
import Edit from './edit'

const UsefulLinks = ({ mode = 'view', ...props }) => {
    const Links = {
        view: View,
        edit: Edit
    }[mode]

    return <Links {...props} />
}

export default UsefulLinks

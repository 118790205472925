import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { capitalize } from 'utilities/string'
import {
    Heading,
    LegendList, LegendItem, LegendDisc,

    TooltipContainer, TooltipHeading, TooltipList,
    TooltipItem, TooltipDisc, TooltipLabel, TooltipValue
} from './s'

export const Title = ({ id }) => (
    <Heading>
        <FormattedMessage
            id={`chart_${id.replace(/-/g, '_')}_title`}
            defaultMessage={capitalize(id.replace(/-/g, ' '))} />
    </Heading>
)

export const CustomLegend = ({ payload, className, salt }) => (
    <LegendList className={className}>
        {payload.map(({ value, color }) => (
            <LegendItem key={`chart:${salt}:legend:${value}`}>
                <LegendDisc style={{ background: color }} />
                {value}
            </LegendItem>
        ))}
    </LegendList>
)

export const CustomTooltip = ({ active, payload, label: heading, valueFormatter, percent, salt }) => {
    const { formatMessage } = useIntl()

    if(!active) {
        return null
    }

    const items = payload.map(({ payload, value, name, dataKey, fill }) => ({
        label: name || formatMessage({
            id: 'noun_not_available',
            defaultMessage: 'N/A'
        }),
        value: valueFormatter((value || value === 0) ?
            value :
            payload[dataKey]),
        color: fill || payload.fill
    }))

    return (
        <TooltipContainer>
            {!!heading && (
                <TooltipHeading>{heading}</TooltipHeading>
            )}
            <TooltipList>
                {items.map(({ color, label, value }) => (
                    <TooltipItem key={`${salt}:tooltip:${label}`}>
                        <TooltipDisc style={{ background: color }} />
                        <TooltipLabel>{label}</TooltipLabel>
                        <TooltipValue>
                            {!!percent && (
                                <FormattedMessage
                                    id="chart_value_format_value_with_percent"
                                    defaultMessage="{value} <thin>({percent} %)</thin>"
                                    values={{
                                        thin: chunks => <span>{chunks}</span>,
                                        value,
                                        percent
                                    }} />
                            )}
                            {!percent && value}
                        </TooltipValue>
                    </TooltipItem>
                ))}
            </TooltipList>
        </TooltipContainer>
    )
}

export const CustomSingleDimensionTooltip = ({ active, payload, label, valueFormatter }) => {
    if(!active) {
        return null
    }

    const item = payload[0]

    const value = valueFormatter((item.value || item.value === 0) ?
        item.value :
        item.payload[item.dataKey]
    )

    return (
        <TooltipContainer>
            <TooltipList>
                <TooltipItem className="no-color">
                    <TooltipLabel>{label}</TooltipLabel>
                    <TooltipValue>{value}</TooltipValue>
                </TooltipItem>
            </TooltipList>
        </TooltipContainer>
    )
}

export const formatValue =
    ({ format, formatMessage }) =>
    value => {
        if(!format) {
            return value
        }

        if(format.decimals) {
            value = value.toFixed(format.decimals)
        }

        if(format.type === 'years') {
            return formatMessage({
                id: 'chart_value_format_years',
                defaultMessage: '{years} years'
            }, { years: value })
        }

        return value
    }

export const reduceLabels =
    ({ formatMessage, territories, short = false }) =>
    (accumulator, { key, i18n, range, format }) => {
        if(range) {
            const rangeBaseKey = (format === 'percentage') ?
                'chart_label_range_percent' :
                'chart_label_range'

            let translation = {
                id: rangeBaseKey,
                defaultMessage: '{from}–{to}',
            }

            if(!('from' in range)) {
                translation = {
                    id: `${rangeBaseKey}_nether`,
                    defaultMessage: '< {to}',
                }
            } else if(!('to' in range)) {
                translation = {
                    id: `${rangeBaseKey}_upper`,
                    defaultMessage: '>= {from}',
                }
            }

            if(range.from === range.to) {
                translation = {
                    id: `${rangeBaseKey}_exact`,
                    defaultMessage: `= ${range.from}`
                }

                range = { value: range.from }
            }

            return {
                ...accumulator,
                [key]: formatMessage(translation, range)
            }
        }

        if(!key || key === 'unspecified') {
            if(short) {
                return {
                    ...accumulator,
                    unspecified: '—'
                }
            }

            return {
                ...accumulator,
                unspecified: formatMessage({
                    id: `chart_label_unspecified`,
                    defaultMessage: 'Unspecified'
                })
            }
        }

        if(!i18n) {
            return {
                ...accumulator,
                [key]: key
            }
        }

        if(i18n === 'country' && key.toUpperCase() in territories) {
            return {
                ...accumulator,
                [key]: territories[key.toUpperCase()]
            }
        }

        return {
            ...accumulator,
            [key]: formatMessage({
                id: `chart_label_${i18n}_${key.toLowerCase()}`,
                defaultMessage: key
            })
        }
    }

const colors = {
    generic: [
        'var(--huma-color-chart-categorical-1)',
        'var(--huma-color-chart-categorical-2)',
        'var(--huma-color-chart-categorical-3)',
        'var(--huma-color-chart-categorical-4)',
        'var(--huma-color-chart-categorical-5)',
        'var(--huma-color-chart-categorical-6)',
        'var(--huma-color-chart-categorical-7)',
        'var(--huma-color-chart-categorical-8)',
        'var(--huma-color-chart-categorical-9)',
    ],
    gender: [
        'var(--huma-color-chart-gender-woman)',
        'var(--huma-color-chart-gender-man)',
        'var(--huma-color-chart-gender-other)',
        'var(--huma-color-chart-undefined)'
    ],
    intensity: [
        'var(--huma-color-chart-intensity-subtler)',
        'var(--huma-color-chart-intensity-subtle)',
        'var(--huma-color-chart-intensity-medium)',
        'var(--huma-color-chart-intensity-bold)',
        'var(--huma-color-chart-intensity-bolder)'
    ],
    survey: [
        'var(--huma-color-chart-survey-1)',
        'var(--huma-color-chart-survey-2)',
        'var(--huma-color-chart-survey-3)'
    ],
    unspecified: 'var(--huma-color-chart-undefined)'
}

export const getColor = ({ theme, index, unspecifiedIndex, total }) => {
    if(index === unspecifiedIndex) {
        return colors.unspecified
    }

    if(!theme) {
        return colors.generic[index]
    }

    if(theme === 'gender') {
        return colors.gender[index]
    }

    if(theme === 'survey') {
        return colors.survey[index]
    }

    if(theme === 'intensity') {
        // Spread colors out and maintain contrast when fewer than five data points
        let palette = [...colors.intensity]
        total -= (!!~unspecifiedIndex ? 1 : 0)

        if(total === 4) {
            palette.splice(1, 1)
        } else if(total === 3) {
            palette.splice(4, 1)
            palette.splice(1, 1)
        } else if(total === 2) {
            palette.splice(4, 1)
            palette.splice(0, 2)
        }

        return palette[index]
    }
}
import React, { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import { cls } from 'utilities/dom'
import { useCategoryFormatter } from 'pages/competence/components/category'
import { categoryToMetaMap } from 'pages/competence/constants/category'
import AbsenceStatusLabel from 'pages/absence/components/approval-status'
import DeviationRiskLevel from 'pages/deviation/components/risk-level'
import DeviationCaseStatus from 'pages/deviation/components/case-status'
import {
    EntityItem, EntityColumns,
    IconColumn, Symbol,
    NameColumn, Name, SubName
} from './s'
import { FlexChildShrink } from 'components/flex'
import Ellipsify from 'components/ellipsify'
import Checkbox from 'components/form/input/checkbox'
import { symbol as qualificationSymbolFallback } from 'pages/competence'
import { symbol as equipmentSymbolFallback } from 'pages/equipment'

const Entity = forwardRef(({ entity, type, index, icon: Icon, prepicked, locked, picked, method, toggle, searching, salt }, ref) => {
    const { formatMessage } = useIntl()
    const categoryFormatter = useCategoryFormatter()

    let {
        name,
        emoji = null,
        symbol = null,
        symbolSize = 40,
        subName = '',
        className = ''
    } = entity

    if(type === 'customFieldType') {
        name = entity.label
    }

    if(type === 'competenceType') {
        name = categoryFormatter(entity)

        if(!symbol) {
            symbol = qualificationSymbolFallback
        }

        className = cls([
            className,
            'large'
        ])

        if(entity?.category) {
            subName = categoryToMetaMap[entity.category].title
        }
    }

    if(type === 'equipmentType' && !symbol) {
        symbol = equipmentSymbolFallback
    }

    if(type === 'absenceType') {
        name = formatMessage({
            id: `absence_type_${name}`,
            defaultMessage: name
        })
    }

    if(type === 'absenceStatus') {
        name = <AbsenceStatusLabel status={entity.id} />
    }

    if(type === 'deviationSeverity') {
        name = <DeviationRiskLevel level={entity.id} />
    }

    if(type === 'deviationStatus') {
        name = <DeviationCaseStatus status={entity.id} />
    }

    const isPrepicked = !!prepicked.find(({ id }) => id === entity.id)
    const isLocked = !!locked.find(({ id }) => id === entity.id)
    const isPicked = !!picked.find(({ id }) => id === entity.id)

    const disabled = isLocked ?
        true :
        (method === 'add') ?
            isPrepicked :
            false

    const checked = (method === 'add') ?
        disabled || isPicked :
        isPicked

    return (
        <EntityItem
            className={className}
            ref={ref}>
            <EntityColumns>
                {(!!Icon || !!emoji || !!symbol) && (
                    <IconColumn>
                        {!!Icon && <Icon size={20} />}
                        {emoji}
                        {!!symbol && (
                            <Symbol
                                symbol={symbol}
                                size={symbolSize}
                                className="hover-effect" />
                        )}
                    </IconColumn>
                )}
                <NameColumn>
                    <Name className="compact">
                        {(typeof name === 'string') && <Ellipsify text={name} />}
                        {(typeof name !== 'string') && name}
                    </Name>
                    {!!subName && (
                        <SubName className="compact">
                            <Ellipsify text={formatMessage(subName)} />
                        </SubName>
                    )}
                </NameColumn>
                <FlexChildShrink>
                    <Checkbox
                        id={`${salt}:${entity.id}:toggle`}
                        defaultChecked={checked}
                        autoFocus={!searching && index === 0}
                        locked={isLocked}
                        disabled={disabled}
                        onChange={() => toggle(entity)} />
                </FlexChildShrink>
            </EntityColumns>
        </EntityItem>
    )
})

export default Entity

import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { motion } from 'framer-motion'
import SearchBase from 'components/form/input/text'
import { Rows } from 'components/flex'
import { OrderedList, ListItem } from 'components/list'
import { captionSmallCss } from 'components/typography/caption'
import { Simple } from 'components/button'
import PersonBase from 'components/person'
import OrganizationBase from 'components/organization'
import { IconColumn as OrganizationIconColumn } from 'components/organization/s'
import GroupBase from 'components/group'
import { IconColumn as GroupIconColumn } from 'components/group/s'

export const Container = styled.div`
    position: relative;
`

export const Search = styled(SearchBase)`
    &.loading {
        padding-inline-end: 40px;
    }
`

export const Results = styled(Rows)`
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    overflow: hidden;
    z-index: 10;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    width: 100%;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-high);
`

export const List = styled(OrderedList)`
    max-height: ${({ space }) => space < (5.5 * 40) ? (space - 40) : (5.5 * 40)}px;
    overflow-y: auto;

    ${belowTablet`
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        min-height: 40px;
    `}
`

export const Item = ListItem

export const ItemDivider = styled(ListItem)`
    ${captionSmallCss}

    padding-inline: 12px;

    &:first-child {
        margin-block-start: 4px;
    }

    &:not(:first-child) {
        margin-block-start: 16px;
    }
`

export const Button = styled(Simple)`
    position: relative;

    border: none;
    border-radius: 8px;
    width: 100%;
    min-height: 40px;
    height: auto;
    padding: 0 40px 0 12px;

    white-space: nowrap;
    text-align: left;

    &.active {
        background-color: var(--huma-color-surface-constructive-transparent-hover);
    }

    ${Item}:first-child:not(:last-child) & {
        border-radius: 8px 8px 0 0;
    }

    ${Item}:last-child:not(:first-child) & {
        border-radius: 0 0 8px 8px;
    }

    ${Item}:not(:first-child, :last-child) & {
        border-radius: 0;
    }

    & > * {
        pointer-events: none;
    }
`

export const KeyHintInner = styled(motion.div).attrs(() => ({
    variants: {
        inactive: {
            opacity: 0,
            transition: {
                duration: .1
            }
        },
        active: {
            opacity: 1,
            transition: {
                duration: .2
            }
        }
    },
    initial: 'inactive',
    exit: 'inactive'
}))`
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 100%;

    color: var(--huma-color-foreground-default);

    svg {
        stroke-width: 2;
    }
`

export const Person = styled(PersonBase)`
    max-width: 100%;
`

export const Organization = styled(OrganizationBase)`
    max-width: 100%;
    height: 60px;

    & ${OrganizationIconColumn} {
        margin-inline-end: 16px;
        width: 24px;
    }
`

export const Group = styled(GroupBase)`
    max-width: 100%;

    & ${GroupIconColumn} {
        margin-inline-end: 16px;
        width: 24px;
        height: 24px;
        padding: 4px;

        svg {
            display: block;
        }
    }
`
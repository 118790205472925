import React, { useRef, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Wrap, Message, Caption, Heading, Buttons, EndButtonWrap, Button, ClearEndButton } from './s'
import { DisplayTime } from 'components/form/field/time'
import { ArrowRight, X } from 'styled-icons/feather'

const Hero = ({
    label, message, mode,
    selectedTime, selectedTimeEnd, setSelectedTimeEnd,
    range, pickingTimeEnd, setPickingTimeEnd, allowOpenEnded,
    startDateLocked, endDateLocked,
    setNavigationState
}) => {
    const start = useRef()
    const end = useRef()

    const clearable = allowOpenEnded && selectedTimeEnd

    const EndWrap = clearable ?
        EndButtonWrap :
        Fragment

    return (
        <Wrap>
            {!!label && <Caption>{label}</Caption>}
            {(!label && !!range) && (
                <Caption>
                    <FormattedMessage
                        id="time_picker_range_caption"
                        defaultMessage="Pick time period" />
                </Caption>
            )}
            {!!message && <Message {...message} />}
            {!range && (
                <Heading>
                    {!!selectedTime && (
                        <DisplayTime
                            time={selectedTime?.toISOString()}
                            mode={mode} />
                    )}
                    {!selectedTime && (
                        <FormattedMessage
                            id="time_action_date_pick"
                            defaultMessage="Pick date" />
                    )}
                </Heading>
            )}
            {!!range && (
                <Buttons>
                    <Button
                        className={`constructive${pickingTimeEnd ? ' inactive' : ''}`}
                        onClick={() => {
                            if(selectedTime && !pickingTimeEnd) {
                                setNavigationState(selectedTime)
                            }

                            if(!startDateLocked) {
                                setPickingTimeEnd(false)
                            }

                            start.current.blur()
                        }}
                        autoFocus={!pickingTimeEnd && !startDateLocked}
                        disabled={startDateLocked}
                        ref={start}>
                        {!!selectedTime && (
                            <DisplayTime
                                time={selectedTime?.toISOString()}
                                short={true}
                                mode={mode} />
                        )}
                        {!selectedTime && (
                            <FormattedMessage
                                id="empty_not_set"
                                defaultMessage="Not set" />
                        )}
                    </Button>
                    <ArrowRight size={24} />
                    <EndWrap>
                        <Button
                            className={`constructive${pickingTimeEnd ? '' : ' inactive'}`}
                            onClick={() => {
                                if(selectedTimeEnd && pickingTimeEnd) {
                                    setNavigationState(selectedTimeEnd)
                                }

                                if(!endDateLocked) {
                                    setPickingTimeEnd(true)
                                }

                                end.current.blur()
                            }}
                            autoFocus={pickingTimeEnd && !endDateLocked}
                            disabled={endDateLocked}
                            ref={end}>
                            {!!selectedTimeEnd && (
                                <DisplayTime
                                    time={selectedTimeEnd?.toISOString()}
                                    short={true}
                                    mode={mode} />
                            )}
                            {!selectedTimeEnd && (
                                <FormattedMessage
                                    id="empty_not_set"
                                    defaultMessage="Not set" />
                            )}
                        </Button>
                        {(clearable && !endDateLocked) && (
                            <ClearEndButton
                                className={`constructive${pickingTimeEnd ? '' : ' inactive'}`}
                                onClick={() => {
                                    setSelectedTimeEnd(null)
                                    setPickingTimeEnd(true)
                                }}>
                                <X size={16} />
                            </ClearEndButton>
                        )}
                    </EndWrap>
                </Buttons>
            )}
        </Wrap>
    )
}

export default Hero
import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { H1, H2, H3, H4 } from 'components/typography/heading'
import Paragraph from 'components/typography/paragraph'
import { UnorderedList, OrderedList, ListItem } from 'components/typography/list'
import { Table, TableColumnHeader, TableCell } from 'components/matrix/s'
import Link from 'components/link'
import { Pre, Code, NeutralCode } from 'components/code/s'
import { Divider }  from './s'

const Markdown = props => (
    <ReactMarkdown
        {...props}
        remarkPlugins={[remarkGfm]}
        components={{
            h1: H1,
            h2: H2,
            h3: H3,
            h4: H4,
            h5: H4,
            h6: H4,
            p: Paragraph,
            ol: ({ node, depth, ...props }) => {
                !!depth && (props.className = 'compact')
                return <OrderedList {...props} />
            },
            ul: ({ node, depth, ...props }) => {
                !!depth && (props.className = 'compact')
                return <UnorderedList {...props} />
            },
            li: ListItem,
            table: ({ node, ...props }) => (
                <Table
                    {...props}
                    style={{ marginBottom: '24px' }} />
            ),
            th: TableColumnHeader,
            td: TableCell,
            a: ({ node, ...props }) => (
                <Link
                    {...props}
                    className="constructive"
                    target="_blank" />
            ),
            pre: Pre,
            code: ({ node, inline, ...props }) => {
                const Component = inline ? Code : NeutralCode
                return <Component {...props} />
            },
            hr: Divider
        }} />
)

export default Markdown

import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import ExpandableBase from 'components/expandable'
import Caption from 'components/typography/caption'
import { Grid } from 'components/grid'
import Paragraph from 'components/typography/paragraph'

export const Expandable = styled(ExpandableBase)`
    &:not(:last-child) {
        margin-block-end: 16px;
    }
`

export const Summary = styled.span`
    span:not(:first-child):last-child:before {
        content: "•";

        margin-inline: 4px;
    }
`

export const Description = styled(Caption)`
    margin: 0 0 32px;
`

export const Channel = styled(Grid)`
    grid-template-columns: min-content auto min-content;
    grid-auto-flow: column;
    grid-column-gap: 24px;
    align-items: center;

    &:not(:last-child) {
        margin-block-end: 16px;
        border-bottom: 1px solid var(--huma-color-border-default);
        padding-block-end: 15px;
    }

    svg {
        ${svgStroke()}
    }
`

export const IconWrapper = styled.div`
    &.inactive {
        color: var(--huma-color-foreground-disabled);
    }
`

export const ChannelName = styled(Paragraph)`
    &.inactive {
        text-decoration: line-through;
    }
`
import React, { forwardRef, useState, useEffect, useCallback, useImperativeHandle } from 'react'
import { useSmartEntity } from 'contexts/smart-entity'
import { useItemSearchPhraseEnricher } from './utilities'
// import { jarowinklerDistance } from 'utilities/string-metric'
import { getEntityIcon } from 'components/entity'
import { Container } from 'widgets/context-menu/s'
import { List, Item, Button, ButtonContentRows, ButtonContentRow, KeyHintInner } from './s'
import Ellipsify from 'components/ellipsify'
import { Inline } from 'components/code'
import { AnimatePresence } from 'framer-motion'
import { CornerDownLeft as Enter } from 'styled-icons/feather'
import Fuse from 'fuse.js'

const Suggestions = forwardRef(({ items: { query }, command }, ref) => {
    query = query?.trim() ?? query

    const {
        entity,
        fields
    } = useSmartEntity()

    const enrichItem = useItemSearchPhraseEnricher()

    const [state, setState] = useState({
        items: [],
        fuse: new Fuse([], fuseOptions),
        matches: [],
        selectedIndex: 0
    })

    useEffect(() => {
        setState(state => {
            const items = entity.sources.flatMap(source => fields
                .filter(field => field.type === source.type)
                .map(field => enrichItem({ source, field }))
            )

            state.fuse.setCollection(items)

            return {
                ...state,
                items
            }
        })
    }, [entity.sources, fields, enrichItem])

    useEffect(() => {
        setState(state => ({
            ...state,
            matches: query ?
                state.fuse.search(query) :
                [],
            selectedIndex: 0
        }))
    }, [state.items, query])

    const previous = useCallback(() => setState(state => ({
        ...state,
        selectedIndex: (state.selectedIndex + state.matches.length - 1) % state.matches.length
    })), [])

    const next = useCallback(() => setState(state => ({
        ...state,
        selectedIndex: (state.selectedIndex + 1) % state.matches.length
    })), [])

    const pick = useCallback(({ item }) => command(item), [query])

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            const navigateOrPick = ['ArrowUp', 'ArrowDown', 'Enter'].includes(event.key)

            if(navigateOrPick) {
                event.stopPropagation()
                event.preventDefault()
            }

            if(event.key === 'ArrowUp') {
                previous()
            }

            if(event.key === 'ArrowDown') {
                next()
            }

            if(event.key === 'Enter') {
                pick(state.matches[state.selectedIndex])
            }

            return navigateOrPick
        }
    }), [previous, next, pick, state.matches, state.selectedIndex])

    if(!query || !state.matches.length) {
        return null
    }

    return (
        <Container
            className="boxy"
            ref={ref}>
            <List>
                {state.matches.map(({ item }, index) => {
                    const active = index === state.selectedIndex
                    const Icon = getEntityIcon(item.source.type, {
                        organization: 'company'
                    })

                    return (
                        <Item key={`variable:suggestion:${item.source.name}.${item.field.name}`}>
                            <Button
                                onClick={() => pick({ item })}
                                className={`neutral${active ? ' active' : ''}`}
                                tabIndex={-1}>
                                <Icon size={16} />
                                <ButtonContentRows>
                                    <ButtonContentRow>
                                        <Ellipsify text={`${item.label.source}: ${item.label.field}`} />
                                    </ButtonContentRow>
                                    <ButtonContentRow>
                                        <Inline className="variable">
                                            <Ellipsify text={`$${item.sourceDisplayName}.${item.field.name}`.replace('.custom', '')} />
                                        </Inline>
                                    </ButtonContentRow>
                                </ButtonContentRows>
                                {!!active && (
                                    <AnimatePresence>
                                        <KeyHintInner animate={active ? 'active' : 'inactive'}>
                                            <Enter size={16} />
                                        </KeyHintInner>
                                    </AnimatePresence>
                                )}
                            </Button>
                        </Item>
                    )
                })}
            </List>
        </Container>
    )
})

const fuseOptions = {
    keys: [
        {
            name: 'indexing.compoundLabel',
            weight: 2
        },
        {
            name: 'suggestions.label.field',
            weight: 1
        },
        {
            name: 'suggestions.label.source',
            weight: 1
        },
        {
            name: 'indexing.words',
            weight: .5
        }
    ],
    includeMatches: true,
    minMatchCharLength: 3,
    findAllMatches: true,
    threshold: .5
}

export default Suggestions
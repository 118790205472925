import React, { useRef, createRef, Fragment } from 'react'
import { useIntl } from 'react-intl'
import { Container, AvatarColumn, Avatar, Rest } from './s'
import { Deactivated } from 'components/person/s'
import Tooltip, { useSingleton } from 'components/tooltip'
import { sort } from 'utilities/people'
import { cls } from 'utilities/dom'
import { getFullName } from 'utilities/person'

const Avatars = ({
    people = [],
    max = 16,
    size = 32,
    sortPeople = true,
    compact = false,
    showRest = true,
    showTooltip = true,
    className,
    salt, itemSalts
}) => {
    const {
        formatMessage,
        locale
    } = useIntl()

    const [source, target] = useSingleton()

    const avatarRefs = useRef([])
    avatarRefs.current = people.map((_, index) => avatarRefs.current[index] ?? createRef())

    if(!people.length) {
        return null
    }

    if(sortPeople) {
        people = sort(locale)(people)
    }

    let cappedPeople = people.slice(0, max)

    let rest = people.length - cappedPeople.length
    if(rest === 1) {
        cappedPeople = people
        rest = 0
    }

    className = cls([
        className,
        !!rest && 'capped'
    ])

    return (
        <Container {...(className ? { className } : null)}>
            {!!showTooltip && <Tooltip singleton={source} />}
            {cappedPeople.map((person, index) => {
                const itemSalt = itemSalts?.[index]

                const isDeactivated = person?.status?.active === false

                const [AvatarWrapper, avatarWrapperProps = null] = !!showTooltip ?
                    [Tooltip, {
                        singleton: target,
                        content: getFullName(person)
                    }] :
                    [Fragment]

                return (
                    <AvatarColumn
                        key={`${salt}:avatars:${person.id}${itemSalt ? `:${itemSalt}` : ''}`}
                        $compact={!compact ? false : size}>
                        {(isDeactivated && !!showTooltip) && (
                            <Tooltip
                                content={formatMessage({
                                    id: 'person_status_deactivated',
                                    defaultMessage: 'This account has been deactivated'
                                })}>
                                <Deactivated size={size} />
                            </Tooltip>
                        )}
                        <AvatarWrapper {...avatarWrapperProps}>
                            <Avatar
                                who={person}
                                size={size}
                                hasTooltip={true}
                                ref={avatarRefs.current[index]} />
                        </AvatarWrapper>
                    </AvatarColumn>
                )
            })}
            {(showRest && !!rest) && <Rest className={(size <= 16) ? 'small' : 'normal'}>+{rest}</Rest>}
        </Container>
    )
}

export default Avatars
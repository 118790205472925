import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { fontMonospace } from 's'
import { Columns, FlexChild, FlexChildShrink } from 'components/flex'

export const Container = styled(Columns)`
    position: relative;

    align-items: center;

    margin: 0 0 24px;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    padding: 12px 16px;
    background-color: var(--huma-color-surface-minimal);

    color: var(--huma-color-foreground-subtle);

    ${belowTablet`
        flex-direction: column;
        align-items: flex-start;
    `}
`

export const Paragraph = styled(FlexChild).attrs({ as: 'p' })`
    max-width: 100%;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    &.truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ${Container}.code & {
        ${fontMonospace}

        font-size: 14px;
    }
`

export const Copy = styled(FlexChildShrink)`
    margin-inline-start: 24px;

    ${belowTablet`
        align-self: flex-end;

        margin: 0;
    `}
`

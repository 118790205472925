import React, { Component } from 'react'
import { useForm } from 'components/form/controller'
import isEqual from 'react-fast-compare'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { Field, Label, Control, ControlMainColumn } from 'components/form/field/s'
import Tiptap, { empty } from 'components/tiptap'
import CharacterCount from './character-count'
import { Error, Columns } from './s'

class EditEditor extends Component {
    constructor(props) {
        super(props)

        this.state = this.valueStateFromProps(props)
        this.register()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    omponentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required,
            include,
            min,
            max
        } = getFieldFromProps(this.props)

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: this.unset,

            validator: (value, meta = {}) => {
                if(!value) {
                    return !required
                }

                if(min > 0 && meta.characters < min) {
                    return false
                }

                if(max < Infinity && meta.characters > max) {
                    return false
                }

                return true
            }
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value ?? empty
    })

    onChange = ({ value = empty, meta }) => {
        this.setState({ value, meta })
        this.props.form.triggerChange(this.props.name, { meta })

        this.props.onChange?.({ [this.props.name]: value }, { meta })
    }

    unset = () => void this.setState({ value: empty }, () => {
        this.props.form.triggerChange(this.props.name, { meta: null })
        this.props.onChange?.({ [this.props.name]: empty })
        this.props.onUnset?.()
    })

    render() {
        const {
            value,
            meta
        } = this.state

        const {
            className,
            editor = {},
            controlProps = {},
            controlContent = null,
            salt,
            label,
            name,
            enabled = true,
            loading = false,
            display,
            error: outsideError,
            children = null,
            ...props
        } = this.props

        const touched = this.props.form.touched.includes(name)
        const error = ((name in this.props.form.errors) && touched) || !!outsideError

        const {
            required,
            softRequired,
            optional,
            max,
            showCharacterCount
        } = getFieldFromProps(this.props)

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const controlClassName = cls([
            controlProps.className,
            display
        ])

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                <input
                    type="hidden"
                    name={name}
                    value={JSON.stringify(value)} />
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <Columns>
                        <ControlMainColumn>
                            <Tiptap
                                {...editor}
                                configure={{
                                    ...(editor?.configure ?? null),
                                    ...((max < Infinity) && {
                                        CharacterCount: {
                                            limit: max
                                        }
                                    })
                                }}
                                {...controlProps}
                                {...(controlClassName ? { className: controlClassName } : null)}
                                {...omit(props, 'field', 'form', 'whistle')}
                                name={name}
                                value={value}
                                id={salt}
                                onCreate={({ content, meta }) => {
                                    this.props.onCreate?.({ content, meta })
                                    this.setState({ meta })
                                }}
                                onUpdate={({ content, meta }) =>
                                    this.props.onUpdate?.({ content, meta })
                                }
                                onChange={this.onChange}
                                disabled={!enabled}
                                salt={salt}>
                                {!!showCharacterCount && (
                                    <CharacterCount
                                        meta={meta}
                                        max={max} />
                                )}
                                {children}
                            </Tiptap>
                            <Error animate={outsideError ? 'reveal' : 'hide'}>
                                {outsideError}
                            </Error>
                        </ControlMainColumn>
                    </Columns>
                    {controlContent}
                </Control>
            </Field>
        )
    }
}

const getFieldFromProps = ({ field = {}, controlProps = {} }) => {
    let {
        value,
        required = false,
        softRequired = false,
        include = 'touched',
        showCharacterCount = false,
        showWordCount = false,
        ...rest
    } = field

    let {
        min = 0,
        max = Infinity,
    } = controlProps

    if(!value) {
        value = empty
    }

    return {
        ...rest,
        value,
        required,
        softRequired,
        include,
        showCharacterCount,
        showWordCount,
        min,
        max
    }
}

export default props => {
    const form = useForm()

    return (
        <EditEditor
            {...props}
            form={form} />
    )
}
import React from 'react'
import { getEntityIcon } from 'components/entity'
import { EntityWrapper } from './s'

const Entity = ({ type, name }) => {
    const Icon = getEntityIcon(type, { organization: 'company' })

    return (
        <EntityWrapper>
            <Icon size={16} />
            <span>{name}</span>
        </EntityWrapper>
    )
}

export default Entity
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useSort } from 'hooks/sort'
import { useDomains } from 'contexts/domains'
import { useTheme, ThemeProvider } from 'styled-components'
import { getBoundActions } from 'widgets/context-menu'
import { Wrap, Header, LoadingContainer, GridColumnHeaderSortable, GridColumnActionsHeader } from './s'
import { Static as Empty } from 'components/empty'
import Loader from 'components/loader'
import Domain from './domain'

const Domains = ({ actions, header = false, upgradable, className }) => {
    const { formatMessage } = useIntl()

    const {
        flash,
        clearFlash,
        verifying,
        hasFetched,
        ...domainsContext
    } = useDomains()

    let theme = useTheme()

    const [domains, sort, setSortField] = useSort({
        items: domainsContext.domains ?? [],
        fields: {
            name: {
                method: 'compare',
                getValue: ({ name }) => name,
                order: 'asc'
            },
            verified: {
                method: 'simple',
                getValue: ({ verified }) => !verified,
                order: 'desc'
            },
        },
        defaultField: 'name',
        storageKey: 'list:domains:sort',
        makeSalt: items => items.map(({ name }) => name).join()
    })

    if(!hasFetched && !domainsContext.domains.length) {
        return (
            <LoadingContainer>
                <Loader />
            </LoadingContainer>
        )
    }

    if(hasFetched && !domainsContext.domains.length) {
        return (
            <Empty>
                <FormattedMessage
                    id="domains_empty"
                    defaultMessage="You can allow people to signup using your company’s email domain.\n\nAdd your domain to get started." />
            </Empty>
        )
    }

    const hasActions = !!getBoundActions(actions, {}).length

    theme = {
        ...theme,
        domains: {
            actions: hasActions
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Wrap {...(className ? { className } : null)}>
                {!!header && (
                    <Header>
                        <GridColumnHeaderSortable
                            field="name"
                            sort={sort}
                            toggle={setSortField}
                            text={formatMessage({
                                id: 'noun_domain',
                                defaultMessage: 'Domain'
                            })} />
                        <GridColumnHeaderSortable
                            field="verified"
                            sort={sort}
                            toggle={setSortField}
                            text={formatMessage({
                                id: 'noun_status',
                                defaultMessage: 'Status'
                            })} />
                        {hasActions && <GridColumnActionsHeader />}
                    </Header>
                )}
                {domains.map(domain => (
                    <Domain
                        domain={domain}
                        actions={actions}
                        verifying={verifying === domain.name}
                        upgradable={upgradable}
                        flash={flash?.current === domain ? clearFlash : null}
                        key={`domains:${domain.name}`} />
                ))}
            </Wrap>
        </ThemeProvider>
    )
}

export default Domains

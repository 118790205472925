import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { RecentSearches } from '../s'
import LinkBase from 'components/link'

export const Topic = styled.div`
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    column-gap: 24px;
    padding-block: 12px;
    box-shadow: 0 1px var(--huma-color-border-default);

    ${RecentSearches}.small & {
        column-gap: 16px;
        padding-block: 4px;
    }

    svg {
        ${svgStroke()}
    }
`

export const Link = styled(LinkBase)`
    display: flex;
    column-gap: 24px;
    align-items: center;
    height: 100%;
    padding-block: 8px;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
        text-decoration: none;

        > span {
            text-decoration: underline;
        }
    }

    ${RecentSearches}.small & {
        column-gap: 16px;
        line-height: 1.5;
    }
`
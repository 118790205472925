import styled, { css } from 'styled-components'
import {
    svgStroke, flashKeyframes,
    belowTablet
} from 'utilities/styled'
import { Grid } from 'components/grid'
import Link from 'components/link'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import { ancestorHover as avatarAncestorHover } from 'components/avatar/s'
import Paragraph from 'components/typography/paragraph'

export const eventItemGrid = css`
    grid-template-columns: minmax(0, 2fr) 0.5fr 1.5fr 40px;
`

export const EventItem = styled(Grid)`
    ${eventItemGrid}
    column-gap: 24px;

    width: 100%;
    padding: 24px 0;
    box-shadow: 0 -1px var(--huma-color-border-default);

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }

    ${belowTablet`
        grid-template-columns: 1fr 40px;
        grid-auto-flow: row;
        grid-auto-rows: auto;
        row-gap: 8px;
        column-gap: 16px;
    `}
`

const cellCss = css`
    display: grid;
    align-items: center;
`

export const MeetingCell = styled.div`
    ${cellCss}
`

const meetingWrapperCss = css`
    display: grid;
    grid-template-columns: 40px auto;
    column-gap: 16px;
`

export const MeetingFragment = styled.div`
    ${meetingWrapperCss}
`

export const MeetingLink = styled(Link)`
    ${meetingWrapperCss}

    color: var(--huma-color-foreground-default);
    text-decoration: none;

    ${avatarAncestorHover()}

    &:is(:hover,:focus) {
        text-decoration: none;
    }
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(MeetingLink)}
`

export const Title = styled(Paragraph)`
    ${MeetingLink}:is(:hover, :focus) & {
        text-decoration: underline;
    }
`

export const Meta = styled.div`
    display: grid;
    grid-auto-rows: auto;
`

export const StatusCell = styled.div`
    ${cellCss}

    align-items: start;

    ${belowTablet`
        grid-row: 3 / 4;
        grid-column: 1 / 3;

        padding-inline-start: 56px;
    `}
`

export const DateCell = styled.div`
    ${cellCss}
    display: flex;

    align-items: start;

    font-size: 14px;
    line-height: 24px;
    color: var(--huma-color-foreground-subtle);

    button {
        border: 0;
    }

    & > :not(:last-child) {
        position: relative;

        margin-inline-end: 12px;

        &:after {
            /* Can't use content: "·", since button:after will inherit text-underline, which you can't override 🤪 */
            content: "";
            position: absolute;
            top: 10px;
            right: -7px;

            border-radius: 50%;
            width: 2px;
            height: 2px;
            background-color: var(--huma-color-foreground-default);
        }
    }

    ${belowTablet`
        grid-row: 2 / 3;
        grid-column: 1 / 3;

        padding-inline-start: 56px;
    `}
`

export const ActionsCell = styled.div`
    justify-content: center;

    margin-top: -8px;

    ${belowTablet`
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    `}

    svg {
        ${svgStroke()}
    }
`

import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Info } from 'styled-icons/feather'

export const tooltipWrapperCss = css`
    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    padding: 8px;
    background-color: var(--huma-color-surface-elevated);
    box-shadow: var(--huma-shadow-low);

    color: var(--huma-color-foreground-default);
`

export const Wrapper = styled.div`
    ${tooltipWrapperCss}

    position: relative;

    transition-property: transform, visibility, opacity;

    white-space: normal;
    overflow-wrap: anywhere;
    font-size: 14px;
    line-height: 24px;

    &.widget {
        border: unset;
        padding: unset;
        background: unset;
        box-shadow: unset;
    }

    &.preserve-line-breaks {
        white-space: pre-wrap;
    }

    &[data-animation=fade][data-state=hidden] {
        opacity: 0;
    }
`

export const TriggerWrapper = styled.span`
    display: inline-block;

    &:where(:not(.naked)) {
        vertical-align: top;

        padding-inline: 4px;

        line-height: ${p => p.$lineHeight ?? 20}px;
    }

    ${p => !!p.onClick && `
        cursor: pointer;
    `}

    &.relative {
        position: relative;
    }

    &.info,
    &.action {
        color: var(--huma-color-foreground-info-bold);
    }

    &.warning {
        color: var(--huma-color-foreground-warning);
    }

    &.error {
        color: var(--huma-color-foreground-error);
    }
`

export const TriggerIcon = styled(Info)`
    line-height: 16px;
    transition: color .15s ease-in-out;

    &:hover {
        color: var(--huma-color-foreground-constructive-bold);
    }

    ${svgStroke('small')}
`

// export const Tippy = styled(TippyBase)`
//     .tippy-box[data-animation=fade][data-state=hidden] {
//         opacity:0;
//     }

//     [data-tippy-root] {
//         max-width: calc(100vw - 10px);
//     }

//     .tippy-box{
//         position: relative;
//         background-color: #333;
//         color: #fff;
//         border-radius: 4px;
//         font-size: 14px;
//         line-height: 1.4;
//         white-space: normal;
//         outline: 0;
//         transition-property: transform, visibility, opacity;
//     }

//     .tippy-box[data-placement^=top] > .tippy-arrow{
//         bottom: 0;
//     }

//     .tippy-box[data-placement^=top] > .tippy-arrow::before {
//         bottom: -7px;
//         left: 0;
//         border-width: 8px 8px 0;
//         border-top-color: initial;
//         transform-origin: center top;
//     }

//     .tippy-box[data-placement^=bottom] > .tippy-arrow{
//         top: 0;
//     }

//     .tippy-box[data-placement^=bottom] > .tippy-arrow::before {
//         top: -7px;
//         left: 0;
//         border-width: 0 8px 8px;
//         border-bottom-color: initial;
//         transform-origin: center bottom;
//     }

//     .tippy-box[data-placement^=left] > .tippy-arrow {
//         right: 0;
//     }

//     .tippy-box[data-placement^=left] > .tippy-arrow::before {
//         border-width: 8px 0 8px 8px;
//         border-left-color: initial;
//         right: -7px;
//         transform-origin: center left;
//     }

//     .tippy-box[data-placement^=right] > .tippy-arrow {
//         left: 0;
//     }

//     .tippy-box[data-placement^=right] > .tippy-arrow::before {
//         left: -7px;
//         border-width: 8px 8px 8px 0;
//         border-right-color: initial;
//         transform-origin: center right;
//     }

//     .tippy-box[data-inertia][data-state=visible] {
//         transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
//     }

//     .tippy-arrow {
//         width: 16px;
//         height: 16px;
//         color: #333;
//     }

//     .tippy-arrow::before {
//         content: "";
//         position: absolute;
//         border-color: transparent;
//         border-style: solid;
//     }

//     .tippy-content {
//         position:
//         relative;
//         padding: 5px 9px;
//         z-index: 1;
//     }
// `
const updateClassName = (box, action, classNames) => {
    classNames.split(/\s+/).forEach(className => {
        if(className) {
            box.classList[action](className)
        }
    })
}

export const classNamePlugin = {
    name: 'className',
    defaultValue: '',
    fn: instance => {
        const box = instance.popper.firstElementChild
        const isDefaultRenderFunction = () => !!instance.props.render?.$$tippy

        const add = () => {
            if(instance.props.className && !isDefaultRenderFunction()) {
                return
            }

            updateClassName(box, 'add', instance.props.className)
        }

        const remove = () => {
            if(isDefaultRenderFunction()) {
                updateClassName(box, 'remove', instance.props.className)
            }
        }

        return {
            onCreate: add,
            onBeforeUpdate: remove,
            onAfterUpdate: add
        }
    }
}
import React from 'react'
import { File as Default, Image, Music, Video, FileText } from 'styled-icons/feather'
import { SmartDocument, SmartDocumentTemplate } from 'components/feather'
import { Excel, Pdf, Powerpoint, Word } from 'components/icon'
import { Keynote, FileZip as Archive } from 'styled-icons/remix-line'
import { cls } from 'utilities/dom'

const FileIcon = ({ extension, className, ...props }) => {
    let Icon = Default
    let feather = true

    if(extension === 'smart') {
        Icon = SmartDocument
    }

    if(extension === 'smart-template') {
        Icon = SmartDocumentTemplate
    }

    if(extension?.match(/(jpg|jpeg|png|gif|tiff|bmp|webp|svg|ai|ps|fig|sketch)/)) {
        Icon = Image
    }

    if(extension?.match(/(aac|ape|flac|m4a|mp3|oga|mogg|wav|wma)/)) {
        Icon = Music
    }

    if(extension?.match(/(webm|mkv|flv|ogv|gifv|avi|mpg|mpeg|mov|wmv|m4v|3gp|mp4)/)) {
        Icon = Video
    }

    if(extension?.match(/(xls|xlsx)/)) {
        Icon = Excel
        feather = false
    }

    if(extension?.match(/(doc|docx)/)) {
        Icon = Word
        feather = false
    }

    if(extension?.match(/(ppt|pptx)/)) {
        Icon = Powerpoint
        feather = false
    }

    if(extension?.match(/(pages|txt|log|md|csv|pax|xml)/)) {
        Icon = FileText
    }

    if(extension === 'key') {
        Icon = Keynote
        feather = false
    }

    if(extension === 'pdf') {
        Icon = Pdf
        feather = false
    }

    if(extension?.match(/(rar|tar|zip|7z)/)) {
        Icon = Archive
        feather = false
    }

    className = cls([
        className,
        feather && 'feather'
    ])

    return (
        <Icon
            {...props}
            {...(className ? { className } : null)} />
    )
}

export default FileIcon
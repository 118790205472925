import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMeetingRounds } from 'contexts/meeting-rounds'
import {
    Wrap, Header,
    GridColumnHeader,
    SkeletonListRow, SkeletonCell, SkeletonProgressCell,
    LoadingContainer, Tutorial
} from './s'
import { SkeletonSymbolAndMeta, SkeletonCircle, SkeletonStrings } from 'components/skeleton'
import Round from './round'
import Loader from 'components/loader'
import { Ghost } from 'components/button'
import { clamp } from 'utilities/math'

const MeetingRounds = ({ header = false, className }) => {
    const {
        rounds,
        intersecter,
        loading,
        paging = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched,
        flash,
        clearFlash
    } = useMeetingRounds()

    const [skeletonLength, setSkeletonLength] = useState(5)

    const { hasNextPage } = paging

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(rounds.length, 1, 10))
        }
    }, [hasFetched, rounds?.length])

    return (
        <Wrap {...(className ? { className } : null)}>
            {(header || fetching) && (
                <Header>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="noun_name"
                            defaultMessage="Name" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="noun_status"
                            defaultMessage="Status" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="noun_due_date"
                            defaultMessage="Due date" />
                    </GridColumnHeader>
                </Header>
            )}
            {(!rounds.length && hasFetched) && (
                <Tutorial which="meetings" />
            )}
            {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                <SkeletonListRow
                    columns={3}
                    key={`list:meeting:rounds:skeleton:${index}`}>
                    <SkeletonCell>
                        <SkeletonSymbolAndMeta
                            size={40}
                            showSecondLine={true} />
                    </SkeletonCell>
                    <SkeletonProgressCell>
                        <SkeletonCircle size={24} />
                        <SkeletonStrings
                            size={16}
                            length={10} />
                    </SkeletonProgressCell>
                    <SkeletonCell>
                        <SkeletonStrings
                            size={16}
                            length={12} />
                    </SkeletonCell>
                </SkeletonListRow>
            ))}
            {rounds.map((round, index) => {
                const last = index + 1 === rounds.length

                return (
                    <Round
                        round={round}
                        {...(last ? { ref: intersecter } : null)}
                        flash={flash?.current === round ? clearFlash : null}
                        key={round.id} />
                )
            })}
            {!!hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost
                            className="constructive"
                            onClick={fetch}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
        </Wrap>
    )
}

export default MeetingRounds
import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import AvatarsBase from 'components/avatars'
import { empty } from './'

const Avatars = styled(AvatarsBase)`
    height: 40px;
`

const ViewPeople = ({ className, label, salt, ...props }) => {
    const {
        name,
        field = {},
        picker = {}
    } = props

    const { locked = empty } = picker

    let { value } = field
    if(!value) {
        value = empty
    }

    const people = [...locked, ...value]
    salt = `${salt}:${name}`

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                {!!people.length && (
                    <Avatars
                        people={people}
                        max={5}
                        salt={salt} />
                )}
                {!people && (
                    <ValueDisplay className="empty">
                        <FormattedMessage
                            id="empty_none_picked"
                            defaultMessage="None picked" />
                    </ValueDisplay>
                )}
            </Control>
        </Field>
    )
}

export default ViewPeople

import React from 'react'
import { cls } from 'utilities/dom'
import { Wrap } from './s'

const Badge = ({ number, inline = false, className, disabled = false, ...props }) => {
    className = cls([
        className,
        (!number || disabled) && 'inactive',
        number.length > 2 && 'small'
    ])

    return (
        <Wrap
            {...props}
            {...(inline ? { as: 'span' } : null)}
            {...(className ? { className } : null)}>
            {number}
        </Wrap>
    )
}

export default Badge
import styled, { css } from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { compact } from 'utilities/array'
import { Grid } from 'components/grid'

export const assigneeGridCss = css`
    ${({ theme: { policyAssignees: { actions } } }) => css`
        grid-template-columns: ${compact(['1.5fr', '1fr', '1fr', actions && 'minmax(0, 1fr)']).join(' ')};
        column-gap: 24px;

        ${belowTablet`
            grid-template-columns: auto 40px;
            grid-auto-flow: row;
            grid-auto-rows: auto;
            row-gap: 16px;
        `}
    `}
`

export const Assignee = styled(Grid)`
    ${assigneeGridCss}
    align-items: start;

    width: 100%;
    padding: 16px 0;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }
`

const cellCss = css`
    display: grid;
    align-items: center;
    grid-auto-flow: column;

    ${belowTablet`
        grid-column: 1 / -1;
    `}
`

export const PersonCell = styled.div`
    ${cellCss}

    ${belowTablet`
        grid-column: 1 / 2;
    `}
`

export const Cell = styled.div`
    ${cellCss}

    ${belowTablet`
        display: none;
    `}
`

export const ActionsCell = styled.div`
    ${cellCss}
    justify-content: end;

    ${belowTablet`
        grid-row: 1 / 2;
        grid-column: 2 / -1;
    `}
`
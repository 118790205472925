import React from 'react'
import { useIntl } from 'react-intl'
import { isFuture, isWithinInterval, endOfDay, add, differenceInDays } from 'date-fns'
import BoxMessage, { InlineMessage } from 'components/message'
import { isofy } from 'utilities/date-time'
import { isSameDay } from 'date-fns'

const ExpiryStatus = ({
    from = null, to, requireEnd = false,
    type = 'inline', showActive = true, context = 'expiration',
    ...props
}) => {
    const { formatMessage } = useIntl()

    const Message = type === 'inline' ?
        InlineMessage :
        BoxMessage

    const expiration = getExpiration({ from, to, requireEnd, showActive })

    if(!expiration || !getTranslation(context, expiration.status)) {
        return null
    }

    return (
        <Message
            {...props}
            type={expiration.type}
            message={formatMessage(getTranslation(context, expiration.status), expiration?.data)} />
    )
}

export const getExpiration = ({ from = null, to, requireEnd = false, showActive = true }) => {
    if(!to && (!showActive || !!requireEnd)) {
        return null
    }

    const now = new Date()

    from = isofy(from)
    to = isofy(to)

    if(!!from && isFuture(from) && showActive) {
        return {
            type: 'info',
            status: 'not_active'
        }
    }

    if(isWithinInterval(endOfDay(to), {
        start: now,
        end: add(now, { days: 90 })
    })) {
        if(isSameDay(to, now)) {
            return {
                type: 'warning',
                status: 'expiring_today'
            }
        }

        return {
            type: 'warning',
            status: 'expiring',
            data: { days: differenceInDays(endOfDay(to), now) }
        }
    }

    if(!!from && !!to && to > from && showActive) {
        if(isWithinInterval(now, {
            start: from,
            end: to
        })) {
            return {
                type: 'success',
                status: 'active'
            }
        }
    }

    if(!!to && !isFuture(endOfDay(to))) {
        return {
            type: 'error',
            status: 'expired'
        }
    }

    return null
}

const getTranslation = (context, status) => ({
    expiration: {
        not_active: {
            id: 'expiration_status_not_active',
            defaultMessage: 'Not yet active'
        },
        active: {
            id: 'expiration_status_active',
            defaultMessage: 'Active'
        },
        expiring: {
            id: 'expiration_status_expiring',
            defaultMessage: 'Expiring in {days, plural, =0 {} =1 {one day} other {# days}}'
        },
        expiring_today: {
            id: 'expiration_status_expiring_today',
            defaultMessage: 'Expiring today'
        },
        expired: {
            id: 'expiration_status_expired',
            defaultMessage: 'Expired'
        }
    },
    competence: {
        active: {
            id: 'qualification_status_active',
            defaultMessage: 'Active'
        },
        expiring: {
            id: 'qualification_status_expiring',
            defaultMessage: 'Expiring in {days, plural, =0 {} =1 {one day} other {# days}}'
        },
        expiring_today: {
            id: 'qualification_status_expiring_today',
            defaultMessage: 'Expiring today'
        },
        expired: {
            id: 'qualification_status_expired',
            defaultMessage: 'Expired'
        }
    }
})[context][status]

export default ExpiryStatus
import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { CloseButton as CloseButtonBase } from 'components/button'

export const Wrapper = styled.div`
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;

    border-radius: 8px;
    background-color: color-mix(in srgb, var(--huma-color-surface-elevated), transparent 30%);
    backdrop-filter: blur(8px);
`

export const Image = styled.img`
    width: min(80%, var(--__huma-component-image-preview-size, 1000px));
    height: min(80%, var(--__huma-component-image-preview-size, 1000px));
    object-fit: contain;

    ${belowPhone`
        width: auto;
        max-width: 100%;
        height: auto;
    `}
`

export const CloseButton = styled(CloseButtonBase)`
    position: absolute;
    top: 16px;
    right: 16px;
`
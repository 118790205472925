import styled, { css } from 'styled-components'
import {
    svgStroke,
    belowPhone, belowTablet
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { Columns } from 'components/flex'
import PersonBase from 'components/person'
import { ancestorHover } from 'components/avatar/s'

export const PersonWrapper = styled(Grid)`
    position: relative;
    column-gap: 24px;
    width: 100%;
    padding: 24px 0;
    box-shadow: 0 -1px var(--huma-color-border-default);

    ${p => !!p.$centered && css`
        align-items: center;
    `}

    ${({ theme: { people: { actions, header } } }) => {
        if(actions || header) {
            return css`
                grid-template-columns: minmax(0, 2fr) repeat(2, minmax(0, 1fr)) 40px;
            `
        }
        return css`
            grid-template-columns: minmax(0, 2fr) repeat(2, minmax(0, 1fr));
        `
    }}

    ${belowPhone`
        grid-gap: 8px 16px;
        padding: 16px 0;

        ${({ theme: { people: { actions, header } } }) => {
            if(actions || header) {
                return css`
                    grid-template-columns: 1fr 40px;
                `
            }
            return css`
                grid-template-columns: 1fr;
            `
        }}

        ${p => !!p.$simple && css`
            grid-template-rows: 1fr;
        `}
    `}

    svg {
        ${svgStroke()}
    }
`

export const EntitiesContainer = styled.div`
    max-width: 100%;
    position: relative;
    overflow: hidden;

    > * {
        max-width: 100%;
        position: relative;
        overflow-x: auto;
    }
`

export const PersonCell = styled(Columns)`
    flex-direction: column;
    align-items: flex-start;

    max-width: 100%;
`

export const Person = styled(PersonBase)`
    ${ancestorHover()}
`

export const Cell = styled(Columns)`
    flex-direction: column;
    align-items: flex-start;

    ${belowPhone`
        display: none;
    `}
`

export const ActionsCell = styled(Columns)`
    ${belowPhone`
        order: 2;
    `}

    .actions-button {
        margin-block: -8px;

        ${belowTablet`
            margin: 0;
        `}
    }
`
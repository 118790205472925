import React, { forwardRef } from 'react'
import View from './view'
import Edit from './edit'

const TextField = forwardRef(({ field = {}, salt, name, mode = 'edit', ...props }, ref) => {
    const { editable = true } = field

    props = {
        ...props,
        field,
        name,
        salt: `${salt}:${name}`
    }

    if(mode === 'edit' && editable) {
        return (
            <Edit
                {...props}
                ref={ref} />
        )
    }

    return <View {...props} />
})

export default TextField

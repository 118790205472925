import React, { useState, useEffect } from 'react'
import { useEmailVerification } from 'hooks/email-verification'
import { useAuth } from 'contexts/auth'
import { bounceUser } from 'utilities/auth'
import Modal from 'modals/generic'
import Content from './content'

const EmailVerification = ({ onResolve }) => {
    const meta = useEmailVerification()
    const auth = useAuth()

    const [show, setShow] = useState(false)
    const [authorized, setAuthorized] = useState(false)

    useEffect(() => {
        setAuthorized(auth?.status === 'authorized')
    }, [auth])

    useEffect(() => {
        !!meta && setShow(true)
    }, [meta])

    const dismiss = (options = {}) => {
        const { cancel = false } = options

        setShow(false)

        if(!cancel && !meta?.error) {
            bounceUser()
        }
    }

    return (
        <Modal
            show={show}
            dismiss={dismiss}>
            <Content
                meta={meta}
                authorized={authorized}
                onResolve={onResolve}
                dismiss={dismiss} />
        </Modal>
    )
}

export default EmailVerification
import React, { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import { promptify, Prompt, Container } from 'utilities/prompt'
import { cls } from 'utilities/dom'
import { size } from 'utilities/object'
import { Heading } from './s'
import Paragraph from 'components/typography/paragraph'
import Form from 'components/form/controller'
import Divider from 'components/divider'
import Acknowledge from 'components/acknowledge'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const StandardPrompt = ({ className, salt, ...prompt }) => {
    const { formatMessage } = useIntl()

    const {
        show = false,
        question = formatMessage({
            id: 'prompt_question',
            defaultMessage: 'Are you sure?'
        }),
        message = null,
        children = null,
        acknowledge = null,
        cancel,
        confirm,
        loading = false
    } = prompt

    if(!show) {
        return null
    }

    const submit = () => {
        onConfirm()
        onCancel()
    }

    const {
        text: cancelText = formatMessage({
            id: 'action_cancel',
            defaultMessage: 'Cancel'
        }),
        callback: onCancel
    } = cancel

    const {
        text: confirmText = formatMessage({
            id: 'action_continue',
            defaultMessage: 'Continue'
        }),
        callback: onConfirm,
        effect = 'neutral'
    } = confirm

    return promptify(
        <Prompt
            cancel={onCancel}
            key={`prompt:standard:${salt}`}>
            <Container {...(className ? { className } : null)}>
                <Heading>{question}</Heading>
                {!!message && (
                    <Paragraph className="preserve-line-breaks">{message}</Paragraph>
                )}
                {children}
                <Form
                    layout="vertical"
                    onSubmit={submit}>
                    {({ errors, trigger }) => (
                        <>
                            {acknowledge && (
                                <>
                                    <Divider />
                                    <Acknowledge
                                        {...acknowledge}
                                        salt={`${salt}:acknowledge`} />
                                </>
                            )}
                            <Actions className={cls([
                                'compact',
                                !confirm && 'centered'
                            ])}>
                                <Plain
                                    className="neutral"
                                    onClick={onCancel}
                                    autoFocus={!acknowledge}>
                                    {cancelText}
                                </Plain>
                                {!!confirmText && (
                                    <ButtonSubmit
                                        className={cls([
                                            effect,
                                            loading && 'loading'
                                        ])}
                                        disabled={!!size(errors) || loading}
                                        ref={trigger}>
                                        {confirmText}
                                    </ButtonSubmit>
                                )}
                            </Actions>
                        </>
                    )}
                </Form>
            </Container>
        </Prompt>
    )
}

export default forwardRef((props, ref) => (
    <StandardPrompt {...props} forwardedRef={ref} />
))

import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import AbsenceTypeProvider from 'contexts/absence-type'
import { useNavigate } from 'react-router-dom'
import { getAbsencePolicyNewUrl, getAbsenceTypeUrl } from 'utilities/url'
import { Summary, Actions } from './s'
import Expandable from 'components/expandable'
import Symbol from 'components/symbol'
import Link from 'components/link'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import { InlineMessage } from 'components/message'
import { InlineButton, Plain } from 'components/button'
import Policies from 'lists/absence/policies'
import { Scrollable as Modal } from 'modals/generic'
import UnassignedUsers from 'pages/absence/modals/policy-assignment/unassigned'
import { Plus, Edit } from 'styled-icons/feather'

const TypeItem = ({ type, salt }) => {
    const { formatMessage } = useIntl()

    const {
        id,
        symbol,
        privacy,
        requireApproval,
        policies
    } = type

    const name = useTypeNameFormatter()(type)

    const [settingUnassignedUsers, setSettingUnassignedUsers] = useState(false)

    const navigate = useNavigate()

    const summary = (
        <Summary>
            <span>
                <FormattedMessage
                    id={`absence_privacy_${privacy}`}
                    defaultMessage={privacy} />
            </span>
            {requireApproval && (
                <span>
                    <FormattedMessage
                        id="absence_type_label_requires_approval"
                        defaultMessage="Requires approval" />
                </span>
            )}
            <span>
                <FormattedMessage
                    id="absence_policies_count"
                    defaultMessage="{count, plural, =0 {No policies} =1 {1 policy} other {{count} policies}}"
                    values={{ count: policies?.length }} />
            </span>
        </Summary>
    )

    return (
        <Expandable
            icon={(
                <Symbol
                    symbol={symbol}
                    className="hover-effect" />
            )}
            heading={name}
            summary={summary}
            defer={true}
            key={salt}>
            <Actions>
                <Plain
                    className="constructive"
                    onClick={() => navigate(getAbsencePolicyNewUrl({ type: type.name }))}
                    icon={<Plus size={24} />}>
                    <FormattedMessage
                        id="absence_policy_action_add"
                        defaultMessage="Add policy" />
                </Plain>
                <Plain
                    as={Link}
                    to={getAbsenceTypeUrl({ id })}
                    className="constructive"
                    icon={<Edit size={24} />}>
                    <FormattedMessage
                        id="absence_type_action_edit"
                        defaultMessage="Edit absence type" />
                </Plain>
            </Actions>
            <AbsenceTypeProvider
                id={type.id}
                includeUnassignedUsers={true}>
                {({ type, fetchType, hasFetched }) => {
                    const {
                        policies = [],
                        unassignedUsers = []
                    } = type ?? {}

                    return (
                        <>
                            {!!unassignedUsers?.length && (
                                <>
                                    <InlineMessage
                                        type="info"
                                        message={formatMessage({
                                            id: 'absence_type_message_unassigned_users_count',
                                            defaultMessage: '{count, plural, =0 {} =1 {<action>1 person</action> is not assigned to a {type} policy} other {<action>{count} people</action> are not assigned to a {type} policy}}'
                                        }, {
                                            action: chunks => (
                                                <InlineButton
                                                    onClick={() => setSettingUnassignedUsers(true)}
                                                    className="constructive">
                                                    {chunks}
                                                </InlineButton>
                                            ),
                                            count: unassignedUsers.length,
                                            type: name
                                        })} />
                                    <Modal
                                        show={!!settingUnassignedUsers}
                                        dismiss={() => setSettingUnassignedUsers(false)}>
                                        <UnassignedUsers
                                            unassignedUsers={unassignedUsers}
                                            dismiss={() => setSettingUnassignedUsers(false)}
                                            onDone={() => {
                                                setSettingUnassignedUsers(false)
                                                fetchType()
                                            }}
                                            salt={salt} />
                                    </Modal>
                                </>
                            )}
                            <Policies
                                header={hasFetched && !!policies?.length}
                                salt={salt} />
                        </>
                    )
                }}
            </AbsenceTypeProvider>
        </Expandable>
    )
}

export default TypeItem

import React from 'react'
import { Scrollable as Modal } from 'modals/generic'
import Content from './content'

const UpgradeModal = props => (
    <Modal
        show={!!props.upgrade}
        dismiss={props.dismiss}
        salt={props.salt}>
        <Content {...props} />
    </Modal>
)

export default UpgradeModal
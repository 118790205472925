import React, { useState } from 'react'
import RoleProvider from 'contexts/role'
import { useIntl, FormattedMessage } from 'react-intl'
import { capitalize } from 'utilities/string'
import { getRoleUrl } from 'utilities/url'
import Expandable from 'components/expandable'
import { Actions, ActionButton, ActionLink } from './s'
import { Plus, Edit, Eye } from 'styled-icons/feather'
import Details from './details'
import Grants from 'pages/settings/pages/roles/role/panes/grants/content'

const Role = ({ role }) => {
    const { formatMessage } = useIntl()

    const [adding, setAdding] = useState(false)

    const {
        domain,
        id,
        name,
        description,
        deletable,
        updatable
    } = role

    const roleName = name ? formatMessage({
        id: `role_${name}_name`,
        defaultMessage: deletable ? name : capitalize(name)
    }) : ''

    const roleDescriptionId = `role_${name}_description`
    const roleDescription = formatMessage({ id: roleDescriptionId })

    const space = formatMessage({
        id: 'sentence_separator',
        defaultMessage: ' '
    })

    const summary = description ?
        description :
        (roleDescription !== roleDescriptionId) ?
            `${updatable ? '' : `🔒${space}`}${roleDescription}` :
            null

    const salt = `list:roles:role:${role.domain}:${role.id}`

    return (
        <Expandable
            heading={roleName}
            summary={summary}
            defer={true}
            key={salt}>
            <Actions>
                <ActionButton
                    className="constructive"
                    onClick={e => {
                        e.stopPropagation()
                        setAdding(true)
                    }}
                    disabled={adding}>
                    <Plus size={24} />
                    <FormattedMessage
                        id="grant_action_add"
                        defaultMessage="Add grant" />
                </ActionButton>
                <ActionLink
                    className="constructive"
                    to={getRoleUrl({ domain, id })}>
                    {!!updatable && <Edit size={24} />}
                    {!updatable && <Eye size={24} />}
                    <FormattedMessage
                        id={updatable ?
                            'role_permissions_action_edit' :
                            'role_permissions_action_see'
                        }
                        defaultMessage={updatable ?
                            'Edit permissions' :
                            'See permissions'
                        } />
                </ActionLink>
            </Actions>
            <RoleProvider
                domain={domain}
                id={id}>
                <Details />
                <Grants
                    domain={role.domain}
                    id={role.id}
                    adding={adding}
                    setAdding={setAdding}
                    showLoader={true}
                    salt={salt} />
            </RoleProvider>
        </Expandable>
    )
}

export default Role
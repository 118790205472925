import React, { useState, useRef, useEffect, forwardRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useSalaryRevisionGroup } from 'contexts/salary-revision-group'
import { isNil } from 'lodash'
import { decimalPattern, integerPattern } from 'utilities/regex'
import { roundedToFixed } from 'utilities/math'
import { reduce } from 'utilities/object'
import { currencies } from 'utilities/payment'
import { getNormalizedAmount, getPeriodUnitConversion, getExchangeRate, getAmount } from 'pages/salary/utilities'
import { periodUnits } from 'pages/salary/constants'
import {
    Row, ChangedIndicator, Cell,
    PriceTruncated, Label, Value, Fields, Field, Text, Select
} from './s'
import Person from './person'
import Paragraph from 'components/typography/paragraph'
import Tooltip, { Trigger } from 'components/tooltip'
import CustomTooltip from './tooltip'
import { Plain } from 'components/button'
import ContextMenu from 'widgets/context-menu'
import Messages from './messages'
import Note from 'components/note'
import { MessageCircle as NoteIcon } from 'styled-icons/feather'

const User = forwardRef(({ showingSalaryDevelopment, setShowingSalaryDevelopment, actions = {}, showingNotesAndAlerts, setActingSalaryNote, salt, ...props }, ref) => {
    const {
        formatMessage,
        formatNumber
    } = useIntl()

    const percentageRef = useRef()
    const fixedRef = useRef()

    const {
        group,
        loading = false,
        periodUnit,
        locallyUpdateUser
    } = useSalaryRevisionGroup()

    const {
        revision,
        status
    } = group

    const {
        currency,
        exchangeRates,
        hoursPerWeek
    } = revision

    const {
        id,
        user,
        hidden = false,
        changed = false,
        change: salaryChange,
        employmentPercentage,
        employmentStartDate,
        currentSalary,
        newSalary
    } = props

    const {
        amount: currentAmount,
        normalizedAmount: currentNormalizedAmount
    } = currentSalary ?? {}

    const {
        amount: newAmount,
        currency: newCurrency,
        note: newNote
    } = newSalary ?? {}

    const hasCurrentSalary = !!currentAmount?.yearly

    const [editingNewSalary, setEditingNewSalary] = useState(!hasCurrentSalary && !!newSalary)
    const [newAddedSalary, setNewAddedSalary] = useState(newSalary ?? {
        amount: periodUnits.reduce((accumulator, periodUnit) => ({
            ...accumulator,
            [periodUnit]: 0
        }), {}),
        currency,
        periodUnit
    })
    const [change, setChange] = useState(salaryChange)

    const adjust = (type, value) => {
        if(!value) {
            setChange(null)

            return
        }

        value = Number(value)

        if(isNaN(value) || !type) {
            return
        }

        if(value === 0) {
            setChange({
                percentage: 0,
                fixed: { yearly: 0, monthly: 0, weekly: 0, hourly: 0 }
            })

            return
        }

        if(type === 'percentage') {
            setChange({
                percentage: value,
                fixed: {
                    yearly: (value / 100) * currentAmount.yearly,
                    monthly: (value / 100) * currentAmount.monthly,
                    weekly: (value / 100) * currentAmount.weekly,
                    hourly: (value / 100) * currentAmount.hourly
                }
            })

            return
        }

        if(type === 'fixed') {
            setChange({
                percentage: (value / currentAmount[[periodUnit]]) * 100,
                fixed: reduce(currentAmount, (accumulator, _, key) => {
                    if(key === periodUnit) {
                        return {
                            ...accumulator,
                            [key]: value
                        }
                    }

                    const periodUnitConversion = getPeriodUnitConversion(hoursPerWeek)

                    return {
                        ...accumulator,
                        [key]: value / periodUnitConversion[key] * periodUnitConversion[periodUnit]
                    }
                }, {})
            })

            return
        }
    }

    const currencyDisplay = currencies[currency] || 'code'

    const getPrice = ({ amount, currency, clampAt = 14, options = {} }) => {
        if(isNil(amount)) {
            return null
        }

        const formattedPrice = formatNumber(roundedToFixed(amount), {
            style: 'currency',
            currency,
            currencyDisplay,
            ...options
        })

        const shouldClamp = formattedPrice.toString().length > clampAt

        if(shouldClamp) {
            return (
                <Tooltip content={formattedPrice}>
                    <PriceTruncated style={{ '--clamp-at': `${clampAt - 2}ch` }}>{formattedPrice}</PriceTruncated>
                </Tooltip>
            )
        }

        return formattedPrice
    }

    useEffect(() => {
        if(!change || !user) {
            return
        }

        locallyUpdateUser({
            id: user.id,
            data: {
                change,
                newSalary: {
                    ...newSalary,
                    amount: periodUnits.reduce((accumulator, periodUnit) => ({
                        ...accumulator,
                        [periodUnit]: currentAmount[periodUnit] + change.fixed[periodUnit]
                    }), {}),
                    normalizedAmount: periodUnits.reduce((accumulator, periodUnit) => ({
                        ...accumulator,
                        [periodUnit]: currentNormalizedAmount[periodUnit] + change.fixed[periodUnit]
                    }), {})
                }
            },
            recalculate: true
        })
    }, [change])

    useEffect(() => {
        if(!editingNewSalary || (!newAddedSalary.amount?.[periodUnit] && newAddedSalary.amount?.[periodUnit] !== 0)) {
            return
        }

        locallyUpdateUser({
            id: user.id,
            data: {
                newSalary: {
                    ...newAddedSalary,
                    normalizedAmount: getNormalizedAmount({
                        amount: newAddedSalary.amount[periodUnit],
                        periodUnit,
                        exchangeRate: getExchangeRate({
                            currency: newAddedSalary.currency,
                            exchangeRates,
                            revisionCurrency: currency
                        }),
                        hoursPerWeek
                    })
                }
            },
            recalculate: true
    })
    }, [editingNewSalary, newAddedSalary])

    useEffect(() => {
        // If data is refreshed and currentSalary or newSalary has changed
        if(newSalary && !hasCurrentSalary) {
            setEditingNewSalary(true)
            setNewAddedSalary(newSalary)
        }
    }, [currentSalary, hasCurrentSalary])

    if(!revision) {
        return null
    }

    const availableCurrencies = [
        currency,
        ...Object.keys(exchangeRates) ?? []
    ]

    const isDeleted = !user?.type

    return (
        <Row
            {...!!hidden ? { className: 'hidden' } : null}
            ref={ref}>
            <Cell $cell="name">
                {!!changed && (
                    <Tooltip content={formatMessage({
                        id: 'salary_revision_tooltip_salary_individually_changed',
                        defaultMessage: 'Has individual changes'
                    })}>
                        <ChangedIndicator />
                    </Tooltip>
                )}
                <Person
                    who={{
                        ...user,
                        id,
                        employmentPercentage
                    }}
                    showingSalaryDevelopment={showingSalaryDevelopment}
                    setShowingSalaryDevelopment={setShowingSalaryDevelopment} />
            </Cell>
            <Cell
                $cell="seniority"
                className="seniority">
                <Paragraph className="small compact">
                    {employmentStartDate && (
                        <Tooltip content={<CustomTooltip start={employmentStartDate} />}>
                            <Trigger />
                        </Tooltip>
                    )}
                    {!employmentStartDate && (
                        <Value>–</Value>
                    )}
                </Paragraph>
            </Cell>
            <Cell $cell="current">
                <Label>
                    <FormattedMessage
                        id="salary_revision_heading_current"
                        defaultMessage="Current" />
                </Label>
                {hasCurrentSalary && (
                    <Paragraph className="small compact">
                        {getPrice({
                            amount: currentAmount[periodUnit],
                            currency: currentSalary.currency,
                            options: {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            }
                        })}
                    </Paragraph>
                )}
                {!hasCurrentSalary && '–'}
            </Cell>
            <Cell $cell="change">
                <Label>
                    <FormattedMessage
                        id="salary_revision_heading_change"
                        defaultMessage="Change" />
                </Label>
                {hasCurrentSalary && (
                    <Fields>
                        <Field className="has-adornment">
                            <Text
                                id={`${salt}:percentage`}
                                name="percentage"
                                value={change ? roundedToFixed(change.percentage, 1) : ''}
                                type="number"
                                inputMode="decimal"
                                step={.1}
                                pattern={decimalPattern}
                                disabled={loading || status === 'approved'}
                                onChange={({ target }) => adjust('percentage', target.value)}
                                ref={percentageRef} />
                            <Paragraph className="compact adornment">%</Paragraph>
                        </Field>
                        <Field>
                            <Text
                                id={`${salt}:fixed`}
                                name="fixed"
                                value={change ? roundedToFixed(change.fixed[periodUnit]) : ''}
                                type="number"
                                inputMode="numeric"
                                step={1}
                                pattern={integerPattern}
                                disabled={loading || status === 'approved'}
                                onChange={({ target }) => adjust('fixed', target.value)}
                                ref={fixedRef} />
                        </Field>
                        <Paragraph className="compact">
                            {currentSalary.currency}
                        </Paragraph>
                    </Fields>
                )}
                {(!hasCurrentSalary && status !== 'approved' && !!editingNewSalary) && (
                    <Fields className="adding">
                        <Field>
                            <Select
                                defaultValue={newAddedSalary.periodUnit}
                                disabled={loading}
                                onChange={({ target }) => setNewAddedSalary(prev => ({
                                    ...prev,
                                    periodUnit: target.value
                                }))}>
                                {periodUnits.map(periodUnit => (
                                    <option
                                        key={periodUnit}
                                        value={periodUnit}>
                                        <FormattedMessage
                                            id={`frequency_${periodUnit}`}
                                            defaultMessage={periodUnit} />
                                    </option>
                                ))}
                            </Select>
                        </Field>
                        <Field>
                            <Text
                                id={`${salt}:amount`}
                                name="amount"
                                value={roundedToFixed(newAddedSalary.amount[newAddedSalary.periodUnit])}
                                type="number"
                                inputMode="numeric"
                                step={1}
                                min={1}
                                pattern={integerPattern}
                                disabled={loading}
                                onChange={({ target }) => setNewAddedSalary(prev => ({
                                    ...prev,
                                    amount: {
                                        ...prev.amount,
                                        ...getAmount({
                                            amount: Number(target.value),
                                            periodUnit: newAddedSalary.periodUnit,
                                            hoursPerWeek
                                        })
                                    }
                                }))} />
                        </Field>
                        <Field>
                            <Select
                                defaultValue={newAddedSalary.currency}
                                disabled={loading}
                                onChange={({ target }) => setNewAddedSalary(prev => ({
                                    ...prev,
                                    currency: target.value
                                }))}>
                                {availableCurrencies.map(currency => (
                                    <option
                                        key={currency}
                                        value={currency}>
                                        {currency}
                                    </option>
                                ))}
                            </Select>
                        </Field>
                    </Fields>
                )}
            </Cell>
            <Cell $cell="new">
                <Label>
                    <FormattedMessage
                        id="salary_revision_heading_new"
                        defaultMessage="New" />
                </Label>
                {newSalary && (
                    <Paragraph className="small compact">
                        {getPrice({
                            amount: editingNewSalary ?
                                newAddedSalary.amount[periodUnit]
                                : newAmount[periodUnit],
                            currency: editingNewSalary ?
                                newAddedSalary.currency
                                : newCurrency,
                            options: {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            }
                        })}
                    </Paragraph>
                )}
                {(!hasCurrentSalary && status !== 'approved' && !editingNewSalary && !isDeleted) && (
                    <Plain
                        className="constructive small"
                        disabled={loading}
                        onClick={() => setEditingNewSalary(true)}>
                        <FormattedMessage
                            id="action_add"
                            defaultMessage="Add" />
                    </Plain>
                )}
            </Cell>
            {!!newSalary && (
                <Cell
                    className="notes"
                    $cell="notes">
                    <Label>
                        <FormattedMessage
                            id="noun_notes"
                            defaultMessage="Notes" />
                    </Label>
                    <Plain
                        className="constructive small"
                        icon={NoteIcon}
                        disabled={loading || status === 'approved'}
                        onClick={() => setActingSalaryNote({
                            user,
                            newSalary
                        })}>
                        <FormattedMessage
                            id={newNote ?
                                'action_edit'
                                : 'action_add'
                            }
                            defaultMessage={newNote ?
                                'Edit'
                                : 'Add'
                            } />
                    </Plain>
                </Cell>
            )}
            <Cell
                className="actions"
                $cell="actions">
                <ContextMenu
                    salt={salt}
                    context={user}
                    actions={actions} />
            </Cell>
            {showingNotesAndAlerts && (
                <>
                    {!isDeleted && (
                        <Messages
                            user={user}
                            currentSalary={currentSalary}
                            newSalary={newSalary}
                            hasNote={!!newNote} />
                    )}
                    {!!newNote && (
                        <Cell
                            className="note"
                            $cell="note">
                            <Note
                                className="end"
                                clamp={true}>
                                {newNote}
                            </Note>
                        </Cell>
                    )}
                </>
            )}
        </Row>
    )
})

export default User
import React from 'react'
import {
    Wrap, Columns,
    MonthYearColumns, SelectField,
    NavigationColumn, PreviousMonthButton, TodayButton, NextMonthButton
} from './s'
import Form from 'components/form/controller'
import {
    ChevronLeft as PreviousMonth,
    ChevronRight as NextMonth
} from 'styled-icons/feather'
import { CalendarToday } from 'components/feather'

const TimeMachine = ({
    navigateInTime,
    navigationMonth, monthNames,
    navigationYear, yearNames,
    decreaseMonth, navigateToToday, increaseMonth,
    salt
}) => (
    <Wrap>
        <Columns>
            <Form
                submitOnChange={true}
                onSubmit={navigateInTime}>
                <MonthYearColumns>
                    <SelectField
                        salt={`${salt}:set:year`}
                        label={false}
                        name="year"
                        field={{
                            value: navigationYear,
                            required: true,
                            include: 'always',
                            allowEmptyOption: false
                        }}
                        options={yearNames.map(name => ({
                            key: name,
                            value: name,
                            text: name
                        }))}
                        key={`${salt}:set:year:${navigationYear}`} />
                    <SelectField
                        salt={`${salt}:set:month`}
                        label={false}
                        name="month"
                        field={{
                            value: navigationMonth,
                            required: true,
                            include: 'always',
                            allowEmptyOption: false
                        }}
                        options={monthNames.map((name, index) => ({
                            key: index,
                            value: index,
                            text: name
                        }))}
                        key={`${salt}:set:month:${navigationMonth}`} />
                </MonthYearColumns>
            </Form>
            <NavigationColumn>
                <PreviousMonthButton
                    onClick={decreaseMonth}
                    tabIndex={0}>
                    <PreviousMonth size={24} />
                </PreviousMonthButton>
                <TodayButton
                    onClick={navigateToToday}
                    tabIndex={0}>
                    <CalendarToday size={24} />
                </TodayButton>
                <NextMonthButton
                    onClick={increaseMonth}
                    tabIndex={0}>
                    <NextMonth size={24} />
                </NextMonthButton>
            </NavigationColumn>
        </Columns>
    </Wrap>
)

export default TimeMachine
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { UserLayout, Person, RemovePersonButton } from './s'
import Select from 'components/form/input/select'

const AccessUnitUser = ({ user, access, locked, update, remove, salt }) => {
    const { formatMessage } = useIntl()

    return (
        <UserLayout>
            <Person
                who={user}
                size={40}
                truncate
                showPosition />
            <Select
                name={`access[user][${user.id}]`}
                value={access.owner ?
                    'owner' :
                    access.share ?
                        'share' :
                        'none'
                }
                onChange={({ target: { value } }) => update(value)}
                disabled={locked}
                className="small">
                {options.map(option => (
                    <option
                        value={option.value}
                        key={`${salt}:${option.value}`}>
                        {formatMessage(option.label)}
                    </option>
                ))}
            </Select>
            {!locked && (
                <RemovePersonButton
                    onClick={remove}
                    className="destructive small">
                    <FormattedMessage
                        id="action_remove"
                        defaultMessage="Remove" />
                </RemovePersonButton>
            )}
        </UserLayout>
    )
}

const options = [
    {
        value: 'share',
        label: {
            id: 'role_permission_read',
            defaultMessage: 'Only see'
        }
    },
    {
        value: 'owner',
        label: {
            id: 'role_permission_read_write',
            defaultMessage: 'See and edit'
        }
    }
]

export default AccessUnitUser
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import DisplayWorkSchedule from './display'
import { cls } from 'utilities/dom'

const ViewWorkSchedule = ({ className, label, ...props }) => {
    const {
        field = {},
        controlProps = {},
        display
    } = props

    const {
        value = null,
        optional
    } = field

    const displayClassName = value ?
        display :
        'empty'

    const controlClassName = cls([
        controlProps.className,
        display
    ])

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && (
                <Label optional={optional}>{label}</Label>
            )}
            <Control {...(controlClassName ? { className: controlClassName } : null)}>
                {!!value && <DisplayWorkSchedule {...value} />}
                {!value && (
                    <ValueDisplay {...(displayClassName ? { className: displayClassName } : null)}>
                        <FormattedMessage
                            id="empty_not_set"
                            defaultMessage="Not set" />
                    </ValueDisplay>
                )}
            </Control>
        </Field>
    )
}

export default ViewWorkSchedule

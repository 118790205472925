import React, { Component, createRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { FormContext } from 'components/form/controller'
import { cls } from 'utilities/dom'
import { Field, Label, Control } from 'components/form/field/s'
import Options from './options'

const empty = 0

export default class EditRating extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const { forwardedRef } = props

        this.ratingRef = createRef()
        this.combinedRef = mergeRefs([
            this.ratingRef,
            forwardedRef
        ])

        const { value } = getFieldFromProps(props)
        this.state = { value }

        this.register()
    }

    componentDidMount() {
        const { include } = getFieldFromProps(this.props)
        if(include !== 'never') {
            this.context.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ forwardedRef, name, field }, { value }) => {
        const forwardedRefChanged = forwardedRef !== this.props.forwardedRef
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include

        if(forwardedRefChanged) {
            this.combinedRef = mergeRefs([
                this.ratingRef,
                this.props.forwardedRef
            ])
        }

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.context.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.context.triggerChange(this.props.name)
        }
    }

    componentWillUnmount() {
        const { include } = getFieldFromProps(this.props)
        if(include !== 'never') {
            this.context.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required,
            include
        } = getFieldFromProps(this.props)

        if(include === 'never') {
            return
        }

        this.context.registerField(this.props.name, {
            empty,
            required,
            include,
            unset: this.unset,

            validator: value => {
                if(required) {
                    return value > 0
                }

                return true
            }
        }, update)
    }

    unset = () => {
        const {
            name,
            onChange
        } = this.props

        this.setState({ value: empty })

        this.context.triggerChange(name)
        onChange?.({ [name]: this.empty })
    }

    onChange = ({ target: { value } }) => {
        const {
            name,
            onChange
        } = this.props

        value = parseInt(value)

        this.setState({ value })

        this.context.triggerChange(name)
        onChange?.({ [name]: value })
    }

    render() {
        const { value } = this.state

        const {
            className,
            salt,
            label,
            name,
            loading = false,
            // field = {}
        } = this.props

        const {
            required,
            max
        } = getFieldFromProps(this.props)

        const touched = this.context.touched.includes(name)
        const error = (name in this.context.errors) && touched

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required}>
                        {label}
                    </Label>
                )}
                <Control>
                    <input
                        name={name}
                        type="hidden"
                        defaultValue={value}
                        key={`${name}:input:${value}`} />
                    <Options
                        {...this.props}
                        value={value}
                        max={max}
                        onChange={this.onChange}
                        salt={salt} />
                </Control>
            </Field>
        )
    }
}

const getFieldFromProps = ({ field = {}, controlProps = {} }) => {
    let {
        value,
        required = false,
        include = 'touched',
        ...rest
    } = field

    const {
        max = 5
    } = controlProps

    if(!value && value !== 0) {
        value = empty
    }

    return {
        ...rest,
        value,
        required,
        include,
        max
    }
}
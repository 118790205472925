import React from 'react'
import { useIntl } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { map } from 'utilities/object'
import { kebabize } from 'utilities/string'
import {
    Bold,
    Italic,
    Underline,
    Link,
    Smile as Emoji
} from 'styled-icons/feather'
import { Inline } from 'components/code'
import {
    Code,
    CodeSquare as CodeSnippet,
    HorizontalRule as Divider
} from 'styled-icons/octicons'
import { Highlight } from 'styled-icons/fluentui-system-regular'
import {
    ListUl as BulletList,
    ListOl as NumberedList
} from 'styled-icons/fa-solid'
import { FormatQuote as Blockquote } from 'styled-icons/material-rounded'
import {
    Youtube as YouTube,
    Vimeo
} from 'styled-icons/icomoon'
import { Mention } from 'styled-icons/octicons'

export const datafyAttributes = (attributes = {}) => map(attributes, (value, key) => ({
    [`data-${kebabize(key)}`]: value
}))

export const getTippySuggestionsConfiguration = (props, suggestions, overrides = {}) => ({
    getReferenceClientRect: props.clientRect,
    appendTo: () => document.body,
    content: suggestions.element,
    showOnCreate: true,
    interactive: true,
    trigger: 'manual',
    placement: 'bottom-start',
    animation: 'shift-away',
    ...overrides
})

export const getExtensionWrapper = (output = false) => (Extension, options = {}) => {
    const {
        enabled = true,
        extend,
        configure
    } = options

    if(!enabled && !output) {
        return null
    }

    if(extend) {
        Extension = Extension.extend(extend)
    }

    if(configure) {
        Extension = Extension.configure(configure)
    }

    return Extension
}

export const useEditorMeta = () => {
    const { formatMessage } = useIntl()
    const { organization } = useOrganization()

    const types = {
        // Headings
        Heading: ({ level }) => ({
            label: formatMessage({
                id: 'editor_block_heading_level_name',
                defaultMessage: 'Heading {level}'
            }, { level }),
            icon: (
                <strong>
                    H<small>{level}</small>
                </strong>
            ),
            markdown: {
                trigger: 'space',
                syntax: [new Array(level).join('#')]
            },
            shortcut: ['mod', 'alt', `${level}`]
        }),

        // Inlines
        Bold: () => ({
            label: formatMessage({
                id: 'editor_block_bold_name',
                defaultMessage: 'Bold'
            }),
            icon: <Bold size={16} />,
            markdown: {
                trigger: 'surround',
                syntax: ['**', '__']
            },
            shortcut: ['mod', 'b']
        }),
        Italic: () => ({
            label: formatMessage({
                id: 'editor_italic_bold_name',
                defaultMessage: 'Italic'
            }),
            icon: <Italic size={16} />,
            markdown: {
                trigger: 'surround',
                syntax: ['*', '_']
            },
            shortcut: ['mod', 'i']
        }),
        Underline: () => ({
            label: formatMessage({
                id: 'editor_block_underline_name',
                defaultMessage: 'Underline'
            }),
            icon: <Underline size={16} />,
            shortcut: ['mod', 'u']
        }),
        Link: () => ({
            label: formatMessage({
                id: 'editor_block_link_name',
                defaultMessage: 'Link'
            }),
            icon: <Link size={14} />
        }),
        Code: () => ({
            label: formatMessage({
                id: 'editor_block_code_inline_name',
                defaultMessage: 'Code'
            }),
            icon: <Code size={16} />,
            markdown: {
                trigger: 'surround',
                syntax: ['`']
            },
            shortcut: ['mod', 'e']
        }),
        Highlight: () => ({
            label: formatMessage({
                id: 'editor_block_highlight_name',
                defaultMessage: 'Highlight'
            }),
            icon: <Highlight size={16} />,
            markdown: {
                trigger: 'surround',
                syntax: ['==']
            },
            shortcut: ['mod', 'shift', 'm']
        }),
        Strike: () => ({
            label: formatMessage({
                id: 'editor_block_strike_name',
                defaultMessage: 'Strike-through'
            }),
            icon: <span style={{ textDecoration: 'line-through' }}>S</span>,
            markdown: {
                trigger: 'surround',
                syntax: ['~~']
            },
            shortcut: ['mod', 'shift', 'x']
        }),
        Subscript: () => ({
            label: formatMessage({
                id: 'editor_block_subscript_name',
                defaultMessage: 'Subscript'
            }),
            icon: <span>O<sub>2</sub></span>
        }),
        Superscript: () => ({
            label: formatMessage({
                id: 'editor_block_superscript_name',
                defaultMessage: 'Superscript'
            }),
            icon: <span>m<sup>3</sup></span>
        }),

        // Lists
        BulletList: () => ({
            label: formatMessage({
                id: 'editor_block_list_unordered_name',
                defaultMessage: 'Bullet list'
            }),
            icon: <BulletList size={16} />,
            markdown: {
                trigger: 'space',
                syntax: ['-', '*']
            },
            shortcut: ['mod', 'shift', '8']
        }),
        OrderedList: () => ({
            label: formatMessage({
                id: 'editor_block_list_ordered_name',
                defaultMessage: 'Numbered list'
            }),
            icon: <NumberedList size={16} />,
            markdown: {
                trigger: 'space',
                syntax: ['1.', '2.']
            },
            shortcut: ['mod', 'shift', '9']
        }),

        // Blocks
        Blockquote: () => ({
            label: formatMessage({
                id: 'editor_block_blockquote_name',
                defaultMessage: 'Block quotation'
            }),
            icon: <Blockquote size={16} />,
            markdown: {
                trigger: 'space',
                syntax: ['>']
            },
            shortcut: ['mod', 'shift', 'b']
        }),
        CodeBlock: () => ({
            label: formatMessage({
                id: 'editor_block_code_block_name',
                defaultMessage: 'Code snippet'
            }),
            icon: <CodeSnippet size={16} />,
            markdown: {
                trigger: 'enter',
                syntax: ['```', '```html', '~~~', '~~~html']
            },
            shortcut: ['mod', 'shift', 'c']
        }),
        HorizontalRule: () => ({
            label: formatMessage({
                id: 'editor_block_horizontalrule_name',
                defaultMessage: 'Divider'
            }),
            icon: <Divider size={16} />,
            markdown: {
                trigger: 'start',
                syntax: ['---', '___']
            }
        }),

        // Invisibles
        Youtube: () => ({
            label: formatMessage({
                id: 'editor_block_youtube_name',
                defaultMessage: 'YouTube video'
            }),
            icon: <YouTube size={16} />,
            instructions: formatMessage({
                id: 'editor_block_youtube_instructions',
                defaultMessage: 'Paste the link to a YouTube video to insert it'
            })
        }),
        Vimeo: () => ({
            label: formatMessage({
                id: 'editor_block_vimeo_name',
                defaultMessage: 'Vimeo video'
            }),
            icon: <Vimeo size={16} />,
            instructions: formatMessage({
                id: 'editor_block_vimeo_instructions',
                defaultMessage: 'Paste the link to a Vimeo video to insert it'
            })
        }),
        Emoji: () => ({
            label: formatMessage({
                id: 'editor_block_emoji_name',
                defaultMessage: 'Emoji'
            }),
            icon: <Emoji size={16} />,
            instructions: formatMessage({
                id: 'editor_block_emoji_instructions',
                defaultMessage: 'Type a <code>:<\/code> followed by your search term to find and insert an emoji'
            }, { code: chunks => <Inline>{chunks}</Inline> })
        }),
        Mention: () => ({
            label: formatMessage({
                id: 'editor_block_mention_name',
                defaultMessage: 'Mention'
            }),
            icon: <Mention size={16} />,
            instructions: formatMessage({
                id: 'editor_block_mention_instructions',
                defaultMessage: 'Type a <code>@<\/code> followed by the name of a person, team or location in {organization} to find and insert a link'
            }, {
                code: chunks => <Inline>{chunks}</Inline>,
                organization: organization.name
            })
        }),
        Variable: () => ({
            label: formatMessage({
                id: 'editor_block_variable_name',
                defaultMessage: 'Variable'
            }),
            icon: (
                <strong>{'$'}</strong>
            ),
            instructions: formatMessage({
                id: 'editor_block_variable_instructions',
                defaultMessage: 'Type a <code>$<\/code> followed by the name of a data source’s type or name, or a field’s name, to find and insert a value or a placeholder'
            }, { code: chunks => <Inline>{chunks}</Inline> })
        })
    }

    types.BulletConvert = types.BulletList
    types.OrderedConvert = types.OrderedList

    return types
}
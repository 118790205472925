import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useFlash } from 'hooks/flash'
import { mergeRefs } from 'react-merge-refs'
import { getJobTitleUrl } from 'utilities/url'
import { CardList } from '../s'
import { CardListRow, EmptyDescription } from './s'
import Link from 'components/link'
import StatusLabel from 'components/status-label'
import ContextMenu, { getActionsArray } from 'widgets/context-menu'
import { Plain } from 'components/button'

const JobTitle = forwardRef(({ jobTitle, actions, flash }, ref) => {
    const navigate = useNavigate()

    const flasher = useFlash(flash)

    const { name } = jobTitle
    const id = `jobTitle:${jobTitle.id}`

    return (
        <CardListRow ref={mergeRefs([ref, flasher])}>
            <CardList.Cell cell="name">
                <Link to={getJobTitleUrl(jobTitle)}>
                    {name}
                </Link>
            </CardList.Cell>
            <CardList.Cell cell="description">
                {!!jobTitle.description && (
                    <StatusLabel className="active self-align-reset">
                        <FormattedMessage
                            id="jobtitle_status_has_description"
                            defaultMessage="Has description" />
                    </StatusLabel>
                )}
                {!jobTitle.description && (
                    <EmptyDescription>
                        <StatusLabel className="neutral self-align-reset">
                            <FormattedMessage
                                id="jobtitle_status_missing_description"
                                defaultMessage="Missing description" />
                        </StatusLabel>
                        <Plain
                            className="constructive small compact"
                            onClick={() => navigate(getJobTitleUrl(jobTitle), {
                                state: {
                                    intent: 'show-content-assistant',
                                    filter: {
                                        hasJobDescription: false
                                    }
                                }
                            })}>
                            <FormattedMessage
                                id="action_generate"
                                defaultMessage="Generate" />
                        </Plain>
                    </EmptyDescription>
                )}
            </CardList.Cell>
            {!!getActionsArray(actions).length && (
                <CardList.Cell cell="actions">
                    <ContextMenu
                        salt={id}
                        context={jobTitle}
                        appendTo={document.body}
                        actions={actions} />
                </CardList.Cell>
            )}
        </CardListRow>
    )
})

export default JobTitle

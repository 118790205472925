import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
    Policy,
    NameCell, Name,
    QuotaCell,
    AssignedCell,
    StatusCell, StatusLabel,
    ActionsCell
} from './s'
import { Plain } from 'components/button'
import MobileLabel from 'components/typography/mobile-label'
import Link from 'components/link'
import Caption from 'components/typography/caption'
import ContextMenu, { getActionsArray } from 'widgets/context-menu'
import { getAbsencePolicyUsersUrl } from 'utilities/url'

const AbsencePolicy = ({ policy, actions, setPreviewingPolicy, salt }) => {
    const {
        id,
        name,
        default: defaultPolicy = false,
        userCount,
        quota
    } = policy

    const hasActions = !!getActionsArray(actions).length

    return (
        <Policy>
            <NameCell>
                <Name className="compact">{name}</Name>
                <Plain
                    className="constructive compact"
                    size="small"
                    onClick={() => setPreviewingPolicy({ policy })}>
                    <FormattedMessage
                        id="action_preview"
                        defaultMessage="Preview" />
                </Plain>
            </NameCell>
            <QuotaCell>
                <MobileLabel>
                    <FormattedMessage
                        id="absence_policy_label_available_days"
                        defaultMessage="Available days" />
                </MobileLabel>
                {quota && (
                    <span>
                        <FormattedMessage
                            id="days_count"
                            defaultMessage="{count, plural, =0 {0 days} =1 {1 day} other {{count} days}}"
                            values={{ count: quota }} />
                    </span>
                )}
                {!quota && (
                    <Caption className="compact">
                        <FormattedMessage
                            id="adjective_unlimited"
                            defaultMessage="Unlimited" />
                    </Caption>
                )}
            </QuotaCell>
            <AssignedCell>
                <MobileLabel>
                    <FormattedMessage
                        id="label_assigned_to"
                        defaultMessage="Assigned to" />
                </MobileLabel>
                <span>
                    <FormattedMessage
                        id="people_count"
                        defaultMessage="{count, plural, =0 {No people} =1 {One person} other {{count} people}}"
                        values={{ count: userCount }} />
                </span>
                <Link
                    to={getAbsencePolicyUsersUrl({ id })}
                    className="constructive">
                    <FormattedMessage
                        id="action_manage"
                        defaultMessage="Manage" />
                </Link>
            </AssignedCell>
            <StatusCell>
                {!!defaultPolicy && (
                    <StatusLabel className="neutral">
                        <FormattedMessage
                            id="work_schedule_status_default"
                            defaultMessage="Default" />
                    </StatusLabel>
                )}
            </StatusCell>
            {hasActions && (
                <ActionsCell>
                    <ContextMenu
                        salt={salt}
                        context={policy}
                        actions={actions} />
                </ActionsCell>
            )}
        </Policy>
    )
}

export default AbsencePolicy

import React, { useState, useEffect } from 'react'
import { useCompetenceRecords } from 'contexts/competence-records'
import { useTheme, ThemeProvider } from 'styled-components'
import { clamp } from 'utilities/math'
import { compact, omit } from 'utilities/object'
import { Wrap, SkeletonCell } from './s'
import Tooltip, { useSingleton } from 'components/tooltip'
import { SkeletonListRow, SkeletonAvatarAndFullName, SkeletonStrings } from 'components/skeleton'
import Tutorial from 'components/tutorial'
import Record from './record'
import { getActionsArray } from 'widgets/context-menu'

const CompetenceRecords = ({ actions = {}, records = [], className }) => {
    let theme = useTheme()

    const [source, target] = useSingleton()

    const [skeletonLength, setSkeletonLength] = useState(5)

    const {
        fetching,
        hasFetched,
        filter = {},
        flash,
        clearFlash
    } = useCompetenceRecords()

    const hasActions = !!getActionsArray(actions).length

    theme = {
        ...theme,
        actions: hasActions
    }

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(records.length, 1, 10))
        }
    }, [hasFetched, records?.length])

    const filtersApplied = !!Object.values(compact(omit(filter, 'concerns'))).flatMap(a => a).length

    return (
        <ThemeProvider theme={theme}>
            <Tooltip
                singleton={source}
                placement="top-start"
                delay={[500, 250]} />
            <Wrap {...(className ? { className } : null)}>
                {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow
                        columns={2}
                        hasActions={true}
                        key={`list:competence:records:skeleton:${index}`}>
                        <SkeletonCell>
                            <SkeletonAvatarAndFullName size={40} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings size={20} />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {(!records.length && hasFetched && !filtersApplied) && (
                    <Tutorial which="competence" />
                )}
                {records.map(record => (
                    <Record
                        record={record}
                        actions={actions}
                        singleton={target}
                        flash={flash?.current === record ? clearFlash : null}
                        key={record.id} />
                ))}
            </Wrap>
        </ThemeProvider>
    )
}

export default CompetenceRecords

import { useMe } from 'contexts/me'

export default () => {
    const { me } = useMe()
    if(!me) {
        return '…'
    }

    const {
        preferredName,
        givenName,
        email,
        phone
    } = me

    return preferredName?.value || givenName?.value || email?.value || phone?.value || 'My profile'
}

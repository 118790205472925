import React from 'react'
import { splitName } from 'utilities/file'
import { Columns, DetailsColumn, LoaderColumn } from './s'
import FileNameIcon from 'components/file-name-icon'
import Caption from 'components/typography/caption'
import Loader from 'components/loader'

const FileLoader = ({ name, status, ...props }) => (
    <Columns>
        <DetailsColumn>
            {!!name && (
                <FileNameIcon
                    {...props}
                    {...splitName(name)}
                    inline={true} />
            )}
            {(!name && !!status) && (
                <Caption
                    as="span"
                    className="compact">
                    {status}
                </Caption>
            )}
        </DetailsColumn>
        <LoaderColumn>
            <Loader />
        </LoaderColumn>
    </Columns>
)

export default FileLoader
import React, { useState, useEffect, memo } from 'react'
import CelebrationItem from './item'

const Celebration = memo(({ density = 5, salt }) => {
    const [items, setItems] = useState([])
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        if(!initialized) {
            const items = new Array(density)
                .fill()
                .map(makeItem)

            setItems(items)
            setInitialized(true)
        }
    }, [density, initialized])

    return items.map((item, index) => (
        <CelebrationItem
            {...item}
            index={index}
            key={`${salt}:${item.color}:${index}`} />
    ))
})

const makeItem = () => {
    const color = colors[random({ max: colors.length })]

    const delay = random({ min: 100, max: 1000 })
    const x = random({ min: -99, max: -30 })
    const y = random({ min: -99, max: -30 })
    let left

    let keyframes = [{
        offset: 0,
        transform: `rotateZ(45deg) translate(0, 0)`,
        opacity: 1
    }]

    if(!random({ max: 2 })) {
        left = `${-1 * x}vw`

        keyframes = keyframes.concat([
            {
                offset: x / -200,
                transform: `rotateZ(45deg) translate(${x}vw, 0)`
            },
            {
                offset: (x + y) / -200,
                transform: `rotateZ(45deg) translate(${x}vw, ${y}vh)`
            },
            {
                offset: (-100 + y) / -200,
                transform: `rotateZ(45deg) translate(-100vw, ${y}vh)`,
                opacity: 0
            }
        ])
    } else {
        keyframes = keyframes.concat([
            {
                offset: y / -200,
                transform: `rotateZ(45deg) translate(0, ${y}vh)`
            },
            {
                offset: (x + y) / -200,
                transform: `rotateZ(45deg) translate(${x}vw, ${y}vh)`
            },
            {
                offset: (-100 + x) / -200,
                transform: `rotateZ(45deg) translate(${x}vw, -100vh)`,
                opacity: 0
            }
        ])
    }

    keyframes.push({
        offset: 1,
        transform: `rotateZ(45deg) translate(-100vw, -100vh)`
    })

    const onReady = item => {
        if(!item) {
            return
        }

        if(left) {
            item.style.left = left
        }

        item.animate(keyframes, {
            duration: 10000,
            delay
        })
    }

    return {
        color,
        onReady
    }
}

export const random = ({ min = 0, max }) => Math.floor(Math.random() * (max - min)) + min

const colors = [
    'blue',
    'red',
    'green',
    'yellow',
    'purple'
]

export default Celebration
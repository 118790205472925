import styled from 'styled-components'
import SelectBase from 'components/form/input/select'

export const Flag = styled.p`
    display: block;
    width: 32px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 12px;
    line-height: 40px;
    pointer-events: none;
`

export const Select = styled(SelectBase)`
    padding-inline-start: 44px !important;
`
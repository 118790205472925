import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useGroups } from 'contexts/groups'
import { getPluralizedType } from 'pages/people/utilities'
import { pick } from 'utilities/object'
import { compact } from 'utilities/array'
import { ContainerQuery, Grid, LoadingContainer, Tutorial, EternalLoadingContainer } from './s'
import Loader from 'components/loader'
import Group from './group'
import { Ghost } from 'components/button'
import { Generic as Empty } from 'components/empty'

const Groups = ({ type, actions, className, salt }) => {
    const {
        groups,
        intersecter,
        paging = {},
        fetch,
        fetching,
        loading,
        autoFetch,
        hasFetched,
        filter = {}
    } = useGroups()

    const filtersApplied = !!compact(Object.values(pick(filter, ...filterFields))).flatMap(a => a).length
    const showTutorial = hasFetched && !filtersApplied && !groups.length
    const noResults = !groups.length && hasFetched && !!filtersApplied

    return (
        <ContainerQuery>
            {(!!fetching && !hasFetched) && (
                <LoadingContainer>
                    <Loader />
                </LoadingContainer>
            )}
            <Grid {...(className ? { className } : null)}>
                {groups.map((group, index) => {
                    const last = index + 1 === groups.length

                    return (
                        <Group
                            group={group}
                            actions={actions}
                            {...((!!intersecter && !last) ? { ref: intersecter } : null)}
                            key={`${salt}:${group.id}`} />
                    )
                })}
            </Grid>
            {(!!intersecter && !!paging?.hasNextPage) && (
                <EternalLoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost
                            className="constructive"
                            onClick={fetch}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </EternalLoadingContainer>
            )}
            {showTutorial && <Tutorial which={getPluralizedType(type)} />}
            {noResults && <Empty />}
        </ContainerQuery>
    )
}

const filterFields = ['search']

export default Groups
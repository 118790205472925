import styled from 'styled-components'
import { print } from 'utilities/styled'
import { h4Css } from 'components/typography/heading'
import { tooltipWrapperCss } from 'components/tooltip/s'
import { paragraphCss } from 'components/typography/paragraph'
import { OrderedList, ListItemChildShrink, ListItem } from 'components/list'
import { flexColumnsCss, flexRowsCss } from 'components/flex'

export const Container = styled.div`
    position: relative;

    border-radius: 8px;
    min-height: 164px;
    padding: 24px;
    box-shadow: inset 0 0 0 1px var(--huma-color-border-default);

    &.vertical {
        padding-block-end: 0;
    }

    ${print`
        .recharts-wrapper,
        .recharts-surface {
            width: 100% !important;
        }
    `}
`

export const ErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-height: inherit;
    height: 100%;

`

export const Heading = styled.h2`
    ${h4Css}

    margin-block-end: 8px;
`

export const Disc = styled.div`
    border-radius: 100%;
    width: 8px;
    height: 8px;
`

export const LegendList = styled(OrderedList)`
    &.horizontal {
        ${flexColumnsCss}

        flex-wrap: wrap;
    }

    &.vertical {
        ${flexRowsCss}

        align-items: flex-end;
    }
`

export const LegendItem = styled(ListItemChildShrink)`
    position: relative;

    margin-inline-end: 12px;
    padding-inline-start: 14px;

    line-height: 24px;
`

export const LegendDisc = styled(Disc).attrs({ as: 'span' })`
    position: absolute;
    left: 0;
    top: 50%;
    display: block;

    margin-block-start: -4px;
`

export const TooltipContainer = styled.div`
    ${tooltipWrapperCss}

    padding: 12px 16px;
`

export const TooltipHeading = styled.h3`
    ${paragraphCss}

    font-weight: 700;
`

export const TooltipList = styled(OrderedList)`
    line-height: 24px;
`

export const TooltipItem = styled(ListItem)`
    display: grid;
    grid-template-columns: minmax(0, auto) minmax(0, 1fr) minmax(0, auto);
    align-items: center;

    &.no-color {
        grid-template-columns: minmax(0, 1fr) minmax(0, auto);
    }
`

export const TooltipDisc = Disc

export const TooltipLabel = styled.span`
    margin-inline: 8px 24px;
`

export const TooltipValue = styled.strong`
    span {
        font-weight: 400;
        color: var(--huma-color-foreground-subtle);
    }
`
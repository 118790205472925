import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Label, Text, Icon, Content } from './s'
import { Info } from 'styled-icons/feather'
import { Scrollable as Modal } from 'modals/generic'
import Actions from 'components/form/actions'
import { Plain } from 'components/button'

const Beta = ({ showTooltip = true, ...props }) => {
    const [showingTooltip, setShowingTooltip] = useState(false)

    return (
        <>
            <Label
                {...props}
                {...(showTooltip ? {
                    onClick: () => setShowingTooltip(true),
                    className: 'clickable'
                } : {
                    as: 'span'
                })}>
                <Text>
                    <FormattedMessage
                        id="noun_beta"
                        defaultMessage="Beta" />
                </Text>
                {showTooltip && (
                    <Icon>
                        <Info size={16} />
                    </Icon>
                )}
            </Label>
            <Modal
                show={!!showingTooltip}
                dismiss={() => setShowingTooltip(false)}>
                <>
                    <Content className="compact">
                        <FormattedMessage
                            id="beta_message"
                            defaultMessage="To provide you with innovative and useful features, we don’t want each new idea to spend ages in the “lab” before letting the most important judge of its value, our customers, get access to it.\n\nThe Beta label is our way of saying that this feature might see a bigger change before it settles, based on whether it proves valuable to you." />
                    </Content>
                    <Actions className="centered">
                        <Plain
                            onClick={() => setShowingTooltip(false)}
                            className="neutral">
                            <FormattedMessage
                                id="action_close"
                                defaultMessage="Close" />
                        </Plain>
                    </Actions>
                </>
            </Modal>
        </>
    )
}

export default Beta
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useGetCompanyUrl, useGetCompanyDomain } from 'utilities/url'
import { Subsidiary, Name, Domain, FakeLink } from './s'
import Text from 'components/typography/caption'
import ImageLogo, { TextLogo } from 'components/logo'
import { ExternalLink } from 'styled-icons/feather'

const SubsidiaryItem = ({ subsidiary }) => {
    const {
        name,
        companyLogo,
        userCount: count,
        key
    } = subsidiary

    const companyUrl = useGetCompanyUrl()(key)
    const companyDomain = useGetCompanyDomain()(key)

    return (
        <Subsidiary
            href={companyUrl}
            target="_blank"
            className="constructive">
            <div>
                <Name className="compact">{name}</Name>
                <Domain className="compact">{companyDomain}</Domain>
            </div>
            <div>
                {!!companyLogo && (
                    <ImageLogo
                        logo={companyLogo}
                        className="small" />
                )}
                {!companyLogo && (
                    <TextLogo
                        title={name}
                        className="small"
                        textLength={name?.length}>
                        {name}
                    </TextLogo>
                )}
            </div>
            <div>
                <Text className="compact">
                    <FormattedMessage
                        id="people_count"
                        defaultMessage="{count, plural, =0 {No people} =1 {One person} other {{count} people}}"
                        values={{ count }} />
                </Text>
            </div>
            <div>
                <Text className="compact">
                    <FakeLink>
                        <ExternalLink size={16} />
                        <span>
                            <FormattedMessage
                                id="action_open"
                                defaultMessage="Open" />
                        </span>
                    </FakeLink>
                </Text>
            </div>
        </Subsidiary>
    )
}

export default SubsidiaryItem
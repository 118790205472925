import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from './s'
import FileDisplay from './display'
import FileLoader from './loader'
import Prompt from 'prompts/standard'

const FileDelete = ({ meta, onClick: remove, prompt, displayProps = null }) => {
    const { formatMessage } = useIntl()

    const [prompting, setPrompting] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const deleteFile = async () => {
        setDeleting(true)
        await remove(meta.id)
        setDeleting(false)
    }

    return (
        <>
            <Link
                onClick={() => {
                    if(prompt) {
                        setPrompting(true)
                    } else {
                        deleteFile()
                    }
                }}
                tabIndex={0}
                className="file destructive">
                {!!deleting && (
                    <FileLoader
                        {...displayProps}
                        name={meta?.fileName}
                        status={formatMessage({
                            id: 'status_deleting',
                            defaultMessage: 'Deleting…'
                        })} />
                )}
                {!deleting && (
                    <FileDisplay
                        {...displayProps}
                        name={meta?.fileName}
                        action="delete" />
                )}
            </Link>
            {(prompt && remove) && (
                <Prompt
                    show={prompting}
                    salt={`file:delete:${meta.id}`}
                    question={prompt.question}
                    cancel={{
                        ...(('cancelText' in prompt) ? { text: prompt.cancelText } : null),
                        callback: () => setPrompting(false)
                    }}
                    confirm={{
                        text: prompt.confirmText,
                        effect: 'destructive',
                        callback: deleteFile
                    }} />
            )}
        </>
    )
}

export default FileDelete
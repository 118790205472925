import React, { useCallback } from 'react'
import { Wrap } from './s'
import Tooltip, { useSingleton } from 'components/tooltip'
import Headings from './headings'
import Inlines from './inlines'
import Lists from './lists'
import Blocks from './blocks'
import Smart from './smart'
import Extras from './extras'

const Toolbar = ({ editor, every, setMarking, active, sticky = null, salt, ...props }) => {
    const [source, tooltipTarget] = useSingleton()

    const getTrigger = useCallback((method, ...args) => () => {
        const focused = editor.chain().focus()
        focused[method](...args).run()
        setMarking(null)
    }, [editor])

    const getCommand = useCallback((method, ...args) => () => {
        editor.commands[method](...args)
    }, [editor])

    const sectionProps = {
        ...props,
        editor,
        every,
        activate,
        getTrigger,
        getCommand,
        setMarking,
        active,
        tooltipTarget,
        salt
    }

    return (
        <Wrap {...(!!sticky ? {
            className: 'sticky',
            ...(!!sticky.spacing ? {
                style: {
                    '--sticky-spacing': `${sticky.spacing}px`
                }
            } : null)
        } : null)}>
            <Tooltip
                singleton={source}
                delay={[0, 250]} />
            <Headings {...sectionProps} />
            <Inlines {...sectionProps} />
            <Lists {...sectionProps} />
            <Blocks {...sectionProps} />
            <Smart {...sectionProps} />
            <Extras {...sectionProps} />
        </Wrap>
    )
}

const activate = active => active ? { className: 'active' } : null

export default Toolbar
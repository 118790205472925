import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { cls } from 'utilities/dom'
import { FlexChildShrink } from 'components/flex'
import CaptionBase from 'components/typography/caption'
import MessageBase from 'components/message'
import { Grid } from 'components/grid'
import { Button as ButtonBase } from 'components/button'

export const Wrap = FlexChildShrink
export const Caption = styled(CaptionBase).attrs(attrs => ({
    ...attrs,
    className: cls([attrs.className, 'compact'])
}))``

export const Message = styled(MessageBase)`
    margin-bottom: 16px;

    ${Caption} + & {
        margin-top: 8px;
    }
`

export const Heading = styled.h1`
    font-size: 24px;
    line-height: 40px;
    font-weight: 500;
`

export const Buttons = styled(Grid)`
    grid-template-columns: 1fr 24px 1fr;
    justify-content: start;
    align-items: center;
    column-gap: 16px;
    padding: 8px 0;

    svg {
        ${svgStroke()}
    }
`

export const EndButtonWrap = styled(Grid)`
    grid-auto-columns: auto;
    grid-auto-flow: column;
    column-gap: 2px;
`

export const Button = styled(ButtonBase)`
    justify-content: center;

    &&&,
    &&&:hover,
    &&&:focus { box-shadow: none; }

    ${EndButtonWrap} & {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.inactive {
        border-color: transparent;
        background-color: var(--huma-color-surface-minimal);

        color: var(--huma-color-foreground-default);

        &:hover {
            background-color: var(--huma-color-surface-minimal-hover);
        }
    }
`

export const ClearEndButton = styled(ButtonBase)`
    justify-content: center;
    padding: 4px;

    &.inactive {
        border-color: transparent;
        background-color: var(--huma-color-surface-minimal);

        color: var(--huma-color-foreground-default);

        &:hover {
            background-color: var(--huma-color-surface-minimal-hover);
        }
    }

    svg {
        ${svgStroke('small')}
    }

    ${EndButtonWrap} & {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSurveys } from 'contexts/surveys'
import { getActionsArray } from 'widgets/context-menu'
import { clamp } from 'utilities/math'
import {
    Wrap, Header,
    GridColumnHeader
} from './s'
import { SkeletonListRow, SkeletonCell, SkeletonStrings } from 'components/skeleton'
import Run from './run'

const EmployeeSatisfactionRuns = ({ actions = [], header = false, className }) => {
    const {
        runs,
        hasFetched
    } = useSurveys()

    const [skeletonLength, setSkeletonLength] = useState(1)

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(runs.length, 1, 10))
        }
    }, [hasFetched, runs?.length])

    const hasActions = !!getActionsArray(actions).length

    return (
        <Wrap {...(className ? { className } : null)}>
            {header && (
                <Header>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="noun_date"
                            defaultMessage="Date" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="employee_satisfaction_label_score"
                            defaultMessage="Score" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="employee_satisfaction_label_recipients"
                            defaultMessage="Recipients" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="employee_satisfaction_label_responses"
                            defaultMessage="Responses" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="employee_satisfaction_label_response_rate"
                            defaultMessage="Response rate" />
                    </GridColumnHeader>
                </Header>
            )}
            {!hasFetched && [...Array(skeletonLength).keys()].map(index => (
                <SkeletonListRow
                    columns={3}
                    hasActions={!!hasActions}
                    key={`list:surveys:skeleton:${index}`}>
                    <SkeletonCell>
                        <SkeletonStrings size={20} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings size={20} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings size={20} />
                    </SkeletonCell>
                </SkeletonListRow>
            ))}
            {runs.map(run => {
                // const last = index + 1 === runs.length

                return (
                    <Run
                        run={run}
                        actions={actions}
                        key={run.id} />
                )
            })}
        </Wrap>
    )
}

export default EmployeeSatisfactionRuns

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useReveal } from 'hooks/reveal'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { cls } from 'utilities/dom'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

const ViewPhoneNumber = ({ className, label, ...props }) => {
    const {
        field = {},
        controlProps = {},
        display,
        obscure = false
    } = props

    let { value } = field
    const [node, reveal] = useReveal(obscure)

    const displayClassName = cls([
        !!value && display,
        !value && 'empty',
        (!!value && obscure) && 'obscured',
        (!!value && reveal) && 'revealed'
    ])

    const controlClassName = cls([
        controlProps.className,
        display
    ])

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control {...(controlClassName ? { className: controlClassName } : null)}>
                <ValueDisplay
                    {...(displayClassName ? { className: displayClassName } : null)}
                    ref={node}>
                    {!!value && formatPhoneNumberIntl(value)}
                    {!value && (
                        <FormattedMessage
                            id="empty_not_entered"
                            defaultMessage="Not entered" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewPhoneNumber

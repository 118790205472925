import React, { forwardRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { getFullName } from 'utilities/person'
import { categoryToI18nMap, resolvedCaseStatuses } from 'pages/deviation/constants'
import { getCaseWarningOrError } from 'pages/deviation/utilities'
import { useOptionedString } from 'hooks/optioned-string'
import { getDeviationCaseUrl } from 'utilities/url'
import { Row, CaseLink, Category, CaseMeta, CaseNumber, RiskLevel } from './s'
import { CardList } from '../s'
import CaseStatus from 'pages/deviation/components/case-status'
import Person from 'components/person'
import { Plain } from 'components/button'
import { InlineMessage } from 'components/message'

const DeviationCase = forwardRef(({ kase, setEditingHandler, ...props }, ref) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()
    const isHandler = check('deviation:manage')

    const {
        number,
        category,
        status,
        risk,
        reporter,
        handler
    } = kase ?? {}

    const warningOrError = getCaseWarningOrError(kase)

    const optionizeString = useOptionedString()

    const reporterName = getFullName(reporter) ?? formatMessage({
        id: 'state_anonymous',
        defaultMessage: 'Anonymous'
    })

    return (
        <Row
            {...props}
            ref={ref}>
            <CardList.Cell
                $cell="meta"
                className="meta rows">
                <CaseLink to={getDeviationCaseUrl(kase)}>
                    {!!category?.key && (
                        <Category className="compact">
                            <FormattedMessage id={categoryToI18nMap[category.key]} />
                        </Category>
                    )}
                    <CaseMeta className="compact">
                        <CaseNumber>{number}</CaseNumber>
                        <span>{reporterName}</span>
                    </CaseMeta>
                </CaseLink>
            </CardList.Cell>
            <CardList.Cell
                $cell="status"
                className="status">
                <CaseStatus status={status} />
            </CardList.Cell>
            <CardList.Cell
                $cell="risk"
                className="risk">
                {!!risk && <RiskLevel level={risk} />}
            </CardList.Cell>
            <CardList.Cell
                $cell="handler"
                className="handler">
                {!!handler && (
                    <Person
                        who={handler}
                        size={16}
                        truncate={true} />
                )}
                {(!handler && !!isHandler) && (
                    <Plain
                        className="constructive small compact"
                        onClick={() => setEditingHandler(kase.id)}>
                        {optionizeString(formatMessage({
                            id: 'action_assign_to',
                            defaultMessage: 'Assign to'
                        }))}
                    </Plain>
                )}
            </CardList.Cell>
            {(warningOrError && !!isHandler && !resolvedCaseStatuses.includes(status)) && (
                <CardList.Cell
                    $cell="message"
                    className="message">
                    <InlineMessage
                        type={warningOrError.type}
                        message={<FormattedMessage {...warningOrError.message} />} />
                </CardList.Cell>
            )}
        </Row>
    )
})

export default DeviationCase

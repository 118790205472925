import CodeBlock from '@tiptap/extension-code-block'
import { mergeAttributes } from '@tiptap/react'

const CustomCodeBlock = CodeBlock.extend({
    renderHTML({ node, HTMLAttributes }) {
        const attributes = {
            ...mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            ...(node.attrs.language ? { 'data-language': node.attrs.language } : null)
        }

        return ['pre', attributes, 0]
    }
})

export default CustomCodeBlock
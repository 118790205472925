import styled, { css } from 'styled-components'
import {
    svgStroke,
    belowPhone
} from 'utilities/styled'
import {
    Grid,
    GridColumnHeader as GridColumnHeaderBase
} from 'components/grid'

export const Wrap = styled.div``

export const Header = styled(Grid)`
    grid-template-columns: 2fr repeat(2, 1fr) 40px;
    column-gap: 24px;
    width: 100%;
    border-bottom: 1px solid var(--huma-color-border-default);

    ${belowPhone`
        display: none;
    `}
`

const gridColumnHeaderCss = css`
    display: flex;
    align-items: center;

    height: 72px;
    padding: 24px 0;
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}
`

export const GridColumnActionsHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}
    justify-content: center;

    ${belowPhone`
        display: none;
    `}

    > svg {
        ${svgStroke()}
    }
`

export const LoadingContainer = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 96px;
`
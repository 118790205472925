import React, { forwardRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { mergeRefs } from 'react-merge-refs'
import { useFlash } from 'hooks/flash'
import { useCategoryFormatter } from 'pages/competence/components/category'
import { categoryToMetaMap } from 'pages/competence/constants/category'
import {
    RecordWrapper,
    CompetenceButton, CompetenceWrapper, Symbol, CompetenceTypeTitle, Category,
    BelongsToCell,
    StatusCell, InlineMessage,
    ActionsCell
} from './s'
import Tooltip from 'components/tooltip'
import Ellipsify from 'components/ellipsify'
import MobileLabel from 'components/typography/mobile-label'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'
import { symbol as symbolFallback } from 'pages/competence'

const CompetenceType = forwardRef(({ type, actions = {}, singleton, flash }, ref) => {
    const { formatMessage } = useIntl()
    const categoryFormatter = useCategoryFormatter()

    const flasher = useFlash(flash)

    const {
        id,
        category,
        userCount,
        missingAttachment,
        missingRequiredValidTo,
        missingRequiredFields,
        expired,
        expireSoon,
        symbol
    } = type

    const view = actions?.viewType(type)

    const [CompetenceCell, competenceProps] = view ?
        [CompetenceButton, { onClick: view.onClick }] :
        [CompetenceWrapper, null]

    const boundActions = getBoundActions(actions, type)

    const salt = `competence:type:${id}`

    return (
        <RecordWrapper ref={mergeRefs([ref, flasher])}>
            <Tooltip
                content={formatMessage({
                    id: 'action_view_details',
                    defaultMessage: 'View details'
                })}
                singleton={singleton}>
                <CompetenceCell {...competenceProps}>
                    <Symbol
                        symbol={symbol ?? symbolFallback}
                        className="hover-effect" />
                    <CompetenceTypeTitle>
                        <Ellipsify text={categoryFormatter(type)} />
                    </CompetenceTypeTitle>
                    {!!category && (
                        <Category className="compact">
                            <FormattedMessage {...categoryToMetaMap[category].title} />
                        </Category>
                    )}
                </CompetenceCell>
            </Tooltip>
            <BelongsToCell>
                <MobileLabel className="endspace">
                    <FormattedMessage
                        id="label_belongs_to"
                        defaultMessage="Belongs to" />
                </MobileLabel>
                {!!userCount && (
                    <FormattedMessage
                        id="people_count"
                        defaultMessage="{count, plural, =0 {No people} =1 {One person} other {{count} people}}"
                        values={{ count: userCount }} />
                )}
                {!userCount && '–'}
            </BelongsToCell>
            <StatusCell>
                {!!missingRequiredValidTo && (
                    <InlineMessage
                        message={formatMessage({
                            id: 'certifications_missing_expiry_day_count',
                            defaultMessage: '{count, plural, =0 {} =1 {One is missing expiry date} other {{count} are missing expiry date}}'
                        }, { count: missingRequiredValidTo })}
                        type="error" />
                )}
                {!!missingAttachment && (
                    <InlineMessage
                        message={formatMessage({
                            id: 'item_status_missing_documentation_count',
                            defaultMessage: '{count, plural, =0 {} =1 {Documentation missing} other {{count} are missing documentation}}'
                        }, { count: missingAttachment })}
                        type="error" />
                )}
                {!!missingRequiredFields && (
                    <InlineMessage
                        message={formatMessage({
                            id: 'item_status_missing_required_fields_count',
                            defaultMessage: '{count, plural, =0 {} =1 {1 is missing required values} other {{count} are missing required values}}'
                        }, { count: missingRequiredFields })}
                        type="error" />
                )}
                {!!expired && (
                    <InlineMessage
                        message={formatMessage({
                            id: 'item_status_expired_count',
                            defaultMessage: '{count, plural, =0 {} =1 {One has expired} other {{count} have expired}}'
                        }, { count: expired })}
                        type="error" />
                )}
                {!!expireSoon && (
                    <InlineMessage
                        message={formatMessage({
                            id: 'item_status_expiring_count',
                            defaultMessage: '{count, plural, =0 {} =1 {One expiring soon} other {{count} expiring soon}}'
                        }, { count: expireSoon })}
                        type="warning" />
                )}
            </StatusCell>
            {!!boundActions?.length && (
                <ActionsCell>
                    <ContextMenu
                        prebound actions={boundActions}
                        salt={salt} />
                </ActionsCell>
            )}
        </RecordWrapper>
    )
})

export default CompetenceType

import React, { Component } from 'react'
import { FormContext } from 'components/form/controller'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { Field, Label, Control } from 'components/form/field/s'
import Input from './input'
import { isPossiblePhoneNumber } from 'react-phone-number-input'

const empty = ''

export default class EditPhoneNumber extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const { value } = getFieldFromProps(props)
        this.state = { value }

        this.register()
    }

    componentDidMount() {
        this.context.triggerChange(this.props.name, { touched: false })
    }

    componentDidUpdate = ({ name, field }, { value }) => {
        const nameChanged = name !== this.props.name
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include

        if(nameChanged) {
            this.context.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(value !== this.state.value) {
            this.context.triggerChange(this.props.name)
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name)
    }

    register = (update = false) => {
        const {
            required,
            include
        } = getFieldFromProps(this.props)

        this.context.registerField(this.props.name, {
            empty,
            required,
            include,
            unset: this.unset,

            validator: value => {
                if(!value) {
                    return !required
                }

                return isPossiblePhoneNumber(value)
            }
        }, update)
    }

    onChange = value => {
        if(!value) {
            value = empty
        }

        this.setState({ value })

        const { name, onChange } = this.props
        onChange?.({ [name]: value })
    }

    unset = () => this.setState({ value: empty })

    render() {
        const { value } = this.state

        const {
            className,
            controlProps = {},
            salt,
            label,
            name,
            enabled = true,
            loading = false,
            display,
            ...props
        } = this.props

        const {
            required,
            softRequired,
            optional,
            errorBeforeTouched
        } = getFieldFromProps(this.props)

        const touched = this.context.touched.includes(name)
        const error = (name in this.context.errors) && (touched || errorBeforeTouched)

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const controlClassName = cls([
            error && 'error'
        ])

        const inputClassName = cls([
            controlProps.className,
            display
        ])

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control {...(controlClassName ? { className: controlClassName } : null)}>
                    <input
                        name={name}
                        value={value}
                        type="hidden" />
                    <Input
                        {...controlProps}
                        {...(inputClassName ? { className: inputClassName } : null)}
                        {...omit(props, 'field')}
                        {...{ value, id: salt }}
                        onChange={this.onChange}
                        disabled={!enabled} />
                </Control>
            </Field>
        )
    }
}

const getFieldFromProps = ({ field = {} }) => {
    let {
        value = empty,
        required = false,
        softRequired = false,
        include = 'touched',
        errorBeforeTouched = false,
        ...rest
    } = field

    if(!value && value !== 0) {
        value = empty
    }

    return {
        value,
        required,
        softRequired,
        include,
        errorBeforeTouched,
        ...rest
    }
}

import React, { Fragment } from 'react'
import { Scores, Score } from './s'
import Tooltip, { useSingleton } from 'components/tooltip'

const Rating = ({ value, max = 5, tooltips = [], ...props }) => {
    const [source, target] = useSingleton()

    if(!value) {
        return null
    }

    return (
        <Scores {...props}>
            <Tooltip singleton={source} />
            {Array.from(Array(max).keys()).map(index => {
                const [Wrapper, wrapperProps] = tooltips[index] ?
                    [Tooltip, {
                        singleton: target,
                        content: tooltips[index]
                    }] :
                    [Fragment, null]

                return (
                    <Wrapper
                        {...wrapperProps}
                        key={index}>
                        <Score $active={value === index + 1}>
                            {index + 1}
                        </Score>
                    </Wrapper>
                )
            })}
        </Scores>
    )
}

export default Rating

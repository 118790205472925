import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { format, endOfDay } from 'date-fns'
import { useI18n } from 'contexts/i18n'
import { useAccess } from 'contexts/access'
import { isofy } from 'utilities/date-time'
import { isNumeric } from 'utilities/math'
import { useCheckViewLevelAccess } from 'pages/competence/utilities'
import {
    Cell,
    DateCell, PeriodCell, LevelCell, LevelValue, StatusCell,
    ExpiryStatus, InlineMessage
} from './s'
import MobileLabel from 'components/typography/mobile-label'
import { ParagraphSmall } from 'components/typography/paragraph'
import { Plain } from 'components/button'

const CompetenceRecordColumn = ({ record, actions, id }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const {
        concerns,
        attachments,
        links,
        startDate,
        endDate,
        validFrom,
        validTo,
        fieldOfStudy,
        school,
        level,
        isNativeLanguage = false,
        type
    } = record

    const {
        category,
        documentationRequired = false,
        validFromRequired = false,
        validToRequired = false
    } = type

    const viewLevelAccess = useCheckViewLevelAccess()(record)

    let fromDate = category === 'certification' ? validFrom : startDate
    let toDate = category === 'certification' ? validTo : endDate

    fromDate = fromDate ? isofy(fromDate) : null
    toDate = toDate ? endOfDay(isofy(toDate)) : null

    const dateFormat = ['education', 'experience'].includes(category) ?
        formatMessage({
            id: 'date_format_full_dayless',
            defaultMessage: 'MMM yyyy'
        }) :
        'PP'

    const edit = actions?.edit?.(record)

    if(['validFrom', 'startDate'].includes(id)) {
        return (
            <DateCell key={id}>
                <MobileLabel className="endspace">
                    <FormattedMessage
                        id={category === 'certification' ?
                            'qualifications_heading_issued_date' :
                            'noun_start_date'
                        }
                        defaultMessage={category === 'certification' ?
                            'Issued date' :
                            'Start date'
                        } />
                </MobileLabel>
                {(!fromDate && validFromRequired) && (
                    <InlineMessage
                        message={formatMessage({
                            id: category === 'certification' ?
                                'competence_error_missing_issued_date' :
                                'message_end_start_missing',
                            defaultMessage: category === 'certification' ?
                                'No issued date' :
                                'No start date'
                        })}
                        size="small"
                        type="error" />
                )}
                {!!fromDate && (
                    <span>{format(fromDate, dateFormat, { locale })}</span>
                )}
            </DateCell>
        )
    }

    if(['validTo', 'endDate'].includes(id)) {
        return (
            <DateCell key={id}>
                <MobileLabel className="endspace">
                    <FormattedMessage
                        id={category === 'certification' ?
                            'qualifications_heading_expiry_date' :
                            'noun_end_date'
                        }
                        defaultMessage={category === 'certification' ?
                            'Expiry date' :
                            'End date'
                        } />
                </MobileLabel>
                {(!toDate && validToRequired) && (
                    <InlineMessage
                        message={formatMessage({
                            id: category === 'certification' ?
                                'competence_error_missing_expiry_date' :
                                'message_end_date_missing',
                            defaultMessage: category === 'certification' ?
                                'No expiry date' :
                                'No end date'
                        })}
                        size="small"
                        type="error" />
                )}
                {!!toDate && (
                    <span>{format(toDate, dateFormat, { locale })}</span>
                )}
            </DateCell>
        )
    }

    if(id === 'period') {
        return (
            <PeriodCell key={id}>
                <MobileLabel className="endspace">
                    <FormattedMessage
                        id="competence_type_label_period"
                        defaultMessage="Period" />
                </MobileLabel>
                <span>
                    <FormattedMessage
                        id="date_range"
                        defaultMessage="{from} - {to}"
                        values={{
                            from: fromDate ? format(fromDate, dateFormat, { locale }) : null,
                            to: toDate ? format(toDate, dateFormat, { locale }) : null
                        }} />
                </span>
            </PeriodCell>
        )
    }

    if(id === 'fieldOfStudy') {
        return (
            <Cell key={id}>
                <MobileLabel className="endspace">
                    <FormattedMessage
                        id="competence_type_label_field_of_study"
                        defaultMessage="Field of study" />
                </MobileLabel>
                <ParagraphSmall className="compact">{fieldOfStudy}</ParagraphSmall>
            </Cell>
        )
    }

    if(id === 'school') {
        return (
            <Cell key={id}>
                <MobileLabel className="endspace">
                    <FormattedMessage
                        id="competence_type_label_school"
                        defaultMessage="School" />
                </MobileLabel>
                <ParagraphSmall className="compact">{school}</ParagraphSmall>
            </Cell>
        )
    }

    if(id === 'level') {
        return (
            <LevelCell key={id}>
                <MobileLabel className="endspace">
                    <FormattedMessage
                        id="competence_type_label_level"
                        defaultMessage="Level" />
                </MobileLabel>
                {(!isNumeric(level) && !!manageAccess && concerns?.status?.active !== false) && (
                    <Plain
                        onClick={() => edit.onClick()}
                        className="constructive compact small">
                        <FormattedMessage
                            id="competence_action_add_level"
                            defaultMessage="Add level" />
                    </Plain>
                )}
                {isNumeric(level) && (
                    <LevelValue className="compact">
                        <span>{level}</span>
                        <span>
                            <FormattedMessage
                                id={`competence_${category}_level_${level}`}
                                defaultMessage={level} />
                        </span>
                        {(category === 'language' && !!isNativeLanguage) && (
                            <span>
                                <FormattedMessage
                                    id="competence_language_native"
                                    defaultMessage="Native / multilingual" />
                            </span>
                        )}
                    </LevelValue>
                )}
            </LevelCell>
        )
    }

    if(id === 'status') {
        const missingDocumentation = (!attachments?.length && !links?.length && documentationRequired)

        return (
            <StatusCell key={id}>
                {(['skill', 'language'].includes(category) && !isNumeric(level) && !!viewLevelAccess) && (
                    <InlineMessage
                        type="warning"
                        className="compact"
                        message={formatMessage({
                            id: 'competence_status_missing_level',
                            defaultMessage: 'Missing level'
                        })} />
                )}
                {category === 'certification' && (
                    <ExpiryStatus
                        from={fromDate}
                        to={toDate}
                        context="competence" />
                )}
                {missingDocumentation && (
                    <InlineMessage
                        type="error"
                        className="compact"
                        message={formatMessage({
                            id: 'qualification_missing_documentation',
                            defaultMessage: 'Missing documentation'
                        })}
                        size="small" />
                )}
            </StatusCell>
        )
    }

    return <Cell />
}

export default CompetenceRecordColumn

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useWorkSchedules } from 'contexts/work-schedules'
import { useTheme, ThemeProvider } from 'styled-components'
import { getActionsArray } from 'widgets/context-menu'
import { Wrap, Header, LoadingContainer, GridColumnHeader, GridColumnActionsHeader } from './s'
import Loader from 'components/loader'
import Schedule from './schedule'

const WorkSchedules = ({ actions = {}, header = false, className }) => {
    const {
        schedules,
        hasFetched
    } = useWorkSchedules()

    let theme = useTheme()

    if(!hasFetched && !schedules.length) {
        return (
            <LoadingContainer>
                <Loader />
            </LoadingContainer>
        )
    }

    const hasActions = !!getActionsArray(actions).length

    theme = {
        ...theme,
        workSchedules: {
            actions: hasActions
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Wrap {...(className ? { className } : null)}>
                {!!header && (
                    <Header>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_name"
                                defaultMessage="Name" />
                        </GridColumnHeader>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_assigned"
                                defaultMessage="Assigned" />
                        </GridColumnHeader>
                        <GridColumnHeader />
                        {hasActions && <GridColumnActionsHeader />}
                    </Header>
                )}
                {schedules.map(schedule => (
                    <Schedule
                        schedule={schedule}
                        actions={actions}
                        salt={`absence:work-schedules:${schedule.id}`}
                        key={`absence:work-schedules:${schedule.id}`} />
                ))}
            </Wrap>
        </ThemeProvider>
    )
}

export default WorkSchedules

import styled, { css } from 'styled-components'
import {
    svgStroke, deactivated, symbolHoverCss,
    belowTablet
} from 'utilities/styled'
import { flexColumnsCss, flexChildShrinkCss } from 'components/flex'
import { Link } from 'react-router-dom'
import { Ghost, Naked } from 'components/button'

const avatarColumnsCss = css`
    ${flexColumnsCss}
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    overflow: hidden;

    &.overflow {
        overflow: visible;
    }

    &.align-start {
        justify-content: start;
    }

    &:not(.deactivated) {
        --avatar-background: ${p => p.$fallback?.background};
        --avatar-color: ${p => p.$fallback?.color};
    }

    &.deactivated {
        --avatar-background: var(--huma-color-surface-subtle);
        --avatar-color: var(--huma-color-foreground-disabled);

        img {
            ${deactivated}
        }
    }
`

export const AvatarColumnsBlock = styled.div`
    ${avatarColumnsCss}
`

export const AvatarColumnsInline = styled.span`
    ${avatarColumnsCss}
`

export const AvatarColumnsLink = styled(Link)`
    ${avatarColumnsCss}
    text-decoration: none;
`

export const CentererBlock = styled.div`
    ${flexChildShrinkCss}
`

export const CentererInline = styled.span`
    ${flexChildShrinkCss}
`

const avatarCss = css`
    display: block;
    width: ${p => p.$size ? `${p.$size}px` : 'auto'};
    height: ${p => p.$size ? `${p.$size}px` : 'auto'};
    border-radius: 100%;

    ${belowTablet`
        width: ${p => p.$size > 72 && `${p.$size * .75}px`};
        height: ${p => p.$size > 72 && `${p.$size * .75}px`};
    `}
`

export const AvatarImage = styled.img`
    ${avatarCss}
`

export const AvatarAnimation = styled.div``

const avatarFallbackCss = css`
    ${avatarCss}
    padding: ${p => p.$size ? `${p.$size * .275}px 0 ${p.$size * .225}px` : '5px 0 3px'};
    font-size: ${p => p.$size ? `${p.$size / 2}px` : '16px'};
    line-height: 1;
    font-weight: 500;
    letter-spacing: -.05em;
    text-align: center;

    background: var(--avatar-background);
    color: var(--avatar-color);

    &.icon {
        display: grid;
        place-items: center;
        padding: 0;
        ${svgStroke('small')}
    }

    ${belowTablet`
        @supports (font-size: min(72px, 24px)) {
            font-size: min(${p => p.$size ? `${p.$size / 2}px` : '16px'}, 24px);
        }
    `}
`

export const AvatarFallbackBlock = styled.div`
    ${avatarFallbackCss}
`

export const AvatarFallbackInline = styled.span`
    ${avatarFallbackCss}
`

const buttonCss = css`
    justify-content: center;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 0;

    svg {
        ${svgStroke('small')}
    }
`

export const Button = styled(Naked)`
    ${buttonCss}
`

export const AddButton = styled(Ghost).attrs({ className: 'constructive' })`
    ${buttonCss}
`

const processPostButtonHoverCss = css`
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        border-radius: 50%;
        border: 2px solid var(--button-background-color);
        width: 100%;
        height: 100%;

        scale: 1;
        transform-origin: center;
        transition: scale .2s cubic-bezier(0, .85, .40, 1.45);
    }

    &:where(:hover, :focus-visible) {
        ${symbolHoverCss}

        &::before {
            scale: 1.2;
        }
    }
`

export const ConcernsButton = styled(Naked)`
    ${buttonCss}

    --button-background-color: var(--huma-color-process-post-surface-1);
    --button-text-color: var(--huma-color-process-post-foreground-1);

    ${processPostButtonHoverCss}
`

export const ResponsibleButton = styled(Naked)`
    ${buttonCss}

    --button-background-color: var(--huma-color-process-post-surface-2);
    --button-text-color: var(--huma-color-process-post-foreground-2);

    ${processPostButtonHoverCss}
`

export const BuddyButton = styled(Naked)`
    ${buttonCss}

    --button-background-color: var(--huma-color-process-post-surface-3);
    --button-text-color: var(--huma-color-process-post-foreground-3);

    ${processPostButtonHoverCss}
`

export const SupervisorButton = styled(Naked)`
    ${buttonCss}

    --button-background-color: var(--huma-color-process-post-surface-4);
    --button-text-color: var(--huma-color-process-post-foreground-4);

    ${processPostButtonHoverCss}
`

export const GroupButton = styled(Naked)`
    ${buttonCss}
`

const ancestorHoverCss = css`
    ${symbolHoverCss}

    &::before {
        transform: scale(1.2);
    }
`

export const ancestorHover = Ancestor => css`
    & ${AvatarColumnsBlock} {
        overflow: revert;
    }

    & ${AvatarAnimation} {
        position: absolute;
        inset: 0;

        &::before {
            border: 2px solid var(--avatar-background);
        }
    }

    & ${AvatarFallbackBlock} {
        position: relative;

        &::before {
            border: 2px solid var(--avatar-background);
        }
    }

    & ${AvatarAnimation},
    & ${AvatarFallbackBlock} {
        transition-property: background-color, filter;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;

            border-radius: 50%;
            width: 100%;
            height: 100%;
            transform: scale(1);
            transform-origin: center;

            transition: transform .2s cubic-bezier(0, .85, .40, 1.45);
        }
    }

    ${() => Ancestor && css`
        ${Ancestor}:where(:hover, :focus) & {
            & ${AvatarAnimation},
            & ${AvatarFallbackBlock} {
                ${ancestorHoverCss}
            }
        }
    `}

    &:where(:hover, :focus) ${AvatarAnimation},
    &:where(:hover, :focus) ${AvatarFallbackBlock} {
        ${ancestorHoverCss}
    }
`

export const AvatarDeletedWrapper = styled.div`
    display: grid;
    place-items: center;

    width: ${p => p.$size ? `${p.$size}px` : 'auto'};
    height: ${p => p.$size ? `${p.$size}px` : 'auto'};
    border-radius: 100%;
    background-color: var(--huma-color-surface-subtle);

    ${belowTablet`
        width: ${p => p.$size > 72 && `${p.$size * .75}px`};
        height: ${p => p.$size > 72 && `${p.$size * .75}px`};
    `}
`
import styled, { css } from 'styled-components'
import { bp } from 'utilities/styled'
import { Columns, FlexChildShrink } from 'components/flex'
import { Naked } from 'components/button'

// 7 rows
export const Wrap = styled(FlexChildShrink)`
    margin: 0 auto;
    max-width: 336px;
    height: 294px;

    ${bp.below(400)`
        max-width: 280px;
    `}
`

export const Row = styled(Columns)`
    justify-content: space-between;
`

export const Cell = styled(FlexChildShrink)`
    margin: 1px 0;
    width: 40px;
    height: 40px;
    position: relative;
    isolation: isolate;

    &:first-child {
        width: 56px;
    }

    ${bp.below(400)`
        &:first-child {
            display: none;
        }
    `}
`

const header = css`
    font-size: 14px;
    line-height: 40px;
    color: var(--huma-color-foreground-subtle);
`

export const WeekdayName = styled.p`
    ${header}
    text-align: center;
`

export const WeekNumber = styled.p`
    ${header}
    width: 32px;
    background-color: var(--huma-color-surface-minimal);
    text-align: center;

    ${Row}:not(:last-of-type) & {
        box-shadow: 0 2px 0 0 var(--huma-color-surface-minimal);
    }

    ${Row}:nth-of-type(2) & {
        border-radius: 4px 4px 0 0;
    }

    ${Row}:last-of-type & {
        border-radius: 0 0 4px 4px;
    }
`

export const Range = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    &:not(:only-child) {
        background-color: var(--huma-color-surface-constructive-minimal);

        &.disabled {
            background-color: var(--huma-color-surface-disabled);
        }
    }

    ${Cell}:nth-of-type(2) &:not(.start):not(.end) {
        border-radius: 4px 0 0 4px;
    }

    ${Cell}:last-of-type &:not(.start):not(.end) {
        border-radius: 0 4px 4px 0;
    }

    &.month-end {
        background: linear-gradient(
            90deg,
            transparent 0%,
            var(--huma-color-surface-constructive-minimal) 100%
        );
    }

    &.month-start {
        background: linear-gradient(90deg,
            var(--huma-color-surface-constructive-minimal) 0%,
            transparent 100%
        );
    }

    &.start {
        border-radius: 100% 0 0 100%;
    }

    &.end {
        border-radius: 0 100% 100% 0;
    }
`

export const Day = styled(Naked)`
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    border-radius: 100%;
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 2px solid transparent;
        border-radius: 100%;
        pointer-events: none;
        transition: border-color .1s ease-in-out;
    }

    &.today:not(.selected) {
        background-color: rgb(from var(--huma-color-surface-constructive-minimal) r g b / .5);

        transition: background-color .15s ease-in-out;

        color: var(--huma-color-foreground-on-constructive-bold);

        &[class*="range-"] {
            background: rgba(0, 0, 0, .05);
        }
    }

    &.selected {
        background-color: var(--huma-color-surface-constructive-bold);

        transition: 0s;

        color: var(--huma-color-foreground-on-bold);
    }

    &:not([disabled]) {
        font-weight: 500;

        &:not(.selected):where(:hover, :focus-visible)::after {
            border-color: var(--huma-color-surface-constructive-bold);
        }

        &.selected:where(:hover, :focus-visible)::after {
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            top: 2px;
            left: 2px;
            border-color: var(--huma-color-surface-default);
        }
    }

    &.weekend {
        &:where(:not(.selected)) {
            color: var(--huma-color-foreground-minimal);
        }
    }

    &.holiday {
        &:where(:not(.selected)) {
            color: var(--huma-color-surface-destructive-bold);
        }
    }

    &:is([disabled]) {
        position: relative;
        cursor: not-allowed;

        text-decoration: line-through;
        color: var(--huma-color-foreground-disabled);

        &.today {
            background-color: var(--huma-color-surface-subtle);

            color: var(--huma-color-foreground-disabled);
        }

        &.selected {
            background-color: rgb(from var(--huma-color-surface-inverted) r g b / .5);

            color: var(--huma-color-foreground-inverted);
        }
    }
`

export const Circle = styled.div`
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    border: 2px solid var(--huma-color-border-constructive-bold);
    border-radius: 100%;
    width: 100%;
    height: 100%;
`

export const Mark = styled.div`
    pointer-events: none;

    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 6px;

    transform: translateX(-50%);

    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--huma-color-foreground-default);

    ${Day}.selected + & {
        background-color: white;
    }
`

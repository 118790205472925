import styled from 'styled-components'
import { OrderedList, ListItem } from 'components/list'
import Paragraph from 'components/typography/paragraph'
import { h3Css } from 'components/typography/heading'

export const DataPoints = OrderedList

export const DataPoint = styled(ListItem)`
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, auto);
    grid-column-gap: 8px;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid var(--huma-color-border-default);
    padding: 16px 0;

    transition: background .5s ease-out;

    &:first-child {
        border-radius: 8px 8px 0 0;
    }

    &:last-child {
        border-bottom: none;
        border-radius: 0 0 8px 8px;
    }

    &.interactive {
        padding: 16px;

        &:hover {
            background-color: var(--huma-color-surface-minimal);

            transition: background-color .15s ease-in-out;
        }
    }

    &.colorized {
        grid-template-columns: minmax(0, auto) minmax(0, 1fr) minmax(0, auto);
    }
`

export const Disc = styled.div`
    border-radius: 100%;
    width: 8px;
    height: 8px;
`

export const Label = Paragraph

export const Value = styled(Paragraph)`
    padding-inline-start: 32px;

    font-weight: 700;

    &.brave {
        ${h3Css}
        margin: 0;
        font-size: 16px;
    }

    span {
        font-weight: 400;
        color: var(--huma-color-foreground-subtle);
    }
`
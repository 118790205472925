import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useDebounce } from 'hooks/debounce'
import { useI18n } from 'contexts/i18n'
import { isInRange } from 'utilities/math'
import { xor } from 'utilities/operator'
import {
    parse, format,
    getDate, getMonth, getYear, getDaysInMonth,
    startOfDay, endOfDay, isAfter, isBefore, isSameDay, isWithinInterval
} from 'date-fns'
import { isofy } from 'utilities/date-time'
import { omit, size, each } from 'utilities/object'
import { capitalize } from 'utilities/string'
import { compact, prune } from 'utilities/array'
import { cls } from 'utilities/dom'
import { Columns, Column, Field, Text, Select, Label, Helper, UnsetValueButton } from './s'
import { UnsetValueButtonXCircle } from 'components/button'
import { X } from 'styled-icons/feather'
import { Error } from 'components/form/field/s'

const Inputs = ({ salt, enabled, value, autoCompleteYear, ...props }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const dateRefs = {
        day: useRef(),
        month: useRef(),
        year: useRef()
    }

    const now = new Date()

    const [touched, setTouched] = useState([])
    const dateValue = !!value ? new Date(value) : null
    const emptyInitialValue = useMemo(() => !dateValue, [])

    const [date, setDate] = useState(!!dateValue ? {
        day: getDate(dateValue),
        month: getMonth(dateValue) + 1,
        year: getYear(dateValue)
    } : {
        day: '',
        month: '',
        year: ''
    })

    const [errors, setErrors] = useDebounce({})

    const {
        showDay,
        showYear,
        picker = {},
        unsettable,
        unset,
        ...rest
    } = props

    const {
        past = true,
        future = true,
        before = null,
        after = null,
        monthAsSelect = false,
        blockedDateRanges
    } = picker

    const valiDate = (date, update = false) => {
        if(!date) {
            return null
        }

        let { day, month, year } = date

        if(!showDay) {
            day = 1
        }

        const monthIndex = month - 1
        const monthDayCount = getDaysInMonth(new Date(year, monthIndex))
        const dayInRange = isInRange({ min: 1, max: monthDayCount, value: day })
        const parsedDate = new Date(year, monthIndex, day)
        const errors = {}

        const messages = {
            invalid: formatMessage({
                id: 'time_error_invalid',
                defaultMessage: 'Invalid date'
            }),
            outOfRange: formatMessage({
                id: 'time_error_date_of_month_year',
                defaultMessage: 'That combination of month and year does not contain that day'
            }),
            missingValues: formatMessage({
                id: 'time_error_missing_values',
                defaultMessage: 'Missing date value(s)'
            })
        }

        if(touched.includes('day') && !dayInRange) {
            errors.day = messages.outOfRange
        }

        // Disallow future dates: Disable if date is after end of today
        if(!future && isAfter(parsedDate, endOfDay(now))) {
            errors.future = messages.invalid
        }

        // Disallow dates after the given date
        if(!!after) {
            const isoAfter = isofy(after)

            if(!isAfter(parsedDate, endOfDay(isoAfter))) {
                errors.after = messages.invalid
            }
        }

        // Disallow past dates: Disable if date is before start of today
        if(!past && isBefore(parsedDate, startOfDay(now))) {
            errors.past = messages.invalid
        }

        // Disallow dates before the given date
        if(!!before) {
            const isoBefore = isofy(before)

            if(!isBefore(parsedDate, startOfDay(isoBefore))) {
                errors.before = messages.invalid
            }
        }

        each(date, (_, key) => {
            if(touched.includes(key) && !date[key]) {
                errors[key] = messages.missingValues
            }
        })

        if(!!year && touched.includes('year') && year < 1900 && document.activeElement !== dateRefs.year.current) {
            errors.year = messages.invalid
        }

        if(!!blockedDateRanges?.length) {
            const isInBlockedDateRanges = blockedDateRanges
                .map(({ on, start, end }) => {
                    if(on) {
                        return isSameDay(parsedDate, isofy(on))
                    }

                    if(!start || !end) {
                        return false
                    }

                    return isWithinInterval(parsedDate, {
                        start: startOfDay(start),
                        end: endOfDay(end)
                    })
                })
                .some(Boolean)

            if(isInBlockedDateRanges) {
                errors.future = messages.invalid
            }
        }

        const result = size(errors) ?
            errors :
            {}

        if(update) {
            setErrors(result)
        }

        return result
    }

    useEffect(() => {
        let { day, month, year } = date

        if(!showDay) {
            day = 1
        }

        let value = null
        let errors = {}
        let isInitiallyInvalid = false

        if(day || month || year) {
            errors = valiDate(date)

            if(!size(errors)) {
                const monthIndex = month - 1
                isInitiallyInvalid = (!!emptyInitialValue && !!touched?.length && (!day || !month || !year))
                value = new Date(year, monthIndex, day)
            }
        }

        setErrors(errors)

        if(touched?.length) {
            props.onChange(value, errors, isInitiallyInvalid)
        }
    }, [date, touched])

    const fieldOrder = formatMessage({
        id: compact([
            'date_order',
            !showDay && 'dayless',
            !showYear && 'yearless',
        ]).join('_'),
        defaultMessage: compact([
            'month',
            showDay && 'day',
            showYear && 'year'
        ]).join(', ')
    }).split(',').map(key => key.trim())

    const onKeyDown = e => {
        const { target, keyCode } = e
        let { name, value } = target
        value = parseInt(value)
        const key = keys[keyCode]

        if(key === 'up') {
            if(name === 'day') {
                const { year, month } = date
                const monthDayCount = (!!year && !!month) ? getDaysInMonth(new Date(year, month - 1)) : 31

                if(value === monthDayCount) {
                    setDate({
                        ...date,
                        [name]: 1
                    })
                }
            }

            if(name === 'month' && value === 12) {
                setDate({
                    ...date,
                    [name]: 1
                })
            }
        }

        if(key === 'down') {
            if(name === 'day' && value === 1) {
                const { year, month } = date
                const monthDayCount = (!!year && !!month) ? getDaysInMonth(new Date(year, month - 1)) : 31

                setDate({
                    ...date,
                    [name]: monthDayCount
                })
            }

            if(name === 'month' && value === 1) {
                setDate({
                    ...date,
                    [name]: 12
                })
            }
        }
    }

    const onKeyUp = e => {
        const { target, keyCode } = e
        const { name, value } = target
        const isNumeric = (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)

        if(!isNumeric) {
            return
        }

        const unitIndex = fieldOrder.indexOf(name)
        if(unitIndex === fieldOrder.length - 1) {
            return
        }

        const digit = parseInt(String.fromCharCode(keyCode), 10)

        if(
            (name === 'day' && ((value.length === 1 && digit > 3) || value.length === 2)) ||
            (name === 'month' && ((value.length === 1 && digit > 1) || value.length === 2)) ||
            (name === 'year' && value.length === 4)
        ) {
            dateRefs[fieldOrder[unitIndex + 1]]?.current?.focus()
        }
    }

    const onChange = e => {
        const { target } = e
        let { name, value } = target

        setTouched(prune([
            ...touched,
            name
        ]))

        value = !!value ?
            parseInt(value, 10) :
            ''

        if(!!value && name === 'day') {
            if(value > 31) {
                value = 31
            }
        }

        if(!!value && name === 'month') {
            if(value > 12) {
                value = 12
            }
        }

        if(!!value && name === 'year') {
            const previousCentury = 19

            if(value.toString().length === 2 && value >= parseInt(format(now, 'yy'), 10) && (parse) && autoCompleteYear) {
                value = `${previousCentury}${value}`
            }
        }

        if(date?.[name] !== value) {
            setDate({
                ...date,
                [name]: value
            })
        }
    }

    const getClassName = unit => {
        const className = cls([
            !showDay && 'dayless',
            (
                (unit === 'day' && !!size(errors) && errors.day === true) ||
                !!errors[unit]
            ) && 'error'
        ])

        return !!className.length ?
            { className } :
            null
    }

    const fieldProps = {
        type: 'number',
        step: 1,
        onKeyDown,
        onKeyUp,
        onChange,
        disabled: !enabled,
        day: {
            min: 1,
            max: 31,
            ref: dateRefs.day
        },
        month: {
            min: 1,
            max: 12,
            ref: dateRefs.month
        },
        year: {
            min: 1900,
            max: 2200,
            ref: dateRefs.year
        }
    }

    const getPlaceholderId = unit => ({
        day: 'date_abbreviation_day',
        month: 'date_abbreviation_month',
        year: 'date_abbreviation_year_full'
    })[unit]

    let monthNames

    if(monthAsSelect) {
        monthNames = Array(12)
            .fill(null)
            .map((_, index) => locale?.localize?.month?.(index) ?? `Month ${(index + 1)}`)
            .map(capitalize)
    }

    return (
        <>
            <Columns {...(!!size(errors) ? { className: 'errored' } : null)}>
                {fieldOrder.map(unit => {
                    const twoColumns = xor(showDay, showYear)

                    salt = `${salt}:${unit}`

                    return (
                        <Column
                            {...(twoColumns ? { className: 'two-columns' } : null)}
                            key={salt}>
                            <Field className="compact">
                                {(monthAsSelect && unit === 'month') && (
                                    <Select
                                        {...getClassName('month')}
                                        {...rest}
                                        id={salt}
                                        name="month"
                                        value={date?.month ?? ''}
                                        onChange={({ target: { value: month } }) => {
                                            month = month ?
                                                Number(month) :
                                                null

                                            setTouched(touched => prune([
                                                ...touched,
                                                'month'
                                            ]))

                                            setDate(date => ({
                                                ...date,
                                                month
                                            }))
                                        }}
                                        disabled={!enabled}
                                        ref={fieldProps.month.ref}>
                                        <option
                                            value=""
                                            disabled={true}
                                            key={`${salt}:month:undecided`} />
                                        {monthNames.map((name, index) => (
                                            <option
                                                value={index + 1}
                                                key={`${salt}:month:${index + 1}`}>
                                                {name}
                                            </option>
                                        ))}
                                    </Select>
                                )}
                                {(!monthAsSelect || unit !== 'month') && (
                                    <Text
                                        {...getClassName(unit)}
                                        {...rest}
                                        {...(omit(fieldProps, 'day', 'month', 'year'))}
                                        {...fieldProps[unit]}
                                        onKeyDown={e => {
                                            if(Array.from(Array(10).keys()).includes(Number(e.key))) {
                                                if(unit === 'day' && e.target.value.length >= 2) {
                                                    e.preventDefault()
                                                }

                                                if(unit === 'year' && e.target.value.length >= 4) {
                                                    e.preventDefault()
                                                }
                                            }
                                        }}
                                        onBlur={() => {
                                            if(unit === 'year') {
                                                valiDate(date, true)
                                            }
                                        }}
                                        id={salt}
                                        name={unit}
                                        value={date?.[unit]}
                                        placeholder={formatMessage({
                                            id: getPlaceholderId(unit),
                                            defaultMessage: unit
                                        })} />
                                )}
                                <Label
                                    className="vertical"
                                    htmlFor={salt}>
                                    <FormattedMessage
                                        id={`date_label_${unit}`}
                                        defaultMessage={unit} />
                                </Label>
                            </Field>
                        </Column>
                    )
                })}
                {(!!unsettable && !!Object.values(date).join('').length) && (
                    <Column className="reset">
                        <UnsetValueButton onClick={() => {
                            setDate({
                                day: '',
                                month: '',
                                year: ''
                            })

                            unset?.()
                        }}>
                            <UnsetValueButtonXCircle>
                                <X size={12} />
                            </UnsetValueButtonXCircle>
                        </UnsetValueButton>
                    </Column>
                )}
            </Columns>
            {(!!before && !after) && (
                <Helper animate={!errors ? 'reveal' : 'hide'}>
                    <FormattedMessage
                        id="time_helper_before"
                        defaultMessage="The date must be before {date}"
                        values={{ date: format(isofy(before), 'PP', { locale }) }} />
                </Helper>
            )}
            {(!!after && !before) && (
                <Helper animate={!errors ? 'reveal' : 'hide'}>
                    <FormattedMessage
                        id="time_helper_after"
                        defaultMessage="The date must be after {date}"
                        values={{ date: format(isofy(after), 'PP', { locale }) }} />
                </Helper>
            )}
            {(!!after && !!before) && (
                <Helper animate={!errors ? 'reveal' : 'hide'}>
                    <FormattedMessage
                        id="time_helper_between"
                        defaultMessage="The date must be between {beforeDate} and {afterDate}"
                        values={{
                            beforeDate: format(isofy(before), 'PP', { locale }),
                            afterDate: format(isofy(after), 'PP', { locale })
                        }} />
                </Helper>
            )}
            <Error
                className="error"
                animate={!!size(errors) ? 'reveal' : 'hide'}>
                {Object.values(errors)[0]}
            </Error>
        </>
    )
}

export default Inputs

const keys = {
    38: 'up',
    40: 'down'
}
import React, { useCallback, useState, useLayoutEffect, forwardRef } from 'react'
import { useI18n } from 'contexts/i18n'
import { compact } from 'utilities/array'
import PhoneInput from 'react-phone-number-input'
import { flexColumnsStyle, SelectColumn, Flag, Select, Text } from './s'
import { FlexChild } from 'components/flex'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

const Input = props => {
    const { territories } = useI18n()

    return (
        <PhoneInput
            defaultCountry={Object.keys(territories)[0]}
            inputComponent={StyledInput}
            countrySelectComponent={CountrySelectWithFlag}
            countrySelectProps={{ unicodeFlags: true }}
            labels={territories}
            style={flexColumnsStyle}
            {...props} />
    )
}

const StyledInput = forwardRef((props, ref) => (
    <FlexChild>
        <Text {...props} ref={ref} />
    </FlexChild>
))

// eslint-disable-next-line no-unused-vars
const CountrySelect = ({ value, onChange, options, unicodeFlags, iconComponent, ...props }) => {
    const {
        locale,
        sortedCountryCodes
    } = useI18n()

    const [sortedOptions, setSortedOptions] = useState(options)

    const onCountrySelected = useCallback(({ currentTarget }) => {
        const { value } = currentTarget
        currentTarget.blur()
        onChange(value === 'ZZ' ? undefined : value)
    }, [onChange])

    useLayoutEffect(() => {
        const unknown = {
            ...options.find(option => !option?.value),
            value: 'ZZ'
        }

        const sortedOptions = compact([
            unknown,
            ...sortedCountryCodes.map(code => options.find(({ value }) => code === value))
        ])

        setSortedOptions(sortedOptions)
    }, [locale])

    return (
        <Select
            {...props}
            value={value ?? 'ZZ'}
            onChange={onCountrySelected}>
            {sortedOptions.map(({ value, label }) => (
                <option
                    value={value}
                    key={value ?? 'ZZ'}>
                    {label}
                </option>
            ))}
        </Select>
    )
}

const CountrySelectWithFlag = ({ value, options, ...props }) => (
    <SelectColumn>
        <CountrySelect
            {...props}
            value={value}
            options={options} />
        <Flag>
            {!!value && getUnicodeFlagIcon(value)}
            {!value && '🌐'}
        </Flag>
    </SelectColumn>
)

export default Input
import { useSmartEntity } from 'contexts/smart-entity'
import { usePossum } from 'hooks/possum'
import { getShortName } from 'utilities/person'
import { getPeopleProfileUrl, getPeopleTeamUrl, getPeopleLocationUrl } from 'utilities/url'

export const useGetMeta = () => {
    const smartEntityContext = useSmartEntity()
    const possessify = usePossum()

    return source => {
        if(!source) {
            return {}
        }

        const value = smartEntityContext?.getValue(source) ?? null
        if(!value) {
            return {}
        }

        const name = nameFormatters[source.type]?.(value) ?? null

        return {
            name,
            pname: possessify(name),
            to: urlGetters[source.type]?.(value) ?? null
        }
    }
}

const nameFormatters = {
    universal: universal => universal.name,
    organization: organization => organization.name,
    user: user => getShortName(user),
    team: team => team.name,
    location: location => location.name
}

const urlGetters = {
    user: getPeopleProfileUrl,
    team: getPeopleTeamUrl,
    location: getPeopleLocationUrl
}
import React, { Component, createRef } from 'react'
import { useIntl } from 'react-intl'
import { useForm } from 'components/form/controller'
import isEqual from 'react-fast-compare'
import { prune, without, last } from 'utilities/array'
import { cls } from 'utilities/dom'
import { isInRange } from 'utilities/math'
import { omit } from 'utilities/object'
import { Field, Label, Control } from 'components/form/field/s'
import { FakeText, Tag, TextWrapper, KeyHintInner, Text, Helper } from '../s'
import { emailAddressPattern } from 'utilities/regex'
import Search from './search'
import { CornerDownLeft as Enter } from 'styled-icons/feather'

const empty = []

class EditArray extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this.valueStateFromProps(props),
            adderError: false,
            focus: false,
            deleteHighlight: false
        }

        this.register()
        this.adder = createRef()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        let {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        let {
            min = 0,
            max = Infinity
        } = this.props.picker ?? {}

        if(required && min === 0) {
            min = 1
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                value: empty,
                adderError: false,
                focus: false,
                deleteHighlight: false
            }),

            validator: value => required ?
                isInRange({ min, max, value: value.length }) :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: prune(props.field?.value || empty)
    })

    onChange = () => {
        const { onChange, name } = this.props
        const { value } = this.state

        onChange?.({ [name]: value })
    }

    onKeyDown = e => {
        const state = { deleteHighlight: false }

        const { keyCode } = e
        const { current: adder } = this.adder
        const { value, type } = adder

        if(keyCode in addKeys) {
            e.preventDefault()

            state.adderError = !this.isAdderValueValid()

            if(!state.adderError) {
                this.addItem(value)
                adder.value = ''

                return
            }
        }

        if(keyCode in removeKeys) {
            if(!value) {
                e.preventDefault()

                // Two-step removal
                if(this.state.deleteHighlight) {
                    this.removeItem(last(this.state.value))
                } else {
                    this.setState({ deleteHighlight: true })
                }
            }

            return
        }

        // Prevent adding certain symbols when working with emails
        if(type === 'email' && keyCode in emailRestrictedKeys) {
            e.preventDefault()
        }

        this.setState(state)
    }

    addItem = item => {
        item = item.trim()
        if(!item) {
            return
        }

        const value = prune([
            ...this.state.value,
            item
        ])

        const {
            name,
            onChange
        } = this.props

        this.setState({
            value,
            deleteHighlight: false,
            adderError: false
        }, () => onChange?.({ [name]: value }))
    }

    removeItem = item => {
        let { value } = this.state

        value = without(value, [item])

        const {
            name,
            onChange
        } = this.props

        this.setState({
            value,
            deleteHighlight: false,
            adderError: false
        }, () => onChange?.({ [name]: value }))
    }

    onFocus = () => this.setState({ focus: true })

    onBlur = () => {
        const { current: adder } = this.adder
        const isAdderValid = this.isAdderValueValid()

        if(isAdderValid) {
            this.addItem(adder.value)
            adder.value = ''
        }

        this.setState({
            focus: false,
            deleteHighlight: false,
            ...(isAdderValid ? { adderError: false } : null)
        })
    }

    isAdderValueValid() {
        const { current: adder } = this.adder
        const { type, validity, value } = adder

        // Email regex
        if(type === 'email') {
            return emailAddressPattern.test(value)
        }

        // Support for built-in browser input type validation
        if(validity?.valid === true) {
            return true
        }

        return !!value
    }

    render() {
        const {
            value,
            adderError,
            focus,
            deleteHighlight
        } = this.state

        const {
            formatMessage,
            className,
            controlProps = {},
            salt,
            label,
            name,
            type = 'text',
            loading = false,
            enabled = true,
            vertical = false,
            field = {},
            picker,
            ...props
        } = this.props

        const { options } = picker ?? {}

        const touched = this.props.form.touched.includes(name)
        const error = ((name in this.props.form.errors) || adderError) && touched

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const fakeTextClassName = cls([
            focus && 'focus',
            !enabled && 'disabled',
            !picker && 'adder',
            !value.length && 'empty',
            error && 'error',
            vertical && 'vertical'
        ])

        const lastValue = last(value)

        const {
            required,
            softRequired,
            optional
        } = field

        const { current: adder } = this.adder

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    {value.map((value, index) => (
                        <input
                            type="hidden"
                            name={`${name}[]`}
                            value={value}
                            key={`${salt}:value:${index}:hidden`} />
                    ))}
                    <FakeText {...(fakeTextClassName ? { className: fakeTextClassName } : null)}>
                        {value.map((value, index) => (
                            <Tag
                                text={value}
                                value={value}
                                deleteHighlight={deleteHighlight && value === lastValue}
                                onClick={this.removeItem}
                                tabIndex={-1}
                                key={`${salt}:value:${index}`} />
                        ))}
                        <TextWrapper picker={picker}>
                            {!!picker && (
                                <Search
                                    type={type}
                                    {...controlProps}
                                    value={value}
                                    id={salt}
                                    placeholder={formatMessage({
                                        id: !!value?.length ?
                                            'action_add_another' :
                                            'action_add_options',
                                        defaultMessage: !!value?.length ?
                                            'Add another…' :
                                            'Add…'
                                    })}
                                    options={options}
                                    addItem={this.addItem}
                                    onFocus={() => this.setState({ focus: true })}
                                    onBlur={() => this.setState({ focus: false })}
                                    disabled={!enabled} />
                            )}
                            {!picker && (
                                <>
                                    <Text
                                        type={type}
                                        {...controlProps}
                                        {...omit(props, 'onChange', 'form', 'whistle')}
                                        id={salt}
                                        placeholder={formatMessage({
                                            id: !!value?.length ?
                                                'action_add_another' :
                                                'action_add_options',
                                            defaultMessage: !!value?.length ?
                                                'Add another…' :
                                                'Add…'
                                        })}
                                        onKeyDown={this.onKeyDown}
                                        onFocus={this.onFocus}
                                        onBlur={this.onBlur}
                                        disabled={!enabled}
                                        ref={this.adder} />
                                    <KeyHintInner animate={!!adder?.value ? 'active' : 'inactive'}>
                                        <Enter size={16} />
                                    </KeyHintInner>
                                </>
                            )}
                        </TextWrapper>
                    </FakeText>
                    <Helper animate={!!adder?.value ? 'reveal' : 'hide'}>
                        {formatMessage({
                            id: 'helper_array_field',
                            defaultMessage: 'Press return key to add'
                        })}
                    </Helper>
                </Control>
            </Field>
        )
    }
}

const addKeys = {
    9: 'tab',
    13: 'enter',
    188: 'comma'
}

const removeKeys = {
    8: 'backspace'
}

const emailRestrictedKeys = {
    32: 'space'
}

export default props => {
    const { formatMessage } = useIntl()
    const form = useForm()

    return (
        <EditArray
            {...props}
            formatMessage={formatMessage}
            form={form} />
    )
}
import React, { Component, createRef, forwardRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { FormContext } from 'components/form/controller'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { Field, Label, Control, Error } from 'components/form/field/s'
import Pills from './pills'
import isEqual from 'react-fast-compare'

const empty = null

class EditPillsField extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const {
            name,
            field = {},
            options,
            forwardedRef
        } = props

        let {
            value,
            required = false,
            include = 'touched'
        } = field

        if(!value && value !== 0) {
            value = empty
        }

        this.state = { value }

        context.registerField(name, {
            empty,
            required,
            include,
            unset: this.unset,

            validator: value => required ?
                ((!!value || value === 0) && !!options?.find(option => option.value === value)) :
                true
        })

        this.field = createRef()
        this.control = createRef()

        this.combinedControlRef = mergeRefs([
            this.control,
            forwardedRef
        ])
    }

    componentDidMount() {
        this.context.triggerChange(this.props.name, { touched: false })
    }

    componentDidUpdate = ({ forwardedRef, name, field }, { value }) => {
        const forwardedRefChanged = forwardedRef !== this.props.forwardedRef
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)
        const requiredChanged = field.required !== this.props.field.required
        const includeChanged = field.include !== this.props.field.include

        if(forwardedRefChanged) {
            this.combinedControlRef = mergeRefs([
                this.control,
                this.props.forwardedRef
            ])
        }

        if(nameChanged) {
            this.context.unregisterField(name)
            this.register()
        }

        if(valueChanged) {
            this.context.triggerChange(this.props.name)
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name)
    }

    onPicked = value => {
        this.setState({ value })

        const { onChange, name } = this.props
        onChange?.({ [name]: value })
    }

    unset = () => this.setState({ value: empty })

    render() {
        const { value } = this.state

        const {
            className,
            field = {},
            controlProps = {},
            label,
            salt,
            name,
            options,
            enabled = true,
            loading = false,
            error: outsideError,
            ...props
        } = this.props

        const touched = this.context.touched.includes(name)
        const error = ((name in this.context.errors) && touched) || !!outsideError

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const {
            required,
            optional
        } = field

        return (
            <Field
                {...(fieldClassName ? { className: fieldClassName } : null)}
                ref={this.field}>
                {!!label && (
                    <Label
                        required={required}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <input
                        type="hidden"
                        name={name}
                        value={value} />
                    <Pills
                        {...controlProps}
                        {...omit(props, 'field')}
                        name={name}
                        value={value}
                        options={options}
                        pick={this.onPicked}
                        disabled={!enabled}
                        salt={`${salt}:${name}`}
                        ref={this.combinedControlRef}
                        key={[
                            salt,
                            name,
                            options.map(option => option.value === value ? 'yes' : 'no')
                        ].flat().join(':')} />
                    <Error animate={outsideError ? 'reveal' : 'hide'}>
                        {outsideError}
                    </Error>
                </Control>
            </Field>
        )
    }
}

export default forwardRef((props, ref) => (
    <EditPillsField
        {...props}
        forwardedRef={ref} />
))

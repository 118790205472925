import styled, { css } from 'styled-components'

export const statusBackgroundColorsCss = css`
    &:not(.disabled) {
        &.neutral {
            background: var(--huma-color-surface-subtle);
        }

        &.passive {
            background: var(--huma-color-surface-warning-minimal);
        }

        &.positive {
            background: var(--huma-color-surface-constructive-minimal);
        }

        &.active {
            background: var(--huma-color-surface-success-minimal);
        }

        &.failed {
            background: var(--huma-color-surface-destructive-minimal);
        }

        &.creative {
            background: var(--huma-color-surface-brand-minimal);
        }
    }
`

const StatusLabel = styled.div`
    display: inline-flex;
    align-self: flex-start;
    align-items: center;

    border-radius: 4px;
    height: 24px;
    padding: 0 8px;

    font-size: 14px;

    ${statusBackgroundColorsCss}

    &.self-align-reset {
        align-self: unset;
    }

    &.disabled {
        box-shadow: 0 0 0 1px var(--huma-color-border-default);

        color: var(--huma-color-foreground-disabled);
    }
`

export default StatusLabel

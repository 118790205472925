import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { sendAppSignal } from 'hooks/app-signal'
import { InlineMessage } from 'components/message'
import { Plain } from 'components/button'

const TooltipContentEmpty = ({ source, sourceHeading }) => {
    const { formatMessage } = useIntl()

    const translation = translations[source.type]
    if(!translation) {
        return null
    }

    const handler = () => sendAppSignal('smart-document.data-source.set', `${source.type}.${source.name}`)

    return (
        <>
            <InlineMessage
                type="warning"
                className="compact"
                message={formatMessage({
                    id: 'editor_variable_source_not_set',
                    defaultMessage: 'Data source is not set.'
                })} />
            {!!translation && (
                <Plain
                    onMouseDown={handler}
                    onClick={handler}
                    className="constructive small">
                    <span>
                        <FormattedMessage
                            {...translation}
                            values={{ role: <strong>{sourceHeading}</strong> }} />
                    </span>
                </Plain>
            )}
        </>
    )
}

const translations = {
    user: {
        id: 'editor_variable_source_user_action_set',
        defaultMessage: 'Set a person as {role}'
    },
    team: {
        id: 'editor_variable_source_team_action_set',
        defaultMessage: 'Set a team as {role}'
    },
    location: {
        id: 'editor_variable_source_location_action_set',
        defaultMessage: 'Set a location as {role}'
    }
}

export default TooltipContentEmpty
import { useEditor as useTiptap } from '@tiptap/react'
import { useExtensions } from './extensions'

export const useEditor = ({
    content,
    transform,
    extend = {},
    configure = {},
    mode = 'edit',
    edit = {},
    view = {},
    every = () => true,
    some = () => true,
    id
}) => {
    const extensions = useExtensions({
        mode,
        extend,
        configure,
        every,
        some,
        id
    })

    const editing = mode === 'edit'

    return useTiptap({
        content,
        extensions,

        ...(editing ?
            edit :
            view
        ),

        enableInputRules: editing,
        enablePasteRules: editing
    }, [
        id,
        mode,
        transform,
        editing,
        extensions.map(extension => extension?.name).join(':')
    ])
}
import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useTheme, ThemeProvider } from 'styled-components'
import { BundleName } from '../plan'
import { useInterval } from 'hooks/interval'
import { clamp } from 'utilities/math'
import { calculateTrialDaysDuration, calculateTrialDaysLeft } from 'utilities/payment'
import ProgressIndicator from 'components/progress-indicator'
import { ProgressWrapper, ProgressParagraph, ProgressButton } from './s'
import { Naked } from 'components/button'
import { ArrowRight } from 'styled-icons/feather'

export const SubscriptionName = ({ bundle, ...props }) => {
    const { formatMessage } = useIntl()

    if(!bundle) {
        return formatMessage({
            id: 'payment_subscription_invoice_manual_charge',
            defaultMessage: 'Manual charge'
        })
    }

    return (
        <BundleName
            {...props}
            bundle={bundle} />
    )
}

export const TrialCountdown = ({ subscription, onClick, showBar = true, ...props }) => {
    let theme = useTheme()

    const [daysLeft, setDaysLeft] = useState(calculateTrialDaysLeft(subscription))
    const duration = calculateTrialDaysDuration(subscription)
    useInterval(() => setDaysLeft(calculateTrialDaysLeft(subscription)), 60 * 60 * 1000)

    if(!subscription?.trial || !!subscription?.trial?.expired) {
        return null
    }

    theme = {
        ...theme,
        hasButton: !!onClick,
        showBar
    }

    return (
        <ThemeProvider theme={theme}>
            <ProgressWrapper
                as={onClick && Naked}
                onClick={onClick}
                {...props}>
                <ProgressParagraph className="compact">
                    <FormattedMessage
                        id="payment_plan_trial_days_left"
                        defaultMessage="{days, plural, =0 {0 days left of the trial period} =1 {1 day left of the trial period} other {{days} days left of the trial period}}"
                        values={{ days: daysLeft }} />
                </ProgressParagraph>
                {onClick && (
                    <ProgressButton>
                        <ArrowRight size={24} />
                    </ProgressButton>
                )}
                {showBar && (
                    <ProgressIndicator
                        values={[clamp((daysLeft / duration) * 100, 3, 97)]}
                        salt="trial-countdown" />
                )}
            </ProgressWrapper>
        </ThemeProvider>
    )
}
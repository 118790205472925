import styled from 'styled-components'
import {
    svgStroke, messageColors,
    belowTablet
} from 'utilities/styled'
import { Columns, FlexChildShrink } from 'components/flex'
import ParagraphBase, { ParagraphSmall as ParagraphSmallBase } from 'components/typography/paragraph'
import { Plain } from 'components/button'

export const Container = styled.div`
    position: relative;

    margin: 0 0 32px;
    border-radius: 8px;
    padding: 16px 24px 16px 16px;
    background-color: var(--message-background-color, var(--huma-color-surface-minimal));

    color: var(--message-text-color, var(--huma-color-foreground-default));

    &.success {
        --message-background-color: var(--huma-color-surface-success-minimal);
        --message-text-color: var(--huma-color-foreground-success);
    }

    &.info {
        --message-background-color: var(--huma-color-surface-info-minimal);
        --message-text-color: var(--huma-color-foreground-info-bold);
    }

    &.warning {
        --message-background-color: var(--huma-color-surface-warning-minimal);
        --message-text-color: var(--huma-color-foreground-warning);
    }

    &.error {
        --message-background-color: var(--huma-color-surface-destructive-minimal);
        --message-text-color: var(--huma-color-foreground-error);
    }

    &.comfortable {
        margin-block-end: 16px;
    }

    &.compact {
        margin-bottom: 0;
    }

    a {
        text-decoration: underline;
    }
`

export const InnerContainer = styled(Columns)`
    column-gap: 16px;

    &.dismiss {
        justify-content: flex-end;
        padding-top: 8px;
    }
`

export const InlineContainer = styled(Columns)`
    column-gap: 16px;
    position: relative;
    padding: 0;
    margin: 0 0 8px;

    &.size-small {
        column-gap: 8px;
    }

    &.clickable {
        cursor: pointer;
    }

    &.spacious {
        margin-block-end: 24px;
    }

    &.comfortable {
        margin-block-end: 4px;
    }

    &.compact {
        margin-bottom: 0;
    }

    &.boxed {
        border-radius: 8px;
        padding: 16px;
        background-color: var(--huma-color-surface-minimal);
    }

    &.framed {
        border-radius: 8px;
        border: 1px solid var(--huma-color-border-default);
        padding: 16px;
    }

    ${messageColors}

    svg.expand-hint {
        ${svgStroke('small')}

        color: var(--huma-color-foreground-default);
    }
`

export const GlobalContainer = styled.div`
    display: grid;
    grid-template-columns: 24px auto;
    column-gap: 16px;
    justify-content: center;
    padding: 12px 24px;
    position: relative;
    line-height: 24px;

    &.overhide {
        display: none;
    }

    &.dismissable {
        grid-template-columns: 24px auto 24px;
    }

    &.success {
        background-color: var(--huma-color-surface-success-minimal);

        svg:where(:not(.x)) {
            color: var(--huma-color-foreground-success);
        }
    }

    &.info {
        background-color: var(--huma-color-surface-info-minimal);

        svg:where(:not(.x)) {
            color: var(--huma-color-foreground-info-bold);
        }
    }

    &.warning {
        background-color: var(--huma-color-surface-warning-minimal);

        svg:where(:not(.x)) {
            color: var(--huma-color-foreground-warning);
        }
    }

    &.error {
        background-color: var(--huma-color-surface-destructive-minimal);

        svg:where(:not(.x)) {
            color: var(--huma-color-foreground-error);
        }
    }

    &:where(.caution, .idea) {
        background-color: var(--huma-color-surface-minimal);

        svg:where(:not(.x)) {
            color: var(--huma-color-foreground-default);
        }
    }

    a {
        text-decoration: underline;
    }
`

export const IconWrap = styled(FlexChildShrink)`
    width: ${p => (p.$size === 'small') ? '20px' : '24px'};
    height: ${p => (p.$size === 'small') ? '20px' : '24px'};
    position: relative;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        ${p => (p.$size === 'small') ?
            svgStroke('small') :
            svgStroke()
        }
    }
`

export const Paragraph = styled(ParagraphBase)`
    margin-block-end: 16px;

    &.heading {
        font-weight: 700;
    }

    ${Container}.heading-compact & {
        margin-block-end: 0;
    }
`

export const ParagraphSmall = styled(ParagraphSmallBase)`
    &:not(:only-child) {
        margin-block-end: 8px;
    }
`

export const Dismiss = styled(FlexChildShrink)`
    ${svgStroke()}

    ${belowTablet`
        align-self: flex-end;
    `}

    ${GlobalContainer} & {
        ${belowTablet`
            align-self: unset;
        `}
    }
`

export const DismissButton = styled(Plain).attrs({ className: 'neutral' })`
    height: 24px;
`
import React, { forwardRef } from 'react'
import { widgetify, Wrapper, Veil, Widget } from 'utilities/widget'
import { Container } from './s'

const Generic = ({ show, className, children, ...widget }) => {
    const {
        position,
        salt,
        clickOutside = {},
        closeButton,
        blocking = false,
        constrain = false,
        scrollable = true,
        focusOn = null,
        forwardedRef
    } = widget

    const animate = show ? 'in' : 'out'

    return widgetify(
        <Wrapper>
            {!!show && (
                <>
                    <Veil animate={animate} />
                    <Widget
                        {...position}
                        clickOutside={clickOutside}
                        closeButton={closeButton}
                        blocking={blocking}
                        constrain={constrain}
                        scrollable={scrollable}
                        focusOn={focusOn}
                        ref={forwardedRef}
                        key={`widget:generic:${salt}`}>
                        <Container {...(className ? { className } : null)}>
                            {children}
                        </Container>
                    </Widget>
                </>
            )}
        </Wrapper>
    )
}

export default forwardRef((props, ref) => (
    <Generic {...props} forwardedRef={ref} />
))

import styled, { css } from 'styled-components'
import { belowTablet } from 'utilities/styled'
import {
    Grid,
    GridColumnHeader as GridColumnHeaderBase,
    GridColumnHeaderSortable as GridColumnHeaderSortableBase
} from 'components/grid'
import { compact } from 'utilities/array'

export const Wrap = styled.div``

export const Header = styled(Grid)`
    width: 100%;
    border-bottom: 1px solid var(--huma-color-border-default);

    ${({ theme: { domains: { actions } } }) => css`
        grid-template: 1fr / ${compact(['1fr', '1fr', actions && '40px']).join(' ')};
        column-gap: 24px;

        ${belowTablet`
            grid-template-columns: auto auto;
            justify-content: start;
            column-gap: 16px;
        `}
    `}
`

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    position: relative;
`

const gridColumnHeaderCss = css`
    padding: 24px 0;

    ${belowTablet`
        padding: 16px 0;
    `}
`

export const GridColumnHeaderSortable = styled(GridColumnHeaderSortableBase)`
    ${gridColumnHeaderCss}
`

export const GridColumnActionsHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}

    ${belowTablet`
        display: none;
    `}
`

import styled, { css } from 'styled-components'
import {
    svgStroke,
    fromContainer, belowContainer
} from 'utilities/styled'
import { containerProps } from '../s'
import Link from 'components/link'
import Paragraph from 'components/typography/paragraph'

const cellCss = css`
    display: flex;
    flex-direction: column;

    ${fromContainer(containerProps)`
        align-items: start;
        justify-content: center;
        grid-column: ${p => p.$cell};
    `}

    ${belowContainer(containerProps)`
        ${p => p.$cell === 'name' && `
            grid-row: 1;
        `}

        ${p => p.$cell === 'reviewers' && `
            grid-row: 2;
        `}

        ${p => p.$cell === 'budget' && `
            grid-row: 3;
        `}

        ${p => p.$cell === 'status' && `
            grid-row: 4;
        `}

        ${p => p.$cell === 'action' && `
            justify-content: center;
            grid-row: 1;
            grid-column: 2;
        `}
    `}
`

export const Cell = styled.div`
    ${cellCss}
`

export const LinkCell = styled(Link)`
    ${cellCss}

    &.action {
        svg {
            ${svgStroke()}

            transition: translate .1s ease-in-out;
        }
    }
`

export const Row = styled.div`
    display: grid;

    min-height: 76px;
    padding-block: 16px;

    &:not(:last-of-type) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    ${fromContainer(containerProps)`
        grid-template-columns: subgrid;
        grid-column: 1 / -1;
    `}

    ${belowContainer(containerProps)`
        grid-template-columns: 1fr auto;
        row-gap: 16px;

        &:first-of-type {
            padding-block-start: 0;
            box-shadow: none;
        }
    `}

    &:has(${LinkCell}:hover, ${LinkCell}:focus-visible) {
        .name {
            text-decoration: none;

            .link-hover {
                text-decoration: underline;
            }
        }

        .action {
            svg {
                translate: 4px;
            }
        }
    }
`

export const Label = styled(Paragraph).attrs({
    as: 'span',
    className: 'caption small compact'
})`
    &::after {
        content: ":";

        margin-inline-end: 4px;
    }

    ${fromContainer(containerProps)`
        display: none;
    `}
`

export const Percentage = styled.span`
    svg {
        ${svgStroke()}

        position: relative;
        inset-block-start: -1px;

        margin-inline-end: 8px;
    }

    &::after {
        inset-inline-end: -8px;

        color: inherit;
    }
`
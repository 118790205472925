export const VIMEO_REGEX = /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/
export const VIMEO_REGEX_GLOBAL = /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/g

export const isValidVimeoUrl = url => {
    return url.match(VIMEO_REGEX)
}

export const getEmbedUrlFromVimeoUrl = (options = {}) => {
    const {
        url,
        autopause,
        autoplay,
        background,
        byline,
        color,
        controls,
        dnt,
        keyboard,
        loop,
        muted,
        pip,
        playsinline,
        portrait,
        quality,
        speed,
        texttrack,
        title,
        transparent,

        t
    } = options

    // if is already an embed url, return it
    if(url.includes('/video/')) {
        return url
    }

    const videoIdRegex = /\/(\d+)/gm
    const matches = videoIdRegex.exec(url)

    if(!matches?.[1]) {
        return null
    }

    let outputUrl = `https://player.vimeo.com/video/${matches[1]}`

    const params = []

    if(!autopause) {
        params.push('autopause=0')
    }

    if(autoplay) {
        params.push('autoplay=1')
    }

    if(background) {
        params.push('background=1')
    }

    if(byline) {
        params.push('byline=1')
    }

    if(color) {
        params.push(`color=${color}`)
    }

    if(!controls) {
        params.push('controls=0')
    }

    if(dnt) {
        params.push('dnt=1')
    }

    if(!keyboard) {
        params.push('keyboard=0')
    }

    if(loop) {
        params.push('loop=1')
    }

    if(muted) {
        params.push('muted=1')
    }

    if(pip) {
        params.push('pip=1')
    }

    if(!playsinline) {
        params.push('playsinline=0')
    }

    if(portrait) {
        params.push('portrait=1')
    }

    if(quality) {
        params.push(`quality=${quality}`)
    }

    if(speed) {
        params.push('speed=0')
    }

    if(texttrack) {
        params.push('texttrack=0')
    }

    if(title) {
        params.push('title=1')
    }

    if(!transparent) {
        params.push('transparent=0')
    }

    if(params.length) {
        outputUrl += `?${params.join('&')}`
    }

    if(t) {
        outputUrl += `#t=${t}`
    }

    return outputUrl
}
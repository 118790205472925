import React from 'react'
import { useIntl } from 'react-intl'
import { useGetMeta } from './tooltip/meta'
import { useExtensions } from 'components/tiptap/hooks/extensions'
import { useSmartEntity } from 'contexts/smart-entity'
import { getExtensionAvailability } from 'components/tiptap'
import { NodeViewWrapper, mergeAttributes } from '@tiptap/react'
import { cls } from 'utilities/dom'
import { getEntityIcon } from 'components/entity'
import Tooltip from 'components/tooltip'
import { followCursor } from 'tippy.js'
import TooltipContent from './tooltip'
import { datafyAttributes } from 'components/tiptap/extensions/utilities'

export const useGetVariableData = () => {
    const { formatMessage } = useIntl()
    const getMeta = useGetMeta()

    const { every, some } = getExtensionAvailability({ preset: 'maximal' })

    const extensions = useExtensions({
        mode: 'view',
        every,
        some,
        output: true
    })

    const variable = extensions.find(({ name }) => name === 'variable')

    return smartEntityContext => node => {
        if(!smartEntityContext) {
            return null
        }

        const {
            context,
            mode,

            getSource,
            getField,
            getValue
        } = smartEntityContext

        let source = {
            type: node.attrs.sourceType,
            name: node.attrs.sourceName
        }

        source = getSource(source) ?? source

        const field = getField({
            type: node.attrs.sourceType,
            name: node.attrs.fieldName
        })

        const value = getValue(source)

        const options = !!field?.cursor && field?.getOptions?.(value)
        const hasOptions = !!options?.length

        const state = {}
        state.filling = mode === 'fill'
        state.detached = !!node.attrs.detached
        state.mismatched = !state.detached && !field
        state.filled = state.filling && !state.detached && !state.mismatched && !!node.attrs.id && !!node.attrs.value
        state.incomplete = state.filling && !state.detached && !state.mismatched && !!node.attrs.id && !node.attrs.value && hasOptions
        state.inacessibleType = ['organization', 'user'].includes(node.attrs.sourceType)
        state.inaccessible = state.filling && state.inacessibleType && !state.detached && !state.mismatched && !!node.attrs.id && !node.attrs.value && !hasOptions && (!!field && (!field?.sourceNames?.every(name => name in (value ?? {})) ?? false))
        state.notSet = state.filling && !state.detached && !state.mismatched && !state.inaccessible && !!node.attrs.id && !node.attrs.value && !hasOptions
        state.empty = state.filling && !state.detached && !state.mismatched && !node.attrs.id && !node.attrs.value && !hasOptions

        const className = cls([
            variable.name,
            mode,
            state.filled && 'filled',
            state.incomplete && 'incomplete',
            state.notSet && 'not-set',
            state.mismatched && 'mismatched',
            state.detached && 'detached',
            state.empty && 'empty',
            state.inaccessible && 'inaccessible'
        ])

        const attributes = mergeAttributes(
            { className },
            variable.options.HTMLAttributes,
            ...datafyAttributes(node.attrs)
        )

        const { name } = getMeta(source)

        const sourceHeading = name ?? (!!source?.label ?
            (typeof source?.label === 'string') ?
                source.label :
                formatMessage(source.label) :
            null)

        const fieldHeading = !!field?.label ?
            (typeof field?.label === 'string') ?
                field.label :
                formatMessage(field.label) :
            null

        const descriptor = (sourceHeading && fieldHeading) ?
            `${sourceHeading}: ${fieldHeading}` :
            null

        const Element = (!!node.attrs.value || descriptor) ?
            'span' :
            'code'

        const Icon = getEntityIcon(node.attrs.sourceType, {
            organization: 'company'
        })

        return {
            variable,
            context,
            source,
            field,
            value,
            options,
            state,
            className,
            attributes,
            descriptor,
            Element,
            Icon
        }
    }
}

const VariableNode = ({ node, variable, updateAttributes }) => {
    const { getVariableData } = useSmartEntity()

    const {
        context,
        source,
        field,
        value,
        options,
        state,
        attributes,
        descriptor,
        Element,
        Icon
    } = getVariableData(node)

    const text = variable.options.renderHTML?.({
        ...node.attrs,
        descriptor
    }) ?? node.attrs.value ?? ''

    return (
        <NodeViewWrapper as="span">
            <Tooltip
                content={(
                    <TooltipContent
                        context={context}
                        source={source}
                        field={field ?? {
                            type: node.attrs.sourceType,
                            name: node.attrs.fieldName
                        }}
                        value={value}
                        options={options}
                        node={node}
                        updateAttributes={updateAttributes}
                        {...state} />
                )}
                placement="bottom-start"
                followCursor="horizontal"
                plugins={[followCursor]}
                interactive={true}
                interactiveBorder={8}
                interactiveDebounce={50}
                popperOptions={{ strategy: 'fixed' }}
                appendTo={() => document.body}//querySelector('#content')}
                // As it’s showing within a body of text, we want it to be tighter
                // in case there are multiple variables over multiple lines:
                offset={[-16, 4]}
                delay={[50, 100]}
                zIndex={3}>
                <Element {...attributes}>
                    <span className="content">
                        {!!Icon && (
                            <span className="icon">
                                <Icon size={14} />
                            </span>
                        )}
                        {text}
                    </span>
                </Element>
            </Tooltip>
        </NodeViewWrapper>
    )
}

export default VariableNode
import styled, { css } from 'styled-components'
import {
    svgStroke,
    belowPhone
} from 'utilities/styled'
import { motion } from 'framer-motion'
import { compact } from 'utilities/array'
import { Rows as RowsBase } from 'components/flex'
import { Link } from 'react-router-dom'
import Caption, { captionSmallCss } from 'components/typography/caption'
import { H4 } from 'components/typography/heading'

export const Header = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 1fr) min-content;
    grid-template-rows: min-content minmax(0, 1fr);
    column-gap: 24px;
    align-items: center;
    padding: 0 24px;
    user-select: none;

    ${belowPhone`
        column-gap: 16px;
        row-gap: 8px;

        padding: 0 16px;
    `}

    ${({ theme: { expandable } }) => {
        const {
            icon = false,
            action = false,
            summary = false,
            content = false
        } = expandable ?? {}

        const columns = compact([
            icon && 'min-content',
            'minmax(0, 1fr)', // Heading
            action && 'min-content',
            'min-content' // Toggle
        ])

        const rows = compact([
            'min-content', // Main row
            summary && 'min-content' // Summary row
        ])

        const hasContentCss = css`
            cursor: pointer;

            outline: none;
            border: none;
            background: transparent;

            text-align: start;
        `

        return css`
            grid-template-columns: ${columns.join(' ')};
            grid-template-rows: ${rows.join(' ')};

            ${content && hasContentCss}
        `
    }}

    svg {
        ${svgStroke()}
    }
`

export const Container = styled(RowsBase)`
    border-radius: 8px;
    min-width: 0;
    box-shadow: inset 0 0 0 1px var(--huma-color-border-default);

    transition: box-shadow .1s ease-in-out;

    &:has(${Header}:where(:hover, :focus)) {
        box-shadow: inset 0 0 0 1px var(--huma-color-border-default-focus);
    }
`

export const LinkContainer = styled(Link)`
    display: block;

    border-radius: 8px;
    box-shadow: inset 0 0 0 1px var(--huma-color-border-default);

    text-decoration: none;
    color: var(--huma-color-foreground-default);

    transition: box-shadow .1s ease-in-out;

    &:has(${Header}:where(:hover, :focus)) {
        box-shadow: inset 0 0 0 1px var(--huma-color-border-default-focus);
    }
`

export const IconCell = styled.div`
    grid-row: span 2;

    ${belowPhone`
        grid-row: 1;

        &:not(.service-onboarding &) {
            align-self: start;
            margin-block-start: 18px;
        }

        ${({ theme: { expandable: { summary } } }) => !summary && css`
            ­­&:not(.service-onboarding) & {
                margin-block-start: 16px;
            }
        `}
    `}
`

export const HeadingCell = styled.div`
    grid-row: 1;
    grid-column: 1;
    padding-block-start: 26px;

    ${({ theme: { expandable } }) => {
        const {
            icon = false,
            summary = false
        } = expandable ?? {}

        return css`
            ${icon && css`
                grid-column: 2;
            `}

            ${!summary && css`
                min-height: 72px;
                padding-block: 24px;
            `}
        `
    }}

    ${belowPhone`
        align-self: end;
        padding-block-start: 18px;

        ${({ theme: { expandable } }) => {
            const {
                icon = false,
                summary = false
            } = expandable ?? {}

            return css`
                ${icon && css`
                    align-self: center;
                `}

                ${!summary && css`
                    min-height: 48px;
                    padding-block: 16px;
                `}
            `
        }}
    `}
`

export const ActionCell = styled.div`
    display: grid;
    grid-row: 1 / -1;
    grid-column: 3 / 4;
    place-items: center;

    ${belowPhone`
        grid-row: 1 / 2;

        &:not(.service-onboarding &) {
            padding-block-start: 16px;
        }
    `}
`

export const SummaryCell = styled.div`
    grid-row: 2;
    grid-column: 1;

    padding-block-end: 22px;

    ${({ theme: { expandable } }) => {
        const { icon = false } = expandable ?? {}

        if(!icon) {
            return null
        }

        return css`
            grid-column: 2;

            ${belowPhone`
                grid-column: 1 / 4;
            `}
        `
    }}

    ${belowPhone`
        grid-column: 1 / 3;
        padding-block-end: 14px;
    `}
`

export const ToggleCell = styled(motion.div).attrs(() => ({
    variants: {
        contracted: {
            transform: 'rotate(0deg)'
        },
        expanded: {
            transform: 'rotate(-180deg)'
        }
    },
    transition: {
        duration: .15
    },
    initial: 'contracted'
}))`
    display: grid;
    grid-row: span 2;
    place-items: center;
    width: 32px;
    height: 24px;

    ${({ theme: { expandable } }) => {
        const {
            summary = false,
            content = false
        } = expandable ?? {}

        return css`
            ${!content && css`
                opacity: calc(1 / 3);
            `}

            ${summary && belowPhone`
                align-self: end;
            `}
        `
    }}

    ${belowPhone`
        grid-row: 1;

        width: 24px;
    `}
`

export const ArrowCell = styled.div`
    display: grid;
    grid-row: span 2;
    place-items: center;
    width: 32px;
    height: 24px;

    ${belowPhone`
        grid-row: 1;

        width: 24px;
    `}
`

export const Heading = styled(H4)`
    line-height: 24px;
    margin: 0;
`

export const Summary = styled(Caption)`
    position: relative;
    word-break: break-word;
    overflow-wrap: anywhere;

    ${belowPhone`
        ${captionSmallCss}
    `}
`

export const Drawer = styled(motion.div).attrs(() => ({
    variants: {
        contracted: {
            height: 0
        },
        expanded: {
            height: 'auto'
        }
    },
    transition: {
        duration: .15
    },
    initial: 'contracted'
}))`
    padding: 0 24px;
    position: relative;
    overflow: hidden;

    ${belowPhone`
        padding: 0 16px;
    `}
`

export const Content = styled.div`
    padding: 0 0 24px;

    ${belowPhone`
        padding: 0 0 16px;
    `}

    > :last-child {
        margin-block-end: 0;
    }
`
import React, { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import { useAccess } from 'contexts/access'
import {
    CompetenceRecordWrapper,
    PersonCellClickable, PersonCellWrapper, Note,
    ActionsCell
} from './s'
import Tooltip from 'components/tooltip'
import Person from 'components/person'
import Column from './column'
import ContextMenu from 'widgets/context-menu'

const CompetenceRecord = forwardRef(({ record, actions, columns, singleton, salt }, ref) => {
    const { formatMessage } = useIntl()

    const { check } = useAccess()

    if(!record) {
        return null
    }

    const {
        concerns,
        note
    } = record

    const manageAccess = check('competence:manage')

    const view = actions?.view?.(record)

    const PersonCell = view ?
        PersonCellClickable :
        PersonCellWrapper

    const personCellProps = view ? {
        onClick: () => view.onClick(record)
    } : null

    return (
        <CompetenceRecordWrapper ref={ref}>
            <Tooltip
                content={formatMessage({
                    id: 'action_view_details',
                    defaultMessage: 'View details'
                })}
                singleton={singleton}>
                <PersonCell {...personCellProps}>
                    <Person
                        who={concerns}
                        showPosition={true}
                        truncate={true}
                        size={40} />
                </PersonCell>
            </Tooltip>
            {columns.map(({ id }) => (
                <Column
                    record={record}
                    actions={actions}
                    id={id}
                    key={`competence-record:${record.id}:column:${id}`} />
            ))}
            {!!manageAccess && !!actions && (
                <ActionsCell>
                    <ContextMenu
                        salt={salt}
                        context={record}
                        actions={actions} />
                </ActionsCell>
            )}
            {note && <Note>{note}</Note>}
        </CompetenceRecordWrapper>
    )
})

export default CompetenceRecord

import styled, { css } from 'styled-components'
import {
    svgStroke, flashKeyframes,
    belowTablet
} from 'utilities/styled'
import { Grid } from 'components/grid'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import Link from 'components/link'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'

export const roundItemGrid = css`
    grid-template-columns: minmax(0, 2fr) repeat(2, 1fr) 40px;
`

export const RoundItem = styled(Grid)`
    ${roundItemGrid}

    column-gap: 24px;

    width: 100%;
    padding-block: 24px;
    box-shadow: 0 -1px var(--huma-color-border-default);

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }

    ${belowTablet`
        grid-template-columns: 1fr 40px;
        grid-auto-flow: row;
        grid-auto-rows: auto;
        row-gap: 8px;
        column-gap: 16px;
    `}
`

const cellCss = css`
    display: grid;
    align-items: center;
`

export const MeetingCell = styled.div`
    color: var(--huma-color-foreground-default);
    text-decoration: none;
`

export const RoundLink = styled(Link)`
    ${cellCss}

    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    ${MeetingCell} & {
        grid-template-rows: 24px 20px;
        grid-template-columns: 40px auto;
        column-gap: 16px;
    }
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(RoundLink)}

    grid-row: 1 / 3;
`

export const MetaWrapper = styled.div`
    display: grid;
    grid-auto-rows: auto;

    padding-inline-start: 16px;
`

export const Title = styled(Paragraph)`
    ${RoundLink}:hover &,
    ${RoundLink}:focus & {
        text-decoration: underline;
    }
`

export const Created = styled(CaptionSmall)`
    margin-block-start: -20px;
    margin-inline-start: 56px;
`

export const ProgressCell = styled.div`
    ${cellCss}

    ${belowTablet`
        grid-row: 1 / 3;
        grid-column: 2 / 3;
        align-items: start;
        justify-content: end;

        padding-block-start: 8px;
    `}
`

export const DueDateCell = styled.div`
    ${cellCss}

    font-size: 14px;
    color: var(--huma-color-foreground-subtle);

    ${belowTablet`
        display: flex;
        grid-auto-flow: column;
        grid-row: 2 / 3;
        grid-column: 1 / 3;
        column-gap: 8px;

        padding-inline-start: 56px;
    `}
`

export const ArrowCell = styled.div`
    ${cellCss}

    ${belowTablet`
        display: none;
    `}

    svg {
        ${svgStroke()}

        transition: transform .1s ease-in-out;
    }

    ${MeetingCell}:hover ~ & svg,
    &:hover svg {
        transform: translateX(4px);
    }
`

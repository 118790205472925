import React from 'react'
import {
    ConcernsGroup, Participants,
    Avatar
} from './s'

const Avatars = ({ host, concerns = null, attendees = [] }) => {
    if(!host) {
        return null
    }

    if(!concerns && !attendees?.length) {
        return (
            <Avatar
                who={host}
                size={40} />
        )
    }

    if(concerns) {
        return (
            <ConcernsGroup>
                <Avatar
                    className="regarding"
                    who={concerns}
                    size={32} />
                <Avatar
                    className="host"
                    who={host}
                    size={16} />
            </ConcernsGroup>
        )
    }

    const participantsCount = attendees?.length + 1

    return (
        <Participants $count={participantsCount}>
            <Avatar
                className="host"
                who={host}
                size={(participantsCount === 2) ? 24 : 20} />
            {attendees.slice(0, 2).map(attendee => (
                <Avatar
                    className="attendee"
                    who={attendee}
                    size={(participantsCount === 2) ? 24 : 20}
                    key={`${attendee.id}`} />
            ))}
        </Participants>
    )
}

export default Avatars
import React from 'react'
import { Wrapper, Summary } from './s'
import TiptapOutput from 'components/tiptap/output'
import UsefulLinks from 'components/useful-links'
import Topics from './topics'

const CategoryItemContent = ({ category, setTemplate, salt }) => {
    const {
        expanded,
        summary,
        links = []
    } = category

    const richSummaryAnchorAttributes = expanded ? null : { tabIndex: -1 }

    return (
        <Wrapper>
            {!!summary && (
                <TiptapOutput
                    content={summary}
                    element={Summary}
                    overrides={{ a: [null, richSummaryAnchorAttributes] }}
                    dependencies={[expanded]} />
            )}
            <UsefulLinks
                className="compact"
                links={links}
                tabable={expanded}
                salt={salt} />
            <Topics
                category={category}
                setTemplate={setTemplate}
                salt={salt} />
        </Wrapper>
    )
}

export default CategoryItemContent

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Content, UnsupportedWrap } from './s'
import { EyeOff } from 'styled-icons/feather'
import Caption from 'components/typography/caption'

const Unsupported = () => (
    <Content className="unsupported">
        <UnsupportedWrap>
            <EyeOff size={24} />
            <Caption className="compact">
                <FormattedMessage
                    id="document_preview_not_available"
                    defaultMessage="Preview not available" />
            </Caption>
        </UnsupportedWrap>
    </Content>
)

export default Unsupported
import React, { useState, useEffect } from 'react'
import { useDragHandle } from 'hooks/drag-handle'
import { useDownload } from 'hooks/download'
import { allowedFileExtensions } from '../constants'
import { Layout } from './s'
import { CloseButton } from 'components/button'
import Preview from './preview'
import ResizeHandle from 'components/resize-handle'
import Details from './details'

const FilePreview = ({ meta = {}, preview, download, remove, dismiss, salt }) => {
    const { layout, resizeHandle, contentHeight, handleMouseDown } = useDragHandle()

    const [supported, setSupported] = useState(null)

    const { id, extension, uploadedBy, uploadedAt } = meta

    const { raw, file, objectUrl, download: resolve } = useDownload(preview)

    useEffect(() => {
        if(allowedFileExtensions.includes(extension)) {
            resolve()
        } else setSupported(false)
    }, [extension])

    useEffect(() => {
        if(file) {
            setSupported(true)
        }

        return () => setSupported(false)
    }, [file])

    salt = `${salt}:${id}:${extension}`

    return (
        <>
            <Layout
                style={{ '--content-height': `${contentHeight}%` }}
                ref={layout}>
                <Preview {...{ supported, file, objectUrl, raw, extension, salt }} />
                <ResizeHandle
                    onMouseDown={handleMouseDown}
                    ref={resizeHandle} />
                <Details {...{ file, uploadedBy, uploadedAt, download, remove, dismiss, salt }} />
            </Layout>
            <CloseButton onClick={dismiss} />
        </>
    )
}

export default FilePreview
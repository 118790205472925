import styled, { css } from 'styled-components'
import { darkMode } from 'utilities/styled'

export const Scores = styled.ol`
    list-style: none;
    display: flex;
    align-items: center;
    column-gap: 8px;

    padding: 0;
`

export const Score = styled.li`
    --huma-component-score-color: var(--huma-palette-neutral-80);

    ${darkMode`
        --huma-component-score-color: var(--huma-palette-neutral-30);
    `}

    display: grid;
    place-items: center;

    border: 2px solid var(--huma-component-score-color);
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: var(--huma-component-score-color);

    line-height: 0;
    font-weight: 500;
    font-size: 0;

    ${({ $active }) => $active && css`
        width: 32px;
        height: 32px;
        background-color: transparent;

        line-height: 1;
        font-size: 16px;
    `}
`
import React, { Children } from 'react'
import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { compact } from 'utilities/array'
import { Columns, FlexChildShrink, flexChildGrowCss } from 'components/flex'

export const HeroActions = styled(Columns)`
    justify-content: space-between;
    column-gap: 24px;

    ${belowPhone`
        &:where(:not(.no-wrap)) {
            flex-direction: column;
            row-gap: 16px;

            margin-block-end: 24px;
        }
    `}
`

export const Action = styled(FlexChildShrink)`
    position: relative;
    pointer-events: none;

    ${HeroActions}.grow-first &:first-child {
        ${flexChildGrowCss}
    }

    ${HeroActions}.push-only-child &:only-child {
        margin-inline-start: auto;
    }

    ${HeroActions}.shrink-first &:first-child {
        flex: 0 1 max-content;
    }

    ${belowPhone`
        ${HeroActions}:not(.no-wrap) & {
            &:first-child:not(:only-child) {
                order: 2;
            }

            &:last-child:not(:only-child) {
                order: 1;
            }
        }
    `}

    > svg.attention-arrow:only-child {
        display: none;
    }

    * {
        pointer-events: auto;
    }
`

export default ({ children, salt, ...props }) => {
    children = compact(Children.toArray(children))

    return (
        <HeroActions {...props}>
            {children.map((action, index) => (
                <Action key={`${salt}:hero-action:${index}`}>
                    {action}
                </Action>
            ))}
        </HeroActions>
    )
}
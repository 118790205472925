import styled from 'styled-components'
import { screenReaderOnly } from 'utilities/styled'

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    padding-block: 8px;
`

export const Pill = styled.div`
    input[type="radio"] {
        ${screenReaderOnly}
    }

    label {
        cursor: pointer;

        display: grid;
        place-content: center;

        border-radius: 50%;
        width: 40px;
        height: 40px;

        transition: all .1s ease-in-out;
    }

    input[type="radio"]:checked + label {
        background-color: var(--huma-color-surface-constructive-bold);

        color: var(--huma-color-foreground-on-bold);

        &:hover {
            background-color: var(--huma-color-surface-constructive-bold-hover);
        }
    }

    input[type="radio"]:not(:checked) + label {
        background-color: var(--huma-color-surface-default);
        box-shadow: 0 0 0 1px var(--huma-color-border-default);
    }

    input[type="radio"]:not(:disabled):not(:checked) + label:is(:hover, :focus) {
        box-shadow: 0 0 0 1px var(--huma-color-border-subtle-hover);
    }

    input[type="radio"]:disabled + label {
        cursor: not-allowed;
    }

    input[type="radio"]:disabled:checked + label {
        background-color: var(--huma-color-border-subtle);

        color: var(--huma-color-foreground-on-bold);
    }
`
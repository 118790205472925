import React, { useState, useEffect } from 'react'
import { get } from 'api'
import { Form } from './s'
import Field from 'components/form/field/people'

const PickUsersField = ({ filter, setFilter, resetFilterField, resetAt, name, field = null, entity = {}, salt }) => {
    const getValue = () => filter?.[name] ?? field?.defaultValue ?? []

    const [hasInteracted, setHasInteracted] = useState(false)
    const [users, setUsers] = useState([])
    const [fetching, setFetching] = useState(false)

    // Resolve users on page load
    useEffect(() => {
        if(!!filter[name]?.length && !users?.length) {
            const fetch = async () => {
                setFetching(true)

                const { ok, response } = await get({
                    path: '/users',
                    params: {
                        ids: filter[name]
                    }
                })

                setFetching(false)

                if(ok && response?.items?.length) {
                    setUsers(response.items)
                }
            }

            fetch()
        }
    }, [getValue().join('+'), users.map(({ id }) => id).join('+')])

    // Unset the users when value is unset
    useEffect(() => {
        (!getValue().length && !!users.length) && setUsers([])
    }, [getValue().length, users.length])

    return (
        <Form>
            <Field
                className="compact wide"
                salt={salt}
                label={false}
                name={name}
                field={{
                    ...field,
                    value: users
                }}
                entity={entity}
                enabled={!fetching}
                onChange={({ [name]: users }) => {
                    if(!hasInteracted) {
                        return
                    }

                    setFilter({ [name]: users.map(({ id }) => id) })
                    setUsers(users)
                }}
                onToggle={() => setHasInteracted(true)}
                onUnset={() => {
                    setFilter({ [name]: [] })
                    setUsers([])
                    resetFilterField()
                }}
                key={`${salt}:${name}:${users.map(({ id }) => id).join('+') ?? 'empty'}:${resetAt}:${fetching}`} />
        </Form>
    )
}

export default PickUsersField

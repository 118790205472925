import React, { forwardRef, useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'
import { useDrag } from 'hooks/drag'
import { useAnimation } from 'hooks/animation'
import { useForm } from 'components/form/controller'
import { mergeRefs } from 'react-merge-refs'
import {
    Dropzone, UploadIconWrap,
    InstructionRow, MaximumSizeRow, FilledRow,
    Filename, EmptyButton,
    Message
} from './s'
import { UploadCloud as Upload, Trash2 as Empty } from 'styled-icons/feather'
import { Columns, FlexChild, FlexChildShrink } from 'components/flex'
import { FormattedMessage } from 'react-intl'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { splitName, normalizeName, megabytesToBytes, bytesToMegabytes } from 'utilities/file'
import Loader from 'components/loader'
import AcceptedExtensionsList from './accepted-extensions-list'

export default forwardRef(({ name, id, text, helper, ...props }, customInputRef) => {
    const { formatMessage } = useIntl()
    const { triggerChange } = useForm()

    const [dragging, setDragging] = useDrag()

    const [fileName, setFileName] = useState([])
    const [error, setError] = useState(null)

    const [activateNope, customRootRef] = useAnimation({ className: 'nope' })

    const {
        multiple,
        accept,
        maxSize = tenMegabytesAsBytes,
        controlProps = {}
    } = props

    // onDrop covers both the drop and click-and-browse interactions
    const onDrop = useCallback((accepted, rejected, event) => {
        setDragging(false)
        setError(null)

        if(!multiple) {
            if(!rejected.length && !!accepted.length) {
                // Only the drop interaction has a dataTransfer, and it needs
                // to have the files set for the form to pick up the value
                if(!!event?.dataTransfer) {
                    inputRef.current.files = event.dataTransfer.files
                }

                const [file] = accepted

                setFileName(file.name)
                triggerChange(name)
                validate(file)
            }

            if(rejected?.length) {
                const { code } = rejected[0].errors?.[0] ?? {}
                const { extension: type } = splitName(rejected[0].file?.name)

                if(!type && code === 'file-invalid-type') {
                    setError({ code: 'file-missing-type' })
                } else {
                    setError({ code, type })
                }

                activateNope()
            }
        }
    }, [name])

    const validate = file => {
        setError(null)

        if(!multiple) {
            let error = false

            if(file?.size > maxSize) {
                setError({ code: 'file-too-large' })
                error = true
            }

            props.onChange?.({ [name]: error ? null : file })
        }
    }

    const {
        getRootProps, getInputProps,
        isDragActive, acceptedFiles
    } = useDropzone({
        ...props,
        accept,
        multiple,
        maxSize,
        onDrop,
        noDragEventsBubbling: true,
        useFsAccessApi: false
    })

    const {
        ref: rootRef,
        ...rootProps
    } = getRootProps({
        ...omit(controlProps, 'loading'),
        name
    })

    const {
        ref: inputRef,
        ...inputProps
    } = getInputProps({
        name,
        id
    })

    const combinedInputRef = mergeRefs([inputRef, customInputRef])
    const combinedRootRef = mergeRefs([rootRef, customRootRef])

    const clear = useCallback(() => {
        setFileName([])

        if(supportsDataTransferConstructor()) {
            inputRef.current.files = new DataTransfer().files
        } else {
            acceptedFiles.length = 0
            acceptedFiles.splice(0, acceptedFiles.length)
        }

        inputRef.current.value = ''
        triggerChange(name)
        props?.onClear?.()
        props.onChange?.({ [name]: null })
    }, [name, inputRef.current])

    const className = cls([
        dragging && 'highlight',
        isDragActive && 'selected',
        fileName?.length && 'filled'
    ])

    const { loading = false } = controlProps

    const animate = (fileName?.length && !error) ?
        'filled' :
        'empty'

    return (
        <>
            <Dropzone
                {...rootProps}
                {...(className ? { className } : null)}
                animate={animate}
                ref={combinedRootRef}>
                <input
                    {...inputProps}
                    ref={combinedInputRef} />
                {!loading && (
                    <>
                        <UploadIconWrap animate={animate}>
                            <Upload size={24} />
                        </UploadIconWrap>
                        {!fileName?.length && (
                            <>
                                <InstructionRow>
                                    {!!text && text}
                                    {!text && (
                                        <FormattedMessage
                                            id={multiple ?
                                                'upload_instruction_multiple' :
                                                'upload_instruction_single'
                                            }
                                            defaultMessage={multiple ?
                                                'Upload your files here' :
                                                'Upload your file here'
                                            } />
                                    )}
                                </InstructionRow>
                                <MaximumSizeRow>
                                    {!!helper && helper}
                                    {!helper && (
                                        <FormattedMessage
                                            id="upload_size_maximum_megabytes"
                                            defaultMessage="Maximum file size: {megabytes}MB"
                                            values={{ megabytes: bytesToMegabytes(maxSize, 0) }} />
                                    )}
                                </MaximumSizeRow>
                            </>
                        )}
                        {!!fileName?.length && (
                            <FilledRow>
                                <Columns>
                                    <FlexChild>
                                        <Filename>
                                            {normalizeName(fileName)}
                                        </Filename>
                                    </FlexChild>
                                    <FlexChildShrink>
                                        <EmptyButton
                                            className="destructive"
                                            onClick={e => {
                                                e.stopPropagation()
                                                clear()
                                            }}>
                                            <Empty size={24} />
                                        </EmptyButton>
                                    </FlexChildShrink>
                                </Columns>
                            </FilledRow>
                        )}
                    </>
                )}
                {!!loading && <Loader className="constructive" />}
            </Dropzone>
            {!!error && (
                <Message
                    type="warning"
                    message={formatMessage({
                        id: getUploadErrorTranslationId(error.code),
                        defaultMessage: error.code
                    }, {
                        rejectedType: `.${error.type}`,
                        acceptedTypes: (
                            <AcceptedExtensionsList
                                accept={accept}
                                key={`${id}:${getUploadErrorTranslationId(error.code)}`} />
                        ),
                        megabytes: bytesToMegabytes(maxSize, 0)
                    })} />
            )}
        </>
    )
})

export const Invisible = forwardRef(({ multiple, maxSize = tenMegabytesAsBytes, controlProps = {}, name, id, ...props }, ref) => {
    const { formatMessage } = useIntl()
    const { triggerChange } = useForm()

    const [error, setError] = useState(null)

    // onDrop covers both the drop and click-and-browse interactions
    const onDrop = useCallback((accepted, rejected, event) => {
        setError(null)

        if(!multiple) {
            if(!rejected.length && !!accepted.length) {
                // Only the drop interaction has a dataTransfer, and it needs
                // to have the files set for the form to pick up the value
                if(!!event?.dataTransfer) {
                    inputRef.current.files = event.dataTransfer.files
                }

                const [file] = accepted

                triggerChange(name)
                validate(file)
            }

            if(rejected?.length) {
                const { code } = rejected[0].errors?.[0] ?? {}
                const { extension: type } = splitName(rejected[0].file?.name)

                if(!type && code === 'file-invalid-type') {
                    setError({ code: 'file-missing-type' })
                } else {
                    setError({ code, type })
                }
            }
        }
    }, [name])

    useEffect(() => {
        props.onError?.({
            [name]: error ? {
                code: error.code,
                message: formatMessage({
                    id: getUploadErrorTranslationId(error.code),
                    defaultMessage: error.code
                }, {
                    rejectedType: `.${error.type}`,
                    acceptedTypes: (
                        <AcceptedExtensionsList
                            accept={props.accept}
                            key={`${id}:${getUploadErrorTranslationId(error.code)}`} />
                    ),
                    megabytes: bytesToMegabytes(maxSize, 0)
                })
            } : null
        })
    }, [error, name, id, maxSize])

    const validate = file => {
        if(!multiple) {
            let error = false

            if(file?.size > maxSize) {
                clear()
                setError({ code: 'file-too-large' })
                error = true
            }

            props.onChange?.({ [name]: error ? null : file })
        }
    }

    const { getRootProps, getInputProps, inputRef, acceptedFiles } = useDropzone({
        ...props,
        multiple,
        maxSize,
        onDrop,
        noDragEventsBubbling: true,
        useFsAccessApi: false
    })

    const clear = useCallback(() => {
        if(supportsDataTransferConstructor()) {
            inputRef.current.files = new DataTransfer().files
        } else {
            acceptedFiles.length = 0
            acceptedFiles.splice(0, acceptedFiles.length)
        }

        inputRef.current.value = ''
        triggerChange(name)
        props?.onClear?.()
        props.onChange?.({ [name]: null })
        props.onError?.({ [name]: null })
    }, [name, inputRef?.current])

    const combinedInputRef = mergeRefs([inputRef, ref])

    const rootProps = getRootProps({ ...omit(controlProps, 'loading'), name, tabIndex: -1 })
    const inputProps = getInputProps({ name, id })

    return (
        <div {...rootProps}>
            <input
                {...inputProps}
                onClick={(...args) => {
                    inputProps.onClick?.(...args)
                    clear()
                }}
                ref={combinedInputRef} />
        </div>
    )
})

export const tenMegabytesAsBytes = megabytesToBytes(10)

export const getUploadErrorTranslationId = code => ({
    'file-invalid-type': 'upload_error_file_type_rejected',
    'file-missing-type': 'upload_error_file_type_missing',
    'file-too-large': 'upload_error_file_too_large',
    'file-too-small': 'upload_error_file_too_small'
})[code]

const supportsDataTransferConstructor = () => {
    try {
        new DataTransfer()
    } catch (err) {
        return false
    }

    return true
}
import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { reduce } from 'utilities/object'
import { sort } from 'utilities/people'
import { getGroupUrl } from 'utilities/url'
import { FlexChildShrink } from 'components/flex'
import {
    LoadingContainer,
    Hero, Heading, SubHeading, HeadingLink,
    ContentRow, Section, SectionHeading, Empty,
    Actions
} from './s'
import { Name as RoleName } from 'components/role'
import Loader from 'components/loader'
import People from './people'
import { Plain } from 'components/button'

const GroupPreviewContent = ({ salt, ...props }) => {
    const { locale, formatMessage } = useIntl()

    const [grantees, setGrantees] = useState([])
    const [members, setMembers] = useState([])

    const peopleSorter = sort(locale)

    useEffect(() => {
        if(!!props.group?.id) {
            setGrantees(getGranteesByRole(props.group.grants, locale, peopleSorter))
            setMembers(peopleSorter(props.group.members))
        }
    }, [props.group])

    let {
        group,
        dismiss
    } = props

    if(!group?.id) {
        return (
            <LoadingContainer>
                <Loader />
            </LoadingContainer>
        )
    }

    const goToLinkData = goToLinkDataMap[group.type]

    return (
        <>
            <Hero>
                <Heading>
                    <FormattedMessage
                        id="people_list_in"
                        defaultMessage="People in {group}"
                        values={{ group: group.name }}
                        />
                </Heading>
                <SubHeading>
                    <HeadingLink
                        href={getGroupUrl(group)}
                        text={formatMessage(goToLinkData)}
                        className="constructive"
                        />
                </SubHeading>
            </Hero>
            <ContentRow>
                {grantees?.map(({ id, name, deletable, people }) => (
                    <Section key={`${salt}:role:${id}`}>
                        <SectionHeading>
                            <RoleName
                                name={name}
                                deletable={deletable} />
                        </SectionHeading>
                        <People
                            people={people}
                            salt={salt} />
                    </Section>
                ))}
                {!!grantees?.length && (
                    <Section>
                        <SectionHeading>
                            <FormattedMessage
                                id="noun_members"
                                defaultMessage="Members" />
                        </SectionHeading>
                        <People
                            people={members}
                            salt={salt} />
                    </Section>
                )}
                {!grantees?.length && (
                    <People
                        people={members}
                        salt={salt} />
                )}
                {(!group.grants.length && !group.members.length) && (
                    <Empty className="compact">
                        <FormattedMessage
                            id="group_person_picker_empty"
                            defaultMessage="No people in {name}"
                            values={group} />
                    </Empty>
                )}
            </ContentRow>
            <FlexChildShrink>
                <Actions className="centered">
                    <Plain onClick={() => dismiss()}>
                        <FormattedMessage
                            id="action_close"
                            defaultMessage="Close" />
                    </Plain>
                </Actions>
            </FlexChildShrink>
        </>
    )
}

const goToLinkDataMap = {
    team: {
        id: 'team_action_navigate',
        defaultMessage: 'Go to team'
    },
    location: {
        id: 'location_action_navigate',
        defaultMessage: 'Go to location'
    }
}

const getGranteesByRole = (grants = [], locale, sorter) => {
    const granteesByRole = grants.reduce((accumulator, { role: { name, id, deletable }, users }) => ({
        ...accumulator,
        [id]: {
            name,
            deletable,
            people: sorter(users)
        }
    }), {})

    return reduce(granteesByRole, (accumulator, data, id) => [
        ...accumulator,
        { ...data, id }
    ], [])
        .filter(({ people }) => !!people.length)
        .sort(({ name: one }, { name: two }) => {
            return one.localeCompare(two, locale, { sensitivity: 'base' })
        })
}

export default GroupPreviewContent
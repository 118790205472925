import React from 'react'
import { Rows } from './s'
import User from './user'
import Unit from './unit'

const AccessUnits = ({ value, updateAccessUnit, removeAccessUnit, formatAdminTooltip, salt }) => (
    <Rows>
        {value.map(({ unit, access, locked = false }) => {
            salt = `${salt}:${unit.type}:${unit.id}`

            if(unit.type === 'user') {
                return (
                    <User
                        user={unit}
                        access={access}
                        locked={locked}
                        update={value => updateAccessUnit(unit.id, {
                            share: value === 'share',
                            owner: value === 'owner'
                        })}
                        remove={() => removeAccessUnit(unit.id)}
                        salt={salt}
                        key={`${salt}:owner:${!!access.owner}:share:${!!access.share}`} />
                )
            }

            return (
                <Unit
                    unit={unit}
                    access={access}
                    locked={locked}
                    formatAdminTooltip={formatAdminTooltip}
                    update={(value, which) => updateAccessUnit(unit.id, { [which]: value === which })}
                    remove={() => removeAccessUnit(unit.id)}
                    drawable={{
                        force: true,
                        open: true
                    }}
                    salt={salt}
                    key={`${salt}:owner:${!!access.owner}:share:${!!access.share}`} />
            )
        })}
    </Rows>
)

export default AccessUnits
import styled, { css } from 'styled-components'
import {
    svgStroke,
    belowPhone
} from 'utilities/styled'
import { motion } from 'framer-motion'
import { Rows as RowsBase, Columns } from 'components/flex'
import { Grid } from 'components/grid'
import { H4 } from 'components/typography/heading'

export const Container = styled(RowsBase)`
    margin-block-end: 32px;
    min-height: 24px;
`

export const NakedContainer = styled.div``

export const Header = styled(Grid)`
    grid-template-columns: min-content minmax(0, 1fr) min-content;
    align-items: center;
    user-select: none;

    &.has-actions:not(.has-toggle) {
        grid-template-columns: minmax(0, 1fr) min-content;
    }

    &.has-toggle:not(.has-actions) {
        grid-template-columns: min-content minmax(0, 1fr);
    }

    &:not(.has-toggle):not(.has-actions) {
        grid-template-columns: minmax(0, 1fr);
    }
`

export const NakedHeader = styled(Grid)`
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    column-gap: 24px;
    user-select: none;

    &.trigger {
        cursor: pointer;

        border-radius: 4px;

        transition: outline .1s ease-in-out;

        &:focus-visible {
            outline: 2px solid var(--huma-color-border-constructive-focus);
            outline-offset: 1px;
        }
    }

    ${NakedContainer}.header-spread & {
        grid-template-columns: 1fr auto;
        column-gap: 16px;
    }

    ${({ theme: { layout } }) => {
        if(layout === 'dashboard') {
            return css`
                padding-inline: 24px;

                ${belowPhone`
                    grid-template-columns: 1fr auto;
                    padding-inline: 0;
                `}
            `
        }
    }}
`

export const ToggleCell = styled(motion.div).attrs(() => ({
    variants: {
        contracted: {
            transform: 'rotate(0deg)'
        },
        queued: {
            transform: 'rotate(-90deg)'
        },
        expanded: {
            transform: 'rotate(-180deg)'
        }
    },
    transition: {
        duration: .15
    }
}))`
    display: grid;
    place-items: center;
    width: 24px;
    height: 24px;

    &:not(.naked) {
        grid-column: 1;
    }

    &.naked,
    ${Header}.interactive & {
        cursor: pointer;
    }

    ${Header}:not(.has-content) & {
        opacity: calc(1/3);
    }

    ${({ theme: { layout } }) => {
        if(layout === 'dashboard') {
            return belowPhone`
                align-self: start;
                margin-top: 4px;
            `
        }
    }}

    svg {
        ${svgStroke()}
    }
`

export const HeadingCell = styled.div`
    grid-column: 2;
    position: relative;
    z-index: 1;

    ${Header}:not(.has-toggle) & {
        grid-column: 1;
    }

    ${Header}.has-content.interactive & {
        cursor: pointer;
    }

    &:before {
        content: "";

        position: absolute;
        z-index: -1;
        top: 50%;
        left: 0;

        border-top: 1px solid var(--huma-color-border-default);
        width: 100%;
    }

    ${belowPhone`
        align-self: end;
    `}
`

const strikeCss = css`
    text-decoration: line-through;
    color: rgb(from var(--huma-color-foreground-default) r g b / 0.5);
`

export const Heading = styled(H4)`
    display: inline-flex;
    padding-inline-end: 16px;
    line-height: 24px;
    font-size: 16px;
    background: var(--huma-color-surface-default);

    ${Header}.has-toggle & {
        padding-inline-start: 16px;
    }

    &.strike {
        ${strikeCss}
    }

    ${belowPhone`
        ${Header}.has-actions & {
            padding-inline-end: 16px;
        }
    `}
`

export const Subheading = styled.span`
    margin-inline-start: 4px;

    color: var(--huma-color-foreground-subtle);

    &.strike {
        ${strikeCss}
    }
`

export const ActionsCell = styled(Columns)`
    grid-column: 3;

    margin-inline-start: 16px;

    ${belowPhone`
        margin-inline-start: 16px;
    `}

    > div:not(:last-child) {
        margin-inline-end: 16px;
    }

    ${Header}:not(.has-toggle) & {
        grid-column: 2;
    }
`

export const StatusCell = styled(Grid)`
    align-items: center;
    grid-column: 4;

    margin-inline-start: 16px;

    > div:not(:last-child) {
        margin-inline-end: 16px;
    }

    ${Header}:not(.has-actions) & {
        grid-column: 3;
    }

    ${Header}:not(.has-actions):not(.has-toggle) & {
        grid-column: 2;
    }
`

export const Drawer = styled(motion.div).attrs(() => ({
    variants: {
        contracted: {
            height: 0,
            overflow: 'hidden'
        },
        queued: {
            height: 0,
            overflow: 'hidden'
        },
        expanded: {
            height: 'auto',
            transitionEnd: {
                overflow: 'visible'
            }
        }
    },
    transition: {
        duration: .15
    }
}))`
    position: relative;

    max-height: 100%;
`

export const Content = styled.div`
    padding-block-start: 24px;

    > *:last-child {
        margin-block-end: 0;
    }
`

export const NakedContent = styled.div``
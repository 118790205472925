import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { modalify, Wrapper, Veil, Modal } from 'utilities/modal'
import PeopleProvider from 'contexts/people'
import { useAppSignal } from 'hooks/app-signal'
import { cls } from 'utilities/dom'
import { isInRange } from 'utilities/math'
import { Columns, FlexChild, FlexChildShrink } from 'components/flex'
import { Plain, Button } from 'components/button'
import {
    Container, Hero, Herows, Heading, Caption,
    ContentRow, Status, EmptyContainer,
    Actions
} from './s'
import ToggleAll from 'components/picker-toggle-all'
import Paragraph from 'components/typography/paragraph'
import Message from 'components/message'
import Filter from './filter'
import People from './people'

const PeoplePicker = ({ className, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const namespace = `${salt}:people-picker:filter`

    const [prepicked, setPrepicked] = useState([])
    const [locked, setLocked] = useState([])
    const [picked, setPicked] = useState([])
    const [resettable, setResettable] = useState(false)
    const [filterResetAt, setFilterResetAt] = useState(Date.now())

    useAppSignal({
        namespace: `${namespace}:advanced-applied`,
        action: advancedApplied => setResettable(advancedApplied)
    })

    const {
        params = {},
        filterOverrides = {},
        endpoint = {},
        paging = {},
        people,
        onSelect,
        dismiss,
        doneAction,
        cancelAction,
        heading = formatMessage({
            id: 'people_action_pick',
            defaultMessage: 'Pick people'
        }),
        caption,
        message,
        limitCaption,
        heroContent,
        emptyText = formatMessage({
            id: 'empty_filter',
            defaultMessage: '🤷 Sorry, nothing was found with your current filter configuration.'
        }),
        enableIsActiveFilter = false,
        method = 'add',
        max = Infinity,
        ...modal
    } = props

    const min = props.min ?? ((method === 'add') ? 1 : 0)

    let { pickedHeading } = props

    if(pickedHeading) {
        if(typeof pickedHeading === 'function') {
            pickedHeading = pickedHeading({ picked })
        }
    } else {
        pickedHeading = formatMessage({
            id: 'people_selected_count',
            defaultMessage: '{count, plural, =0 {No people} one {one person} other {# people}} selected'
        }, { count: picked.length })
    }

    const {
        show = false,
        outer = true
    } = modal

    const withoutExcluded = (array = []) => array.filter(({ id }) => !params?.excludeIds?.includes(id))

    useEffect(() => {
        const prepicked = withoutExcluded(props.prepicked ?? props.picked ?? [])
        setPrepicked(prepicked)

        const locked = withoutExcluded(props.locked ?? [])
        setLocked(locked)

        if(method === 'manage' && prepicked.length) {
            setPicked(prepicked)
        }

        return () => {
            setPrepicked([])
            setLocked([])
            setPicked([])
        }
    }, [show, method])

    className = cls([
        className,
        method
    ])

    const togglePicked = person => setPicked(picked => {
        let selection = [
            ...picked,
            person
        ]

        if(!!picked.find(({ id }) => id === person.id)) {
            selection = picked.filter(({ id }) => id !== person.id)
        }

        onSelect?.(selection)
        return selection
    })

    const animate = show ? 'in' : 'out'
    const isWithinRange = isInRange({ min, max, value: picked.length })

    // TODO: Remove this logic when the API supports combining permission
    // filters with other filters supported by UI components
    const filterable = !params?.permission

    const cancel = cancelAction()
    const done = doneAction?.({ picked })

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    {!!outer && <Veil animate={animate} />}
                    <Modal
                        dismiss={dismiss}
                        key={`modal:people-picker:${salt}`}>
                        <PeopleProvider
                            people={people}
                            endpoint={endpoint}
                            filter={params}
                            paging={paging}
                            autoFetch={true}>
                            {({ people, fixed, loading, hasFetched, autoFetch, paging, setPeopleFilter }) => (
                                <Container
                                    {...(className ? { className } : null)}
                                    animate={animate}>
                                    <Hero>
                                        <Herows>
                                            <FlexChildShrink>
                                                <Columns>
                                                    <FlexChild>
                                                        <Heading>{heading}</Heading>
                                                    </FlexChild>
                                                    {(method !== 'view') && (
                                                        <FlexChildShrink>
                                                            <ToggleAll
                                                                loaded={people}
                                                                prepicked={prepicked}
                                                                locked={locked}
                                                                picked={picked}
                                                                setPicked={(...args) => {
                                                                    setPicked(...args)
                                                                    onSelect?.(...args)
                                                                }}
                                                                method={method}
                                                                salt={salt} />
                                                        </FlexChildShrink>
                                                    )}
                                                </Columns>
                                            </FlexChildShrink>
                                            {!!caption && (
                                                <FlexChildShrink>
                                                    <Caption className="compact">{caption}</Caption>
                                                </FlexChildShrink>
                                            )}
                                            {!!limitCaption && (
                                                <FlexChildShrink>
                                                    <Caption className={cls([
                                                        !isWithinRange && 'destructive',
                                                        'compact'
                                                    ])}>
                                                        {limitCaption}
                                                    </Caption>
                                                </FlexChildShrink>
                                            )}
                                            {!!message && (
                                                <FlexChildShrink>
                                                    <Message
                                                        {...message}
                                                        className={cls(['compact', message?.className])} />
                                                </FlexChildShrink>
                                            )}
                                            {!!heroContent && (
                                                <FlexChildShrink>
                                                    {heroContent}
                                                </FlexChildShrink>
                                            )}
                                        </Herows>
                                    </Hero>
                                    {(filterable && !fixed) && (
                                        <Filter
                                            params={params}
                                            overrides={filterOverrides}
                                            enableIsActiveFilter={enableIsActiveFilter}
                                            namespace={namespace}
                                            salt={`${salt}:filter`}
                                            key={filterResetAt} />
                                    )}
                                    <ContentRow>
                                        {(!!people.length && !loading && autoFetch) && (
                                            <Status className="caption compact small">
                                                <FormattedMessage
                                                    id={(!fixed && !!paging?.hasNextPage) ?
                                                        'picker_status_scroll_to_load_more' :
                                                        'picker_status_all_loaded'
                                                    }
                                                    defaultMessage={(!fixed && !!paging?.hasNextPage) ?
                                                        '{count} loaded – scroll to load more' :
                                                        'All {count} are loaded'
                                                    }
                                                    values={{ count: people.length }} />
                                            </Status>
                                        )}
                                        {(!people.length && !loading && hasFetched) && (
                                            <EmptyContainer>
                                                <Paragraph className="caption small">{emptyText}</Paragraph>
                                                {resettable && (
                                                    <Plain
                                                        className="destructive"
                                                        onClick={() => {
                                                            setPeopleFilter({ filter: params })
                                                            setFilterResetAt(Date.now())
                                                        }}>
                                                        <FormattedMessage
                                                            id="action_reset_filters"
                                                            defaultMessage="Reset filters" />
                                                    </Plain>
                                                )}
                                            </EmptyContainer>
                                        )}
                                        <People
                                            prepicked={prepicked}
                                            locked={locked}
                                            picked={picked}
                                            method={method}
                                            togglePicked={togglePicked}
                                            salt={salt} />
                                    </ContentRow>
                                    <FlexChildShrink>
                                        <Actions {...(!cancel || !done) ? { className: 'centered' } : null}>
                                            {cancel && (
                                                <Plain
                                                    className={cancel.effect}
                                                    onClick={cancel.onClick}>
                                                    {cancel.label}
                                                </Plain>
                                            )}
                                            {done && (
                                                <Button
                                                    className={done.effect}
                                                    onClick={() => {
                                                        done.onClick()
                                                        dismiss()
                                                    }}
                                                    disabled={!isWithinRange}>
                                                    {done.label}
                                                </Button>
                                            )}
                                        </Actions>
                                    </FlexChildShrink>
                                </Container>
                            )}
                        </PeopleProvider>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

export default PeoplePicker
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useChart } from 'contexts/chart'
import { map } from 'utilities/object'
import { sharesToRoundedPercentages } from 'utilities/array'
import { Title, CustomTooltip, reduceLabels, formatValue, getColor } from 'components/charts'
import {
    ResponsiveContainer,
    PieChart as RePieChart, Pie, Cell, Sector,
    Tooltip
} from 'recharts'
import { Container } from 'components/charts/s'
import { Content as AsTable } from 'components/charts/table'

const hidePercentBelowThreshold = 5

const PieChart = () => {
    const { formatMessage } = useIntl()
    const { territories } = useI18n()
    const [activeSliceIndex, setActiveSliceIndex] = useState(null)

    const { chart } = useChart()

    if(!chart?.labels?.length) {
        return null
    }

    const valueFormatter = formatValue({
        format: chart.valueFormat,
        formatMessage,
        territories
    })

    const labelReducer = reduceLabels({
        formatMessage,
        territories
    })

    const labels = chart.labels.reduce(labelReducer, {})
    const labelsInOrder = Object.values(labels)
    const unspecifiedIndex = Object.keys(labels).indexOf('unspecified')

    const slices = map(chart.data, (value, key) => ({
        label: labels[key],
        value
    }))
        .sort(({ label: one }, { label: two }) => {
            return labelsInOrder.indexOf(one) - labelsInOrder.indexOf(two)
        })

    const colors = slices.map((slice, index) => getColor({
        theme: chart.colors,
        index,
        unspecifiedIndex,
        total: slices.length
    }))

    const percentages = sharesToRoundedPercentages(slices.map(({ value }) => value))

    return (
        <Container>
            <Title id={chart.id} />
            <ResponsiveContainer
                width="100%"
                height={224}>
                <RePieChart
                    margin={{
                        top: 32,
                        right: 32,
                        bottom: 64,
                        left: 32
                    }}>
                    <Tooltip
                        content={(
                            <CustomTooltip
                                valueFormatter={valueFormatter}
                                percent={(percentages[activeSliceIndex] < hidePercentBelowThreshold) ?
                                    percentages[activeSliceIndex] :
                                    null
                                }
                                wrapperStyle={{ outline: 'none' }}
                                salt={chart.id} />
                        )}
                        cursor={false} />
                    <Pie
                        data={slices}
                        nameKey="label"
                        dataKey="value"
                        label={(
                            <SliceLabel
                                labelsInOrder={labelsInOrder}
                                percentages={percentages} />
                        )}
                        cy={80}
                        innerRadius={56}
                        outerRadius={80}
                        startAngle={90}
                        endAngle={-270}
                        paddingAngle={.5}
                        stroke="var(--huma-color-surface-default)"
                        labelLine={false}
                        isAnimationActive={false}
                        onMouseEnter={(_, index) => setActiveSliceIndex(index)}
                        onMouseLeave={() => setActiveSliceIndex(null)}
                        activeIndex={activeSliceIndex}
                        activeShape={props => {
                            const {
                                cx,
                                cy,
                                startAngle,
                                endAngle,
                                midAngle
                            } = props

                            const sin = Math.sin(-radian * midAngle)
                            const cos = Math.cos(-radian * midAngle)
                            const sx = cx + 0 * cos
                            const sy = cy + 0 * sin

                            return (
                                <Sector
                                    cx={sx}
                                    cy={sy}
                                    innerRadius={56}
                                    outerRadius={84}
                                    startAngle={startAngle}
                                    endAngle={endAngle}
                                    fill={colors[activeSliceIndex]}
                                    cornerRadius={2} />
                            )
                        }}>
                        {slices.map((slice, index) => (
                            <Cell
                                fill={colors[index]}
                                cornerRadius={2}
                                key={`${chart.id}:slice:${index}`} />
                        ))}
                    </Pie>
                </RePieChart>
            </ResponsiveContainer>
            <AsTable
                chart={chart}
                showPercentages={true}
                colorize={true}
                setActiveIndex={setActiveSliceIndex} />
        </Container>
    )
}

export default PieChart

export const SliceLabel = ({ cx, cy, midAngle, payload, labelsInOrder, percentages }) => {
    const percent = percentages[labelsInOrder.indexOf(payload.label)]
    if((!percent && percent !== 0) || percent < hidePercentBelowThreshold) {
        return null
    }

    const radius = 104
    const x = (cx + radius * Math.cos(-midAngle * radian))
    const y = (cy + radius * Math.sin(-midAngle * radian))

    return (
        <text
            {...{ x, y }}
            fill="var(--huma-color-foreground-default)"
            textAnchor="middle"
            dominantBaseline="central">
            {`${percent} %`}
        </text>
    )
}

export const radian = Math.PI / 180
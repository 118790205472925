import React from 'react'
import { prune } from 'utilities/array'
import WordList from 'components/word-list'

const AcceptedExtensionsList = ({ accept }) => {
    const mimeExtensions = Object.values(accept)
    if(!mimeExtensions.length) {
        return ''
    }

    let extensions = mimeExtensions
        .filter(extensions => Array.isArray(extensions))
        .flat()

    extensions = prune(extensions)

    if(!extensions.length) {
        return ''
    }

    return <WordList words={extensions.flat()} />
}

export default AcceptedExtensionsList
import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Fields } from './s'
import { ModalHeading } from 'components/typography/heading'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import Message from 'components/message'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'
import { size } from 'utilities/object'

const EditLink = ({ usefulLinks = [], setUsefulLinks, link = {}, onChange, onDone, salt }) => {
    const { formatMessage } = useIntl()

    const {
        name,
        url: initialUrl
    } = link

    const [url, setUrl] = useState(initialUrl)

    const isEditing = !!size(link)

    const addOrUpdate = body => {
        let links

        if(isEditing) {
            links = usefulLinks?.map(link => {
                if(link.url === initialUrl) {
                    return {
                        ...link,
                        ...body
                    }
                }

                return link
            })
        } else {
            links = [
                ...usefulLinks,
                body
            ]
        }

        setUsefulLinks(links)
        onChange?.(links)

        onDone()
    }

    salt = `${salt}:edit`

    const heading = formatMessage({
        id: isEditing
            ? 'useful_links_action_edit'
            : 'useful_links_action_add',
        defaultMessage: isEditing
            ? 'Edit link'
            : 'Add link'
    })

    return (
        <Form
            layout="vertical"
            onSubmit={addOrUpdate}>
            {({ touched, errors, trigger }) => (
                <>
                    <ModalHeading>{heading}</ModalHeading>
                    <Fields>
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_name',
                                defaultMessage: 'Name'
                            })}
                            name="name"
                            controlProps={{ autoFocus: true }}
                            field={{
                                value: name,
                                required: true,
                                include: 'always'
                            }} />
                        <StringField
                            salt={salt}
                            label={formatMessage({
                                id: 'noun_link',
                                defaultMessage: 'Link'
                            })}
                            name="url"
                            field={{
                                value: url,
                                required: true,
                                strict: false,
                                include: 'always'
                            }}
                            controlProps={{ type: 'url' }}
                            onChange={({ url }) => setUrl(url)} />
                        {(usefulLinks.some(link => link.url === url) && url !== initialUrl) && (
                            <Message
                                type="error"
                                message={formatMessage({
                                    id: 'useful_links_error_duplicate',
                                    defaultMessage: 'This link already exists'
                                })} />
                        )}
                    </Fields>
                    <Actions className="compact">
                        <Plain
                            className="neutral"
                            onClick={onDone}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className="constructive"
                            disabled={[
                                !touched.length,
                                !!size(errors),
                                (usefulLinks.some(link => link.url === url) && url !== initialUrl)
                            ].some(Boolean)}
                            ref={trigger}>
                            <FormattedMessage
                                id={isEditing
                                    ? 'action_save'
                                    : 'action_add'
                                }
                                defaultMessage={isEditing
                                    ? 'Save'
                                    : 'Add'
                                } />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default EditLink
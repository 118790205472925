import React, { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import Tooltip from 'components/tooltip'
import { Simple } from 'components/button'
import { ColumnEdit } from 'components/feather'
import { Widget } from './s'
import ColumnConfiguratorContent from './content'

const ColumnConfigurator = ({ editingColumns, setEditingColumns, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const [acting, setActing] = useState(false)

    useEffect(() => {
        if(editingColumns) {
            setActing(true)
        }

        if(!acting) {
            setEditingColumns?.(false)
        }
    }, [acting, editingColumns])

    const widget = useRef()
    const control = useRef()

    return (
        <>
            <Tooltip
                content={formatMessage({
                    id: 'edit_columns_label',
                    defaultMessage: 'Edit columns'
                })}
                placement="top-end">
                <Simple
                    onClick={() => setActing(acting => !acting)}
                    icon={ColumnEdit}
                    ref={control} />
            </Tooltip>
            <Widget
                show={acting}
                clickOutside={{
                    inside: [control],
                    action: () => setActing(false)
                }}
                position={{
                    origin: control,
                    direction: {
                        x: {
                            where: 'inside',
                            to: 'left'
                        },
                        y: {
                            where: 'outside',
                            to: 'down',
                            adjust: 8
                        }
                    }
                }}
                blocking={true}
                salt={`${salt}:columns`}
                ref={widget}>
                <ColumnConfiguratorContent
                    {...props}
                    salt={salt} />
            </Widget>
        </>
    )
}

export default ColumnConfigurator

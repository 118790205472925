import styled, { keyframes } from 'styled-components'
import { CheckCircleFilled, AlertTriangleFilled, AlertOctagonFilled } from 'components/feather'
import { Loader4 } from 'styled-icons/remix-fill'

const rotateKeyframes = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`

export const Success = styled(CheckCircleFilled)`
    color: var(--huma-color-foreground-success);
`

export const Warning = styled(AlertTriangleFilled)`
    color: var(--huma-color-foreground-warning);
`

export const Error = styled(AlertOctagonFilled)`
    color: var(--huma-color-foreground-error);
`

export const Other = styled(Loader4)`
    animation: ${rotateKeyframes} 5s linear infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

    color: var(--huma-color-foreground-default);
`
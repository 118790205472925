import React from 'react'
import { SkeletonToolbar } from './s'
import { SkeletonRectangle } from 'components/skeleton'

const TiptapLoader = () => (
    <div>
        <SkeletonToolbar>
            <SkeletonRectangle size={32} />
            <SkeletonRectangle size={32} />
            <SkeletonRectangle size={32} />
        </SkeletonToolbar>
        <SkeletonRectangle size={280} />
    </div>
)

export default TiptapLoader

import Heading from '@tiptap/extension-heading'
import { textblockTypeInputRule } from '@tiptap/react'

const adjustLevel = level => (level === 1) ?
    2 :
    level

const TitleHeading = Heading.extend({
    parseHTML() {
        return this.options.levels.map(level => ({
            tag: `h${level}`,
            attrs: { level: adjustLevel(level) },
        }))
    },

    addKeyboardShortcuts() {
        return this.options.levels.reduce((accumulator, level) => ({
            ...accumulator,
            [`Mod-Alt-${level}`]: () => this.editor.commands.toggleHeading({ level: adjustLevel(level) })
        }), {
            'Mod-Alt-t': () => this.editor.commands.toggleHeading({ level: 2 })
        })
    },

    addInputRules() {
        return this.options.levels.map(level => textblockTypeInputRule({
            find: new RegExp(`^(#{1,${level}})\\s$`),
            type: this.type,
            getAttributes: {
                level: adjustLevel(level)
            }
        }))
    },

    // addPasteRules() {
    //     return this.options.levels.map(level => nodePasteRule({
    //         find: new RegExp(`^#{1,${level}}\\s`),
    //         type: this.type,
    //         getAttributes: {
    //             level: adjustLevel(level)
    //         }
    //     }))
    // }
})

export default TitleHeading
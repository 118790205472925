import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { size } from 'utilities/object'

const OAuth = () => {
    const [urlSearchParams] = useSearchParams()

    const searchParams = Object.fromEntries(urlSearchParams)

    useEffect(() => {
        if(size(searchParams) && global.opener) {
            global.opener.postMessage(searchParams)
            global.close()
        }
    }, [JSON.stringify(searchParams)])

    return <p>Loading…</p>
}

export default OAuth

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { List, Item, Good, Bad } from './s'

const Features = ({ features, size = 24, bad = false, salt, ...props }) => {
    if(!features?.length) {
        return null
    }

    return (
        <List {...props}>
            {features.map(feature => (
                <Item key={`${salt}:feature:${feature.id}`}>
                    <span>
                        {!bad && <Good size={size} />}
                        {!!bad && <Bad size={size} />}
                    </span>
                    <FormattedMessage {...feature} />
                </Item>
            ))}
        </List>
    )
}

export default Features
import React, { useState } from 'react'
import {
    Wrapper,
    ThumbnailWrapper, PlayButton, Play, Thumbnail, ThumbnailFallback
} from './s'
import { CaptionSmall } from 'components/typography/caption'
import Modal from 'modals/video'
import Embed from './embed'

const Loom = ({ id, caption, className, ...props }) => {
    const [showing, setShowing] = useState(false)
    const [showThumbnailFallback, setShowThumbnailFallback] = useState(false)

    if(!id) {
        return null
    }

    const salt = `loom:${id}`

    return (
        <Wrapper
            className={className}
            {...props}>
            <ThumbnailWrapper>
                <PlayButton>
                    <Play size={24} />
                </PlayButton>
                {!showThumbnailFallback && (
                    <Thumbnail
                        onClick={() => setShowing(true)}
                        onError={() => setShowThumbnailFallback(true)}
                        src={`https://cdn.loom.com/sessions/thumbnails/${id}-with-play.gif`} />
                )}
                {showThumbnailFallback && (
                    <ThumbnailFallback onClick={() => setShowing(true)} />
                )}
            </ThumbnailWrapper>
            {caption && <CaptionSmall className="compact">{caption}</CaptionSmall>}
            <Modal
                show={!!showing}
                dismiss={() => setShowing(false)}
                salt={`${salt}:embed`}>
                <Embed
                    id={id}
                    onDone={() => setShowing(false)} />
            </Modal>
        </Wrapper>
    )
}

export default Loom

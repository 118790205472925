import React, { forwardRef, Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { mergeRefs } from 'react-merge-refs'
import { useFlash } from 'hooks/flash'
import {
    CompetenceProfileWrapper,
    ProfileCellClickable, ProfileCellWrapper, Symbol, Name, Count,
    StatusCell, ProfileStatus,
    ActionsCell
} from './s'
import Tooltip from 'components/tooltip'
import ContextMenu, { getActionsArray } from 'widgets/context-menu'

const CompetenceProfile = forwardRef(({ profile, actions = {}, singleton, flash, salt }, ref) => {
    const { formatMessage } = useIntl()

    const flasher = useFlash(flash)

    if(!profile) {
        return null
    }

    const {
        symbol,
        name,
        targetsCount = 0,
        public: isPublic = false
    } = profile

    const view = actions?.view?.(profile)

    const [ProfileWrapper, profileWrapperProps] = view ?
        [Tooltip, {
            content: formatMessage({
                id: 'action_view_details',
                defaultMessage: 'View details'
            }),
            singleton
        }] :
        [Fragment, null]

    const [ProfileCell, profileCellProps] = view ?
        [ProfileCellClickable, { onClick: () => view.onClick() }] :
        [ProfileCellWrapper, null]

    return (
        <CompetenceProfileWrapper ref={mergeRefs([ref, flasher])}>
            <ProfileWrapper {...profileWrapperProps}>
                <ProfileCell {...profileCellProps}>
                    <Symbol
                        symbol={symbol}
                        className="hover-effect" />
                    <Name className="compact">{name}</Name>
                    <Count className="compact">
                        <FormattedMessage
                            id="competence_count"
                            defaultMessage="{count, plural, =0 {0 competences} =1 {1 competence} other {{count} competences}}"
                            values={{ count: targetsCount }} />
                    </Count>
                </ProfileCell>
            </ProfileWrapper>
            <StatusCell>
                <ProfileStatus status={isPublic ? 'public' : 'private'} />
            </StatusCell>
            {!!getActionsArray(actions)?.length && (
                <ActionsCell>
                    <ContextMenu
                        salt={salt}
                        context={profile}
                        actions={actions} />
                </ActionsCell>
            )}
        </CompetenceProfileWrapper>
    )
})

export default CompetenceProfile
import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { statusBackgroundColorsCss } from 'components/status-label'
import { OrderedListColumns, ListItemChildShrink } from 'components/list'
import Paragraph from 'components/typography/paragraph'
import { InlineButton, buttonStyles } from 'components/button'
import ModalBase from 'modals/generic'
import SymbolBase from 'components/symbol'

export const BlobsContainer = styled(OrderedListColumns)`
    align-items: flex-start;
    flex-wrap: wrap;

    width: 100%;
    min-height: 40px;
    padding-block-start: 4px;
`

export const Blob = styled(ListItemChildShrink)`
    margin: 0 4px 4px 0;
    max-width: 100%;
`

const blobCss = css`
    position: relative;
    display: block;

    border-radius: 4px;
    padding: 0 12px 0 32px;
    background-color: var(--huma-color-surface-minimal);

    font-size: 14px;
    line-height: 32px;
    color: var(--huma-color-foreground-default);

    svg {
        position: absolute;
        top: 8px;
        left: 8px;

        ${svgStroke('small')}
    }

    &:not(:has(> :first-child)) {
        padding-inline-start: 12px;
    }

    &.status {
        ${statusBackgroundColorsCss}
    }

    &.neutral {
        background-color: var(--huma-color-surface-minimal);
    }

    &.passive {
        background-color: var(--huma-color-surface-warning-minimal);
    }

    &.positive {
        background-color: var(--huma-color-surface-constructive-minimal);
    }

    &.active {
        background-color: var(--huma-color-surface-success-minimal);
    }

    &.failed {
        background-color: var(--huma-color-surface-destructive-minimal);
    }

    &.creative {
        background-color: var(--huma-color-surface-brand-minimal);
    }
`

export const BlobTrigger = styled(InlineButton)`
    ${buttonStyles.nakedCss}
    ${blobCss}

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    max-width: 100%;
    height: 32px;

    transition: all .1s ease-in-out;

    text-decoration: none;
    text-align: start;

    &:where(:hover, :focus) {
        background: var(--huma-color-surface-constructive-minimal);

        text-decoration: none;
        color: var(--huma-color-foreground-constructive-bold);
    }
`

export const BlobPlain = styled.span`
    ${blobCss}
`

export const TextsContainer = styled(Paragraph)`
    &.compact-aggregate {
        svg.aggregate-icon { margin-inline-end: 8px; }
    }

    svg.aggregate-icon {
        ${svgStroke('small')}
        margin-inline-end: 16px;
        margin-top: -.125em;
    }
`

export const TextTrigger = styled(InlineButton)`
    ${buttonStyles.nakedCss}

    transition: all .1s ease-in-out;

    color: var(--huma-color-foreground-subtle);
    text-align: start;
    text-decoration: none;

    &:focus,
    &:hover {
        color: var(--huma-color-foreground-constructive-bold);
        text-decoration: underline;
    }
`

export const TextPlain = styled.span`
    color: inherit;
`

export const Modal = styled(ModalBase)`
    padding: 0;
`

export const Symbol = styled(SymbolBase)`
    position: absolute;
    top: 6px;
    left: 6px;

    display: inline-flex;

    transform: scale(0.5);
    transform-origin: top left;
`
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { format, addDays } from 'date-fns'
import queryString from 'query-string'
import { ListItem, IconWrap } from './s'
import { Google } from 'components/icon'
import { ArrowUpRight } from 'styled-icons/feather'
import Paragraph from 'components/typography/paragraph'
import { getEmailAddress } from 'utilities/person'

// https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/google.md

const GoogleCalendar = ({ start, end, title: text, description: details, organizer, attendees = [] }) => {
    let dates

    start = format(start, 'yyyyMMdd')
    end = format(addDays(end, 1), 'yyyyMMdd')

    const add = [
        organizer,
        ...attendees
    ]
        .map(attendee => getEmailAddress(attendee))
        .join(',')

    if(!!start && !!end) {
        dates = `${start}/${end}`
    }

    const url = queryString.stringifyUrl({
        url: 'https://calendar.google.com/calendar/render',
        query: {
            action: 'TEMPLATE',
            dates,
            text,
            details,
            add
        }
    })

    return (
        <ListItem
            href={url}
            target="_blank">
            <Google />
            <Paragraph className="compact">
                <FormattedMessage
                    id="noun_google_calendar"
                    defaultMessage="Google Calendar" />
            </Paragraph>
            <IconWrap>
                <ArrowUpRight size={24} />
            </IconWrap>
        </ListItem>
    )
}

export default GoogleCalendar
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { size } from 'utilities/object'
import Form from 'components/form/controller'
import { H2 as Heading } from 'components/typography/heading'
import StringField from 'components/form/field/string'
import CheckboxField from 'components/form/field/checkbox'
import Actions from 'components/form/actions'
import { Plain, ButtonSubmit } from 'components/button'

const ManageLinkContent = ({ editor, link, dismiss, salt }) => {
    const { formatMessage } = useIntl()

    const onSubmit = ({ href, external }) => {
        const attributes = {
            href,
            target: external ?
                '_blank' :
                '_self'
        }

        if(link) {
            editor.chain().focus().extendMarkRange('link').setLink(attributes).run()
        } else {
            editor.chain().focus().toggleLink(attributes).run()
        }

        dismiss()
    }

    return (
        <Form
            layout="vertical"
            onSubmit={onSubmit}>
            {({ touched, errors, trigger }) => (
                <>
                    <Heading>
                        <FormattedMessage
                            id={link ?
                                'link_action_edit' :
                                'action_add_link'
                            }
                            defaultMessage={link ?
                                'Edit link' :
                                'Add link'
                            } />
                    </Heading>
                    <StringField
                        salt={salt}
                        label={formatMessage({
                                id: 'noun_link',
                                defaultMessage: 'Link'
                            })}
                        name="href"
                        field={{
                            value: link?.href ?? '',
                            required: true,
                            strict: true,
                            include: 'always'
                        }}
                        controlProps={{
                            type: 'url',
                            placeholder: formatMessage({
                                id: 'link_placeholder',
                                defaultMessage: 'https://…'
                            })
                        }} />
                    <CheckboxField
                        salt={salt}
                        label={formatMessage({
                            id: 'link_label_open_in_new_window',
                            defaultMessage: 'Open in new window?'
                        })}
                        name="external"
                        field={{
                            value: !link || link?.target === '_blank',
                            include: 'always'
                        }}
                        interaction="switch" />
                    <Actions className="compact">
                        <Plain onClick={dismiss}>
                            <FormattedMessage
                                id="action_cancel"
                                defaultMessage="Cancel" />
                        </Plain>
                        <ButtonSubmit
                            className="constructive"
                            disabled={!touched.length || !!size(errors)}
                            ref={trigger}>
                            <FormattedMessage
                                id={link ?
                                    'link_action_edit' :
                                    'action_add_link'
                                }
                                defaultMessage={link ?
                                    'Edit link' :
                                    'Add link'
                                } />
                        </ButtonSubmit>
                    </Actions>
                </>
            )}
        </Form>
    )
}

export default ManageLinkContent
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Columns as ColumnsBase } from 'components/flex'

export const Columns = styled(ColumnsBase)`
    align-items: center;

    svg {
        display: flex;
        ${svgStroke()}
    }
`

import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDownload } from 'hooks/download'
import { cls } from 'utilities/dom'
import { Button } from 'components/button'
import { DownloadCloud } from 'styled-icons/feather'

const DownloadButton = ({
    path, generateName, external = false,
    as: Trigger = Button,
    ...props
}) => {
    const { formatMessage } = useIntl()

    const {
        made,
        objectUrl,
        file,
        name: remoteName,
        trigger,
        download
    } = useDownload({
        path,
        generateName,
        external,
        auto: false
    })

    const className = cls([
        props?.className,
        'constructive',
        (!!made && !objectUrl) && 'loading'
    ])

    const label = props.label ?? formatMessage({
        id: 'action_download',
        defaultMessage: 'Download'
    })

    useEffect(() => {
        made && download()
    }, [path, made])

    return (
        <Trigger
            as="a"
            {...(!made ? { onClick: download } : null)}
            {...((!!objectUrl && !global.navigator.msSaveBlob) ? {
                href: objectUrl,
                download: remoteName
            } : null)}
            {...((!!file && !!global.navigator.msSaveBlob) ? {
                onClick: () => global.navigator.msSaveBlob(file, remoteName)
            } : null)}
            {...(className ? { className } : null)}
            icon={DownloadCloud}
            ref={trigger}>
            {label}
        </Trigger>
    )
}

export default DownloadButton
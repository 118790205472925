import React from 'react'
import { EmbedWrapper, Iframe } from './s'
import { CloseButton } from 'components/button'

const Embed = ({ id, onDone }) => {
    return (
        <EmbedWrapper>
            <Iframe
                src={`https://www.loom.com/embed/${id}?hideEmbedTopBar=true`}
                allowFullScreen={true} />
            <CloseButton onClick={onDone} />
        </EmbedWrapper>
    )
}

export default Embed

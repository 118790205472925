import React from 'react'
import { useChart } from 'contexts/chart'
import SingleDimension from './single-dimension'
import MultiDimension from './multi-dimension'

const BarChart = () => {
    const { chart } = useChart()

    if(!chart?.labels?.length) {
        return null
    }

    if(chart.type === 'single-dimension') {
        return <SingleDimension />
    }

    return <MultiDimension />
}

export default BarChart
import React from 'react'
import Prompt from 'prompts/standard'
import { omit } from 'utilities/object'

const Prompts = ({ actions = [], setPrompting, salt, ...props }) => {
    return actions
        .filter(({ prompt = null, onClick }) => prompt && onClick)
        .map(action => {
            const {
                prompt,
                effect = 'neutral',
                salt: promptSalt
            } = action

            const promptKey = `${salt}:${promptSalt}`
            const { [promptKey]: confirm } = props

            const key = `context-menu:prompt:${promptKey}`

            return (
                <Prompt
                    {...omit(prompt, 'cancelText', 'confirmText', 'content')}
                    show={!!confirm}
                    cancel={{
                        ...(('cancelText' in prompt) ? { text: prompt.cancelText } : null),
                        callback: () => setPrompting(false)
                    }}
                    confirm={{
                        text: prompt.confirmText,
                        effect,
                        callback: confirm ?? (() => {})
                    }}
                    loading={!!prompt?.loading}
                    salt={key}
                    key={key}>
                    {prompt.content ?? null}
                </Prompt>
            )
        })
}
export default Prompts
import styled from 'styled-components'
import Paragraph from 'components/typography/paragraph'

export const Sizer = styled.div`
    &.vertical {
        height: ${({ theme }) => 48 + (theme.bars * 56)}px;
    }
`

export const VerticalLabel = styled(Paragraph).attrs(attrs => ({
    ...attrs,
    xmlns: 'http://www.w3.org/1999/xhtml'
}))`
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    grid-gap: 24px;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &:not(.last)::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: -24px;
        left: 0;
        background-color: var(--huma-color-border-default);
    }

    > * {
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`
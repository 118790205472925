import React from 'react'
import { useIntl } from 'react-intl'
import { useDownload } from 'hooks/download'
import { cls } from 'utilities/dom'
import { Link } from './s'
import FileLoader from './loader'
import FileDisplay from './display'

const FileDownload = props => {
    const { formatMessage } = useIntl()

    const {
        meta,
        path,
        auto = false,
        external = false,
        generateName,
        displayProps = null
    } = props

    const className = cls([
        props?.className,
        'file',
        'constructive'
    ])

    const {
        made,
        objectUrl,
        file,
        name: remoteName,
        trigger,
        download
    } = useDownload({ path, auto, external, generateName })

    return (
        <Link
            {...((!auto && !made) ? { onClick: download } : null)}
            {...((!!objectUrl && !global.navigator.msSaveBlob) ? {
                href: objectUrl,
                download: remoteName
            } : null)}
            {...((!!file && !!global.navigator.msSaveBlob) ? {
                onClick: () => global.navigator.msSaveBlob(file, remoteName)
            } : null)}
            tabIndex={0}
            className={className}
            ref={trigger}>
            {(!auto && !made) && (
                <FileDisplay
                    {...displayProps}
                    name={meta?.fileName}
                    action="download" />
            )}
            {(!!made && !objectUrl) && (
                <FileLoader
                    {...displayProps}
                    name={meta?.fileName}
                    status={formatMessage({
                        id: 'status_downloading',
                        defaultMessage: 'Downloading…'
                    })} />
            )}
            {(!!made && !!objectUrl) && (
                <FileDisplay
                    {...displayProps}
                    name={remoteName}
                    action="download" />
            )}
        </Link>
    )
}

export default FileDownload
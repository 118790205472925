import styled from 'styled-components'
import {
    svgStroke, buttonOutlineProps,
    belowPhone
} from 'utilities/styled'
import { Columns as ColumnsBase, FlexChildShrink } from 'components/flex'
import SelectFieldBase from 'components/form/field/select'
import { Naked } from 'components/button'
import { Grid } from 'components/grid'

export const Wrap = styled(FlexChildShrink)`
    padding: 8px 0;
`

export const Columns = styled(ColumnsBase)`
    justify-content: space-between;
    row-gap: 8px;
`

export const MonthYearColumns = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    justify-content: space-between;
    column-gap: 4px;
`

export const SelectField = styled(SelectFieldBase)`
    font-size: 16px;
    font-weight: 500;
    margin: 0;

    select {
        cursor: pointer;

        ${belowPhone`
            max-width: 96px;
        `}
    }
`

export const NavigationColumn = styled(Grid)`
    grid-auto-columns: 40px;
    grid-auto-flow: column;
    margin-right: -8px;
`

export const TodayButton = styled(Naked)`
    border-radius: 8px;
    width: 40px;
    height: 40px;

    svg {
        transition: margin .1s ease-in-out;
        ${svgStroke()}
    }

    &:focus-visible {
        outline: ${buttonOutlineProps.width}px solid var(--huma-color-border-constructive-focus);
        outline-offset: ${buttonOutlineProps.offset}px;
    }

    @media (prefers-reduced-motion: no-preference) {
        &:focus-visible {
            transition: outline-offset 75ms ease-out;
        }
    }
`

export const PreviousMonthButton = styled(TodayButton)`
    &:hover svg {
        margin-left: -4px;
    }
`

export const NextMonthButton = styled(TodayButton)`
    &:hover svg {
        margin-right: -4px;
    }
`
import styled from 'styled-components'
import { darkMode, screenReaderOnly } from 'utilities/styled'

export const Options = styled.ul`
    --huma-component-rating-color: var(--huma-palette-neutral-80);

    ${darkMode`
        --huma-component-rating-color: var(--huma-palette-neutral-30);
    `}

    list-style: none;
    display: flex;
    align-items: center;
    column-gap: 8px;

    padding: 0;
`

export const Option = styled.li``

export const OptionLabel = styled.label`
    cursor: pointer;
    position: relative;

    display: block;

    width: 32px;
    height: 32px;

    transition: all .2s ease;

    line-height: 33px; /* Don’t ask me to align fonts ever again. */
    text-align: center;

    &::after {
        content: "";
        position: absolute;
        inset: 0;

        border-radius: 50%;
        border: 2px solid var(--huma-component-rating-color);

        transition: inset .2s cubic-bezier(.4, 0, .2, 1);
    }

    ${Option}:hover &::after {
        border-style: dashed;
        border-color: var(--huma-color-border-constructive-bold);
        inset: -2px;
    }

    ${Option}:not(.checked):hover & {
        color: var(--huma-color-foreground-constructive-bold);
        font-weight: 500;
    }

    ${Option}.checked &::after {
        border-style: dashed;
        border-color: var(--huma-color-border-bold);
    }

    ${Option}.checked & {
        font-weight: 500;
    }
`

export const OptionInput = styled.input.attrs({
    type: 'radio'
})`
    ${screenReaderOnly}
`
import React, { Component } from 'react'
import { notifyReactError } from 'utilities/bugsnag'
import Content from './content'
import { safeReload } from 'utilities/auth'

export class PageErrorBoundary extends Component {
    state = {
        error: null
    }

    static getDerivedStateFromError = error => ({ error })

    componentDidCatch = (error, info) => {
        notifyReactError(error, info)
        console.error(error, info)
    }

    render() {
        const { error } = this.state

        if(error) {
            if(
                /Loading chunk ([0-9]{1,4}) failed/g.test(error?.toString()) ||
                error?.toString?.()?.includes?.('ChunkLoadError')
            ) {
                safeReload()
                return null
            }

            return (
                <Content
                    context="page"
                    message={error?.toString?.()} />
            )
        }

        return this.props?.children ?? null
    }
}

export class ComponentErrorBoundary extends Component {
    state = {
        error: null
    }

    static getDerivedStateFromError = error => ({ error })

    componentDidCatch = (error, info) => {
        notifyReactError(error, info)
        console.error(error, info)
    }

    render() {
        const { error } = this.state
        const { crash = true } = this.props

        if(!!error && crash) {
            return (
                <Content
                    context="component"
                    message={error.toString?.()}
                    reset={() => this.setState({ error: null })} />
            )
        }

        return this.props?.children ?? null
    }
}

export class ModalErrorBoundary extends Component {
    state = {
        error: null
    }

    static getDerivedStateFromError = error => ({ error })

    componentDidCatch = (error, info) => {
        notifyReactError(error, info)
        console.error(error, info)
    }

    render() {
        const { error } = this.state

        const {
            crash = true,
            dismiss
        } = this.props

        if(!!error && crash) {
            return (
                <Content
                    context="modal"
                    message={error.toString?.()}
                    dismiss={dismiss} />
            )
        }

        return this.props?.children ?? null
    }
}
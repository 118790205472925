import styled, { css } from 'styled-components'
import {
    svgStroke, interpointDivider, flashKeyframes,
    belowPhone, belowTablet, fromPhone
} from 'utilities/styled'
import { Grid } from 'components/grid'
import Link from 'components/link'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import Paragraph from 'components/typography/paragraph'
import { Columns } from 'components/flex'
import BylineBase from 'components/byline'

export const templateGrid = css`
    grid-template-columns: minmax(0, 7fr) minmax(0, 4fr) 3fr;
`

export const Template = styled(Grid)`
    ${templateGrid}

    position: relative;
    column-gap: 24px;

    width: 100%;
    padding-block: 24px;

    & ~ & {
        box-shadow: 0 -1px var(--huma-color-border-default);
    }

    ${belowTablet`
        grid-template-columns: 1fr min-content;
        grid-auto-flow: row;
        grid-auto-rows: auto;
        row-gap: 8px;

        padding-block-end: 16px;

        & ~ & {
            padding-block: 16px;
        }
    `}

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }
`

export const TemplateCell = styled(Link)`
    display: grid;
    grid-template-columns: 40px auto;
    align-items: start;
    column-gap: 16px;

    height: auto;

    text-align: start;

    ${belowPhone`
        grid-column: 1 / 2
    `}

    &:not(:disabled):where(:hover, :focus) {
        text-decoration: none;
    }
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(TemplateCell)}
`

export const MetaCell = styled.div`
    line-height: 24px;

    & > p {
        white-space: break-spaces;
    }
`

export const Name = styled(Paragraph)`
    ${TemplateCell}:where(:hover, :focus) & {
        text-decoration: underline;
    }
`

export const UpdatedCell = styled(Columns)`
    line-height: 24px;

    ${fromPhone`
        flex-direction: column;
        align-items: flex-start;
    `}

    ${belowPhone`
        flex-direction: row;
        order: 2;
        grid-column: span 2;

        padding-inline-start: 56px;

        font-size: 14px;
        color: var(--huma-color-foreground-subtle);

        ${interpointDivider()}
    `}
`

export const Byline = styled(BylineBase)`
    overflow: hidden;
    margin: 0;
    max-width: 100%;

    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    color: var(--huma-color-foreground-subtle);
`

export const ActionsCell = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    column-gap: 16px;
    justify-content: end;
    align-items: center;

    ${belowTablet`
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    `}

    svg {
        ${svgStroke()}
    }

    .actions-button {
        margin-block: -8px;

        ${belowTablet`
            margin-block: 0;
        `}
    }
`

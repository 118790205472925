import { emojiIndex } from 'emoji-mart'
import Emoji from '@tiptap-pro/extension-emoji'
import { ReactRenderer } from '@tiptap/react'
import { PluginKey } from '@tiptap/pm/state'
import Suggestions from './suggestions'
import tippy from 'tippy.js'
import { omit } from 'utilities/object'
import { getTippySuggestionsConfiguration } from '../utilities'

export const EmojiPluginKey = new PluginKey('emoji')

const CustomEmoji = Emoji.extend({
    addAttributes() {
        return {
            ...Emoji.config.addAttributes(),
            emoji: {
                default: null,
                parseHTML: element => element.dataset.emoji,
                renderHTML: attributes => ({ 'data-emoji': attributes.emoji })
            }
        }
    },

    addOptions() {
        return {
            ...omit(Emoji.options, 'HTMLAttributes'),
            suggestion: {
                ...Emoji.options.suggestion,
                pluginKey: EmojiPluginKey,

                items: ({ query }) => emojiIndex.search(query)
                    ?.map(({ id, native }) => ({
                        name: id,
                        emoji: native
                    }))
                    ?.slice(0, 5) ?? [],

                render: () => {
                    let suggestions
                    let popup

                    return {
                        onStart: props => {
                            suggestions = new ReactRenderer(Suggestions, {
                                props,
                                editor: props.editor
                            })

                            if(!props.clientRect) {
                                return
                            }

                            popup = tippy('body', getTippySuggestionsConfiguration(props, suggestions))
                        },

                        onUpdate: props => {
                            suggestions?.updateProps(props)

                            if(!props.clientRect) {
                                return
                            }

                            popup?.[0]?.setProps({
                                getReferenceClientRect: props.clientRect
                            })
                        },

                        onKeyDown: props => {
                            if(props.event.key === 'Escape') {
                                popup?.[0]?.hide()
                                return true
                            }

                            return suggestions?.ref?.onKeyDown(props)
                        },

                        onExit: () => {
                            suggestions?.destroy()
                            popup?.[0]?.destroy()
                        }
                    }
                }
            }
        }
    }
})

export default CustomEmoji
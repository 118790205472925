import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { Values } from './s'
import { Tag } from 'components/tags/s'

const ViewArray = ({ field = {}, className, label }) => {
    let { value } = field

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                {!!value?.length && (
                    <Values>
                        {value.map(value => (
                            <Tag key={value}>
                                {value}
                            </Tag>
                        ))}
                    </Values>
                )}
                {!value?.length && (
                    <ValueDisplay className="empty">
                        <FormattedMessage
                            id="empty_none_entered"
                            defaultMessage="None entered" />
                    </ValueDisplay>
                )}
            </Control>
        </Field>
    )
}

export default ViewArray

import styled, { css } from 'styled-components'
import { fromTablet, belowTablet } from 'utilities/styled'
import { compact } from 'utilities/array'
import { Grid } from 'components/grid'
import { Plain } from 'components/button'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import Paragraph from 'components/typography/paragraph'
import { captionSmallCss } from 'components/typography/caption'
import DisplayTimePeriodBase from 'pages/absence/pages/type/configuration/time-period-field/display'

export const balanceGridCss = css`
    ${({ theme: { absenceBalance: { actions } } }) => css`
        grid-template-columns: ${compact(['2fr', '3fr', actions && '40px']).join(' ')};
        column-gap: 24px;

        ${belowTablet`
            grid-template-columns: auto 40px;
            grid-auto-flow: row;
            grid-auto-rows: auto;
            row-gap: 16px;
        `}
    `}
`

export const Entry = styled(Grid)`
    ${balanceGridCss}

    padding: 16px 0;

    ${fromTablet`
        &:not(:last-of-type) {
            box-shadow: 0 1px var(--huma-color-border-default);
        }
    `}

    ${belowTablet`
        &:not(:last-of-type) {
            border-bottom: 1px solid var(--huma-color-border-default);
        }
    `}
`

export const typePeriodCss = css`
    display: grid;
    grid-template-columns: 40px auto;
    column-gap: 16px;
    align-items: center;

    height: auto;
    min-height: 40px;

    text-align: start;
`

export const TypePeriodFragment = styled.div`
    ${typePeriodCss}
`

export const TypePeriodCell = styled(Plain)`
    ${typePeriodCss}

    &:not(:disabled):hover,
    &:not(:disabled):focus {
        text-decoration: none;
    }
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(TypePeriodCell)}
`

export const TypeMeta = styled.div`
    text-wrap: wrap;
`

export const TypeName = styled(Paragraph)`
    ${TypePeriodCell}:not(:disabled):hover &,
    ${TypePeriodCell}:not(:disabled):focus & {
        text-decoration: underline;
    }
`

export const DisplayTimePeriod = styled(DisplayTimePeriodBase)`
    ${captionSmallCss}
`

export const BalanceCell = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${belowTablet`
        grid-row: 2 / 3;
        grid-column: 1 / -1;

        padding-inline-start: 56px;

        &:empty {
            display: none;
        }
    `}
`

export const ActionsCell = styled.div`
    display: flex;
    align-items: center;

    ${belowTablet`
        grid-row: 1 / 2;
        grid-column: 2 / -1;
        align-items: start;
    `}
`
import styled from 'styled-components'

export const LoadingContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 32px;
    height: 40px;
`
import React, { useCallback, useState, useEffect } from 'react'
import { useI18n } from 'contexts/i18n'
import { useDirty } from 'contexts/modal'
import WorkSchedulesProvider from 'contexts/work-schedules'
import {
    eachDayOfInterval, format,
    startOfDay, endOfDay, subDays, addDays, differenceInDays, isSameDay, isToday,
    startOfWeek, endOfWeek,
    startOfMonth, endOfMonth, getDaysInMonth, addMonths, subMonths,
    getMonth, setMonth, getYear, setYear,
    isAfter, isBefore, isWithinInterval, isWeekend
} from 'date-fns'
import { isofy, getDate } from 'utilities/date-time'
import Hero from './hero'
import TimeMachine from './time-machine'
import Calendar from './calendar'
import Shortcuts from './shortcuts'
import Actions from './actions'
import Message from 'components/message'
import { last } from 'utilities/array'
import { capitalize } from 'utilities/string'
import { clamp } from 'utilities/math'
import { xor } from 'utilities/operator'

const TimePickerContent = ({ time, holidays = [], onYearChange, salt, ...props }) => {
    const { dateLocale: locale } = useI18n()

    const [dirty, setDirty] = useDirty()

    const {
        mode = 'date',
        range = false,
        allowOpenEnded = false,
        startDateLocked = false,
        endDateLocked = false,
        resettable = false,
        label,
        message,
        dismiss,
        doneAction,
        cancelAction,
        blockedDateRanges,
        blockedDateRangesMessage,
        disabledTooltip,
        markedDateRanges,
        leapDayBlocked = false,
        futureYearsAvailableInt = 5,
        shortcuts
    } = props

    const setNavigationState = useCallback(time => {
        setNavigationTime(time)
        setNavigationMonth(getMonth(time))
        setNavigationYear(getYear(time))
    }, [])

    const [initialized, setInitialized] = useState(false)
    const [selectedTime, setSelectedTime] = useState(null)
    const [selectedTimeEnd, setSelectedTimeEnd] = useState(null)
    const [pickingTimeEnd, setPickingTimeEnd] = useState(!!startDateLocked && !endDateLocked)
    const [hoverDay, setHoverDay] = useState(null)
    const [navigationTime, setNavigationTime] = useState(new Date)
    const [navigationMonth, setNavigationMonth] = useState(getMonth(navigationTime))
    const [navigationYear, setNavigationYear] = useState(getYear(navigationTime))

    const [weeks, setWeeks] = useState(null)
    const [weekdayNames, setWeekdayNames] = useState(null)
    const [monthNames, setMonthNames] = useState(null)
    const [yearNames, setYearNames] = useState(null)

    const {
        past = true,
        future = true,
        before = null,
        after = null
    } = props ?? {}

    useEffect(() => {
        onYearChange?.(navigationYear)
    }, [navigationYear])

    useEffect(() => {
        if(time) {
            if(range && Array.isArray(time)) {
                const [isoFrom, isoTo] = time.map(isofy)

                isoFrom && setSelectedTime(isoFrom)
                isoTo && setSelectedTimeEnd(isoTo)

                if(isoFrom || isoTo) {
                    setNavigationState(isoFrom ?? isoTo)
                }

                if(isoFrom && !isoTo && !endDateLocked) {
                    setPickingTimeEnd(true)
                }
            } else {
                const isoTime = isofy(time)

                setSelectedTime(isoTime)
                setNavigationState(isoTime)
            }

            if(!initialized) {
                setInitialized(true)
            } else {
                setDirty(true)
            }
        }
    }, [time, initialized])

    const getDayData = useCallback((date, month) => ({
        date,
        month,
        today: isToday(date),
        range: range && isInRange(date),
        selected:
            (!!selectedTime && isSameDay(date, selectedTime)) ||
            (!!selectedTimeEnd && isSameDay(date, selectedTimeEnd)),
        disabled: isDisabled(date),
        mark: getMark(date),
        holiday: isHoliday(date),
        weekend: isWeekend(date)
    }), [selectedTime, selectedTimeEnd, hoverDay, pickingTimeEnd, before, after, range, holidays])

    const isWithinBlockedDateRange = (
        !!blockedDateRanges?.length &&
        range &&
        selectedTime &&
        selectedTimeEnd &&
        blockedDateRanges
            .map(({ start, end }) =>
                eachDayOfInterval({ start, end })
                    .map(date => isWithinInterval(date, {
                        start: selectedTime,
                        end: selectedTimeEnd
                    }))
                    .some(Boolean)
            )
            .some(Boolean)
    )

    const isInRange = date => {
        if(!range) {
            return null
        }

        let isStart = false
        let isEnd = false
        let isBetween = false

        if(!!selectedTime && !!selectedTimeEnd) {
            isStart = isSameDay(date, selectedTime)
            isEnd = isSameDay(date, selectedTimeEnd)
            isBetween = isWithinInterval(date, {
                start: selectedTime,
                end: selectedTimeEnd
            })
        } else if(hoverDay && xor(selectedTime, selectedTimeEnd)) {
            // No hover out of range
            if(
                (!!selectedTime && isBefore(hoverDay, selectedTime)) ||
                (!!selectedTimeEnd && isAfter(hoverDay, selectedTimeEnd))
            ) {
                return null
            }

            const knownEdge = !!selectedTime ? 'start' : 'end'
            const openEdge = !!selectedTime ? 'end' : 'start'
            const edgeTime = selectedTime ?? selectedTimeEnd

            const range = {
                [knownEdge]: edgeTime,
                [openEdge]: hoverDay
            }

            isStart = isSameDay(date, range.start)
            isEnd = isSameDay(date, range.end)
            isBetween = isWithinInterval(date, range)
        }

        if(isStart && isEnd) {
            return null
        }

        if(isBetween && !isStart && !isEnd) {
            return 'between'
        } else if(isStart) {
            return 'start'
        } else if(isEnd) {
            return 'end'
        }

        return null
    }

    const isDisabled = date => {
        const now = new Date()

        if(!date) {
            return false
        }

        if(leapDayBlocked && date.getMonth() === 1 && date.getDate() === 29) {
            return true
        }

        // Disallow future dates: Disable if date is after end of today
        if(!future && isAfter(date, endOfDay(now))) {
            return true
        }

        // Only allow dates between a given date
        if(!!before && !!after) {
            const isoBefore = isofy(before)
            const isoAfter = isofy(after)

            return !isWithinInterval(date, {
                start: startOfDay(isoAfter),
                end: endOfDay(isoBefore)
            })
        }

        // Only allow dates after the given date
        if(!!after) {
            const isoAfter = isofy(after)
            return !isAfter(date, endOfDay(isoAfter))
        }

        // Disallow past dates: Disable if date is before start of today
        if(!past && isBefore(date, startOfDay(now))) {
            return true
        }

        // Only allow dates before the given date
        if(!!before) {
            const isoBefore = isofy(before)
            return !isBefore(date, startOfDay(isoBefore))
        }

        if(!!blockedDateRanges?.length) {
            return blockedDateRanges
                .map(({ on, start, end }) => {
                    if(on) {
                        return isSameDay(date, isofy(on))
                    }

                    if(!start || !end) {
                        return false
                    }

                    return isWithinInterval(date, {
                        start: startOfDay(start),
                        end: endOfDay(end)
                    })
                })
                .some(Boolean)
        }

        return false
    }

    const getMark = date => {
        if(!markedDateRanges?.length) {
            return null
        }

        return markedDateRanges
            .map(({ range }) => {
                range = createRangeWithOpenEnd(range)

                if(!range) {
                    return null
                }

                let { start, end } = range
                start = isofy(start)
                end = isofy(end)

                if(!start || !end) {
                    return null
                }

                const inRange = isWithinInterval(date, {
                    start: startOfDay(start),
                    end: endOfDay(end)
                })

                return inRange
            })
            .filter(Boolean)[0]
    }

    const isHoliday = date => {
        if(!holidays?.length) {
            return false
        }

        return holidays.includes(getDate(date))
    }

    // Generate the month view
    useEffect(() => {
        const monthStartDay = startOfMonth(navigationTime)
        const monthEndDay = startOfDay(endOfMonth(navigationTime))
        const monthDayCount = getDaysInMonth(navigationTime)

        const previousMonthPadDaysCount = differenceInDays(
            monthStartDay,
            startOfWeek(monthStartDay, { locale })
        )

        const nextMonthPadDaysCount = differenceInDays(
            endOfWeek(monthEndDay, { locale }),
            monthEndDay
        )

        const previousMonthDays = new Array(previousMonthPadDaysCount)
            .fill()
            .map((d, index) => getDayData(
                subDays(monthStartDay, index + 1),
                'previous'
            ))
            .reverse()

        const currentMonthDays = new Array(monthDayCount)
            .fill()
            .map((d, index) => getDayData(
                addDays(monthStartDay, index),
                'current'
            ))

        const nextMonthDays = new Array(nextMonthPadDaysCount)
            .fill()
            .map((d, index) => getDayData(
                addDays(monthEndDay, index + 1),
                'next'
            ))

        const month = [
            ...previousMonthDays,
            ...currentMonthDays,
            ...nextMonthDays
        ]

        const weeks = month.reduce((accumulator, day) => {
            let currentWeek = accumulator.pop()
            currentWeek = [...currentWeek, day]
            accumulator.push(currentWeek)

            if(currentWeek.length === 7 && day !== last(month)) {
                accumulator.push([])
            }

            return accumulator
        }, [[]])

        setWeeks(weeks)
    }, [selectedTime, selectedTimeEnd, hoverDay, navigationTime, pickingTimeEnd, before, after, locale])

    // Generate localized names of things
    useEffect(() => {
        // Weekday names
        const now = new Date()
        const weekdays = eachDayOfInterval({
            start: startOfWeek(now, { locale }),
            end: endOfWeek(now, { locale })
        })
        const weekdayNames = weekdays.map(weekday => format(weekday, 'EEEEEE', { locale }))

        // Month names
        const monthNames = Array(12)
            .fill(null)
            .map((_, index) => locale?.localize?.month?.(index) ?? `Month ${(index + 1)}`)
            .map(capitalize)

        // Year names
        const nowYearInt = now.getFullYear()
        const futureYearsToAddInt = clamp(futureYearsAvailableInt, 0, 100)
        const endYearInt = !future ? nowYearInt : nowYearInt + futureYearsToAddInt
        const numberOfYearsToShowInt = !future ? 100 : 100 + futureYearsToAddInt
        const yearNames = Array(numberOfYearsToShowInt)
            .fill(null)
            .map((_, index) => endYearInt - index)
            .reverse()

        setWeekdayNames(weekdayNames)
        setMonthNames(monthNames)
        setYearNames(yearNames)
    }, [locale])

    const decreaseMonth = useCallback(() => {
        const decreasedNavigationTime = subMonths(navigationTime, 1)
        setNavigationState(decreasedNavigationTime)
    }, [navigationTime])

    const navigateToToday = useCallback(() => setNavigationState(new Date), [])

    const increaseMonth = useCallback(() => {
        const increasedNavigationTime = addMonths(navigationTime, 1)
        setNavigationState(increasedNavigationTime)
    }, [navigationTime])

    const navigateInTime = useCallback(({ month, year }) => {
        if(!month || !year) {
            return
        }

        const monthUnchanged = parseInt(month, 10) === navigationMonth
        const yearUnchanged = parseInt(year, 10) === navigationYear
        if(monthUnchanged && yearUnchanged) {
            return
        }

        const modifiedNavigationTime = setYear(setMonth(navigationTime, month), year)
        setNavigationState(modifiedNavigationTime)
    }, [navigationTime, navigationMonth, navigationYear])

    const onDayClick = useCallback((date, month) => {
        if(range) {
            if(selectedTime && selectedTimeEnd && !isWithinInterval(date, {
                start: selectedTime,
                end: selectedTimeEnd
            })) {
                if(!endDateLocked) {
                    setPickingTimeEnd(true)
                }

                if(isBefore(date, selectedTime)) {
                    setSelectedTime(date)
                } else if(isAfter(date, selectedTimeEnd)) {
                    setSelectedTimeEnd(date)
                }
            } else {
                if(pickingTimeEnd) {
                    if(selectedTime && isBefore(date, selectedTime)) {
                        setSelectedTime(date)
                    } else {
                        setSelectedTimeEnd(date)
                    }

                    if(!selectedTime && !startDateLocked) {
                        setPickingTimeEnd(false)
                    }
                } else  {
                    if(selectedTimeEnd && isAfter(date, selectedTimeEnd)) {
                        setSelectedTimeEnd(date)
                    } else {
                        setSelectedTime(date)

                        if(!selectedTimeEnd && !allowOpenEnded) {
                            setSelectedTimeEnd(date)
                        }
                    }

                    if(!endDateLocked) {
                        setPickingTimeEnd(true)
                    }
                }
            }
        } else {
            setSelectedTime(date)
        }

        setDirty(true)

        if(month !== 'current') {
            setNavigationState(date)
        }
    }, [pickingTimeEnd, selectedTime, selectedTimeEnd, allowOpenEnded, endDateLocked, startDateLocked, range])

    const getValue = () => {
        if(range) {
            return [
                selectedTime?.toISOString?.(),
                selectedTimeEnd?.toISOString?.()
            ]
        }

        return selectedTime?.toISOString?.()
    }

    const reset = () => {
        setSelectedTime(null)
        setSelectedTimeEnd(null)
        setPickingTimeEnd(!!startDateLocked && !endDateLocked)
        setDirty(true)
    }

    const cancel = cancelAction()
    const done = doneAction({ picked: getValue() })

    if(!weeks?.length) {
        return null
    }

    return (
        <>
            <Hero
                label={label}
                message={message}
                mode={mode}
                selectedTime={selectedTime}
                selectedTimeEnd={selectedTimeEnd}
                setSelectedTimeEnd={setSelectedTimeEnd}
                range={range}
                pickingTimeEnd={pickingTimeEnd}
                setPickingTimeEnd={setPickingTimeEnd}
                allowOpenEnded={allowOpenEnded}
                startDateLocked={startDateLocked}
                endDateLocked={endDateLocked}
                setNavigationState={setNavigationState} />
            <TimeMachine
                navigateInTime={navigateInTime}
                navigationMonth={navigationMonth}
                monthNames={monthNames}
                navigationYear={navigationYear}
                yearNames={yearNames}
                decreaseMonth={decreaseMonth}
                navigateToToday={navigateToToday}
                increaseMonth={increaseMonth}
                salt={salt} />
            <Calendar
                weekdayNames={weekdayNames}
                weeks={weeks}
                onDayClick={onDayClick}
                setHoverDay={setHoverDay}
                disabledTooltip={disabledTooltip}
                locale={locale}
                salt={salt}
                onMouseLeave={() => setHoverDay(null)} />
            {!!range && (
                <Shortcuts
                    shortcuts={shortcuts}
                    selectedTime={selectedTime}
                    selectedTimeEnd={selectedTimeEnd}
                    setSelectedTime={setSelectedTime}
                    setSelectedTimeEnd={setSelectedTimeEnd}
                    setNavigationState={setNavigationState}
                    setPickingTimeEnd={setPickingTimeEnd}
                    setDirty={setDirty}
                    salt={salt} />
            )}
            {isWithinBlockedDateRange && blockedDateRangesMessage && (
                <Message
                    className="compact"
                    type="error"
                    message={blockedDateRangesMessage} />
            )}
            <Actions
                cancel={cancel}
                done={done}
                resettable={resettable}
                reset={reset}
                dismiss={dismiss}
                dirty={dirty}
                range={range}
                allowOpenEnded={allowOpenEnded}
                isWithinBlockedDateRange={isWithinBlockedDateRange}
                selectedTime={selectedTime}
                selectedTimeEnd={selectedTimeEnd} />
        </>
    )
}

const createRangeWithOpenEnd = ({ start, end }) => {
    start = isofy(start)
    end = isofy(end)
    const now = new Date()

    if(!start && !!end && (now < end)) {
        return {
            start: now,
            end
        }
    }

    if(!end && !!start && (now > start)) {
        return {
            start,
            end: now
        }
    }

    if(!!start && !!end) {
        return {
            start,
            end
        }
    }

    return null
}

export default props => {
    if(!!props.holidaysCountryCode) {
        return (
            <WorkSchedulesProvider fetchOnMount={false}>
                {({ holidays = {}, fetchHolidays }) => {
                    holidays = Object.values(holidays?.[props.holidaysCountryCode] ?? {}).flat()

                    return (
                        <TimePickerContent
                            {...props}
                            holidays={holidays}
                            onYearChange={year => {
                                const years = [year - 1, year, year + 1]
                                fetchHolidays([props.holidaysCountryCode], years)
                            }} />
                    )
                }}
            </WorkSchedulesProvider>
        )
    }

    return <TimePickerContent {...props} />
}
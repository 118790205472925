import React, { useState } from 'react'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { clamp } from 'utilities/math'
import Category from './category'

const ServiceOnboardingCategories = ({ mode = 'sequential', onBeforeAction, salt, ...props }) => {
    const {
        onboarder,
        categories
    } = useServiceOnboarding()

    const [activeCategoryIndex, setActiveCategoryIndex] = useState(categories.findIndex(({ todos }) => !!todos.length) ?? 0)

    if(!onboarder) {
        return null
    }

    const categoriesToDisplay = categories.filter(category => {
        if(mode === 'sequential') {
            return !!category.todos.length
        }

        return true
    })

    return categoriesToDisplay.map((category, index) => {
        const itemSalt = `${salt}:service-onboarding:${mode}:category:${category.id}`

        return (
            <Category
                {...props}
                category={category}
                id={category.id}
                mode={mode}
                index={index}
                activeIndex={clamp(activeCategoryIndex, 0, categoriesToDisplay.length - 1)}
                last={index === categories.length - 1}
                onExpand={() => setActiveCategoryIndex(index)}
                onBeforeAction={onBeforeAction}
                salt={itemSalt}
                key={itemSalt} />
        )
    })
}

export default ServiceOnboardingCategories
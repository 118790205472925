import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { getSalaryRevisionUrl } from 'utilities/url'
import { getFullName } from 'utilities/person'
import { safeFormat } from 'utilities/date-time'
import { Row, Cell, Label, Name } from './s'
import Status from 'pages/salary/components/revision-status'
import { ArrowRight } from 'styled-icons/feather'
import Paragraph from 'components/typography/paragraph'

const Revision = forwardRef(({ revision, ...props }, ref) => {
    const { dateLocale: locale } = useI18n()

    const {
        name,
        status,
        createdBy,
        createdAt,
        completedBy,
        completedAt,
        effectiveDate
    } = revision

    return (
        <Row
            {...props}
            to={getSalaryRevisionUrl(revision)}
            ref={ref}>
            <Cell $cell="meta">
                <Name className="compact">{name}</Name>
                <Paragraph className="caption small compact">
                    <FormattedMessage
                        id={(completedBy && completedAt) ?
                            'meta_completed_when_by'
                            : 'meta_created_when_by'
                        }
                        defaultMessage={(completedBy && completedAt) ?
                            'Completed {date} by {user}'
                            : 'Created {date} by {user}'
                        }
                        values={{
                            date: safeFormat(completedAt ?? createdAt, 'PP', { locale }),
                            user: getFullName(completedBy ?? createdBy)
                        }} />
                </Paragraph>
            </Cell>
            <Cell $cell="effective">
                <Label>
                    <FormattedMessage
                        id="noun_effective_date"
                        defaultMessage="Effective date" />
                </Label>
                <span>{safeFormat(effectiveDate, 'PPP', { locale })}</span>
            </Cell>
            <Cell $cell="status">
                <Status status={status} />
            </Cell>
            <Cell
                $cell="action"
                className="action">
                <ArrowRight size={24} />
            </Cell>
        </Row>
    )
})

export default Revision
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Field as FieldBase, Control as ControlBase } from 'components/form/field/s'
import { Rows as RowsBase, Columns as ColumnsBase } from 'components/flex'
import { Grid } from 'components/grid'
import TextBase from 'components/form/input/text'
import SelectBase from 'components/form/input/select'
import { InlineMessage } from 'components/message'

export const Field = styled(FieldBase)`
    margin-bottom: 0;
    flex-direction: column;
`

export const Control = styled(ControlBase)`
    display: flex;
    column-gap: 8px;
`

export const Rows = styled(RowsBase)`
    max-width: 100%;
    row-gap: 8px;
`

export const Columns = styled(ColumnsBase)`
    column-gap: 8px;
`

export const DateAndTime = styled(Grid)`
    grid-template-columns: minmax(0, 1fr) 112px;
    grid-column-gap: 8px;
`

export const Time = styled(TextBase)`
    width: 112px;
`

export const Select = styled(SelectBase)`
    min-width: 160px;
    max-width: 100%;
`

export const MessageRevealer = styled(motion.div).attrs(() => ({
    variants: {
        hide: {
            height: 0,
            opacity: 0,
            transition: {
                duration: .2
            }
        },
        reveal: {
            height: 'auto',
            opacity: 1,
            transition: {
                duration: .2
            }
        }
    },
    initial: 'hide'
}))`
    position: relative;
    overflow: hidden;
`

export const Message = styled(InlineMessage)`
    margin: 8px 0 0;
`
import React, { useState, Children, cloneElement, isValidElement } from 'react'
import { useAccess } from 'contexts/access'
import { useUpgradable } from 'hooks/upgradable'
import { sendAppSignal } from 'hooks/app-signal'
import { refreshPayment } from 'utilities/payment'
import { Scrollable as Modal } from 'modals/generic'
import Manage from 'pages/settings/pages/subscription/main/manage'

const SubscriptionManager = ({ children, module, feature, moduleless = false, callback, salt }) => {
    const [managing, setManaging] = useState(null)

    const meta = useUpgradeMeta({ module, feature })

    return (
        <>
            {Children.map(children, child => {
                if(isValidElement(child)) {
                    return cloneElement(child, {
                        manager: {
                            meta,
                            setManaging
                        }
                    })
                }

                return child
            })}
            {(meta.access && ((!meta.includedInSubscription && !!meta.plansWithFeature?.length) || moduleless)) && (
                <Modal
                    show={!!managing}
                    dismiss={() => setManaging(null)}
                    salt={`${salt}:subscription:manage`}>
                    <Manage
                        meta={managing}
                        onDone={() => {
                            refreshPayment()
                            setManaging(null)
                            sendAppSignal('value.show', null)
                            callback?.()
                        }}
                        salt={salt} />
                </Modal>
            )}
        </>
    )
}

export const useUpgradeMeta = ({ module, feature }) => {
    const {
        check,
        getBundlesWithModule,
        getBundlesWithFeature
    } = useAccess()

    const checkUpgradable = useUpgradable()

    return {
        access: check('organization:manage'),
        includedInSubscription: (module || feature) ?
            !checkUpgradable({ module, feature }) :
            false,
        plansWithFeature: module ?
            getBundlesWithModule(module) :
            feature ?
                getBundlesWithFeature(feature) :
                []
    }
}

export default SubscriptionManager
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { BubbleMenu } from '@tiptap/react'
import { Wrap, Icon, Button } from './s'
import { Link } from 'styled-icons/feather'

// eslint-disable-next-line no-unused-vars
const LinkBubble = ({ editor, marking, setMarking, active, every, some, salt, ...props }) => (
    <BubbleMenu
        editor={editor}
        tippyOptions={{ duration: 100 }}>
        {(!!editor && every('Link') && active.link) && (
            <Wrap {...props}>
                <Icon>
                    <Link size={16} />
                </Icon>
                <Button
                    className="destructive"
                    onClick={() => editor
                        .chain()
                        .focus()
                        .unsetLink()
                        .run()
                    }>
                    <FormattedMessage
                        id="action_remove"
                        defaultMessage="Remove" />
                </Button>
                <Button onClick={() => setMarking({
                    type: 'link',
                    link: editor.getAttributes('link')
                })}>
                    <FormattedMessage
                        id="action_edit"
                        defaultMessage="Edit" />
                </Button>
            </Wrap>
        )}
    </BubbleMenu>
)

export default LinkBubble
import React, { forwardRef } from 'react'
import {
    format, getWeek,
    isLastDayOfMonth, isFirstDayOfMonth
} from 'date-fns'
import { Wrap, Row, Cell, WeekdayName, WeekNumber, Range, Day, Circle, Mark } from './s'
import { first } from 'utilities/array'
import { cls } from 'utilities/dom'
import Tooltip, { useSingleton } from 'components/tooltip'

const Calendar = ({
    weekdayNames, weeks,
    onDayClick, setHoverDay,
    disabledTooltip,
    locale, salt, ...props
}) => {
    const [source, target] = useSingleton()

    return (
        <Wrap {...props}>
            <Tooltip
                singleton={source}
                delay={[0, 250]} />
            <Row>
                <Cell />
                {weekdayNames.map(weekdayName => (
                    <Cell key={`${salt}:weekday:name:label:${weekdayName}`}>
                        <WeekdayName>
                            {weekdayName}
                        </WeekdayName>
                    </Cell>
                ))}
            </Row>
            {weeks.map((week, i) => (
                <Row key={`${salt}:week:${i}`}>
                    <Cell>
                        <WeekNumber>
                            {getWeek(first(week).date, { locale })}
                        </WeekNumber>
                    </Cell>
                    {week.map(({ date, month, selected, range, mark, holiday, weekend = false, disabled, today }, j) => {
                        const className = cls([
                            selected && 'selected',
                            range && `range-${range}`,
                            today && 'today',
                            holiday && 'holiday',
                            weekend && 'weekend'
                        ])

                        const rangeClassName = (range && range !== 'circle') ? cls([
                            (month === 'current') && range,
                            (isLastDayOfMonth(date) && ((month === 'previous' && !selected) || range === 'start')) && 'month-end',
                            (isFirstDayOfMonth(date) && ((month === 'next' && !selected) || range === 'end')) && 'month-start',
                            disabled && 'disabled'
                        ]) : null

                        const dayProps = {
                            ...(className ? { className } : null),
                            onClick: () => onDayClick(date, month),
                            onMouseEnter: () => setHoverDay(date),
                            disabled,
                            tabIndex: 0,
                            onDayClick,
                            date,
                            month,
                            setHoverDay
                        }

                        return (
                            <Cell key={`${salt}:week:${i}:day:${j}`}>
                                {!!rangeClassName && <Range className={rangeClassName} />}
                                {(month === 'current') && (
                                    <>
                                        {(!disabledTooltip || (!disabled && disabledTooltip)) && <DayNode {...dayProps} />}
                                        {(!!disabled && disabledTooltip) && (
                                            <Tooltip
                                                content={disabledTooltip}
                                                singleton={target}>
                                                <div tabIndex={0}>
                                                    <DayNode {...dayProps} />
                                                </div>
                                            </Tooltip>
                                        )}
                                        {(range === 'circle') && <Circle />}
                                        {mark && <Mark />}
                                    </>
                                )}
                            </Cell>
                        )
                    })}
                </Row>
            ))}
        </Wrap>
    )
}

const DayNode = forwardRef(({ className, onDayClick, date, month, setHoverDay, disabled, ...props }, ref) => (
    <Day
        {...props}
        {...(className ? { className } : null)}
        onClick={() => onDayClick(date, month)}
        onMouseEnter={() => setHoverDay(date)}
        disabled={disabled}
        tabIndex={0}
        ref={ref}>
        {format(date, 'd')}
    </Day>
))

export default Calendar

import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { aspectRatioFallback as a } from '../s'

export const EmbedWrapper = styled.div`
    position: relative;
    inset: auto;

    margin: 0 auto;
    width: calc(100% - 10em);
    aspect-ratio: var(--aspect-ratio, ${a.width} / ${a.height});

    transform: translate(0, 0);

    @media (min-aspect-ratio: ${`${a.width} / ${a.height}`}) {
        height: calc(100vh - 10em);
    }

    ${belowPhone`
        width: calc(100% - 48px);
    `}
`

export const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;

    border: 0;
    width: 100%;
    height: 100%;
`
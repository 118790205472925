import { useIntl } from 'react-intl'
import { capitalize } from 'utilities/string'

export const Name = ({ name, deletable }) => {
    const { formatMessage } = useIntl()

    if(!name) {
        return ''
    }

    if(deletable) {
        return name
    }

    const translation = getTranslation(name)
    if(!translation) {
        return capitalize(name)
    }

    return formatMessage(translation)
}

const getTranslation = name => ({
    // Special roles
    colleague: {
        id: 'role_colleague_name',
        defaultMessage: '…others'
    },
    me: {
        id: 'role_me_name',
        defaultMessage: '…themselves'
    },

    // User roles
    manager: {
        id: 'role_manager_name',
        defaultMessage: 'Manager'
    },
    supervisor: {
        id: 'role_supervisor_name',
        defaultMessage: 'Supervisor'
    },
    payroll: {
        id: 'role_payroll_name',
        defaultMessage: 'Payroll manager'
    },

    // System roles
    'admin': {
        id: 'role_admin_name',
        defaultMessage: 'System admin'
    },
    'user-admin': {
        id: 'role_user-admin_name',
        defaultMessage: 'User admin'
    },
    'news-editor': {
        id: 'role_news-editor_name',
        defaultMessage: 'News editor'
    },
    'whistleblowing-handler': {
        id: 'role_whistleblowing-handler_name',
        defaultMessage: 'Whistleblowing handler'
    },
    'salary-admin': {
        id: 'role_salary-admin_name',
        defaultMessage: 'Salary admin'
    }
})[name]
import React, { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { v4 as uuid } from 'uuid'
import { useI18n } from 'contexts/i18n'
import { useOrganization } from 'contexts/organization'
import { useContentAssistant } from 'contexts/content-assistant'
import { Wrapper } from './s'
import Header from './header'
import Content from './content'
import Footer from './footer'

const languageNames = new Intl.DisplayNames(['en'], { type: 'language' })
const languageFallbackName = 'English'

const AssistantPanel = ({ callback, dismiss, autoGenerate = null, upgradable, salt }) => {
    const { formatMessage } = useIntl()

    const { locale } = useI18n()

    const contentRef = useRef(null)
    const scrollTimeoutRef = useRef(null)

    const { organization } = useOrganization()

    const {
        settings,
        messages,

        promptAssistant,
        appendPrompt,
        resetDialogue,
        appendMessage
    } = useContentAssistant()

    const [currentLocale, setCurrentLocale] = useState(locale)

    const scrollToBottom = () => {
        const content = contentRef.current

        if(!content) {
            return
        }

        content.scrollTo({
            top: content.scrollHeight,
            behavior: 'smooth'
        })
    }

    const cleanPrompt = ({ prompt, key = null, value = null }) => {
        const language = languageNames.of(currentLocale) ?? languageFallbackName

        prompt = prompt.replace(/<language>/g, language)
        prompt = prompt.replace(/<orgName>/g, organization?.name)

        if(key === 'assistant_action_translate') {
            value = languageNames.of(value) ?? languageFallbackName
        }

        prompt = prompt.replace(/<value>/g, value)

        // If there are any remaining <...> tags, replace them with values from autoGenerate
        if(prompt.match(/<.*?>/)) {
            prompt = prompt.replace(/<.*?>/g, match => {
                const key = match.replace(/<|>/g, '')

                return autoGenerate.values[key]
            })
        }

        return prompt
    }

    const addPrompt = async body => {
        scrollTimeoutRef.current = setTimeout(scrollToBottom, 50)

        const { ok } = await promptAssistant(body)

        if(ok) {
            scrollTimeoutRef.current = setTimeout(scrollToBottom, 500)
        }
    }

    useEffect(() => {
        if(settings?.initialPrompts?.[autoGenerate.field] && !messages?.length) {
            if(!autoGenerate.value && !!autoGenerate.field) {
                const prompt = cleanPrompt({
                    prompt: settings.initialPrompts[autoGenerate.field].prompt,
                    language: autoGenerate.language
                })

                addPrompt({
                    prompt,
                    displayPrompt: formatMessage({
                        id: settings.initialPrompts[autoGenerate.field].key
                    }, {
                        jobTitle: autoGenerate.values.jobTitle
                    })
                })
            } else if(!!autoGenerate.value && !!autoGenerate.field) {
                const userId = uuid()
                const assistantId = uuid()

                appendPrompt({
                    user: {
                        id: userId,
                        content: cleanPrompt({
                            prompt: settings.initialPrompts[autoGenerate.field].prompt,
                            language: autoGenerate.language
                        })
                    },
                    assistant: {
                        id: assistantId,
                        content: autoGenerate.value
                    }
                })

                appendMessage({
                    id: userId,
                    output: autoGenerate.value,
                    prompt: null,
                    locked: true
                })
            }
        }

        return () => {
            const scrollTimeout = scrollTimeoutRef.current

            setCurrentLocale(locale)
            resetDialogue()

            if(scrollTimeout) {
                clearTimeout(scrollTimeout)
            }
        }
    }, [])

    return (
        <Wrapper>
            <Header dismiss={dismiss} />
            <Content
                callback={callback}
                setCurrentLocale={setCurrentLocale}
                hasValue={!!autoGenerate.value}
                upgradable={upgradable}
                scroll={{
                    action: scrollToBottom,
                    timeoutRef: scrollTimeoutRef
                }}
                salt={salt}
                ref={contentRef} />
            <Footer
                addPrompt={addPrompt}
                cleanPrompt={cleanPrompt}
                currentLocale={currentLocale}
                setCurrentLocale={setCurrentLocale}
                upgradable={upgradable}
                salt={salt} />
        </Wrapper>
    )
}

export default AssistantPanel
import React, { forwardRef, Fragment } from 'react'
import { useIntl } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import {
    UnitsWrap, UnitWrap,
    IrremovableUnit, RemovableUnit,
    TypeSymbolWrap, XCircle
} from './s'
import {
    Users as Organization,
    MapPin as Location,
    X as Remove
} from 'styled-icons/feather'
import { Team, Company } from 'components/feather'
import { AvatarWrapper, Deactivated } from 'components/person/s'
import Avatar from 'components/avatar'
import Tooltip from 'components/tooltip'
import { getFullName } from 'utilities/person'
import { cls } from 'utilities/dom'
import { optionize } from 'components/form/field/share'

export const Units = forwardRef((props, ref) => {
    const { formatList } = useIntl()

    const { organization } = useOrganization()
    const publicOption = organization ?
        optionize('organization')(organization) :
        null

    const normalize = normalizer(publicOption)

    const {
        max = Infinity,
        inline = false,
        clickables = [],
        onClick,
        salt,
        publicUnit = false,
        publicWhenEmpty = false,
        showTooltip = true,
        mode = 'view',
        ...rest
    } = props

    let {
        units = [],
        ...attributes
    } = rest

    if(!units.length) {
        if((publicWhenEmpty || publicUnit) && publicOption) {
            units = [publicOption]
        } else {
            return null
        }
    }

    units = units.map(normalize)

    const cappedUnits = units.slice(0, max)
    const overflow = units.length - cappedUnits.length
    const overflowTitle = formatList(units.slice(max).map(({ type, [type]: { name } }) => name), { type: 'unit' })

    const className = cls([
        inline ? 'text' : 'blob',
        attributes?.className
    ])

    return (
        <UnitsWrap
            {...attributes}
            {...(className ? { className } : null)}
            ref={ref}>
            {cappedUnits.map((unit, index) => {
                const last = index === cappedUnits.length - 1

                const [UnitWrapper, unitWrapperProps = null] = (!!showTooltip && last && overflow) ?
                    [Tooltip, { content: overflowTitle }]
                    : [Fragment]

                return (
                    <UnitWrapper
                        {...unitWrapperProps}
                        key={`${salt}:unit:${index}`}>
                        <Unit
                            unit={unit}
                            mode={mode}
                            inline={inline}
                            onClick={clickables.find(clickable => {
                                clickable = normalize(clickable)
                                return clickable[clickable.type].id === unit[unit.type].id
                            }) ? onClick : null}
                            {...((last && overflow) ? {
                                className: 'overflow',
                                'data-overflow': overflow
                            } : null)} />
                    </UnitWrapper>
                )
            })}
        </UnitsWrap>
    )
})

export const Unit = forwardRef(({ unit, mode, inline, onClick, className, ...props }, ref) => {
    if(typeof onClick === 'function') {
        return (
            <UnitWrap
                {...props}
                className={cls([
                    className,
                    inline ? 'text' : 'blob'
                ])}
                ref={ref}>
                <RemovableUnit onClick={() => onClick(unit)}>
                    <TypeSymbolWrap>
                        <TypeSymbol {...unit} />
                    </TypeSymbolWrap>
                    <Name {...unit} />
                    <XCircle>
                        <Remove size={12} />
                    </XCircle>
                </RemovableUnit>
            </UnitWrap>
        )
    }

    return (
        <UnitWrap
            {...props}
            className={cls([
                className,
                inline ? 'text' : 'blob'
            ])}
            ref={ref}>
            <IrremovableUnit>
                <TypeSymbolWrap>
                    <TypeSymbol {...unit} />
                </TypeSymbolWrap>
                <Name {...unit} mode={mode} />
            </IrremovableUnit>
        </UnitWrap>
    )
})

const Name = ({ type, ...unit }) => {
    const { formatMessage } = useIntl()

    if(!type) {
        return null
    }

    const { [type]: entity } = unit

    return {
        user: () => getFullName(entity),
        organization: () => formatMessage({
            id: 'noun_everyone',
            defaultMessage: 'Everyone'
        }),
        company: () => entity.name,
        team: () => entity.name,
        location: () => entity.name
    }[type]() ?? null
}

const TypeSymbol = ({ type, ...unit }) => {
    const { formatMessage } = useIntl()

    if(!type) {
        return null
    }

    const { [type]: entity } = unit

    return {
        user: () => (
            <AvatarWrapper>
                {!entity.status?.active && (
                    <Tooltip
                        content={formatMessage({
                            id: 'person_status_deactivated',
                            defaultMessage: 'This account has been deactivated'
                        })}>
                        <Deactivated size={16} />
                    </Tooltip>
                )}
                <Avatar
                    who={entity}
                    size={16}
                    inline={true} />
            </AvatarWrapper>
        ),
        organization: () => <Organization size={16} />,
        company: () => <Company size={16} />,
        team: () => <Team size={16} />,
        location: () => <Location size={16} />
    }[type]()
}

const normalizer = publicOption => unit => {
    if(unit?.type in unit) {
        return unit
    }

    if(unit?.type === 'organization' && publicOption) {
        return publicOption
    }

    return optionize(unit.type)(unit)
}
import React from 'react'
import { size } from 'utilities/object'
import FilePreview from './preview'
import FileDownload from './download'
import FileDelete from './delete'
import { NoLink } from './s'
import FileDisplay from './display'

const File = ({ meta, preview, download, remove, actions, displayProps, ...props }) => {
    if(preview) {
        return (
            <FilePreview
                {...props}
                preview={preview}
                download={download}
                meta={meta}
                displayProps={displayProps} />
        )
    }

    if(download) {
        return (
            <FileDownload
                {...props}
                {...download}
                meta={meta}
                displayProps={displayProps} />
        )
    }

    if(remove) {
        return (
            <FileDelete
                {...props}
                {...remove}
                meta={meta}
                displayProps={displayProps} />
        )
    }

    if(!!size(actions)) {
        return (
            <NoLink {...props}>
                <FileDisplay
                    name={meta?.fileName}
                    meta={meta}
                    actions={actions}
                    displayProps={displayProps} />
            </NoLink>
        )
    }

    return (
        <NoLink {...props}>
            <FileDisplay
                name={meta?.fileName}
                displayProps={displayProps} />
        </NoLink>
    )
}

export default File
import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react'
import { format } from './utilities'
import { Container } from 'widgets/context-menu/s'
import { List, Item, Button, KeyHintInner, Person, Group } from './s'
import { AnimatePresence } from 'framer-motion'
import { CornerDownLeft as Enter } from 'styled-icons/feather'

const Suggestions = forwardRef(({ items, command }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const prev = () => setSelectedIndex(selectedIndex => (selectedIndex + items.length - 1) % items.length)
    const next = () => setSelectedIndex(selectedIndex => (selectedIndex + 1) % items.length)
    const pick = item => () => command(format(item))

    useEffect(() => setSelectedIndex(0), [items])

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            const navigateOrPick = ['ArrowUp', 'ArrowDown', 'Enter'].includes(event.key)

            if(navigateOrPick) {
                event.stopPropagation()
                event.preventDefault()
            }

            if(event.key === 'ArrowUp') {
                prev()
            }

            if(event.key === 'ArrowDown') {
                next()
            }

            if(event.key === 'Enter') {
                pick(items[selectedIndex])()
            }

            return navigateOrPick
        }
    }), [selectedIndex])

    return (
        <Container
            className="boxy"
            ref={ref}>
            <List>
                {items.map((item, index) => {
                    const active = index === selectedIndex

                    return (
                        <Item key={`mention:suggestion:${item.id}`}>
                            <Button
                                onClick={pick(item)}
                                className={`neutral${active ? ' active' : ''}`}
                                tabIndex={-1}>
                                {['team', 'location'].includes(item.type) && (
                                    <Group
                                        group={item}
                                        type={item.type}
                                        size={16} />
                                )}
                                {(item.type === 'user') && (
                                    <Person
                                        who={item}
                                        showPosition={true}
                                        size={24}
                                        truncate={active}
                                        className="large" />
                                )}
                                {!!active && (
                                    <AnimatePresence>
                                        <KeyHintInner animate={active ? 'active' : 'inactive'}>
                                            <Enter size={16} />
                                        </KeyHintInner>
                                    </AnimatePresence>
                                )}
                            </Button>
                        </Item>
                    )
                })}
            </List>
        </Container>
    )
})

export default Suggestions
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useSmartEntity } from 'contexts/smart-entity'
import { sendAppSignal } from 'hooks/app-signal'
import {
    Wrapper,
    Header,
    Content, ContentRow, AddButton,
    Footer
} from './s'
import Paragraph from 'components/typography/paragraph'
import Source from './source'
import { InlineMessage } from 'components/message'
import { Inline } from 'components/code'
import { Plus } from 'styled-icons/feather'

const SmartContent = ({ close, salt }) => {
    const { formatMessage } = useIntl()

    const {
        getCustomSources,
        getBuiltInSources
    } = useSmartEntity()

    const sources = [
        ...getCustomSources(),
        ...getBuiltInSources()
    ]

    return (
        <Wrapper>
            <Header>
                <Paragraph className="small caption compact">
                    <FormattedMessage
                        id="documents_smart_action_insert_fields"
                        defaultMessage="Insert smart fields" />
                </Paragraph>
            </Header>
            <Content>
                <ContentRow>
                    <AddButton
                        icon={<Plus size={24} />}
                        className="constructive compact"
                        onClick={() => {
                            close()
                            sendAppSignal('smart-editor.data-source.add')
                        }}>
                        <FormattedMessage
                            id="documents_smart_data_sources_action_add"
                            defaultMessage="Add data source" />
                    </AddButton>
                </ContentRow>
                {sources.map(source => {
                    const key = `${salt}:source:${source.type}:${source.name}`

                    return (
                        <ContentRow key={key}>
                            <Source
                                source={source}
                                mode="toolbar"
                                onInsert={close}
                                salt={key} />
                        </ContentRow>
                    )
                })}
            </Content>
            <Footer>
                <InlineMessage
                    type="info"
                    className="compact"
                    message={formatMessage({
                        id: 'editor_block_variable_instructions',
                        defaultMessage: 'Type a <code>$<\/code> followed by the name of a data source’s type or name, or a field’s name, to find and insert a value or a placeholder'
                    }, { code: chunks => <Inline>{chunks}</Inline> })} />
            </Footer>
        </Wrapper>
    )
}

export default SmartContent
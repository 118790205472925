import styled from 'styled-components'
import Paragraph from 'components/typography/paragraph'
import { bp, belowTablet } from 'utilities/styled'
import { Container as ArticleContainer } from './card/s'

export const Cards = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0 16px;
    max-width: 100%;

    ${bp.between('tablet', 1520)`
        grid-template-columns: repeat(3, 1fr);

        > ${ArticleContainer}:nth-of-type(4) {
            display: none;
        }
    `}

    ${belowTablet`
        grid-template-columns: repeat(${({ theme }) => theme.articles}, 240px);
        width: 100vw;
        max-width: 100vw;
        position: relative;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-padding-inline-start: 24px;
        -webkit-overflow-scrolling: touch;
        margin-left: -24px;
        padding: 0 24px;

        &:after {
            content: "";
            width: 8px;
        }
    `}
`

export const LoadingContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
`

export const EmptyText = styled(Paragraph)`
    margin: 0 0 32px;
`

import React from 'react'
import { useIntl } from 'react-intl'
import { useGetMeta } from './meta'
import { InlineMessage } from 'components/message'

const TooltipContentIncomplete = ({ source, fieldHeading }) => {
    const { formatMessage } = useIntl()
    const getMeta = useGetMeta()

    const { name } = getMeta(source)

    return (
        <InlineMessage
            type="warning"
            className="compact"
            message={formatMessage({
                id: 'editor_variable_source_value_options_incomplete',
                defaultMessage: '{source} has multiple values for {field}. Pick one.'
            }, {
                source: <strong>{name}</strong>,
                field: <strong>{fieldHeading}</strong>
            })} />
    )
}

export default TooltipContentIncomplete
import React, { Component, createRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { useForm } from 'components/form/controller'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { parseHumanDecimals } from 'utilities/number'
import { isInRange } from 'utilities/math'
import { emailAddressPattern, linkStrictPattern, linkLoosePattern } from 'utilities/regex'
import { slugify as toSlug, normalize } from 'utilities/string'
import { Field, Label, Control, ControlMainColumn, ValueDisplay } from 'components/form/field/s'
import { FlexChildShrink } from 'components/flex'
import Text from 'components/form/input/text'
import Textarea from 'components/form/input/textarea'
import { Error, Columns } from './s'
import { UnsetValueButton, UnsetValueButtonXCircle } from 'components/button'
import { X } from 'styled-icons/feather'
import Suggestions from './suggestions'

const empty = ''

class EditString extends Component {
    constructor(props) {
        super(props)

        this.state = this.valueStateFromProps(props)
        this.register()

        this.inputRef = createRef()
    }

    componentDidMount() {
        const {
            include = 'touched',
            growable = false
        } = this.props.field

        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }

        !!growable && this.adaptTextarea()
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required,
            include,
            match,
            exact,
            min,
            max,
            step,
            type,
            strict
        } = getFieldFromProps(this.props)

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            type,

            empty,
            required,
            include,

            unset: this.unset,

            validator: value => {
                if(!value) {
                    return !required
                }

                if(typeof value === 'string' && !value.trim()) {
                    return !required
                }

                if(!!match?.value) {
                    if(match?.strict) {
                        return value === match?.value
                    }

                    return normalize(value)?.toLowerCase() === normalize(match?.value)?.toLowerCase()
                }

                // Email regex
                if(this.getType() === 'email') {
                    return emailAddressPattern.test(value?.trim?.() ?? value)
                }

                // Link regex
                if(type === 'url') {
                    const pattern = strict ?
                        linkStrictPattern :
                        linkLoosePattern

                    return pattern.test(value?.trim?.() ?? value)
                }

                // Support for built-in browser input type validation
                const browserValidationInvalid = this.inputRef?.current?.validity?.valid === false

                if(this.getType() === 'number') {
                    if(this.inputRef?.current?.inputMode === 'decimal' && browserValidationInvalid) {
                        return false
                    }

                    const [number, valid] = parseHumanDecimals(value)

                    if(!valid) {
                        return false
                    }

                    if((min || min === 0) && max) {
                        return isInRange({ min, max, value: number })
                    }

                    if(step) {
                        return number % Number(step) === 0
                    }
                } else {
                    if(browserValidationInvalid) {
                        return false
                    }

                    if(exact) {
                        return value.length === exact
                    }

                    if((min || min === 0) && max) {
                        return isInRange({ min, max, value: value?.trim?.()?.length ?? 0 })
                    }
                }

                if(browserValidationInvalid) {
                    return false
                }

                if(required) {
                    return !!value?.trim() || value === 0
                }

                return true
            }
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value || empty
    })

    onChange = ({ target }) => {
        const {
            type,
            lowercase,
            slugify
        } = getFieldFromProps(this.props)

        let { value = empty } = target

        if(type === 'number') {
            [value] = parseHumanDecimals(value)
        }

        if(lowercase) {
            value = value.toLowerCase()
        }

        if(slugify) {
            if(value.endsWith(' ')) {
                value = `${value.trim()}-`
            }

            value = toSlug(value, { preserveTrailingDash: true })
        }

        this.setState({ value })
        this.props.onChange?.({ [this.props.name]: value })
    }

    onBlur = ({ target }) => {
        let { value = empty } = target

        if(getFieldFromProps(this.props).slugify) {
            value = toSlug(value)

            this.setState({ value })
            this.props.onChange?.({ [this.props.name]: value })
        }

        this.props.onBlur?.({ [this.props.name]: value })
    }

    adaptTextarea = () => {
        if(this.inputRef?.current?.scrollHeight) {
            this.inputRef.current.style.height = '0px'
            this.inputRef.current.style.height = `${this.inputRef.current.scrollHeight - 4}px`
        }
    }

    unset = () => void this.setState({ value: empty }, () => {
        this.props.onChange?.({ [this.props.name]: empty })
        this.props.onUnset?.()

        this.props.form.triggerChange(this.props.name)
    })

    getType = () => this.props.controlProps?.type || this.props.type || 'text'

    render() {
        const { value } = this.state

        const {
            className,
            controlProps = {},
            controlContent = null,
            salt,
            label,
            name,
            options,
            enabled = true,
            loading = false,
            display,
            error: outsideError,
            forwardedRef,
            ...props
        } = this.props

        const touched = this.props.form.touched.includes(name)
        const error = ((name in this.props.form.errors) && touched) || !!outsideError

        const {
            suffix,
            required,
            softRequired,
            optional,
            optionalProps,
            unsettable,
            growable
        } = getFieldFromProps(this.props)

        const fieldClassName = cls([
            className,
            touched && 'touched',
            suffix && 'suffixed',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const controlClassName = cls([
            growable && 'growable',
            (!!unsettable && !!value) && 'unsettable',
            error && 'error'
        ])

        const inputClassName = cls([
            controlProps.className,
            display
        ])

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}
                        optionalProps={optionalProps}>
                        {label}
                    </Label>
                )}
                <Control {...(controlClassName ? { className: controlClassName } : null)}>
                    <Columns>
                        <ControlMainColumn>
                            {!!growable && (
                                <Textarea
                                    {...controlProps}
                                    {...(inputClassName ? { className: inputClassName } : null)}
                                    {...omit(props, 'form', 'field', 'whistle', 'onUnset')}
                                    name={name}
                                    value={value}
                                    id={salt}
                                    onChange={(...args) => {
                                        this.onChange(...args)
                                        this.adaptTextarea()
                                    }}
                                    onFocus={(...args) => {
                                        this.props.onFocus?.(...args)
                                        controlProps.onFocus?.(...args)
                                    }}
                                    onBlur={(...args) => {
                                        this.onBlur(...args)
                                        controlProps.onBlur?.(...args)
                                    }}
                                    onInput={e => {
                                        e.target.value = e.target.value.replace(/(\r\n|\n|\r)/gm, '')

                                        if(e.key === 'Enter') {
                                            e.preventDefault()
                                            e.stopPropagation()
                                        }
                                    }}
                                    disabled={!enabled}
                                    ref={mergeRefs([
                                        this.inputRef,
                                        forwardedRef
                                    ])} />
                            )}
                            {(!options?.length && !growable) && (
                                <Text
                                    {...controlProps}
                                    {...(inputClassName ? { className: inputClassName } : null)}
                                    {...omit(props, 'form', 'field', 'whistle', 'onUnset')}
                                    name={name}
                                    value={value}
                                    type={this.getType()}
                                    id={salt}
                                    onChange={this.onChange}
                                    onFocus={(...args) => {
                                        this.props.onFocus?.(...args)
                                        controlProps.onFocus?.(...args)
                                    }}
                                    onBlur={(...args) => {
                                        this.onBlur(...args)
                                        controlProps.onBlur?.(...args)
                                    }}
                                    disabled={!enabled}
                                    ref={mergeRefs([
                                        this.inputRef,
                                        forwardedRef
                                    ])} />
                            )}
                            {(!!unsettable && !!value) && (
                                <UnsetValueButton onClick={this.unset}>
                                    <UnsetValueButtonXCircle>
                                        <X size={12} />
                                    </UnsetValueButtonXCircle>
                                </UnsetValueButton>
                            )}
                            <Error animate={outsideError ? 'reveal' : 'hide'}>
                                {outsideError}
                            </Error>
                            {!!options?.length && (
                                <Suggestions
                                    show={controlProps?.autoFocus ?? false}
                                    name={name}
                                    value={value}
                                    suggestions={options}
                                    onChange={this.onChange}
                                    id={salt} />
                            )}
                        </ControlMainColumn>
                        {!!suffix && (
                            <FlexChildShrink>
                                <ValueDisplay className="soft">
                                    {suffix}
                                </ValueDisplay>
                            </FlexChildShrink>
                        )}
                    </Columns>
                    {controlContent}
                </Control>
            </Field>
        )
    }
}

const getFieldFromProps = ({ field = {}, controlProps = {} }) => {
    let {
        required = false,
        softRequired = false,
        strict = true,
        include = 'touched',
        exact = false,
        lowercase = false,
        unsettable = false,
        growable = false,
        ...rest
    } = field

    let {
        type = null,
        min = 0,
        max = Infinity,
        step
    } = controlProps

    return {
        ...rest,
        required,
        softRequired,
        strict,
        include,
        exact,
        min,
        max,
        step,
        lowercase,
        unsettable,
        growable,
        type
    }
}

export default props => {
    const form = useForm()

    return (
        <EditString
            {...props}
            form={form} />
    )
}
const minimal = [
    'Heading',
    'Paragraph',
    'HorizontalRule',
    'HardBreak',
    'Link',
    'Bold',
    'Italic',
    'Underline',
    'Strike',
    'Code',
    'Emoji'
]

const medium = [
    'BulletList',
    'OrderedList',
    'ListItem',
    'Blockquote',
    'CodeBlock',
    'Highlight',
    'Subscript',
    'Superscript',
    'Mention',
    'Youtube',
    'Vimeo'
]

const maximal = [
    // 'Image'
]

const summary = [
    'Paragraph',
    'Link',
    'Bold',
    'Italic',
    'Underline',
    'Strike',
    'Highlight',
    'Code',
    'Subscript',
    'Superscript',
    'Mention',
    'Emoji'
]

export default {
    minimal,
    medium: [
        ...minimal,
        ...medium
    ],
    maximal: [
        ...minimal,
        ...medium,
        ...maximal
    ],
    summary
}
import styled, { css } from 'styled-components'
import { interpointDivider } from 'utilities/styled'

export const paragraphCss = css`
    @layer paragraph {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--huma-color-foreground-default);

        &.preserve-line-breaks {
            white-space: pre-line;
        }

        &.balance-text-rows {
            text-wrap: balance;
        }

        &.ellipsify {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &.interpoint-divider {
            ${interpointDivider()}
        }

        &.center {
            text-align: center;
        }

        a:hover &.link-parent {
            text-decoration: underline;
        }

        &.strike {
            text-decoration: line-through;
        }

        &.caption {
            color: var(--huma-color-foreground-subtle);
        }

        &.preamble {
            max-width: 60ch;

            font-size: 20px;
            font-weight: 300;
            line-height: 28px;
        }

        &.empty {
            color: var(--huma-color-foreground-minimal);
        }

        &.constructive,
        &.action {
            color: var(--huma-color-foreground-constructive-bold);
        }

        &.destructive,
        &.error {
            color: var(--huma-color-foreground-destructive);
        }
    }
`

const smallCss = css`
    font-size: 14px;
    line-height: 20px;
`

export const paragraphSmallCss = css`
    @layer paragraph-small {
        ${paragraphCss}
        ${smallCss}
    }
`

export const captionCss = css`
    @layer caption {
        ${paragraphCss}

        color: var(--huma-color-foreground-subtle);
    }
`

export const captionSmallCss = css`
    @layer caption-small {
        ${captionCss}
        ${smallCss}
    }
`

const Paragraph = styled.p`
    ${paragraphCss}

    margin-block-end: 24px;

    &.small {
        ${paragraphSmallCss}

        margin-block-end: 16px;
    }

    &.compact {
        margin-block-end: 0;
    }
`

export const ParagraphSmall = styled.p`
    ${paragraphSmallCss}

    margin-block-end: 16px;

    &.compact {
        margin-block-end: 0;
    }
`

export default Paragraph

import React, { useState, forwardRef } from 'react'
import {
    AvatarColumnsBlock, AvatarColumnsInline, AvatarColumnsLink,
    CentererBlock, CentererInline,
    AvatarImage, AvatarAnimation, AvatarFallbackBlock, AvatarFallbackInline,
    Button, AddButton, ConcernsButton, ResponsibleButton,
    BuddyButton, SupervisorButton, GroupButton,
    AvatarDeletedWrapper
} from './s'
import {
    UserPlus, UserCheck, User,
    MapPin as Location
} from 'styled-icons/feather'
import { Team } from 'components/feather'
import { getAvatarUrl, getFullName, getAvatarFallback, getColors } from 'utilities/person'
import { cls } from 'utilities/dom'
import { omit } from 'utilities/object'
import { getPeopleProfileUrl, getGroupUrl } from 'utilities/url'
import { UserX as AvatarDeletedIcon } from 'styled-icons/feather'

const Avatar = forwardRef(({ who, size, className, inline, link = false, hasTooltip = false, to = null }, ref) => {
    const [imagePreferred, setImagePreferred] = useState(true)

    let avatarUrl = getAvatarUrl(who)
    const avatarFallback = getAvatarFallback(who)

    const AvatarColumns = link ?
        AvatarColumnsLink :
        inline ?
            AvatarColumnsInline :
            AvatarColumnsBlock

    const Centerer = (inline || link) ?
        CentererInline :
        CentererBlock

    const AvatarFallback = (inline || link) ?
        AvatarFallbackInline :
        AvatarFallbackBlock

    const fullName = getFullName(who)

    const { status } = who ?? {}
    const isDeactivated = status?.active === false

    const avatarClassName = cls([
        className,
        isDeactivated && 'deactivated'
    ])

    const props = {
        ...(avatarClassName ? { className: avatarClassName } : null),
        ...(link ? { to: to ?? getPeopleProfileUrl(who) } : null)
    }

    return (
        <AvatarColumns
            {...props}
            $fallback={omit(avatarFallback, 'initials')}
            ref={ref}>
            <Centerer className="centerer">
                {(!!avatarUrl && !!imagePreferred) && (
                    <>
                        <AvatarImage
                            className="avatar"
                            src={avatarUrl}
                            alt={fullName}
                            {...(!hasTooltip ? { title: fullName } : null)}
                            $size={size}
                            onError={() => setImagePreferred(false)} />
                        <AvatarAnimation />
                    </>
                )}
                {(!avatarUrl || !imagePreferred) && (
                    <AvatarFallback
                        $size={size}
                        {...(!hasTooltip ? { title: fullName } : null)}
                        {...(!isDeactivated ? {
                            style: {
                                '--avatar-background': avatarFallback?.background,
                                '--avatar-color': avatarFallback?.color
                            }
                        } : null)}
                        className="avatar">
                        {avatarFallback?.initials}
                    </AvatarFallback>
                )}
            </Centerer>
        </AvatarColumns>
    )
})

const GroupAvatar = ({ group, size, className, inline, link = false, to = null }) => {
    const avatarFallback = getColors(group.id)

    const AvatarColumns = link ?
        AvatarColumnsLink :
        inline ?
            AvatarColumnsInline :
            AvatarColumnsBlock

    const Centerer = (inline || link) ?
        CentererInline :
        CentererBlock

    const AvatarFallback = (inline || link) ?
        AvatarFallbackInline :
        AvatarFallbackBlock

    const props = {
        ...(className ? { className } : null),
        ...(link ? { to: to ?? getGroupUrl(group) } : null)
    }

    return (
        <AvatarColumns {...props}>
            <Centerer className="centerer">
                <AvatarFallback
                    $size={size}
                    {...avatarFallback}
                    style={{
                        '--avatar-background': avatarFallback?.background,
                        '--avatar-color': avatarFallback?.color
                    }}
                    className="avatar icon">
                    {(group.type === 'team') && <Team size={size * .4} />}
                    {(group.type === 'location') && <Location size={size * .4} />}
                </AvatarFallback>
            </Centerer>
        </AvatarColumns>
    )
}

export const AvatarButton = ({ who, size = 40, ...props }) => (
    <Button {...props}>
        <Avatar
            who={who}
            size={size} />
    </Button>
)

export const AvatarAddButton = ({ iconSize = 16, ...props }) => (
    <AddButton {...props}>
        <UserPlus size={iconSize} />
    </AddButton>
)

export const AvatarConcernsButton = ({ iconSize = 16, ...props }) => (
    <ConcernsButton {...props}>
        <UserCheck size={iconSize} />
    </ConcernsButton>
)

export const AvatarResponsibleButton = ({ iconSize = 16, ...props }) => (
    <ResponsibleButton {...props}>
        <User size={iconSize} />
    </ResponsibleButton>
)

export const AvatarBuddyButton = ({ iconSize = 16, ...props }) => (
    <BuddyButton {...props}>
        <User size={iconSize} />
    </BuddyButton>
)

export const AvatarSupervisorButton = ({ iconSize = 16, ...props }) => (
    <SupervisorButton {...props}>
        <User size={iconSize} />
    </SupervisorButton>
)

export const AvatarGroupButton = ({ group, size = 40, ...props }) => (
    <GroupButton {...props}>
        <GroupAvatar
            group={group}
            size={size} />
    </GroupButton>
)

export const AvatarDeleted = ({ size = 40, ...props }) => (
    <AvatarDeletedWrapper
        {...props}
        $size={size}>
        <AvatarDeletedIcon size={size / 2} />
    </AvatarDeletedWrapper>
)

export default Avatar
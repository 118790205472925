import styled from 'styled-components'
import { belowContainer } from 'utilities/styled'
import { SkeletonListRow as SkeletonListRowBase, SkeletonCell as SkeletonCellBase } from 'components/skeleton'
import CardListBase from 'components/card-list'

export const containerProps = {
    name: 'deviation-cases',
    breakpoint: 650
}

export const SkeletonListRow = styled(SkeletonListRowBase)`
    display: grid;
    grid-template-columns: subgrid;
    grid-column: wrap;
    align-items: center;
    column-gap: 16px;

    padding-block: 16px;
    min-height: 72px;

    &:not(:last-child) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    ${belowContainer(containerProps)`
        display: flex;
        flex-direction: column;
        align-items: start;
    `}
`

export const SkeletonCell = styled(SkeletonCellBase)`
    grid-column: ${p => p.$cell};
`

export const CardList = styled(CardListBase)`
    grid-template-columns:
        [wrap-start meta] minmax(0, 2fr)
        [status] minmax(0, 1fr)
        [risk] minmax(0, 1fr)
        [handler] minmax(0, 1fr)
        [message] minmax(0, 1fr) [wrap-end];
`

export const LoadingContainer = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 96px;
`

import React from 'react'
import { StyledIconBase } from '@styled-icons/styled-icon'
import LogoSvg from 'static/gfx/logo.csvg'

export const Custom = ({ svg, className, ...props }) => {
    const { props: svgProps } = svg()
    const { children, ...attrs } = svgProps

    return (
        <StyledIconBase
            iconVerticalAlign="middle"
            {...attrs}
            {...props}
            {...(className ? { className } : null)}>
            {children}
        </StyledIconBase>
    )
}

export const Logo = props => (
    <Custom
        width={111}
        height={32}
        {...props}
        svg={LogoSvg} />
)
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Title as TitleBase, H2 } from 'components/typography/heading'
import { Naked } from 'components/button'

export const ModuleTitleLayout = styled(Grid)`
    grid-template-columns: minmax(0, max-content) min-content;
    grid-gap: 12px;
    align-items: center;

    &.sub {
        grid-gap: 8px;
    }

    &.beta {
        grid-template-rows: auto auto;
        row-gap: 8px;
    }

    &.spacious {
        margin-block: 0 16px;

        &.sub {
            margin-block: 0 12px;
        }
    }
`

export const Title = styled(TitleBase)`
    margin: 0;
`

export const Subheading = styled(H2)`
    margin-block-end: 0;
`

export const HelpButton = styled(Naked)`
    transition: color .2s ease-in-out;

    color: var(--huma-color-foreground-subtle);

    &:hover {
        color: var(--huma-color-foreground-default);
    }

    svg {
        ${svgStroke}
    }
`
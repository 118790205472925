import styled from 'styled-components'

export const Wrapper = styled.div`
    display: grid;
    grid-template-rows:
        [header] auto
        [content] 1fr
        [footer] auto;
    row-gap: 16px;
    align-items: end;

    height: 100%;
`
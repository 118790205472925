import React, { forwardRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { safeFormat } from 'utilities/date-time'
import { getWhistleblowingCaseUrl } from 'utilities/url'
import { getCaseWarningOrError } from 'pages/whistleblowing/utilities'
import { prune } from 'utilities/array'
import { Row, Cell, ContainedLabel, Time, MetaCellInner, CaseMeta, CaseNumber } from './s'
import Tooltip from 'components/tooltip'
import CaseStatus from 'pages/whistleblowing/components/case-status'
import Person, { AnonymousPerson } from 'components/person'
import { InlineMessage } from 'components/message'
import { ArrowRight } from 'styled-icons/feather'

const Case = forwardRef(({ kase, statusContext, ...props }, ref) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const dateFormat = formatMessage({
        id: 'date_fns_format_full_friendly',
        defaultMessage: `PPP 'at' p`
    })

    const warningOrError = getCaseWarningOrError(kase)

    const reporter = Object.keys(kase.reporter ?? {}).length ?
        prune([
            kase.reporter.name,
            kase.reporter.email,
            kase.reporter.phone
        ]) :
        null

    const CaseReporter = reporter?.length > 1 ?
        props => (
            <Tooltip
                content={reporter.join(', ')}
                placement="bottom-start">
                <span {...props} />
            </Tooltip>
        ) : 'span'

    return (
        <Row
            {...props}
            to={getWhistleblowingCaseUrl(kase)}
            ref={ref}>
            <Cell $cell="meta">
                <ContainedLabel>
                    <FormattedMessage
                        id="whistleblowing_case_meta_label_submitted"
                        defaultMessage="Submitted" />
                </ContainedLabel>
                <MetaCellInner>
                    <Time
                        dateTime={kase.createdAt}
                        className="compact">
                        {safeFormat(kase.createdAt, dateFormat, { locale })}
                    </Time>
                    <CaseMeta className="compact">
                        <CaseNumber>{kase.number}</CaseNumber>
                        {!!reporter?.length && (
                            <CaseReporter>
                                {reporter[0]}
                            </CaseReporter>
                        )}
                    </CaseMeta>
                </MetaCellInner>
            </Cell>
            <Cell $cell="status">
                <CaseStatus status={kase.status} />
            </Cell>
            {kase.lastActionAuthor && (
                <Cell $cell={statusContext.cell}>
                    <ContainedLabel>
                        <FormattedMessage
                            id="whistleblowing_case_label_last_action"
                            defaultMessage="Last action" />
                    </ContainedLabel>
                    {(kase.lastActionAuthor?.type === 'reporter') && (
                        <AnonymousPerson
                            role={formatMessage({
                                id: 'whistleblowing_case_meta_label_whistleblower',
                                defaultMessage: 'Whistleblower'
                            })}
                            showJobTitle={!reporter?.length}
                            size={40} />
                    )}
                    {(kase.lastActionAuthor?.type === 'handler' && kase.lastActionAuthor?.user) && (
                        <Person
                            who={{
                                ...kase.lastActionAuthor.user,
                                jobTitle: {
                                    name: formatMessage({
                                        id: 'noun_case_handler',
                                        defaultMessage: 'Case Handler'
                                    })
                                }
                            }}
                            showPosition={true}
                            size={40} />
                    )}
                </Cell>
            )}
            {warningOrError && (
                <Cell $cell="message">
                    <InlineMessage
                        type={warningOrError.type}
                        message={<FormattedMessage {...warningOrError.message} />} />
                </Cell>
            )}
            <Cell
                className="action"
                $cell="action">
                <ArrowRight size={24} />
            </Cell>
        </Row>
    )
})

export default Case

import React, { useState, useEffect } from 'react'
import { clamp } from 'utilities/math'
import { FlexChildShrink } from 'components/flex'
import {
    ListRow, RadioListRow, Cell,
    Strings, Avatars,
    AvatarAndFullName, Avatar, Category, Icon, DetailsColumn,
    SymbolAndMeta,
    Circle, Square, Rectangle, Logo, Status
} from './s'

export const SkeletonListRow = ({ children, columns = 3, hasActions = false, ...props }) => (
    <ListRow
        {...props}
        $columns={columns}
        $hasActions={hasActions}>
        {children}
    </ListRow>
)

export const SkeletonRadioListRow = ({ children, columns = 3, hasActions = false, ...props }) => (
    <RadioListRow
        {...props}
        $columns={columns}
        $hasActions={hasActions}>
        {children}
    </RadioListRow>
)

export const SkeletonCell = ({ children, ...props }) => <Cell {...props}>{children}</Cell>

export const SkeletonStrings = ({ size = 24, length, count = 1, ...props }) => (
    <Strings
        {...props}
        $size={size}
        $length={length}>
        {[...Array(count).keys()].map(index => (
            <span key={`skeleton:strings:${index}`} />
        ))}
    </Strings>
)

export const SkeletonAvatars = ({ className, size = 24, count = 5 }) => (
    <Avatars className={className}>
        {[...Array(count).keys()].map(index => (
            <Avatar
                $size={size}
                key={`skeleton:avatar:${index}`} />
        ))}
    </Avatars>
)

export const SkeletonAvatarAndFullName = ({ className, size = 24, showSecondLine = false }) => (
    <AvatarAndFullName className={className}>
        <FlexChildShrink>
            <Avatar $size={size} />
        </FlexChildShrink>
        <DetailsColumn $size={size}>
            <span />
            {showSecondLine && <span />}
        </DetailsColumn>
    </AvatarAndFullName>
)

export const SkeletonSymbolAndMeta = ({ className, size = 24, showSecondLine = false }) => (
    <SymbolAndMeta className={className}>
        <FlexChildShrink>
            <Avatar $size={size} />
        </FlexChildShrink>
        <DetailsColumn $size={size}>
            <span />
            {showSecondLine && <span />}
        </DetailsColumn>
    </SymbolAndMeta>
)

export const SkeletonCategory = ({ className, size = 24, length, showSecondLine = false }) => (
    <Category className={className}>
        <FlexChildShrink>
            <Icon $size={size} />
        </FlexChildShrink>
        <DetailsColumn
            $size={size}
            $length={length}>
            <span />
            {showSecondLine && <span />}
        </DetailsColumn>
    </Category>
)

export const SkeletonCircle = ({ size = 20, ...props }) => <Circle {...props} $size={size} />
export const SkeletonSquare = ({ size = 20, ...props }) => <Square {...props} $size={size} />
export const SkeletonRectangle = ({ size = 20, ...props }) => <Rectangle {...props} $size={size} />
export const SkeletonLogo = ({ size = 20, ...props }) => <Logo {...props} $size={size} />
export const SkeletonRadio = ({ size = 20, ...props }) => <Circle {...props} $size={size} />
export const SkeletonStatus = Status

export const useSkeletonLoader = (items = [], hasFetched, options = {}) => {
    const {
        defaultCount = 5,
        max = 10
    } = options

    const [skeletonLength, setSkeletonLength] = useState(defaultCount)

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(items.length, 1, max))
        }
    }, [hasFetched, items.length, max])

    return skeletonLength
}
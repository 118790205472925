import React, { useState, useRef, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { compact } from 'utilities/array'
import { Wrapper } from '../s'
import { AddButton, DragDropWrapper, Sortables } from './s'
import { Label } from 'components/form/field/s'
import { Scrollable as Modal } from 'modals/generic'
import EditLink from './edit-link'
import SortableLink from 'components/sortable-link'
import { Plus } from 'styled-icons/feather'

const EditUsefulLinks = ({ links = [], onChange, shouldAutoFocus = true, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const addRef = useRef()

    const [usefulLinks, setUsefulLinks] = useState(links)
    const [editing, setEditing] = useState(null)

    useEffect(() => {
        if(!editing && addRef?.current && shouldAutoFocus) {
            addRef.current.focus()
        }
    }, [editing])

    const remove = url => {
        const links = usefulLinks?.filter(link => link.url !== url)

        setUsefulLinks(links)
        onChange?.(links)
    }

    const update = links => {
        setUsefulLinks(links)
        onChange?.(links)
    }

    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1)
        list.splice(endIndex, 0, removed)

        return list
    }

    const onDragEnd = result => {
        const { destination, source } = result

        if (!destination || destination.index === source.index) {
            return
        }

        const order = reorder(
            usefulLinks,
            source.index,
            destination.index
        )

        update([...order])
    }

    const actions = compact([
        link => ({
            salt: `${salt}:edit`,
            label: formatMessage({
                id: 'useful_links_action_edit',
                defaultMessage: 'Edit link'
            }),
            onClick: () => setEditing(link),
            effect: 'neutral'
        }),
        ({ url }) => ({
            salt: `${salt}:delete`,
            label: formatMessage({
                id: 'useful_links_action_delete',
                defaultMessage: 'Delete link'
            }),
            onClick: () => remove(url),
            effect: 'destructive'
        })
    ])

    return (
        <Wrapper {...props}>
            <Label>
                <FormattedMessage
                    id="noun_useful_links"
                    defaultMessage="Useful links" />
            </Label>
            <AddButton
                className="constructive"
                onClick={() => setEditing(true)}
                ref={addRef}>
                <Plus size={24} />
                <FormattedMessage
                    id="useful_links_action_add"
                    defaultMessage="Add link" />
            </AddButton>
            {!!usefulLinks?.length && (
                <DragDropWrapper>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {provided => (
                                <Sortables
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {usefulLinks.map((link, index) => (
                                        <Draggable
                                            key={`${salt}:draggable:${link.url}:${link.name}`}
                                            isDragDisabled={false}
                                            disableInteractiveElementBlocking={true}
                                            draggableId={`${salt}:draggable:${link.url}:${link.name}`}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                <SortableLink
                                                    link={link}
                                                    actions={actions}
                                                    count={usefulLinks?.length}
                                                    provided={provided}
                                                    snapshot={snapshot}
                                                    salt={salt} />
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Sortables>
                            )}
                        </Droppable>
                    </DragDropContext>
                </DragDropWrapper>
            )}
            <Modal
                show={!!editing}
                dismiss={() => setEditing(null)}
                salt={salt}>
                <EditLink
                    salt={salt}
                    link={editing}
                    usefulLinks={usefulLinks}
                    setUsefulLinks={setUsefulLinks}
                    onChange={onChange}
                    onDone={() => setEditing(null)}>
                </EditLink>
            </Modal>
        </Wrapper>
    )
}

export default EditUsefulLinks

import React from 'react'
import TooltipBase from 'components/tooltip'
import { RichLabel } from './s'
import Shortcut from '../help/shortcut'

const Tooltip = ({ content, shortcut, placement = 'top-start', salt, ...props }) => {
    if(shortcut) {
        content = (
            <RichLabel>
                <div>{content}</div>
                <Shortcut
                    shortcut={shortcut}
                    className="smaller"
                    salt={`${salt}:tooltip`} />
            </RichLabel>
        )
    }

    return (
        <TooltipBase
            {...props}
            content={content}
            placement={placement}
            trigger="mouseenter click" />
    )
}

export default Tooltip
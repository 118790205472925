import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import { pruneBy } from 'utilities/array'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { empty } from 'components/form/field/share'
import Tooltip from 'components/tooltip'
import CustomTooltip from './custom-tooltip'
import { Units } from 'components/units'
import WhoHasAccess from 'components/who-has-access'

const ViewAccessUnits = ({ className, label, salt, ...props }) => {
    const { isItMyOwnId } = useMe()

    const {
        name,
        field = {},
        display = {}
    } = props

    let { value } = field
    if(!value) {
        value = empty
    }

    const { view: viewDisplay = null } = display

    const owners = value
        .filter(({ access }) => !!access?.owner)
        .map(({ unit }) => unit)

    const shares = value
        .filter(({ access }) => !!access?.share)
        .map(({ unit }) => unit)

    const accesses = pruneBy([...owners, ...shares])
    const publicAccess = accesses.find(({ type }) => type === 'organization')
    const userShares = accesses.filter(({ type }) => type === 'user')
    const unshared = accesses.length === 1 && isItMyOwnId(accesses[0].id)

    salt = `${salt}:${name}:view`

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                {(!unshared && !publicAccess && userShares?.length === accesses.length) && (
                    <Tooltip
                        content={(
                            <CustomTooltip
                                value={value}
                                salt={salt} />
                        )}
                        placement="top-end">
                        <WhoHasAccess
                            people={pruneBy([...owners, ...userShares])}
                            max={10}
                            size={32}
                            showTooltip={false}
                            salt={salt} />
                    </Tooltip>
                )}
                {(!unshared && (!!publicAccess || userShares?.length !== accesses.length)) && (
                    <Tooltip
                        content={(
                            <CustomTooltip
                                value={value}
                                salt={salt} />
                        )}
                        placement="top-end">
                        <Units
                            units={accesses.map(owner => owner.type === 'organization' ? { ...owner, type: 'company' } : owner)}
                            showTooltip={false}
                            max={5}
                            salt={salt} />
                    </Tooltip>
                )}
                {!!unshared && (
                    <ValueDisplay className="empty">
                        <FormattedMessage
                            id="access_only_you"
                            defaultMessage="Only you have access" />
                    </ValueDisplay>
                )}
                {!accesses.length && (
                    <ValueDisplay className="empty">
                        <FormattedMessage
                            id="empty_none_picked"
                            defaultMessage="None picked" />
                    </ValueDisplay>
                )}
            </Control>
            {viewDisplay}
        </Field>
    )
}

export default ViewAccessUnits
import React, { Fragment } from 'react'
import { useIntl } from 'react-intl'
import {
    Table, TableHead, TableBody,
    TableRow, TableHeader, TableCell
} from './s'
import Person from 'components/person'
import Entity from './entity'
import {
    Eye as Share,
    Edit as Owner,
    Check as Yes,
    X as No
} from 'styled-icons/feather'

const CustomTooltip = ({ value, salt }) => {
    const { formatMessage } = useIntl()

    if(!value?.length) {
        return null
    }

    const membersLabel = formatMessage({
        id: 'noun_members',
        defaultMessage: 'Members'
    })

    const adminsLabel = formatMessage({
        id: 'noun_admins',
        defaultMessage: 'Admins'
    })

    salt = `${salt}:tooltip`

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeader />
                    <TableHeader>
                        <Share size={14} />
                    </TableHeader>
                    <TableHeader>
                        <Owner size={14} />
                    </TableHeader>
                </TableRow>
            </TableHead>
            <TableBody>
                {value.map(({ unit, access }) => {
                    const share = (unit.type === 'user' && access.owner) || access.share
                    const owner = access.owner

                    return (
                        <Fragment key={`${salt}:${unit.id}`}>
                            <TableRow>
                                <TableHeader>
                                    {(unit.type === 'user') && (
                                        <Person
                                            who={unit}
                                            truncate
                                            size={16}
                                            className="compact" />
                                    )}
                                    {(unit.type !== 'user') && <Entity {...unit} />}
                                </TableHeader>
                                {(unit.type === 'user') && (
                                    <>
                                        <TableCell className={!!share ? 'positive' : 'negative'}>
                                            <AccessIcon yes={share} />
                                        </TableCell>
                                        <TableCell className={!!owner ? 'positive' : 'negative'}>
                                            <AccessIcon yes={owner} />
                                        </TableCell>
                                    </>
                                )}
                                {(unit.type !== 'user') && (
                                    <>
                                        <TableCell />
                                        <TableCell />
                                    </>
                                )}
                            </TableRow>
                            {(unit.type !== 'user') && (
                                <>
                                    <TableRow className="label">
                                        <TableHeader>
                                            {membersLabel}
                                        </TableHeader>
                                        <TableCell className={!!share ? 'positive' : 'negative'}>
                                            <AccessIcon yes={share} />
                                        </TableCell>
                                        <TableCell className="negative">
                                            <AccessIcon yes={false} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="label">
                                        <TableHeader>
                                            {adminsLabel}
                                        </TableHeader>
                                        <TableCell className={!!owner ? 'positive' : 'negative'}>
                                            <AccessIcon yes={owner} />
                                        </TableCell>
                                        <TableCell className={!!owner ? 'positive' : 'negative'}>
                                            <AccessIcon yes={owner} />
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                        </Fragment>
                    )
                })}
            </TableBody>
        </Table>
    )
}

const AccessIcon = ({ yes }) => {
    if(yes) {
        return <Yes size={14} />
    }

    return <No size={14} />
}

export default CustomTooltip
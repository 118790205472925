import styled from 'styled-components'
import { ParagraphSmall } from 'components/typography/paragraph'
import { Button } from 'components/button'

export const Email = styled.div`
    margin-block-start: 12px;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    box-shadow: var(--huma-shadow-high);
    background-color: var(--huma-color-surface-default);
`

export const EmailHeader = styled.div`
    padding: 24px;
    border-bottom: 1px solid var(--huma-color-border-default);
`

export const EmailHeaderLine = ParagraphSmall

export const EmailContent = styled.div`
    padding: 24px 24px 0;
`

export const Variable = styled.span`
    display: inline-block;

    background-color: var(--huma-color-surface-success-minimal);

    ${EmailHeader} & {
        border-radius: 10px;
        height: 20px;
        padding-inline: 6px;
    }

    ${EmailContent} & {
        border-radius: 12px;
        height: 24px;
        padding-inline: 8px;
    }
`

export const DisabledConstructiveButton = styled(Button).attrs(() => ({
    autoFocus: false,
    tabIndex: -1,
    className: 'constructive'
}))`
    pointer-events: none;

    margin-block-end: 24px;
`
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import Paragraph from 'components/typography/paragraph'
import MessageBase from 'components/message'
import { buttonStyles } from 'components/button'
import { Grid } from 'components/grid'
import LinkBase from 'components/link'

export const Bundles = styled.div``

export const Bundle = styled(Grid)`
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 16px 0;

    &:not(:last-of-type) {
        box-shadow: 0 1px 0 0 var(--huma-color-border-default);
    }
`

export const BundleHeader = styled.div``

export const BundleHeading = styled(Paragraph)`
    font-weight: 500;
`

export const BundleCTA = styled.div``

export const CenteredParagraph = styled(Paragraph)`
    margin-block: 16px;

    text-align: center;
`
export const ContactSalesWrap = styled.div`
    margin-block-start: 16px;
    border-radius: 0;
    padding: 16px 0 0;
    box-shadow: 0 -1px 0 0 var(--huma-color-border-default);

    &:empty {
        display: none;
    }
`

export const Message = styled(MessageBase)`
    margin-block: 16px 8px;
`

export const PromptLink = styled(LinkBase)`
    text-decoration: underline;
`

export const Channels = styled(Grid)`
    column-gap: 24px;
    grid-template-columns: repeat(2, auto);
    justify-content: start;

    svg {
        ${svgStroke()}

        margin-inline-end: 4px;
    }
`

export const ChannelLink = styled.a`
    ${buttonStyles.plainCss}

    justify-content: center;

    height: 24px;

    font-size: 14px;
    text-decoration: none;

    svg {
        ${svgStroke('small')}

        margin-inline-end: 8px;
    }
`
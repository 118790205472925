import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import { PlainBlobs } from 'components/entities'

export const Columns = styled(Grid)`
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;

    svg {
        ${svgStroke()}
    }
`

export const IconColumn = styled(Grid)`
    justify-items: end;
    width: 24px;
`

export const Entities = styled(PlainBlobs)`
    flex-wrap: wrap;
    position: relative;
    left: -8px;
`
import React from 'react'
import { usePage } from 'contexts/page'
import { useOrganization } from 'contexts/organization'
import { Title } from 'components/typography/heading'
import { Wrap, SkeletonListRow, SkeletonCell } from './s'
import Subsidiary from './subsidiary'
import { SkeletonStrings, SkeletonLogo, SkeletonCategory } from 'components/skeleton'

const CorporationSubsidiaries = props => {
    const { title } = usePage()
    const { subsidiaries } = useOrganization()

    return (
        <>
            <Title>{title}</Title>
            <Wrap {...props}>
                {!subsidiaries && [...Array(5).keys()].map(index => (
                    <SkeletonListRow
                        columns={4}
                        hasActions={true}
                        key={`list:corporation-subsidiaries:skeleton:${index}`}>
                        <SkeletonCell>
                            <SkeletonStrings
                                length={16}
                                size={20} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonLogo size={32} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings
                                length={12}
                                size={20} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonCategory
                                length={8}
                                size={20} />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {subsidiaries?.map(subsidiary => (
                    <Subsidiary
                        subsidiary={subsidiary}
                        key={subsidiary.id} />
                ))}
            </Wrap>
        </>
    )
}

export default CorporationSubsidiaries
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import { getFullName } from 'utilities/person'
import { Items } from './s'

const Participants = ({ host, concerns = null, attendees = [] }) => {
    const { isItMyOwnId } = useMe()

    if(!host) {
        return null
    }

    const amIOrganizing = isItMyOwnId(host.id)
    attendees = attendees.length + 1

    return (
        <Items className="compact">
            <span>
                <FormattedMessage
                    id={amIOrganizing ? 'meeting_host_me' : 'meeting_host_user'}
                    defaultMessage={amIOrganizing ? 'You are the meeting host' : '{name} is the meeting host'}
                    values={{ name: getFullName(host, { fallback: false }) }} />
            </span>
            {((attendees !== 2 && !concerns) || (attendees >= 3 && !!concerns)) && (
                <span>
                    <FormattedMessage
                        id="meeting_attendees_count"
                        defaultMessage="{count, plural, =0 {0 attendees} =1 {1 attendee} other {{count} attendees}}"
                        values={{ count: attendees }} />
                </span>
            )}
        </Items>
    )

}

export default Participants
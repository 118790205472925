import React from 'react'
import { useTheme, ThemeProvider } from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import ContextMenu, { getActionsArray } from 'widgets/context-menu'
import { isOngoing, isFuture } from 'pages/absence/utilities'
import { cls } from 'utilities/dom'
import {
    Revealer,
    Entry, EntryDot, EntryActions,
    SkeletonWrapper
} from './s'
import { SkeletonStrings } from 'components/skeleton'

const TimelineEntry = ({ entry, className, salt }) => {
    let theme = useTheme()

    const {
        content,
        actions = [],
        fromDate,
        toDate
    } = entry

    const hasActions = !!getActionsArray(actions).length

    theme = {
        ...theme,
        policyTimeline: {
            ...theme.policyTimeline,
            actions: hasActions
        }
    }

    const now = new Date()

    className = cls([
        className,
        !fromDate && 'endless-start',
        !toDate && 'endless-end',
        // Safeguard
        [
            (!(fromDate > toDate) && isOngoing({ start: fromDate, end: toDate }, now)),
            (!fromDate && !toDate)
        ].some(Boolean) && 'ongoing',
        (fromDate > toDate && !!toDate) && 'error',
        isFuture({ start: fromDate }, now) && 'future'
    ])

    return (
        <ThemeProvider theme={theme}>
            <AnimatePresence>
                <Revealer animate="show">
                    <Entry className={className}>
                        <EntryDot className="entry-dot" />
                        <div>
                            {content}
                        </div>
                        {hasActions && (
                            <EntryActions>
                                <ContextMenu
                                    salt={salt}
                                    context={entry}
                                    actions={actions} />
                            </EntryActions>
                        )}
                    </Entry>
                </Revealer>
            </AnimatePresence>
        </ThemeProvider>
    )
}

export const TimelineEntryLoading = () => {
    let theme = useTheme()

    theme = {
        ...theme,
        policyTimeline: {
            ...theme.policyTimeline,
            size: 20,
            actions: false
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Entry className="loading">
                <EntryDot className="entry-dot" />
                <SkeletonWrapper>
                    <SkeletonStrings
                        size={14}
                        length={20} />
                    <SkeletonStrings
                        size={20}
                        length={25} />
                    <SkeletonStrings
                        size={14}
                        length={7} />
                </SkeletonWrapper>
            </Entry>
        </ThemeProvider>
    )
}

export default TimelineEntry

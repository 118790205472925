import React from 'react'
import { Grid } from './s'
import Integration from './integration'

const Integrations = ({ integrations, ...props }) => (
    <Grid>
        {integrations.map(integration => (
            <Integration
                {...props}
                {...integration}
                key={`integrations:${integration.type}`} />
        ))}
    </Grid>
)

export default Integrations
import React, { useState, useEffect } from 'react'
import { useInterval } from 'hooks/interval'
import { ColorWrapper, EmojiWrapper, Background, FaderWrap, Fader } from './s'
import { AnimatePresence } from 'framer-motion'
import { Emoji } from 'emoji-mart'
import { cls } from 'utilities/dom'
import { percentageToAngle } from 'utilities/math'

const DisplaySymbol = ({ symbol, icon = null, percentage = 100, size = 40, inline = false, ...props }) => {
    const {
        color = '#f5f4f3',
        colorToken = 'symbol-neutral-1',
        emoji
    } = symbol ?? {}

    const className = cls([
        props.className,
        inline && 'inline'
    ])

    return (
        <ColorWrapper
            {...props}
            {...(className ? { className } : null)}
            style={{ '--symbol-size': `${size}px` }}>
            {!!emoji && <DisplayEmoji emoji={emoji} />}
            {icon}
            <Background
                style={{
                    '--symbol-color': color,
                    '--symbol-color-token': `var(--huma-color-${colorToken ?? 'symbol-neutral-1'})`,
                    '--symbol-angle': `${percentageToAngle(percentage)}deg`
                }} />
        </ColorWrapper>
    )
}

export const DisplayEmoji = ({ className, ...props }) => {
    className = cls([
        className,
        'emoji'
    ])

    return (
        <EmojiWrapper
            {...(className ? { className } : null)}
            dangerouslySetInnerHTML={{
                __html: Emoji({
                    ...props,
                    native: true,
                    html: true
                })
            }} />
    )
}

export const RollingEmojis = ({
    emojis,
    endWithEmoji = 'question',
    interval = 1750,
    repeat = Infinity,
    salt,
    ...props
}) => {
    emojis = emojis ?? rollingEmojis

    const [state, setState] = useState({
        current: emojis[0],
        next: emojis[1],
        loops: 0,
        running: true
    })

    const stop = useInterval(() => {
        const currentIndex = emojis.indexOf(state.current)
        let { loops } = state

        let nextIndex = currentIndex + 1
        if(!emojis[nextIndex]) {
            nextIndex = 0
            loops++
        }

        let nextNextIndex = nextIndex + 1
        if(!emojis[nextNextIndex]) {
            nextNextIndex = 0
        }

        setState(state => ({
            ...state,
            current: emojis[nextIndex],
            next: emojis[nextNextIndex],
            loops
        }))
    }, interval)

    useEffect(() => {
        if(state.running && (repeat || repeat === 0) && repeat !== Infinity && state.loops >= repeat) {
            stop()

            setState(state => ({
                ...state,
                running: false
            }))
        }
    }, [repeat, state.loops, state.running, stop])

    return (
        <FaderWrap>
            <AnimatePresence>
                {!!state.running && emojis.map(emoji => (
                    <Fader
                        animate={(emoji === state.next) ?
                            'queued' :
                            (emoji === state.current) ?
                                'in' :
                                'out'
                        }
                        key={`${salt}:rolling-emojis:${emoji}`}>
                        <DisplayEmoji
                            {...props}
                            emoji={emoji} />
                    </Fader>
                ))}
                {(!state.running && !!endWithEmoji) && (
                    <Fader animate="in">
                        <DisplayEmoji
                            {...props}
                            emoji={endWithEmoji} />
                    </Fader>
                )}
            </AnimatePresence>
        </FaderWrap>
    )
}

const rollingEmojis = [
    'sports_medal',
    'brain',
    'wave',
    'sunglasses',
    'star2',
    'muscle',
    'exploding_head',
    'bulb',
    'partying_face',
    'raised_hands',
]

export default DisplaySymbol
import React, { useEffect } from 'react'
import { useI18n } from 'contexts/i18n'
import { cls } from 'utilities/dom'
import { size } from 'utilities/object'
import {
    TutorialWrapper,
    Content, Words, Title, Video,
    Screenshot, Image
} from './s'
import Paragraph from 'components/typography/paragraph'
import Veed from 'components/veed'
import Loom from 'components/loom'

const ModuleFeature = ({
    id,
    data = {},
    onScreenshotLoaded,
    reversed = false,
    className,
    ...props
}) => {
    const { locale } = useI18n()

    useEffect(() => {
        if(!data?.screenshotUrl && onScreenshotLoaded) {
            onScreenshotLoaded(id)
        }
    }, [data?.screenshotUrl, id])

    if(!data?.content) {
        return null
    }

    const {
        title,
        caption,
        loom,
        veed = []
    } = {
        ...(data.content?.en ?? null),
        ...(data.content?.[locale] ?? null)
    }

    const { screenshotUrl } = data

    const hasVideo = !!size(loom) || !!veed?.length

    className = cls([
        className,
        hasVideo && 'has-video',
        reversed && 'reversed'
    ])

    return (
        <TutorialWrapper
            {...props}
            {...(className ? { className } : null)}>
            <Content>
                <Words>
                    {title && <Title>{title}</Title>}
                    {caption && <Paragraph className="compact">{caption}</Paragraph>}
                </Words>
                {hasVideo && (
                    <Video>
                        {!!veed?.length && (
                            <Veed
                                veed={veed}
                                key={veed[0].id} />
                        )}
                        {(loom && !veed?.length) && <Loom {...loom} />}
                    </Video>
                )}
            </Content>
            <Screenshot {...(!data?.screenshotUrl ? { className: 'empty' } : null)}>
                <Image
                    onLoad={() => onScreenshotLoaded?.(id)}
                    onError={() => onScreenshotLoaded?.(id)}
                    src={screenshotUrl} />
            </Screenshot>
        </TutorialWrapper>
    )
}

export default ModuleFeature
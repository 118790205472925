import React from 'react'
import { useIntl } from 'react-intl'
import { useFlash } from 'hooks/flash'
import { isNumeric } from 'utilities/math'
import { useCheckViewLevelAccess } from 'pages/competence/utilities'
import {
    RecordWrapper,
    CompetenceCell,
    StatusCell, ExpiryStatus, InlineMessage,
    ActionsCell
} from './s'
import Rating from 'components/rating'
import NameDetails from './name-details'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'

const CompetenceRecord = ({ record, actions = {}, flash, ...props }) => {
    const { formatMessage } = useIntl()

    const flasher = useFlash(flash)

    const {
        id,
        type,
        category,
        attachments,
        links,
        level,
        validFrom,
        validTo
    } = record

    const {
        documentationRequired,
        validToRequired
    } = type

    const viewLevelAccess = useCheckViewLevelAccess()(record)

    const missingDocumentation = (!!documentationRequired && !attachments?.length && !links?.length)

    const view = actions?.view?.(record)
    const edit = actions?.edit?.(record)

    const boundActions = getBoundActions(actions, record)

    const salt = `competence:record:${id}`

    return (
        <RecordWrapper ref={flasher}>
            <CompetenceCell>
                <NameDetails
                    {...props}
                    record={record}
                    view={view}
                    edit={edit} />
            </CompetenceCell>
            <StatusCell>
                {(isNumeric(level) && !missingDocumentation) && <Rating value={level} />}
                {(!!viewLevelAccess && !isNumeric(level) && ['skill', 'language'].includes(category)) && (
                    <InlineMessage
                        type="warning"
                        message={formatMessage({
                            id: 'competence_status_missing_level',
                            defaultMessage: 'Missing level'
                        })} />
                )}
                {category === 'certification' && (
                    <>
                        <ExpiryStatus
                            from={validFrom}
                            to={validTo}
                            showActive={true}
                            context="competence" />
                        {(!validTo && validToRequired) && (
                            <InlineMessage
                                message={formatMessage({
                                    id: 'competence_error_missing_expiry_date',
                                    defaultMessage: 'No expiry date'
                                })}
                                type="error" />
                        )}
                    </>
                )}
                {!!missingDocumentation && (
                    <InlineMessage
                        message={formatMessage({
                            id: 'qualification_status_documentation_missing',
                            defaultMessage: 'Documentation missing'
                        })}
                        type="error" />
                )}
            </StatusCell>
            {!!boundActions?.length && (
                <ActionsCell>
                    <ContextMenu
                        prebound actions={boundActions}
                        salt={salt} />
                </ActionsCell>
            )}
        </RecordWrapper>
    )
}

export default CompetenceRecord

import React from 'react'
import styled from 'styled-components'

const Divider = styled.hr`
    margin-block: ${p => p.$size ? `${p.$size}px` : '16px'};
    border: 0;
    width: 100%;
    height: 1px;
    background-color: var(--huma-color-border-default);

    &.not-start {
        margin-block-start: 0;
    }

    &.not-end {
        margin-block-end: 0;
    }
`

const TextDividerBackground = styled.div`
    position: relative;
    z-index: 1;

    margin-block: ${p => p.$size ? `${p.$size}px` : '16px'};

    text-align: center;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 0;
        right: 0;

        width: 100%;

        border-top: 1px solid var(--huma-color-border-default);
    }

    span {
        padding-inline: 8px;
        background-color: var(--huma-color-surface-default);

        color: var(--huma-color-foreground-subtle);
    }
`

export const TextDivider = ({ className, children }) => (
    <TextDividerBackground className={className}>
        <span>{children}</span>
    </TextDividerBackground>
)

export default Divider

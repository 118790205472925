import styled, { css } from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Grid, GridColumnHeader as GridColumnHeaderBase } from 'components/grid'
import { assigneeGridCss } from './assignee/s'
import { SkeletonListRow as SkeletonListRowBase } from 'components/skeleton'

export const Wrap = styled.div``

export const Header = styled(Grid)`
    ${assigneeGridCss}

    width: 100%;
    border-bottom: 1px solid var(--huma-color-border-default);

    ${belowTablet`
        display: flex;
        justify-content: end;
    `}
`

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    position: relative;
`

const gridColumnHeaderCss = css`
    padding: 16px 0;
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}

    ${belowTablet`
        display: none;
    `}
`

export const GridColumnActionsHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}

    display: flex;
    justify-content: end;
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    ${assigneeGridCss}
`
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { getIntegrationUrl } from 'utilities/url'
import {
    GridCell, Columns,
    LogoWrapper, Logo, Status, InlineMessage,
    Heading, Link
} from './s'
import Message from 'components/message'
import { FlexChild, FlexChildGrow, FlexChildShrink } from 'components/flex'
import Beta from 'components/beta'
import Paragraph from 'components/typography/paragraph'
import data from 'pages/integrations/pages/integration/data'

const Integration = ({ type, beta, status, name, upgradable }) => {
    const { formatMessage } = useIntl()

    const {
        logo,
        logoDark
    } = data?.[type]?.() ?? {}

    const readMoreAndSetUp = status === 'not_configured'
    const readMoreAndConnect = status === 'configured'

    const statusClassName = (status === 'authorization_failure') ?
        'error' :
        'success'

    return (
        <GridCell>
            <FlexChild>
                <Columns>
                    <FlexChildGrow>
                        {!!logo && (
                            <LogoWrapper>
                                {!!logoDark && (
                                    <Logo
                                        src={logoDark}
                                        alt={name}
                                        className="dark" />
                                )}
                                <Logo
                                    src={logo}
                                    alt={name} />
                            </LogoWrapper>
                        )}
                    </FlexChildGrow>
                    {['configured', 'connected', 'pending', 'active', 'authorization_failure'].includes(status) && (
                        <FlexChildShrink>
                            <Status className={statusClassName}>
                                {(status === 'configured') && (
                                    <InlineMessage
                                        type="success"
                                        message={formatMessage({
                                            id: 'integration_status_configured',
                                            defaultMessage: 'Configured'
                                        })} />
                                )}
                                {(status === 'connected') && (
                                    <InlineMessage
                                        type="success"
                                        message={formatMessage({
                                            id: 'integration_status_connected',
                                            defaultMessage: 'Connected'
                                        })} />
                                )}
                                {(status === 'pending') && (
                                    <InlineMessage
                                        type="success"
                                        message={formatMessage({
                                            id: 'integration_status_pending',
                                            defaultMessage: 'Pending approval'
                                        })} />
                                )}
                                {(status === 'active') && (
                                    <InlineMessage
                                        type="success"
                                        message={formatMessage({
                                            id: 'integration_status_connected_active',
                                            defaultMessage: 'Connected and active'
                                        })} />
                                )}
                                {(status === 'authorization_failure') && (
                                    <InlineMessage
                                        type="error"
                                        message={formatMessage({
                                            id: 'integration_status_connection_error',
                                            defaultMessage: 'Connection error'
                                        })} />
                                )}
                            </Status>
                        </FlexChildShrink>
                    )}
                </Columns>
            </FlexChild>
            <FlexChild>
                <Columns>
                    <FlexChildShrink>
                        <Heading>{name}</Heading>
                    </FlexChildShrink>
                    {!!beta && (
                        <FlexChildShrink>
                            <Beta />
                        </FlexChildShrink>
                    )}
                </Columns>
            </FlexChild>
            <FlexChild>
                <Paragraph>
                    <FormattedMessage
                        id={`integration_${type}_description`}
                        defaultMessage={`About ${name}`} />
                </Paragraph>
                {(status === 'configured') && (
                    <Message
                        type="warning"
                        message={formatMessage({
                            id: 'integration_message_configured',
                            defaultMessage: 'This integration has been set up, but is not active.'
                        })} />
                )}
                {(status === 'connected') && (
                    <Message
                        type="warning"
                        message={formatMessage({
                            id: 'integration_message_manually_sync',
                            defaultMessage: 'You need to <wrapper>synchronize users</wrapper> manually to activate the integration'
                        }, { wrapper: chunks => <em><strong>{chunks}</strong></em> })} />
                )}
                {(status === 'authorization_failure') && (
                    <Message
                        type="error"
                        message={formatMessage({
                            id: 'integration_status_authorization_failure',
                            defaultMessage: 'Something is wrong with your connection to {name}. Please reconnect and sync again.'
                        }, { name })} />
                )}
            </FlexChild>
            <FlexChild>
                <Link
                    to={getIntegrationUrl({ type })}
                    className="constructive">
                    <FormattedMessage
                        id={upgradable ?
                            'action_read_more' :
                            readMoreAndSetUp ?
                                'integration_action_read_more_set_up' :
                                readMoreAndConnect ?
                                    'integration_action_read_more_connect' :
                                    'integration_action_read_more'
                        }
                        defaultMessage={upgradable ?
                            'Read more' :
                            readMoreAndSetUp ?
                                'Read more and set up' :
                                readMoreAndConnect ?
                                    'Read more and connect' :
                                    'Read more'
                        } />
                </Link>
            </FlexChild>
        </GridCell>
    )
}

export default Integration

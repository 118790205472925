import React, { forwardRef, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { outget } from 'api'
import { getNewsArticleUrl } from 'utilities/url'
import {
    Container, Link, Button, Image, Overlay,
    Content, StatusLabel, Byline, Heading,
    Meta, Units
} from './s'
import { Modal } from 'lists/news/modal/s'
import ArticleDetails from 'lists/news/modal'
import { mute } from 'utilities/styled'

const Article = forwardRef(({ article, modal }, ref) => {
    const [showModal, setShowModal] = useState(false)
    const [color, setColor] = useState(null)

    useEffect(() => {
        if(!!article.featuredImage?.['3:2']?.paletteUrl) {
            const getColorFromImgix = async () => {
                const { ok, response: colors } = await outget(article.featuredImage['3:2'].paletteUrl)

                if(ok && colors) {
                    if(colors.dominant_colors.muted_dark?.hex) {
                        setColor(colors.dominant_colors.muted_dark.hex)
                    } else if(colors.dominant_colors.muted?.hex) {
                        setColor(mute('var(--huma-palette-neutral-10)', colors.dominant_colors.muted.hex))
                    }
                }
            }

            getColorFromImgix()
        }
    }, [article.featuredImage?.['3:2']?.paletteUrl])

    const {
        id,
        status,
        title,
        featuredImage,
        author,
        shares
    } = article

    const Trigger = modal ?
        Button :
        Link

    const triggerProps = {
        ...modal && { onClick: () => setShowModal(true) },
        ...!modal && { to: getNewsArticleUrl(article) }
    }

    return (
        <>
            <Container
                className={featuredImage ? 'image' : 'no-image'}
                style={{
                    '--__huma-component-news-card-image': `url(${featuredImage?.['3:2']?.url})`,
                    '--__huma-component-news-card-color': color
                }}
                aria-live="polite"
                ref={ref}>
                <Trigger {...triggerProps}>
                    {!!featuredImage && (
                        <>
                            <Image />
                            <Overlay />
                        </>
                    )}
                    <Content>
                        {/* Category? */}
                        <Byline
                            who={author}
                            size={16} />
                        <Heading
                            as="h2"
                            itemProp="title">
                            {title}
                        </Heading>
                        <Meta>
                            <Units
                                units={shares}
                                publicWhenEmpty={true}
                                inline={true}
                                max={1} />
                        </Meta>
                    </Content>
                    {(status === 'draft') && (
                        <StatusLabel className="passive">
                            <FormattedMessage
                                id="article_status_draft"
                                defaultMessage="Draft" />
                        </StatusLabel>
                    )}
                </Trigger>
            </Container>
            {!!modal && (
                <Modal
                    className="large"
                    show={!!showModal}
                    dismiss={() => setShowModal(false)}>
                    {!!showModal && (
                        <ArticleDetails
                            id={id}
                            close={() => setShowModal(false)} />
                    )}
                </Modal>
            )}
        </>
    )
})

export default Article

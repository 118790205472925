import styled, { keyframes } from 'styled-components'
import { motion } from 'framer-motion'
import { darkMode } from 'utilities/styled'
import ParagraphBase from 'components/typography/paragraph'
import ActionsBase from 'components/form/actions'

export const Card = styled(motion.div).attrs(() => ({
    variants: {
        hide: {
            opacity: 0,
            y: 8
        },
        show: {
            opacity: 1,
            y: 0
        }
    },
    transition: {
        type: 'spring',
        mass: .5,
        stiffness: 100,
        velocity: 2
    },
    initial: 'hide',
    exit: 'hide'
}))`
    border-radius: 8px;
    border: 1px solid var(--huma-color-border-default);
    padding: 16px;

    &:first-child {
        margin-block-start: auto;
    }
`

export const Prompt = styled(ParagraphBase)`
    --quote-start: '‘';
    --quote-end: '’';

    &::before {
        content: var(--quote-start);
    }

    &::after {
        content: var(--quote-end);
    }
`

export const Content = styled(motion.div).attrs(() => ({
    variants: {
        loading: {
            height: 116
        },
        loaded: {
            height: 'auto'
        }
    },
    initial: 'loading',
    exit: 'loading'
}))`
    position: relative;

    width: 100%;
    min-height: 72px;

    transition: height 0.4s ease-in-out, min-height 0.4s ease-in-out;
`

export const Loader = styled(motion.div).attrs(() => ({
    variants: {
        loading: {
            zIndex: 1,
            transition: {
                staggerChildren: 0.05,
                staggerDirection: 1
            }
        },
        loaded: {
            zIndex: -1,
            transition: {
                delay: 0.1,
                staggerChildren: 0.05,
                staggerDirection: 1
            }
        }
    },
    initial: 'loading',
    exit: 'loaded'
}))`
    position: absolute;
    bottom: 1px;

    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 4px;

    width: inherit;
`

const skeletonLoader = keyframes`
    0% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
`

export const LoaderItem = styled(motion.span).attrs(() => ({
    variants: {
        loading: {
            opacity: 1,
            scaleY: 1,
            transition: {
                duration: 0.05,
                ease: 'easeInOut'
            }
        },
        loaded: {
            opacity: 0,
            scaleY: 0,
            transition: {
                duration: 0.05,
                ease: 'easeInOut'
            }
        }
    }
}))`
    pointer-events: none;

    display: block;

    border-radius: 4px;
    height: 20px;
    background-attachment: fixed;
    background-image: linear-gradient(
        90deg,
        var(--huma-color-surface-constructive-subtle),
        var(--huma-color-surface-destructive-subtle),
        var(--huma-color-surface-warning-subtle),
        var(--huma-color-surface-success-subtle),
        var(--huma-color-surface-constructive-subtle)
    );
    background-size: 400% 400%;

    animation: ${skeletonLoader} 2s linear infinite alternate;
    transform-origin: 100% 100%;

    &:nth-child(1) {
        width: 90%;
    }

    &:nth-child(2) {
        width: 50%;
    }

    &:nth-child(3) {
        width: 70%;
    }
`

const generate = keyframes`
    to {
        background-size: 150% 100%;
    }
`

export const Paragraph = styled(motion(ParagraphBase)).attrs(() => ({
    variants: {
        loading: {
            height: 0
        },
        loaded: {
            height: 'auto'
        }
    },
    transition: {
        duration: 0.4
    },
    exit: 'loading'
}))`
    --huma-component-dialogue-item-paragraph: var(--huma-palette-purple-70);

    overflow: hidden;
    white-space: pre-line;

    > .generated {
        background-clip: text;
        background-repeat: no-repeat;
        background-size: 0% 100%;
        background-image: linear-gradient(
            to right,
            var(--huma-color-foreground-default),
            var(--huma-color-foreground-default) 50%,
            var(--huma-component-dialogue-item-paragraph)
        );

        animation: ${generate} 3s linear forwards;
        animation-delay: 2s;

        color: transparent;

        ${darkMode`
            --huma-component-dialogue-item-paragraph: var(--huma-palette-purple-40);
        `}
    }
`

export const Actions = styled(motion(ActionsBase)).attrs(() => ({
    variants: {
        loading: {
            opacity: 0,
            height: 0
        },
        loaded: {
            opacity: 1,
            height: 'auto',
            transition: {
                delay: 0.35,
                duration: 0.2
            }
        }
    },
    initial: 'loading',
    exit: 'loaded'
}))``

export const Helper = styled(ParagraphBase)`
    margin-block: 16px 0;
`
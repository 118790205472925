import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useContentAssistant } from 'contexts/content-assistant'
import { cls } from 'utilities/dom'
import {
    Card,
    Prompt, Content, Loader, LoaderItem, Paragraph, Actions, Helper
} from './s'
import { Button, Plain, Simple } from 'components/button'
import ContextMenu from 'widgets/context-menu'
import { Trash2 as Remove } from 'styled-icons/feather'

const DialogueItem = ({ callback, hasValue = false, message, setCurrentLocale, scroll, index, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const {
        messages = [],
        retryLatestPrompt,
        abortPrompt,
        removePrompt,
        loading: loadingAssistant,
        userAborted
    } = useContentAssistant()

    const {
        prompt,
        output,
        error = false
    } = message ?? {}

    const [isPresent, setIsPresent] = useState(true)

    const loading = loadingAssistant && !output
    const animate = (message && isPresent) ? 'show' : 'hide'

    if(!message || !isPresent || (loading && error)) {
        return null
    }

    const replaceAction = () => ({
        salt: `${salt}:replace`,
        label: formatMessage({
            id: 'assistant_action_replace',
            defaultMessage: 'Replace'
        }),
        effect: 'neutral',
        onClick: () => callback(output, 'replace')
    })

    const appendAction = () => ({
        salt: `${salt}:append`,
        label: formatMessage({
            id: 'assistant_action_append',
            defaultMessage: 'Append'
        }),
        effect: 'neutral',
        onClick: () => callback(output, 'append')
    })

    const actions = {
        replace: replaceAction,
        append: appendAction
    }

    const isDeletable = messages.length > 1 && index === messages.length - 1

    const errorMessage = formatMessage({
        id: userAborted ?
            'assistant_message_operation_cancelled' :
            'error_generic',
        defaultMessage: userAborted ?
            'Cancelled' :
            'Something went wrong 🤷‍♀️ {message}'
    }, { message: '' })

    return (
        <Card
            {...props}
            animate={animate}>
            {!!prompt && (
                <Prompt
                    className="caption small"
                    style={{
                        '--quote-start': `"${formatMessage({
                            id: 'quotation_level_1_start',
                            defaultMessage: '“'
                        })}"`,
                        '--quote-end': `"${formatMessage({
                            id: 'quotation_level_1_end',
                            defaultMessage: '”'
                        })}"`
                    }}>
                    {prompt}
                </Prompt>
            )}
            <Content animate={loading ? 'loading' : 'loaded'}>
                {/* {(!!prompt && !error) && (
                    <Message
                        type="warning"
                        className="boxed"
                        message={formatMessage({
                            id: 'assistant_intro_chatgpt_model_info',
                            defaultMessage: 'ChatGPT may produce inaccurate information about people, places, or facts.'
                        })}
                        animate={loading ? 'loading' : 'loaded'} />
                )} */}
                <Loader animate={loading ? 'loading' : 'loaded'}>
                    <LoaderItem />
                    <LoaderItem />
                    <LoaderItem />
                    {loading && (
                        <Plain
                            className="constructive"
                            onClick={abortPrompt}>
                            <FormattedMessage
                                id="assistant_action_stop"
                                defaultMessage="Stop generating" />
                        </Plain>
                    )}
                </Loader>
                <Paragraph
                    className={cls([
                        !!error && 'error',
                        'compact'
                    ])}
                    animate={loading ? 'loading' : 'loaded'}
                    initial={!!prompt ? 'loaded' : 'loading'}>
                    {!error && <span className="generated">{output}</span>}
                    {error && errorMessage}
                </Paragraph>
                {!!error && (
                    <Plain
                        className="constructive"
                        onClick={() => {
                            scroll.timeoutRef.current = setTimeout(scroll.action, 50)
                            retryLatestPrompt()
                            setIsPresent(false)
                        }}>
                        <FormattedMessage
                            id="assistant_action_retry"
                            defaultMessage="Retry" />
                    </Plain>
                )}
                {(!!prompt && !error) && (
                    <Actions
                        className={(isDeletable && !error) ? 'spread' : 'start'}
                        animate={loading ? 'loading' : 'loaded'}>
                        {(!!hasValue && !error) && (
                            <ContextMenu
                                salt={salt}
                                actions={actions}
                                trigger={(
                                    <Button
                                        className="constructive"
                                        disabled={loading}>
                                        <FormattedMessage
                                            id="assistant_action_insert_options"
                                            defaultMessage="Insert…" />
                                    </Button>
                                )} />
                        )}
                        {!hasValue && (
                            <Button
                                className="constructive"
                                disabled={loading}
                                onClick={() => callback(output, 'replace')}>
                                <FormattedMessage
                                    id="assistant_action_insert"
                                    defaultMessage="Insert" />
                            </Button>
                        )}
                        {isDeletable && (
                            <Simple
                                onClick={() => {
                                    const previousLocale = messages
                                        .slice(0, messages.indexOf(message))
                                        .toReversed()
                                        .find(({ fromLocale }) => fromLocale)?.fromLocale

                                    if(previousLocale) {
                                        setCurrentLocale(previousLocale)
                                    }

                                    removePrompt(message.id)
                                    setIsPresent(false)
                                }}
                                className="destructive"
                                icon={Remove} />
                        )}
                    </Actions>
                )}
                {(!prompt && !loading) && (
                    <Helper className="caption small">
                        <FormattedMessage
                            id="assistant_message_preexisting_value"
                            defaultMessage="How do you want to edit the content?" />
                    </Helper>
                )}
            </Content>
        </Card>
    )
}

export default DialogueItem

import React from 'react'
import { useReveal } from 'hooks/reveal'
import { ValueDisplay } from 'components/form/field/s'
import { cls } from 'utilities/dom'

const Obscured = ({ text, className, children = null, ...props }) => {
    const [node, reveal] = useReveal(true)

    className = cls([
        className,
        'obscured',
        reveal && 'revealed'
    ])

    return (
        <ValueDisplay
            {...props}
            {...(className ? { className } : null)}
            x-ms-format-detection="none"
            ref={node}>
            {text}
            {children}
        </ValueDisplay>
    )
}

export default Obscured

import React, { forwardRef } from 'react'
import { getJobTitle } from 'utilities/person'
import {
    PersonWrapper,
    PersonCell, Person, Cell, ActionsCell,
    EntitiesContainer
} from './s'
import { TriggerTexts as Entities } from 'components/entities'
import Caption from 'components/typography/caption'
import ContextMenu from 'widgets/context-menu'

const PersonItem = forwardRef(({ person, actions, columns }, ref) => {
    const { locations, teams, phone, email } = person
    const salt = `list:people:grid:person:${person.id}`

    return (
        <PersonWrapper
            $simple={(!locations && !teams)}
            $centered={!getJobTitle(person)}
            ref={ref}>
            <PersonCell>
                <Person
                    who={person}
                    asLink={true}
                    showPosition={true}
                    truncate={true}
                    size={40} />
            </PersonCell>
            {columns.map(({ id }) => (
                <Cell key={`${salt}:column:${id}`}>
                    {(id === 'locations' && !!locations?.length) && (
                        <EntitiesContainer>
                            <Entities
                                entities={locations}
                                preview={true}
                                salt={`${salt}:locations`} />
                        </EntitiesContainer>
                    )}
                    {(id === 'teams' && !!teams?.length) && (
                        <EntitiesContainer>
                            <Entities
                                entities={teams}
                                preview={true}
                                salt={`${salt}:teams`} />
                        </EntitiesContainer>
                    )}
                    {(id === 'phonenumber' && phone) && (
                        <Caption className="compact">{phone}</Caption>
                    )}
                    {(id === 'email' && email) && (
                        <Caption className="compact">{email}</Caption>
                    )}
                </Cell>
            ))}
            {(!!actions && !!actions.length) && (
                <ActionsCell>
                    <ContextMenu
                        salt={salt}
                        context={person}
                        actions={actions} />
                </ActionsCell>
            )}
        </PersonWrapper>
    )
})

export default PersonItem
import styled, { css } from 'styled-components'
import { fontSans, fontSerif, fontMonospace } from 's'

export const textCss = css`
    position: relative;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    width: 100%;
    height: 40px;
    padding-inline: 12px;
    background: none;

    transition: border-color .1s ease-in-out, box-shadow .1s ease-in-out;

    line-height: 40px;
    color: var(--huma-color-foreground-default);

    -webkit-appearance: none;

    ::selection {
        background-color: var(--huma-color-border-constructive-focus);
    }

    &.big {
        &,
        &:-webkit-autofill::first-line {
            font-size: 20px;
            font-weight: 500;
        }
    }

    &.huge {
        ${fontSerif}

        height: 64px;
        line-height: 63px;

        &,
        &:-webkit-autofill::first-line {
            font-size: 32px;
            font-weight: 500;
        }
    }

    &.obscured {
        &,
        & > * {
            transition: all .5s ease-in-out;

            text-shadow: 0 0 8px var(--huma-color-foreground-subtle);
            text-indent: 8px;
            color: transparent;
        }

        &:focus,
        &:focus > *,
        &.interacting,
        &.interacting > * {
            transition: all .15s ease-in-out;

            text-shadow: none;
            text-indent: 0;
            color: var(--huma-color-foreground-default);
        }
    }

    &.suggestive {
        background-color: transparent;
        background-image: var(--huma-select-arrow-url);
        background-repeat: no-repeat;
        background-position: right 6px top 50%, 0 0;
        background-size: 10px auto, 100%;
    }

    &.unsettable {
        padding-inline-end: 40px;
    }

    &.monospace {
        &,
        &:-webkit-autofill::first-line {
            ${fontMonospace}
            font-size: 14px;
        }
    }

    &:is(:disabled, .disabled) {
        cursor: default;

        border-color: var(--huma-color-border-default);
        background-color: var(--huma-color-surface-disabled);

        color: var(--huma-color-foreground-disabled);
    }

    &:where(:not(:disabled, .disabled)) {
        &:hover {
            border-color: var(--huma-color-border-default-focus);
        }

        &:focus {
            border-color: var(--huma-color-border-default);
            outline: none;
            box-shadow:
                0 0 0 1px var(--huma-color-surface-default),
                0 0 0 3px var(--huma-color-border-constructive-focus);

            ::selection {
                background-color: var(--huma-color-border-constructive-focus);
            }
        }

        &:invalid,
        &.error,
        .control.error & {
            border-color: var(--huma-color-border-destructive);

            &:focus {
                box-shadow:
                    0 0 0 1px var(--huma-color-surface-default),
                    0 0 0 3px var(--huma-color-border-destructive-focus);

                ::selection {
                    background-color: var(--huma-color-border-destructive-focus);
                }
            }
        }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }

    &:not(.monospace):-webkit-autofill::first-line {
        ${fontSans}
        font-size: 16px;
    }

    &[type="search"] {
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            ${'' /* https://stackoverflow.com/a/9422689 */}
            -webkit-appearance: none;
        }
    }

    &[type="number"] {
        appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &[list]::-webkit-calendar-picker-indicator {
        line-height: 16px;
        font-size: 14px;
        color: var(--huma-color-foreground-subtle);
    }
`

export default styled.input.attrs(({
    type = 'text',
    tabIndex = 0,
    autoComplete = 'off',
    'data-1p-ignore': data1pIgnore = 'true',
    autoFocus = false,
    spellCheck = false,
    ...attributes
}) => ({
    type,
    tabIndex,
    autoComplete,
    'data-1p-ignore': data1pIgnore,
    autoFocus,
    spellCheck,
    ...attributes
}))`
    ${textCss}
`

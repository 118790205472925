import React from 'react'
import View from './view'
import Edit from './edit'

const LanguageField = ({ name, salt, ...props }) => {
    const {
        field = {},
        mode = 'edit'
    } = props

    const { editable = true } = field

    props = {
        ...props,
        field,
        name,
        salt: `${salt}:${name}`
    }

    if(mode === 'edit' && editable) {
        return <Edit {...props} />
    }

    return <View {...props} />
}

export default LanguageField
import styled from 'styled-components'
import { Grid } from 'components/grid'
import Text from 'components/form/input/text'

export const MatchedCardLogo = styled.span`
    display: flex;
    place-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    left: 12px;
`

export const NumberInput = styled(Text)`
    padding-inline-start: 44px;
`

export const Input = Text

export const Columns = styled(Grid)`
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
`

export const SupportedCardLogos = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    column-gap: 12px;
    justify-content: end;
    margin: -16px 0 24px;
`
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useRole } from 'contexts/role'
import { cls } from 'utilities/dom'
import Caption from 'components/typography/caption'

const Details = () => {
    const { role } = useRole()

    if(!role) {
        return null
    }

    return (
        <Caption className={cls([!role.grants.length && 'compact'])}>
            <FormattedMessage
                id={(role.domain === 'user') ?
                    'role_user_grants_information' :
                    'role_organization_grants_information'
                }
                defaultMessage={(role.domain === 'user') ?
                    '{count} persons have been granted this role over members of various Teams and Locations' :
                    '{count} persons have been granted this role organization-wide'
                }
                values={{ count: role.grants.length }} />
        </Caption>
    )
}

export default Details
import styled from 'styled-components'
import { Field as FieldBase } from 'components/form/field/s'
import { flexColumnsCss, flexRowsCss, flexChildCss, flexChildShrinkCss } from 'components/flex'
import { Plain } from 'components/button'

export const Field = FieldBase

export const Switch = styled.div`
    ${Field}.boolean:not(:has(${Field})) & {
        ${flexColumnsCss}
    }

    *:where(form.horizontal) ${Field}:not(.boolean):not(:has(${Field})) &,
    ${Field}.horizontal:not(.boolean):not(:has(${Field})) &,
    &.horizontal {
        ${flexColumnsCss}
    }

    *:where(form:not(.horizontal)) ${Field}:not(.boolean):not(:has(${Field})) &,
    ${Field}.vertical:not(.boolean):not(:has(${Field})) &,
    &.vertical {
        ${flexRowsCss}
    }

    justify-content: flex-start;
`

export const PredefinedItems = styled.div`
    ${flexChildShrinkCss}
`

export const Options = styled.div`
    ${flexColumnsCss}
    display: inline-flex;
    align-items: center;

    border-radius: 8px;
    height: 40px;
    background-color: var(--huma-color-surface-minimal);
    box-shadow: 0 0 0 1px var(--huma-color-surface-minimal);
`

export const Option = styled.div`
    ${flexChildShrinkCss}
    position: relative;

    &:not(.checked) + &:not(.checked)::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        display: block;

        width: 1px;
        height: 24px;
        background-color: var(--huma-color-border-default);
    }
`

export const RadioInput = styled.input.attrs({ type: 'radio' })`
    position: absolute;
    opacity: 0;
    pointer-events: none;
`

export const RadioLabel = styled.label`
    cursor: pointer;
    display: block;
    user-select: none;

    border-radius: 8px;
    padding: 0 16px;

    transition: .1s ease-in-out;
    transition-property: background-color, color;

    line-height: 40px;
    text-align: center;
    color: var(--huma-color-foreground-subtle);

    ${RadioInput}:not(:disabled):not(:checked) + &:hover,
    ${RadioInput}:not(:disabled):focus + & {
        color: var(--huma-color-foreground-default);
    }

    ${RadioInput}:checked + & {
        background-color: var(--huma-color-surface-default);
        box-shadow: var(--huma-shadow-lowest);

        color: var(--huma-color-foreground-default);
    }

    ${RadioInput}:disabled + & {
        cursor: default;
    }
`

export const CustomItem = styled.div`
    ${flexChildCss}

    ${Field}.boolean:not(:has(${Field})) & {
        margin-inline-start: 24px;
    }

    *:where(form.horizontal) ${Field}:not(.boolean):not(:has(${Field})) &,
    ${Field}.horizontal:not(.boolean):not(:has(${Field})) &,
    &.horizontal {
        margin-inline-start: 24px;
    }

    *:where(form:not(.horizontal)) ${Field}:not(.boolean):not(:has(${Field})) &,
    ${Field}.vertical:not(.boolean):not(:has(${Field})) &,
    &.vertical {
        padding-top: 8px;
        margin: 0;
    }
`

export const ResetButton = styled(Plain)`
    ${flexChildShrinkCss}

    ${Field}.boolean:not(:has(${Field})) & {
        margin-inline-start: 24px;
    }

    *:where(form.horizontal) ${Field}:not(.boolean):not(:has(${Field})) &,
    ${Field}.horizontal:not(.boolean):not(:has(${Field})) &,
    &.horizontal {
        margin-inline-start: 24px;
    }

    *:where(form:not(.horizontal)) ${Field}:not(.boolean):not(:has(${Field})) &,
    ${Field}.vertical:not(.boolean):not(:has(${Field})) &,
    &.vertical {
        margin-top: 8px;
    }
`

export const ErrorMessage = styled.p`
    position: absolute;
    top: 100%;
    left: 0;

    font-size: 12px;
    line-height: 2;
    color: var(--huma-color-foreground-destructive);
`

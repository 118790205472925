import styled from 'styled-components'
import ActionsBase from 'components/form/actions'
import MessageBase from 'components/message'

export const Actions = styled(ActionsBase)`
    &:not(.compact) {
        margin-bottom: 24px;
    }

    padding: 0;
`

export const Message = styled(MessageBase)`
    &:not(.compact) {
        margin-bottom: 24px;
    }
`
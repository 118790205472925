import styled from 'styled-components'
import { belowTablet, numbers } from 'utilities/styled'
import { motion } from 'framer-motion'

const belowPhoneVariants = {
    out: {
        top: '33.33vh',
        opacity: 0,
        pointerEvents: 'none',
        transition: {
            type: 'spring',
            mass: 0.25,
            stiffness: 200,
            velocity: 4,
            damping: 10
        }
    },
    in: {
        top: 0,
        opacity: 1,
        pointerEvents: 'auto',
        transition: {
            type: 'spring',
            mass: 0.5,
            stiffness: 100,
            velocity: 2
        }
    }
}

const fromPhoneVariants = {
    out: {
        opacity: 0,
        pointerEvents: 'none',
        transition: {
            duration: .15,
        }
    },
    in: {
        opacity: 1,
        pointerEvents: 'auto',
        transition: {
            duration: .15
        }
    }
}

export const Container = styled(motion.div).attrs(() => ({
    variants: document.documentElement.offsetWidth < numbers.phone ?
        belowPhoneVariants :
        fromPhoneVariants,
    initial: 'out',
    exit: 'out',

    onChange: e => {
        e.stopPropagation()
    }
}))`
    position: relative;

    display: grid;
    place-self: center;

    margin: 16px;
    width: 100%;
    height: auto;
    max-height: calc(100dvh - 32px);

    color: var(--huma-color-foreground-default);

    ${belowTablet`
        margin: 32px 0 0;
        width: 100vw;
    `}
`
import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { Grid } from 'components/grid'

export const Links = styled(Grid)`
    grid-auto-columns: columns;
    grid-auto-flow: column;
    gap: 16px;
    justify-content: start;

    ${belowPhone`
        grid-auto-rows: auto;
        grid-auto-flow: row;
        gap: 8px;
    `}
`
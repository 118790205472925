import React from 'react'
import { useIntl } from 'react-intl'
import { Plain, Link, AvatarWrapper, Deactivated, Details, Name, Position } from './s'
import DefaultAvatar from 'components/avatar'
import Tooltip from 'components/tooltip'
import { getFullName, getShortName, getJobTitle } from 'utilities/person'
import { getPeopleProfileUrl } from 'utilities/url'
import { cls } from 'utilities/dom'

const Person = ({
    who,
    nameAs: NameRenderer = Name,
    shortName = false,
    avatarOverride,
    ...props
}) => {
    const { formatMessage } = useIntl()

    if(!who) {
        return null
    }

    const {
        asLink = false,
        showPosition = false,
        oneLine = false,
        size = 24,
        nowrap = true,
        truncate = false,
        ...attributes
    } = props

    const small = size <= 16
    const large = size >= 40

    const className = cls([
        attributes?.className,
        small && 'small',
        large && 'large',
        truncate && 'truncate'
    ])

    const Person = asLink ?
        Link :
        Plain

    const avatar = avatarOverride ?? (
        <DefaultAvatar
            who={who}
            size={size} />
    )

    const name = shortName ?
        getShortName(who) :
        getFullName(who)

    const position = getJobTitle(who)?.name

    const { status } = who ?? {}
    const isDeactivated = status?.active === false

    const detailsClassName = cls([
        nowrap && 'nowrap',
        truncate && 'truncate',
        oneLine && 'one-line',
        isDeactivated && 'deactivated'
    ])

    return (
        <Person
            {...attributes}
            {...(asLink ? { to: getPeopleProfileUrl(who) } : null)}
            {...(className ? { className } : null)}
            size={size}>
            <AvatarWrapper>
                {isDeactivated && (
                    <Tooltip
                        content={formatMessage({
                            id: 'person_status_deactivated',
                            defaultMessage: 'This account has been deactivated'
                        })}>
                        <Deactivated size={size} />
                    </Tooltip>
                )}
                {avatar}
            </AvatarWrapper>
            <Details>
                <NameRenderer className={detailsClassName}>{name}</NameRenderer>
                {(!!showPosition && position) && (
                    <Position className={detailsClassName}>{position}</Position>
                )}
            </Details>
        </Person>
    )
}

export const AnonymousPerson = ({ role, showJobTitle = true, ...props }) => {
    const { formatMessage } = useIntl()

    return (
        <Person
            {...props}
            who={{
                id: 'anonymous',
                givenName: role,
                ...(showJobTitle ? {
                    jobTitle: {
                        name: formatMessage({
                            id: 'state_anonymous',
                            defaultMessage: 'Anonymous'
                        })
                    }
                } : null)
            }}
            showPosition={true} />
    )
}

export default Person
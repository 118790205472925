import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { useNavigate } from 'react-router-dom'
import { useStorage } from 'hooks/storage'
import { nameToQuickGuideMap } from 'utilities/categories'
import { compact } from 'utilities/array'
import { cls } from 'utilities/dom'
import paths from 'app/paths'
import { get } from 'utilities/object'
import {
    Wrapper, TutorialWrapper, CloseButton,
    Words, Actions,
    Screenshot, Image,
    KnowledgeBase, KnowledgeBaseText, KnowledgeBaseTitle, Url
} from './s'
import UpgradeCTA from 'components/upgrade-cta'
import ModuleFeature from './module-feature'
import ReadMore from './read-more'
import Paragraph from 'components/typography/paragraph'
import { Plain } from 'components/button'
import Faq from 'components/faq'
import { HelpCircle, ExternalLink, Plus } from 'styled-icons/feather'

const Tutorial = ({
    which,
    show = true,
    upgrade,
    className,
    faqProps = null,
    linkLast = false,
    ...props
}) => {
    const { formatMessage } = useIntl()
    const { locale } = useI18n()

    const [imageLoaded, setImageLoaded] = useState(false)

    const { tutorials } = useServiceOnboarding()
    const tutorial = tutorials[which] ?? tutorials[nameToQuickGuideMap(which)]

    if(!tutorial?.content) {
        return null
    }

    const {
        readMore,
        knowledgeBaseUrl
    } = tutorial

    const {
        title,
        faq
    } = {
        ...tutorial.content.en,
        ...tutorial.content[locale]
    }

    const { message } = {
        ...(tutorial.upgrade?.en || null),
        ...(tutorial.upgrade?.[locale] || null)
    }

    const animate = (show && imageLoaded) ? 'in' : 'out'

    const KnowledgeBaseLink = () => {
        if(!knowledgeBaseUrl) {
            return null
        }

        return (
            <KnowledgeBase
                href={knowledgeBaseUrl}
                target="_blank">
                <HelpCircle size={24} />
                <KnowledgeBaseText>
                    <KnowledgeBaseTitle className="compact">
                        <FormattedMessage
                            id="knowledge_base_action_navigate_module"
                            defaultMessage="More about {moduleName} in our Knowledge Base"
                            values={{ moduleName: formatMessage({
                                id: `noun_${which}`,
                                defaultMessage: which
                            }) }} />
                    </KnowledgeBaseTitle>
                    <Url className="compact">{knowledgeBaseUrl}</Url>
                </KnowledgeBaseText>
                <ExternalLink size={16} />
            </KnowledgeBase>
        )
    }

    return (
        <Wrapper animate={animate}>
            {!!upgrade && (
                <UpgradeCTA
                    {...(message ? { message } : null)}
                    {...((!message && !upgrade?.useFeatureDescription) ? { message: title } : null)}
                    responsive
                    useUpgradeIcon
                    useFeatureOrModuleIcon
                    {...upgrade}
                    className="compact"
                    salt={`tutorial:upgrade:${which}`} />
            )}
            <ModuleFeature
                {...props}
                data={tutorial}
                onScreenshotLoaded={() => setImageLoaded(true)}
                className={className} />
            {!!readMore && <ReadMore item={readMore} />}
            {!linkLast && <KnowledgeBaseLink />}
            {!!faq?.length && (
                <Faq
                    {...faqProps}
                    items={faq} />
            )}
            {!!linkLast && <KnowledgeBaseLink />}
        </Wrapper>
    )
}

export const DashboardTutorial = ({ which, showActions = true, className, salt, ...props }) => {
    const { locale } = useI18n()

    const { tutorials } = useServiceOnboarding()

    const navigate = useNavigate()

    const [dismissed, setDismissed] = useStorage({
        key: `dismissed:dashboard:${which}`,
        defaultValue: false
    })

    const tutorial = tutorials[which]
    if(!tutorial?.dashboard || dismissed) {
        return null
    }

    const {
        screenshotUrl,
        actions
    } = tutorial

    const content = {
        ...tutorial.dashboard.en,
        ...tutorial.dashboard[locale]
    }

    const { text } = content

    className = cls([
        className,
        'dashboard'
    ])

    return (
        <TutorialWrapper
            className={className}
            {...props}>
            <CloseButton onClick={() => setDismissed(true)} />
            <Words>
                <Paragraph className={cls([!actions?.length && 'compact'])}>{text}</Paragraph>
                {(!!actions?.length && !!showActions) && (
                    <Actions className="compact">
                        {actions.map(({ key, path, intent, text }) => {
                            const resolvedPath = get(paths, path)
                            if(!resolvedPath) {
                                return null
                            }

                            text = text?.[locale] ?? text?.en

                            const args = compact([
                                resolvedPath,
                                !!intent && { state: { intent } }
                            ])

                            return (
                                <Plain
                                    className="constructive"
                                    onClick={() => navigate(...args)}
                                    key={`${salt}:action:${key}`}>
                                    <Plus size={24} />
                                    {text}
                                </Plain>
                            )
                        })}
                    </Actions>
                )}
            </Words>
            <Screenshot>
                <Image src={screenshotUrl} />
            </Screenshot>
        </TutorialWrapper>
    )
}

export default Tutorial
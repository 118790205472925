import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { useForm } from 'components/form/controller'
import { cls } from 'utilities/dom'
import { pick } from 'utilities/object'
import {
    Field, Label, Control,
    Option, OptionHeaderColumns, OptionHeaderLabelColumn, OptionHeaderRadioColumn,
    OptionContent, InnerPadding
} from './s'
import Radiobutton from 'components/form/input/radiobutton'
import { ArrowUpCircle as Upgrade } from 'styled-icons/feather'
import StatusLabel from 'components/status-label'

const empty = null

class EditOneOf extends Component {
    constructor(props) {
        super(props)

        this.state = this.valueStateFromProps(props)

        this.register()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,
            unset: () => this.setState({ value: empty }),

            validator: value => required ?
                !!value :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value || empty
    })

    select = option => this.setState({ value: option }, () => {
        this.props.onChange?.({ [this.props.name]: option })
    })

    render() {
        const {
            className,
            label,
            name,
            enabled = true,
            loading = false,
            field = {}
        } = this.props

        const touched = this.props.form.touched.includes(name)
        const error = ((name in this.props.form.errors) && touched)

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const {
            options,
            required,
            softRequired,
            optional,
            conscious = false
        } = field

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    {options.map(({
                        label, value, disabled = false,
                        content, recommended = false, upgradable = false,
                        ...option
                    }, index) => {
                        const checked = ('checked' in option) ?
                            option.checked :
                            this.state.value ?
                                this.state.value === value :
                                conscious ?
                                    false :
                                    index === 0

                        const animate = checked ?
                            'expanded' :
                            'contracted'

                        const last = index + 1 === options.length

                        const props = disabled ?
                            { className: 'disabled' } :
                            pick(option, 'onClick')

                        return (
                            <Option
                                {...(last ? { className: 'last' } : null)}
                                key={`option:${value}:checked:${checked}`}>
                                <OptionHeaderColumns {...props}>
                                    <OptionHeaderLabelColumn>
                                        <span>{label}</span>
                                        {!!upgradable && (
                                            <Upgrade
                                                size={16}
                                                className="upgrade" />
                                        )}
                                        {(!upgradable && !!recommended) && (
                                            <StatusLabel
                                                as="span"
                                                className="positive">
                                                <FormattedMessage
                                                    id="noun_recommended"
                                                    defaultMessage="Recommended" />
                                            </StatusLabel>
                                        )}
                                    </OptionHeaderLabelColumn>
                                    <OptionHeaderRadioColumn>
                                        <Radiobutton
                                            name={name}
                                            defaultValue={value}
                                            defaultChecked={checked}
                                            onChange={() => {
                                                this.select(value)

                                                global.requestAnimationFrame(() =>  {
                                                    options.find(option => option.value === value)?.controlRef?.current?.focus()
                                                })
                                            }}
                                            controlProps={{ as: 'p' }}
                                            disabled={disabled || !enabled}
                                            {...(!!option?.ref ? { ref: option.ref } : null)} />
                                    </OptionHeaderRadioColumn>
                                </OptionHeaderColumns>
                                {!!content && (
                                    <OptionContent
                                        animate={animate}
                                        initial={animate}>
                                        <InnerPadding>{content}</InnerPadding>
                                    </OptionContent>
                                )}
                            </Option>
                        )
                    })}
                </Control>
            </Field>
        )
    }
}

export default props => {
    const form = useForm()

    return (
        <EditOneOf
            {...props}
            form={form} />
    )
}
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { sendAppSignal } from 'hooks/app-signal'
import { cls } from 'utilities/dom'
import { getCategoryIcon, categoryToI18nMap } from 'utilities/categories'
import { featureToDescription } from 'pages/settings/pages/modules/content/module/feature'
import SubscriptionManager from './subscription-manager'
import { Important, Layout, Heading, Caption } from './s'
import { ArrowUpCircle } from 'styled-icons/feather'
import { InlineButton, Benjamin } from 'components/button'
import Upgrade from 'pages/settings/components/upgrade'
import { BundleName } from 'components/payment/plan'
import { useGetBundleChangeMeta } from 'utilities/payment'

const UpgradeCTA = ({
    manager,
    module, moduleless = false, feature,
    title = '', message = '',
    content = null,
    responsive = false,
    useUpgradeIcon = false,
    useFeatureOrModuleIcon = false,
    useFeatureTitle = false,
    useFeatureDescription = false,
    className,
    ...props
}) => {
    const { formatMessage } = useIntl()

    const getBundleChangeMeta = useGetBundleChangeMeta()

    if(!module && !feature && !moduleless) {
        return null
    }

    const Icon = (useFeatureOrModuleIcon ?
        getCategoryIcon(feature ?? module) :
        ArrowUpCircle
    ) ?? ArrowUpCircle

    if(!title && !!feature && useFeatureTitle) {
        const titleTranslationId = categoryToI18nMap[feature]

        if(titleTranslationId) {
            title = formatMessage({
                id: titleTranslationId,
                defaultMessage: titleTranslationId
            })

            if(title === titleTranslationId) {
                title = null
            }
        }
    }

    if(!message && !!feature && useFeatureDescription) {
        const descriptionTranslation = featureToDescription[feature]

        if(descriptionTranslation) {
            message = formatMessage(descriptionTranslation)
        }
    }

    className = cls([
        'upgrade-cta',
        'passive',
        className
    ])

    const layoutClassName = cls([
        responsive && 'responsive'
    ])

    const adminMessage = formatMessage({
        id: 'payment_message_unlock_features',
        defaultMessage: 'Upgrade, and unlock more and better features!'
    })

    const nonAdminMessage = formatMessage({
        id: 'payment_message_admin_unlock_features',
        defaultMessage: 'An admin can upgrade, and unlock more and better features.'
    })

    const enterpriseMeta = getBundleChangeMeta({
        track: 'standard',
        bundle: 'enterprise'
    })

    return (
        <Important
            {...props}
            className={className}>
            <Icon size={24} />
            <Layout {...(layoutClassName ? { className: layoutClassName } : null)}>
                <div>
                    <Heading className="compact">
                        {title || formatMessage({
                            id: 'module_message_upgrade_to_access',
                            defaultMessage: 'Upgrade to gain access'
                        })}
                    </Heading>
                    <Caption className="compact">
                        {!manager.meta.access && nonAdminMessage}
                        {!!manager.meta.access && (
                            <>
                                <span>{message || adminMessage}</span>
                                {!moduleless && (
                                    <InlineButton
                                        onClick={() => sendAppSignal('value.show', {
                                            manager,
                                            module,
                                            feature
                                        })}
                                        className="constructive">
                                        <FormattedMessage
                                            id="value_proposition_cta"
                                            defaultMessage="Explore the value" />
                                    </InlineButton>
                                )}
                            </>
                        )}
                    </Caption>
                </div>
                {content}
                {!!manager.meta.access && (
                    <>
                        {!moduleless && (
                            <Upgrade
                                module={module}
                                feature={feature}
                                setManaging={manager.setManaging}
                                useIcon={useUpgradeIcon} />
                        )}
                        {!!moduleless && (
                            <Benjamin onClick={() => manager.setManaging(enterpriseMeta)}>
                                <FormattedMessage
                                    id={enterpriseMeta.change === 'purchase' ?
                                        'payment_plan_action_purchase'
                                        : 'payment_plan_action_upgrade'
                                    }
                                    defaultMessage={enterpriseMeta.change === 'purchase' ?
                                        'Buy {plan}'
                                        : 'Upgrade to {plan}'
                                    }
                                    values={{ plan: <BundleName bundle={enterpriseMeta.to} /> }} />
                            </Benjamin>
                        )}
                    </>
                )}
            </Layout>
        </Important>
    )
}

export default ({ module, moduleless = false, feature, callback, salt, ...props }) => (
    <SubscriptionManager
        module={module}
        feature={feature}
        moduleless={moduleless}
        callback={callback}
        salt={salt}>
        <UpgradeCTA
            {...props}
            module={module}
            feature={feature}
            moduleless={moduleless} />
    </SubscriptionManager>
)
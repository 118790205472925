import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import ExpandableBase from 'components/expandable'
import Paragraph from 'components/typography/paragraph'

export const Expandable = styled(ExpandableBase)`
    margin-block-end: 8px !important;

    &.last {
        margin-block-end: 24px !important;
    }
`

export const ProgressTimeEstimate = styled(Paragraph)`
    ${belowTablet`
        display: none;
    `}
`
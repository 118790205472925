import React from 'react'
import {
    image as imageExtensions,
    spreadsheet as spreadsheetExtensions,
    plaintext as plaintextExtensions
} from 'utilities/file'
import { without } from 'utilities/array'
import Loader from 'components/loader'
import { Main, Content } from './s'
import Pdf from './pdf'
import Image from './image'
import Spreadsheet from './spreadsheet'
import Markdown from './markdown'
import Plaintext from './plaintext'
import Unsupported from './unsupported'

const PreviewDocumentContent = ({ supported, file, objectUrl, raw, extension, salt }) => (
    <Main>
        {(supported === null) && (
            <Content className="loading">
                <Loader className="xl" />
            </Content>
        )}
        {(supported && extension === 'pdf') && (
            <Pdf
                file={file}
                url={objectUrl} />
        )}
        {(supported && imageExtensions.includes(extension)) && (
            <Image
                file={file}
                url={objectUrl} />
        )}
        {(supported && spreadsheetExtensions.includes(extension)) && (
            <Spreadsheet
                file={file}
                raw={raw}
                salt={salt} />
        )}
        {(supported && extension === 'md') && (
            <Markdown
                raw={raw}
                salt={salt} />
        )}
        {(supported && without(plaintextExtensions, ['md']).includes(extension)) && (
            <Plaintext
                raw={raw}
                salt={salt} />
        )}
        {(supported === false) && (
            <Unsupported />
        )}
    </Main>
)

export default PreviewDocumentContent
import styled from 'styled-components'
import { FlexChildShrink } from 'components/flex'
import { Grid } from 'components/grid'

export const Wrap = FlexChildShrink

export const Columns = styled(Grid)`
    grid-template-columns: minmax(0, 1fr) 40px;
    align-items: end;
    column-gap: 24px;
    padding: 0 0 16px;
`

export const Heading = styled.h1`
    font-size: 24px;
    line-height: 40px;
    font-weight: 500;
`
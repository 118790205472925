import React, { useState } from 'react'
import { getActionsArray, getBoundActions } from './'
import { Rows } from 'components/flex'
import List from './list'
import Prompts from './prompts'
import Upgrade from 'modals/upgrade'

const ContextMenu = ({ actions = [], context, prebound = false, salt, ...props }) => {
    const [prompting, setPrompting] = useState(null)
    const [upgrading, setUpgrading] = useState(null)

    actions = prebound ?
        getActionsArray(actions) :
        getBoundActions(actions, context)

    if(!actions.length) {
        return null
    }

    return (
        <>
            <Rows {...props}>
                <List
                    actions={actions}
                    setPrompting={setPrompting}
                    setUpgrading={setUpgrading}
                    inline={true}
                    salt={salt} />
            </Rows>
            <Prompts
                {...prompting}
                actions={actions}
                setPrompting={setPrompting}
                salt={salt} />
            <Upgrade
                {...upgrading}
                dismiss={() => setUpgrading(null)}
                salt={`context-menu:upgrade:${salt}`} />
        </>
    )
}

export default ContextMenu
import styled, { css } from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { CloseButton as CloseButtonBase, buttonStyles } from 'components/button'
import { ModalHeading, H3, H4 } from 'components/typography/heading'
import Paragraph, { paragraphSmallCss } from 'components/typography/paragraph'

export const Big = styled(ModalHeading)`
    margin-block-end: 0;
`

export const Medium = styled(H3)`
    margin-block-end: 0;

    line-height: 24px;
`

export const Small = styled(H4)`
    margin-block-end: 0;
`

export const ModalHeaderWrapper = styled.div`
    margin-block-end: 24px;

    &.compact {
        margin-block-end: 0;
    }

    &.flipped {
        display: flex;
        align-items: center;
        column-gap: 24px;
    }

    &:not(.compact):has(${Small}) {
        margin-block-end: 16px;
    }
`

export const CloseButton = styled(CloseButtonBase)`
    ${p => {
        const offset = p.$offset ?? 24
        const belowTabletOffset = (offset > 8) ?
            offset - 8 :
            offset

        return css`
            ${buttonStyles.closeButtonCss({ offset })}

            ${belowTablet`
                ${buttonStyles.closeButtonCss({ offset: belowTabletOffset })}
            `}
        `
    }}

    ${ModalHeaderWrapper}.flipped & {
        position: static;
    }
`

export const Caption = styled(Paragraph)`
    ${Small} + & {
        ${paragraphSmallCss}
    }
)`
import React from 'react'
import { useIntl } from 'react-intl'
import { Heading, Symbol } from './s'
import Expandable from 'components/expandable'
import CheckboxField from 'components/form/field/checkbox'
import Content from './content'

const CategoryItem = ({ category, template, setTemplate, salt }) => {
    const { formatMessage } = useIntl()

    const {
        id,
        title,
        symbol,
        topics = []
    } = category

    const action = (
        <CheckboxField
            salt={salt}
            className="compact"
            label={false}
            name={`category:${id}`}
            field={{ value: true }}
            interaction="switch"
            controlProps={{ onClick: e => e.stopPropagation() }}
            onChange={state => {
                const value = state[`category:${id}`]

                if(value) {
                    setTemplate(previousTemplate => {
                        const newCategories = previousTemplate?.categories.map(category => {
                            if(category.id === id) {
                                return {
                                    ...category,
                                    enabled: true
                                }
                            }

                            return category
                        })

                        return {
                            ...previousTemplate,
                            categories: newCategories
                        }
                    })
                } else {
                    setTemplate(previousTemplate => {
                        const newCategories = previousTemplate?.categories.map(category => {
                            if(category.id === id) {
                                return {
                                    ...category,
                                    enabled: false
                                }
                            }

                            return category
                        })

                        return {
                            ...previousTemplate,
                            categories: newCategories
                        }
                    })
                }
            }} />
    )

    const willBeIncluded = !!template.categories.find(category => category.id === id && !!category.enabled)

    const heading = (
        <Heading {...( !willBeIncluded ? { className: 'disabled' } : null )}>
            {title}
        </Heading>
    )

    const summary = formatMessage({
        id: willBeIncluded ?
            'handbooks_template_category_topics_summary_included' :
            'handbooks_template_category_topics_summary_not_included',
        defaultMessage: willBeIncluded ?
            '{count, plural, =0 {This category, without any of its topics, will be added} =1 {This category and 1 topic will be added} other {This category and {count} topics will be added}}' :
            'Neither this category nor its {count} topics will be added'
    }, {
        count: willBeIncluded ?
            topics.filter(({ enabled }) => !!enabled).length :
            topics.length
    })

    return (
        <Expandable
            icon={(
                <Symbol
                    symbol={symbol}
                    className="hover-effect" />
            )}
            heading={heading}
            action={action}
            summary={summary}
            callback={() => {
                setTemplate(previousTemplate => {
                    const newCategories = previousTemplate?.categories.map(category => {
                        if(category.id === id) {
                            return {
                                ...category,
                                expanded: !category.expanded
                            }
                        }

                        return category
                    })

                    return {
                        ...previousTemplate,
                        categories: newCategories
                    }
                })
            }}
            key={salt}>
            <Content
                category={category}
                setTemplate={setTemplate}
                salt={salt} />
        </Expandable>
    )
}

export default CategoryItem

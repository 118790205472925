import React, { forwardRef } from 'react'
import { Wrapper, Pill } from './s'

const Pills = forwardRef(({ name, value, options, pick, disabled, salt, ...props }, ref) => (
    <Wrapper
        {...props}
        ref={ref}>
        {options.map(({ value: optionValue, label }) => {
            const checked = value === optionValue

            return (
                <Pill key={`${salt}:${optionValue}:${checked}`}>
                    <input
                        type="radio"
                        name={`${salt}:${name}:radio`}
                        value={optionValue}
                        id={`${salt}-${optionValue}`}
                        onChange={() => pick(optionValue)}
                        checked={checked}
                        disabled={disabled} />
                    <label htmlFor={`${salt}-${optionValue}`}>
                        {label}
                    </label>
                </Pill>
            )
        })}
    </Wrapper>
))

export default Pills
import { Node, nodePasteRule } from '@tiptap/core'
import { mergeAttributes } from '@tiptap/react'
import { getEmbedUrlFromVimeoUrl, isValidVimeoUrl, VIMEO_REGEX_GLOBAL } from './utils'
import { pick } from 'utilities/object'

export const Vimeo = Node.create({
    name: 'vimeo',

    addOptions() {
        return {
            addPasteHandler: true,
            HTMLAttributes: {},
            inline: false,

            allowFullscreen: true,
            width: 640,
            height: 480,

            autopause: true,
            autoplay: false,
            background: false,
            byline: undefined,
            color: undefined,
            controls: true,
            dnt: false,
            keyboard: true,
            loop: false,
            muted: false,
            pip: false,
            playsinline: true,
            portrait: undefined,
            quality: 'auto',
            speed: false,
            texttrack: false,
            title: undefined,
            transparent: true,
            t: undefined
        }
    },

    inline() {
        return this.options.inline
    },

    group() {
        return this.options.inline ?
            'inline' :
            'block'
    },

    draggable: true,

    addAttributes() {
        return {
            src: {
                default: null,
            },
            width: {
                default: this.options.width,
            },
            height: {
                default: this.options.height,
            },
            t: {
                default: 0,
            }
        }
    },

    parseHTML() {
        return [{
            tag: 'div[data-vimeo-video] iframe',
        }]
    },

    addCommands() {
        return {
            setVimeoVideo: options => ({ commands }) => {
                if(!isValidVimeoUrl(options.src)) {
                    return false
                }

                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                })
            }
        }
    },

    addPasteRules() {
        if(!this.options.addPasteHandler) {
            return []
        }

        return [
            nodePasteRule({
                find: VIMEO_REGEX_GLOBAL,
                type: this.type,
                getAttributes: match => ({ src: match.input })
            })
        ]
    },

    renderHTML({ HTMLAttributes }) {
        const embedUrl = getEmbedUrlFromVimeoUrl({
            url: HTMLAttributes.src,
            autopause: this.options.autopause,
            autoplay: this.options.autoplay,
            background: this.options.background,
            byline: this.options.byline,
            color: this.options.color,
            controls: this.options.controls,
            dnt: this.options.dnt,
            keyboard: this.options.keyboard,
            loop: this.options.loop,
            muted: this.options.muted,
            pip: this.options.pip,
            playsinline: this.options.playsinline,
            portrait: this.options.portrait,
            quality: this.options.quality,
            speed: this.options.speed,
            texttrack: this.options.texttrack,
            title: this.options.title,
            transparent: this.options.transparent,
            t: HTMLAttributes.t ?? 0
        })

        HTMLAttributes.src = embedUrl

        const iframeAttributes = pick(mergeAttributes(
            this.options.HTMLAttributes,
            {
                width: this.options.width,
                height: this.options.height,
                allowFullscreen: this.options.allowFullscreen
            },
            HTMLAttributes
        ), 'src', 'width', 'height', 'allowFullscreen')

        return [
            'div',
            { 'data-vimeo-video': '' },
            ['iframe', iframeAttributes]
        ]
    }
})
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useClickOutside } from 'hooks/click-outside'
import { useSize } from 'hooks/viewport'
import { Text as Search } from '../../s'
import { Results, List, Item, Button, NewTag, KeyHintInner, Group, Highlighter } from './s'
import { AnimatePresence } from 'framer-motion'
import { CornerDownLeft as Enter, Tag } from 'styled-icons/feather'
import { IconColumn, DetailsColumn, Name } from 'components/group/s'

export default ({ type, value, placeholder, options, id, addItem, onFocus, onBlur, ...searchProps }) => {
    const { formatMessage } = useIntl()

    const control = useRef()
    const input = useRef()

    const height = useSize({ dimension: 'height' })

    useClickOutside({
        callback: () => {
            setShow(false)
            onBlur()
        },
        refs: [control]
    })

    const [active, setActive] = useState(0)
    const [search, setSearch] = useState('')
    const [searchValid, setSearchValid] = useState(false)
    const [results, setResults] = useState([])
    const [resultSpace, setResultSpace] = useState(0)
    const [show, setShow] = useState(false)

    const addUnit = unit => {
        const {
            type,
            name
        } = unit
        const isItem = type === 'item'

        addItem(isItem ? name : search)
        setSearch('')
        setResults(results.filter(({ name: resultName }) => name !== resultName))
        onBlur()
    }

    useEffect(() => {
        const { bottom = 0 } = input?.current?.getBoundingClientRect?.() ?? {}

        if(bottom > 0 && height > 0) {
            setResultSpace(height - bottom)
        }
    }, [input?.current, height, show])

    useEffect(() => {
        setSearchValid(search.length > 0)

        if(!!options?.length) {
            const results = options
                .filter(name => name.toLowerCase().includes(search.toLowerCase()) && !value.includes(name))
                .map(name => ({
                    type: 'item',
                    name
                }))

            if(!!results.filter(({ name }) => name.toLowerCase() === search.toLowerCase()).length || search === '') {
                results.filter(({ type }) => type !== 'search')
            } else {
                results.unshift({
                    type: 'search',
                    name: 'search'
                })
            }

            setResults(results)

            if(results.length === 1) {
                setActive(0)
            }
        } else if(search.length > 0) {
            setResults([{
                type: 'search',
                name: 'search'
            }])
        } else {
            setResults([])
        }
    }, [search, options])

    const cyclePrevious = useCallback(() => {
        if(active > 0) {
            setActive(active - 1)
        } else {
            setActive(results.length - 1)
        }
    }, [active, results])

    const cycleNext = useCallback(() => {
        if(active < results.length - 1) {
            setActive(active + 1)
        } else {
            setActive(0)
        }
    }, [active, results])

    const onKeyDown = e => {
        const { keyCode, shiftKey } = e
        const key = keys[keyCode]

        if(keyCode in keys) {
            if((key === 'tab' && show && searchValid) || key !== 'tab') {
                e.preventDefault()
            }
        }

        if(key === 'enter') {
            addUnit(results[active])
        }

        if(key === 'up') {
            if(!show) {
                setShow(true)
                setActive(0)
            } else {
                cyclePrevious()
            }
        }

        if(key === 'down') {
            if(!show) {
                setShow(true)
                setActive(0)
            } else {
                cycleNext()
            }
        }

        if(shiftKey && key === 'tab' && show) {
            cyclePrevious()
        }

        if(!shiftKey && key === 'tab' && show) {
            cycleNext()
        }
    }

    const onKeyUp = e => {
        const { keyCode } = e
        const key = keys[keyCode]

        if(key === 'esc') {
            setShow(false)
        } else if(key === 'tab' && !searchValid) {
            setShow(false)
        } else if(key !== 'tab' && searchValid) {
            setShow(true)
        }

        input.current.focus()
    }

    return (
        <div ref={control}>
            <Search
                {...searchProps}
                type={type}
                value={search}
                id={id}
                placeholder={placeholder}
                onFocus={() => {
                    setActive(0)
                    onFocus()
                    setShow(true)
                }}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onChange={e => setSearch(e.currentTarget.value)}
                autoComplete="off"
                ref={input} />
            {(!!show && !!results?.length) && (
                <Results>
                    <List $space={resultSpace}>
                        {results.map((unit, index) => {
                            const {
                                type,
                                name
                            } = unit
                            const isActive = index === active
                            const isItem = type === 'item'

                            return (
                                <Item key={`${id}:result:${type}:${name}`}>
                                    <Button
                                        onClick={() => addUnit(unit)}
                                        className={`neutral${isActive ? ' active' : ''}`}
                                        tabIndex={-1}>
                                        <AnimatePresence>
                                            {!!isActive && (
                                                <KeyHintInner animate={isActive ? 'active' : 'inactive'}>
                                                    <Enter size={16} />
                                                </KeyHintInner>
                                            )}
                                        </AnimatePresence>
                                        {isItem && (
                                            <Group>
                                                <IconColumn iconSize={16}>
                                                    <Tag size={16} />
                                                </IconColumn>
                                                <DetailsColumn>
                                                    <Name>
                                                        {!!search?.length && (
                                                            <Highlighter
                                                                searchWords={[search]}
                                                                textToHighlight={name} />
                                                        )}
                                                        {!search?.length && name}
                                                    </Name>
                                                </DetailsColumn>
                                            </Group>
                                        )}
                                        {!isItem && (
                                            <div>
                                                {search}
                                                <NewTag className="compact">
                                                    {formatMessage({
                                                        id: 'news_tags_new',
                                                        defaultMessage: 'New tag'
                                                    }).toLowerCase()}
                                                </NewTag>
                                            </div>
                                        )}
                                    </Button>
                                </Item>
                            )
                        })}
                    </List>
                </Results>
            )}
        </div>
    )
}

const keys = {
    9: 'tab',
    13: 'enter',
    27: 'esc',
    38: 'up',
    40: 'down'
}
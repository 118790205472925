import React, { useRef, forwardRef, Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useMe } from 'contexts/me'
import { useTheme, ThemeProvider } from 'styled-components'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import { getDaysCount } from 'pages/absence/utilities'
import { cls } from 'utilities/dom'
import { decimalToPercent } from 'utilities/math'
import {
    EntryItem, EntryCellClickable, EntryCellFragment,
    PersonEntryClickable, PersonEntryFragment, PersonCell,
    Symbol,
    MetaCell, AbsenceDate, Emoji, MetaStatus, InlineMessage,
    StatusCell,
    CommentCell, NoteCell,
    ActionsCell
} from './s'
import Tooltip from 'components/tooltip'
import PercentageLabel from 'components/percentage-label'
import Person from 'components/person'
import Paragraph from 'components/typography/paragraph'
import Note from 'components/note'
import Status from './status'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'

const Entry = forwardRef(({ entry, actions = {}, salt, showApprovedStatus = true, ...props }, ref) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()

    const { check } = useAccess()
    const { isItMyOwnId } = useMe()

    const buttonRef = useRef()

    const {
        type,
        user,
        start,
        end,
        status,
        permissionLevel,
        grade = 1,
        note,
        reviewedBy,
        comment
    } = entry

    const days = getDaysCount(entry)

    const absenceAdmin = [
        check('absence:manage'),
        isItMyOwnId(user?.id),
        ['edit', 'approve'].includes(permissionLevel)
    ].some(Boolean)

    const {
        showPerson = false,
        showActions = true,
        showWorkdaysAffected = false,
        compact = false,
        ...attributes
    } = props

    const name = useTypeNameFormatter()(type)
    const view = actions?.view?.(entry)

    const dateProps = {
        user,
        start,
        end
    }

    const [PersonWrapper, personWrapperProps] = view ?
        [Tooltip, {
            content: formatMessage({
                id: 'action_view_details',
                defaultMessage: 'View details'
            }),
            delay: [500, 250],
            placement: 'top-start'
        }] :
        [Fragment, null]

    const PersonEntryCell = view ?
        PersonEntryClickable :
        PersonEntryFragment

    const EntryCell = view ?
        EntryCellClickable :
        EntryCellFragment

    const entryCellProps = view ? {
        onClick: () => {
            view.onClick()
            buttonRef.current.blur()
        }
    } : null

    const boundActions = getBoundActions(actions, entry)
    const hasActions = !!boundActions?.length && !!showActions

    theme.absence = {
        actions: hasActions,
        person: showPerson
    }

    const metaCellClassName = cls(['canceled', 'rejected'].includes(status) && 'strike')

    const symbolClassName = cls([
        'hover-effect',
        (absenceAdmin && status === 'pending') && 'passive',
        status === 'canceled' && 'voided'
    ])

    return (
        <ThemeProvider theme={theme}>
            <EntryItem
                {...attributes}
                ref={ref}>
                {!!showPerson && (
                    <PersonWrapper {...personWrapperProps}>
                        <PersonEntryCell
                            {...entryCellProps}
                            ref={buttonRef}>
                            <PersonCell>
                                <Person
                                    who={user}
                                    showPosition={true}
                                    truncate={true}
                                    size={40} />
                            </PersonCell>
                            <EntryCellFragment>
                                <MetaCell {...(metaCellClassName ? { className: metaCellClassName } : null)}>
                                    <AbsenceDate {...dateProps} />
                                    <Paragraph className="caption small compact interpoint-divider">
                                        <span>
                                            <Emoji
                                                emoji={type.symbol.emoji}
                                                size={14} />
                                            <span>{name}</span>
                                        </span>
                                        {grade < 1 && (
                                            <span>
                                                <PercentageLabel decimal={grade} />
                                            </span>
                                        )}
                                        {(!!showWorkdaysAffected && !!days) && (
                                            <span>
                                                <FormattedMessage
                                                    id="absence_count_workdays"
                                                    defaultMessage="{count, plural, =0 {} =1 {1 workweek day} other {{count} workweek days}}"
                                                    values={{ count: days }} />
                                            </span>
                                        )}
                                    </Paragraph>
                                    <MetaStatus>
                                        {(absenceAdmin && !end) && (
                                            <InlineMessage
                                                message={formatMessage({
                                                    id: 'absence_message_missing_end_date',
                                                    defaultMessage: 'No end date'
                                                })}
                                                type="warning" />
                                        )}
                                    </MetaStatus>
                                </MetaCell>
                            </EntryCellFragment>
                        </PersonEntryCell>
                    </PersonWrapper>
                )}
                {!showPerson && (
                    <EntryCell
                        {...entryCellProps}
                        ref={buttonRef}>
                        <Symbol
                            symbol={type.symbol}
                            {...(grade < 1 ? { percentage: decimalToPercent(grade) } : null)}
                            className={symbolClassName} />
                        <MetaCell {...(metaCellClassName ? { className: metaCellClassName } : null)}>
                            <AbsenceDate
                                {...dateProps}
                                {...entryCellProps} />
                            <Paragraph className="caption small compact interpoint-divider">
                                <span>{name}</span>
                                {grade < 1 && (
                                    <span>
                                        <PercentageLabel decimal={grade} />
                                    </span>
                                )}
                                {(!!showWorkdaysAffected && !!days) && (
                                    <span>
                                        <FormattedMessage
                                            id="absence_count_workdays"
                                            defaultMessage="{count, plural, =0 {} =1 {1 workweek day} other {{count} workweek days}}"
                                            values={{ count: days }} />
                                    </span>
                                )}
                            </Paragraph>
                            <MetaStatus>
                                {(absenceAdmin && !end) && (
                                    <InlineMessage
                                        message={formatMessage({
                                            id: 'absence_message_missing_end_date',
                                            defaultMessage: 'No end date'
                                        })}
                                        type="warning" />
                                )}
                            </MetaStatus>
                        </MetaCell>
                    </EntryCell>
                )}
                {!compact && (
                    <>
                        {(!showPerson && ((!!comment && !!reviewedBy) || !!note)) && (
                            <>
                                {!!comment && (
                                    <CommentCell>
                                        <Note user={reviewedBy}>{comment}</Note>
                                    </CommentCell>
                                )}
                                {!comment && (
                                    <NoteCell>
                                        <Note>{note}</Note>
                                    </NoteCell>
                                )}
                            </>
                        )}
                        <StatusCell>
                            <Status
                                {...entry}
                                type={type}
                                showApprovedStatus={showApprovedStatus} />
                        </StatusCell>
                        <ActionsCell>
                            {hasActions && (
                                <ContextMenu
                                    prebound actions={boundActions}
                                    salt={salt} />
                            )}
                        </ActionsCell>
                    </>
                )}
            </EntryItem>
        </ThemeProvider>
    )
})

export default Entry

import { useCallback } from 'react'
import { useAddressFormatter, fields as addressFields } from 'components/address'
import { safeFormat } from 'utilities/date-time'
import { useYearsAndMonthsSince } from 'components/time-years-and-months-since'
import { currencies } from 'utilities/payment'
import { getFractionDigits } from 'pages/people/pages/profile/panes/about/salary/utilities'
import { periodUnitToPeriodShortMap } from 'pages/people/pages/profile/panes/about/salary'
import { get, pick } from 'utilities/object'
import { without } from 'utilities/array'

import {
    unpackField,
    unpackStringField,
    // getGivenName,
    // getPreferredName,
    // getFamilyName,
    getFullName,
    getJobTitle,
    getPhoneNumber,
    getEmailAddress
} from 'utilities/person'

const allTypes = [
    'universal',
    'organization',
    'user',
    'team',
    'location'
]

const useUniversalFieldDefinitions = () => {
    const getDateFormatter = useGetSourceDateFormatter()

    return useCallback(({ i18n }) => {
        return [
            {
                name: 'currentDate',
                label: {
                    id: 'date_current',
                    defaultMessage: 'Current date'
                },
                formatter: getDateFormatter({ i18n, path: 'currentDate' }),
                sourceNames: ['currentDate'],
                dynamic: 'time'
            }
        ]
    }, [getDateFormatter])
}

const useOrganizationFieldDefinitions = () => {
    const getAddressFormatter = useGetSourceAddressFormatter()

    return useCallback((options = {}) => {
        const { i18n } = options

        return [
            {
                name: 'name',
                label: {
                    id: 'noun_name',
                    defaultMessage: 'Name'
                },
                formatter: organization => organization?.name ?? null
            },
            {
                name: 'companyName',
                label: {
                    id: 'integration_label_companyName',
                    defaultMessage: 'Company name'
                },
                formatter: organization => organization.account?.company ?? null
            },
            {
                name: 'address',
                label: {
                    id: 'noun_address_billing',
                    defaultMessage: 'Billing address'
                },
                formatter: getAddressFormatter({ i18n, path: 'account.address' })
            },
            {
                name: 'organizationNumber',
                label: {
                    id: 'payment_label_organization_number',
                    defaultMessage: 'Organization number'
                },
                formatter: organization => organization.account?.organizationNumber ?? null
            },
            {
                name: 'vatNumber',
                label: {
                    id: 'payment_label_vat_number',
                    defaultMessage: 'VAT registration number'
                },
                formatter: organization => organization.account?.vatNumber ?? null
            }
        ]
    }, [getAddressFormatter])
}

const useUserFieldDefinitions = () => {
    const getAddressFormatter = useGetSourceAddressFormatter()
    const getDateFormatter = useGetSourceDateFormatter()
    const getListFormatter = useGetSourceListFormatter()
    const yearsAndMonthsSince = useYearsAndMonthsSince()
    const getSalaryFormatter = useGetSourceSalaryFormatter()
    const getBankAccountFormatter = useGetSourceBankAccountFormatter()
    const getIdentificationFormatter = useGetSourceIdentificationFormatter()

    return useCallback((options = {}) => {
        const { i18n, user } = options
        const { customProfileFields = [] } = user

        const introFields = [
            {
                name: 'email',
                label: {
                    id: 'person_label_emailaddress',
                    defaultMessage: 'Email address'
                },
                formatter: getEmailAddress,
                sourceNames: ['email']
            },
            {
                name: 'phone',
                label: {
                    id: 'person_label_phonenumber',
                    defaultMessage: 'Phone number'
                },
                formatter: getPhoneNumber,
                sourceNames: ['phone']
            },
            {
                name: 'funfacts',
                label: {
                    id: 'person_label_funfacts',
                    defaultMessage: 'Fun facts'
                },
                formatter: user => unpackStringField(user?.funfacts),
                sourceNames: ['funfacts']
            },
            {
                name: 'interests',
                label: {
                    id: 'person_label_interests',
                    defaultMessage: 'Interests'
                },
                formatter: getListFormatter({ i18n, path: 'interests' }),
                sourceNames: ['interests']
            },
            {
                name: 'birthDate',
                label: {
                    id: 'person_label_birthdate',
                    defaultMessage: 'Date of birth'
                },
                formatter: getDateFormatter({ i18n, path: 'birthDate' }),
                sourceNames: ['birthDate']
            }
        ]

        const affiliationFields = [
            // "person_label_supervisor": "Supervisor",
            // "person_label_subordinates": "Subordinates",
            {
                name: 'teams',
                label: {
                    id: 'person_label_teams',
                    defaultMessage: 'Teams'
                },
                formatter: getListFormatter({
                    i18n,
                    path: 'teams',
                    cursor: team => team.name
                }),
                sourceNames: ['teams']
            },
            {
                name: 'locations',
                label: {
                    id: 'person_label_locations',
                    defaultMessage: 'Locations'
                },
                formatter: getListFormatter({
                    i18n,
                    path: 'locations',
                    cursor: location => location.name
                }),
                sourceNames: ['locations']
            }
        ]

        const employmentFields = [
            {
                name: 'jobTitle',
                label: {
                    id: 'person_label_employment_jobtitle',
                    defaultMessage: 'Job title'
                },
                formatter: user => getJobTitle(user)?.name ?? null,
                sourceNames: ['jobTitle']
            },
            // "person_label_employment_jobdescription": "Job description"
            {
                name: 'employmentType',
                label: {
                    id: 'person_label_employment_type',
                    defaultMessage: 'Employment type'
                },
                formatter: user => {
                    const employmentType = unpackStringField(user?.employmentType)
                    if(!employmentType) {
                        return ''
                    }

                    return i18n.intl.formatMessage({
                        Permanent: {
                            id: 'person_label_employment_type_permanent',
                            defaultMessage: 'Permanent'
                        },
                        Temporary: {
                            id: 'person_label_employment_type_temporary',
                            defaultMessage: 'Temporary'
                        },
                        Casual: {
                            id: 'person_label_employment_type_casual',
                            defaultMessage: 'Casual'
                        },
                        Trainee: {
                            id: 'person_label_employment_type_trainee',
                            defaultMessage: 'Trainee'
                        },
                        Consultant: {
                            id: 'person_label_employment_type_consultant',
                            defaultMessage: 'Consultant'
                        }
                    }[employmentType])
                },
                sourceNames: ['employmentType']
            },
            {
                name: 'employmentPercentage',
                label: {
                    id: 'person_label_employment_percentage',
                    defaultMessage: 'Employment percentage'
                },
                formatter: user => {
                    const employmentPercentage = unpackStringField(user?.employmentPercentage)
                    if(!employmentPercentage && employmentPercentage !== 0) {
                        return ''
                    }

                    return i18n.intl.formatMessage({
                        id: 'value_percent',
                        defaultMessage: '{percent} %'
                    }, { percent: employmentPercentage })
                },
                sourceNames: ['employmentPercentage']
            },
            {
                name: 'employmentId',
                label: {
                    id: 'person_label_employment_id',
                    defaultMessage: 'Employment ID'
                },
                formatter: user => unpackStringField(user?.employmentId),
                sourceNames: ['employmentId']
            },
            {
                name: 'employmentStartDate',
                label: {
                    id: 'person_label_employment_contract_date_start',
                    defaultMessage: 'Employment start date'
                },
                formatter: getDateFormatter({ i18n, path: 'employmentStartDate' }),
                sourceNames: ['employmentStartDate']
            },
            {
                name: 'seniority',
                label: {
                    id: 'person_label_employment_seniority',
                    defaultMessage: 'Seniority'
                },
                formatter: user => {
                    const start = unpackStringField(user?.employmentStartDate)
                    const end = unpackStringField(user?.employmentEndDate)

                    if(!start) {
                        return ''
                    }

                    return yearsAndMonthsSince({ start, end }, { intl: i18n.intl })
                },
                sourceNames: ['employmentStartDate', 'employmentEndDate']
            },
            {
                name: 'firstDayOfWork',
                label: {
                    id: 'person_label_employment_date_start',
                    defaultMessage: 'First day of work'
                },
                formatter: getDateFormatter({ i18n, path: 'firstDayOfWork' }),
                sourceNames: ['firstDayOfWork']
            },
            {
                name: 'probationEndDate',
                label: {
                    id: 'person_label_employment_probation_date_end',
                    defaultMessage: 'Probation end date'
                },
                formatter: getDateFormatter({ i18n, path: 'probationEndDate' }),
                sourceNames: ['probationEndDate']
            },
            {
                name: 'terminationNoticeDate',
                label: {
                    id: 'person_label_employment_termination_notice_date',
                    defaultMessage: 'Termination notice date'
                },
                formatter: getDateFormatter({ i18n, path: 'terminationNoticeDate' }),
                sourceNames: ['terminationNoticeDate']
            },
            {
                name: 'lastDayOfWork',
                label: {
                    id: 'person_label_employment_date_end',
                    defaultMessage: 'Last day of work'
                },
                formatter: getDateFormatter({ i18n, path: 'lastDayOfWork' }),
                sourceNames: ['lastDayOfWork']
            },
            {
                name: 'employmentEndDate',
                label: {
                    id: 'person_label_employment_termination_date',
                    defaultMessage: 'Employment end date'
                },
                formatter: getDateFormatter({ i18n, path: 'employmentEndDate' }),
                sourceNames: ['employmentEndDate']
            }
        ]

        const salaryFields = [
            {
                name: 'salary',
                label: {
                    id: 'salary_label_current_salary',
                    defaultMessage: 'Current salary'
                },
                formatter: getSalaryFormatter({ i18n, path: 'salary' }),
                sourceNames: ['salary']
            }
        ]

        const financialFields = [
            {
                name: 'bankAccount',
                label: {
                    id: 'person_label_financial_account',
                    defaultMessage: 'Bank account'
                },
                formatter: getBankAccountFormatter({ i18n, path: 'bankAccount' }),
                sourceNames: ['bankAccount']
            }
        ]

        const personaliaFields = [
            // {
            //     name: 'givenName',
            //     label: {
            //         id: 'person_label_name_given',
            //         defaultMessage: 'Given name'
            //     },
            //     formatter: getGivenName,
            //     sourceNames: ['givenName']
            // },
            // {
            //     name: 'preferredName',
            //     label: {
            //         id: 'person_label_name_preferred',
            //         defaultMessage: 'Preferred name'
            //     },
            //     formatter: getPreferredName,
            //     sourceNames: ['preferredName']
            // },
            // {
            //     name: 'familyName',
            //     label: {
            //         id: 'person_label_name_family',
            //         defaultMessage: 'Family name'
            //     },
            //     formatter: getFamilyName,
            //     sourceNames: ['familyName']
            // },
            {
                name: 'name',
                label: {
                    id: 'person_label_name',
                    defaultMessage: 'Name'
                },
                formatter: user => getFullName(user, { fallback: false }),
                sourceNames: ['fullName']
            },
            {
                name: 'personalEmail',
                label: {
                    id: 'person_label_email_personal',
                    defaultMessage: 'Personal email address'
                },
                formatter: user => getEmailAddress(user, 'privateEmail'),
                sourceNames: ['privateEmail']
            },
            {
                name: 'homeAddress',
                label: {
                    id: 'person_label_address_home',
                    defaultMessage: 'Address'
                },
                formatter: getAddressFormatter({ i18n, path: 'homeAddress' }),
                sourceNames: ['homeAddress']
            },
            {
                name: 'gender',
                label: {
                    id: 'person_label_gender',
                    defaultMessage: 'Gender'
                },
                formatter: user => {
                    const gender = unpackStringField(user?.gender)
                    if(!gender) {
                        return ''
                    }

                    if(['Female', 'Male', 'Rather not say'].includes(gender)) {
                        return i18n.intl.formatMessage({
                            Female: {
                                id: 'person_label_gender_female',
                                defaultMessage: 'Female'
                            },
                            Male: {
                                id: 'person_label_gender_male',
                                defaultMessage: 'Male'
                            },
                            'Rather not say': {
                                id: 'person_label_gender_other',
                                defaultMessage: 'Rather not say'
                            }
                        }[gender])
                    }

                    return gender
                },
                sourceNames: ['gender']
            },
            {
                name: 'nationality',
                label: {
                    id: 'person_label_nationality',
                    defaultMessage: 'Nationality'
                },
                formatter: user => {
                    const nationality = unpackStringField(user?.nationality)
                    if(!nationality) {
                        return ''
                    }

                    return new Intl.DisplayNames([i18n.locale, 'en'], { type: 'region' }).of(nationality)
                },
                sourceNames: ['nationality']
            },
            {
                name: 'civilStatus',
                label: {
                    id: 'person_label_civilstatus',
                    defaultMessage: 'Civil status'
                },
                formatter: user => {
                    const civilStatus = unpackStringField(user?.civilStatus)
                    if(!civilStatus) {
                        return ''
                    }

                    return i18n.intl.formatMessage({
                        Single: {
                            id: 'person_label_civilstatus_single',
                            defaultMessage: 'Single'
                        },
                        Relationship: {
                            id: 'person_label_civilstatus_relationship',
                            defaultMessage: 'Relationship'
                        },
                        Married: {
                            id: 'person_label_civilstatus_married',
                            defaultMessage: 'Married'
                        }
                    }[civilStatus])
                },
                sourceNames: ['civilStatus']
            },
            {
                name: 'dietaryRestrictions',
                label: {
                    id: 'person_label_dietary_requirements',
                    defaultMessage: 'Dietary restrictions'
                },
                formatter: user => unpackStringField(user?.dietaryRequirements),
                sourceNames: ['dietaryRequirements']
            }
        ]

        const getIdentificationOptions = (user, type) => (unpackField(get(user, 'identifications')) ?? [])
            .filter(identification => identification.type === type)

        const getNationalIdentificationOptions = user => getIdentificationOptions(user, 'national')
        const getPassportIdentificationOptions = user => getIdentificationOptions(user, 'passport')

        const identificationCursor = 'country'

        // For identification fields, the cursor property = country code
        const identificationFields = [
            {
                name: 'nationalIdentificationNumber',
                label: {
                    id: 'person_label_identifications_national',
                    defaultMessage: 'National identification number'
                },
                cursor: identificationCursor,
                getOptions: getNationalIdentificationOptions,
                formatter: getIdentificationFormatter({
                    cursorKey: identificationCursor,
                    getOptions: getNationalIdentificationOptions
                }),
                sourceNames: ['identifications']
            },
            {
                name: 'passportNumber',
                label: {
                    id: 'person_label_identifications_passport',
                    defaultMessage: 'Passport number'
                },
                cursor: identificationCursor,
                getOptions: getPassportIdentificationOptions,
                formatter: getIdentificationFormatter({
                    cursorKey: identificationCursor,
                    getOptions: getPassportIdentificationOptions
                }),
                sourceNames: ['identifications']
            }
        ]

        const childrenFields = [
            // Children
        ]

        const emergencyContactFields = [
            // Emergency contacts
        ]

        const customFields = customProfileFields.map(({ name, label }) => {
            name = `custom.${name}`

            return {
                name,
                label,
                formatter: user => {
                    const field = user?.[name]
                    if(!field) {
                        return null
                    }

                    const value = {
                        text: () => field.value,
                        textarea: () => field.value,
                        url: () => field.value,
                        number: () => field.value,
                        decimal: () => field.value,
                        localdate: () => getDateFormatter({ i18n, path: 'value' })(field),
                        boolean: () => {
                            if(![true, false].includes(field.value)) {
                                return null
                            }

                            return i18n.intl.formatMessage({
                                id: field.value ?
                                    'boolean_true' :
                                    'boolean_false',
                                defaultMessage: field.value ?
                                    'Yes' :
                                    'No'
                            })
                        },
                        enum: () => {
                            if(!field.value) {
                                return null
                            }

                            return field.options?.[field.value]
                        }
                    }[field.type]()

                    return (value || value === 0) ?
                        value :
                        null
                },
                sourceNames: [name]
            }
        })

        return [
            ...introFields,
            ...affiliationFields,
            ...employmentFields,
            ...salaryFields,
            ...financialFields,
            ...personaliaFields,
            ...identificationFields,
            ...childrenFields,
            ...emergencyContactFields,
            ...customFields
        ]
    }, [getAddressFormatter, getDateFormatter, getListFormatter, yearsAndMonthsSince, getSalaryFormatter, getBankAccountFormatter, getIdentificationFormatter])
}

const useGroupFieldDefinitions = () => {
    const getAddressFormatter = useGetSourceAddressFormatter()

    return useCallback(({ i18n, type }) => {
        let fieldDefinitions = [
            {
                name: 'name',
                label: {
                    id: 'noun_name',
                    defaultMessage: 'Name'
                },
                formatter: group => group?.name ?? null
            },
            {
                name: 'description',
                label: {
                    id: 'noun_description',
                    defaultMessage: 'Description'
                },
                formatter: group => group?.description ?? null
            }
        ]

        if(type === 'location') {
            fieldDefinitions = [
                ...fieldDefinitions,
                {
                    name: 'address',
                    label: {
                        id: 'noun_address',
                        defaultMessage: 'Address'
                    },
                    formatter: getAddressFormatter({ i18n, path: 'address' })
                }
            ]
        }

        return fieldDefinitions
    }, [getAddressFormatter])
}

export const useGetFieldDefinitions = () => {
    const getUniversalFieldDefinitions = useUniversalFieldDefinitions()
    const getOrganizationFieldDefinitions = useOrganizationFieldDefinitions()
    const getUserFieldDefinitions = useUserFieldDefinitions()
    const getGroupFieldDefinitions = useGroupFieldDefinitions()

    return (options = {}) => {
        const {
            i18n,
            types = allTypes,
            organization = {},
            user = {},
            team = {},
            location = {}
        } = options

        const universalFieldDefinitions = getUniversalFieldDefinitions({ i18n }).map(addTypeToFieldDefinition('universal'))

        return without(types, ['universal']).reduce((accumulator, type) => {
            const getFieldDefinitions = {
                organization: () => getOrganizationFieldDefinitions({ i18n, organization }),
                user: () => getUserFieldDefinitions({ i18n, user }),
                team: () => getGroupFieldDefinitions({ type, i18n, team }),
                location: () => getGroupFieldDefinitions({ type, i18n, location })
            }[type]

            if(!getFieldDefinitions) {
                return accumulator
            }

            return [
                ...accumulator,
                ...getFieldDefinitions(type).map(addTypeToFieldDefinition(type))
            ]
        }, universalFieldDefinitions)
    }
}

const useGetSourceAddressFormatter = () => {
    const formatAddress = useAddressFormatter()

    return useCallback(({ i18n, path }) => source => {
        const address = pick(unpackField(get(source, path)), ...addressFields)

        return Object.values(address ?? {}).some(Boolean) ?
            formatAddress({ i18n, address }) :
            ''
    }, [formatAddress])
}

const useGetSourceDateFormatter = () => {
    return useCallback(({ i18n, path }) => source => {
        const dateString = unpackStringField(get(source, path))

        return !!dateString ?
            safeFormat(new Date(dateString), 'PPP', { locale: i18n.dateLocale }) :
            ''
    }, [])
}

const useGetSourceListFormatter = () => {
    return useCallback(({ i18n, path, cursor = v => v }) => source => {
        let words = unpackField(get(source, path))
        if(!words?.length) {
            return null
        }

        if(typeof words === 'string') {
            words = words.split(',')
        }

        if(words?.length === 1) {
            return cursor(words[0])
        }

        return new Intl.ListFormat([i18n.locale, 'en'], {
            type: 'conjunction'
        }).format(words.map(cursor))
    }, [])
}

const useGetSourceSalaryFormatter = () => {
    return useCallback(({ i18n, path }) => source => {
        const salary = unpackField(get(source, path))
        if(!salary?.current) {
            return null
        }

        const {
            amount,
            currency,
            periodUnit
        } = salary

        const currencyDisplay = currencies[currency] || 'code'

        return i18n.intl.formatMessage({
            id: 'salary_value_frequency',
            defaultMessage: '{salary} per {periodUnit}'
        }, {
            salary: i18n.intl.formatNumber(amount, {
                style: 'currency',
                currency,
                currencyDisplay,
                minimumFractionDigits: getFractionDigits(amount),
                maximumFractionDigits: getFractionDigits(amount)
            }),
            periodUnit: i18n.intl.formatMessage(periodUnitToPeriodShortMap?.[periodUnit] ?? periodUnitToPeriodShortMap.default)
        })
    }, [])
}

const useGetSourceBankAccountFormatter = () => {
    return useCallback(({ i18n, path }) => source => {
        const bankAccount = unpackField(get(source, path))
        if(!bankAccount?.number && !bankAccount?.iban) {
            return null
        }

        const {
            number,
            country,
            iban,
            bic
        } = bankAccount

        let output = null

        const separator = i18n.intl.formatMessage({
            id: 'sentence_separator',
            defaultMessage: ' '
        })

        if(number && country) {
            output = `${formatBankAccountNumber(number, country)}${separator}(${country})`
        }

        if(iban && bic) {
            output = `${iban}/${bic}`
        }

        return output
    }, [])
}

const useGetSourceIdentificationFormatter = () => {
    return useCallback(({ cursorKey, getOptions }) => (source, cursor) => {
        const options = getOptions(source)
        if(!options?.length) {
            return null
        }

        if(options.length === 1) {
            return options[0].value
        }

        if(!!cursor) {
            return options?.find(({ [cursorKey]: valueCursor }) => valueCursor === cursor)?.value ?? null
        }

        return null
    }, [])
}

const formatBankAccountNumber = (number, country) => ({
    NO: () => `${number.slice(0, 4)}.${number.slice(4, 6)}.${number.slice(6)}`
})[country]?.() ?? number

const addTypeToFieldDefinition = type => field => ({
    ...field,
    type
})
import React from 'react'
import { useTheme, ThemeProvider } from 'styled-components'
import {
    LinkContainer,
    Header, IconCell, HeadingCell, SummaryCell, ArrowCell,
    Heading, Summary
} from './s'
import { ArrowRight as Arrow } from 'styled-icons/feather'

const Link = props => {
    let theme = useTheme()

    const {
        icon = null,
        heading = '',
        summary = '',
        ...attributes
    } = props

    theme = {
        ...theme,
        expandable: {
            icon: !!icon,
            summary: !!summary
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <LinkContainer {...attributes}>
                <Header>
                    {!!icon && (
                        <IconCell>
                            {icon}
                        </IconCell>
                    )}
                    {!!heading && (
                        <HeadingCell>
                            <Heading>
                                {heading}
                            </Heading>
                        </HeadingCell>
                    )}
                    {!!summary && (
                        <SummaryCell>
                            <Summary className="compact">
                                {summary}
                            </Summary>
                        </SummaryCell>
                    )}
                    <ArrowCell>
                        <Arrow size={24} />
                    </ArrowCell>
                </Header>
            </LinkContainer>
        </ThemeProvider>
    )
}

export default Link

import styled from 'styled-components'
import { motion } from 'framer-motion'
import {
    Field as FieldBase,
    Control as ControlBase,
    Label as LabelBase
 } from 'components/form/field/s'
import { Columns as ColumnsBase, FlexChild } from 'components/flex'
import TextBase from 'components/form/input/text'
import SelectBase from 'components/form/input/select'
import { captionSmallCss } from 'components/typography/caption'
import { UnsetValueButton as UnsetValueButtonBase } from 'components/button'

export const Field = styled(FieldBase)`
    margin-bottom: 0;
    flex-direction: column;
`

export const Control = styled(ControlBase)`
    display: flex;
    column-gap: 8px;
    transition: margin-block-end .2s ease-in-out;

    &.error-within,
    &:where(:has(.errored)) {
        margin-block-end: 40px;
    }
`

export const Columns = styled(ColumnsBase)`
    column-gap: 8px;
`

export const Column = styled(FlexChild)`
    flex: 0 0 auto;

    &.two-columns {
        flex: 1;
    }
`

export const Label = styled(LabelBase)`
    margin-top: 4px;
    margin-bottom: 0;
    width: auto;

    font-size: 14px;
    color: var(--huma-color-foreground-subtle);
`

export const Text = styled(TextBase)`
    min-width: 70px;
    max-width: 100px;

    &.dayless {
        max-width: unset;
    }

    &::placeholder {
        overflow: visible;
    }

    &[type="number"] {
        appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
`

export const Select = styled(SelectBase)`
    min-width: 80px;
    max-width: 160px;

    &.dayless {
        max-width: unset;
    }
`

export const Helper = styled(motion.p).attrs(() => ({
    variants: {
        hide: {
            height: 0,
            marginBottom: 0,
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        reveal: {
            height: 'auto',
            marginBottom: 16,
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .2
            }
        }
    },
    initial: 'hide'
}))`
    position: absolute;
    bottom: 0;
    ${captionSmallCss}
`

export const UnsetValueButton = styled(UnsetValueButtonBase)`
    position: static;

    width: 40px;
    height: 40px;

    transform: unset;
`
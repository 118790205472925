import { compact } from 'utilities/array'
import { compact as compactObject } from 'utilities/object'

export const useGetValuesInterpolator = () => ({ values, fields, mode }) => content => {
    if(mode === 'build') {
        values = {}
    }

    if(!!content?.type && !content?.content) {
        content.content = []
    }

    if(Array.isArray(content)) {
        content = { content }
    }

    const contentReducer = (accumulator, node) => {
        if(node.type !== 'variable') {
            return [
                ...accumulator,
                {
                    ...node,
                    ...(!!node.content ? {
                        content: node.content.reduce(contentReducer, [])
                    } : null)
                }
            ]
        }

        const typeFields = fields.filter(({ type }) => type === node.attrs.sourceType)

        let { value } = Object.values(values).find(({ source }) => {
            if(!source) {
                return false
            }

            const sameType = source.type === node.attrs.sourceType
            const sameName = source.name === node.attrs.sourceName

            return sameType && sameName
        }) ?? {}

        if(!value) {
            return [
                ...accumulator,
                {
                    ...node,
                    attrs: compactObject({
                        ...node.attrs,
                        id: null,
                        value: null
                    })
                }
            ]
        }

        const {
            formatter,
            cursor = null,
            getOptions = null
        } = typeFields.find(({ name }) => name === node.attrs.fieldName) ?? {}

        let cursorToSet = null
        let formattedValue = null

        if(!!value) {
            const options = getOptions?.(value)
            if(!node.attrs.cursor && options?.length === 1) {
                cursorToSet = options[0][cursor]
            }

            const formatterArguments = compact([
                value,
                !!cursor ?
                    (node.attrs.cursor ?? null) :
                    null
            ])

            formattedValue = formatter?.(...formatterArguments)
        }

        return [
            ...accumulator,
            {
                ...node,
                attrs: compactObject({
                    ...node.attrs,
                    ...(!!cursorToSet ? { cursor: cursorToSet } : null),
                    id: value.id,
                    value: (mode === 'fill') ? formattedValue : null
                })
            }
        ]
    }

    return {
        ...content,
        content: content.content.reduce((accumulator, block) => [
            ...accumulator,
            {
                ...block,
                ...(!!block.content ? {
                    content: block.content.reduce(contentReducer, [])
                } : null)
            }
        ], [])
    }
}

export const getContentSources = ({ content }) => {
    if(!!content?.type && !content?.content) {
        content.content = []
    }

    if(Array.isArray(content)) {
        content = { content }
    }

    const contentReducer = (accumulator, node) => {
        if(node.type !== 'variable') {
            return {
                ...accumulator,
                ...node.content?.reduce(contentReducer, accumulator)
            }
        }

        const key = `${node.attrs.sourceType}:${node.attrs.sourceName}`

        return {
            ...accumulator,
            [key]: compactObject({
                ...(accumulator[key] ?? null),
                type: node.attrs.sourceType,
                name: node.attrs.sourceName,
                detached: !!node.attrs.detached,
                count: (accumulator[key]?.count ?? 0) + 1,
                fields: {
                    ...(accumulator[key]?.fields ?? null),
                    [node.attrs.fieldName]: (accumulator[key]?.fields?.[node.attrs.fieldName] ?? 0) + 1
                }
            })
        }
    }

    return Object.values(content.content.reduce((accumulator, block) => ({
        ...accumulator,
        ...block.content?.reduce(contentReducer, accumulator)
    }), {}))
}

export const renameContentSource = ({ content, from, to }) => {
    if(!!content?.type && !content?.content) {
        content.content = []
    }

    if(Array.isArray(content)) {
        content = { content }
    }

    const contentReducer = (accumulator, node) => {
        if(node.type !== 'variable' || node.attrs.sourceType !== from.type || node.attrs.sourceName !== from.name) {
            return [
                ...accumulator,
                {
                    ...node,
                    ...(!!node.content ? {
                        content: node.content.reduce(contentReducer, [])
                    } : null)
                }
            ]
        }

        return [
            ...accumulator,
            {
                ...node,
                attrs: compactObject({
                    ...node.attrs,
                    sourceName: to.name
                })
            }
        ]
    }

    return {
        ...content,
        content: content.content.reduce((accumulator, block) => [
            ...accumulator,
            {
                ...block,
                ...(!!block.content ? {
                    content: block.content.reduce(contentReducer, [])
                } : null)
            }
        ], [])
    }
}

export const getContentWithoutInterpolatedProperties = (...properties) => ({ content, source }) => {
    if(!!content?.type && !content?.content) {
        content.content = []
    }

    if(Array.isArray(content)) {
        content = { content }
    }

    const contentReducer = (accumulator, node) => {
        if(node.type !== 'variable') {
            return [
                ...accumulator,
                {
                    ...node,
                    ...(!!node.content ? {
                        content: node.content.reduce(contentReducer, [])
                    } : null)
                }
            ]
        }

        const affected = !source || (!!source && (node.attrs.sourceType === source?.type && node.attrs.sourceName === source?.name))
        if(!affected) {
            return [
                ...accumulator,
                node
            ]
        }

        return [
            ...accumulator,
            {
                ...node,
                attrs: compactObject({
                    ...node.attrs,
                    ...properties.reduce((accumulator, property) => ({
                        ...accumulator,
                        [property]: null
                    }), {})
                })
            }
        ]
    }

    return {
        ...content,
        content: content.content.reduce((accumulator, block) => [
            ...accumulator,
            {
                ...block,
                ...(!!block.content ? {
                    content: block.content.reduce(contentReducer, [])
                } : null)
            }
        ], [])
    }
}

export const getContentWithoutFalsyValues = ({ content }) => {
    if(!!content?.type && !content?.content) {
        content.content = []
    }

    if(Array.isArray(content)) {
        content = { content }
    }

    const contentReducer = (accumulator, node) => [
        ...accumulator,
        {
            ...node,
            ...(!!node.attrs ? {
                attrs: compactObject(node.attrs)
            } : null),
            ...(!!node.content ? {
                content: node.content.reduce(contentReducer, [])
            } : null)
        }
    ]

    return {
        ...content,
        content: content.content.reduce((accumulator, block) => [
            ...accumulator,
            {
                ...block,
                ...(!!block.content ? {
                    content: block.content.reduce(contentReducer, [])
                } : null)
            }
        ], [])
    }
}

const xtachContentSource = detached => ({ content, source, replaceName = null, id = null }) => {
    if(!!content?.type && !content?.content) {
        content.content = []
    }

    if(Array.isArray(content)) {
        content = { content }
    }

    const contentReducer = (accumulator, node) => {
        if(node.type !== 'variable' || node.attrs.sourceType !== source.type || node.attrs.sourceName !== source.name) {
            return [
                ...accumulator,
                {
                    ...node,
                    ...(!!node.content ? {
                        content: node.content.reduce(contentReducer, [])
                    } : null)
                }
            ]
        }

        return [
            ...accumulator,
            {
                ...node,
                attrs: compactObject({
                    ...node.attrs,
                    sourceName: replaceName ?? node.attrs.sourceName,
                    id,
                    detached,
                    ...(detached ? { cursor: null } : null),
                    value: null
                })
            }
        ]
    }

    return {
        ...content,
        content: content.content.reduce((accumulator, block) => [
            ...accumulator,
            {
                ...block,
                ...(!!block.content ? {
                    content: block.content.reduce(contentReducer, [])
                } : null)
            }
        ], [])
    }
}

export const detachContentSource = xtachContentSource(true)
export const attachContentSource = xtachContentSource(null)

export const discardMarksForDisallowedTypes = ({ content }) => {
    if(!!content?.type && !content?.content) {
        content.content = []
    }

    if(Array.isArray(content)) {
        content = { content }
    }

    const contentReducer = (accumulator, node) => {
        if(!['text', 'variable'].includes(node.type)) {
            return [
                ...accumulator,
                compactObject({
                    ...node,
                    marks: null,
                    ...(!!node.content ? {
                        content: node.content.reduce(contentReducer, [])
                    } : null)
                })
            ]
        }

        return [
            ...accumulator,
            node
        ]
    }

    return {
        ...content,
        content: content.content.reduce((accumulator, block) => [
            ...accumulator,
            {
                ...block,
                ...(!!block.content ? {
                    content: block.content.reduce(contentReducer, [])
                } : null)
            }
        ], [])
    }
}
import styled from 'styled-components'
import { print } from 'utilities/styled'

export const Wrapper = styled.div`
    &:not(.compact) {
        margin-bottom: 24px;
    }

    ${print`
        display: none;
    `}
`
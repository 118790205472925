import styled from 'styled-components'
import { fromContainer, belowContainer } from 'utilities/styled'
import { SkeletonListRow as SkeletonListRowBase, SkeletonCell as SkeletonCellBase } from 'components/skeleton'
import CardListBase from 'components/card-list'

export const containerProps = {
    name: 'job-titles',
    breakpoint: 650
}

export const SkeletonListRow = styled(SkeletonListRowBase)`
    display: grid;
    grid-template-columns: subgrid;
    grid-column: 1 / -1;
    align-items: center;
    column-gap: 16px;

    padding-block: 16px;
    min-height: 72px;
    box-shadow: 0 1px var(--huma-color-border-default);

    ${belowContainer(containerProps)`
        display: flex;
        flex-direction: column;
        align-items: start;
    `}
`

export const SkeletonCell = styled(SkeletonCellBase)`
    grid-column: ${p => p.$cell};
`

export const CardList = styled(CardListBase)`
    grid-template-columns:
        [name] minmax(0, 1fr)
        [description] minmax(0, 1fr)
        [actions] 40px;

    ${fromContainer(containerProps)`
        margin-block-start: 16px;
    `}
`

export const LoadingContainer = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 96px;
`
import React, { Fragment } from 'react'
import { cls } from 'utilities/dom'
import { Options, Option, OptionLabel, OptionInput } from './s'
import Tooltip, { useSingleton } from 'components/tooltip'

const RatingOptions = ({ value, max, enabled = true, tooltips = [], onChange, salt }) => {
    const [source, target] = useSingleton()

    value = parseInt(value)

    return (
        <Options>
            <Tooltip
                singleton={source}
                delay={[0, 250]} />
            {Array.from({ length: max }, (_, index) => {
                const id = `${salt}:option:${index}`
                const isChecked = value === index + 1

                const optionClassName = cls([
                    isChecked && 'checked'
                ])

                const [LabelWrapper, labelWrapperProps] = !!tooltips?.length ?
                    [Tooltip, {
                        singleton: target,
                        content: tooltips[index]
                    }] :
                    [Fragment, null]

                return (
                    <Option
                        {...(optionClassName ? { className: optionClassName } : null)}
                        key={id}>
                        <LabelWrapper {...labelWrapperProps}>
                            <OptionLabel htmlFor={id}>
                                {index + 1}
                            </OptionLabel>
                        </LabelWrapper>
                        <OptionInput
                            id={id}
                            name={name}
                            type="radio"
                            defaultValue={index + 1}
                            checked={isChecked}
                            disabled={!enabled}
                            onChange={onChange} />
                    </Option>
                )
            })}
        </Options>
    )
}

export default RatingOptions

import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Grid } from 'components/grid'
import { flexChildCss, flexChildShrinkCss } from 'components/flex'

export const Labels = styled(Grid)`
    user-select: none;
    position: relative;

    grid-template-columns: repeat(2, minmax(100px, 1fr));
    align-items: center;

    border-radius: 8px;
    height: 48px;
    padding-inline: 4px;
    background-color: var(--huma-color-surface-minimal);
    box-shadow: 0 0 0 1px var(--huma-color-surface-minimal);

    &.shrink {
        display: inline-grid;
    }

    &::before {
        content: "";
        position: relative;
        z-index: 0;

        grid-row: 1;
        grid-column: 1;

        height: 40px;
        background-color: var(--huma-color-surface-default);
        box-shadow: var(--huma-shadow-lowest);

        transition:
            transform .3s linear(0, .006, .025 2.8%, .101 6.1%, .539 18.9%, .721 25.3%, .849 31.5%, .937 38.1%, .968 41.8%, .991 45.7%, 1.006 50.1%, 1.015 55%, 1.017 63.9%, 1.001),
            border-radius .3s ease;

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }

    &:has([value="no"]:checked)::before {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:has([value="yes"]:checked)::before {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        transform: translateX(100%);
    }
`

export const Label = styled.label`
    cursor: pointer;
    position: relative;
    z-index: 1;
    user-select: none;

    display: grid;
    place-items: center;
    grid-row: 1;

    border-radius: 4px;
    height: 40px;
    padding: 0 16px;

    transition: color .1s ease-in-out;

    text-align: center;
    color: var(--huma-color-foreground-subtle);

    &:first-of-type {
        grid-column: 1;
    }

    &:last-of-type {
        grid-column: 2;
    }

    &:is(:hover, :focus-within, :has(:checked)) {
        color: var(--huma-color-foreground-default);
    }

    &:has(:focus-visible):focus-within {
        outline: 2px solid var(--huma-color-border-constructive-focus);
    }
`

export const Boolean = styled(motion.div).attrs(() => ({
    variants: {
        false: {
            backgroundColor: 'var(--huma-color-surface-minimal)'
        },
        true: {
            backgroundColor: 'var(--huma-color-surface-constructive-bold)'
        },
        transition: {
            type: 'spring',
            mass: 0.25,
            stiffness: 200,
            velocity: 4,
            damping: 50
        }
    },
    initial: 'false'
}))`
    ${flexChildShrinkCss}

    cursor: pointer;
    position: relative;

    border-radius: 8px;
    width: 48px;
    height: 32px;
    padding: 0 2px;
`

export const State = styled(motion.span).attrs(() => ({
    variants: {
        false: {
            left: '2px'
        },
        true: {
            left: '18px'
        },
        transition: {
            type: 'spring',
            mass: 0.25,
            stiffness: 200,
            velocity: 4,
            damping: 50
        }
    },
    initial: 'false'
}))`
    pointer-events: none;
    position: absolute;
    top: 2px;
    display: block;

    border-radius: 6px;
    width: 28px;
    height: 28px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-lowest);
`

export const BooleanLabel = styled.label`
    ${flexChildCss}

    cursor: pointer;
    padding-inline-end: 24px;

    font-size: 16px;
    line-height: 32px;
    color: var(--huma-color-foreground-subtle);
`
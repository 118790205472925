import React from 'react'
import { FilterRow, Form, SearchIcon, SearchField } from './s'

const Search = ({ placeholder, setSearch, salt }) => (
    <FilterRow>
        <Form>
            <SearchField
                salt={salt}
                label={false}
                placeholder={placeholder}
                name="search"
                field={{ unsettable: true }}
                onChange={({ search }) => setSearch(search)} />
            <SearchIcon size={16} />
        </Form>
    </FilterRow>
)

export default Search

import React from 'react'
import { useIntl } from 'react-intl'
import { getFullName, getJobTitle } from 'utilities/person'
import { getPeopleProfileUrl } from 'utilities/url'
import { isNil } from 'lodash'
import {
    Wrapper, Link,
    AvatarWrapper,
    Name, Meta
} from './s'
import { Deactivated } from 'components/person/s'
import Avatar from 'components/avatar'
import Tooltip from 'components/tooltip'
import PercentageLabel from 'components/percentage-label'
import SalaryDevelopmentTrigger from 'pages/salary/panels/development/trigger'

const User = ({ who, showingSalaryDevelopment, setShowingSalaryDevelopment }) => {
    const { formatMessage } = useIntl()

    const {
        employmentPercentage,
        status
    } = who

    const name = getFullName(who)
    const jobTitle = getJobTitle(who)?.name

    const isDeactivated = status?.active === false

    return (
        <Wrapper>
            <Link to={getPeopleProfileUrl(who)}>
                <AvatarWrapper>
                    {isDeactivated && (
                        <Tooltip
                            content={formatMessage({
                                id: 'person_status_deactivated',
                                defaultMessage: 'This account has been deactivated'
                            })}>
                            <Deactivated size={40} />
                        </Tooltip>
                    )}
                    <Avatar who={who} size={40} />
                </AvatarWrapper>
                <Name className="compact">{name}</Name>
            </Link>
            <Meta className="caption small compact interpoint-divider">
                {jobTitle && <span>{jobTitle}</span>}
                {(!isNil(employmentPercentage) && employmentPercentage !== 100) && (
                    <span>
                        <Tooltip content={formatMessage({
                            id: 'person_label_employment_percentage',
                            defaultMessage: 'Employment percentage'
                        })}>
                            <PercentageLabel decimal={employmentPercentage / 100} />
                        </Tooltip>
                    </span>
                )}
                <SalaryDevelopmentTrigger
                    onClick={() => setShowingSalaryDevelopment(who.id)}
                    disabled={!!showingSalaryDevelopment} />
            </Meta>
        </Wrapper>
    )
}

export default User
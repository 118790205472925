import styled from 'styled-components'
import { darkMode } from 'utilities/styled'
import { H2 } from 'components/typography/heading'
import { Grid, GridCell as GridCellBase } from 'components/grid'
import { Columns as ColumnsBase } from 'components/flex'
import { InlineMessage as InlineMessageBase } from 'components/message'
import LinkBase from 'components/link'
import { buttonStyles } from 'components/button'
import { fontSerif } from 's'

export const GridCell = styled(GridCellBase)`
    position: relative;
    display: grid;
    grid-template-rows: 56px 40px auto 40px;

    border: 2px solid var(--huma-color-border-default);
    border-radius: 8px;
    padding: 32px;
`

export const Columns = styled(ColumnsBase)`
    column-gap: 8px;
`

export const Status = styled.div`
    border-radius: 4px;
    height: 32px;
    padding: 6px 8px;

    &.info {
        background-color: var(--huma-color-surface-info-minimal);
    }

    &.warning {
        background-color: var(--huma-color-surface-warning-minimal);
    }

    &.success {
        background-color: var(--huma-color-surface-success-minimal);
    }

    &.error {
        background-color: var(--huma-color-surface-destructive-minimal);
    }
`

export const InlineMessage = styled(InlineMessageBase)`
    margin-bottom: 0;

    & * {
        height: 20px;
        line-height: 22px;
    }
`

export const Heading = styled(H2)`
    ${fontSerif}
    margin-bottom: 16px;
`

export const LogoWrapper = styled(Grid).attrs({ as: 'picture' })`
    grid-template-columns: 100px;
    align-items: center;
    height: 32px;
    margin-bottom: 24px;

    &:not(:has(img.dark)) {
        img {
            display: block !important;
        }
    }
`

export const Logo = styled.img`
    display: block;
    max-width: 100px;
    max-height: 32px;

    &.dark {
        display: none;
    }

    ${darkMode`
        display: none;

        &.dark {
            display: block;
        }
    `}
`

export const Link = styled(LinkBase)`
    ${buttonStyles.ghostCss}

    width: 100%;
    justify-content: center;

    &:hover,
    &:focus {
        text-decoration: none;
    }
`
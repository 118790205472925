import React, { useState, useRef, forwardRef, Fragment } from 'react'
import { useNotificationSettings } from 'contexts/notification-settings'
import { useIntl, FormattedMessage } from 'react-intl'
import { cls } from 'utilities/dom'
import { getIntegrationUrl } from 'utilities/url'
import { Expandable, Summary, Description, Channel, IconWrapper, ChannelName } from './s'
import Paragraph from 'components/typography/paragraph'
import Link from 'components/link'
import CheckboxFieldNode from 'components/form/field/checkbox'
import WordList from 'components/word-list'
import Form from 'components/form/controller'
import Tooltip from 'components/tooltip'
import { compact } from 'utilities/array'
import { getCategoryIcon } from 'utilities/categories'
import {
    Smartphone as Push,
    Mail as Email,
    Slack,
    MessageSquare as SMS
} from 'styled-icons/feather'

const CheckboxField = forwardRef((props, ref) => <CheckboxFieldNode {...props} ref={ref} />)

const NotificationItem = ({ which, category }) => {
    const { formatMessage } = useIntl()

    const checkboxRef = useRef()

    const {
        update,
        notifications
    } = useNotificationSettings()

    const [updating, setUpdating] = useState(null)

    const configuration = notifications.find(({ event }) => event === which)

    const {
        push,
        email,
        slack,
        sms = null
    } = configuration?.channels ?? {}

    const id = `notification:${which}`

    const updateConfiguration = async configuration => {
        const [channel] = Object.keys(configuration)

        setUpdating(`${which}:${channel}`)
        await update(which, configuration)
        setUpdating(null)
    }

    const Icon = getCategoryIcon(category)

    const descriptionTranslationKey = `notification_settings_${which}_description`
    const description = formatMessage({
        id: descriptionTranslationKey,
        defaultMessage: descriptionTranslationKey
    })

    const activeChannels = compact([
        !!push?.enabled && formatMessage(getChannelLabel('push')),
        !!email?.enabled && formatMessage(getChannelLabel('email')),
        !!slack?.enabled && formatMessage(getChannelLabel('slack')),
        !!sms?.enabled && formatMessage(getChannelLabel('sms'))
    ])

    const summary = !!activeChannels.length ? (
        <Summary>
            <span>
                <WordList
                    words={activeChannels}
                    key={`notification:summary:${which}:${activeChannels.join('-')}`} />
            </span>
        </Summary>
    ) : (
        <span>
            <FormattedMessage
                id="notification_settings_channels_none"
                defaultMessage="No notifications" />
        </span>
    )

    return (
        <Expandable
            icon={!!Icon && <Icon size={24} />}
            heading={formatMessage({
                id: `notification_settings_${which}_name`,
                defaultMessage: which
            })}
            summary={summary}>
            <>
                {(descriptionTranslationKey !== description) && (
                    <Description>{description}</Description>
                )}
                <Form>
                    {[{ push }, { email }, { slack }, { sms }]
                        .filter(channel => !!Object.values(channel)[0])
                        .map(channel => {
                            const [name, {
                                enabled = false,
                                active = true,
                                ...configuration
                            }] = Object.entries(channel)[0]

                            const Icon = getChannelIcon(name)
                            const label = formatMessage(getChannelLabel(name))
                            const inactive = configuration.type === 'integration' && !active

                            return (
                                <Channel key={`notification:${which}:channel:${name}`}>
                                    <IconWrapper {...(inactive ? { className: 'inactive' } : null)}>
                                        <Icon size={24} />
                                    </IconWrapper>
                                    <div>
                                        <ChannelName className={cls([
                                            'compact',
                                            inactive && 'inactive caption'
                                        ])}>
                                            {label}
                                        </ChannelName>
                                        {(inactive && enabled) && (
                                            <Paragraph className="caption compact small">
                                                <FormattedMessage
                                                    id="notification_settings_channel_integration_inactive"
                                                    defaultMessage="<link>Activate the {integration} integration<\/link> to use as a notification channel"
                                                    values={{
                                                        link: chunks => (
                                                            <Link
                                                                to={getIntegrationUrl({ type: name })}
                                                                className="constructive"
                                                                target="_blank">
                                                                {chunks}
                                                            </Link>
                                                        ),
                                                        integration: label
                                                    }} />
                                            </Paragraph>
                                        )}
                                    </div>
                                    {(name === 'slack' && !inactive) && (
                                        <Tooltip
                                            content={formatMessage({
                                                id: 'notification_settings_channel_slack_tooltip',
                                                defaultMessage: 'Slack notifications are currently not configurable, but this is something we plan to support in the future'
                                            })}
                                            placement="top-end">
                                            <div ref={checkboxRef}>
                                                <CheckboxField
                                                    className="compact"
                                                    label={false}
                                                    name={name}
                                                    field={{ value: active && enabled }}
                                                    onChange={updateConfiguration}
                                                    interaction="switch"
                                                    enabled={configuration.changable && (updating !== `${which}:${name}`)}
                                                    {...((updating === `${which}:${name}`) ? { loading: true } : null)}
                                                    salt={id} />
                                            </div>
                                        </Tooltip>
                                    )}
                                    {(name !== 'slack' || inactive) && (
                                        <CheckboxField
                                            className="compact"
                                            label={false}
                                            name={name}
                                            field={{ value: active && enabled }}
                                            onChange={updateConfiguration}
                                            interaction="switch"
                                            enabled={configuration.changable && (updating !== `${which}:${name}`)}
                                            {...((updating === `${which}:${name}`) ? { loading: true } : null)}
                                            salt={id} />
                                    )}
                                </Channel>
                            )
                        })
                    }
                </Form>
            </>
        </Expandable>
    )
}

const getChannelLabel = channel => ({
    push: {
        id: 'noun_push',
        defaultMessage: 'Push'
    },
    email: {
        id: 'noun_email',
        defaultMessage: 'Email'
    },
    slack: {
        id: 'noun_slack',
        defaultMessage: 'Slack'
    },
    sms: {
        id: 'noun_sms',
        defaultMessage: 'SMS'
    }
})[channel]

const getChannelIcon = channel => ({
    push: Push,
    email: Email,
    slack: Slack,
    sms: SMS
})[channel]

export default NotificationItem

import React from 'react'
import { useReveal } from 'hooks/reveal'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { cls } from 'utilities/dom'

const UneditableField = ({ className, label, ...props }) => {
    const {
        field = {},
        obscure = false
    } = props

    let { value } = field
    if(!value) {
        value = null
    }

    const [node, reveal] = useReveal(obscure)

    const displayClassName = cls([
        obscure && 'obscured',
        reveal && 'revealed'
    ])

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <ValueDisplay
                    className={displayClassName}
                    ref={node}>
                    {value}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default UneditableField

import styled from 'styled-components'
import { OrderedList as OrderedListBase, ListItem as ListItemBase } from 'components/list'
import Paragraph from 'components/typography/paragraph'

export const OrderedList = styled(OrderedListBase)`
    list-style: decimal inside;
    border-bottom: 1px solid var(--huma-color-border-default);
    margin: 0 0 24px;

    &.hanging {
        list-style: decimal outside;
    }

    &.naked {
        border: none;
    }
`

export const ListItem = styled(ListItemBase)`
    border-top: 1px solid var(--huma-color-border-default);
    padding-block: 16px;

    p:first-child {
        display: inline;

        ${OrderedList}.headings & {
            display: inline-block;
        }
    }

    ${OrderedList}.headings &::marker {
        font-weight: 500;
    }

    ${OrderedList}.hanging & {
        margin-inline-start: 48px;
    }

    ${OrderedList}.naked & {
        border: none;
    }

    & > :last-child {
        margin-block-end: 0;
    }
`

export const Instruction = styled(Paragraph)`
    &:not(:only-child) {
        margin-block-end: 8px;
    }

    ${OrderedList}.headings & {
        font-weight: 500;
    }
`
import styled from 'styled-components'
import { belowTablet, fromTablet } from 'utilities/styled'
import { motion } from 'framer-motion'
import { Scrollable } from 'modals/generic'
import { Columns, FlexChildShrink, flexChildShrinkCss } from 'components/flex'
import { CloseButton as CloseButtonBase } from 'components/button'
import StatisticsBase from 'pages/news/pages/article/view/statistics'
import { ArticleHeading } from 'components/typography/heading'
import Person from 'components/person'
import { tiptapContentCss } from 'components/tiptap/s'

export const Modal = styled(Scrollable)`
    padding: 0;

    ${belowTablet`
        align-self: flex-start;
        width: 100vw;
        max-width: 100vw;
        min-height: calc(100svh - 88px);
        margin: 88px 0 0;
        border-radius: 16px 16px 0 0;
    `}
`

export const Menu = styled(Columns).attrs(() => ({ as: 'nav' }))`
    justify-content: space-between;
    height: 72px;
    padding: 16px 88px;
    overflow: auto;

    ${belowTablet`
        padding: 16px;
    `}
`

export const MenuColumns = styled(Columns)`
    align-items: center;
`

export const MenuColumn = styled(FlexChildShrink)`
    [dir="ltr"] &:not(:last-child) {
        margin-right: 16px;
    }

    [dir="rtl"] &:not(:last-child) {
        margin-left: 16px;
    }
`

export const CloseButton = styled(CloseButtonBase)`
    position: static;
`

export const Article = styled.article`
    min-height: 600px;
    position: relative;

    ${belowTablet`
        min-height: calc(100svh - 160px);
    `}
`

export const Statistics = styled(StatisticsBase)`
    margin-left: 40px;
    margin-right: 40px;

    ${belowTablet`
        margin-left: 16px;
        margin-right: 16px;
    `}
`

export const FeaturedImage = styled(motion.div).attrs(() => ({
    variants: {
        hide: {
            height: '256px',
            opacity: 0
        },
        show: {
            height: 'auto',
            opacity: 1
        }
    },
    transition: {
        type: 'spring',
        mass: .25,
        stiffness: 100,
        velocity: 2
    },
    initial: 'hide'
}))`
    padding: 0 40px;
    position: relative;
    overflow: hidden;

    ${belowTablet`
        padding: 0;
    `}

    ${fromTablet`
        border-radius: 8px;
    `}

    img {
        display: block;
        max-width: 100%;
        max-height: 60svh;
        margin: 0 auto;

        ${fromTablet`
            border-radius: 8px;
        `}
    }
`

export const Content = styled.div`
    padding: 24px;
    position: relative;
    word-break: break-word;
    overflow-wrap: anywhere;

    ${fromTablet`
        width: var(--huma-global-content-tight-width);
        max-width: 100%;
        margin: 0 auto;
    `}
`

export const Heading = styled(ArticleHeading)`
    ${flexChildShrinkCss}
`

export const Byline = styled(Person)`
    ${flexChildShrinkCss}
    margin-bottom: 16px;
`

export const Body = styled.div`
    margin-bottom: 40px;

    ${tiptapContentCss}

    h1, h2, h3, h4 {
        scroll-margin-block-start: 16px;
    }

    :last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useReveal } from 'hooks/reveal'
import { cls } from 'utilities/dom'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { Clamp } from './s'

const ViewText = ({ className, label, ...props }) => {
    const {
        field = {},
        obscure = false
    } = props

    let {
        value,
        clamp = false
    } = field
    const [node, reveal] = useReveal(obscure)
    let displayClassName = []

    if(value) {
        displayClassName = [
            !clamp && 'multi',
            !!clamp && 'clamp',
            obscure && 'obscured',
            reveal && 'revealed'
        ]
    } else {
        displayClassName = ['empty']
    }

    const ValueWrapper = clamp ?
        Clamp :
        Fragment

    displayClassName = cls(displayClassName)

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <ValueDisplay
                    className={displayClassName}
                    ref={node}>
                    {!!value && (
                        <ValueWrapper>
                            {value}
                        </ValueWrapper>
                    )}
                    {!value && (
                        <FormattedMessage
                            id="empty_not_entered"
                            defaultMessage="Not entered" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewText

import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import debounce from 'lodash.debounce'
import isEqual from 'react-fast-compare'
import JsonData from 'form-serialize'
import {
    Heading, WidgetParagraph, Actions,
    DragDropWrapper, Sortables, SortableItem, IndexColumn, DragColumn
} from './s'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { FlexChildGrow } from 'components/flex'
import Select from 'components/form/input/select'
import { DragIndicator } from 'styled-icons/material'
import { Plain } from 'components/button'

const ColumnConfiguratorContent = ({ columns = [], defaultColumnsOrder = [], columnsOrder = [], configureColumns, salt }) => {
    const form = useRef()

    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1)
        list.splice(endIndex, 0, removed)

        return list
    }

    const onDragEnd = result => {
        const { destination, source } = result

        if(!destination || destination.index === source.index) {
            return
        }

        const order = reorder(
            columnsOrder,
            source.index,
            destination.index
        )

        configureColumns(order)
    }

    const changeColumnOrder = () => {
        // https://github.com/defunctzombie/form-serialize#options
        const options = {
            hash: true,
            empty: true
        }
        const data = JsonData(form.current, options)
        const order = Object.values(data)

        if(!isEqual([...order].sort(), [...columnsOrder].sort())) {
            configureColumns(order)
        }
    }

    const changeColumnOrderDebounced = debounce(changeColumnOrder, 100, {
        leading: false,
        trailing: true,
        maxWait: 300
    })

    const reset = () => {
        configureColumns(defaultColumnsOrder)
    }

    return (
        <form
            onChange={changeColumnOrderDebounced}
            ref={form}>
            <Heading>
                <FormattedMessage
                    id="edit_columns_label"
                    defaultMessage="Edit columns" />
            </Heading>
            <WidgetParagraph>
                <FormattedMessage
                    id="edit_columns_description"
                    defaultMessage="Choose what is shown in each column. Small screens will not display all columns." />
            </WidgetParagraph>
            <DragDropWrapper>
                <SortableItem>
                    <IndexColumn>1.</IndexColumn>
                    <FlexChildGrow>
                        <FormattedMessage
                            id="noun_name"
                            defaultMessage="Name" />
                    </FlexChildGrow>
                </SortableItem>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {provided => (
                            <Sortables
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {columnsOrder.map((value, index) => {
                                    const excludeColumnsIds = columns
                                        .filter(column => (columnsOrder.includes(column.id)) && column.id !== value)
                                        .map(({ id }) => id)

                                    const includedColumns = columns.filter(({ id }) => !excludeColumnsIds.includes(id))

                                    return (
                                        <Draggable
                                            key={value}
                                            isDragDisabled={false}
                                            disableInteractiveElementBlocking={true}
                                            draggableId={value}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                <SortableItem
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    $isDragging={snapshot.isDragging}>
                                                    <IndexColumn>{index + 2}.</IndexColumn>
                                                    <FlexChildGrow>
                                                        <Select
                                                            defaultValue={value}
                                                            name={value}>
                                                            {includedColumns
                                                                .map(includedColumn => (
                                                                    <option
                                                                        key={`${salt}:${includedColumn.id}`}
                                                                        value={includedColumn.id}>
                                                                        {includedColumn.title}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FlexChildGrow>
                                                    <DragColumn {...provided.dragHandleProps}>
                                                        <DragIndicator size={24} />
                                                    </DragColumn>
                                                </SortableItem>
                                            )}
                                        </Draggable>
                                    )}
                                )}
                                {provided.placeholder}
                            </Sortables>
                        )}
                    </Droppable>
                </DragDropContext>
            </DragDropWrapper>
            <Actions>
                <Plain
                    onClick={reset}
                    className="constructive">
                    <FormattedMessage
                        id="action_reset_defaults"
                        defaultMessage="Reset to defaults" />
                </Plain>
            </Actions>
        </form>
    )
}

export default ColumnConfiguratorContent

import React from 'react'
import { useIntl } from 'react-intl'
import {
    TopicWrapper,
    Paragraph, Content,
    Tags, Tag
} from './s'
import TiptapOutput from 'components/tiptap/output'
import { InlineMessage } from 'components/message'
import { Tag as TagIcon } from 'styled-icons/feather'

const TopicContent = ({ topic, salt }) => {
    const { formatMessage } = useIntl()

    const {
        summary,
        content,
        tags = []
    } = topic

    return (
        <TopicWrapper>
            <Paragraph>{summary}</Paragraph>
            <TiptapOutput
                content={content}
                element={Content} />
            {!!tags?.length && (
                <Tags>
                    <TagIcon size={16} />
                    {tags.map(tag => (
                        <Tag key={`${salt}:tag:${tag}`}>
                            {tag}
                        </Tag>
                    ))}
                </Tags>
            )}
            <InlineMessage
                type="info"
                className="compact"
                message={formatMessage({
                    id: 'handbooks_template_topic_message_edit_content',
                    defaultMessage: 'You can edit the content of this topic when it has been added to your handbook.'
                })} />
        </TopicWrapper>
    )
}

export default TopicContent

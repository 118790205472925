import styled from 'styled-components'

export const Content = styled.div`
    grid-row: content;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    row-gap: 24px;

    margin-inline: -32px;
    height: 100%;
    padding-inline: 32px;
`
import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { modalify, Wrapper, Veil, Modal, ScrollableContainer } from 'utilities/modal'
import Form from 'components/form/controller'
import StringField from 'components/form/field/string'
import CountryField from 'components/form/field/country'
import Message from 'components/message'
import Shortcuts from './shortcuts'
import Actions from 'components/form/actions'
import { Plain, Button } from 'components/button'
import { size } from 'utilities/object'

const AddressFiller = ({ className, salt, address: initialAddress, fields, message, ...props }) => {
    const { formatMessage } = useIntl()

    const [address, setAddress] = useState(initialAddress)
    const [updated, setUpdated] = useState(Date.now())

    const {
        dismiss,
        onDone,
        shortcutsMode = null,
        ...modal
    } = props

    const {
        show = false,
        outer = true
    } = modal

    const animate = show ? 'in' : 'out'

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    {!!outer && <Veil animate={animate} />}
                    <Modal
                        dismiss={dismiss}
                        key={`modal:address-filler:${salt}`}>
                        <ScrollableContainer
                            {...(className ? { className } : null)}
                            animate={animate}>
                            <Form
                                json={{ empty: false }}
                                layout="vertical"
                                silenceEvents={true}>
                                {({ touched, triggerChange, errors, trigger }) => (
                                    <>
                                        <StringField
                                            salt={salt}
                                            label={formatMessage({
                                                id: 'noun_address',
                                                defaultMessage: 'Address'
                                            })}
                                            name="line1"
                                            field={{
                                                value: address?.line1,
                                                required: true,
                                                include: 'always'
                                            }}
                                            controlProps={{
                                                autoComplete: 'street-address address-line1',
                                                'data-1p-ignore': 'false'
                                            }}
                                            onChange={({ line1 }) => setAddress(address => ({ ...address, line1 }))}
                                            key={`${salt}:line1:updated:${updated}`} />
                                        <StringField
                                            salt={salt}
                                            label={false}
                                            name="line2"
                                            field={{
                                                value: address?.line2,
                                                include: 'always'
                                            }}
                                            controlProps={{
                                                autoComplete: 'street-address address-line2',
                                                'data-1p-ignore': 'false'
                                            }}
                                            onChange={({ line2 }) => setAddress(address => ({ ...address, line2 }))}
                                            key={`${salt}:line2:updated:${updated}`} />
                                        <StringField
                                            salt={salt}
                                            label={formatMessage({
                                                id: 'address_label_postcode',
                                                defaultMessage: 'Postcode'
                                            })}
                                            name="postalCode"
                                            field={{
                                                value: address?.postalCode,
                                                required: true,
                                                include: 'always'
                                            }}
                                            controlProps={{
                                                autoComplete: 'postal-code',
                                                'data-1p-ignore': 'false'
                                            }}
                                            onChange={({ postalCode }) => setAddress(address => ({ ...address, postalCode }))}
                                            key={`${salt}:postalCode:updated:${updated}`} />
                                        <StringField
                                            salt={salt}
                                            label={formatMessage({
                                                id: 'address_label_city',
                                                defaultMessage: 'City'
                                            })}
                                            name="city"
                                            field={{
                                                value: address?.city,
                                                required: true,
                                                include: 'always'
                                            }}
                                            controlProps={{
                                                autoComplete: 'address-level2',
                                                'data-1p-ignore': 'false'
                                            }}
                                            onChange={({ city }) => setAddress(address => ({ ...address, city }))}
                                            key={`${salt}:city:updated:${updated}`} />
                                        <CountryField
                                            salt={salt}
                                            label={formatMessage({
                                                id: 'address_label_country',
                                                defaultMessage: 'Country'
                                            })}
                                            name="country"
                                            field={{
                                                value: address?.country,
                                                required: !fields?.country?.disabled,
                                                include: 'always'
                                            }}
                                            controlProps={{
                                                autoComplete: 'country-name',
                                                'data-1p-ignore': 'false'
                                            }}
                                            enabled={!fields?.country?.disabled}
                                            onChange={({ country }) => setAddress(address => ({ ...address, country }))}
                                            key={`${salt}:country:updated:${updated}`} />
                                        {!!fields?.country?.disabled?.message && <Message {...fields.country.disabled} />}
                                        {!!message?.message && <Message {...message} />}
                                        {!!shortcutsMode && (
                                            <Shortcuts
                                                mode={shortcutsMode}
                                                address={address}
                                                setAddress={address => {
                                                    setAddress(address)
                                                    Object.keys(address).forEach(key => triggerChange(key))
                                                    setUpdated(Date.now())
                                                }}
                                                salt={salt} />
                                        )}
                                        <Actions className="compact">
                                            <Plain
                                                className="neutral"
                                                onClick={dismiss}>
                                                <FormattedMessage
                                                    id="action_cancel"
                                                    defaultMessage="Cancel" />
                                            </Plain>
                                            <Button
                                                className="constructive"
                                                onClick={() => {
                                                    onDone({
                                                        ...empty,
                                                        ...address
                                                    })

                                                    dismiss()
                                                }}
                                                disabled={!touched.length || !!size(errors)}
                                                ref={trigger}>
                                                <FormattedMessage
                                                    id="action_done"
                                                    defaultMessage="Done" />
                                            </Button>
                                        </Actions>
                                    </>
                                )}
                            </Form>
                        </ScrollableContainer>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

const empty = {
    line1: null,
    line2: null,
    line3: null,
    postalCode: null,
    city: null,
    country: null
}

export const validate = address => (
    !!address?.line1?.trim() &&
    !!address?.postalCode?.trim() &&
    !!address?.city?.trim() &&
    !!address?.country?.trim()
)

export default AddressFiller

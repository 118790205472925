
import styled from 'styled-components'
import { fromLaptop, belowLaptop } from 'utilities/styled'
import { fontSerif } from 's'

export const TextLogoWrapper = styled.div`
    overflow: hidden;
    position: relative;
    display: block;

    white-space: nowrap;
    text-overflow: ellipsis;
    ${fontSerif}
    font-weight: 700;
    line-height: 40px;
    text-decoration: none;
    color: var(--huma-color-foreground-default);

    &.small {
        width: auto !important;
        height: 32px;
        font-size: 20px;
        line-height: 32px;
    }

    ${fromLaptop`
        margin-inline-end: 32px;
        width: 200px;
        aspect-ratio: 5 / 1;

        /*
            Sets a min value of 16px and a max value of 24px.
            The base value is based on a calculation which assumes the optimal
            font size for a 26 characters long name to be 1.6ch, and 3.2ch – or
            double the size – for a 13 characters long name.

            To ensure that the base value in the following equation equals 41.6,
            we adjust the fontSize correspondingly as the stringLength
            (clamped to 13, 26) changes

            stringLength * fontSize = 41.6

            Given the two known values, baseValue and stringLength,
            we calculate the optimal font size like this:

            fontSize = 41.6 / stringLength

            The fallback value, 1.8ch, is the result of this equation
            given a stringLength of 13
        */

        font-size: ${p => p.$textLength > 16 ? `clamp(16px, ${p.$textLength ? (41.6 / p.$textLength).toFixed(2) : 1.8}ch, 24px)` : '24px'};
    `}

    ${belowLaptop`
        max-width: max(160px, 50vw);
        height: 32px;

        font-size: 24px;
        line-height: 44px;

        &.small {
            height: 24px;
        }
    `}
`

export const LogoWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;

    /* 5:1 */
    width: 200px;
    height: 40px;

    &.small {
        width: 160px;
        height: 32px;

        ${belowLaptop`
            width: 120px;
            height: 24px;
        `}
    }

    ${belowLaptop`
        width: 160px;
        height: 32px;
    `}

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 0% 50%;
    }
`
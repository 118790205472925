import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'

export const FieldValue = styled.div`
    display: grid;
    grid-template-columns: 24px auto;
    align-items: center;
    column-gap: 24px;

    > svg {
        ${svgStroke()}
    }
`
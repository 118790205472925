import React from 'react'
import { Buffer, ProgressBar } from './s'

const ProgressIndicator = ({ values, salt, ...props }) => (
    <Buffer
        {...props}
        role="progressbar">
        {values.reverse().map((value, index) => (
            <ProgressBar
                $value={value}
                key={`${salt}:progress:${index}`} />
        ))}
    </Buffer>
)

export default ProgressIndicator
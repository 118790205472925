import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { prune } from 'utilities/array'
import {
    DefaultDocument,
    SummaryDocument,
    TitledDocument
} from '../schema'
import { getExtensionWrapper } from '../extensions/utilities'

// Utility
import History from '@tiptap/extension-history'
import Typography from '@tiptap/extension-typography'
import Dropcursor from '@tiptap/extension-dropcursor'
import Gapcursor from '@tiptap/extension-gapcursor'
import BubbleMenu from '@tiptap/extension-bubble-menu'
import Placeholder from '@tiptap/extension-placeholder'
import UniqueID from '@tiptap-pro/extension-unique-id'
import CharacterCount from '@tiptap/extension-character-count'
import Text from '@tiptap/extension-text'
import HardBreak from '@tiptap/extension-hard-break'

// Block
import Title from '../extensions/title'
import TitleHeading from '../extensions/title-heading'
import DefaultHeading from '@tiptap/extension-heading'
import Paragraph from '@tiptap/extension-paragraph'
// import Image from '@tiptap/extension-image'
import Blockquote from '@tiptap/extension-blockquote'
import CustomCodeBlock from '../extensions/code-block'
import CustomYoutube from '../extensions/youtube'
import CustomVimeo from '../extensions/vimeo'
import HorizontalRule from '@tiptap/extension-horizontal-rule'

// List
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'

import Indent from '../extensions/indent'

// Inline
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import Code from '@tiptap/extension-code'
import CustomHighlight from '../extensions/highlight'
import Strike from '@tiptap/extension-strike'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import CustomEmoji from '../extensions/emoji'
import CustomMention from '../extensions/mention'
import Variable from '../extensions/variable'

import { purple } from 'constants/colors'

export const useExtensions = ({
    mode = 'edit',
    extend = {},
    configure = {},
    every = () => true,
    some = () => true,
    output = false
}) => {
    const { formatMessage } = useIntl()
    const { locale } = useI18n()

    const Document = getDocument(configure?.docType)
    const titled = configure?.docType === 'titled'

    const Heading = titled ?
        TitleHeading :
        DefaultHeading

    const headingLevels = (configure?.headingLevels ?? [2, 3, 4])

    const ExtensionWrapper = getExtensionWrapper(output)

    const extensions = prune([
        // Utility
        Document.configure({
            ...(configure?.Document ?? null)
        }),

        ...((mode === 'edit') ? [
            History.configure({
                ...(configure?.History ?? null)
            }),
            Typography.configure({
                ...(configure?.Typography ?? null)
            }),
            Dropcursor.configure({
                ...(configure?.Dropcursor ?? null)
            }),
            Gapcursor.configure({
                ...(configure?.Gapcursor ?? null)
            }),
            BubbleMenu.configure({
                shouldShow: ({ editor }) => [
                    'bulletList',
                    'orderedList',
                    'link'
                ].some(editor.isActive),
                ...(configure?.BubbleMenu ?? null)
            }),
            Placeholder.configure({
                showOnlyCurrent: !titled,
                placeholder: ({ node }) => ({
                    title: formatMessage({
                        id: 'editor_block_title_placeholder',
                        defaultMessage: 'Add title…'
                    }),
                    heading: formatMessage({
                        id: 'editor_block_heading_placeholder',
                        defaultMessage: 'Add heading…'
                    }),
                    ...(configure?.Placeholder?.nodes ?? null)
                })[node.type.name] ?? formatMessage({
                    id: 'editor_tiptap_placeholder',
                    defaultMessage: 'Start writing, or use Markdown to format your content…'
                }),
                ...(configure?.Placeholder ?? null)
            }),
            CharacterCount.configure({
                mode: 'nodeSize',
                ...(configure?.CharacterCount ?? null)
            })
        ] : []),

        UniqueID.configure({
            types: [
                'heading', 'paragraph',
                'bulletList', 'orderedList', 'listItem'
            ],
            ...(configure?.UniqueId ?? null)
        }),

        ExtensionWrapper(Text, {
            configure: configure?.Text
        }),
        ExtensionWrapper(HardBreak, {
            enabled: every('HardBreak'),
            configure: configure?.HardBreak
        }),

        // Inline
        ExtensionWrapper(Bold, {
            enabled: every('Bold'),
            extend: { inline: true },
            configure: configure?.Bold,
        }),
        ExtensionWrapper(Italic, {
            enabled: every('Italic'),
            extend: { inline: true },
            configure: configure?.Italic,
        }),
        ExtensionWrapper(Link, {
            enabled: every('Link'),
            extend: { excludes: 'highlight' },
            configure: {
                openOnClick: false,
                ...(configure?.Link ?? null)
            }
        }),
        ExtensionWrapper(Underline, {
            enabled: every('Underline'),
            configure: configure?.Underline
        }),
        ExtensionWrapper(Strike, {
            enabled: every('Strike'),
            configure: configure?.Strike
        }),
        ExtensionWrapper(Code, {
            enabled: every('Code'),
            extend: { excludes: '_' },
            configure: configure?.Code
        }),
        ExtensionWrapper(Subscript, {
            enabled: every('Subscript'),
            extend: { excludes: 'superscript' },
            configure: configure?.Subscript
        }),
        ExtensionWrapper(Superscript, {
            enabled: every('Superscript'),
            extend: { excludes: 'subscript' },
            configure: configure?.Superscript
        }),
        ExtensionWrapper(CustomHighlight, {
            enabled: every('Highlight'),
            extend: {
                inclusive: true,
                excludes: '_'
            },
            configure: configure?.Highlight
        }),

        // Block
        ExtensionWrapper(Title, {
            enabled: titled,
            extend: { marks: '' },
            configure: {
                ...(configure?.Title ?? null),
                levels: [1]
            }
        }),
        ExtensionWrapper(Heading, {
            enabled: every('Heading'),
            extend: {
                marks: '',
                ...(extend?.Heading ?? null)
            },
            configure: {
                ...(configure?.Heading ?? null),
                levels: prune([
                    titled && 1,
                    ...headingLevels
                ]),
                titled
            }
        }),
        ExtensionWrapper(Paragraph, {
            enabled: every('Paragraph'),
            configure: configure?.Paragraph
        }),
        ExtensionWrapper(CustomCodeBlock, {
            enabled: every('CodeBlock'),
            extend: { marks: '' },
            configure: configure?.CodeBlock
        }),
        ExtensionWrapper(CustomYoutube, {
            enabled: every('Youtube'),
            configure: {
                width: '100%',
                height: 'auto',
                nocookie: true,
                interfaceLanguage: locale,
                modestBranding: true,
                ...(configure?.Youtube ?? null)
            }
        }),
        ExtensionWrapper(CustomVimeo, {
            enabled: every('Vimeo'),
            configure: {
                width: '100%',
                height: 'auto',
                dnt: true,
                color: purple.ten.c.replace(/#/g, ''),
                pip: false,
                ...(configure?.Vimeo ?? null)
            }
        }),
        // ExtensionWrapper(Image, {
        //     enabled: every('Image'),
        //     configure: {
        //         allowBase64: true,
        //         ...(configure?.Image ?? null)
        //     }
        // }),
        ExtensionWrapper(Blockquote, {
            enabled: every('Blockquote'),
            extend: { content: 'paragraph*' },
            configure: configure?.Blockquote
        }),
        ExtensionWrapper(HorizontalRule, {
            enabled: every('HorizontalRule'),
            configure: configure?.HorizontalRule
        }),

        // List
        ExtensionWrapper(BulletList, {
            enabled: every('BulletList', 'ListItem'),
            configure: configure?.BulletList
        }),
        ExtensionWrapper(OrderedList, {
            enabled: every('OrderedList', 'ListItem'),
            configure: configure?.OrderedList
        }),
        ExtensionWrapper(ListItem, {
            enabled: every('ListItem') && some('BulletList', 'OrderedList'),
            configure: configure?.ListItem
        }),

        ExtensionWrapper(Indent, {
            enabled: every('Indent'),
            configure: configure?.Indent
        }),

        // Custom
        ExtensionWrapper(CustomEmoji, {
            enabled: every('Emoji'),
            configure: configure?.Emoji
        }),
        ExtensionWrapper(CustomMention, {
            enabled: every('Mention'),
            configure: configure?.Mention
        }),
        ExtensionWrapper(Variable, {
            enabled: every('Variable'),
            configure: configure?.Variable
        })
    ])

    return extensions
}

const getDocument = type => ({
    summary: SummaryDocument,
    titled: TitledDocument
})[type] ?? DefaultDocument
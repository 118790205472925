import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSalaryRevision } from 'contexts/salary-revision'
import {
    containerProps,
    CardList,
    SkeletonListRow, SkeletonCell,
    LoadingContainer
} from './s'
import {
    useSkeletonLoader,
    SkeletonStrings, SkeletonAvatars, SkeletonStatus
} from 'components/skeleton'
import { Static as Empty } from 'components/empty'
import { Ghost } from 'components/button'
import Loader from 'components/loader'
import Group from './group'

const SalaryRevisionGroups = props => {
    const {
        groups: revisionGroups,
        fetchGroups
    } = useSalaryRevision()

    const {
        items: groups = [],

        intersecter,
        loading,
        paging = {},
        fetching,
        autoFetch,
        hasFetched
    } = revisionGroups ?? {}

    const skeletonLength = useSkeletonLoader(groups, hasFetched)

    const noGroups = !!hasFetched && !groups.length

    if(noGroups) {
        return (
            <Empty>
                <FormattedMessage
                    id="salary_revision_groups_message_empty"
                    defaultMessage="No groups have been added yet." />
            </Empty>
        )
    }

    return (
        <CardList {...containerProps}>
            <CardList.Header>
                <CardList.Cell $cell="name">
                    <FormattedMessage
                        id="noun_name"
                        defaultMessage="Name" />
                </CardList.Cell>
                <CardList.Cell $cell="reviewers">
                    <FormattedMessage
                        id="salary_revision_label_reviewers"
                        defaultMessage="Reviewers" />
                </CardList.Cell>
                <CardList.Cell $cell="budget">
                    <FormattedMessage
                        id="noun_budget"
                        defaultMessage="Budget" />
                </CardList.Cell>
                <CardList.Cell $cell="status">
                    <FormattedMessage
                        id="noun_status"
                        defaultMessage="Status" />
                </CardList.Cell>
            </CardList.Header>
            {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                <SkeletonListRow
                    columns={4}
                    key={`list:salary:revision-groups:skeleton:${index}`}>
                    <SkeletonCell $cell="name">
                        <SkeletonStrings
                            size={20}
                            length={16} />
                    </SkeletonCell>
                    <SkeletonCell $cell="reviewers">
                        <SkeletonAvatars />
                    </SkeletonCell>
                    <SkeletonCell $cell="budget">
                        <SkeletonStrings
                            size={20}
                            length={16} />
                    </SkeletonCell>
                    <SkeletonCell $cell="status">
                        <SkeletonStatus />
                    </SkeletonCell>
                </SkeletonListRow>
            ))}
            {groups?.map((group, index) => {
                const last = index + 1 === groups.length

                return (
                    <Group
                        {...props}
                        group={group}
                        {...(last ? { ref: intersecter } : null)}
                        key={group.id} />
                )
            })}
            {!!paging?.hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost
                            className="constructive"
                            onClick={fetchGroups}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
        </CardList>
    )
}

export default SalaryRevisionGroups
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMeetingEvents } from 'contexts/meeting-events'
import { useTheme, ThemeProvider } from 'styled-components'
import {
    Wrap, Header,
    GridColumnHeader, GridColumnActionsHeader,
    SkeletonListRow, SkeletonCell, SkeletonProgressCell,
    LoadingContainer, Tutorial
} from './s'
import { SkeletonAvatarAndFullName, SkeletonCircle, SkeletonStrings } from 'components/skeleton'
import Event from './event'
import { areFiltersApplied } from 'pages/meetings/components/events-filter'
import Empty from 'components/empty'
import Caption from 'components/typography/caption'
import Loader from 'components/loader'
import { Ghost } from 'components/button'
import { clamp } from 'utilities/math'
import { getActionsArray } from 'widgets/context-menu'

const MeetingEvents = ({ actions = {}, header = false, hasLoaded = true, from = null, className }) => {
    let theme = useTheme()

    const {
        events,
        intersecter,
        loading,
        paging = {},
        filter = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched,
        flash,
        clearFlash
    } = useMeetingEvents()

    const [skeletonLength, setSkeletonLength] = useState(5)

    const hasActions = !!getActionsArray(actions).length
    const { hasNextPage } = paging

    theme = {
        ...theme,
        meetingEvents: {
            actions: hasActions
        }
    }

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(events.length, 1, 10))
        }
    }, [hasFetched, events?.length])

    const filtersApplied = areFiltersApplied(filter)

    return (
        <ThemeProvider theme={theme}>
            <Wrap {...(className ? { className } : null)}>
                {(header || fetching) && (
                    <Header>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_name"
                                defaultMessage="Name" />
                        </GridColumnHeader>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_status"
                                defaultMessage="Status" />
                        </GridColumnHeader>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_meeting_date"
                                defaultMessage="Meeting date" />
                        </GridColumnHeader>
                        {!!hasActions && <GridColumnActionsHeader />}
                    </Header>
                )}
                {(!events.length && hasFetched && hasLoaded) && (
                    <>
                        {!filtersApplied && <Tutorial which="meetings" />}
                        {filtersApplied && (
                            <Empty which="meetings">
                                <Caption className="compact">
                                    <FormattedMessage
                                        id="meetings_empty_filter_message"
                                        defaultMessage="🤷 Sorry, no meetings found." />
                                </Caption>
                            </Empty>
                        )}
                    </>
                )}
                {((!!fetching && !hasFetched) || !hasLoaded) && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow
                        columns={3}
                        hasActions={!!hasActions}
                        key={`list:meeting:events:skeleton:${index}`}>
                        <SkeletonCell>
                            <SkeletonAvatarAndFullName
                                size={40}
                                showSecondLine={index % 2 === 0} />
                        </SkeletonCell>
                        <SkeletonProgressCell>
                            <SkeletonCircle size={24} />
                            <SkeletonStrings
                                size={16}
                                length={10} />
                        </SkeletonProgressCell>
                        <SkeletonCell>
                            <SkeletonStrings
                                size={16}
                                length={12} />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {hasLoaded && events.map((event, index) => {
                    const last = index + 1 === events.length

                    return (
                        <Event
                            event={event}
                            actions={actions}
                            from={from}
                            {...(last ? { ref: intersecter } : null)}
                            flash={flash?.current === event ? clearFlash : null}
                            key={event.id} />
                    )
                })}
                {!!hasNextPage && (
                    <LoadingContainer>
                        {(!loading && !autoFetch) && (
                            <Ghost
                                className="constructive"
                                onClick={fetch}>
                                <FormattedMessage
                                    id="action_load_more"
                                    defaultMessage="Load more…"
                                />
                            </Ghost>
                        )}
                        {!!loading && <Loader />}
                    </LoadingContainer>
                )}
            </Wrap>
        </ThemeProvider>
    )
}

export default MeetingEvents
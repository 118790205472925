import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useTheme, ThemeProvider } from 'styled-components'
import { useAbsenceType } from 'contexts/absence-type'
import { useNavigate } from 'react-router-dom'
import { getActionsArray } from 'widgets/context-menu'
import { getAbsencePolicyUrl, getAbsencePolicyNewUrl } from 'utilities/url'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import {
    LoadingContainer,
    Wrap, Header, GridColumnHeader
} from './s'
import Loader from 'components/loader'
import Policy from './policy'
import Divider from 'components/divider'
import Caption from 'components/typography/caption'
import { Scrollable as Modal } from 'modals/generic'
import PreviewPolicy from 'pages/absence/modals/policy/preview'
import ArchivePolicy from 'pages/absence/modals/policy/archive'

const AbsencePolicies = ({ header = false, salt, ...props }) => {
    const { formatMessage } = useIntl()

    let theme = useTheme()

    const {
        type,
        updateDefaultPolicy,
        hasFetched
    } = useAbsenceType()

    const { policies = [] } = type ?? {}

    const navigate = useNavigate()

    const typeNameFormatter = useTypeNameFormatter()

    const [previewingPolicy, setPreviewingPolicy] = useState(null)
    const [promptingArchive, setPromptingArchive] = useState(null)

    const setDefault = id => updateDefaultPolicy(id, true)
    const unsetDefault = id => updateDefaultPolicy(id, false)

    const unsetDefaultPolicyAction = ({ id, default: defaultPolicy, type }) => {
        if(!defaultPolicy) {
            return null
        }

        return {
            salt: `${salt}:default:unset`,
            label: formatMessage({
                id: 'action_unset_default',
                defaultMessage: 'Remove as default'
            }),
            effect: 'neutral',
            onClick: () => unsetDefault(id),
            prompt: {
                question: formatMessage({
                    id: 'absence_policy_unset_default_prompt_question',
                    defaultMessage: 'Remove default policy for {type}'
                }, {
                    type: typeNameFormatter(type)
                }),
                message: formatMessage({
                    id: 'absence_policy_unset_default_prompt_message',
                    defaultMessage: 'New hires will not be assigned to any {type} policies.'
                }, {
                    type: typeNameFormatter(type)
                }),
                confirmText: formatMessage({
                    id: 'action_unset_default',
                    defaultMessage: 'Remove as default'
                })
            }
        }
    }

    const setDefaultPolicyAction = ({ id, default: defaultPolicy }) => {
        if(!!defaultPolicy) {
            return null
        }

        return {
            salt: `${salt}:default:set`,
            label: formatMessage({
                id: 'action_set_default',
                defaultMessage: 'Set as default'
            }),
            effect: 'neutral',
            onClick: () => setDefault(id)
        }
    }

    const editAction = ({ id }) => ({
        salt: `${salt}:edit`,
        label: formatMessage({
            id: 'action_edit',
            defaultMessage: 'Edit'
        }),
        effect: 'neutral',
        element: 'link',
        link: {
            to: getAbsencePolicyUrl({ id })
        }
    })

    const duplicateAction = ({ type, ...policy }) => ({
        salt: `${salt}:duplicate`,
        label: formatMessage({
            id: 'action_duplicate',
            defaultMessage: 'Duplicate'
        }),
        effect: 'neutral',
        onClick: () => navigate(getAbsencePolicyNewUrl({ type: type.name }), { state: { policy } })
    })

    const archiveAction = policy => ({
        salt: `${salt}:archive`,
        label: formatMessage({
            id: 'action_archive',
            defaultMessage: 'Archive'
        }),
        effect: 'destructive',
        onClick: () => setPromptingArchive(policy)
    })

    const actions = {
        unsetDefault: unsetDefaultPolicyAction,
        setDefault: setDefaultPolicyAction,
        edit: editAction,
        duplicate: duplicateAction,
        archive: archiveAction
    }

    const typeName = typeNameFormatter(type)

    if(!type || (!policies?.length && !hasFetched)) {
        return (
            <LoadingContainer>
                <Loader />
            </LoadingContainer>
        )
    }

    const hasActions = !!getActionsArray(actions).length

    theme = {
        ...theme,
        absencePolicies: {
            actions: hasActions
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Wrap {...props}>
                {!!header && (
                    <Header>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_policy"
                                defaultMessage="Policy" />
                        </GridColumnHeader>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="absence_policy_label_available_days"
                                defaultMessage="Available days" />
                        </GridColumnHeader>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_assigned"
                                defaultMessage="Assigned" />
                        </GridColumnHeader>
                        <GridColumnHeader />
                        {hasActions && <GridColumnHeader />}
                    </Header>
                )}
                {policies.map(policy => {
                    if(!!policy?.archived) {
                        return null
                    }

                    policy = {
                        ...policy,
                        type
                    }

                    return (
                        <Policy
                            policy={policy}
                            actions={actions}
                            setPreviewingPolicy={setPreviewingPolicy}
                            salt={`absence:policies:${policy.id}`}
                            key={`absence:policies:${policy.id}`} />
                    )
                })}
                {(hasFetched && !policies?.length) && (
                    <>
                        <Divider $size={16} />
                        <Caption className="compact">
                            <FormattedMessage
                                id="absence_policies_empty"
                                defaultMessage="No {type} policies have been added yet."
                                values={{ type: typeName }} />
                        </Caption>
                    </>
                )}
            </Wrap>
            <Modal
                show={!!previewingPolicy}
                dismiss={() => setPreviewingPolicy(null)}>
                <PreviewPolicy
                    {...previewingPolicy}
                    dismiss={() => setPreviewingPolicy(null)} />
            </Modal>
            <Modal
                show={promptingArchive}
                dismiss={() => setPromptingArchive(null)}>
                <ArchivePolicy
                    policy={promptingArchive}
                    onDone={() => setPromptingArchive(null)}
                    salt={salt} />
            </Modal>
        </ThemeProvider>
    )
}

export default AbsencePolicies

import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useTasks } from 'contexts/tasks'
import { useTheme, ThemeProvider } from 'styled-components'
import { clamp } from 'utilities/math'
import { getActionsArray } from 'widgets/context-menu'
import {
    Wrap, Header,
    GridColumnHeader, GridColumnActionsHeader,
    LoadingContainer, Tutorial, EmptyText
} from './s'
import Empty, { Generic as GenericEmpty } from 'components/empty'
import Link from 'components/link'
import Task from './task'
import Loader from 'components/loader'
import { Ghost } from 'components/button'
import { SkeletonRadioListRow, SkeletonCell, SkeletonRadio, SkeletonStrings } from 'components/skeleton'

const Tasks = ({ actions, mode = 'default', context = 'default', group = null, header = false, className }) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()

    const {
        tasks = [],
        intersecter,
        loading,
        filter = {},
        paging = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched,
        flash,
        clearFlash,
        toggleTaskCompleted
    } = useTasks()

    const [skeletonLength, setSkeletonLength] = useState(5)

    const hasActions = !!getActionsArray(actions).length
    const { hasNextPage } = paging

    theme.tasks = {
        mode,
        context,
        actions: hasActions
    }

    const completedDate = formatMessage({
        id: 'tasks_heading_date_completed',
        defaultMessage: 'Completed'
    })

    const dueDate = formatMessage({
        id: 'tasks_heading_date_due',
        defaultMessage: 'Due date'
    })

    const combinedDate = formatMessage({
        id: 'tasks_heading_date_combined',
        defaultMessage: 'Due / completed'
    })

    const dateHeader = ['true', 'false'].includes(filter?.completed) ?
        (filter?.completed === 'true') ?
            completedDate :
            dueDate :
        combinedDate

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(tasks.length, 1, 10))
        }
    }, [hasFetched, tasks?.length])

    const filtersApplied = ('completed' in filter)

    return (
        <ThemeProvider theme={theme}>
            <Wrap {...(className ? { className } : null)}>
                {((header || !!fetching) && (context !== 'dashboard')) && (
                    <Header>
                        <GridColumnHeader />
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_title"
                                defaultMessage="Title" />
                        </GridColumnHeader>
                        {!processTemplateModes.includes(mode) && (
                            <GridColumnHeader>
                                {dateHeader}
                            </GridColumnHeader>
                        )}
                        {!processModes.includes(mode) && (
                            <GridColumnHeader>
                                <FormattedMessage
                                    id="label_assigned_to"
                                    defaultMessage="Assigned to" />
                            </GridColumnHeader>
                        )}
                        {!!hasActions && (
                            <GridColumnActionsHeader />
                        )}
                    </Header>
                )}
                {(!!fetching && !hasFetched) && (
                    <>
                        {[...Array(skeletonLength).keys()].map(index => (
                            <SkeletonRadioListRow key={`list:tasks:skeleton:${index}`} columns={3} hasActions={!!hasActions}>
                                <SkeletonCell>
                                    <SkeletonRadio />
                                </SkeletonCell>
                                <SkeletonCell>
                                    <SkeletonStrings size={24} />
                                </SkeletonCell>
                                {!processModes.includes(mode) && (
                                    <SkeletonCell>
                                        <SkeletonStrings size={24} />
                                    </SkeletonCell>
                                )}
                                {!processTemplateModes.includes(mode) && (
                                    <SkeletonCell>
                                        <SkeletonStrings size={24} />
                                    </SkeletonCell>
                                )}
                            </SkeletonRadioListRow>
                        ))}
                    </>
                )}
                {(!tasks.length && hasFetched && !fetching) && (
                    <>
                        {!!group && (
                            <Empty which="tasks">
                                <EmptyText>
                                    <FormattedMessage
                                        id="tasks_group_empty"
                                        defaultMessage="Tasks assigned to {group} will be displayed here."
                                        values={{ group: group.name }} />
                                </EmptyText>
                                <Link
                                    to="path:tasks.base"
                                    className="constructive">
                                    <FormattedMessage
                                        id="tasks_action_navigate_goto"
                                        defaultMessage="Go to tasks" />
                                </Link>
                            </Empty>
                        )}
                        {(!group && filtersApplied) && <GenericEmpty />}
                        {(!group && !filtersApplied) && <Tutorial which="tasks" />}
                    </>
                )}
                {tasks.map((task, index) => {
                    const last = index + 1 === tasks.length

                    return (
                        <Task
                            {...{
                                task,
                                toggleTaskCompleted,
                                actions,
                                header,
                                mode,
                                context,
                                ...((!!intersecter && last) ? { ref: intersecter } : null)
                            }}
                            flash={flash?.current === task ? clearFlash : null}
                            key={task.id} />
                    )
                })}
                {(!!intersecter && !!hasNextPage) && (
                    <LoadingContainer>
                        {(!loading && !autoFetch) && (
                            <Ghost
                                className="constructive"
                                onClick={fetch}>
                                <FormattedMessage
                                    id="action_load_more"
                                    defaultMessage="Load more…"
                                />
                            </Ghost>
                        )}
                        {!!loading && <Loader />}
                    </LoadingContainer>
                )}
                {(!!tasks?.length && !!fetching && context === 'dashboard') && (
                    <SkeletonRadioListRow
                        columns={3}
                        hasActions={!!hasActions}>
                        <SkeletonCell>
                            <SkeletonRadio />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings size={24} />
                        </SkeletonCell>
                        {!processModes.includes(mode) && (
                            <SkeletonCell>
                                <SkeletonStrings size={24} />
                            </SkeletonCell>
                        )}
                        {!processTemplateModes.includes(mode) && (
                            <SkeletonCell>
                                <SkeletonStrings size={24} />
                            </SkeletonCell>
                        )}
                    </SkeletonRadioListRow>
                )}
            </Wrap>
        </ThemeProvider>
    )
}

const processTypes = ['onboarding', 'offboarding', 'process']

export const processTemplateModes = processTypes.map(type => `${type}:template`)
export const processDraftModes = processTypes.map(type => `${type}:draft`)
export const processStartedModes = processTypes.map(type => `${type}:started`)

export const processModes = [
    ...processTemplateModes,
    ...processDraftModes,
    ...processStartedModes
]

export default Tasks
import styled from 'styled-components'

export const Columns = styled.label`
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    align-items: center;
    grid-column-gap: 16px;
    height: 24px;
    cursor: pointer;
`

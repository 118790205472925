import React from 'react'
import Modal from 'modals/fullscreen'
import { Wrapper, Image, CloseButton } from './s'

const ImagePreview = ({ imgUrl, maxWidth = 1000, title = '', show, dismiss, salt }) => {
    return (
        <Modal
            show={show}
            dismiss={dismiss}
            salt={salt}>
            {!!show && (
                <Wrapper>
                    <Image
                        src={imgUrl}
                        style={{ '--__huma-component-image-preview-size': `${maxWidth}px` }}
                        alt={title} />
                    <CloseButton onClick={dismiss} />
                </Wrapper>
            )}
        </Modal>
    )
}

export default ImagePreview
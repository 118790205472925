import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { Grid } from 'components/grid'

export const Layout = styled(Grid)`
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 16px;

    ${belowPhone`
        grid-template-columns: repeat(auto-fill, 1fr);
    `}
`
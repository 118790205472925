import styled from 'styled-components'
import {
    svgStroke,
    belowLaptop
} from 'utilities/styled'
import {
    Table as TableBase,
    TableColumnHeader as TableColumnHeaderBase,
    TableColumnGroupColumn,
    TableRow as TableRowBase,
    TableRowHeader as TableRowHeaderBase,
    TableCell as TableCellBase
} from 'components/table'
import { Inline } from 'components/code'
import { Grid } from 'components/grid'

export const Table = styled(TableBase)`
    margin-bottom: 40px;

    ${TableColumnGroupColumn} {
        ${belowLaptop`
            &:last-child {
                display: none;
            }
        `}
    }
`

export const TableColumnHeader = styled(TableColumnHeaderBase)`
    padding-block: 16px;
    border-bottom: 1px solid var(--huma-color-border-default);

    svg {
        position: relative;
        top: -1px;

        margin-inline-end: 8px;
    }

    ${belowLaptop`
        &:last-of-type {
            display: none;
        }
    `}
`

export const TableRow = TableRowBase

export const TableRowHeader = styled(TableRowHeaderBase)`
    vertical-align: top;
    padding-block: 16px;

    line-height: 24px;

    ${TableRow}.disabled & {
        text-decoration: line-through;
    }

    ${TableRow}:not(:last-child) & {
        border-bottom: 1px solid var(--huma-color-border-default);
    }
`

export const TableCell = styled(TableCellBase)`
    vertical-align: top;
    padding-block: 16px;

    &:first-child {
        padding-block: 20px;

        svg {
            ${svgStroke('small')}

            position: relative;
            top: -2px;
        }
    }

    &:not(:last-child) {
        padding-inline-end: 16px;
    }

    ${belowLaptop`
        &:last-child {
            display: none;
        }
    `}

    ${TableRow}.disabled & {
        opacity: .33;
    }

    ${TableRow}:not(:last-child) & {
        border-bottom: 1px solid var(--huma-color-border-default);
    }
`

export const Markdowns = styled(Grid)`
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-row-gap: 6px;
    justify-content: start;

    padding-block: 3px;
`

export const Markdown = styled(Inline)`
    display: inline-block;

    ${TableRow}.disabled & {
        color: var(--huma-color-foreground-default);
    }
`

export const Keys = styled(Grid).attrs({ as: 'span' })`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 4px;
    justify-content: start;
`

export const Key = styled(Grid).attrs({ as: 'span' })`
    --__huma-component-key-border-color: var(--huma-color-border-default);
    --__huma-component-key-background-color: var(--huma-color-surface-default);
    --__huma-component-key-shadow-1: var(--huma-palette-neutral-80);
    --__huma-component-key-shadow-2: var(--huma-palette-neutral-90);
    --__huma-component-key-text-color: var(--huma-color-foreground-default);

    place-items: center;

    border: 1px solid var(--__huma-component-key-border-color);
    border-radius: 4px;
    min-width: 24px;
    height: 24px;
    padding: 2px 4px 0;
    box-shadow:
        0 0 1px var(--__huma-component-key-shadow-1),
        0 2px 0 var(--__huma-component-key-shadow-2);

    font-size: 14px;
    color: var(--__huma-component-key-text-color);

    html[data-theme="dark"] #bottom-panel & {
        --__huma-component-key-border-color: var(--huma-palette-neutral-90);
        --__huma-component-key-background-color: var(--huma-palette-white);
        --__huma-component-key-text-color: var(--huma-palette-neutral-10);
    }

    html[data-theme="dark"] &:not(#bottom-panel &) {
        --__huma-component-key-shadow-1: var(--huma-palette-neutral-20);
        --__huma-component-key-shadow-2: var(--huma-palette-neutral-30);
    }

    ${Keys}.fill & {
        background-color: var(--__huma-component-key-background-color);
    }

    ${Keys}.smaller & {
        min-width: 20px;
        height: 20px;

        font-size: 12px;
    }
`
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useSalaryRevisionGroup } from 'contexts/salary-revision-group'
import {
    containerProps,
    CardList, Cell
} from './s'
import { Static as Empty, Generic as NoResults } from 'components/empty'
import User from './user'

const SalaryRevisionGroupUsers = props => {
    const { formatMessage } = useIntl()

    const {
        group,
        sorting = {},
        toggleSorting,
        allUsersHidden = false,
        refreshedAt,
        hasFetched
    } = useSalaryRevisionGroup()

    const { users = [] } = group

    const noUsers = !!hasFetched && !users.length

    if(noUsers) {
        return (
            <Empty>
                <FormattedMessage
                    id="salary_revision_group_users_message_empty"
                    defaultMessage="No members have been added yet." />
            </Empty>
        )
    }

    if(allUsersHidden) {
        return <NoResults />
    }

    return (
        <CardList {...containerProps}>
            <CardList.Header>
                <CardList.Sortable
                    field="name"
                    sort={sorting}
                    toggle={toggleSorting}
                    text={formatMessage({
                        id: 'person_label_name',
                        defaultMessage: 'Name'
                    })}
                    $cell="name" />
                <CardList.Sortable
                    field="employmentStartDate"
                    sort={sorting}
                    toggle={toggleSorting}
                    text={formatMessage({
                        id: 'person_label_employment_seniority',
                        defaultMessage: 'Seniority'
                    })}
                    $cell="seniority" />
                <CardList.Sortable
                    field="currentSalary"
                    sort={sorting}
                    toggle={toggleSorting}
                    text={formatMessage({
                        id: 'salary_revision_heading_current',
                        defaultMessage: 'Current'
                    })}
                    tooltip={{
                        content: formatMessage({
                            id: 'salary_label_current_salary',
                            defaultMessage: 'Current salary'
                        })
                    }}
                    $cell="current" />
                <CardList.Cell $cell="change">
                    <FormattedMessage
                        id="salary_revision_heading_change"
                        defaultMessage="Change" />
                </CardList.Cell>
                <CardList.Sortable
                    field="newSalary"
                    sort={sorting}
                    toggle={toggleSorting}
                    text={formatMessage({
                        id: 'salary_revision_heading_new',
                        defaultMessage: 'New'
                    })}
                    tooltip={{
                        content: formatMessage({
                            id: 'salary_label_new_salary',
                            defaultMessage: 'New salary'
                        })
                    }}
                    $cell="new" />
                <Cell $cell="notes">
                    <FormattedMessage
                        id="noun_notes"
                        defaultMessage="Notes" />
                </Cell>
            </CardList.Header>
            {users.map(user => (
                <User
                    {...props}
                    {...user}
                    key={`${user.id}:${refreshedAt ?? 'current'}`} />
            ))}
        </CardList>
    )
}

export default SalaryRevisionGroupUsers
import React, { useEffect, forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    TogglerWrap, TogglerButton,
    TogglerWithActionsWrap, TogglerWithActionsButton
} from './s'
import { Plain, UnsetValueButton, UnsetValueButtonXCircle } from 'components/button'
import { getEntityIcon } from 'components/entity'
import Symbol from 'components/symbol'
import { X as Unset } from 'styled-icons/feather'
import { cls } from 'utilities/dom'

export const useKeyboardEvents = ({ ref, active, click, dependencies }) => useEffect(() => {
    const openWidget = e => {
        const { keyCode, key } = e

        const tab = keyCode === 9
        const esc = keyCode === 27
        const modifier = e.getModifierState?.(key) ?? false
        const output = !!String.fromCharCode(keyCode)?.trim()

        if(!active && !tab && !esc && !modifier && output) {
            click()
        }
    }

    ref?.current?.addEventListener('keydown', openWidget)
    return () => ref?.current?.removeEventListener('keydown', openWidget)
}, dependencies)

// eslint-disable-next-line no-unused-vars
const Toggler = ({ children, type, showIcon = true, symbol, className, active, unset, unsettable, forwardedRef, ...props }) => {
    const {
        onClick,
        disabled
    } = props

    const EntityIcon = getEntityIcon(type)

    className = cls([
        className,
        (showIcon && EntityIcon) && 'with-icon',
        (showIcon && symbol) && 'with-symbol',
        active && 'interacting'
    ])

    useKeyboardEvents({
        ref: forwardedRef,
        active,
        click: onClick,
        dependencies: [active]
    })

    return (
        <TogglerWrap>
            <TogglerButton
                {...props}
                {...(className ? { className } : null)}
                tabIndex={0}
                disabled={disabled}
                {...(!!forwardedRef ? { ref: forwardedRef } : null)}>
                {children}
                {showIcon && (
                    <>
                        {(!!EntityIcon && !symbol && (!unset || disabled)) && <EntityIcon size={20} />}
                        {(!!symbol && !EntityIcon && (!unset || disabled)) && (
                            <Symbol
                                symbol={symbol}
                                size={20}>
                                {symbol}
                            </Symbol>
                        )}
                    </>
                )}
            </TogglerButton>
            {(!!unset && !disabled) && (
                <UnsetValueButton
                    onClick={unset}
                    disabled={disabled}>
                    <UnsetValueButtonXCircle>
                        <Unset size={12} />
                    </UnsetValueButtonXCircle>
                </UnsetValueButton>
            )}
        </TogglerWrap>
    )
}

// eslint-disable-next-line no-unused-vars
const TogglerWithActions = ({ children, className, active, unset, unsettable, showIcon, forwardedRef, ...props }) => {
    const {
        onClick,
        disabled
    } = props

    const wrapClassName = cls([
        className,
        (unset || !unsettable) && 'has-value',
        (typeof unset === 'function') && 'has-unset'
    ])

    const buttonClassName = cls([
        className,
        (unset || !unsettable) && 'has-value',
        (typeof unset === 'function') && 'has-unset',
        active && 'interacting'
    ])

    useKeyboardEvents({
        ref: forwardedRef,
        active,
        click: onClick,
        dependencies: [forwardedRef?.current, active]
    })

    return (
        <TogglerWithActionsWrap {...(wrapClassName ? { className: wrapClassName } : null)}>
            {(unset || !unsettable) && (
                <TogglerWithActionsButton
                    {...props}
                    {...(buttonClassName ? { className: buttonClassName } : null)}
                    tabIndex={0}
                    disabled={disabled}
                    {...(!!forwardedRef ? { ref: forwardedRef } : null)}>
                    {children}
                </TogglerWithActionsButton>
            )}
            <Plain
                className="constructive"
                onClick={onClick}
                disabled={disabled}>
                <FormattedMessage
                    id={(unset || !unsettable) ?
                        'action_change' :
                        'action_set'
                    }
                    defaultMessage={(unset || !unsettable) ?
                        'Change' :
                        'Set'
                    } />
            </Plain>
            {(typeof unset === 'function') && (
                <Plain
                    className="destructive"
                    onClick={unset}
                    disabled={disabled}>
                    <FormattedMessage
                        id="action_clear"
                        defaultMessage="Clear" />
                </Plain>
            )}
        </TogglerWithActionsWrap>
    )
}

export const WithActions = forwardRef((props, ref) => (
    <TogglerWithActions
        {...props}
        forwardedRef={ref} />
))


export default forwardRef((props, ref) => (
    <Toggler
        {...props}
        forwardedRef={ref} />
))
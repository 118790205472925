import React, { forwardRef, Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { mergeRefs } from 'react-merge-refs'
import { useTheme, ThemeProvider } from 'styled-components'
import { useFlash } from 'hooks/flash'
import {
    PieceWrapper,
    PersonCellClickable, PersonCellFragment, EquipmentMessage,
    Unassigned, Symbol, UnassignedLabel,
    StatusCell, ExpiryStatus,
    VariantCell,
    ActionsCell
} from './s'
import Tooltip from 'components/tooltip'
import Person from 'components/person'
import MissingDates from 'pages/equipment/components/missing-dates'
import MobileLabel from 'components/typography/mobile-label'
import ContextMenu, { getActionsArray } from 'widgets/context-menu'

const EquipmentPiece = forwardRef(({ piece, actions = {}, singleton, flash }, ref) => {
    const { formatMessage } = useIntl()

    const theme = useTheme()

    const flasher = useFlash(flash)

    const {
        id,
        type,
        assignedTo,
        attachments,
        links,
        fromDate,
        toDate,
        serialNumber,
        variant
    } = piece

    const {
        symbol,
        requireFromDate,
        requireToDate,
        requireDocumentation,
        requireSerialNumber
    } = type ?? {}

    const missingDocumentation = !!requireDocumentation && (!attachments?.length && !links?.length)
    const missingSerialNumber = !!requireSerialNumber && !serialNumber

    theme.equipment = {
        variant: !!variant
    }

    const view = actions?.view?.(piece)

    const [PersonWrapper, personWrapperProps] = view ?
        [Tooltip, {
            content: formatMessage({
                id: 'action_view_details',
                defaultMessage: 'View details'
            }),
            singleton
        }] :
        [Fragment, null]

    const [PersonCell, personCellProps] = view ?
        [PersonCellClickable, { onClick: () => view.onClick() }] :
        [PersonCellFragment, null]

    const salt = `equipment:piece:${id}`

    return (
        <ThemeProvider theme={theme}>
            <PieceWrapper ref={mergeRefs([ref, flasher])}>
                <PersonWrapper {...personWrapperProps}>
                    <PersonCell {...personCellProps}>
                        {!!assignedTo && (
                            <Person
                                who={assignedTo}
                                truncate={true}
                                size={40} />
                        )}
                        {!assignedTo && (
                            <Unassigned>
                                <Symbol
                                    symbol={symbol}
                                    className="hover-effect" />
                                <UnassignedLabel>
                                    <FormattedMessage
                                        id="item_status_unassigned"
                                        defaultMessage="Unassigned" />
                                </UnassignedLabel>
                            </Unassigned>
                        )}
                    </PersonCell>
                </PersonWrapper>
                <VariantCell>
                    {!!variant && (
                        <>
                            <MobileLabel className="endspace">
                                <FormattedMessage
                                    id="equipment_label_variant"
                                    defaultMessage="Variant" />
                            </MobileLabel>
                            <span>{variant}</span>
                        </>
                    )}
                </VariantCell>
                <StatusCell>
                    <ExpiryStatus
                        from={fromDate}
                        to={toDate}
                        requireEnd={requireToDate} />
                    <MissingDates
                        requireStart={requireFromDate}
                        requireEnd={requireToDate}
                        start={fromDate}
                        end={toDate} />
                    {!!missingDocumentation && (
                        <EquipmentMessage
                            message={formatMessage({
                                id: 'equipment_status_documentation_missing',
                                defaultMessage: 'Documentation missing'
                            })}
                            type="error" />
                    )}
                    {!!missingSerialNumber && (
                        <EquipmentMessage
                            message={formatMessage({
                                id: 'equipment_status_serial_number_missing',
                                defaultMessage: 'Serial number missing'
                            })}
                            type="error" />
                    )}
                </StatusCell>
                {!!getActionsArray(actions)?.length && (
                    <ActionsCell>
                        <ContextMenu
                            salt={salt}
                            context={piece}
                            actions={actions} />
                    </ActionsCell>
                )}
            </PieceWrapper>
        </ThemeProvider>
    )
})

export default EquipmentPiece

import React from 'react'
import {
    Calendar as Date,
    Clock as Time,
    Shield as JobTitle,
    MapPin as Location,
    Users,
    User as Person,
    UserCheck as Role,
    Menu as ProcessTemplate,
    Flag as Country,
    Globe
} from 'styled-icons/feather'
import {
    Company,
    Team,
    Deviation,
    Language,
    SmartDocumentTemplate
} from 'components/feather'

const typeToEntityIconMap = {
    date: props => <Date {...props} />,
    time: props => <Time {...props} />,
    jobTitle: props => <JobTitle {...props} />,
    organization: props => <Users {...props} />,
    company: props => <Company {...props} />,
    location: props => <Location {...props} />,
    team: props => <Team {...props} />,
    group: props => <Users {...props} />,
    people: props => <Users {...props} />,
    person: props => <Person {...props} />,
    role: props => <Role {...props} />,
    processTemplate: props => <ProcessTemplate {...props} />,
    language: props => <Language {...props} />,
    country: props => <Country {...props} />,
    deviationCategory: props => <Deviation {...props} />,
    smartTemplate: props => <SmartDocumentTemplate {...props} />,
    universal: props => <Globe {...props} />
}

typeToEntityIconMap.user = typeToEntityIconMap.person

export const getEntityIcon = (type, mapping) => typeToEntityIconMap?.[mapping?.[type] ?? type] ?? null
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Search } from 'styled-icons/feather'
import StringField from 'components/form/field/string'
import LoaderBase from 'components/loader'

export const SearchIcon = styled(Search)`
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 12px;

    ${svgStroke()}
`

export const Field = styled(StringField)`
    &.searching input {
        padding-inline-end: 40px;
    }

    input {
        padding-inline-start: 48px;
        transition: padding-inline-end .2s ease-in-out;
    }
`

export const Loader = styled(LoaderBase)`
    position: absolute;
    top: 20px !important;
    right: -4px !important;
    bottom: auto;
    left: auto;
    margin: 0 !important;
`
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { ThemeProvider, useTheme } from 'styled-components'
import { useCompetenceProfiles } from 'contexts/competence-profiles'
import { clamp } from 'utilities/math'
import { getActionsArray } from 'widgets/context-menu'
import {
    Wrap,
    Header, GridColumnHeader,
    Tutorial, SkeletonListRow, SkeletonCell,
    LoadingContainer
} from './s'
import Tooltip, { useSingleton } from 'components/tooltip'
import { SkeletonAvatarAndFullName, SkeletonStrings } from 'components/skeleton'
import { Ghost } from 'components/button'
import Loader from 'components/loader'
import Profile from './profile'

const CompetenceProfiles = ({ actions = {}, ...props }) => {
    let theme = useTheme()

    const [source, target] = useSingleton()

    const [skeletonLength, setSkeletonLength] = useState(5)

    const {
        profiles = [],
        intersecter,
        paging = {},
        fetchProfiles,
        fetching,
        loading,
        autoFetch,
        hasFetched,
        flash,
        clearFlash
    } = useCompetenceProfiles()

    const hasActions = !!getActionsArray(actions).length

    theme = {
        ...theme,
        actions: hasActions
    }

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(profiles.length, 1, 10))
        }
    }, [hasFetched, profiles?.length])

    return (
        <ThemeProvider theme={theme}>
            <Tooltip
                singleton={source}
                placement="top-start"
                delay={[500, 250]} />
            <Wrap {...props}>
                {(!!profiles?.length || fetching) && (
                    <Header>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_name"
                                defaultMessage="Name" />
                        </GridColumnHeader>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_status"
                                defaultMessage="Status" />
                        </GridColumnHeader>
                    </Header>
                )}
                {(!profiles.length && hasFetched && !fetching) && (
                    <Tutorial which="competenceGap" />
                )}
                {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow
                        columns={2}
                        hasActions={true}
                        key={`list:competence:profiles:skeleton:${index}`}>
                        <SkeletonCell>
                            <SkeletonAvatarAndFullName size={40} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings size={20} />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {profiles.map((profile, index) => {
                    const last = index === ~-profiles.length

                    return (
                        <Profile
                            profile={profile}
                            actions={actions}
                            {...((!!intersecter && !last) ? { ref: intersecter } : null)}
                            singleton={target}
                            flash={flash?.current === profile ? clearFlash : null}
                            key={`list:competence:profiles:${profile.id}`} />
                    )
                })}
                {!!paging?.hasNextPage && (
                    <LoadingContainer>
                        {(!loading && !autoFetch) && (
                            <Ghost
                                className="constructive"
                                onClick={fetchProfiles}>
                                <FormattedMessage
                                    id="action_load_more"
                                    defaultMessage="Load more…"
                                />
                            </Ghost>
                        )}
                        {!!loading && <Loader />}
                    </LoadingContainer>
                )}
            </Wrap>
        </ThemeProvider>
    )
}

export default CompetenceProfiles
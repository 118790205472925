import React, { useState, useEffect, cloneElement } from 'react'
import { useIntl } from 'react-intl'
import TimePicker from 'modals/time-picker'
import { formatDataTime } from './'

const StandaloneTimePicker = ({ time, trigger: Trigger, mode = 'date', onChange, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const [value, setValue] = useState(null)
    const [picking, setPicking] = useState(false)

    const dataTimeFormatter = formatDataTime(mode)

    useEffect(() => {
        setValue(dataTimeFormatter(time))
    }, [time, mode])

    useEffect(() => {
        const time = dataTimeFormatter(value)

        if(time !== value) {
            onChange?.(time)
        }
    }, [value, mode])

    return (
        <>
            {cloneElement(Trigger, {
                onClick: e => {
                    e.stopPropagation()
                    setPicking(true)
                }
            })}
            <TimePicker
                {...props}
                show={picking}
                mode={mode}
                salt={salt}
                time={value ?? ''}
                past={false}
                dismiss={() => setPicking(false)}
                cancelAction={() => ({
                    label: formatMessage({
                        id: 'action_cancel',
                        defaultMessage: 'Cancel'
                    }),
                    effect: 'neutral',
                    onClick: () => setPicking(false)
                })}
                doneAction={({ picked }) => ({
                    label: formatMessage({
                        id: 'action_done',
                        defaultMessage: 'Done'
                    }),
                    effect: 'constructive',
                    onClick: () => {
                        setPicking(false)
                        setValue(picked)
                    }
                })} />
        </>
    )
}

export default StandaloneTimePicker
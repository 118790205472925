import styled from 'styled-components'
import { Grid } from 'components/grid'
import { Plain } from 'components/button'
import { Naked } from 'components/drawable-panel'

export const Wrapper = styled(Grid)`
    grid-template-rows: auto 1fr auto;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-high);
`

export const Header = styled.header`
    padding: 8px 16px;
    box-shadow: 0 1px var(--huma-color-border-default);
`

export const Content = styled.div``

export const AddButton = styled(Plain)`
    column-gap: 16px;
`

export const DrawablePanel = styled(Naked)`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &.toolbar > :first-child {
        padding: 16px;
    }

    &.toolbar.expanded > :first-child {
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    &.toolbar.expanded > :last-child {
        max-height: calc(50vh - var(--huma-global-main-layout-header-height) - var(--huma-global-sticky-menu-height));
        overflow-y: auto !important;
    }

    &.side-panel {
        width: calc(100% + (var(--side-panel-padding) * 2));
        margin-inline: calc(var(--side-panel-padding) * -1);
        margin-block: -8px 8px;

        &.expanded:not(:last-child) {
            margin-block-end: 32px;
        }
    }
`

export const Actions = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    column-gap: 16px;

    margin-block-end: 16px;
    padding-inline: 16px;

    > button {
        justify-content: center;
    }

    ${DrawablePanel}.side-panel & {
        padding-inline: var(--side-panel-padding);
    }
`

export const ContentRow = styled.div`
    padding: 16px;
    box-shadow: 0 1px var(--huma-color-border-default);

    &:has(${DrawablePanel}) {
        padding: unset;
    }
`

export const DrawableRow = styled(Grid)`
    grid-template-columns: minmax(0, 1fr) min-content;
    column-gap: 16px;

    min-height: 60px;
    padding-inline: 24px;
    background-color: rgb(from var(--huma-color-surface-minimal) r g b / .5);

    &:first-child {
        min-height: 68px;
        padding-block-start: 8px;
    }

    &:last-child {
        min-height: 68px;
        padding-block-end: 8px;
    }

    &:first-child:last-child {
        min-height: 76px;
    }

    ${DrawablePanel}.side-panel & {
        padding-inline: var(--side-panel-padding);
    }

    > :first-child {
        align-self: end;
    }

    > button {
        grid-row: 1 / span 2;
        grid-column: 2;
    }

    strong {
        font-weight: 500;
    }
`

export const Footer = styled.footer`
    padding: 16px;
`
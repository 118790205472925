import React, { Component, createRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { FormContext } from 'components/form/controller'
import { omit, size } from 'utilities/object'
import { cls } from 'utilities/dom'
import { Control } from './s'
import { Field, Label, Error } from 'components/form/field/s'
import Text from 'components/form/input/text'

const empty = ''

class EditTimeOfDay extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        this.field = createRef()
        this.inputRef = createRef()

        const {
            name,
            field = {}
        } = props

        let {
            value,
            required = false,
            include = 'touched'
        } = field

        if(!value) {
            value = empty
        }

        const errors = valiDate(value, required)

        this.state = {
            value,
            errors
        }

        context.registerField(name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                value: empty
            }),

            validator: value => {
                if(size(this.state.errors)) {
                    return false
                }

                if(required && !value) {
                    return false
                }

                return true
            }
        }, true)
    }

    componentDidMount() {
        this.context.triggerChange(this.props.name, { touched: false })
    }

    componentDidUpdate = ({ name }, { value }) => {
        if(value !== this.state.value) {
            this.context.triggerChange(name)
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name)
    }

    onChange = ({ target }) => {
        let { value = empty } = target

        const errors = valiDate(value, {
            ...this.props.picker,
            required: this.props.field.required
        })

        this.setState({
            value,
            errors
        })

        const { onChange, name } = this.props
        onChange?.({ [name]: value })

        this.context.triggerChange(name)
    }

    render() {
        const {
            value = {},
            errors
        } = this.state

        const {
            className,
            label,
            salt,
            name,
            enabled = true,
            loading = false,
            field = {},
            error: outsideError,
            forwardedRef,
            ...props
        } = this.props

        const touched = this.context.touched.includes(name)
        const error = ((name in this.context.errors) && touched) || !!outsideError

        const controlClassName = cls([
            !!size(errors) && 'error'
        ])

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading'
        ])

        const id = `${salt}:${name}`

        const {
            required,
            optional
        } = field

        return (
            <Field
                {...(fieldClassName ? { className: fieldClassName } : null)}
                ref={this.field}>
                {!!label && (
                    <Label
                        htmlFor={id}
                        required={required}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control {...(controlClassName ? { className: controlClassName } : null)}>
                    <input
                        name={name}
                        defaultValue={value}
                        type="hidden"
                        key={`${id}:input:${value}`} />
                    <Text
                        {...omit(props, 'field')}
                        id={`${salt}:date`}
                        type="time"
                        value={value}
                        disabled={!enabled}
                        onChange={this.onChange}
                        onKeyUp={e => {
                            // Support for built-in browser input type validation
                            const browserValidationInvalid = e.target?.validity?.valid === false

                            if(browserValidationInvalid) {
                                this.setState({
                                    errors: {
                                        ...errors,
                                        triggerChange: true
                                    }
                                }, () => this.context.triggerChange(name, { touched: true }))
                            } else if(errors.triggerChange) {
                                this.setState({
                                    errors: omit(errors, 'triggerChange')
                                }, () => this.context.triggerChange(name, { touched: true }))
                            }
                        }}
                        ref={mergeRefs([
                            this.inputRef,
                            forwardedRef
                        ])} />
                    <Error animate={outsideError ? 'reveal' : 'hide'}>
                        {outsideError}
                    </Error>
                </Control>
            </Field>
        )
    }
}

const valiDate = (value, options = {}) => {
    const errors = {}

    const { required = false } = options

    if(required && !value) {
        errors.time = true
    }

    if(size(errors)) {
        return errors
    }

    return errors
}

export default EditTimeOfDay

import React from 'react'
import styled from 'styled-components'
import {
    svgStroke,
    belowTablet
} from 'utilities/styled'
import { get } from 'utilities/object'
import paths from 'app/paths'
import { Link } from 'react-router-dom'
import { FlexChildShrink, flexColumnsCss } from 'components/flex'
import { ArrowLeft as ArrowLeftBase } from 'styled-icons/feather'

const LinkColumns = styled(Link)`
    ${flexColumnsCss}

    display: inline-flex;
    align-items: center;

    margin: 0 0 24px -16px;
    width: auto;
    height: 40px;
    padding: 0 16px;

    text-decoration: none;
    color: var(--huma-color-foreground-default);
`

const TextColumn = styled(FlexChildShrink)`
    margin-inline-start: 20px;

    &.hide-on-small {
        ${belowTablet`
            display: none;
        `}
    }
`

const ArrowLeft = styled(ArrowLeftBase)`
    position: relative;
    left: 0;
    transition: left .1s ease-in-out;

    ${svgStroke()}

    ${LinkColumns}:hover & {
        left: -4px;
    }
`

export default ({ to, text, hideTextOnSmallDevices = false, ...props }) => {
    if(typeof to === 'string' && to?.startsWith('path:')) {
        to = get(paths, to.replace('path:', ''))
    }

    return (
        <LinkColumns
            {...props}
            to={to}>
            <FlexChildShrink>
                <ArrowLeft size={24} />
            </FlexChildShrink>
            {!!text && (
                <TextColumn {...(hideTextOnSmallDevices ? { className: 'hide-on-small' } : null)}>
                    {text}
                </TextColumn>
            )}
        </LinkColumns>
    )
}

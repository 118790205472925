import React, { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { safeFormat, safeTransform, isofy, getDate } from 'utilities/date-time'
import { toDate } from 'date-fns-tz'
import { isSameDay, differenceInYears } from 'date-fns'
import { capitalize } from 'utilities/string'
import View from './view'
import Edit from './edit'
import EditSimple from './edit-simple'
import EditZoned from './edit-zoned'

const TimeField = forwardRef(({ field = {}, name, mode = 'edit', simple = false, zoned = false, salt, ...props }, forwardedRef) => {
    const {
        value,
        range = false,
        editable = true
    } = field

    props = {
        ...props,
        field,
        name,
        salt: `${salt}:${name}`,
        forwardedRef
    }

    if(mode === 'edit' && editable) {
        if(!range && !Array.isArray(value)) {
            if(simple) {
                return <EditSimple {...props} />
            }

            if(zoned) {
                return <EditZoned {...props} />
            }
        }

        return <Edit {...props} />
    }

    return <View {...props} />
})

export const DisplayTime = ({ time, mode = 'date', short = false, yearsDiff = false }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const timeFormatter = formatTime(mode, locale, formatMessage, { short })

    if(Array.isArray(time)) {
        const [from, to] = time.map(edge => edge ? timeFormatter(edge) : '')

        return formatMessage({
            id: 'range',
            defaultMessage: '{from} – {to}'
        }, { from, to })
    } else if(time) {
        const formatted = timeFormatter(time)

        if(yearsDiff) {
            const years = Math.abs(safeTransform(time, differenceInYears, new Date()))
            const yearsFormatted = formatMessage({
                id: 'time_years',
                defaultMessage: '{years, plural, one {1 year} other {# years}}'
            }, { years })

            return `${formatted} (${yearsFormatted})`
        }

        return formatted
    }

    return null
}

const formatZonedTime = (time, locale, formatMessage, options = {}) => {
    const hereZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const thereZone = time.zone ?? hereZone

    if(!time?.date || !time?.time) {
        return {
            there: {
                time: 'N/A',
                zone: thereZone
            },
            here: {
                time: 'N/A',
                zone: hereZone
            },
            weekdaysDifferent: false,
            timeDifferent: false,
            zoneDifferent: false
        }
    }

    const thereDate = toDate(`${time.date}T${time.time}`, { timeZone: hereZone })
    const hereDate = toDate(`${time.date}T${time.time}`, { timeZone: thereZone })

    const weekdaysDifferent = !isSameDay(thereDate, hereDate)

    const {
        format = formatMessage({
            id: weekdaysDifferent ?
                'date_fns_format_weekday_time_friendly' :
                'date_fns_format_time_friendly',
            defaultMessage: weekdaysDifferent ?
                'EEEE h:mm aaaa' :
                'h:mm aaaa'
        })
    } = options

    return {
        there: {
            time: safeFormat(thereDate, format, { locale }),
            zone: thereZone
        },
        here: {
            time: safeFormat(hereDate, format, { locale }),
            zone: hereZone
        },
        weekdaysDifferent,
        timeDifferent: thereDate.toISOString() !== hereDate.toISOString(),
        zoneDifferent: thereZone !== hereZone
    }
}

export const formatTime = (mode, locale, formatMessage, options = {}) => time => {
    if(time) {
        if(mode === 'zoned') {
            return formatZonedTime(time, locale, formatMessage, options)
        }

        const { short = false } = options
        time = isofy(time)

        const timeFormat = {
            date: short ? 'PP' : 'PPP',
            time: 'p'
        }?.[mode] ?? (short ? 'PPp' : 'PPPp')

        return capitalize(
            safeFormat(time, timeFormat, { locale })
        )
    }

    return null
}

export const formatDataTime = mode => time => {
    if(time) {
        time = isofy(time)

        return {
            date: () => safeFormat(time, 'yyyy-MM-dd'),
            time: () => safeFormat(time, 'HH:mm'),
            zoned: () => ({
                date: time.date ? getDate(time.date, { timeZone: time.zone }) : null,
                time: time.time ?? null,
                zone: time.zone ?? null
            })
        }[mode]?.() ?? time.toUTCString()
    }

    return time
}

export default TimeField

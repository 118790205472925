import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Naked } from 'components/button'
import { textCss } from 'components/form/input/text'
import { ValueDisplay } from 'components/form/field/s'

export const TogglerWrap = styled.div`
    position: relative;
`

export const TogglerWithActionsWrap = styled(Grid)`
    grid-template-columns: 1fr;
    justify-content: start;
    align-items: start;
    column-gap: 24px;

    &.centered {
        align-items: center;
    }

    &.framed {
        /* adapted textCss */
        position: relative;

        outline: 1px solid var(--huma-color-border-default);
        border-radius: 4px;
        width: 100%;
        height: auto;
        padding-inline: 12px;
        background: none;

        transition:
            outline-color .1s ease-in-out,
            box-shadow .1s ease-in-out;

        &:has([disabled]) {
            cursor: not-allowed;

            > * {
                pointer-events: none;
            }
        }

        &:not(:has([disabled])) {
            &:hover {
                outline-color: var(--huma-color-border-default-focus);
            }

            &:focus-within {
                outline-color: var(--huma-color-border-default);
                box-shadow:
                    0 0 0 2px var(--huma-color-surface-default),
                    0 0 0 4px var(--huma-color-border-constructive-focus)
            }
        }
    }

    &.has-value,
    &.has-unset {
        grid-template-columns: 1fr minmax(auto, min-content);
    }

    &.has-value.has-unset {
        grid-template-columns: 1fr minmax(auto, min-content) minmax(auto, min-content);
    }
`

export const TogglerButton = styled(Naked)`
    display: block;
    position: relative;
    overflow: hidden;
    ${textCss}
    height: auto;
    min-height: 40px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 24px;

    &.with-icon {
        padding-inline-end: 36px;
        position: relative;

        > svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
            fill: none !important;

            ${svgStroke()}
        }
    }

    &.with-symbol {
        padding-inline-start: 36px;
        position: relative;

        > div {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 12px;
        }
    }

    &.tall {
        min-height: 56px;
    }

    > ${ValueDisplay} {
        min-height: unset;
        padding: 0;
    }

    &[disabled] {
        border-color: var(--huma-color-border-default);
        background-color: var(--huma-color-surface-disabled);

        color: var(--huma-color-foreground-disabled);
    }
`

export const TogglerWithActionsButton = styled(Naked)`
    display: block;
    position: relative;
    overflow: clip;
    overflow-clip-margin: 8px;

    height: auto;
    min-height: 40px;

    text-align: start;

    &.tall {
        min-height: 56px;
    }

    > ${ValueDisplay} {
        min-height: unset;
        padding: 0;
    }
`
import styled from 'styled-components'
import { Rows } from 'components/flex'
import { buttonStyles } from 'components/button'

export const ContactContainer = styled(Rows)`
    border-radius: 8px;
    padding: 16px;
    background-color: var(--huma-color-surface-default);
`

export const Info = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    align-items: center;

    &.cta {
        row-gap: 16px;

        & > :nth-child(1) {
            grid-row: 2;
            grid-column: 1 / 3;
        }

        & > :nth-child(2) {
            grid-row: 3;
        }

        & > :nth-child(3) {
            grid-row: 1;
        }
    }

    &:not(.cta) {
        gap: 16px;

        & > :nth-child(1) {
            grid-column: 1 / 3;
        }

        & > :nth-child(2) {
            grid-row: 2;
        }

        & > :nth-child(3) {
            grid-row: 2;
        }
    }

    a {
        color: var(--huma-color-foreground-default);
    }
`

export const ChannelLink = styled.a`
    ${buttonStyles.plainCss}
    display: inline;
    height: 24px;
    font-size: 14px;
    text-decoration: none;
`
import React from 'react'
import { Form } from './s'
import Field from 'components/form/field/time'

// eslint-disable-next-line no-unused-vars
const TimeField = ({ filter, setFilter, allResetAt, name, field = null, salt, resetAt, fieldSalt, context, ...props }) => {
    const getValue = () => filter?.[name] ?? field?.defaultValue ?? null

    return (
        <Form>
            <Field
                {...props}
                salt={salt}
                className="compact"
                label={false}
                name={name}
                field={{
                    ...field,
                    value: getValue()
                }}
                onChange={setFilter}
                key={`${salt}:${name}:${allResetAt}`} />
        </Form>
    )
}

export default TimeField
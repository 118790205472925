import { useIntl } from 'react-intl'
import { usePayment } from 'contexts/payment'
import { compact } from 'utilities/array'

export const getBundleNameFormatter = formatMessage => ({
    bundle,
    prefix = 'Huma',
    custom = false,
    trial = false,
    inactive = false
}) => compact([
    prefix,
    formatMessage({
        id: `payment_plan_name_${bundle.bundle}`,
        defaultMessage: bundle.bundle
    }),
    custom && 'Custom',
    trial && 'Trial',
    inactive && 'Inactive'
]).join(' ')

export const BundleName = ({
    bundle,
    prefix = 'Huma',
    trial = false,
    inactive = false
}) => {
    const { formatMessage } = useIntl()
    const { account } = usePayment()

    return getBundleNameFormatter(formatMessage)({
        bundle,
        prefix,
        custom: !account?.subscription?.public ?? false,
        trial,
        inactive
    })
}

export const BundleCapabilitiesHeading = ({ bundle }) => {
    const { formatMessage } = useIntl()
    const name = getBundleNameFormatter(formatMessage)({ bundle, prefix: false })

    return formatMessage({
        id: `payment_plan_included_${bundle.bundle}`,
        defaultMessage: `Included in ${name}:`
    })
}

export const PlanFrequency = ({ bundle }) => {
    const { formatMessage } = useIntl()

    const frequency = {
        days: 'daily',
        months: 'monthly',
        years: 'yearly'
    }[bundle.intervalUnit]

    if(frequency) {
        return formatMessage({
            id: `frequency_${frequency}`,
            defaultMessage: frequency
        })
    }

    return null
}
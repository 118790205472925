import React from 'react'
import { useIntl } from 'react-intl'
import { compact } from 'utilities/array'
import { FilterRow } from './s'
import Filter from 'pages/people/panes/everyone/filter'

const PeoplePickerFilter = ({ params, overrides, enableIsActiveFilter = false, namespace, salt }) => {
    const { formatMessage } = useIntl()

    return (
        <FilterRow>
            <Filter
                overrides={{
                    ...(overrides ?? null),
                    search: {
                        controlProps: {
                            autoFocus: true,
                            placeholder: formatMessage({
                                id: 'people_action_find',
                                defaultMessage: 'Find people'
                            })
                        }
                    }
                }}
                hideNames={compact([
                    ...Object.keys(params),
                    !enableIsActiveFilter && 'isActive'
                ])}
                namespace={namespace}
                showerride={true}
                expandable={true}
                memoryFilterOptions={{ enabled: false }}
                salt={salt} />
        </FilterRow>
    )
}

export default PeoplePickerFilter
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { EmptyWrapper, GenericEmpty, Image, EmptyContent, EmptyText } from './s'

const Empty = ({ which, show = true, children = null, ...props }) => {
    const { tutorials } = useServiceOnboarding()
    const tutorial = tutorials[which]

    const [imageLoaded, setImageLoaded] = useState(false)

    useEffect(() => {
        if(!tutorial?.emptyUrl) {
            setImageLoaded(true)
        }
    }, [tutorial])

    const { emptyUrl } = tutorial ?? {}

    const animate = (show && imageLoaded) ? 'in' : 'out'

    return (
        <EmptyWrapper
            animate={animate}
            {...props}>
            {emptyUrl && (
                <Image
                    onLoad={() => setImageLoaded(true)}
                    onError={() => setImageLoaded(true)}
                    src={emptyUrl} />
            )}
            {children && (
                <EmptyContent>
                    {children}
                </EmptyContent>
            )}
        </EmptyWrapper>
    )
}

export const Generic = ({ children, ...props }) => (
    <GenericEmpty {...props}>
        <EmptyContent>
            <EmptyText className="compact">
                <FormattedMessage
                    id="empty_filter"
                    defaultMessage="🤷 Sorry, nothing was found with your current filter configuration." />
            </EmptyText>
            {children}
        </EmptyContent>
    </GenericEmpty>
)

export const Static = ({ children = null, ...props }) => (
    <EmptyWrapper
        {...props}
        animate="in">
        {!!children && (
            <EmptyContent>
                {children}
            </EmptyContent>
        )}
    </EmptyWrapper>
)

export default Empty

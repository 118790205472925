import React, { Component, createRef } from 'react'
import { useIntl } from 'react-intl'
import { useForm } from 'components/form/controller'
import { mergeRefs } from 'react-merge-refs'
import { empty } from './'
import { omit } from 'utilities/object'
import { compact, pruneBy } from 'utilities/array'
import { cls } from 'utilities/dom'
import { isInRange } from 'utilities/math'
import isEqual from 'react-fast-compare'
import { Field, Label, Control, Placeholder } from 'components/form/field/s'
import Toggler from 'components/form/input/toggler'
import DisplayPeople from './display'
import PeoplePicker from 'modals/people-picker'

class EditPeople extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this.valueStateFromProps(props),
            picking: false
        }

        this.register()

        this.control = createRef()
        this.combinedControlRef = mergeRefs([
            this.control,
            props.forwardedRef
        ])
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ forwardedRef, name, field, whistle }, { value }) => {
        const forwardedRefChanged = forwardedRef !== this.props.forwardedRef
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(forwardedRefChanged) {
            this.combinedControlRef = mergeRefs([
                this.control,
                this.props.forwardedRef
            ])
        }

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        let {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        let {
            min = 0,
            max = Infinity
        } = this.props.picker ?? {}

        if(required && min === 0) {
            min = 1
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: this.unset,

            validator: value => required ?
                isInRange({ min, max, value: value.length }) :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => {
        const value = props.field?.value || empty

        return {
            value,
            selection: value
        }
    }

    openPicker = () => {
        this.setState({ picking: true })
        this.props.onToggle?.()
    }

    closePicker = () => {
        this.setState(({ value }) => ({
            picking: false,
            selection: value
        }))

        this.props.onToggle?.()
    }

    unset = () => {
        this.setState({
            value: empty,
            selection: empty,
            picking: false
        })

        this.props.onUnset?.()
    }

    select = selection => this.setState({ selection })

    commit = () => {
        const { method = 'manage' } = this.props.picker ?? {}

        this.setState(state => {
            const value = (method === 'manage') ?
                state.selection :
                pruneBy([...state.value, ...state.selection])

            return {
                value,
                selection: empty
            }
        }, () => this.props.onChange?.({ [this.props.name]: this.state.value }))
    }

    render() {
        const {
            value,
            selection,
            picking
        } = this.state

        const {
            className,
            controlProps = {},
            picker = {},
            entity = {},
            label,
            salt,
            name,
            enabled = true,
            loading = false,
            field = {},
            formatMessage,
            ...props
        } = this.props

        const {
            locked = [],
            method = 'manage',
            outer = true,
        } = picker

        const {
            endpoint = {},
            params = {}
        } = entity

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const valueMapperContext = { name, salt }
        const mapValues = this.props.form.mode === 'json' ?
            jsonMapper(valueMapperContext) :
            multipartMapper(valueMapperContext)

        const people = compact([
            ...(!!locked?.length ? locked : []),
            ...(!!value?.length ? value : [])
        ])

        let {
            required,
            softRequired,
            optional,
            placeholder,
            unsettable = true
        } = field

        if(!placeholder) {
            placeholder = formatMessage({
                id: 'people_action_pick',
                defaultMessage: 'Pick people'
            })
        }

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    {mapValues(value).map(({ key, ...person }) => (
                        <input
                            {...person}
                            key={key} />
                    ))}
                    <Toggler
                        {...controlProps}
                        {...omit(props, 'field', 'form', 'whistle', 'onToggle', 'onUnset')}
                        type="people"
                        id={salt}
                        onClick={this.openPicker}
                        active={picking}
                        unset={(!!value.length && unsettable) && this.unset}
                        unsettable={unsettable}
                        disabled={!enabled}
                        ref={this.combinedControlRef}>
                        {!!people?.length && (
                            <DisplayPeople
                                people={people}
                                salt={salt} />
                        )}
                        {!people?.length && (
                            <Placeholder>
                                {placeholder}
                            </Placeholder>
                        )}
                    </Toggler>
                    <PeoplePicker
                        {...picker}
                        endpoint={endpoint}
                        params={params}
                        method={method}
                        outer={outer}
                        show={picking}
                        picked={selection}
                        locked={locked}
                        salt={salt}
                        onSelect={this.select}
                        dismiss={this.closePicker}
                        cancelAction={() => ({
                            label: formatMessage({
                                id: 'action_cancel',
                                defaultMessage: 'Cancel'
                            }),
                            effect: 'neutral',
                            onClick: this.closePicker
                        })}
                        doneAction={({ picked }) => ({
                            label: formatMessage({
                                id: 'people_action_pick_count',
                                defaultMessage: '{people_action_pick_count, plural, =0 {Pick} =1 {Pick one person} other {Pick {count} people}}'
                            }, { count: picked.length }),
                            effect: 'constructive',
                            onClick: () => this.commit()
                        })} />
                </Control>
            </Field>
        )
    }
}

const jsonMapper = ({ name, salt }) => people => people.map(({ id }, index) => ({
    name: `${name}[${index}][id]`,
    value: id,
    type: 'hidden',
    key: `${salt}:value:${id}:${index}`
}))

const multipartMapper = ({ name, salt }) => people => [{
    name,
    value: people.map(({ id }) => id).join(','),
    type: 'hidden',
    key: `${salt}:value`
}]

export default props => {
    const { formatMessage } = useIntl()
    const form = useForm()

    return (
        <EditPeople
            {...props}
            formatMessage={formatMessage}
            form={form} />
    )
}
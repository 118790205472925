import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { getSalaryRevisionGroupUrl } from 'utilities/url'
import { safeFormat } from 'utilities/date-time'
import {
    Wrapper,
    Name, UserCount, Status, ArrowRight, Message
} from './s'

const RevisionGroup = ({ group }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const {
        id,
        revisionId,
        name,
        userCount,
        status,
        dueDate
    } = group

    return (
        <Wrapper to={getSalaryRevisionGroupUrl({
            id: revisionId,
            group: id
        })}>
            <Name className="compact">{name}</Name>
            <UserCount className="caption small compact">
                <FormattedMessage
                    id="people_count"
                    defaultMessage="{count, plural, =0 {No people} =1 {1 person} other {{count} people}}"
                    values={{ count: userCount }} />
            </UserCount>
            <Status status={status} />
            <ArrowRight size={24} />
            {dueDate && (
                <Message
                    type="warning"
                    className="small compact"
                    message={formatMessage({
                        id: 'salary_revision_message_submit_due',
                        defaultMessage: 'Submit before {date}',
                    }, { date: safeFormat(dueDate, 'PPP', { locale }) })} />
            )}
        </Wrapper>
    )
}

export default RevisionGroup
import styled, { css } from 'styled-components'
import {
    svgStroke,
    belowTablet, fromPhone, fromTablet, print, darkMode
} from 'utilities/styled'
import { containerCss } from 'utilities/widget'
import { Rows } from 'components/flex'
import { OrderedList, ListItem } from 'components/list'
import { Simple, Naked, buttonStyles } from 'components/button'
import { Link } from 'react-router-dom'
import SymbolBase from 'components/symbol'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'

export const Container = styled(Rows)`
    ${containerCss}
    overflow: hidden;

    ${belowTablet`
        width: calc(100% + 48px);
        position: relative;
        left: -24px;

        &:not(.boxy) {
            min-height: 136px;
        }
    `}
`

export const MobileHeader = styled.div`
    ${fromTablet`
        display: none;
    `}

    padding: 16px 24px;
`

export const CloseButton = styled(Naked)`
    cursor: pointer;
    position: relative;

    height: 40px;
    padding-left: 48px;

    transition: all .1s ease-in-out;

    font-size: 16px;
    color: var(--huma-color-foreground-default);

    svg {
        position: absolute;
        top: 8px;
        left: 0;

        ${svgStroke()}
    }
`

export const List = styled(OrderedList)`
    min-width: 120px;

    ${Container}.small & {
        min-width: 100px;
    }
`

export const Item = styled(ListItem)`
    &:not(:last-child) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }

    &.hide-from-phone {
        ${fromPhone`
            display: none;
        `}
    }

    &.hide-from-tablet {
        ${fromTablet`
            display: none;
        `}
    }

    svg {
        ${svgStroke()}

        &.upgrade {
            position: absolute;
            top: 50%;
            right: 16px;

            transform: translateY(-50%);

            color: var(--huma-color-foreground-subtle);
        }
    }

    ${Container}.small & svg {
        ${svgStroke('small')}
    }
`

const actionCss = css`
    --huma-component-action-background-color: transparent;

    @scope (${Container}) {
        background-color: var(--huma-component-action-background-color);
    }

    position: relative;
    display: grid;

    border-radius: 0;
    width: 100%;
    min-height: 56px;

    text-align: start;
    white-space: nowrap;

    ${Container}.small & {
        min-height: 40px;
    }

    &:not(:disabled).loading::after {
        top: 15px;
    }

    &:not(:disabled) {
        &:where(:hover, :focus-visible) {
            --huma-component-action-background-color: var(--huma-color-surface-elevated-hover);

            &.constructive {
                --huma-component-action-background-color: var(--huma-color-surface-constructive-transparent-hover);
            }

            &.destructive {
                --huma-component-action-background-color: var(--huma-color-surface-destructive-transparent-hover);
            }
        }

        &:active {
            --huma-component-action-background-color: var(--huma-color-surface-elevated-active);
        }
    }

    &.soft-disable {
        pointer-events: none;
    }

    &.with-icon {
        grid-auto-flow: column;
        grid-auto-columns: min-content;
        column-gap: 16px;
    }

    &.with-upgrade {
        padding-right: 48px;
    }

    &:not(.inline) {
        ${belowTablet`
            padding-right: 24px;
            padding-left: 24px;
        `}
    }
`

export const actionLinkCss = css`
    ${buttonStyles.simpleCss}
    ${actionCss}

    text-decoration: none;
`

const withHelptextCss = css`
    align-items: flex-start;

    height: auto;
    padding-block: 12px 8px;
`

export const ActionButton = styled(Simple)`
    ${actionCss}

    &.help {
        ${withHelptextCss}
    }

    &[disabled] svg {
        filter: grayscale(1) brightness(0.85);

        ${darkMode`
            filter: grayscale(1) brightness(1.15);
        `}
    }
`

export const ActionAnchor = styled.a`
    ${actionLinkCss}

    &.help {
        ${withHelptextCss}
    }
`

export const ActionLink = styled(Link)`
    ${actionLinkCss}

    &.help {
        ${withHelptextCss}
    }
`

export const ActionLabel = styled.label`
    ${buttonStyles.simpleCss}
    ${actionCss}

    &.help {
        ${withHelptextCss}
    }
`

export const ActionsButton = styled(Naked)`
    display: block;

    border-radius: 8px;
    width: 40px;
    height: 40px;

    transition: background-color 1s ease;

    &:where(:hover, :focus) {
        background-color: var(--huma-color-surface-elevated-hover);

        transition: background-color 0.2s ease;
    }

    &:active {
        background-color: var(--huma-color-surface-elevated-active);
    }

    svg {
        ${svgStroke()}
    }

    ${print`
        display: none;
    `}
`

export const Symbol = styled(SymbolBase)`
    margin-inline-end: 8px;
`

export const Label = styled(Paragraph)`
    ${ActionButton}.help &,
    ${ActionAnchor}.help &,
    ${ActionLink}.help &,
    ${ActionLabel}.help & {
        display: block;
    }

    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
`

export const Helptext = styled(CaptionSmall)`
    display: block;

    margin-block-start: -4px;

    ${ActionButton}:disabled &,
    ${ActionAnchor}:disabled &,
    ${ActionLink}:disabled &,
    ${ActionLabel}:disabled & {
        color: var(--huma-color-foreground-disabled)}
    }
`

export const LoaderWrap = styled.span`
    display: block;
    width: 24px;
    height: 24px;
    position: relative;

    &.end {
        position: absolute;
        top: 16px;
        right: 16px;

        ${Container}.small & {
            top: 8px;
            right: 8px;
        }
    }
`
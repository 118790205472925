import styled from 'styled-components'
import { WithActions } from 'components/form/input/toggler'

export const Toggler = styled(WithActions)`
    align-items: center;

    text-align: start;

    &.has-value,
    &.has-unset {
        grid-template-columns: auto minmax(auto, min-content);
    }

    &.has-value.has-unset {
        grid-template-columns: auto minmax(auto, min-content) minmax(auto, min-content);
    }
`
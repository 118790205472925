import React, {
    forwardRef,
    useEffect, useState, useRef,
    Fragment
} from 'react'
import { mergeRefs } from 'react-merge-refs'
import { cls } from 'utilities/dom'
import { Wrap, Rigid, Flexible } from './s'
import Tooltip from 'components/tooltip'

const Ellipsify = forwardRef(({ text = '', head = 0, tail, tooltipProps, className, ...props }, forwardedRef) => {
    const ref = useRef()

    const [truncated, setTruncated] = useState(false)

    useEffect(() => {
        if(ref.current) {
            setTruncated(isTruncated(ref.current))
        }
    }, [ref.current])

    if(text.length <= head + tail) {
        return text
    }

    text = text.replace(/\s+/g, ' ')

    if(isNaN(tail) || tail === 0) {
        tail = undefined
    } else {
        tail = -tail
    }

    const start = text.slice(0, head)
    const middle = text.slice(head, tail)
    const end = tail && text.slice(tail)

    const [TextWrapper, textProps] = truncated ?
        [Tooltip, {
            ...(tooltipProps ?? null),
            content: text,
            delay: [500, 250]
        }] :
        [Fragment, null]

    className = cls([
        className,
        truncated && 'truncated'
    ])

    return (
        <TextWrapper {...textProps}>
            <Wrap
                {...props}
                {...(className ? { className } : null)}
                ref={mergeRefs([ref, forwardedRef])}>
                {!!start && <Rigid>{start}</Rigid>}
                {!!middle && <Flexible>{middle}</Flexible>}
                {!!end && <Rigid>{end}</Rigid>}
            </Wrap>
        </TextWrapper>
    )
})

const isTruncated = e => {
    const tempNode = e.cloneNode(true)

    tempNode.style.position = 'fixed'
    tempNode.style.overflow = 'visible'
    tempNode.style.whiteSpace = 'nowrap'
    tempNode.style.visibility = 'hidden'

    e.parentElement.appendChild(tempNode)

    try {
        const fullWidth = tempNode.getBoundingClientRect().width
        const displayWidth = e.getBoundingClientRect().width

        return fullWidth > displayWidth
    } finally {
        tempNode.parentElement.removeChild(tempNode)
    }
}

export default Ellipsify
import React, { Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { format } from 'date-fns'
import { useI18n } from 'contexts/i18n'
import { useAccess } from 'contexts/access'
import { isofy } from 'utilities/date-time'
import { isNumeric } from 'utilities/math'
import { compact } from 'utilities/array'
import { useCategoryFormatter } from 'pages/competence/components/category'
import { NameDetails, CompetenceCellClickable, CompetenceCellWrapper, Symbol, Name, SubHeading, AddLevelButton, Note } from './s'
import Ellipsify from 'components/ellipsify'
import Tooltip from 'components/tooltip'
import { symbol as symbolFallback } from 'pages/competence'

const RecordNameDetails = ({ record, view, edit, singleton }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()
    const categoryFormatter = useCategoryFormatter()

    const { check } = useAccess()
    const manageAccess = check('competence:manage')

    const {
        type,
        category,
        level,
        school,
        fieldOfStudy,
        isNativeLanguage = false,
        startDate,
        endDate,
        validFrom,
        validTo,
        note
    } = record

    const { symbol } = type

    const dayLessDateFormat = formatMessage({
        id: 'date_format_full_dayless',
        defaultMessage: 'MMM yyyy'
    })

    const fromDate = category === 'certification' ? isofy(validFrom) : isofy(startDate)
    const toDate = category === 'certification' ? isofy(validTo) : isofy(endDate)

    const subHeadings = compact([
        isNumeric(level) && 'level',
        category === 'education' && 'education',
        category === 'certification' && 'validityPeriod',
        ['education', 'experience'].includes(category) && 'datesPeriod',
        (['skill', 'language'].includes(category) && !isNumeric(level) && manageAccess) && 'addLevel'
    ])

    const levelSubHeading = compact([
        isNumeric(level) && level,
        formatMessage({
            id: `competence_${category}_level_${level}`,
            defaultMessage: level
        }),
        (category === 'language' && !!isNativeLanguage) && formatMessage({
            id: 'competence_language_native',
            defaultMessage: 'Native / multilingual'
        })
    ]).join(' · ')

    const educationSubHeading = compact([
        school,
        !!fieldOfStudy && categoryFormatter(type)
    ]).join(' · ')

    const [CompetenceWrapper, competenceWrapperProps] = view ?
        [Tooltip, {
            content: formatMessage({
                id: 'action_view_details',
                defaultMessage: 'View details'
            }),
            singleton
        }] :
        [Fragment, null]

    const [CompetenceCell, competenceCellProps] = view ?
        [CompetenceCellClickable, {
            onClick: view.onClick,
            className: 'compact'
        }] :
        [CompetenceCellWrapper, null]

    return (
        <NameDetails style={{
            '--sub-headings': subHeadings.length,
            '--offset-top': subHeadings.length > 1 ? subHeadings.length - 1 : 0
        }}>
            <CompetenceWrapper {...competenceWrapperProps}>
                <CompetenceCell {...competenceCellProps}>
                    <Symbol
                        symbol={symbol ?? symbolFallback}
                        className="hover-effect" />
                    <Name>
                        <Ellipsify text={!!fieldOfStudy?.length ? fieldOfStudy : categoryFormatter(type)} />
                    </Name>
                </CompetenceCell>
            </CompetenceWrapper>
            {subHeadings.includes('level') && (
                <SubHeading>
                    <Ellipsify text={levelSubHeading} />
                </SubHeading>
            )}
            {(subHeadings.includes('education') && !!educationSubHeading?.length) && (
                <SubHeading>
                    <Ellipsify text={educationSubHeading} />
                </SubHeading>
            )}
            {subHeadings.includes('validityPeriod') && (
                <SubHeading>
                    <Ellipsify text={formatMessage({
                        id: 'date_range',
                        defaultMessage: '{from} – {to}'
                    }, {
                        from: !fromDate ?
                            formatMessage({
                                id: 'competence_error_missing_issued_date',
                                defaultMessage: 'No issued date'
                            }) :
                            format(fromDate, 'PP', { locale }),
                        to: !toDate ?
                            formatMessage({
                                id: 'competence_error_missing_expiry_date',
                                defaultMessage: 'No expiry date'
                            }) :
                            format(toDate, 'PP', { locale })
                    })} />
                </SubHeading>
            )}
            {subHeadings.includes('datesPeriod') && (
                <SubHeading>
                    <FormattedMessage
                        id="date_range"
                        defaultMessage="{from} – {to}"
                        values={{
                            from: fromDate ?
                                format(fromDate, dayLessDateFormat, { locale }) :
                                null,
                            to: toDate ?
                                format(toDate, dayLessDateFormat, { locale }) :
                                null
                        }} />
                </SubHeading>
            )}
            {(subHeadings.includes('addLevel') && edit) && (
                <AddLevelButton
                    onClick={edit?.onClick}
                    className="constructive">
                    <FormattedMessage
                        id="competence_action_add_level"
                        defaultMessage="Add level" />
                </AddLevelButton>
            )}
            {!!note && <Note>{note}</Note>}
        </NameDetails>
    )
}

export default RecordNameDetails

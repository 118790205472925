import React from 'react'
import { useI18n } from 'contexts/i18n'
import { Link, Wrapper, Url } from './s'
import Paragraph from 'components/typography/paragraph'
import { HelpCircle, ExternalLink } from 'styled-icons/feather'

const ReadMore = ({ item }) => {
    const { locale } = useI18n()

    const {
        url,
        title
    } = {
        ...item?.en,
        ...item?.[locale]
    }

    if(!url || !title) {
        return null
    }

    return (
        <Link
            href={url}
            target="_blank">
            <HelpCircle size={24} />
            <Wrapper>
                <Paragraph className="compact">{title}</Paragraph>
                <Url className="compact">{url}</Url>
            </Wrapper>
            <ExternalLink size={16} />
        </Link>
    )
}

export default ReadMore

import styled from 'styled-components'

export const Wrap = styled.div`
    display: grid;
    place-items: center;

    border-radius: 50%;
    width: 24px;
    height: 24px;
    background-color: var(--huma-color-surface-brand-bold);
    box-shadow: 0 0 0 2px var(--huma-color-surface-default);

    font-size: 14px;
    line-height: 22px;
    color: var(--huma-color-foreground-on-bold);

    &.inactive {
        background-color: var(--huma-color-surface-minimal);

        color: var(--huma-color-foreground-default);
    }

    &.small {
        font-size: 12px;
    }
`
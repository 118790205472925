import React, { Component, createRef } from 'react'
import { FormContext } from 'components/form/controller'
import { encode } from 'he'
import { empty, getOptionId } from '../'
import { prune } from 'utilities/array'
import { cls } from 'utilities/dom'
import { isInRange } from 'utilities/math'
import { Field, Label, Control } from 'components/form/field/s'
import { Units } from 'components/units'
import Search from './search'

class EditShare extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const {
            name,
            publicOption,
            field = {},
            picker = {}
        } = props

        let {
            value,
            required = false,
            include = 'touched'
        } = field

        if(!value) {
            value = empty
        }

        let {
            min = 0,
            max = Infinity
        } = picker

        if(required && min === 0) {
            min = 1
        }

        const isPublic = value.length && getOptionId(value[0]) === getOptionId(publicOption)

        this.state = {
            value,
            isPublic,
            focus: false
        }

        if(include !== 'never') {
            context.registerField(name, {
                empty,
                required,
                include,

                unset: () => this.setState({
                    value: empty,
                    isPublic: false,
                    focus: false
                }),

                validator: value => required ?
                    isInRange({ min, max, value: value.length }) :
                    true
            })
        }

        this.field = createRef()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.context.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = (props, { value }) => {
        if(value !== this.state.value && this.props.field?.include !== 'never') {
            this.context.triggerChange(this.props.name)
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.context.unregisterField(this.props.name)
        }
    }

    onChange = () => {
        const { onChange, name } = this.props
        const { value } = this.state

        onChange?.({ [name]: value })
    }

    addShare = share => this.setState(({ value }, { publicOption }) => {
        const publicAdded = getOptionId(share) === getOptionId(publicOption)

        if(publicAdded || this.props.picker?.single) {
            return {
                value: [share],
                isPublic: publicAdded
            }
        }

        const sharesWithoutPublicOption = [...value, share]
            .filter(share => getOptionId(share) !== getOptionId(publicOption))

        const uniqueShares = prune(sharesWithoutPublicOption.map(getOptionId))
            .map(id => sharesWithoutPublicOption.find(share => id === getOptionId(share)))

        return {
            value: uniqueShares,
            isPublic: false
        }
    }, this.onChange)

    removeShare = share => this.setState(({ value, isPublic }, { publicOption }) => {
        const publicRemoved = getOptionId(share) === getOptionId(publicOption)

        return {
            value: value.filter(s => getOptionId(s) !== getOptionId(share)),
            isPublic: publicRemoved ? false : isPublic
        }
    }, this.onChange)

    render() {
        const {
            value,
            isPublic,
            focus
        } = this.state

        const {
            className,
            label,
            salt,
            name,
            enabled = true,
            loading = false,
            field = {},
            controlProps = {},
            picker = {},
            params = {},
            publicOption,
            forwardedRef
        } = this.props

        const {
            locked = [],
            types,
            single = false
        } = picker

        const touched = this.context.touched.includes(name)
        const error = (name in this.context.errors) && touched

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const fakeTextClassName = cls([
            focus && 'focus',
            !enabled && 'disabled',
            !value?.length && 'empty'
        ])

        const valueMapperContext = { name, salt }
        const mapValues = this.context.mode === 'json' ?
            jsonMapper(valueMapperContext) :
            multipartMapper(valueMapperContext)

        const shares = isPublic ?
            value :
            [...locked, ...value]

        const {
            required,
            softRequired,
            optional
        } = field

        return (
            <Field
                {...(fieldClassName ? { className: fieldClassName } : null)}
                ref={this.field}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    {mapValues(value).map(({ key, ...share }) => (
                        <input
                            type="hidden"
                            {...share}
                            key={key} />
                    ))}
                    <Units
                        units={shares}
                        clickables={enabled ? value : []}
                        onClick={this.removeShare}
                        mode="edit"
                        salt={salt} />
                    <Search
                        {...controlProps}
                        id={salt}
                        {...(fakeTextClassName ? { className: fakeTextClassName } : null)}
                        value={value}
                        exclude={locked}
                        publicOption={publicOption}
                        single={single}
                        types={types}
                        params={{
                            ...(params ?? null),
                            ...(picker?.params ?? null)
                        }}
                        addShare={this.addShare}
                        onFocus={() => this.setState({ focus: true })}
                        onBlur={() => this.setState({ focus: false })}
                        disabled={!enabled}
                        forwardedRef={forwardedRef} />
                </Control>
            </Field>
        )
    }
}

export default EditShare

export const jsonMapper = ({ name, salt }) => shares =>
    shares.flatMap(({ type, ...share }, index) => {
        if(!share[type]) {
            share[type] = share
        }

        const { id } = share[type]

        return [
            {
                name: `${name}[${index}][type]`,
                value: type,
                key: `${salt}:value:type:${id}:${index}`
            },
            {
                name: `${name}[${index}][id]`,
                value: id,
                key: `${salt}:value:id:${id}:${index}`
            }
        ]
    })

export const multipartMapper = ({ name, salt }) => shares => {
    const values = shares.flatMap(({ type, ...share }) => {
        if(!share[type]) {
            share[type] = share
        }

        return {
            type,
            id: share[type].id
        }
    })

    const value = `encoded:${encode(JSON.stringify(values))}`

    return [{
        name,
        value,
        key: `${salt}:value`
    }]
}

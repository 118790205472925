import React, { useRef, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSize } from 'hooks/viewport'
import { useDebounce } from 'hooks/debounce'
import { useClickOutside } from 'hooks/click-outside'
import { sum, compact } from 'utilities/array'
import { cls } from 'utilities/dom'
import { Rows, FlexChildShrink } from 'components/flex'
import {
    Content,
    Menu, Tabs, Tab,
    Overflow, OverflowToggler, OverflowArrow,
    OverflowListWrap, OverflowList, OverflowItem
} from './s'
import { Outlet } from 'react-router-dom'
import Beta from 'components/beta'
import { ArrowSortedDown as ToggleArrow } from 'styled-icons/typicons'
import { ArrowUpCircle as UpgradeHint } from 'styled-icons/feather'

export const TabsAndPanes = ({ panes, salt, ...props }) => {
    const width = useSize({ dimension: 'width' })
    const tabs = useRef()
    const overflowerRef = useRef()

    const [fitting, setFitting, { signal }] = useDebounce(0)
    const [overflow, setOverflow] = useState([])
    const [showOverflow, setShowOverflow] = useState(false)
    const [overflowCurrent, setOverflowCurrent] = useState(false)
    const [currentItemIndex, setCurrentItemIndex] = useState(0)

    useClickOutside({
        callback: () => setShowOverflow(false),
        refs: [tabs]
    })

    useEffect(() => {
        const container = tabs?.current
        const overflower = overflowerRef?.current

        if(!container || !overflower) {
            return
        }

        const containerWidth = container?.offsetWidth
        const overflowerWidth = overflower?.offsetWidth

        const itemWidths = []
        const items = container?.querySelectorAll('a.top')
        items.forEach((item, index) => {
            itemWidths.push(item.offsetWidth)

            if([...item.classList].includes('current')) {
                setCurrentItemIndex(index)
            }
        })

        // Don’t count the overflower’s width if all items fit…
        if(sum(itemWidths) <= containerWidth) {
            setFitting(items.length)
        } else {
            // …but only when there’s overflow to begin with
            let fittingItemsWidth = overflowerWidth
            let done = false
            itemWidths.forEach((itemWidth, index) => {
                if(!done && (fittingItemsWidth + itemWidth) < containerWidth) {
                    setFitting(index + 1)
                    fittingItemsWidth += itemWidth
                } else {
                    done = true
                }
            })
        }
    }, [panes, tabs?.current, overflowerRef?.current, width])

    useEffect(() => {
        const overflowing = panes.length > fitting
        setOverflow(overflowing ? panes.slice(fitting) : [])
        setOverflowCurrent(currentItemIndex ? currentItemIndex + 1 > fitting : false)
    }, [fitting, signal, currentItemIndex])

    if(panes.length === 1) {
        return <Outlet />
    }

    const animate = showOverflow ? 'expanded' : 'collapsed'

    const overflowerClassname = cls([
        !overflow.length && 'hide',
        overflowCurrent && 'current'
    ])

    return (
        <Rows {...props}>
            <Content>
                <Outlet />
            </Content>
            <Menu>
                <Tabs ref={tabs}>
                    {panes.map(({
                        path, end = false,
                        label,
                        beta = false,
                        upgradable = false,
                        tabUpgradable = null
                    }, index) => {
                        const overflowing = !!overflow.length && index >= fitting

                        if(tabUpgradable !== null) {
                            upgradable = tabUpgradable
                        }

                        const className = cls([
                            'top',
                            overflowing && 'hide',
                            upgradable && 'upgradable'
                        ])

                        const id = compact(path.split('/')).join('-')

                        end = (index === 0) ?
                            true :
                            end

                        return (
                            <Tab
                                to={splatless(path)} end={end}
                                onClick={() => setShowOverflow(false)}
                                className={className}
                                id={`nav-sub-${id}`}
                                key={`${salt}:tab:${id}:${index}:${overflowing}`}>
                                <span>{label}</span>
                                {!!upgradable && (
                                    <UpgradeHint
                                        className="upgrade"
                                        size={16} />
                                )}
                                {(!upgradable && !!beta) && <Beta showTooltip={false} />}
                            </Tab>
                        )
                    })}
                    <Overflow
                        {...(overflowerClassname ? { className: overflowerClassname } : null)}
                        ref={overflowerRef}>
                        <OverflowToggler onClick={() => setShowOverflow(!showOverflow)}>
                            <FlexChildShrink>
                                <FormattedMessage
                                    id="noun_more"
                                    defaultMessage="More" />
                            </FlexChildShrink>
                            <OverflowArrow animate={animate}>
                                <ToggleArrow size={16} />
                            </OverflowArrow>
                        </OverflowToggler>
                        <OverflowListWrap className={cls(
                            showOverflow ? 'show' : 'hide',
                            'boxy'
                        )}>
                            <OverflowList>
                                {overflow.map(({
                                    path, end = false,
                                    label,
                                    beta = false,
                                    upgradable = false
                                }, index) => (
                                    <OverflowItem key={`${salt}:tab:overflow:${label.toLowerCase()}:${index}`}>
                                        <Tab
                                            to={splatless(path)} end={end}
                                            onClick={() => setShowOverflow(false)}
                                            {...(upgradable ? { className: 'with-upgrade' } : null)}>
                                            <span>{label}</span>
                                            {!!upgradable && (
                                                <UpgradeHint
                                                    className="upgrade"
                                                    size={16} />
                                            )}
                                            {(!upgradable && !!beta) && <Beta showTooltip={false} />}
                                        </Tab>
                                    </OverflowItem>
                                ))}
                            </OverflowList>
                        </OverflowListWrap>
                    </Overflow>
                </Tabs>
            </Menu>
        </Rows>
    )
}

const splatless = path => path.replace(/\/\*$/, '')

export const getPathWithoutBase = base => (path = '') => {
    if(path.startsWith(base)) {
        path = path.slice(base.length)
    }

    return path
}
import React, { useState, useRef } from 'react'
import { useEnvironment } from 'contexts/environment'
import pkg from 'package'
import { getUserAgent } from 'utilities/user-agent'
import {
    Videos, Wrapper,
    ThumbnailLink, ThumbnailButton, Thumbnail, ThumbnailFallback
} from './s'
import Caption from 'components/typography/caption'
import Modal from 'modals/video'
import Embed from './embed'

const salt = `veed`
const { browser: { isSafari } } = getUserAgent()

const Video = ({ id, caption, thumbnail, setShowing, className, ...props }) => {
    const { environment } = useEnvironment()

    const thumbnailRef = useRef()

    const [showThumbnailFallback, setShowThumbnailFallback] = useState(false)

    const aspectRatio = thumbnailRef.current?.naturalWidth ?
        `${thumbnailRef.current.naturalWidth} / ${thumbnailRef.current.naturalHeight}` :
        '16 / 9'

    const ThumbnailWrapper = isSafari
        ? ThumbnailLink
        : ThumbnailButton

    const thumbnailWrapperProps = isSafari
        ? {
            href: `https://veed.io/view/${id}`,
            target: '_blank'
        }
        : {
            onClick: () => setShowing({
                id,
                aspectRatio
            })
        }

    return (
        <Wrapper
            className={className}
            {...props}>
            <ThumbnailWrapper
                {...thumbnailWrapperProps}
                style={{ '--aspect-ratio': aspectRatio }}>
                {!showThumbnailFallback && (
                    <Thumbnail
                        onError={() => setShowThumbnailFallback(true)}
                        src={`https://${environment.tld}/hubfs/${pkg.thirdPartyApps.hubspotProductionAccountId}/veed/${thumbnail}`}
                        ref={thumbnailRef} />
                )}
                {showThumbnailFallback && <ThumbnailFallback />}
            </ThumbnailWrapper>
            {caption && <Caption className="compact">{caption}</Caption>}
        </Wrapper>
    )
}

const Veed = ({ veed: videos = [], ...props }) => {
    const [showing, setShowing] = useState(null)

    if(!videos?.length) {
        return null
    }

    return (
        <Videos>
            {videos.map(video => (
                <Video
                    {...props}
                    {...video}
                    setShowing={setShowing}
                    key={`veed:${video.id}`} />
            ))}
            <Modal
                show={!!showing}
                dismiss={() => setShowing(null)}
                salt={`${salt}:embed`}>
                <Embed
                    {...showing}
                    onDone={() => setShowing(null)} />
            </Modal>
        </Videos>
    )
}

export default Veed

import styled, { css } from 'styled-components'
import { belowPhone } from 'utilities/styled'
import {
    Grid,
    GridColumnHeader as GridColumnHeaderBase,
    GridColumnHeaderSortable as GridColumnHeaderSortableBase
} from 'components/grid'
import { profileGrid } from './profile/s'
import TutorialBase from 'components/tutorial'
import {
    SkeletonListRow as SkeletonListRowBase,
    SkeletonCell as SkeletonCellBase
} from 'components/skeleton'

export const Wrap = styled.div``

export const Header = styled(Grid)`
    ${profileGrid}

    width: 100%;
    border-bottom: 1px solid var(--huma-color-border-default);

    ${belowPhone`
        display: none;
    `}
`

const gridColumnHeaderCss = css`
    display: flex;
    align-items: center;

    height: 72px;
    padding: 24px 0;

    ${belowPhone`
        padding: 16px 0;
    `}
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}
`

export const GridColumnHeaderSortable = styled(GridColumnHeaderSortableBase)`
    ${gridColumnHeaderCss}
`

export const LoadingContainer = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 96px;
`

export const Tutorial = styled(TutorialBase)`
    margin-top: 80px;

    ${belowPhone`
        margin-top: 40px;
    `}
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    ${profileGrid}
`

export const SkeletonCell = styled(SkeletonCellBase)`
    justify-content: center;
`
import React from 'react'
import styled from 'styled-components'
import { messageColors, svgStroke } from 'utilities/styled'
import { cls } from 'utilities/dom'
import { FlexChildShrink } from 'components/flex'
import {
    CheckCircleFilled as Success,
    InfoCircleFilled as Info,
    AlertTriangleFilled as Warning,
    AlertOctagonFilled as Error
} from 'components/feather'

const IconWrap = styled(FlexChildShrink)`
    position: relative;

    margin-inline-end: 16px;
    width: 24px;
    height: 24px;

    ${messageColors}

    svg {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        ${svgStroke()}
    }
`

const AlertIcon = ({ type, className, ...props }) => {
    const Icon = typeToIconMap[type] || null

    className = cls([
        type,
        className
    ])

    return (
        <IconWrap
            {...props}
            {...(className ? { className } : null)}>
            <Icon />
        </IconWrap>
    )
}

export default AlertIcon

const typeToIconMap = {
    success: ({ size = 20, ...props }) => <Success {...props} size={size} />,
    info: ({ size = 20, ...props }) => <Info {...props} size={size} />,
    warning: ({ size = 24, ...props }) => <Warning {...props} size={size} />,
    error: ({ size = 20, ...props }) => <Error {...props} size={size} />
}
import styled, { css } from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Field, Control } from 'components/form/field/s'

export const selectCss = css`
    appearance: none;

    margin: 0;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    width: 100%;
    max-width: 100%;
    height: 40px;
    padding: 0 32px 0 12px;
    background-color: transparent;
    background-image: var(--huma-select-arrow-url);
    background-repeat: no-repeat;
    background-position: right 16px top 50%, 0 0;
    background-size: 10px auto, 100%;

    transition:
        border-color .1s ease-in-out,
        box-shadow .1s ease-in-out;

    line-height: 40px;

    ${belowTablet`
        padding-inline-end: 24px;
        background-position: right 11px top 50%, 0 0;
    `}

    [dir="rtl"] &,
    :root:lang(ar) &,
    :root:lang(iw) & {
        padding: 0 0 0 36px;
        background-position: left 12px top 50%, 0 0;
    }

    &::-ms-expand {
        display: none;
    }

    &:disabled {
        padding: 0 12px;
        color: var(--huma-color-foreground-on-disabled);
        background-color: var(--huma-color-surface-disabled);
        background-image: var(--huma-select-arrow-disabled-url);
        cursor: default;
    }

    &:not(:disabled) {
        &:hover {
            border-color: var(--huma-color-border-default-focus);
        }

        &:focus {
            border-color: var(--huma-color-border-default-focus);
            outline: none;
            box-shadow:
                0 0 0 1px var(--huma-color-surface-default),
                0 0 0 3px var(--huma-color-border-constructive-focus);
        }

        &.error,
        ${Control}.error & {
            border-color: var(--huma-color-border-destructive);

            &:focus {
                box-shadow:
                    0 0 0 1px var(--huma-color-surface-default),
                    0 0 0 3px var(--huma-color-border-destructive-focus);
            }
        }
    }

    &.traditional {
        padding: 0 31px 0 15px;
        border-top-width: 1px;
        border-right-width: 1px;
        border-left-width: 1px;
        border-radius: 4px;
        background-position: right 12px top 50%, 0 0;
    }

    ${Field}.small &,
    &.small {
        height: 32px;

        font-size: 14px;
        line-height: 32px;
    }

    &:invalid {
        color: var(--huma-color-foreground-minimal);
    }

    &.empty {
        color: var(--huma-color-foreground-minimal);
    }

    option {
        background-color: var(--huma-color-surface-default);
        color: var(--huma-color-foreground-default);

        font-weight: 400;

        &[disabled] {
            color: var(--huma-color-foreground-minimal);
        }
    }
`

export default styled.select.attrs({
    tabIndex: 0,
    size: 1
})`
    ${selectCss}
`

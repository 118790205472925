import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { Values } from './s'
import { Tag } from 'components/tags/s'

const ViewCheckboxes = ({ label, className, salt, ...props }) => {
    const { field = {} } = props
    let { value } = field

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                {!!value?.length && (
                    <Values>
                        {value.map(({ id, name }) => (
                            <Tag key={`${salt}:${id}`}>
                                {name}
                            </Tag>
                        ))}
                    </Values>
                )}
                {!value?.length && (
                    <ValueDisplay className="empty">
                        <FormattedMessage
                            id="empty_none_set"
                            defaultMessage="None set" />
                    </ValueDisplay>
                )}
            </Control>
        </Field>
    )
}

export default ViewCheckboxes

import React from 'react'
import { useAbsenceEntries } from 'contexts/absence-entries'
import { LoadingContainer } from './s'
import Entry from './entry'
import Loader from 'components/loader'

const AbsenceRequests = ({ actions = {}, ...props }) => {
    const {
        entries,
        intersecter,
        loading,
        paging = {}
    } = useAbsenceEntries()

    const { hasNextPage } = paging

    return (
        <>
            {entries.map((entry, index) => {
                const last = index + 1 === entries.length

                return (
                    <Entry
                        {...props}
                        entry={entry}
                        actions={actions}
                        {...((!!intersecter && last) ? { ref: intersecter } : null)}
                        key={`absence:requests:entry:${entry.id}`} />
                )
            })}
            {(!!intersecter && !!hasNextPage && !!loading) && (
                <LoadingContainer>
                    <Loader />
                </LoadingContainer>
            )}
        </>
    )
}

export default AbsenceRequests

import React, { Component, createRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { FormContext } from 'components/form/controller'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import isEqual from 'react-fast-compare'
import { Field, Label, Control, Error } from 'components/form/field/s'
import { WithActions as Toggler } from 'components/form/input/toggler'
import DisplayWorkSchedule from './display'
import { Scrollable as Modal } from 'modals/generic'
import Reassign from 'pages/absence/modals/work-schedule/reassign'

const empty = null

class EditWorkSchedule extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const {
            name,
            field = {},
            forwardedRef
        } = props

        let {
            value,
            required = false,
            include = 'touched'
        } = field

        if(!value) {
            value = empty
        }

        this.state = {
            value,
            picking: false
        }

        context.registerField(name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                value: empty,
                picking: false
            }),

            validator: value => required ?
                !!value :
                true
        })

        this.field = createRef()
        this.control = createRef()

        this.combinedControlRef = mergeRefs([
            this.control,
            forwardedRef
        ])
    }

    componentDidMount() {
        this.context.triggerChange(this.props.name, { touched: false })
    }

    componentDidUpdate = ({ forwardedRef, name }, { value }) => {
        const forwardedRefChanged = forwardedRef !== this.props.forwardedRef
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)

        if(forwardedRefChanged) {
            this.combinedControlRef = mergeRefs([
                this.control,
                this.props.forwardedRef
            ])
        }

        if(nameChanged) {
            this.context.unregisterField(name)
            this.register()
        }

        if(valueChanged) {
            this.context.triggerChange(this.props.name)
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name)
    }

    openPicker = () => this.setState({ picking: true })
    closePicker = () => this.setState({ picking: false })
    unset = () => this.onPicked(empty)

    onPicked = value => {
        this.setState({ value })

        const { onChange, name } = this.props
        onChange?.({ [name]: value })

        this.context.triggerChange(name)
    }

    render() {
        const {
            value = empty,
            picking
        } = this.state

        const {
            className,
            controlProps = {},
            label,
            salt,
            name,
            enabled = true,
            loading = false,
            field = {},
            picker = {},
            error: outsideError,
            ...props
        } = this.props

        const touched = this.context.touched.includes(name)
        const error = ((name in this.context.errors) && touched) || !!outsideError

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const id = `${salt}:${name}`

        const {
            required,
            softRequired,
            optional,
            unsettable = true
        } = field

        return (
            <Field
                {...(fieldClassName ? { className: fieldClassName } : null)}
                ref={this.field}>
                {!!label && (
                    <Label
                        htmlFor={id}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <input
                        name={name}
                        defaultValue={value?.id}
                        type="hidden"
                        key={`${id}:input:${value?.id ?? 'empty'}`} />
                    <Toggler
                        {...controlProps}
                        {...omit(props, 'field')}
                        id={id}
                        onClick={this.openPicker}
                        active={picking}
                        unset={(!!value && unsettable) && this.unset}
                        unsettable={unsettable}
                        disabled={!enabled}
                        ref={this.combinedControlRef}>
                        {!!value && <DisplayWorkSchedule {...value} />}
                    </Toggler>
                    <Modal
                        show={picking}
                        dismiss={this.closePicker}
                        salt={salt}>
                        <Reassign
                            {...picker}
                            schedule={value}
                            onSelect={this.onPicked}
                            onDone={this.closePicker}
                            salt={salt} />
                    </Modal>
                    <Error animate={outsideError ? 'reveal' : 'hide'}>
                        {outsideError}
                    </Error>
                </Control>
            </Field>
        )
    }
}

export default EditWorkSchedule
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import LinkBase from 'components/link'
import { CaptionSmall } from 'components/typography/caption'

export const Link = styled(LinkBase)`
    display: grid;
    grid-template-columns: 24px minmax(0, 1fr) 16px;
    align-items: center;
    align-self: center;
    column-gap: 24px;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    max-width: var(--huma-global-content-tight-width);
    width: 100%;
    padding: 16px 24px;

    svg {
        ${svgStroke()}

        &:last-child {
            ${svgStroke('small')}
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }
`

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const Url = styled(CaptionSmall)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${Link}:where(:hover, :focus) & {
        text-decoration: underline;
    }
`
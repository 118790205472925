import styled from 'styled-components'
import {
    svgStroke,
    darkMode
} from 'utilities/styled'
import { FlexChildShrink } from 'components/flex'
import { CaptionSmall as CaptionSmallBase } from 'components/typography/caption'
import { Grid } from 'components/grid'
import { Naked } from 'components/button'

export const Wrap = FlexChildShrink

export const CaptionSmall = styled(CaptionSmallBase)`
    margin-block: 24px 8px;
`

export const Colors = styled(Grid)`
    grid-template-columns: repeat(auto-fit, minmax(32px, 1fr));
    gap: 4px;
`

export const Color = styled(Naked)`
    --button-text-color: lch(from var(--huma-component-color-picker-token) calc(l - 70) calc(c + 20) h);

    ${darkMode`
        --button-text-color: lch(from var(--huma-component-color-picker-token) calc(l + 70) calc(c - 20) h);
    `}

    display: grid;
    justify-content: center;
    align-items: center;

    border-radius: 100%;
    width: 32px;
    height: 32px;
    background-color: var(--huma-component-color-picker-token);

    &:where(:hover, :focus, .selected) {
        box-shadow: inset 0 0 1px var(--button-text-color);
    }

    svg {
        ${svgStroke()}
    }
`
import React from 'react'
import { Steps as SSteps, Step, ActiveStep } from './s'
import { Required } from 'components/form/field/s'
import { cls } from 'utilities/dom'

const Steps = ({ className, steps = [], showAllLabels = true, at = 0, salt }) => {
    if(!steps?.length) {
        return null
    }

    return (
        <>
            <SSteps className={className}>
                {steps.map(({ id, text, required = false }, index) => {
                    const reached = !!id ?
                        index <= steps.findIndex(({ id }) => id === at) :
                        index <= at

                    const current = !!id ?
                        at === steps[index].id :
                        at === index

                    const className = cls([
                        reached && 'reached',
                        current && 'current'
                    ])

                    return (
                        <Step
                            {...(className ? { className } : null)}
                            key={`${salt}:step:${id ?? index}`}>
                            {showAllLabels && (
                                <>
                                    {`${index + 1}. `}
                                    {text}
                                    {!!required && <Required>*</Required>}
                                </>
                            )}
                        </Step>
                    )
                })}
            </SSteps>
            {!showAllLabels && (
                <ActiveStep>
                    {`${at + 1}. `}
                    {steps[at].text}
                </ActiveStep>
            )}
        </>
    )
}

export default Steps
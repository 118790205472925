import React, { useState, useRef, useEffect, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useEditor } from './hooks/editor'
import { hideAll as hideAllTippies } from 'tippy.js'
import presets from './presets'
import Loader from './loader'
import Toolbar from './toolbar'
import { EditorContentStyles, Meta, HelpButton } from './s'
import { EditorContent } from '@tiptap/react'
import LinkBubble from './link/bubble'
import Modal, { Scrollable } from 'modals/generic'
import ManageLink from './link/manage'
import EmojiPicker from 'modals/emoji-picker'
import Help from './help'

const Editor = ({
    value,
    children,
    register,
    transform: outsideTransform,
    extend = {},
    configure = {},
    toolbar = {},
    disabled = false,
    preset,
    enabledExtensions = [],
    disabledExtensions = [],
    className,
    salt,
    ...props
}) => {
    const [initialized, setInitialized] = useState(false)
    const [tooltipsEnabled, setTooltipsEnabled] = useState(false)
    const [marking, setMarking] = useState(null)
    const [picking, setPicking] = useState(null)
    const [showHelp, setShowHelp] = useState(false)

    const transform = useCallback(content => {
        if(typeof outsideTransform === 'function') {
            return outsideTransform(content)
        }

        return content
    }, [outsideTransform])

    const content = useRef(transform(getValue(value)))

    const { every, some } = getExtensionAvailability({
        preset,
        enabledExtensions,
        disabledExtensions
    })

    const id = `${salt}:editor`

    const getMeta = editor => ({
        characters: editor.storage.characterCount.characters(),
        words: editor.storage.characterCount.words()
    })

    const editor = useEditor({
        content: content.current,
        extend,
        configure,
        mode: 'edit',
        every,
        some,
        id,

        edit: {
            editable: !disabled,
            autofocus: !!props.autoFocus ?
                'start' :
                false,

            onCreate: ({ editor }) => {
                initializeContent()

                props.onCreate?.({
                    editor,
                    content: editor.getJSON(),
                    meta: getMeta(editor)
                })
            },

            onUpdate: ({ editor }) => {
                const content = editor.getJSON()

                // Huma Form format
                props.onChange?.({
                    value: content,
                    meta: getMeta(editor)
                })

                // Tiptap format
                props.onUpdate?.({
                    editor,
                    content,
                    meta: getMeta(editor)
                })
            },

            onDestroy: () => setInitialized(false),
            onFocus: () => setTooltipsEnabled(true),

            onBlur: ({ editor }) => {
                setTooltipsEnabled(false)
                hideAllTippies()

                register?.({
                    editor,
                    updater: updateContent
                })
            }
        }
    })

    const clearContent = useCallback(() => queueMicrotask(() => {
        editor.commands.setContent(' ')
        editor.commands.clearContent()
    }), [editor])

    const updateContent = useCallback(update => {
        if(!initialized || !editor) {
            return null
        }

        if(!update) {
            update = editor.getJSON()
        }

        if(!Array.isArray(update)) {
            console.warn('Content should be an array', update)

            if(Array.isArray(update?.content)) {
                update = update?.content
            } else {
                return
            }
        }

        if(!update.length) {
            queueMicrotask(() => editor.commands.focus('start'))
            return
        }

        queueMicrotask(() => {
            editor.commands.setContent(update, false)
            editor.commands.setTextSelection(editor.state.selection)
        })

        content.current = update
        return content.current
    }, [initialized, editor, transform])

    const initializeContent = useCallback(() => {
        if(!initialized) {
            setInitialized(true)

            if(!content.current?.content?.length) {
                clearContent()
            } else {
                updateContent(transform(content.current))
            }
        }
    }, [initialized, clearContent, updateContent, transform])

    useEffect(() => {
        if(initialized && editor) {
            register?.({
                editor,
                updater: updateContent
            })
        }

        return () => register?.(null)
    }, [initialized, editor, register, updateContent])

    useEffect(() => {
        if(editor && marking) {
            hideAllTippies()
        }
    }, [editor, marking])

    useEffect(() => {
        updateContent(transform(content.current).content)
    }, [updateContent, transform])

    if(!initialized) {
        return <Loader />
    }

    const active = {
        ...[1, 2, 3, 4, 5, 6].reduce((accumulator, level) => ({
            ...accumulator,
            [`h${level}`]: editor.isActive('heading', { level })
        }), {}),

        bold: editor.isActive('bold'),
        italic: editor.isActive('italic'),
        underline: editor.isActive('underline'),
        link: editor.isActive('link'),
        strike: editor.isActive('strike'),
        code: editor.isActive('code'),
        subscript: editor.isActive('subscript'),
        superscript: editor.isActive('superscript'),
        highlight: editor.isActive('highlight'),

        bulletList: editor.isActive('bulletList'),
        orderedList: editor.isActive('orderedList'),
        listItem: editor.isActive('listItem'),

        blockquote: editor.isActive('blockquote'),
        codeBlock: editor.isActive('codeBlock'),

        variable: editor.isActive('variable')
    }

    return (
        <>
            <Toolbar
                {...toolbar}
                editor={editor}
                tooltipsEnabled={tooltipsEnabled}
                marking={marking}
                setMarking={setMarking}
                picking={picking}
                setPicking={setPicking}
                active={active}
                every={every}
                some={some}
                salt={`${id}:toolbar`} />
            <EditorContentStyles {...(className ? { className } : null)}>
                <EditorContent editor={editor} />
            </EditorContentStyles>
            <Meta>
                {children}
                <HelpButton onClick={() => setShowHelp(true)}>
                    <FormattedMessage
                        id="noun_help"
                        defaultMessage="Help" />
                </HelpButton>
            </Meta>
            <LinkBubble
                editor={editor}
                marking={marking}
                setMarking={setMarking}
                active={active}
                every={every}
                some={some}
                salt={`${id}:bubble:link`} />
            <Modal
                show={(marking?.type === 'link')}
                dismiss={() => setMarking(null)}>
                <ManageLink
                    editor={editor}
                    link={marking?.link ?? null}
                    dismiss={() => setMarking(null)}
                    salt={`${id}:manage:link`} />
            </Modal>
            <EmojiPicker
                show={picking === 'emoji'}
                salt={salt}
                dismiss={() => setPicking(null)}
                immediate={true}
                cancelAction={() => ({ onClick: () => setPicking(null) })}
                doneAction={({ picked }) => ({
                    onClick: () => {
                        setPicking(null)

                        editor
                            .chain()
                            .focus()
                            .insertContent({
                                type: 'emoji',
                                attrs: {
                                    name: picked
                                }
                            })
                            .run()
                    }
                })}
                key={`${id}:emoji:picker`} />
            <Scrollable
                show={showHelp}
                className="medium"
                dismiss={() => setShowHelp(false)}>
                <Help
                    editor={editor}
                    dismiss={() => setShowHelp(false)} />
            </Scrollable>
        </>
    )
}

export const empty = {
    type: 'doc',
    content: []
}

export const getValue = value => {
    const type = value?.type ?? empty.type
    let content = value?.content ?? value

    if(!Array.isArray(content) || !content.length) {
        content = empty.content
    }

    return {
        type,
        content
    }
}

export const getExtensionAvailability = ({ preset, enabledExtensions = [], disabledExtensions = [] }) => {
    const extensions = (presets[preset] ?? [])
        .concat(enabledExtensions)
        .filter(extension => !disabledExtensions.includes(extension))

    return {
        every: (...names) => names.every(name => extensions.includes(name)),
        some: (...names) => names.some(name => extensions.includes(name))
    }
}

export default Editor
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useWhistleblowingCases } from 'contexts/whistleblowing-cases'
import {
    Container, Wrap,
    Header, ColumnHeader,
    SkeletonListRow, SkeletonCell,
    LoadingContainer
} from './s'
import Empty from 'components/empty'
import Caption from 'components/typography/caption'
import {
    useSkeletonLoader,
    SkeletonStrings, SkeletonStatus, SkeletonAvatarAndFullName
} from 'components/skeleton'
import Case from './case'
import { Ghost } from 'components/button'
import Loader from 'components/loader'

export const containerBreakpoint = 650

const Cases = ({ context = 'open', ...props }) => {
    const {
        kases = [],

        intersecter,
        loading,
        paging = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched
    } = useWhistleblowingCases()

    const skeletonLength = useSkeletonLoader(kases, hasFetched)

    const statusContext = {
        open: {
            cell: 'last-action',
            label: {
                id: 'whistleblowing_case_label_last_action',
                defaultMessage: 'Last action'
            }
        },
        closed: {
            cell: 'closed-by',
            label: {
                id: 'whistleblowing_case_label_closed_by',
                defaultMessage: 'Closed by'
            }
        }
    }[context]

    if(hasFetched && !kases.length) {
        return (
            <Empty which="whistleblowing">
                <Caption className="compact">
                    <FormattedMessage
                        id="incident_cases_empty_filter_message"
                        defaultMessage="🤷 Sorry, no cases were found." />
                </Caption>
            </Empty>
        )
    }

    return (
        <>
            <Container>
                <Wrap
                    {...props}
                    $personCell={statusContext.cell}>
                    <Header>
                        <ColumnHeader $cell="meta">
                            <FormattedMessage
                                id="whistleblowing_case_meta_label_submitted"
                                defaultMessage="Submitted" />
                        </ColumnHeader>
                        <ColumnHeader $cell="status">
                            <FormattedMessage
                                id="noun_status"
                                defaultMessage="Status" />
                        </ColumnHeader>
                        <ColumnHeader $cell={statusContext.cell}>
                            <FormattedMessage {...statusContext.label} />
                        </ColumnHeader>
                    </Header>
                    {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                        <SkeletonListRow
                            columns={3}
                            hasActions={true}
                            key={`list:whistleblowing:cases:skeleton:${index}`}>
                            <SkeletonCell $cell="meta">
                                <SkeletonStrings
                                    size={20}
                                    length={16} />
                            </SkeletonCell>
                            <SkeletonCell $cell="status">
                                <SkeletonStatus />
                            </SkeletonCell>
                            <SkeletonCell $cell={statusContext.cell}>
                                <SkeletonAvatarAndFullName size={40} />
                            </SkeletonCell>
                        </SkeletonListRow>
                    ))}
                    {kases.map((kase, index) => {
                        const last = index + 1 === kases.length

                        return (
                            <Case
                                kase={kase}
                                statusContext={statusContext}
                                {...(last ? { ref: intersecter } : null)}
                                key={`list:whistleblowing:case:${kase.id}`} />
                        )
                    })}
                </Wrap>
            </Container>
            {!!paging?.hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost
                            className="constructive"
                            onClick={fetch}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
        </>
    )
}

export default Cases

import styled, { css } from 'styled-components'
import { fromPhone, belowPhone } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Columns } from 'components/flex'
import { Naked, Plain } from 'components/button'
import Paragraph from 'components/typography/paragraph'
import { compact } from 'utilities/array'
import StatusLabelBase from 'components/status-label'

export const Schedule = styled(Grid)`
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid var(--huma-color-border-default);

    ${({ theme: { workSchedules: { actions } } }) => css`
        ${fromPhone`
            grid-template-columns: ${compact(['3fr', '2fr', '1fr', actions && '40px']).join(' ')};
            column-gap: 24px;
        `}

        ${belowPhone`
            grid-template-areas:
                "name${actions && ' actions'}"
                "assigned${actions && ' actions'}"
                "status${actions && ' actions'}";
            grid-template-columns: ${compact(['1fr', actions && '40px']).join(' ')};
            column-gap: 16px;
        `}
    `}
`

export const NameCell = styled.div`
    ${belowPhone`
        grid-area: name;
    `}
`

export const NameButton = styled(Naked)`
    text-align: start;
`

export const Name = styled(Paragraph)`
    ${NameButton}:hover &,
    ${NameButton}:focus & {
        text-decoration: underline;
    }
`

export const AssignedCell = styled(Columns)`
    ${belowPhone`
        grid-area: assigned;
    `}

    align-items: center;
    column-gap: 8px;

    color: var(--huma-color-foreground-subtle);

    ${belowPhone`
        font-size: 14px;
    `}
`

export const ManageButton = styled(Plain)`
    ${belowPhone`
        font-size: 14px;
    `}
`

export const StatusCell = styled(Columns)`
    ${fromPhone`
        justify-content: flex-end;
    `}

    ${belowPhone`
        grid-area: status;
        justify-content: flex-start;
    `}
`

export const StatusLabel = styled(StatusLabelBase)`
    align-self: center;
`

export const ActionsCell = styled(Columns)`
    ${belowPhone`
        grid-area: actions;
    `}
`
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { Grid } from 'components/grid'

export const Wrapper = styled.div`
    position: relative;
`

export const Revealer = styled(motion.div).attrs(() => ({
    variants: {
        hide: {
            height: 0,
            opacity: 0
        },
        show: {
            height: 'auto',
            opacity: 1,
            overflow: 'visible'
        }
    },
    transition: {
        type: 'spring',
        mass: .5,
        stiffness: 100,
        velocity: 2
    },
    initial: 'hide',
    exit: 'hide'
}))`
    position: relative;
    overflow: hidden;
`

export const Entry = styled(Grid)`
    position: relative;

    ${({ theme: { policyTimeline: { actions } } }) => actions && css`
        grid-template-columns: auto 40px;
    `}

    padding-inline-start: 46px;
    padding-bottom: 32px;

    ${Revealer}:where(:not(:only-of-type)) &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 11px;

        width: 1px;
        height: 100%;
        background-color: var(--huma-color-border-default);
    }

    &.loading {
        margin-block-start: 8px;
    }

    &.endless-start::after {
        content: "";
        position: absolute;
        top: 16px;
        left: 11px;

        width: 1px;
        height: 24px;
        background: linear-gradient(
            to top,
            transparent 0%,
            var(--huma-color-border-default) 100%
        );
    }

    &.endless-end::after {
        content: "";
        position: absolute;
        top: -19px;
        left: 11px;

        width: 1px;
        height: 24px;
        background: linear-gradient(
            to bottom,
            transparent 0%,
            var(--huma-color-border-default) 100%
        );
    }

    &.future::before {
        background: linear-gradient(
            to bottom,
            var(--huma-color-border-default),
            var(--huma-color-border-default) 50%,
            transparent 50%
        );
        background-size: 1px 8px;
    }

    ${Revealer}:first-of-type & {
        &::before {
            top: ${({ theme: { policyTimeline: { size } } }) => `${size / 2}px`};
        }
    }

    ${Revealer}:last-of-type & {
        padding-bottom: 0;

        &::before {
            height: ${({ theme: { policyTimeline: { size } } }) => `${size / 2}px`};
        }

        ${Revealer}.stop + &::before {
            height: 0;
        }
    }
`

export const EntryDot = styled.div`
    box-sizing: content-box;
    position: absolute;
    top: ${({ theme: { policyTimeline: { size } } }) => `${size / 2}px`};
    left: 6px;

    transform: translateY(-50%);
    border: 2px solid var(--huma-color-surface-default);
    border-radius: 50%;
    width: 7px;
    height: 7px;
    background-color: var(--huma-color-foreground-subtle);

    ${Entry}.empty & {
        left: 7px;

        width: 5px;
        height: 5px;
        background-color: var(--huma-color-foreground-on-disabled); // Should probably be another token
    }

    ${Entry}.future & {
        &::before {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;

            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: var(--huma-color-surface-default);
        }
    }

    ${Entry}.ongoing & {
        left: 4px;

        border-width: 3px;
        width: 9px;
        height: 9px;
        background-color: var(--huma-color-foreground-success);
        box-shadow: var(--huma-shadow-low);
    }

    ${Entry}.error & {
        left: 4px;

        border-width: 3px;
        width: 9px;
        height: 9px;
        background-color: var(--huma-color-foreground-error);
    }
`

export const EntryActions = styled.div`
    margin-block-start: -8px;
`

export const SkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    margin-block-start: 4px;
`
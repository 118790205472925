import React, { forwardRef } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useContentAssistant } from 'contexts/content-assistant'
import { Content } from './s'
import UpgradeCTA from 'components/upgrade-cta'
import DialogueItem from './dialogue-item'

const AssistantPanelContent = forwardRef((props, ref) => {
    const { messages } = useContentAssistant()

    const {
        upgradable,
        salt
    } = props

    return (
        <Content ref={ref}>
            {upgradable && (
                <UpgradeCTA
                    feature="content-assistant"
                    useUpgradeIcon
                    useFeatureOrModuleIcon
                    useFeatureDescription
                    className="airy"
                    salt={salt} />
            )}
            {!upgradable && (
                <AnimatePresence>
                    {messages?.map((message, index) => (
                        <DialogueItem
                            {...props}
                            message={message}
                            index={index}
                            key={message.id} />
                    ))}
                </AnimatePresence>
            )}
        </Content>
    )
})

export default AssistantPanelContent

import { useIntl } from 'react-intl'
import { parseISO, differenceInMonths } from 'date-fns'

export const useYearsAndMonthsSince = () => {
    const globalIntl = useIntl()

    return ({ start, end }, options = {}) => {
        const intl = options?.intl ?? globalIntl
        const now = new Date()

        if(typeof start === 'string') {
            start = parseISO(start)
        }

        if(typeof end === 'string') {
            end = parseISO(end)
        } else if(!end) {
            end = now
        }

        if(end > now) {
            end = now
        }

        const wholeMonths = differenceInMonths(end, start)
        const years = Math.floor(wholeMonths / 12)
        const months = wholeMonths % 12

        let formatted = intl.formatMessage({
            id: 'time_hasntstartedyet',
            defaultMessage: 'Hasn‘t started yet'
        })

        if(end > start) {
            if(years && months) {
                formatted = intl.formatMessage({
                    id: `time_year${years > 1 ? 's' : ''}_month${months > 1 ? 's' : ''}`,
                    defaultMessage: '{years, plural, one {One year} other {# years}} and {months, plural, one {one month} other {# months}}'
                }, { years, months })
            } else if(years) {
                formatted = intl.formatMessage({
                    id: 'time_years',
                    defaultMessage: '{years, plural, one {One year} other {# years}}'
                }, { years })
            } else if(months) {
                formatted = intl.formatMessage({
                    id: 'time_months',
                    defaultMessage: '{months, plural, one {One month} other {# months}}'
                }, { months })
            } else {
                formatted = intl.formatMessage({
                    id: 'time_juststarted',
                    defaultMessage: 'Just started'
                })
            }
        }

        return formatted
    }
}

const YearsAndMonthsSince = ({ start, end }) => {
    return useYearsAndMonthsSince()({ start, end })
}

export default YearsAndMonthsSince
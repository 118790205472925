import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Grid } from 'components/grid'

export const Layout = styled(Grid)`
    position: relative;
    overflow: hidden;

    grid-template-columns: minmax(0, 1fr) minmax(400px, 30vw);

    ${belowTablet`
        grid-template-rows: var(--content-height) calc(100% - var(--content-height));
        grid-template-columns: unset;
    `}
`
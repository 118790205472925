import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import {
    svgStroke,
    fromPhone
} from 'utilities/styled'
import LinkBase from 'components/link'
import { Lock } from 'styled-icons/feather'

const containerCss = css`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    column-gap: 16px;
    justify-content: start;
    align-items: center;
    min-height: ${({ size }) => size ? size : 24}px;
    color: var(--huma-color-foreground-default);

    &.small,
    &.compact {
        column-gap: 8px;
    }

    &.truncate {
        grid-template-columns: auto minmax(0, 1fr);
    }
`

export const Plain = styled.div`
    ${containerCss}
`

export const Link = styled(LinkBase)`
    ${containerCss}
    text-decoration: none;

    &:hover,
    &:focus-visible {
        text-decoration: none;
    }
`

export const AvatarWrapper = styled.div`
    position: relative;
    isolation: isolate;

    svg {
        ${svgStroke('small')}
    }
`

const DeactivedBadge = styled.span`
    position: absolute;
    z-index: 1;
    bottom: -4px;
    right: -4px;
    box-sizing: content-box;

    display: inline-grid;
    place-items: center;

    border: 2px solid var(--huma-color-surface-default);
    border-radius: 50%;
    width: ${({ $wrapperSize }) => $wrapperSize}px;
    height: ${({ $wrapperSize }) => $wrapperSize}px;
    background-color: var(--huma-color-surface-subtle);

    color: var(--huma-color-foreground-default);

    svg {
        ${svgStroke('small')}
    }
`

export const Deactivated = forwardRef(({ size = 24, ...props }, ref) => {
    let badgeSize = {}

    if(size >= 88) {
        badgeSize.lock = 16
        badgeSize.wrapper = 32
    } else if(size >= 48) {
        badgeSize.lock = 12
        badgeSize.wrapper = 24
    } else if(size >= 32) {
        badgeSize.lock = 10
        badgeSize.wrapper = 16
    } else {
        badgeSize.lock = 8
        badgeSize.wrapper = 12
    }

    return (
        <DeactivedBadge
            {...props}
            $wrapperSize={badgeSize.wrapper}
            ref={ref}>
            <Lock size={badgeSize.lock} />
        </DeactivedBadge>
    )
})

export const Details = styled.div`
    line-height: 1;

    ${fromPhone`
        &.nowrap {
            white-space: nowrap;
        }
    `}

    &.truncate {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:has(.one-line) {
        display: flex;
    }
`

const BaseParagraph = styled.p`
    font-size: 16px;
    line-height: 24px;

    ${Plain}.small &,
    ${Link}.small & {
        font-size: 14px;
        line-height: 16px;
    }

    &.nowrap {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`

export const Name = styled(BaseParagraph)`
    ${Link}:hover & {
        text-decoration: underline;
    }

    &.deactivated {
        color: var(--huma-color-foreground-subtle);
    }
`

export const Position = styled(BaseParagraph)`
    &.one-line::before {
        content: ", ";
    }

    &:not(.one-line) {
        font-size: 14px;
        line-height: 20px;
        color: var(--huma-color-foreground-subtle);
    }
`

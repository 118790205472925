import styled from 'styled-components'
import {
    svgStroke,
    belowTablet
} from 'utilities/styled'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'
import { NakedLink } from 'components/link'

export const Subsidiary = styled(NakedLink)`
    position: relative;

    display: grid;
    grid-template-columns: repeat(3, minmax(0, 2fr)) minmax(0, min-content);
    align-items: center;
    column-gap: 24px;

    width: 100%;
    padding: 24px 0;

    &:not(:first-child) {
        box-shadow: 0 -1px var(--huma-color-border-default);
    }

    svg {
        ${svgStroke()}
    }

    ${belowTablet`
        grid-template: repeat(2, minmax(0, 1fr)) / minmax(0, 1fr) min-content;
        justify-content: space-between;
        row-gap: 8px;
        column-gap: 16px;
        padding: 16px 0;

        & > :first-child {
            display: none;
        }

        & > :nth-child(2) {
            grid-column: 1;
            grid-row: 1;
        }

        & > :nth-child(4) {
            grid-column: 2 / -1;
            grid-row: 1 / -1;
        }
    `}
`

export const Name = styled(Paragraph)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const Domain = styled(CaptionSmall)`
    line-height: 24px;
`

export const FakeLink = styled.span`
    display: grid;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    grid-column-gap: 8px;
    align-items: center;

    color: var(--huma-color-foreground-constructive-bold);

    ${Subsidiary}:hover &,
    ${Subsidiary}:focus & {
        text-decoration: underline;
    }
`
import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'

export const Wrapper = styled.div`
    width: 100%;
    padding-inline-start: 64px;

    ${belowPhone`
        padding-inline-start: 0;
    `}

    > :last-child {
        margin-bottom: 0;
    }
`

export const Summary = styled.div`
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }
`
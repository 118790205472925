import styled from 'styled-components'

export const Balloon = styled.div`
    --huma-component-balloon-dimension: 10vmax;

    pointer-events: none;
    position: fixed;
    z-index: 2;
    bottom: calc(-1 * var(--huma-component-balloon-dimension));
    left: 0;

    margin-inline-start: 24px;
    border-radius: 100% 100% 15% 100%;
    width: var(--huma-component-balloon-dimension);
    height: var(--huma-component-balloon-dimension);

    transform: rotateZ(45deg);

    &::before {
        content: "";
        position: absolute;
        top: 45%;
        left: 15%;

        border-radius: 100%;
        width: 10%;
        height: 25%;
        background: radial-gradient(
            circle,
            rgba(255,255,255,.7) 0%,
            rgba(255,255,255,.1) 100%
        );
    }

    &::after {
        content: "";
        position: absolute;
        top: 94%;
        left: 90%;

        border-radius: 22%;
        width: 13%;
        height: 5%;
        background-color: inherit;

        transform: rotateZ(-45deg);
    }

    &.blue {
        background-color: var(--huma-color-surface-constructive-bold);
    }

    &.red {
        background-color: var(--huma-color-surface-destructive-bold);
    }

    &.green {
        background-color: var(--huma-color-surface-success-bold);
    }

    &.yellow {
        background-color: var(--huma-color-surface-warning-bold);
    }

    &.purple {
        background-color: var(--huma-color-surface-brand-bold);
    }
`

export const BalloonString = styled.div`
    position: absolute;
    top: calc(var(--huma-component-balloon-dimension) - 6px);
    left: calc(var(--huma-component-balloon-dimension) - 8px);

    width: 2px;
    height: calc(var(--huma-component-balloon-dimension) * .6);
    background-color: #990;

    transform: rotateZ(-45deg);
    transform-origin: top center;
`
import styled from 'styled-components'
import { H4 } from 'components/typography/heading'

export const Steps = styled.div`
    display: flex;
    column-gap: 8px;
`

export const Step = styled.div`
    position: relative;

    display: flex;
    align-items: flex-start;
    flex: 1;

    margin-block-end: 24px;
    min-height: 32px;
    padding-block-start: 8px;

    font-size: 14px;
    line-height: 20px;

    &:empty {
        height: auto;
        min-height: 0;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        border-radius: 8px;
        width: 100%;
        height: 4px;

        background-color: var(--huma-color-surface-subtle);
    }

    &.reached::after {
        background-color: var(--huma-color-surface-constructive-bold);
    }

    &.current {
        font-weight: 500;
    }
`

export const ActiveStep = styled(H4)`
    margin-block-end: 16px;
`
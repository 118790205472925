import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useSmartEntity } from 'contexts/smart-entity'
import { getEntityIcon } from 'components/entity'
import { DrawablePanel, Actions, DrawableRow } from './s'
import { Ghost, Plain } from 'components/button'
import ItemMeta from 'components/item-meta'
import Paragraph from 'components/typography/paragraph'
import Ellipsify from 'components/ellipsify'
import Prompt from 'prompts/standard'
import Label from 'pages/documents/components/smart-editor/sources/label'
import References from 'pages/documents/components/smart-editor/sources/references'

const ToolbarSmartSource = ({
    source, displayName,
    mode = 'toolbar', drawable = null,
    onInsert,
    editable = false, setModifying,
    salt
}) => {
    const { locale } = useI18n()
    const { formatMessage } = useIntl()

    const {
        context,
        fields,

        getValue,
        insertVariable,
        removeSource
    } = useSmartEntity()

    const [prompting, setPrompting] = useState(false)

    let {
        id,
        type,
        name: sourceName,
        label: sourceLabel
    } = source

    sourceLabel = !!sourceLabel?.id ?
        formatMessage(sourceLabel) :
        sourceLabel

    const sourceFields = fields
        .filter(field => field.type === type)
        .map(field => ({
            ...field,
            label: !!field.label?.id ?
                formatMessage(field.label) :
                field.label
        }))
        .sort(({ label: one }, { label: two }) =>
            one.localeCompare(two, locale, { sensitivity: 'base' })
        )

    const Icon = getEntityIcon(type, {
        organization: 'company'
    })

    const value = getValue({ type, name: sourceName })

    return (
        <>
            <DrawablePanel
                {...drawable}
                {...(!drawable ? {
                    header: (
                        <ItemMeta
                            icon={<Icon size={24} />}
                            name={sourceLabel}
                            meta={`$${displayName ?? sourceName}`} />
                    ),
                    trigger: 'header'
                } : null)}
                className={mode}>
                {!!editable && (
                    <Actions>
                        <Ghost
                            className="constructive"
                            onClick={() => setModifying({ source, mode: 'edit' })}>
                            <FormattedMessage
                                id="action_edit"
                                defaultMessage="Edit" />
                        </Ghost>
                        <Ghost
                            className="destructive"
                            onClick={() => setPrompting(true)}>
                            <FormattedMessage
                                id="action_delete"
                                defaultMessage="Delete" />
                        </Ghost>
                    </Actions>
                )}
                {sourceFields.map(({ name: fieldName, label: fieldLabel, formatter }) => (
                    <DrawableRow key={`${salt}:field:${fieldName}`}>
                        <Paragraph className="compact">
                            <Ellipsify text={fieldLabel} />
                        </Paragraph>
                        <Paragraph className="small caption compact">
                            <Ellipsify text={`$${displayName ?? sourceName}.${fieldName}`.replace('.custom', '')} />
                        </Paragraph>
                        <Plain
                            className="constructive compact"
                            onClick={() => {
                                const attributes = {
                                    sourceType: type,
                                    sourceName,
                                    fieldName,
                                    ...(id ? { id } : null),
                                    ...(value ? { value: formatter(value) } : null)
                                }

                                onInsert?.(attributes)
                                insertVariable(attributes)
                            }}>
                            <FormattedMessage
                                id="action_insert"
                                defaultMessage="Insert" />
                        </Plain>
                    </DrawableRow>
                ))}
            </DrawablePanel>
            {!!editable && (
                <Prompt
                    show={!!prompting}
                    salt={salt}
                    question={formatMessage({
                        id: 'documents_smart_data_sources_action_delete_prompt_question',
                        defaultMessage: 'Confirm removing data source'
                    })}
                    cancel={{ callback: () => setPrompting(false) }}
                    confirm={{
                        text: formatMessage({
                            id: 'action_delete',
                            defaultMessage: 'Delete'
                        }),
                        effect: 'destructive',
                        callback: () => removeSource(source)
                    }}>
                    <Label
                        {...source}
                        displayName={displayName}
                        context={context}
                        asCaption={false}
                        showVariableHint={false} />
                    <References
                        {...source}
                        className="caption small" />
                </Prompt>
            )}
        </>
    )
}

export default ToolbarSmartSource
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import {
    Entry,
    TypePeriodCell, TypePeriodFragment, Symbol, TypeMeta, TypeName, DisplayTimePeriod,
    BalanceCell,
    ActionsCell
} from './s'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'
import Balance from 'pages/absence/components/balance'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'

const AbsenceBalanceEntry = ({ entry, actions = {}, salt }) => {
    const {
        type,
        policy,
        count,
        sum,
        pendingCount,
        pendingSum
    } = entry

    const {
        allowGraded = false,
        gradedAggregate = 'full'
    } = type ?? {}

    const [total, pending] = (!!allowGraded && gradedAggregate === 'graded') ?
        [sum, pendingSum] :
        [count, pendingCount]

    const typeName = useTypeNameFormatter()(type)

    const boundActions = getBoundActions(actions, entry)

    const balanceDetails = actions?.balanceDetails?.(entry)
    const managePolicies = actions?.managePolicies?.(entry)

    const TypePeriodWrapper = (balanceDetails || managePolicies) ?
        TypePeriodCell :
        TypePeriodFragment

    const typePeriodProps = (balanceDetails || managePolicies) ?
        {
            onClick: balanceDetails ?
                () => balanceDetails.onClick() :
                () => managePolicies.onClick()
        } :
        null

    return (
        <Entry>
            <TypePeriodWrapper {...typePeriodProps}>
                <Symbol
                    symbol={type.symbol}
                    className="hover-effect" />
                <TypeMeta>
                    <TypeName className="compact">{typeName}</TypeName>
                    <DisplayTimePeriod
                        className="compact"
                        {...policy?.timeWindow} />
                    {!policy && (
                        <CaptionSmall>
                            <FormattedMessage
                                id="absence_policy_message_none_assigned"
                                defaultMessage="No policy assigned" />
                        </CaptionSmall>
                    )}
                </TypeMeta>
            </TypePeriodWrapper>
            <BalanceCell>
                {(total !== null) && (
                    <Paragraph className="compact">
                        <Balance
                            policy={policy}
                            count={total} />
                    </Paragraph>
                )}
                {!!pending && (
                    <CaptionSmall className="compact">
                        <FormattedMessage
                            id="absence_excluded_pending_days_count"
                            defaultMessage="{count, plural, =0 {} =1 {Excluding 1 pending day} other {Excluding {count} pending days}}"
                            values={{ count: pending }} />
                    </CaptionSmall>
                )}
            </BalanceCell>
            {!!boundActions?.length && (
                <ActionsCell>
                    <ContextMenu
                        prebound actions={boundActions}
                        salt={salt} />
                </ActionsCell>
            )}
        </Entry>
    )
}

export default AbsenceBalanceEntry

import React from 'react'
import Modal from 'modals/fullscreen'
import Content from './content'

const salt = 'modal:jubilee-gift'

const JubileeGiftModal = ({ show = false, dismiss }) => (
    <Modal
        show={show}
        dismiss={dismiss}
        salt={salt}>
        {!!show && (
            <Content
                who={show}
                dismiss={dismiss}
                salt={salt} />
        )}
    </Modal>
)

export default JubileeGiftModal

import styled from 'styled-components'
import { Grid } from 'components/grid'

export const Wrap = styled(Grid)`
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-row-gap: 16px;

    margin: 24px 0 48px;
`

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    position: relative;
`

import React from 'react'
import { Organization, IconColumn, DetailsColumn, Name } from './s'
import { MapPin as Location, Tag } from 'styled-icons/feather'
import { Team } from 'components/feather'

export default ({ group, type, ...props }) => {
    const {
        size = 24,
        nowrap = true,
        ...attributes
    } = props

    const Icon = iconMap[type]
    const { name } = group

    return (
        <Organization {...attributes}>
            <IconColumn $iconSize={size}>
                <Icon {...{ size }} />
            </IconColumn>
            <DetailsColumn>
                <Name {...(nowrap ? { className: 'nowrap' } : null)}>
                    {name}
                </Name>
            </DetailsColumn>
        </Organization>
    )
}

export const iconMap = {
    team: Team,
    location: Location,
    tag: Tag
}
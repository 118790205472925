import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import FormBase from 'components/form/controller'
import { FlexChildShrink } from 'components/flex'
import { Search } from 'styled-icons/feather'
import StringField from 'components/form/field/string'

export const FilterRow = styled(FlexChildShrink)`
    margin-top: 16px;
    margin-bottom: 24px;
`

export const Form = styled(FormBase)`
    width: 100%;
    position: relative;
`

export const SearchIcon = styled(Search)`
    position: absolute;
    top: 12px;
    left: 12px;
    pointer-events: none;

    ${svgStroke()}
`

export const SearchField = styled(StringField)`
    width: 100%;
    margin: 0;

    input {
        padding-left: 40px;
    }
`

import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import SymbolBase from 'components/symbol'
import { Background as SymbolBackground, hoverEffect } from 'components/symbol/s'
import Paragraph from 'components/typography/paragraph'

export const itemMetaWrapperCss = css`
    display: grid;
    grid-template-rows:
        [symbol-start name] minmax(24px, auto)
        [meta] 20px [symbol-end];
    grid-template-columns: max-content minmax(0, 1fr);
    column-gap: 16px;
    align-items: center;
    justify-content: start;

    text-align: start;
    text-decoration: none;

    &.wrapped {
        border: 1px solid var(--huma-color-border-default);
        border-radius: 8px;
        padding: 16px;
    }

    &.start {
        align-items: start;
    }

    &.upgradable {
        grid-template-columns: max-content minmax(0, 1fr) min-content;

        > .upgrade-hint {
            grid-row: 1 / span 2;
            grid-column: 3;
        }
    }

    & > svg {
        grid-row: symbol;

        ${svgStroke()}
    }

    &:hover {
        text-decoration: none;
    }

    label& {
        cursor: pointer;
    }
`

export const ItemMetaWrapper = styled.div`
    ${itemMetaWrapperCss}
`

export const Symbol = styled(SymbolBase)`
    grid-row: symbol;

    :where(a, button, label):is(:hover, :focus-within) > & ${SymbolBackground} {
        ${hoverEffect}
    }

    svg {
        ${svgStroke()}
    }
`

export const Name = styled(Paragraph).attrs({ className: 'compact' })`
    grid-column: 2;

    line-height: 20px;

    :where(a, button):is(:hover, :focus-within) > & {
        text-decoration: underline;
    }

    &:last-child {
        grid-row: symbol;
    }
`

export const Meta = styled(Paragraph).attrs({ className: 'caption small compact' })`
    grid-row: 2;
    grid-column: 2;
`
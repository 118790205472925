import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Wrapper } from '../s'
import { Links, Chip, FavIcon } from './s'
import { Label } from 'components/form/field/s'
import Link from 'components/link'
import Ellipsify from 'components/ellipsify'

const ViewUsefulLinks = ({ links = [], tabable = true, salt, ...props }) => {
    if(!links?.length) {
        return null
    }

    return (
        <Wrapper {...props}>
            <Label>
                <FormattedMessage
                    id="noun_useful_links"
                    defaultMessage="Useful links" />
            </Label>
            <Links>
                {links.map(({ name, url }, index) => (
                    <Chip
                        {...(!tabable ? { tabIndex: -1 } : null)}
                        as={Link}
                        key={`${salt}:links:${name}:${index}`}
                        href={url}
                        target="_blank">
                        <FavIcon src={url} />
                        <Ellipsify
                            text={name}
                            head={5} />
                    </Chip>
                ))}
            </Links>
        </Wrapper>
    )
}

export default ViewUsefulLinks

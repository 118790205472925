import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import EntitiesProvider from 'contexts/entities'
import { useAppSignal } from 'hooks/app-signal'
import { modalify, Wrapper, Veil, Modal } from 'utilities/modal'
import { isInRange } from 'utilities/math'
import { size } from 'utilities/object'
import { cls } from 'utilities/dom'
import { Columns, FlexChild, FlexChildShrink } from 'components/flex'
import {
    Container, Hero, Herows, Heading,
    ContentRow, Status, EmptyContainer, CreateButton,
    Actions
} from './s'
import ToggleAll from 'components/picker-toggle-all'
import Paragraph from 'components/typography/paragraph'
import Message from 'components/message'
import Filter from 'modals/entity-picker/filter'
import Search from 'modals/entity-picker/search'
import Entities from './entities'
import { Plain, Button } from 'components/button'

// Creation. TODO: Consider lazifying
import { Plus } from 'styled-icons/feather'
import AddGroup from 'pages/people/modals/add-group'
import AddJobTitle from 'pages/settings/pages/job-titles/content/edit'
import AddCompetenceType from 'pages/competence/modals/type/edit'

const EntitiesPicker = ({ className, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const namespace = `${salt}:entities-picker:filter`

    const {
        onSelect,
        dismiss,
        doneAction,
        cancelAction,
        method = 'manage',
        type,
        path,
        entities,
        sticky = [],
        params = {},
        creator = {},
        creatable = false,
        search: searchConfig,
        heading = formatMessage({
            id: 'action_pick',
            defaultMessage: 'Pick'
        }),
        caption,
        message,
        heroContent,
        emptyText = formatMessage({
            id: 'empty_filter',
            defaultMessage: '🤷 Sorry, nothing was found with your current filter configuration.'
        }),
        max = Infinity,
        ...modal
    } = props

    const min = props.min ?? ((method === 'manage') ? 0 : 1)

    const [createdEntities, setCreatedEntities] = useState([])
    const [prepicked, setPrepicked] = useState([])
    const [locked, setLocked] = useState([])
    const [picked, setPicked] = useState([])
    const [searching, setSearching] = useState(!!searchConfig)
    const [creating, setCreating] = useState(false)
    const [resettable, setResettable] = useState(false)
    const [filterResetAt, setFilterResetAt] = useState(Date.now())

    useAppSignal({
        namespace: `${namespace}:advanced-applied`,
        action: advancedApplied => setResettable(advancedApplied)
    })

    const { check } = useAccess()

    let typeOverride
    let createAccess = (typeToPermissionsMap[type] ?? []).some(check)
    let Creator = typeToCreator[type]

    if(!type && params.types?.length === 2 && params.types?.includes('team') && params.types?.includes('location')) {
        typeOverride = 'group'
        createAccess = typeToPermissionsMap.group.some(check)
        Creator = typeToCreator.group
    }

    let { pickedHeading } = props
    if(pickedHeading) {
        if(typeof pickedHeading === 'function') {
            pickedHeading = pickedHeading({ picked })
        }
    } else {
        pickedHeading = formatMessage({
            id: 'selected_count',
            defaultMessage: '{count, plural, =0 {None} one {One} other {#}} selected'
        }, { count: picked.length })
    }

    const {
        show = false,
        outer = true
    } = modal

    useEffect(() => {
        props.picked && setPrepicked(props.picked)
        props.locked && setLocked(props.locked)

        if(method === 'manage' && props.picked) {
            setPicked(props.picked)
        }

        return () => {
            setPrepicked([])
            setLocked([])
            setPicked([])
        }
    }, [show, method])

    const cancel = cancelAction()
    const done = doneAction({ picked })

    const togglePicked = entity => setPicked(picked => {
        let selection = [
            ...picked,
            entity
        ]

        if(!!picked.find(({ id }) => id === entity.id)) {
            selection = picked.filter(({ id }) => id !== entity.id)
        }

        onSelect?.(selection)
        return selection
    })

    const animate = show ? 'in' : 'out'

    className = cls([
        className,
        creating && 'creating'
    ])

    const searchLocally = setSearch => ({ search }) => setSearch(search)

    salt = `modal:entities-picker:${salt}`

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    {!!outer && <Veil animate={animate} />}
                    <Modal
                        dismiss={dismiss}
                        key={salt}>
                        <EntitiesProvider
                            entities={entities}
                            path={path}
                            filter={params}
                            autoFetch={true}>
                            {({ entities, filtered, loading, autoFetch, hasFetched, paging, setLocalSearch, setEntitiesFilter }) => {
                                entities = [...(filtered ?? entities ?? [])]
                                const showStatus = !!entities.length && !loading && autoFetch

                                return (
                                    <Container
                                        {...(className ? { className } : null)}
                                        animate={animate}>
                                        {!creating && (
                                            <Hero>
                                                <Herows>
                                                    <FlexChildShrink>
                                                        <Columns>
                                                            <FlexChild>
                                                                <Heading>{heading}</Heading>
                                                            </FlexChild>
                                                            <FlexChildShrink>
                                                                <ToggleAll
                                                                    loaded={entities}
                                                                    picked={picked}
                                                                    prepicked={prepicked}
                                                                    locked={locked}
                                                                    setPicked={(...args) => {
                                                                        setPicked(...args)
                                                                        onSelect?.(...args)
                                                                    }}
                                                                    salt={salt} />
                                                            </FlexChildShrink>
                                                        </Columns>
                                                    </FlexChildShrink>
                                                    {!!caption && (
                                                        <FlexChildShrink>
                                                            <Paragraph className="caption compact">{caption}</Paragraph>
                                                        </FlexChildShrink>
                                                    )}
                                                    {!!message && (
                                                        <FlexChildShrink>
                                                            <Message
                                                                {...message}
                                                                className={cls(['compact', message?.className])} />
                                                        </FlexChildShrink>
                                                    )}
                                                    {!!heroContent && (
                                                        <FlexChildShrink>
                                                            {heroContent}
                                                        </FlexChildShrink>
                                                    )}
                                                </Herows>
                                            </Hero>
                                        )}
                                        {(!!path && !creating) && (
                                            <Filter
                                                {...(size(searchConfig ?? {}) ? {
                                                    overrides: {
                                                        search: {
                                                            ...searchConfig,
                                                            autoFocus: true
                                                        }
                                                    }
                                                } : null)}
                                                namespace={namespace}
                                                salt={`${salt}:filter`}
                                                key={filterResetAt} />
                                        )}
                                        {(!!searchConfig && !path && !creating) && (
                                            <Search
                                                {...searchConfig}
                                                setSearch={searchLocally(setLocalSearch)}
                                                searching={searching}
                                                setSearching={setSearching}
                                                salt={salt} />
                                        )}
                                        <ContentRow>
                                            {(!creating && !!showStatus) && (
                                                <Status className="caption small compact">
                                                    <FormattedMessage
                                                        id={!!paging?.hasNextPage ?
                                                            'picker_status_scroll_to_load_more' :
                                                            'picker_status_all_loaded'
                                                        }
                                                        defaultMessage={!!paging?.hasNextPage ?
                                                            '{count} loaded – scroll to load more' :
                                                            'All {count} are loaded'
                                                        }
                                                        values={{ count: entities.length }} />
                                                </Status>
                                            )}
                                            {(!creating && !entities.length && !loading && hasFetched) && (
                                                <EmptyContainer>
                                                    <Paragraph className="caption small">{emptyText}</Paragraph>
                                                    {resettable && (
                                                        <Plain
                                                            className="destructive"
                                                            onClick={() => {
                                                                setEntitiesFilter(params)
                                                                setFilterResetAt(Date.now())
                                                            }}>
                                                            <FormattedMessage
                                                                id="action_reset_filters"
                                                                defaultMessage="Reset filters" />
                                                        </Plain>
                                                    )}
                                                </EmptyContainer>
                                            )}
                                            {(!!creatable && !!createAccess && !!Creator) && (
                                                <>
                                                    {!creating && (
                                                        <CreateButton
                                                            className="constructive"
                                                            onClick={() => setCreating(true)}>
                                                            <Plus size={20} />
                                                            {creator?.heading ?? formatMessage(typeToCreateTextMap[typeOverride ?? type])}
                                                        </CreateButton>
                                                    )}
                                                    {!!creating && (
                                                        <Creator
                                                            {...creator}
                                                            doneLabel={formatMessage({
                                                                id: 'action_add',
                                                                defaultMessage: 'Add'
                                                            })}
                                                            onDone={entity => {
                                                                if(entity) {
                                                                    togglePicked(entity)

                                                                    setCreatedEntities(createdEntities => [
                                                                        entity,
                                                                        ...createdEntities
                                                                    ])
                                                                }

                                                                setCreating(false)
                                                            }}
                                                            salt={`${salt}:create:entity`} />
                                                    )}
                                                </>
                                            )}
                                            {!creating && (
                                                <Entities
                                                    type={type}
                                                    sticky={sticky}
                                                    created={createdEntities}
                                                    prepicked={prepicked}
                                                    locked={locked}
                                                    picked={picked}
                                                    method={method}
                                                    togglePicked={togglePicked}
                                                    searching={searching}
                                                    salt={salt} />
                                            )}
                                        </ContentRow>
                                        {!creating && (
                                            <FlexChildShrink>
                                                <Actions>
                                                    <Plain
                                                        className={cancel.effect}
                                                        onClick={cancel.onClick}>
                                                        {cancel.label}
                                                    </Plain>
                                                    <Button
                                                        className={done.effect}
                                                        onClick={() => {
                                                            done.onClick()
                                                            dismiss()
                                                        }}
                                                        disabled={!isInRange({ min, max, value: picked.length })}>
                                                        {done.label}
                                                    </Button>
                                                </Actions>
                                            </FlexChildShrink>
                                        )}
                                    </Container>
                                )
                            }}
                        </EntitiesProvider>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

export default EntitiesPicker

const typeToPermissionsMap = {
    group: ['groups:manage', 'teams:manage', 'locations:manage'],
    team: ['groups:manage', 'teams:manage'],
    location: ['groups:manage', 'locations:manage'],
    jobTitle: ['users:manage'],
    competenceType: ['competence:manage']
}

const typeToCreateTextMap = {
    group: {
        id: 'group_action_add',
        defaultMessage: 'Add group'
    },
    team: {
        id: 'team_action_add',
        defaultMessage: 'Add team'
    },
    location: {
        id: 'location_action_add',
        defaultMessage: 'Add location'
    },
    jobTitle: {
        id: 'jobtitle_action_add',
        defaultMessage: 'Add job title'
    },
    competenceType: {
        id: 'competence_type_action_add',
        defaultMessage: 'Add competence type'
    }
}

const typeToCreator = {
    group: props => <AddGroup {...props} />,
    team: props => <AddGroup {...props} type="team" />,
    location: props => <AddGroup {...props} type="location" />,
    jobTitle: props => <AddJobTitle {...props} provide />,
    competenceType: props => <AddCompetenceType {...props} />
}
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { cls } from 'utilities/dom'
import { Wrap } from './s'

const CharacterCount = ({ value, maxLength = Infinity }) => {
    const count = value?.length ?? 0
    const limited = maxLength < Infinity
    const exceeding = limited && count > maxLength

    return (
        <Wrap className={cls([
            exceeding ? 'destructive' : 'neutral',
            'compact'
        ])}>
            <FormattedMessage
                id={limited ?
                    'editor_character_count_limit' :
                    'editor_character_count'
                }
                defaultMessage={limited ?
                    '{count} / {total} characters' :
                    '{count} characters'
                }
                values={{
                    count,
                    limit: maxLength
                }} />
        </Wrap>
    )
}

export default CharacterCount
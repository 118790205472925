import styled from 'styled-components'
import { fromTablet } from 'utilities/styled'

const ResizeHandle = styled.div`
    position: absolute;
    z-index: 1;
    isolation: isolate;
    inset-block-start: var(--content-height, 80%);
    cursor: row-resize;

    transition: box-shadow .2s ease-out;
    transform: translateY(-50%);

    border-top: 1px solid var(--huma-color-border-default);
    width: 100%;
    height: 21px;
    background-color: var(--huma-color-surface-elevated);

    &::after {
        content: "";
        position: absolute;
        inset: 0;

        margin: auto;
        border-radius: 4px;
        width: 40px;
        height: 4px;
        background-color: var(--huma-color-surface-inverted);

        opacity: 0.5;
    }

    &.active {
        outline: 2px solid var(--huma-color-surface-constructive-minimal);
    }

    ${fromTablet`
        display: none;
    `}
`

export default ResizeHandle
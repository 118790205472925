import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useTheme, ThemeProvider } from 'styled-components'
import { useAccess } from 'contexts/access'
import { useUserPermissions } from 'contexts/unit-permissions'
import { useMe } from 'contexts/me'
import { useAbsenceTypes } from 'contexts/absence-types'
import { useAbsenceStats } from 'contexts/absence-stats'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import { getTimePeriodValues } from 'pages/absence/utilities'
import { getActionsArray } from 'widgets/context-menu'
import {
    Wrap,
    Header, GridColumnHeader
} from './s'
import Entry from './entry'

const AbsenceBalance = ({ user, setActing, setManagingPolicies, setViewingBalanceDetails, setEntriesFilter, setHistory, salt, ...props }) => {
    const { formatMessage } = useIntl()

    let theme = useTheme()

    const { stats } = useAbsenceStats()
    const { types } = useAbsenceTypes()

    const systemAccess = useAccess()
    const userPermissions = useUserPermissions()

    const absenceAdmin = systemAccess.check('absence:manage')
    const absenceManager = userPermissions?.checkUnitPermission?.('user:absence:manage')
    const hasEditAccess = absenceAdmin || absenceManager

    const { isItMyOwnId } = useMe()

    const typeNameFormatter = useTypeNameFormatter()

    const registerAbsenceAction = ({ type, policy }) => {
        if(!policy || user?.status?.active === false) {
            return null
        }

        const typeName = typeNameFormatter(type)

        return ({
            salt: `${salt}:register`,
            label: formatMessage({
                id: 'absence_action_register_type',
                defaultMessage: 'Register {type}'
            }, {
                type: typeName
            }),
            effect: 'neutral',
            onClick: () => setActing({
                type,
                user,
                mode: 'edit'
            })
        })
    }

    const balanceDetailsAction = ({ type, policy }) => {
        if(!policy || user?.status?.active === false) {
            return null
        }

        return ({
            salt: `${salt}:details`,
            label: formatMessage({
                id: 'absence_balance_heading_details',
                defaultMessage: 'Balance details'
            }),
            effect: 'neutral',
            onClick: () => setViewingBalanceDetails({ type })
        })
    }

    const transferDaysAction = ({ type, policy }) => {
        if(!hasEditAccess || user?.status?.active === false || !policy || !types?.length) {
            return null
        }

        const typePolicy = types.find(({ id }) => id === type.id).policies?.find(({ id }) => id === policy?.id) ?? policy

        if(!typePolicy?.allowTransfer || !Number.isInteger(typePolicy?.quota) || typePolicy?.timeWindow?.type === 'rolling') {
            return null
        }

        return ({
            salt: `${salt}:transfer`,
            label: formatMessage({
                id: 'absence_action_transfer_days',
                defaultMessage: 'Transfer days'
            }),
            effect: 'neutral',
            onClick: () => setViewingBalanceDetails({
                type,
                mode: 'transfer'
            })
        })
    }

    const manualAdjustmentAction = ({ type, policy }) => {
        if(!hasEditAccess || user?.status?.active === false || !types?.length) {
            return null
        }

        const typePolicy = types.find(({ id }) => id === type.id).policies?.find(({ id }) => id === policy?.id) ?? policy

        if(!Number.isInteger(typePolicy?.quota) || typePolicy?.timeWindow?.type === 'rolling') {
            return null
        }

        return ({
            salt: `${salt}:adjustment`,
            label: formatMessage({
                id: 'absence_action_manual_adjustment',
                defaultMessage: 'Manual adjustment'
            }),
            effect: 'neutral',
            onClick: () => setViewingBalanceDetails({
                type,
                mode: 'adjustment'
            })
        })
    }

    const managePoliciesAction = ({ type }) => {
        if(!absenceAdmin || user?.status?.active === false) {
            return null
        }

        return ({
            salt: `${salt}:policies:manage`,
            label: formatMessage({
                id: 'absence_action_manage_policies',
                defaultMessage: 'Manage policies'
            }),
            effect: 'neutral',
            onClick: () => setManagingPolicies(type)
        })
    }

    const viewEntriesAction = ({ type, policy }) => {
        if((!absenceAdmin && !isItMyOwnId(user?.id)) || user?.status?.active === false || !policy) {
            return null
        }

        return ({
            salt: `${salt}:entries`,
            label: formatMessage({
                id: 'absence_balance_heading_entries',
                defaultMessage: 'Entries in this period'
            }),
            effect: 'neutral',
            onClick: () => {
                setHistory(true)
                setEntriesFilter({
                    date: Object.values(getTimePeriodValues(policy.timeWindow)),
                    types: [type.id]
                })
            }
        })
    }

    const actions = {
        registerAbsence: registerAbsenceAction,
        balanceDetails: balanceDetailsAction,
        transferDays: transferDaysAction,
        manualAdjustment: manualAdjustmentAction,
        managePolicies: managePoliciesAction,
        viewEntries: viewEntriesAction
    }

    const hasActions = !!getActionsArray(actions).length

    if(!stats?.length) {
        return null
    }

    theme = {
        ...theme,
        absenceBalance: {
            actions: hasActions
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Wrap {...props}>
                <Header>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="absence_breakdown_heading_type_period"
                            defaultMessage="Type and period" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="absence_total_balance_title"
                            defaultMessage="Total balance" />
                    </GridColumnHeader>
                    {hasActions && <GridColumnHeader />}
                </Header>
                {stats.map(entry => (
                    <Entry
                        entry={entry}
                        actions={actions}
                        salt={`absence:balance:entry:${entry.type.id}`}
                        key={`absence:balance:entry:${entry.type.id}`} />
                ))}
            </Wrap>
        </ThemeProvider>
    )
}

export default AbsenceBalance
import React, { forwardRef } from 'react'
import { cls } from 'utilities/dom'
import { Container, SvgWrapper, Buffer, ProgressBar, Value } from './s'

const ProgressCircle = forwardRef(({ children, className, size = 24, strokeWidth = 4, progress = 0, progressColor, ...props }, ref) => {
    const center = size / 2
    const radius = (size / 2) - (strokeWidth / 2)
    const circumference = 2 * Math.PI * radius

    progress = progress > 100 ? 100 : progress

    const offset = ((100 - progress) / 100) * circumference

    className = cls([
        className,
        progressColor
    ])

    return (
        <Container
            {...props}
            className={className}
            $width={size}
            ref={ref}>
            <SvgWrapper
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}>
                <Buffer
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth} />
                <ProgressBar
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset} />
            </SvgWrapper>
            {!!children && (
                <Value>
                    {children}
                </Value>
            )}
        </Container>
    )
})

export default ProgressCircle

import React, { Children } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDownload } from 'hooks/download'
import { cls } from 'utilities/dom'
import { omit } from 'utilities/object'
import {
    List, Item,
    ActionButton, ActionAnchor, ActionLink, ActionLabel,
    Symbol, Label, Helptext, LoaderWrap
} from './s'
import Loader from 'components/loader'
import { ExternalLink, ArrowUpCircle as UpgradeHint } from 'styled-icons/feather'

const Items = ({ actions, toggle, setPrompting, setUpgrading, salt, inline = false, ...props }) => {
    const navigate = useNavigate()

    return (
        <List {...props}>
            {actions.map(action => {
                const {
                    element = 'button',
                    as,
                    onClick,
                    link = null,
                    download = {},
                    icon,
                    symbol,
                    label,
                    richLabel = null,
                    helptext = null,
                    effect = 'neutral',
                    prompt = null,
                    className,
                    loading,
                    external = null,
                    upgrade = null,
                    disabled = false,
                    salt: actionSalt,
                    ...props
                } = action

                const ActionElement = as ?? ({
                    a: ActionAnchor,
                    link: ActionLink,
                    label: ActionLabel,
                    button: ActionButton,
                    downloader: Downloader
                }[element])

                const confirm = () => {
                    toggle && toggle()
                    onClick && onClick()
                    link && navigate(link.to, omit(link, 'to'))
                    setPrompting(null)
                }

                const actionClassName = cls([
                    className,
                    effect,
                    inline && 'inline',
                    (!!symbol || !!icon) && 'with-icon',
                    loading && 'soft-disable',
                    !!external && 'external',
                    !!upgrade && 'with-upgrade',
                    !!helptext && 'help'
                ])

                const actionKey = `${salt}:${actionSalt}`
                const key = `context-menu:action:${actionKey}`

                return (
                    <Item
                        {...(className ? { className: className } : null)}
                        key={key}>
                        <ActionElement
                            {...props}
                            {...(actionClassName ? { className: actionClassName } : null)}
                            {...(((onClick || !!upgrade) && !disabled) ? {
                                onClick: () => {
                                    if(upgrade) {
                                        return void setUpgrading({
                                            upgrade,
                                            heading: label,
                                            callback: confirm
                                        })
                                    }

                                    if(prompt) {
                                        return void setPrompting({ [actionKey]: confirm })
                                    }

                                    confirm()
                                }
                            } : null)}
                            {...(onClick && disabled ? {
                                onClick: () => alert('Nice try 🤡')
                            } : null)}
                            {...(!upgrade ? link : null)}
                            {...download}
                            {...(disabled ? { disabled: true } : null)}
                            tabIndex={0}>
                            {(!!icon && loading) && (
                                <LoaderWrap>
                                    <Loader />
                                </LoaderWrap>
                            )}
                            {(!!icon && !loading) && icon}
                            {!!symbol && (
                                <Symbol
                                    symbol={symbol}
                                    size={20} />
                            )}
                            {richLabel}
                            {(!richLabel && !!helptext) && (
                                <span>
                                    <Label
                                        as="span"
                                        className="compact">
                                        {label}
                                    </Label>
                                    <Helptext
                                        as="span"
                                        className="compact">
                                        {helptext}
                                    </Helptext>
                                </span>
                            )}
                            {(!richLabel && !helptext) && (
                                <Label
                                    as="span"
                                    className="compact">
                                    {label}
                                </Label>
                            )}
                            {(!icon && loading) && (
                                <LoaderWrap className="end">
                                    <Loader />
                                </LoaderWrap>
                            )}
                            {!!external && (
                                <ExternalLink
                                    className="external"
                                    size={16} />
                            )}
                            {(!!upgrade && !disabled) && (
                                <UpgradeHint
                                    className="upgrade"
                                    size={16} />
                            )}
                        </ActionElement>
                    </Item>
                )
            })}
        </List>
    )
}

export default Items

const Downloader = props => {
    const {
        path,
        external = false,
        children,
        ...attributes
    } = props

    const {
        made,
        objectUrl,
        file,
        name: remoteName,
        trigger,
        download
    } = useDownload({
        path,
        auto: false,
        external,
        keepAlive: true
    })

    let [icon, label] = Children.toArray(children)
    if(!label) {
        label = icon
        icon = null
    }

    const loading = !!made && !objectUrl

    return (
        <ActionAnchor
            {...attributes}
            {...(!made ? { onClick: download } : null)}
            {...((!!objectUrl && !global.navigator.msSaveBlob) ? {
                href: objectUrl,
                download: remoteName
            } : null)}
            {...((!!file && !!global.navigator.msSaveBlob) ? {
                onClick: () => global.navigator.msSaveBlob(file, remoteName)
            } : null)}
            ref={trigger}>
            {(!!icon && loading) && (
                <LoaderWrap>
                    <Loader />
                </LoaderWrap>
            )}
            {(!!icon && !loading) && icon}
            {label}
            {(!icon && loading) && (
                <LoaderWrap className="end">
                    <Loader />
                </LoaderWrap>
            )}
        </ActionAnchor>
    )
}
import styled from 'styled-components'
import { fontMonospace } from 's'
import {
    interpointDivider,
    belowContainer
} from 'utilities/styled'
import { containerProps, CardList } from '../s'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'
import Link from 'components/link'
import RiskLevelBase from 'pages/deviation/components/risk-level'

export const Row = styled(CardList.Row)`
    ${belowContainer(containerProps)`
        display: grid;
        grid-template-columns: 1fr auto 24px auto;
        column-gap: 8px;

        .meta {
            grid-column: span 2;
        }

        .risk {
            grid-row: 1;
            grid-column: 3;
        }

        .status {
            grid-row: 1;
            grid-column: 4;
        }

        .handler {
            grid-row: 2;
            grid-column: 1 / span 3;

            &:last-child {
                grid-column: 1 / -1;
            }
        }

        .message {
            grid-row: 2;
            grid-column: 2 / -1;
            justify-self: end;
        }
    `}
`

export const Category = Paragraph

export const CaseLink = styled(Link)`
    &:hover,
    &:focus-visible {
        text-decoration: none;

        ${Category} {
            text-decoration: underline;
        }
    }
`

export const CaseMeta = styled(CaptionSmall).attrs({ as: 'span' })`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${interpointDivider()}
`

export const CaseNumber = styled.span`
    ${fontMonospace}
    font-size: 12px;
`

export const RiskLevel = styled(RiskLevelBase)`
    ${belowContainer(containerProps)`
        .label {
            display: none;
        }

        > span {
            justify-content: center;

            width: 24px;
            padding-inline: 0;
        }
    `}
`

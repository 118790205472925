import React, { useState, useLayoutEffect } from 'react'
import { get } from 'api'
import { compact } from 'utilities/array'
import { LoadingContainer } from './s'
import Loader from 'components/loader'
import View from './view'
import Edit from './edit'

const RadiobuttonsField = ({ name, salt, ...props }) => {
    const {
        field = {},
        mode = 'edit',
        options: initialOptions = [],
        optionsUrl,
        optionsMapper
    } = props

    const {
        value: initialValue = null,
        editable = true
    } = field

    const [options, setOptions] = useState(compact(initialOptions))
    const [value, setValue] = useState(initialValue)
    const [ready, setReady] = useState(false)

    useLayoutEffect(() => {
        if(!!initialOptions.length) {
            const options = compact(initialOptions)
            setOptions(options)

            if(!!value) {
                setValue(expandValue(options, value))
            }

            setReady(true)
        } else if(optionsUrl) {
            const fetch = async () => {
                let { response: options, ok } = await get({ path: optionsUrl })

                if(ok) {
                    options = compact(options.map(optionsMapper))

                    if(!!value) {
                        setValue(expandValue(options, value))
                    }

                    setOptions(options)
                    setReady(true)
                }
            }

            fetch()
        } else setReady(true)
    }, [initialOptions.map(({ id, value }) => `${id}-${value}`).join(':'), optionsUrl])

    props = {
        ...props,
        field: {
            ...field,
            value
        },
        name,
        options,
        salt: `${salt}:${name}`
    }

    if(!ready) {
        return (
            <LoadingContainer>
                <Loader />
            </LoadingContainer>
        )
    }

    if(mode === 'edit' && editable && ready) {
        return <Edit {...props} />
    }

    return <View {...props} />
}

export default RadiobuttonsField

const expandValue = (options, value) => options.find(option => option.value === value)
import styled, { css } from 'styled-components'
import { belowTablet } from 'utilities/styled'
import {
    Grid,
    GridColumnHeader as GridColumnHeaderBase,
    GridColumnHeaderSortable as GridColumnHeaderSortableBase
} from 'components/grid'

export const Wrap = styled.div``

export const Header = styled(Grid)`
    width: 100%;
    column-gap: 24px;
    box-shadow: 0 1px var(--huma-color-border-default);

    ${({ theme }) => {
        if(!!theme?.surveyComments?.actions) {
            return css`
                grid-template-columns: 1fr 3fr 40px;
            `
        }

        return css`
            grid-template-columns: 1fr 3fr;
        `
    }}
`

const gridColumnHeaderCss = css`
    display: flex;
    align-items: center;
    height: 72px;
    padding: 24px 0;

    ${belowTablet`
        padding: 16px 0;
    `}
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}

    ${belowTablet`
        display: none;
    `}
`

export const GridColumnHeaderSortable = styled(GridColumnHeaderSortableBase)`
    ${gridColumnHeaderCss}
`

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    position: relative;
`
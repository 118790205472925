import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import { ParagraphSmall } from 'components/typography/paragraph'

export const ProgressWrapper = styled(Grid)`
    row-gap: 8px;
    align-items: center;

    text-align: start;

    ${({ theme: { hasButton, showBar } }) => {
        if(hasButton) {
            return css`
                grid-template-columns: auto 40px;
            `
        }
        if(showBar) {
            return css`
                grid-template-rows: 40px auto;
            `
        }
    }}
`

export const ProgressParagraph = ParagraphSmall

export const ProgressButton = styled(Grid)`
    place-items: center;
    width: 40px;
    height: 40px;

    svg {
        ${svgStroke()}
        transition: transform .1s ease-in-out;
    }

    ${ProgressWrapper}:hover & svg {
        transform: translateX(4px);
    }
`
import React from 'react'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import {
    Wrapper,
    Symbol, Title,
    AddButton
} from './s'
import { Plus } from 'styled-icons/feather'

const AbsenceType = ({ type, actionIcon: ActionIcon = Plus, ...props }) => {
    const name = useTypeNameFormatter()(type)

    return (
        <Wrapper {...props}>
            <Symbol
                symbol={type.symbol}
                className="hover-effect" />
            <Title className="compact">{name}</Title>
            <AddButton className="add-button">
                <ActionIcon size={24} />
            </AddButton>
        </Wrapper>
    )
}

export default AbsenceType
import React, { Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { format, isYesterday, isToday, isTomorrow, isSameDay } from 'date-fns'
import { isofy } from 'utilities/date-time'

const DateRange = ({
    start, startFormat = 'PP', showStartDate = true,
    end, endFormat = 'PP', endEmptyText = '',
    as: Wrapper = Fragment, separator = '–', ...props
}) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    if(!start && !end) {
        return null
    }

    const sameDay = (!!start && !!end && isSameDay(start, end))
    const formatDate = dateFormatter(formatMessage, locale)

    start = (start && showStartDate) ?
        formatDate({
            date: start,
            dateFormat: startFormat
        }) :
        null

    end = end ?
        formatDate({
            date: end,
            dateFormat: endFormat
        }) :
        null

    return (
        <Wrapper {...props}>
            {sameDay && start}
            {!sameDay && (
                <FormattedMessage
                    id="date_range_separator"
                    defaultMessage="{from} {separator} {to}"
                    values={{
                        from: start ? <span>{start}</span> : '',
                        to: end ? <span>{end}</span> : endEmptyText,
                        separator
                    }} />
            )}
        </Wrapper>
    )
}

export const dateFormatter = (formatMessage, locale) => ({ date, fallbackFormatter, dateFormat }) => {
    date = isofy(date)

    if(isYesterday(date)) {
        return formatMessage({
            id: 'yesterday',
            defaultMessage: 'Yesterday'
        })
    }

    if(isToday(date)) {
        return formatMessage({
            id: 'today',
            defaultMessage: 'Today'
        })
    }

    if(isTomorrow(date)) {
        return formatMessage({
            id: 'tomorrow',
            defaultMessage: 'Tomorrow'
        })
    }

    if(typeof fallbackFormatter === 'function') {
        return fallbackFormatter(date)
    }

    if(dateFormat) {
        return format(date, dateFormat, { locale })
    }

    return date.toUTCString()
}

export default DateRange
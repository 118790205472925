import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNews } from 'contexts/news'
import { useTheme, ThemeProvider } from 'styled-components'
import { useSize } from 'hooks/viewport'
import { numbers as breakpoints } from 'utilities/styled'
import { Cards, LoadingContainer, EmptyText } from './s'
import EmptyContainer from 'components/empty'
import Loader from 'components/loader'
import { Ghost } from 'components/button'
import Link from 'components/link'

import ListItem from './list-item'
import Card from './card'
import CardSkeletonLoader from './card/skeleton-loader'

const News = ({ actions = [], as = 'list', modal = false }) => {
    let theme = useTheme()

    const {
        articles = [],
        intersecter,
        loading,
        paging = {},
        fetchNews,
        autoFetch,
        hasFetched
    } = useNews()

    const { hasNextPage } = paging

    const width = useSize({ dimension: 'width' })

    const skeletonLength = (width > 1520 || width < breakpoints.tablet) ? 4 : 3

    const Articles = (as === 'list') ?
        Fragment :
        Cards

    const Article = (as === 'list') ?
        ListItem :
        Card

    theme = {
        ...theme,
        articles: articles?.length || skeletonLength
    }

    return (
        <ThemeProvider theme={theme}>
            {(as === 'cards' && !hasFetched) && (
                <Articles>
                    {[...Array(skeletonLength).keys()].map(index => (
                        <CardSkeletonLoader
                            index={index}
                            key={`article:card:skeleton-loader:${index}`} />
                    ))}
                </Articles>
            )}
            {!!hasFetched && (
                <Articles>
                    {articles.map((article, index) => {
                        const last = index + 1 === articles.length

                        return (
                            <Article
                                article={article}
                                actions={actions}
                                modal={modal}
                                {...((!!intersecter && last) ? { ref: intersecter } : null)}
                                key={`article:card:${article.id}`} />
                        )
                    })}
                </Articles>
            )}
            {(!!intersecter && !!hasNextPage) && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost className="constructive" onClick={fetchNews}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
        </ThemeProvider>
    )
}

export const Empty = ({ title: group, children = null }) => (
    <EmptyContainer which="news">
        {group && (
            <>
                <EmptyText>
                    <FormattedMessage
                        id="news_group_empty"
                        defaultMessage="No articles are published to {group} yet."
                        values={{ group }} />
                </EmptyText>
                <Link
                    to="path:news.base"
                    className="constructive">
                    <FormattedMessage
                        id="news_action_navigate_empty"
                        defaultMessage="Go to news" />
                </Link>
            </>
        )}
        {children}
    </EmptyContainer>
)

export default News

import styled, { css } from 'styled-components'
import { fromContainer, belowContainer } from 'utilities/styled'
import { containerProps } from '../s'
import Paragraph from 'components/typography/paragraph'
import { Grid } from 'components/grid'

export const Row = styled(Grid)`
    min-height: 76px;
    padding-block: 16px;

    ${fromContainer(containerProps)`
        grid-template-columns: subgrid;
        grid-column: 1 / -1;
        row-gap: 8px;

        &:not(:last-of-type) {
            box-shadow: 0 1px var(--huma-color-border-default);
        }
    `}

    ${belowContainer(containerProps)`
        position: relative;

        grid-template-columns: minmax(0, 1fr) auto;
        gap: 16px;

        &:first-of-type {
            padding-block-start: 0;
            box-shadow: none;
        }
    `}

    &:is(:hover, :focus-visible) {
        text-decoration: none;
    }
`

export const Cell = styled.div`
    display: flex;

    ${fromContainer(containerProps)`
        flex-direction: column;
        align-items: start;
        justify-content: center;
        grid-column: ${p => p.$cell};

        ${p => p.$cell === 'message' && css`
            grid-row: 2;

            padding-inline-start: 56px; /* Avatar + gap */

            &.no-note {
                grid-column: 1 / -1;
            }
        `}

        ${p => p.$cell === 'note' && css`
            align-items: end;
            grid-row: 2;
        `}
    `}

    ${belowContainer(containerProps)`
        align-items: center;
        justify-content: space-between;
        grid-column: 1 / -1;

        ${p => p.$cell === 'name' && css`
            grid-row: 1;
            grid-column: 1;
        `}

        ${p => p.$cell === 'actions' && css`
            grid-row: 1;
            grid-column: 2;
        `}
    `}
`

export const Label = styled(Paragraph).attrs({ className: 'caption small compact' })`
    ${fromContainer(containerProps)`
        display: none;
    `}
`
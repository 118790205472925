import styled from 'styled-components'
import { darkMode } from 'utilities/styled'
import LinkBase from 'components/link'

export const Link = styled(LinkBase)`
    position: relative;
    display: grid;
    place-items: center;
    gap: 8px;

    border: 2px solid var(--huma-color-border-default);
    border-radius: 8px;
    padding: 24px;

    &:hover {
        border-color: var(--huma-color-border-default-focus);
    }
`

export const LogoWrapper = styled.picture`
    &:not(:has(img.dark)) {
        img {
            display: block !important;
        }
    }
`

export const Logo = styled.img`
    display: block;

    max-width: 100px;
    min-height: 24px;

    &.dark {
        display: none;
    }

    ${darkMode`
        display: none;

        &.dark {
            display: block;
        }
    `}
`
import React, { useState } from 'react'
import { splitName } from 'utilities/file'
import { cls } from 'utilities/dom'
import { Link } from './s'
import FileDisplay from './display'
import FilePreviewModal from 'modals/file-preview'

const FilePreview = ({ meta, preview, download, displayProps = null, ...props }) => {
    const [previewing, setPreviewing] = useState(false)

    const className = cls([
        props?.className,
        'file',
        'neutral'
    ])

    return (
        <>
            <Link
                onClick={() => setPreviewing(true)}
                tabIndex={0}
                className={className}>
                <FileDisplay
                    {...displayProps}
                    name={meta?.fileName}
                    action="preview" />
            </Link>
            <FilePreviewModal
                show={previewing}
                meta={{
                    ...meta,
                    extension: splitName(meta.fileName)?.extension
                }}
                preview={preview}
                download={download}
                dismiss={() => setPreviewing(false)}
                salt={`file:${meta.id}:preview`} />
        </>
    )
}

export default FilePreview
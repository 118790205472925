import React from 'react'
import { Scrollable as Modal } from 'modals/generic'
import Content from './content'

const FeatureUpgrade = ({ show, dismiss, salt, ...props }) => {
    salt = `modal:feature-upgrade:${salt}`

    return (
        <Modal
            show={show}
            dismiss={dismiss}
            salt={salt}>
            {!!show && (
                <Content
                    {...props}
                    dismiss={dismiss}
                    salt={`${salt}:content`} />
            )}
        </Modal>
    )
}

export default FeatureUpgrade

import React, { Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useGetMeta } from './meta'
import { cls } from 'utilities/dom'
import { getEntityIcon } from 'components/entity'
import { Section, Heading, IconWrapper, Label } from './s'
import Avatar from 'components/avatar'
import {
    Terminal as Field,
    RefreshCw as Dynamic
} from 'styled-icons/feather'
import { VariableHint } from 'pages/documents/components/smart-editor/sources/s'
import { InlineMessage } from 'components/message'
import Incomplete from './incomplete'
import NotSet from './not-set'
import Mismatched from './mismatched'
import Detached from './detached'
import Empty from './empty'
import Inaccessible from './inaccessible'
import Options from './options'

const TooltipContent = ({
    context, source, field, value, options, node, updateAttributes,
    filled, incomplete, notSet, mismatched, detached, empty, inaccessible
}) => {
    const { formatMessage } = useIntl()
    const getMeta = useGetMeta()

    const showAvatar = (source?.type === 'user') && !!value?.id

    const who = (source?.name === 'responsible') ?
        {
            id: 'unspecified',
            givenName: formatMessage({
                id: 'noun_user_loggedin',
                defaultMessage: 'Logged-in user'
            })
        } :
        value

    const Icon = getEntityIcon(source?.type, {
        organization: 'company'
    })

    const name = who?.givenName ?? getMeta(source)?.name

    const sourceHeading = (source?.name === 'responsible') ?
        who?.givenName :
        (!!source?.label ?
            (typeof source?.label === 'string') ?
                source.label :
                formatMessage(source.label) :
            null
        )

    const fieldHeading = !!field?.label ?
        (typeof field?.label === 'string') ?
            field.label :
            formatMessage(field.label) :
        null

    const typeHeading = (field?.type in typeHeadingTranslations) ?
        formatMessage(typeHeadingTranslations[field.type]) :
        null

    const fieldHeadingClassName = cls([
        (incomplete || notSet) && 'warning',
        inaccessible && 'error'
    ])

    const showHeading = sourceHeading || fieldHeading
    const showVariableHint = source && field

    const showOptions = (options?.length ?? 0) > 1
    const ContentWrapper = showOptions ?
        Section :
        Fragment

    const sourceDisplayName = (source?.name === 'responsible') ?
        'loggedInUser' :
        source?.name

    return (
        <>
            {(showHeading || showVariableHint) && (
                <Section>
                    {showHeading && (
                        <Heading>
                            {(sourceHeading && showAvatar) && (
                                <Avatar
                                    who={who}
                                    size={16} />
                            )}
                            {(sourceHeading && !showAvatar && Icon) && <Icon size={16} />}
                            {(!sourceHeading && fieldHeading) && <Field size={16} />}
                            <span>
                                {sourceHeading && (
                                    <Label {...(empty ? { className: 'warning' } : null)}>
                                        {sourceHeading}
                                    </Label>
                                )}
                                {(!sourceHeading && typeHeading) && (
                                    <Label>
                                        {typeHeading}
                                    </Label>
                                )}
                                {((sourceHeading || typeHeading) && fieldHeading) && ': '}
                                {fieldHeading && (
                                    <Label {...(fieldHeadingClassName ? { className: fieldHeadingClassName } : null)}>
                                        {fieldHeading}
                                    </Label>
                                )}
                            </span>
                        </Heading>
                    )}
                    {(field?.dynamic === 'time') && (
                        <Heading className="caption">
                            <IconWrapper className="dynamic">
                                <Dynamic size={12} />
                            </IconWrapper>
                            <span>
                                <FormattedMessage {...dynamicTypeTranslations.time} />
                            </span>
                        </Heading>
                    )}
                    {(source?.type === 'user' && source?.name === 'responsible') && (
                        <Heading className="caption">
                            <IconWrapper className="dynamic">
                                <Dynamic size={12} />
                            </IconWrapper>
                            <span>
                                <FormattedMessage {...dynamicTypeTranslations.responsible} />
                            </span>
                        </Heading>
                    )}
                    {showVariableHint && (
                        <VariableHint>
                            {`$${sourceDisplayName}.${field.name}`.replace('.custom', '')}
                        </VariableHint>
                    )}
                </Section>
            )}
            <ContentWrapper>
                {(showOptions && !!node.attrs.cursor && !!node.attrs.value) && (
                    <InlineMessage
                        type="info"
                        className="compact"
                        message={formatMessage({
                            id: 'editor_variable_source_value_options_info',
                            defaultMessage: '{source} has multiple values for {field}.'
                        }, {
                            source: <strong>{name}</strong>,
                            field: <strong>{fieldHeading}</strong>
                        })} />
                )}
                {incomplete && (
                    <Incomplete
                        source={source}
                        field={field}
                        options={options}
                        fieldHeading={fieldHeading} />
                )}
                {notSet && (
                    <NotSet
                        source={source}
                        fieldHeading={fieldHeading} />
                )}
                {mismatched && (
                    <Mismatched
                        field={field}
                        sourceHeading={sourceHeading} />
                )}
                {detached && (
                    <Detached
                        context={context}
                        source={source} />
                )}
                {empty && (
                    <Empty
                        source={source}
                        sourceHeading={sourceHeading} />
                )}
                {inaccessible && (
                    <Inaccessible
                        source={source}
                        fieldHeading={fieldHeading} />
                )}
            </ContentWrapper>
            {showOptions && (
                <Options
                    node={node}
                    options={options}
                    cursor={field?.cursor}
                    updateAttributes={updateAttributes}
                    salt={`${source.name}.${field.name}:${value?.id ?? 'not-set'}`} />
            )}
        </>
    )
}

const typeHeadingTranslations = {
    user: {
        id: 'noun_user',
        defaultMessage: 'User'
    },
    team: {
        id: 'noun_team',
        defaultMessage: 'Team'
    },
    location: {
        id: 'noun_location',
        defaultMessage: 'Location'
    }
}

const dynamicTypeTranslations = {
    time: {
        id: 'editor_variable_source_value_dynamic_time',
        defaultMessage: 'This value changes over time'
    },
    responsible: {
        id: 'editor_variable_source_value_dynamic_responsible',
        defaultMessage: 'Whoever is currently diting or viewing'
    }
}

export default TooltipContent
import React from 'react'
import { Link } from 'react-router-dom'
import { Blob, BlobTrigger, BlobPlain, TextTrigger, TextPlain } from './s'

const getTriggerProps = (url, onClick) => ({
    ...(url ?
        {
            as: Link,
            to: url,
            ...(onClick ? { onClick } : null)
        } :
        { onClick }
    )
})

export const TextTriggerEntity = ({ name, url, onClick }) => (
    <TextTrigger {...getTriggerProps(url, onClick)}>
        {name}
    </TextTrigger>
)

export const TextPlainEntity = ({ name }) => (
    <TextPlain>
        {name}
    </TextPlain>
)

export const BlobTriggerEntity = ({ children, name, url, onClick, className }) => (
    <Blob>
        <BlobTrigger
            {...getTriggerProps(url, onClick)}
            {...className ? { className } : null}>
            {children}
            {name}
        </BlobTrigger>
    </Blob>
)

export const BlobPlainEntity = ({ children, name, className }) => (
    <Blob>
        <BlobPlain {...className ? { className } : null}>
            {children}
            {name}
        </BlobPlain>
    </Blob>
)
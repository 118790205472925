import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { PersonItem, PersonColumns } from './s'
import { FlexChild, FlexChildShrink } from 'components/flex'
import User from 'components/person'
import { Simple } from 'components/button'

const Person = forwardRef(({ person, picked, pick }, ref) => {
    const isPicked = picked?.id === person.id

    return (
        <PersonItem ref={ref}>
            <PersonColumns>
                <FlexChild>
                    <User
                        who={person}
                        showPosition={true}
                        truncate={true}
                        size={40} />
                </FlexChild>
                <FlexChildShrink>
                    <Simple
                        className={isPicked ? 'neutral' : 'constructive'}
                        onClick={() => pick(person)}>
                        <FormattedMessage
                            id={isPicked ? 'noun_picked' : 'action_pick'}
                            defaultMessage={isPicked ? 'Picked' : 'Pick'} />
                    </Simple>
                </FlexChildShrink>
            </PersonColumns>
        </PersonItem>
    )
})

export default Person

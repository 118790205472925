import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useInvoices } from 'contexts/invoices'
import { parseISO, format, isAfter } from 'date-fns'
import {
    Invoice,
    PlanCell, PreviewButton, PlanName,
    InvoiceDateCell, DueAtCell,
    TotalCell, StatusLayout
} from './s'
import Caption from 'components/typography/caption'
import { ParagraphSmall } from 'components/typography/paragraph'
import { Status } from 'components/payment/invoice'
import { Price } from 'components/payment'

const InvoiceItem = ({ invoice, setPreviewing, forwardedRef }) => {
    const { dateLocale: locale } = useI18n()

    const {
        id,
        type,
        currency,
        date,
        dueAt,
        status,
        total,
        lines
    } = invoice

    const {
        getInvoicePreviewUrl,
        getInvoiceDownloadUrl
    } = useInvoices()

    const mainCharge = lines.findLast(({ unitCode, subtotal }) => unitCode === 'users' && subtotal > 0)

    const previewUrl = getInvoicePreviewUrl(id)
    const downloadUrl = getInvoiceDownloadUrl(id)

    const invoiceDate = date ? parseISO(date) : null
    const dueDate = dueAt ? parseISO(dueAt) : null

    const formattedInvoiceDate = invoiceDate ?
        format(invoiceDate, 'PP', { locale }) :
        '—'

    const formattedDueDate = dueDate ?
        format(dueDate, 'PP', { locale }) :
        '—'

    const pastDue = !!dueDate && isAfter(new Date(), dueDate)

    return (
        <Invoice ref={forwardedRef}>
            <PlanCell>
                <PreviewButton onClick={() => setPreviewing({
                    meta: {
                        id,
                        extension: 'pdf'
                    },
                    preview: { path: previewUrl },
                    download: { path: downloadUrl }
                })}>
                    <PlanName className="compact">
                        {lines[0].productName}
                    </PlanName>
                    {!!mainCharge && (type === 'Invoice') && (
                        <Caption className="compact">
                            <FormattedMessage
                                id="people_count"
                                defaultMessage="{count, plural, =0 {No people} =1 {One person} other {{count} people}}"
                                values={{ count: mainCharge?.quantity }} />
                        </Caption>
                    )}
                </PreviewButton>
            </PlanCell>
            <InvoiceDateCell>
                {formattedInvoiceDate}
            </InvoiceDateCell>
            <DueAtCell>
                {formattedDueDate}
            </DueAtCell>
            <TotalCell>
                <Caption>
                    <Price
                        value={total}
                        currency={currency} />
                </Caption>
                <StatusLayout>
                    <Status
                        status={status}
                        pastDue={pastDue}
                        icon={{ size: 16 }}
                        textAs={props => (
                            <ParagraphSmall
                                {...props}
                                className="compact" />
                        )} />
                </StatusLayout>
            </TotalCell>
        </Invoice>
    )
}

export default forwardRef((props, ref) => (
    <InvoiceItem {...props} forwardedRef={ref} />
))
import React, { useState } from 'react'
import { useSmartEntity } from 'contexts/smart-entity'
import { cls } from 'utilities/dom'
import Widget from 'components/tooltip'
import { Section, Button } from '../s'
import Content from './content'
import { VariableHint } from 'pages/documents/components/smart-editor/sources/s'

const ToolbarSmart = ({ every, active, salt }) => {
    const smartEntity = useSmartEntity()

    const [acting, setActing] = useState(false)

    if(!smartEntity || !every('Variable')) {
        return null
    }

    return (
        <Section>
            <Widget
                placement="bottom-start"
                trigger="click"
                wrapperClassName="widget"
                content={(
                    <Content
                        close={() => setActing(false)}
                        salt={`${salt}:smart:variable`} />
                )}
                interactive
                visible={acting}
                onClickOutside={() => setActing(false)}
                duration={0}
                animation={false}
                appendTo={document.body}>
                <Button
                    onClick={() => setActing(true)}
                    className={cls(['dynamic', active.variable && 'active'])}
                    tabIndex={-1}>
                    <VariableHint>$smart</VariableHint>
                </Button>
            </Widget>
        </Section>
    )
}

export default ToolbarSmart
import Heading from '@tiptap/extension-heading'

// https://angelika.me/2023/03/07/how-to-require-document-to-start-with-h1-tiptap/

const Title = Heading.extend({
    name: 'title',
    group: 'title',

    parseHTML: () => [{ tag: 'h1:first-child' }],

    addKeyboardShortcuts() {
        return {
            'Mod-Alt-t': () => this.editor.commands.toggleHeading({ level: 1 }),
            'Mod-Alt-1': () => this.editor.commands.toggleHeading({ level: 1 })
        }
    }
})

export default Title
import React from 'react'
import { Container, Content, Byline, Heading } from './s'
import { SkeletonCategory as Meta } from 'components/skeleton'

const SkeletonLoader = ({ index }) => {
    const headingLines = (index % 2 === 0) ? 1 : 2

    return (
        <Container
            className="no-image"
            aria-live="polite">
            <Content>
                <Byline size={16} />
                <Heading size={20} count={headingLines} />
                <Meta size={16} />
            </Content>
        </Container>
    )
}

export default SkeletonLoader

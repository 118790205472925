import React from 'react'
import { getUserAgent } from 'utilities/user-agent'
import { Keys, Key } from './s'

const Shortcut = ({ shortcut, salt, ...props }) => {
    if(!shortcut?.length || isMobile) {
        return null
    }

    const reference = {
        ...common,
        ...(isMacOS ? macos : windows)
    }

    return (
        <Keys {...props}>
            {shortcut.map(key => {
                const symbol = (reference[key] ?? key)?.toUpperCase?.()

                return (
                    <Key key={`${salt}:shortcut:${symbol}`}>
                        {symbol}
                    </Key>
                )
            })}
        </Keys>
    )
}

export default Shortcut

const {
    isMobile,
    os: { isMacOS }
} = getUserAgent()

const common = {
    capslock: '⇪',
    shift: '⇧',
    arrowup: '↑',
    arrowright: '→',
    arrowdown: '↓',
    arrowleft: '←',
    enter: '↩',
    backspace: '⌫',
    delete: '⌦',
    escape: '⎋',
    tab: '⇥',
    pageup: '⇞',
    pagedown: '⇟',
    space: '␣'
}

const macos = {
    mod: '⌘',
    cmd: '⌘',
    command: '⌘',
    meta: '⌘',
    super: '⌘',
    control: '⌃',
    ctrl: '⌃',
    option: '⌥',
    opt: '⌥',
    alt: '⌥'
}

const windows = {
    mod: 'ctrl',
    control: 'ctrl',
    ctrl: 'ctrl',
    option: 'alt',
    opt: 'alt',
    meta: '❖',
    super: '❖',
    cmd: '❖',
    command: '❖'
}
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Wrap, Columns, Heading } from './s'
import Caption from 'components/typography/caption'
import DisplaySymbol from 'components/symbol'

const Hero = ({ label, emoji, color, colorToken }) => (
    <Wrap>
        <Columns>
            <div>
                {!!label && <Caption className="compact">{label}</Caption>}
                <Heading>
                    <FormattedMessage
                        id="symbol_action_pick"
                        defaultMessage="Pick symbol" />
                </Heading>
            </div>
            <DisplaySymbol symbol={{ emoji, color, colorToken }} />
        </Columns>
    </Wrap>
)

export default Hero
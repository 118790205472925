import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Wrap, Columns, Heading, EmojiCell, UnsetButton, CloseButton } from './s'
import Caption from 'components/typography/caption'
import { DisplayEmoji } from 'components/symbol'
import { X } from 'styled-icons/feather'

const Hero = ({ label, emoji, unset, immediate, dismiss }) => (
    <Wrap>
        <Columns>
            <div>
                {!!label && <Caption className="compact">{label}</Caption>}
                <Heading>
                    <FormattedMessage
                        id="emoji_action_pick"
                        defaultMessage="Pick emoji" />
                </Heading>
            </div>
            {(!!emoji && !immediate) && (
                <EmojiCell>
                    <DisplayEmoji
                        emoji={emoji}
                        size={40} />
                    {!!unset && (
                        <UnsetButton
                            onClick={unset}
                            autoFocus={false}>
                            <X size={24} />
                        </UnsetButton>
                    )}
                </EmojiCell>
            )}
            {!!immediate && (
                <CloseButton onClick={dismiss}>
                    <X size={24} />
                </CloseButton>
            )}
        </Columns>
    </Wrap>
)

export default Hero
import React from 'react'
import { useIntl } from 'react-intl'
import { ModalHeader } from 'modals/generic'
import UpgradeCTA from 'components/upgrade-cta'

const UpgradeModalContent = ({ heading, dismiss, upgrade, callback, salt }) => {
    const { formatMessage } = useIntl()

    return (
        <>
            <ModalHeader
                heading={heading ?? formatMessage({
                    id: 'payment_plan_cta_upgrade',
                    defaultMessage: 'Upgrade'
                })}
                dismiss={dismiss} />
            <UpgradeCTA
                useUpgradeIcon
                useFeatureOrModuleIcon
                useFeatureDescription
                {...upgrade} // Let it override the above props if needed
                callback={() => {
                    dismiss()

                    // Wait for a moment to let the modal close visually
                    global.setTimeout(() => { callback?.() }, 500)
                }}
                className="compact"
                salt={salt} />
        </>
    )
}

export default UpgradeModalContent
import React from 'react'
import { FormattedMessage } from 'react-intl'
import pkg from 'package'
import { Layout, HeaderRow, Heading, CloseButton, Iframe } from './s'
import Caption from 'components/typography/caption'

const FeatureRoadmapModalDumbContent = ({ dismiss }) => (
    <Layout>
        <HeaderRow>
            <Heading>
                <FormattedMessage
                    id="noun_feature_roadmap"
                    defaultMessage="Feature road map" />
            </Heading>
            <Caption className="compact">
                <FormattedMessage
                    id="feature_roadmap_information"
                    defaultMessage="Click any card to add your vote." />
            </Caption>
            <CloseButton onClick={dismiss} />
        </HeaderRow>
        <div>
            <Iframe src={`https://portal.productboard.com/${pkg.thirdPartyApps.productboard}/`} />
        </div>
    </Layout>
)

export default FeatureRoadmapModalDumbContent
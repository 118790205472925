import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { CheckboxGrid, CheckboxField } from './s'
import { Plain } from 'components/button'
import Topic from './topic'

const CategoryItemTopics = ({ category, setTemplate, salt }) => {
    const { formatMessage } = useIntl()

    const {
        id: categoryId,
        topics,
        expanded
    } = category

    const [isAllEnabled, setIsAllEnabled] = useState(true)
    const [isAllOpen, setIsAllOpen] = useState(false)

    useEffect(() => {
        setIsAllEnabled(topics.every(({ enabled }) => enabled))
        setIsAllOpen(topics.every(({ expanded }) => expanded))
    }, [topics])

    return (
        <>
            <CheckboxGrid>
                <CheckboxField
                    salt={salt}
                    className="compact"
                    name={`${salt}:allTopics`}
                    field={{
                        value: isAllEnabled,
                        include: 'always'
                    }}
                    enabled={category.enabled}
                    label={formatMessage({
                        id: 'handbooks_template_action_select_all_topics',
                        defaultMessage: 'Select all topics'
                    })}
                    interaction="switch"
                    onChange={() => {
                        setTemplate(previousTemplate => {
                            const newCategories = previousTemplate?.categories.map(category => {
                                if (category.id === categoryId) {
                                    return {
                                        ...category,
                                        topics: category.topics.map(topic => ({
                                            ...topic,
                                            enabled: !isAllEnabled
                                        }))
                                    }
                                }

                                return category
                            })

                            return {
                                ...previousTemplate,
                                categories: newCategories
                            }
                        })

                        setIsAllEnabled(!isAllEnabled)
                    }}
                    {...(!expanded ? { tabIndex: -1 } : null)}
                    key={`${salt}:switch:all:${isAllEnabled}`} />
                <Plain
                    className="constructive"
                    onClick={() => {
                        setTemplate(previousTemplate => {
                            const newCategories = previousTemplate?.categories.map(category => {
                                if (category.id === categoryId) {
                                    return {
                                        ...category,
                                        topics: category.topics.map(topic => ({
                                            ...topic,
                                            expanded: !isAllOpen
                                        }))
                                    }
                                }

                                return category
                            })

                            return {
                                ...previousTemplate,
                                categories: newCategories
                            }
                        })

                        setIsAllOpen(!isAllOpen)
                    }}
                    {...(!expanded ? { tabIndex: -1 } : null)}>
                    <FormattedMessage
                        id={isAllOpen ? 'action_close_all' : 'action_open_all'}
                        defaultMessage={isAllOpen ? 'Close all' : 'Open all'} />
                </Plain>
            </CheckboxGrid>
            {topics.map(topic => (
                <Topic
                    category={category}
                    topic={topic}
                    setTemplate={setTemplate}
                    salt={salt}
                    key={`${salt}:category:topics:${topic.id}`} />
            ))}
        </>
    )
}

export default CategoryItemTopics

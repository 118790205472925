import React, { useState } from 'react'
import { useTimeout } from 'hooks/timeout'
import { Navigate } from 'react-router-dom'

const DelayedRedirect = ({ wait = 2500, ...props }) => {
    const [ready, setReady] = useState(false)
    useTimeout(() => setReady(true), wait)

    return ready ?
        <Navigate {...props} replace /> :
        null
}

export default DelayedRedirect
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { FlexChildShrink } from 'components/flex'
import { Grid } from 'components/grid'
import { Naked, buttonStyles } from 'components/button'

export const Wrap = FlexChildShrink

export const Columns = styled(Grid)`
    grid-template-columns: minmax(0, 1fr) 40px;
    align-items: end;
    column-gap: 24px;

    padding: 0 0 16px;
`

export const Heading = styled.h1`
    font-size: 24px;
    line-height: 40px;
    font-weight: 500;
`

export const EmojiCell = styled.div`
    position: relative;
`

export const UnsetButton = styled(Naked)`
    position: absolute;
    top: 0;
    left: 0;

    width: 40px;
    height: 40px;
    background-color: transparent;

    transition: .15s ease;
    transition-property: color, background-color;

    color: transparent;

    &:where(:hover, :focus) {
        background-color: rgb(from var(--huma-color-surface-default) r g b / .8);

        color: var(--huma-color-foreground-default);
    }
    svg {
        ${svgStroke()}
    }
`

export const CloseButton = styled(Naked)`
    ${buttonStyles.closeButtonCss()}

    position: static;
`
import styled from 'styled-components'
import { CaptionSmall } from 'components/typography/caption'

export const Container = styled.div`
    position: relative;

    margin-block: 8px 32px;
    border-radius: 8px;
    padding: 12px 16px;
    box-shadow: inset 0 0 0 1px var(--huma-color-border-default);
`

export const Heading = styled(CaptionSmall)`
    line-height: 32px;
`
import { Link as LinkBase } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { fontSans } from 's'
import {
    svgStroke,
    belowTablet, fromTablet
} from 'utilities/styled'
import { Rows, FlexChildShrink, flexChildShrinkCss } from 'components/flex'
import Person from 'components/person'
import StatusLabelBase from 'components/status-label'
import { ArticleHeading } from 'components/typography/heading'
import { Units } from 'components/units'
import { CaptionSmall } from 'components/typography/caption'

export const ArticleImage = styled(FlexChildShrink)`
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    width: 50%;
    max-height: 60vh;

    img {
        width: 100%;
        height: auto;
    }

    ${fromTablet`
        transition: .3s ease transform;

        img {
            border-radius: 8px;

            /* Prevent children flicker on hover */
            -webkit-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -webkit-transform-style: preserve-3d;
        }
    `}

    ${belowTablet`
        order: 1;
        width: 100vw;
        max-width: 100vw;
        max-height: 60svh;
        position: relative;
        left: 50%;
        right: 50%;
        margin-right: -50vw;
        margin-left: -50vw;
        border-radius: 0;
    `}
`

export const ArticleColumns = styled.article`
    position: relative;

    display: flex;

    ${({ theme: { image } }) => {
        if(!image) {
            return css`
                border: 4px solid var(--huma-color-border-default);
                border-radius: 8px;
            `
        }
    }}

    /* Prevent children flicker on hover */
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-style: preserve-3d;

    ${fromTablet`
        &:not(:first-of-type) {
            margin-top: 40px;
        }
    `}

    ${belowTablet`
        flex-direction: column;

        &:not(:first-of-type) {
            margin-top: 12px;
        }

        ${({ theme: { image } }) => {
            if(!image) {
                return css`
                    border: none;
                    border-radius: 0;
                `
            }
        }}
    `}

    &:hover {
        ${ArticleImage} {
            transform: scale(1.01);
        }
    }
`

export const Link = styled(LinkBase)`
    color: var(--huma-color-foreground-default);
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        ${belowTablet`
            right: 50%;
            left: 50%;

            margin-right: -50vw;
            margin-left: -50vw;
        `}
    }
`

export const ArticleSummary = styled(Rows)`
    justify-content: center;

    margin: 16px 0 16px 24px;
    padding: 24px;

    ${({ theme: { image } }) => {
        if(!image) {
            return css`
                flex: 0 0 75%;
            `
        }
    }}

    ${belowTablet`
        order: 2;
        margin: 0;
        padding: 24px 0;
    `}
`

export const StatusLabel = styled(StatusLabelBase)`
    margin-bottom: 16px;
    ${belowTablet`
        position: absolute;
        top: 20px;
        right: 56px;
        margin: 0;
    `}
`

export const Avatar = styled(Person)`
    ${flexChildShrinkCss}
    margin-bottom: 16px;
`

export const Title = styled(ArticleHeading)`
    ${flexChildShrinkCss}
`

export const Meta = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    & > :not(:last-child) {
        position: relative;
        padding-inline-end: 24px;

        &::after {
            content: "·";
            width: 24px;
            height: 24px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            ${fontSans}
            text-align: center;
            line-height: 24px;
            color: var(--huma-color-foreground-subtle);
        }
    }
`

export const DateTime = styled.time`
    white-space: nowrap;
    font-size: 14px;
    line-height: 40px;
    color: var(--huma-color-foreground-subtle);

    &::first-letter {
        text-transform: uppercase;
    }
`

export const SharedTo = styled(Units)`
    ${flexChildShrinkCss}
`

export const ReadPercentage = styled(CaptionSmall)`
    display: inline-grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    align-items: center;
    white-space: nowrap;
    line-height: 40px;

    svg {
        ${svgStroke('small')}
        margin-inline-end: 6px;
    }
`
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useGetMeta } from './meta'
import { InlineMessage } from 'components/message'
import Link from 'components/link'

const TooltipContentInaccessible = ({ source, fieldHeading }) => {
    const { formatMessage } = useIntl()
    const getMeta = useGetMeta()

    const { name, pname, to } = getMeta(source)
    const translation = translations[source.type]

    if(!translation) {
        return null
    }

    return (
        <>
            <InlineMessage
                type="error"
                className="compact"
                message={formatMessage({
                    id: 'editor_variable_source_value_not_accessible',
                    defaultMessage: 'You don’t have access to {field} on {source}.'
                }, {
                    field: <strong>{fieldHeading}</strong>,
                    source: <strong>{name}</strong>
                })} />
            {(!!translation && !!to) && (
                <Link
                    to={to}
                    target="_blank"
                    className="constructive small">
                    <FormattedMessage
                        {...translation}
                        values={{ pname }} />
                </Link>
            )}
        </>
    )
}

const translations = {
    user: {
        id: 'action_navigate_user',
        defaultMessage: 'Go to {pname} profile'
    },
    team: {
        id: 'team_action_navigate',
        defaultMessage: 'Go to team'
    },
    location: {
        id: 'location_action_navigate',
        defaultMessage: 'Go to location'
    }
}

export default TooltipContentInaccessible
import React, { Component, createRef } from 'react'
import { useIntl } from 'react-intl'
import { useForm } from 'components/form/controller'
import { mergeRefs } from 'react-merge-refs'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import isEqual from 'react-fast-compare'
import { Field, Label, Control, Placeholder } from 'components/form/field/s'
import DefaultToggler, { WithActions } from 'components/form/input/toggler'
import Person from 'components/person'
import PersonPicker from 'modals/person-picker'
import GroupPersonPicker from 'modals/group-person-picker'

const empty = null

class EditPerson extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this.valueStateFromProps(props),
            picking: false
        }

        this.register()

        this.control = createRef()
        this.combinedControlRef = mergeRefs([
            this.control,
            props.forwardedRef
        ])
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ forwardedRef, name, field, whistle }, { value }) => {
        const forwardedRefChanged = forwardedRef !== this.props.forwardedRef
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(forwardedRefChanged) {
            this.combinedControlRef = mergeRefs([
                this.control,
                this.props.forwardedRef
            ])
        }

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                value: empty,
                picking: false
            }),

            validator: value => required ?
                !!value :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value || empty
    })

    openPicker = () => {
        this.setState({ picking: true })
        this.props.onToggle?.()
    }

    closePicker = () => {
        this.setState({ picking: false })
        this.props.onToggle?.()
    }

    unset = () => this.onPicked(empty)

    onPicked = value => {
        this.setState({ value })
        this.props.onChange?.({ [this.props.name]: value })
    }

    render() {
        const {
            value = {},
            picking
        } = this.state

        const {
            className,
            controlProps = {},
            toggler = {},
            picker = {},
            entity = {},
            label,
            salt,
            name,
            enabled = true,
            loading = false,
            field = {},
            formatMessage,
            ...props
        } = this.props

        const {
            mode: togglerMode = 'default',
            onClick: togglerOnClick
        } = toggler

        const {
            mode: pickerMode = 'default',
            outer = true
        } = picker

        const { params = {} } = entity

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        let {
            required,
            softRequired,
            optional,
            placeholder,
            unsettable = true
        } = field

        if(!placeholder) {
            placeholder = formatMessage({
                id: 'person_action_pick',
                defaultMessage: 'Pick person'
            })
        }

        const id = `${salt}:${name}`

        const Toggler = {
            'with-actions': WithActions
        }[togglerMode] ?? DefaultToggler

        const Picker = {
            group: GroupPersonPicker
        }[pickerMode] ?? PersonPicker

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={id}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <input
                        name={name}
                        value={value?.id || ''}
                        type="hidden" />
                    <Toggler
                        {...controlProps}
                        {...omit(props, 'field', 'form', 'whistle')}
                        id={id}
                        onClick={() => {
                            this.openPicker()
                            togglerOnClick?.()
                        }}
                        active={picking}
                        unset={(!!value && unsettable) && this.unset}
                        unsettable={unsettable}
                        type="person"
                        disabled={!enabled}
                        ref={this.combinedControlRef}>
                        {!!value?.id && (
                            <Person
                                who={value}
                                truncate={true} />
                        )}
                        {!value?.id && (
                            <Placeholder>
                                {placeholder}
                            </Placeholder>
                        )}
                    </Toggler>
                    <Picker
                        {...picker}
                        params={params}
                        outer={outer}
                        show={picking}
                        picked={value}
                        {...(!!picker?.prepicked) ? { prepicked: picker.prepicked } : null}
                        salt={id}
                        dismiss={this.closePicker}
                        cancelAction={() => ({
                            label: formatMessage({
                                id: 'action_cancel',
                                defaultMessage: 'Cancel'
                            }),
                            effect: 'neutral',
                            onClick: this.closePicker
                        })}
                        doneAction={({ picked }) => ({
                            label: formatMessage({
                                id: 'person_action_pick',
                                defaultMessage: 'Pick person'
                            }),
                            effect: 'constructive',
                            onClick: () => this.onPicked(picked)
                        })} />
                </Control>
            </Field>
        )
    }
}

export default props => {
    const form = useForm()
    const { formatMessage } = useIntl()

    return (
        <EditPerson
            {...props}
            form={form}
            formatMessage={formatMessage} />
    )
}
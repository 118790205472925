import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useReveal } from 'hooks/reveal'
import { ValueDisplay } from './s'
import { Field, Label, Control } from 'components/form/field/s'
import Link from 'components/link'
import Ellipsify from 'components/ellipsify'
import { cls } from 'utilities/dom'

const ViewString = ({ className, label, ...props }) => {
    const { locale } = useI18n()

    const {
        field = {},
        controlProps = {},
        display,
        obscure = false,
        ellipsify = null
    } = props

    let {
        value,
        suffix = ''
    } = field

    const {
        type,
        inputMode
    } = controlProps

    const [node, reveal] = useReveal(obscure)

    let hasValue = !!value

    if(type === 'number') {
        hasValue = Number.isFinite(value)

        if(hasValue) {
            value = Number(value)

            if(inputMode === 'decimal' && Intl?.NumberFormat) {
                value = Intl.NumberFormat(locale, {
                    style: 'decimal',
                    maximumFractionDigits: 2
                }).format(value)
            }
        }
    }

    const displayClassName = cls([
        hasValue && display,
        (hasValue && obscure) && 'obscured',
        (hasValue && reveal) && 'revealed',
        !hasValue && 'empty'
    ])

    const controlClassName = cls([
        controlProps.className,
        display
    ])

    const [Element, elementProps] = [
        (type === 'url') ?
            Link :
            Fragment,
        (type === 'url') ?
            {
                href: value,
                target: '_blank',
                className: 'constructive'
            } :
            null
    ]

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control {...(controlClassName ? { className: controlClassName } : null)}>
                <ValueDisplay
                    {...(displayClassName ? { className: displayClassName } : null)}
                    ref={node}>
                    {(hasValue && !!ellipsify) && (
                        <Element {...elementProps}>
                            <Ellipsify
                                {...ellipsify}
                                text={`${value}${suffix}`} />
                        </Element>
                    )}
                    {(hasValue && !ellipsify) && (
                        <Element {...elementProps}>
                            {`${value}${suffix}`}
                        </Element>
                    )}
                    {!hasValue && (
                        <FormattedMessage
                            id="empty_not_entered"
                            defaultMessage="Not entered" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewString
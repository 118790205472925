import React from 'react'
import styled, { css, withTheme, ThemeProvider } from 'styled-components'
import { Columns, FlexChild, FlexChildShrink } from 'components/flex'
import { ArrowSortedDown as Descending, ArrowSortedUp as Ascending } from 'styled-icons/typicons'
import { cls } from 'utilities/dom'

export const Table = styled.table`
    width: 100%;
    table-layout: fixed;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
`

export const TableColumnGroupColumn = styled.col`
    width: ${({ theme: { width = 'auto' } }) => width};
`

export const TableColumnGroup = withTheme(({ widths = [], theme }) => (
    <colgroup>
        {widths.map((width, index) => (
            <ThemeProvider theme={{ ...theme, width }} key={index}>
                <TableColumnGroupColumn />
            </ThemeProvider>
        ))}
    </colgroup>
))

export const TableHead = styled.thead``
export const TableBody = styled.tbody``
export const TableFoot = styled.tfoot``

export const TableRow = styled.tr``

const cellCss = css`
    padding: 0;
    vertical-align: middle;
`

export const TableColumnHeader = styled.th.attrs({ scope: 'col' })`
    ${cellCss}
    font-size: 16px;
    font-weight: 400;
    color: var(--huma-color-foreground-subtle);
`

export const TableRowHeader = styled.th.attrs({ scope: 'row' })`
    ${cellCss}
    font-weight: 500;
`

export const TableCell = styled.td`
    ${cellCss}
`

export const TableCellipsis = styled(TableCell)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const SortOrderIcon = ({ order }) => {
    const Icon = order === 'asc' ? Ascending : Descending
    return <Icon size={16} />
}

const TableColumnHeaderClickable = styled(TableColumnHeader)`
    cursor: pointer;
    user-select: none;
`

const TableColumnHeaderTextColumn = styled(FlexChildShrink)`
    margin-right: 16px;
`

export const TableColumnHeaderSortable = ({ field, toggle, text, sort, className, ...props }) => {
    const active = field === sort.by

    className = cls([
        className,
        active && 'active'
    ])

    return (
        <TableColumnHeaderClickable
            {...props}
            {...(className ? { className } : null)}
            onClick={() => toggle(field)}>
            <Columns>
                <TableColumnHeaderTextColumn>
                    {text}
                </TableColumnHeaderTextColumn>
                {active && (
                    <FlexChild>
                        <SortOrderIcon order={sort.order} />
                    </FlexChild>
                )}
            </Columns>
        </TableColumnHeaderClickable>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'
import paths from 'app/paths'
import { numbers } from 'utilities/styled'
import { TextLogoWrapper, LogoWrapper } from './s'

const Logo = ({ logo, className, link = false, as, ...props }) => {
    if(!logo) {
        return null
    }

    const element = link ?
        Link :
        (as ?? 'div')

    return (
        <LogoWrapper
            className={className}
            {...(link ? { to: paths.root } : null)}
            as={element}>
            <img
                {...props}
                src={logo['4:1-small'].url}
                srcSet={`
                    ${logo['4:1-small'].url} ${numbers.phone}w,
                    ${logo['4:1-medium'].url} ${numbers.tablet}w
                `}
                itemProp="logo" />
        </LogoWrapper>
    )
}

export const TextLogo = ({ textLength, link = false, as, ...props }) => {
    const element = link ?
        Link :
        (as ?? 'div')

    return (
        <TextLogoWrapper
            {...props}
            $textLength={textLength}
            {...(link ? { to: paths.root } : null)}
            as={element} />
    )
}

export default Logo
import styled from 'styled-components'
import { Rows as RowsBase } from 'components/flex'
import { H4 } from 'components/typography/heading'

export const Notifications = styled(RowsBase)`
    &:not(:last-child) {
        margin-bottom: 40px;
    }
`

export const SectionHeading = styled(H4)`
    margin-bottom: 8px;
`
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { belowTablet } from 'utilities/styled'
import Paragraph from 'components/typography/paragraph'

export const EmptyWrapper = styled(motion.div).attrs(() => ({
    variants: {
        out: {
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        in: {
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .3
            }
        }
    },
    initial: 'out'
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 400px;
    padding: 40px 0;

    ${belowTablet`
        min-height: 150px;
    `}
`

export const GenericEmpty = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    padding: 40px 0;

    ${belowTablet`
        min-height: 150px;
    `}
`

export const Image = styled.img`
    margin-bottom: 40px;
    width: 100%;
    max-width: 450px;
`

export const EmptyContent = styled.div`
    margin: 0 auto;
    max-width: 550px;

    line-height: 24px;
    text-align: center;
    white-space: pre-line;
`

export const EmptyText = styled(Paragraph)`
    &:not(:last-child) {
        margin-block-end: 32px;
    }
`
import React, { Component, createRef } from 'react'
import { FormContext } from 'components/form/controller'
import { omit, size } from 'utilities/object'
import { cls } from 'utilities/dom'
import { Control } from './s'
import { Field, Label, Error } from 'components/form/field/s'
import Inputs from './inputs'
import { formatDataTime } from 'components/form/field/time'

const empty = null

class EditTimeSimple extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const {
            name,
            field = {}
        } = props

        let {
            value,
            required = false,
            include = 'touched'
        } = field

        if(!value) {
            value = empty
        }

        this.state = {
            value: this.getDataFormattedTime(value),
            errors: {},
            isInitiallyInvalid: false,
            unsetAt: Date.now()
        }

        context.registerField(name, {
            empty,
            required,
            include,

            unset: this.unset,

            validator: value => {
                const {
                    errors,
                    isInitiallyInvalid
                } = this.state

                if(size(errors)) {
                    return false
                }

                if(isInitiallyInvalid) {
                    return false
                }

                if(required && !value) {
                    return false
                }

                return true
            }
        })

        this.field = createRef()
        this.control = createRef()
    }

    componentDidMount() {
        this.context.triggerChange(this.props.name, { touched: false })
    }

    componentDidUpdate = ({ name }, { value }) => {
        if(value !== this.state.value) {
            this.context.triggerChange(name)
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name)
    }

    getDataFormattedTime = (time = '') => {
        if(time) {
            const { mode = 'date' } = this.props
            return formatDataTime(mode)(time)
        }

        return time
    }

    unset = () => void this.setState({
        value: empty,
        unsetAt: Date.now()
    }, () => this.props.onChange?.({ [name]: empty }))

    onChange = (value, errors = {}, isInitiallyInvalid) => {
        value = this.getDataFormattedTime(value)

        this.setState({
            value,
            errors,
            isInitiallyInvalid
        })

        const { onChange, name } = this.props
        onChange?.({ [name]: value })

        this.context.triggerChange(name)
    }

    render() {
        const {
            value = '',
            unsetAt
        } = this.state

        const {
            className,
            label,
            salt,
            name,
            mode = 'date',
            enabled = true,
            loading = false,
            field = {},
            view, // eslint-disable-line no-unused-vars
            forwardedRef, // eslint-disable-line no-unused-vars
            controlContent = null,
            error: outsideError,
            ...props
        } = this.props

        const touched = this.context.touched.includes(name)
        const error = ((name in this.context.errors) && touched) || !!outsideError

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading'
        ])

        const id = `${salt}:${name}`

        const {
            required,
            softRequired = false,
            optional,
            showDay = true,
            showYear = true,
            autoCompleteYear = false,
            unsettable = false
        } = field

        return (
            <Field
                {...(fieldClassName ? { className: fieldClassName } : null)}
                ref={this.field}>
                {!!label && (
                    <Label
                        htmlFor={id}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control {...(!!outsideError ? { className: 'error-within' } : null)}>
                    <input
                        name={name}
                        defaultValue={value}
                        type="hidden"
                        key={`${id}:input:${value}`} />
                    <Inputs
                        {...omit(props, 'field')}
                        mode={mode}
                        salt={salt}
                        enabled={enabled}
                        showDay={showDay}
                        showYear={showYear}
                        autoCompleteYear={autoCompleteYear}
                        value={value}
                        unsettable={unsettable}
                        unset={this.unset}
                        onChange={this.onChange}
                        key={`${id}:inputs:${unsetAt}`} />
                    <Error animate={outsideError ? 'reveal' : 'hide'}>
                        {outsideError}
                    </Error>
                    {controlContent}
                </Control>
            </Field>
        )
    }
}

export default EditTimeSimple
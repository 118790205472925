import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { getFullName, getJobTitle } from 'utilities/person'
import { getPeopleProfileUrl } from 'utilities/url'
import { isNil } from 'lodash'
import {
    Wrapper, Link, UserWrapper,
    AvatarWrapper,
    Name, Meta
} from './s'
import { Deactivated } from 'components/person/s'
import Avatar, { AvatarDeleted } from 'components/avatar'
import Tooltip from 'components/tooltip'
import PercentageLabel from 'components/percentage-label'
import SalaryDevelopmentTrigger from 'pages/salary/panels/development/trigger'

const Person = ({ who, showingSalaryDevelopment, setShowingSalaryDevelopment }) => {
    const { formatMessage } = useIntl()

    const {
        employmentPercentage,
        status,
        type
    } = who

    const name = getFullName(who)
    const jobTitle = getJobTitle(who)?.name

    const isDeactivated = status?.active === false
    const isDeleted = !type

    const [UserNode, userProps] = !isDeleted ?
        [Link, { to: getPeopleProfileUrl(who) }] :
        [UserWrapper, null]

    return (
        <Wrapper>
            <UserNode {...userProps}>
                <AvatarWrapper>
                    {isDeactivated && (
                        <Tooltip
                            content={formatMessage({
                                id: 'person_status_deactivated',
                                defaultMessage: 'This account has been deactivated'
                            })}>
                            <Deactivated size={40} />
                        </Tooltip>
                    )}
                    {!isDeleted && <Avatar who={who} size={40} />}
                    {isDeleted && <AvatarDeleted size={40} />}
                </AvatarWrapper>
                {!isDeleted && <Name className="compact">{name}</Name>}
                {isDeleted && (
                    <Name className="compact deleted caption">
                        <FormattedMessage
                            id="user_status_deleted"
                            defaultMessage="Deleted user" />
                    </Name>
                )}
            </UserNode>
            {!isDeleted && (
                <Meta className="caption small compact interpoint-divider">
                    {jobTitle && <span>{jobTitle}</span>}
                    {(!isNil(employmentPercentage) && employmentPercentage !== 100) && (
                        <span>
                            <Tooltip content={formatMessage({
                                id: 'person_label_employment_percentage',
                                defaultMessage: 'Employment percentage'
                            })}>
                                <PercentageLabel decimal={employmentPercentage / 100} />
                            </Tooltip>
                        </span>
                    )}
                    <SalaryDevelopmentTrigger
                        onClick={() => setShowingSalaryDevelopment(who.id)}
                        disabled={!!showingSalaryDevelopment} />
                </Meta>
            )}
        </Wrapper>
    )
}

export default Person
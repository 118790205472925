import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'

export const Columns = styled.span`
    cursor: pointer;
    display: grid;
    grid-template-columns: minmax(0, auto) 24px;
    grid-column-gap: 16px;
    align-items: center;

    border-radius: 8px;
    height: 56px;
    padding: 16px;
    box-shadow: inset 0 0 0 1px var(--huma-color-border-default);

    transition: box-shadow ease-out .3s;

    text-align: start;
    text-decoration: none;

    a > & svg {
        ${svgStroke()}
    }
`

export const DetailsColumn = styled.span``

export const LoaderColumn = styled.span`
    position: relative;
`

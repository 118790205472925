import styled from 'styled-components'
import { motion } from 'framer-motion'
import {
    svgStroke,
    belowPhone
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { Plain } from 'components/button'
import ParagraphBase from 'components/typography/paragraph'
import { captionSmallCss } from 'components/typography/caption'
import { tiptapContentCss } from 'components/tiptap/s'

export const Expandable = styled(Grid)`
    grid-template-columns: 24px 1fr auto;
    column-gap: 24px;
    align-items: center;

    padding-block: 12px;

    ${belowPhone`
        column-gap: 16px;
    `}

    &:not(:last-of-type) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    &:last-of-type {
        margin-bottom: -12px;
    }
`

export const OpenButton = styled(Plain)``

export const TopicTitle = styled.label`
    display: grid;
    grid-template-columns: 16px 1fr auto;
    column-gap: 24px;
    align-items: center;
    cursor: pointer;
    transition: opacity .1s ease-in-out;

    &:not(.disabled) {
        &:focus,
        &:hover,
        &:has(+ ${OpenButton}:focus),
        &:has(+ ${OpenButton}:hover) {
            > span:where(:not(.no-hover)) {
                text-decoration: underline;
            }
        }
    }

    &.disabled {
        opacity: .5;

        &:focus,
        &:hover,
        &:has(+ ${OpenButton}:focus),
        &:has(+ ${OpenButton}:hover) {
            opacity: .75;
        }
    }

    ${belowPhone`
        column-gap: 16px;
        line-height: 24px;
    `}

    & > span.title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

export const Drawer = styled(motion.div).attrs(() => ({
    variants: {
        contracted: {
            height: 0
        },
        expanded: {
            height: 'auto'
        }
    },
    transition: {
        duration: .15
    },
    initial: 'contracted'
}))`
    position: relative;
    overflow: clip;
    overflow-clip-margin: 4px;

    grid-column: 2 / 3;

    ${belowPhone`
        grid-column: 1 / -1;
    `}
`

export const TopicWrapper = styled.div`
    margin-top: 8px;
`

export const Paragraph = styled(ParagraphBase)`
    margin-bottom: 16px;
    max-width: 66ch;
`

export const Content = styled.div`
    ${tiptapContentCss}

    display: flow-root;
`

export const Tags = styled.div`
    ${captionSmallCss}

    margin-bottom: 16px;

    svg {
        ${svgStroke('small')}

        margin-top: -.125em;
        margin-inline-end: 16px;

        color: var(--huma-color-foreground-subtle);
    }
`

export const Tag = styled.div`
    display: inline-block;

    border-radius: 4px;
    padding: 2px 8px;
    background-color: var(--huma-color-surface-minimal);

    transition: all .1s ease-in-out;

    text-align: start;
    color: var(--huma-color-foreground-default);

    &:not(:last-of-type) {
        margin-inline-end: 4px;
    }
`
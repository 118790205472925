import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSize } from 'hooks/viewport'
import { numbers } from 'utilities/styled'
import { local } from 'utilities/storage'
import { Wrap } from './s'
import { Picker, store } from 'emoji-mart'
import {
    Clock as Recent,
    Smile as People,
    Github as Nature,
    Coffee as Foods,
    Dribbble as Activity,
    Map as Places,
    Watch as Objects,
    CheckSquare as Symbols,
    Flag as Flags
} from 'styled-icons/feather'

store.setHandlers({
    getter: local.getNamespaced('emoji-picker'),
    setter: local.setNamespaced('emoji-picker')
})

const EmojiPicker = ({ emoji, promotedEmoji, pickEmoji }) => {
    const { formatMessage } = useIntl()
    const width = useSize({ dimension: 'width' })

    const [emojiSize, setEmojiSize] = useState(28)

    useEffect(() => {
        setEmojiSize(width <= numbers.phone ? 24 : 28)
    }, [width])

    return (
        <Wrap>
            <Picker
                icons={{
                    categories: {
                        recent: () => <Recent size={20} />,
                        people: () => <People size={20} />,
                        nature: () => <Nature size={20} />,
                        foods: () => <Foods size={20} />,
                        activity: () => <Activity size={20} />,
                        places: () => <Places size={20} />,
                        objects: () => <Objects size={20} />,
                        symbols: () => <Symbols size={20} />,
                        flags: () => <Flags size={20} />
                    }
                }}
                i18n={{
                    search: formatMessage({
                        id: 'action_search',
                        defaultMessage: 'Search'
                    }),
                    clear: formatMessage({
                        id: 'action_clear',
                        defaultMessage: 'Clear'
                    }),
                    notfound: formatMessage({
                        id: 'symbol_picker_emoji_not_found',
                        defaultMessage: 'No emoji found'
                    }),
                    categories: {
                        search: formatMessage({
                            id: 'noun_search_result',
                            defaultMessage: 'Search result'
                        }),
                    }
                }}
                emoji={emoji || null}
                color={null}
                native={true}
                emojiSize={emojiSize}
                perLine={7}
                recent={promotedEmoji?.length ? promotedEmoji : null}
                autoFocus={true}
                onSelect={({ id }) => pickEmoji(id)}
                title=""
                showPreview={false}
                showSkinTones={false}
                emojisToShowFilter={({ name }) => name !== 'Black Question Mark Ornament'} />
        </Wrap>
    )
}

export default EmojiPicker
import styled from 'styled-components'
import {
    Table as TableBase,
    TableColumnHeader as TableColumnHeaderBase,
    TableRowHeader as TableRowHeaderBase,
    TableCell as TableCellBase
} from 'components/table'

export const Table = styled(TableBase)`
    border: 1px solid var(--huma-color-border-default);
`

export const CornerCell = styled.td`
    border: 1px solid var(--huma-color-border-default);
    width: 40px;
    height: 32px;
    background-color: rgb(from var(--huma-color-surface-subtle) r g b / .25);
`

export const TableColumnHeader = styled(TableColumnHeaderBase)`
    border: 1px solid var(--huma-color-border-default);
    height: 32px;
    padding: 0 8px;
    background-color: rgb(from var(--huma-color-surface-subtle) r g b / .25);

    font-weight: 400;
    text-align: center;
    color: var(--huma-color-foreground-minimal);

    &.important {
        height: 40px;

        font-size: 18px;
        font-weight: 500;
        text-align: start;
        color: var(--huma-color-foreground-default);
    }
`

export const TableRowHeader = styled(TableRowHeaderBase)`
    vertical-align: baseline;
    border: 1px solid var(--huma-color-border-default);
    width: 40px;
    height: 32px;
    padding: 4px 8px;
    background-color: rgb(from var(--huma-color-surface-subtle) r g b / .25);

    font-weight: 400;
    line-height: 1.5;
    text-align: end;
    color: var(--huma-color-foreground-minimal);
`

export const TableCell = styled(TableCellBase)`
    vertical-align: baseline;
    border: 1px solid var(--huma-color-border-default);
    padding: 4px 8px;
    background-color: var(--huma-color-surface-default);

    line-height: 1.5;
`

export const DontWrap = styled.span`
    display: inline-block;

    white-space: nowrap;
`

export const TableFooter = styled(TableCell)`
    color: var(--huma-color-foreground-minimal);
    /* font-style: italic; */
`
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { format, addDays } from 'date-fns'
import queryString from 'query-string'
import { getEmailAddress } from 'utilities/person'
import { ListItem, IconWrap } from './s'
import { Microsoft } from 'components/icon'
import { ArrowUpRight } from 'styled-icons/feather'
import Paragraph from 'components/typography/paragraph'

// https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/outlook-web.md

const Office365 = ({ start, end, title: subject, description: body, organizer, attendees = [] }) => {
    const to = [
        organizer,
        ...attendees
    ]
        .map(attendee => getEmailAddress(attendee))
        .join(',')

    const url = queryString.stringifyUrl({
        url: 'https://outlook.office.com/calendar/action/compose',
        query: {
            rru: 'addevent',
            startdt: format(start, 'yyyy-MM-dd'),
            enddt: format(addDays(end, 1), 'yyyy-MM-dd'),
            subject,
            body,
            to,
            allday: true
        }
    })

    return (
        <ListItem
            href={url}
            target="_blank">
            <Microsoft />
            <Paragraph className="compact">
                <FormattedMessage
                    id="noun_office_365"
                    defaultMessage="Office 365" />
            </Paragraph>
            <IconWrap>
                <ArrowUpRight size={24} />
            </IconWrap>
        </ListItem>
    )
}

export default Office365
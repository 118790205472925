import React from 'react'

// This file isn’t used as of today, therefore it just has some standard basic props and output.
const ViewQuantity = ({ className, label, ...props }) => {
    const {
        field = {}
    } = props

    let {
        value
    } = field

    return (
        <div>{value}</div>
    )
}

export default ViewQuantity

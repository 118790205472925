import styled, { keyframes } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { motion } from 'framer-motion'
import { FlexChild, FlexChildShrink, flexRowsCss, flexChildShrinkCss } from 'components/flex'
import { Raised } from 'components/button'
import MessageBase from 'components/message'

const shakeKeyframes = keyframes`
    10%,
    90% {
        transform: translateX(-1px);
    }

    20%,
    80% {
        transform: translateX(2px);
    }

    30%,
    50%,
    70% {
        transform: translateX(-4px);
    }

    40%,
    60% {
        transform: translateX(4px);
    }
`

export const Dropzone = styled(motion.div).attrs(() => ({
    variants: {
        empty: {
            height: '160px',
            transition: {
                duration: .1
            }
        },
        filled: {
            height: '56px',
            transition: {
                duration: .2,
                delay: .2
            }
        }
    },
    initial: 'empty'
}))`
    ${flexRowsCss}

    cursor: pointer;
    position: relative;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    border: 2px dashed var(--huma-color-border-constructive-subtle);
    height: 160px;

    transition: all .1s ease-in-out;

    color: var(--huma-color-foreground-constructive-bold);

    &.filled {
        border-color: var(--huma-color-border-default);
        background-color: var(--huma-color-surface-default);

        color: var(--huma-color-foreground-default);
    }

    &:is(.highlight, :hover) {
        border-color: var(--huma-color-border-constructive-bold);
        background-color: var(--huma-color-surface-constructive-minimal);
    }

    &.selected {
        border-style: solid;
    }

    &.nope {
        animation: ${shakeKeyframes} 0.82s cubic-bezier(.36, .07, .19, .97) both;
        transform: translateX(0);
        backface-visibility: hidden;
        perspective: 1000px;
    }

    &:focus {
        outline: none;
    }

    svg {
        ${svgStroke()}
    }
`

export const UploadIconWrap = styled(motion.div).attrs(() => ({
    variants: {
        empty: {
            height: 'auto',
            opacity: 1,
            transition: {
                duration: .1,
                delay: .1
            }
        },
        filled: {
            height: 0,
            opacity: 0,
            transition: {
                duration: .2
            }
        }
    },
    initial: 'empty'
}))`
    ${flexChildShrinkCss}

    position: relative;
    overflow: hidden;
`

export const InstructionRow = styled(FlexChildShrink)`
    line-height: 56px;
`

export const MaximumSizeRow = styled(FlexChildShrink)`
    margin-block-start: -16px;

    font-size: 14px;
    line-height: 24px;
    color: var(--huma-color-foreground-subtle);
`

export const FilledRow = styled(FlexChildShrink)`
    width: 100%;
    padding-inline: 14px 6px;
`

export const Filename = styled(FlexChild)`
    overflow: hidden;

    margin-inline-end: 24px;
    max-width: 100%;

    line-height: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--huma-color-foreground-default);
`

export const EmptyButton = styled(Raised)`
    padding: 0 8px;
`

export const Message = styled(MessageBase)`
    margin: 16px 0 0;
`
import React from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { isAfter } from 'date-fns'
import { useSalaryRevisionGroup } from 'contexts/salary-revision-group'
import { usePossum } from 'hooks/possum'
import { cls } from 'utilities/dom'
import { getShortName } from 'utilities/person'
import { safeFormat, isWithinIntervalLoose, isofy } from 'utilities/date-time'
import { Cell } from './s'
import { InlineMessage } from 'components/message'

const UserMessages = ({ user, currentSalary, newSalary, hasNote }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const { group } = useSalaryRevisionGroup()

    const { revision } = group

    const {
        currency,
        effectiveDate
    } = revision ?? {}

    const possessify = usePossum()

    const now = new Date()

    if(!user || !revision) {
        return null
    }

    const cellProps = {
        $cell: 'message',
        className: cls([
            'message',
            !hasNote && 'no-note'
        ])
    }

    const hasCurrentSalary = !!currentSalary?.amount?.yearly

    let messages = []

    // No current salary
    if(!currentSalary?.amount?.yearly) {
        messages.push({
            type: 'info',
            message: {
                id: 'salary_revision_message_user_salary_no_current',
                defaultMessage: '{name} does not have a current salary.',
                values: { name: getShortName(user) }
            }
        })
    }

    // Current salary's currency differs from the revision's currency
    if(hasCurrentSalary && currentSalary?.currency !== currency && newSalary) {
        messages.push({
            type: 'info',
            message: {
                id: 'salary_revision_message_user_salary_currency_mismatch',
                defaultMessage: '{pname} current salary is in a different currency than the revision, but will still be updated in {currency}.',
                values: {
                    pname: possessify(getShortName(user)),
                    currency: currentSalary.currency
                }
            }
        })
    }

    // Upcoming salary is before the revision's effective date
    if(!!currentSalary?.fromDate && !!effectiveDate && isAfter(isofy(effectiveDate), now) && isWithinIntervalLoose(currentSalary.fromDate, effectiveDate)) {
        messages.push({
            type: 'warning',
            message: {
                id: 'salary_revision_message_user_salary_upcoming_before_effective_date',
                defaultMessage: '{name} has an upcoming salary change (on {upcomingDate}) before the revision’s effective date ({effectiveDate}). The upcoming salary is shown as current salary above.',
                values: {
                    name: getShortName(user),
                    upcomingDate: safeFormat(currentSalary.fromDate, 'PPP', { locale }),
                    effectiveDate: safeFormat(effectiveDate, 'PPP', { locale })
                }
            }
        })
    }

    // Current salary has an end date that is after the revision's effective date
    if(!!currentSalary?.toDate && !!effectiveDate && isAfter(isofy(currentSalary.toDate), isofy(effectiveDate))) {
        messages.push({
            type: 'warning',
            message: {
                id: 'salary_revision_message_user_salary_end_date',
                defaultMessage: '{pname} current salary ends on {date}. The new salary will overwrite it and leave it without an end date when the revision is completed.',
                values: {
                    pname: possessify(getShortName(user)),
                    date: safeFormat(currentSalary.toDate, 'PPP', { locale })
                }
            }
        })
    }

    if(!messages.length) {
        return null
    }

    return (
        <Cell {...cellProps}>
            {messages.map(({ type, message }, index) => {
                const last = index === messages.length - 1

                return (
                    <InlineMessage
                        type={type}
                        message={formatMessage(message, message?.values)}
                        {...last ? { className: 'compact' } : null}
                        key={message.id} />
                )
            })}
        </Cell>
    )
}

export default UserMessages
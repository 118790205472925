import React from 'react'
import { StyledIconBase } from '@styled-icons/styled-icon'
import GoogleSvg from 'static/gfx/icon/provider/google.csvg'
import MicrosoftSvg from 'static/gfx/icon/provider/microsoft.csvg'
import ExcelSvg from 'static/gfx/icon/file/excel.csvg'
import PdfSvg from 'static/gfx/icon/file/pdf.csvg'
import PowerpointSvg from 'static/gfx/icon/file/powerpoint.csvg'
import WordSvg from 'static/gfx/icon/file/word.csvg'
import PencilSvg from 'static/gfx/icon/misc/pencil.csvg'

export const Custom = ({ svg, size = 24, className, ...props }) => {
    const { props: svgProps } = svg()
    const { children, ...attrs } = svgProps

    return (
        <StyledIconBase
            width={size}
            height={size}
            iconVerticalAlign="middle"
            {...attrs}
            {...props}
            {...(className ? { className } : null)}>
            {children}
        </StyledIconBase>
    )
}

export const Google = props => (
    <Custom
        {...props}
        svg={GoogleSvg} />
)

export const Microsoft = props => (
    <Custom
        {...props}
        svg={MicrosoftSvg} />
)

export const Excel = props => (
    <Custom
        {...props}
        svg={ExcelSvg} />
)

export const Pdf = props => (
    <Custom
        {...props}
        svg={PdfSvg} />
)

export const Powerpoint = props => (
    <Custom
        {...props}
        svg={PowerpointSvg} />
)

export const Word = props => (
    <Custom
        {...props}
        svg={WordSvg} />
)

export const Pencil = props => (
    <Custom
        {...props}
        svg={PencilSvg} />
)
import React from 'react'
import { FormattedMessage } from 'react-intl'
import queryString from 'query-string'
import { useOrganization } from 'contexts/organization'
import { Layout, HeaderRow, Heading, CloseButton, Iframe } from './s'
import Caption from 'components/typography/caption'
import { X } from 'styled-icons/feather'

const JubileeGiftModalContent = ({ who = {}, dismiss }) => {
    const { organization } = useOrganization()

    let url

    if(Array.isArray(who)) {
        url = queryString.stringifyUrl({
            url: 'https://www.send.glede.app',
            query: {
                utm_source: 'huma',
                company_name: organization.name,
                recipients: JSON.stringify(who.map(user => ({
                    email: user.email,
                    first_name: user.givenName,
                    last_name: user.familyName,
                    phone_number: user.phone,
                    ...(user.birthday && { birthday: user.birthday })
                })))
            }
        })
    } else {
        const {
            givenName,
            familyName,
            email,
            phone,
            birthday = null
        } = who ?? {}

        url = queryString.stringifyUrl({
            url: 'https://www.send.glede.app',
            query: {
                email,
                first_name: givenName,
                last_name: familyName,
                company_name: organization.name,
                phone_number: phone,
                ...(birthday && { birthday }),
                utm_source: 'huma'
            }
        })
    }

    return (
        <Layout>
            <HeaderRow>
                <Heading>
                    <FormattedMessage
                        id="jubilee_gift_heading"
                        defaultMessage="Celebrate your employees! 🎉" />
                </Heading>
                <Caption className="compact">
                    <FormattedMessage
                        id="jubilee_gift_information"
                        defaultMessage="Send a gift card to your employees to celebrate their jubilees. Straight from Huma." />
                </Caption>
                <CloseButton onClick={dismiss}>
                    <X size={24} />
                </CloseButton>
            </HeaderRow>
            <div>
                <Iframe src={url} />
            </div>
        </Layout>
    )
}

export default JubileeGiftModalContent
import styled, { css } from 'styled-components'
import { Grid } from 'components/grid'
import { belowTablet, svgStroke } from 'utilities/styled'
import { Link } from 'react-router-dom'
import { Plain, buttonStyles } from 'components/button'

export const Actions = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 32px;
    justify-content: start;
    margin-bottom: 24px;

    ${belowTablet`
        grid-auto-flow: row;
        grid-template: 1fr / auto;
        margin-bottom: 16px;
    `}
`

const actionCss = css`
    svg {
        margin-inline-end: 16px;
        ${svgStroke()}
    }
`

export const ActionButton = styled(Plain)`
    ${actionCss}
`

export const ActionLink = styled(Link)`
    ${buttonStyles.plainCss}
    ${actionCss}
    text-decoration: none;
`
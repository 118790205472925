import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { useNavigate } from 'react-router-dom'
import paths from 'app/paths'
import { cls } from 'utilities/dom'
import { get, size } from 'utilities/object'
import { compact } from 'utilities/array'
import {
    DrawablePanel, DoneCircle,
    Content, Actions,
    LocalePicker, MyProfileCompletion
} from './s'
import HumaDomain from 'components/service-onboarding/categories/category/huma-domain'
import Paragraph from 'components/typography/paragraph'
import Loom from 'components/loom'
import Veed from 'components/veed'
import Integrations from 'lists/integrations/mini'
import BirthdayJubileeVisibility from 'pages/people/pages/profile/pages/settings/panes/general/content/birthday-jubilee-visibility'
import AppLinks from 'components/app-links'
import { Button, Plain } from 'components/button'
import { Check } from 'styled-icons/feather'

const ServiceOnboardingStep = ({ mode, index, activeIndex, last, salt, ...props }) => {
    const { locale } = useI18n()
    const { updateOnboardingStatus } = useServiceOnboarding()

    const navigate = useNavigate()

    const [updating, setUpdating] = useState(null)

    const updateStatus = async status => {
        setUpdating(status)

        const payload = persistedBy ?
            { [persistedBy]: status } :
            affects.reduce((accumulator, action) => ({
                ...accumulator,
                [action]: status
            }), {})

        await updateOnboardingStatus(payload)
        setUpdating(null)
    }

    const {
        content,
        actions,
        persistedBy,
        affects,
        status,
        onBeforeAction,
        component,
        onOpen
    } = props

    const {
        title,
        body,
        loom,
        veed = []
    } = {
        ...content.en,
        ...content[locale]
    }

    const paragraphs = body?.split('\n\n')

    const skip = !['skipped', 'completed'].includes(status) ? (
        <Plain
            className={cls(['neutral', (updating === 'skipped') && 'loading'])}
            onClick={() => updateStatus('skipped')}>
            <FormattedMessage
                id="action_skip_for_now"
                defaultMessage="Skip for now" />
        </Plain>
    ) : null

    const statusBadge = (status !== 'todo') ?
        (status === 'skipped') ? (
            <FormattedMessage
                id="status_skipped"
                defaultMessage="Skipped" />
        ) : (
            <DoneCircle>
                <Check size={16} />
            </DoneCircle>
        ) : null

    const DynamicComponent = {
        integrations: props => (
            <Integrations
                {...props}
                onClickCapture={() => onBeforeAction?.()} />
        ),
        'locale-picker': LocalePicker,
        'birthday-jubilee-visibility': BirthdayJubileeVisibility,
        'huma-domain': HumaDomain,
        'profile-completion': props => (
            <div onClickCapture={() => onBeforeAction?.()}>
                <MyProfileCompletion {...props} />
            </div>
        ),
        'app-links': AppLinks
    }[component] ?? null

    const hasVideo = !!size(loom) || !!veed?.length

    return (
        <DrawablePanel
            {...(last ? { className: 'last' } : null)}
            heading={title}
            {...((mode === 'sequential') ? {
                open: index === activeIndex,
                force: true,
                onOpen
            } : {
                persistState: false
            })}
            completed={status !== 'todo'}
            status={statusBadge}
            salt={salt}
            key={`${salt}:${index}`}>
            <Content>
                {paragraphs?.map((paragraph, index) => (
                    <Paragraph
                        className="compact"
                        key={`${salt}:paragraph:${index}`}>
                        {paragraph}
                    </Paragraph>
                ))}
                {hasVideo && (
                    <>
                        {(loom && !veed?.length) && <Loom {...loom} />}
                        {!!veed?.length && (
                            <Veed
                                veed={veed}
                                key={veed[0].id} />
                        )}
                    </>
                )}
                {!!DynamicComponent && <DynamicComponent />}
                {!!actions?.length && (
                    <Actions>
                        {actions.map(({ key, path, intent, text }) => {
                            const resolvedPath = get(paths, path)
                            if(!resolvedPath) {
                                return null
                            }

                            text = text?.[locale] ?? text?.en

                            const args = compact([
                                resolvedPath,
                                !!intent && { state: { intent } }
                            ])

                            return (
                                <Button
                                    className="constructive"
                                    onClick={() => {
                                        onBeforeAction?.()
                                        navigate(...args)
                                    }}
                                    key={`${salt}:action:${key}`}>
                                    {text}
                                </Button>
                            )
                        })}
                        {skip}
                    </Actions>
                )}
                {(!actions?.length && status !== 'completed') && (
                    <Actions>
                        <Button
                            className={cls(['constructive', (updating === 'completed') && 'loading'])}
                            onClick={() => updateStatus('completed')}>
                            <Check size={20} />
                            <FormattedMessage
                                id="action_done"
                                defaultMessage="Done" />
                        </Button>
                        {skip}
                    </Actions>
                )}
            </Content>
        </DrawablePanel>
    )
}

export default ServiceOnboardingStep
import styled from 'styled-components'
import {
    interpointDivider, flashKeyframes,
    belowContainer
} from 'utilities/styled'
import { containerProps, CardList } from '../s'

export const CardListRow = styled(CardList.Row)`
    ${belowContainer(containerProps)`
        position: relative;

        display: grid;
        grid-template-columns: 1fr 40px;

        .description {
            grid-row: 2;
            grid-column: 1;
            flex-direction: row;
            align-items: center;
            column-gap: 12px;
        }

        .actions {
            grid-row: 1 / -1;
            grid-column: 2;

            button {
                position: absolute;
                inset-block-start: 8px;
            }
        }
    `}

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }
`

export const EmptyDescription = styled.div`
    display: flex;

    ${interpointDivider()}
`
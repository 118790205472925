import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useCompetenceRecords } from 'contexts/competence-records'
import { clamp } from 'utilities/math'
import { compact } from 'utilities/object'
import { useStorage } from 'hooks/storage'
import {
    Wrap,
    Header,
    GridColumnHeader, GridColumnActionsHeader,
    LoadingContainer
} from './s'
import { categoryColumns, categoryDefaultColumnsOrders } from 'pages/competence/constants/category'
import { useColumnsFormatter } from 'pages/competence/components/category'
import ColumnConfigurator from 'components/column-configurator'
import Tooltip, { useSingleton } from 'components/tooltip'
import Empty from 'components/empty'
import { SkeletonListRow, SkeletonCell, SkeletonAvatarAndFullName, SkeletonStrings } from 'components/skeleton'
import Paragraph from 'components/typography/paragraph'
import { Ghost } from 'components/button'
import Loader from 'components/loader'
import Record from './record'

const CompetenceType = ({ type, actions = {}, editingColumns, setEditingColumns, className, salt }) => {
    const {
        records,
        intersecter,
        loading,
        paging = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched,
        filter = {},
        flash,
        clearFlash
    } = useCompetenceRecords()

    const formatter = useColumnsFormatter()
    const columns = formatter(type.category)

    const [columnsOrder, setColumnsOrder] = useStorage({
        key: `${salt}:configurable`,
        defaultValue: categoryDefaultColumnsOrders[type.category],
        type: 'session'
    })

    const [source, target] = useSingleton()

    const [skeletonLength, setSkeletonLength] = useState(5)

    const { hasNextPage } = paging

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(records.length, 1, 10))
        }
    }, [records?.length])

    const filtersApplied = !!Object.values(compact(filter)).flatMap(a => a).length

    return (
        <Wrap {...(className ? { className } : null)}>
            <Tooltip
                singleton={source}
                placement="top-start"
                delay={[500, 250]} />
            {((!!records?.length && hasFetched) || fetching) && (
                <Header>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="qualifications_heading_belongs_to"
                            defaultMessage="Regarding" />
                    </GridColumnHeader>
                    {columnsOrder.map(value => {
                        const column = columns.find(({ id }) => id === value)

                        if(!column) {
                            return null
                        }

                        const { id, title } = column

                        return (
                            <GridColumnHeader key={id}>
                                {title}
                            </GridColumnHeader>
                        )
                    })}
                    <GridColumnActionsHeader>
                        {categoryColumns[type.category]?.length > 2 && (
                            <ColumnConfigurator
                                columns={columns}
                                defaultColumnsOrder={categoryDefaultColumnsOrders[type.category]}
                                columnsOrder={columnsOrder}
                                configureColumns={order => setColumnsOrder([...order])}
                                editingColumns={editingColumns}
                                setEditingColumns={setEditingColumns}
                                salt={salt} />
                        )}
                    </GridColumnActionsHeader>
                </Header>
            )}
            {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                <SkeletonListRow key={`list:competence-types:skeleton:${index}`} columns={3} hasActions={!!actions}>
                    <SkeletonCell>
                        <SkeletonAvatarAndFullName
                            size={40}
                            showSecondLine={true} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings size={20} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings size={20} />
                    </SkeletonCell>
                </SkeletonListRow>
            ))}
            {(!records?.length && hasFetched && !filtersApplied) && (
                <Empty which="competence">
                    <Paragraph className="compact">
                        <FormattedMessage
                            id="competence_empty"
                            defaultMessage="No competences have been added yet." />
                    </Paragraph>
                </Empty>
            )}
            {(!records?.length && hasFetched && !!filtersApplied) && (
                <Empty which="competence">
                    <FormattedMessage
                        id="competence_empty_filter_message"
                        defaultMessage="🤷 Sorry, no competences found." />
                </Empty>
            )}
            {records.map((record, index) => {
                const last = index + 1 === records.length
                const includedColumns = columnsOrder.map(value => ({
                    ...columns.find(({ id }) => id === value)
                }))

                return (
                    <Record
                        record={record}
                        actions={actions}
                        columns={includedColumns}
                        {...((!!intersecter && last) ? { ref: intersecter } : null)}
                        singleton={target}
                        flash={flash?.current === record ? clearFlash : null}
                        salt={`${salt}:${record.id}`}
                        key={record.id} />
                )
            })}
            {!!hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost className="constructive" onClick={fetch}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
        </Wrap>
    )
}

export default CompetenceType

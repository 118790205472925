import React from 'react'
import { useAbsenceTypes } from 'contexts/absence-types'
import { Wrap, LoadingContainer } from './s'
import Empty from 'components/empty'
import Loader from 'components/loader'
import Type from './type'

const AbsenceTypes = props => {
    const {
        types,
        hasFetched,
    } = useAbsenceTypes()

    if(!hasFetched && !types.length) {
        return (
            <LoadingContainer>
                <Loader />
            </LoadingContainer>
        )
    }

    if(hasFetched && !types.length) {
        return (
            Empty
        )
    }

    return (
        <Wrap {...props}>
            {types.map(type => (
                <Type
                    type={type}
                    salt={`absence-types:type:${type.id}`}
                    key={`absence-types:type:${type.id}`} />
            ))}
        </Wrap>
    )
}

export default AbsenceTypes

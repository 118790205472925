import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useTheme, ThemeProvider } from 'styled-components'
import { useNotificationSettings } from 'contexts/notification-settings'
import { useAccess } from 'contexts/access'
import { useFeatureFilter } from 'hooks/feature-filter'
import { SectionHeading, Notifications } from './s'
import Notification from './notification'

const NotificationSettings = ({ mode = 'default', className }) => {
    let theme = useTheme()

    const { notifications } = useNotificationSettings()

    const { check } = useAccess()
    const featureFilter = useFeatureFilter()

    const allNotifications = notifications.filter(featureFilter)

    const adminNotifications = allNotifications.filter(({ permissions }) =>
        permissions?.some(permission => check(permission)) ?? false
    )

    const userNotifications = allNotifications.filter(({ permissions }) => !permissions?.length)

    const notificationMapper = ({ event, category }) => (
        <Notification
            which={event}
            category={category}
            key={`notifications:${event}`} />
    )

    theme = {
        ...theme,
        notifications: { mode }
    }

    return (
        <ThemeProvider theme={theme}>
            {!!adminNotifications.length && (
                <>
                    <SectionHeading>
                        <FormattedMessage
                            id="notification_settings_heading_admin"
                            defaultMessage="Admin notifications" />
                    </SectionHeading>
                    <Notifications {...(className ? { className } : null)}>
                        {adminNotifications.map(notificationMapper)}
                    </Notifications>
                    <SectionHeading>
                        <FormattedMessage
                            id="notification_settings_heading_user"
                            defaultMessage="User notifications" />
                    </SectionHeading>
                    <Notifications {...(className ? { className } : null)}>
                        {userNotifications.map(notificationMapper)}
                    </Notifications>
                </>
            )}
            {!adminNotifications.length && (
                <Notifications {...(className ? { className } : null)}>
                    {userNotifications.map(notificationMapper)}
                </Notifications>
            )}
        </ThemeProvider>
    )
}

export default NotificationSettings
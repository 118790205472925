import styled from 'styled-components'
import { motion } from 'framer-motion'
import {
    svgStroke,
    belowPhone, belowDesktop, fromDesktop, fromPhone
} from 'utilities/styled'
import { CloseButton as CloseButtonBase } from 'components/button'
import { H4 } from 'components/typography/heading'
import ActionsBase from 'components/form/actions'
import Link from 'components/link'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'

export const Wrapper = styled(motion.div).attrs(() => ({
    variants: {
        out: {
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        in: {
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .3
            }
        }
    },
    initial: 'out'
}))`
    display: flex;
    flex-direction: column;
    row-gap: 40px;
`

export const TutorialWrapper = styled.div`
    position: relative;
    isolation: isolate;

    display: flex;
    align-items: center;
    gap: 24px 48px;

    padding: 0 48px;

    transition: opacity .3s ease-in;

    > * {
        flex: 1;
    }

    ${belowPhone`
        flex-direction: column;

        padding: 0;
    `}

    &.dashboard {
        gap: 0 72px;

        border-radius: 8px;
        padding: 0 72px;
        box-shadow: 0 0 0 1px var(--huma-color-border-default);

        ${fromDesktop`
            flex-direction: row-reverse;
        `}

        ${belowDesktop`
            gap: 16px;

            padding: 16px;
        `}
    }
`

export const CloseButton = styled(CloseButtonBase)`
    z-index: 1;

    top: 16px;
    right: 16px;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 48px;
`

export const Words = styled.div`
    & :last-child {
        margin-bottom: 0;
    }

    p {
        white-space: pre-wrap;
    }

    ${TutorialWrapper}.dashboard & {
        align-self: center;

        ${fromDesktop`
            margin: 16px 0;
        `}

        ${belowDesktop`
            order: 2;
        `}
    }
`

export const Title = styled(H4)`
    margin-bottom: 24px;
`

export const Actions = styled(ActionsBase)`
    justify-content: flex-start;

    svg {
        ${svgStroke('small')}
        margin-inline-end: 8px;
    }
`

export const Video = styled.div`
    max-width: 400px;
`

export const Screenshot = styled.div`
    position: relative;
    overflow: hidden;

    justify-content: center;
    align-self: start;

    &.empty {
        display: none;
    }

    ${TutorialWrapper}.dashboard & {
        align-self: end;

        ${belowDesktop`
            order: 1;

            max-width: 400px;
            margin: 0 auto;
        `}
    }
`

export const Image = styled.img`
    max-width: min(450px, 100%);

    ${TutorialWrapper}.dashboard & {
        ${fromPhone`
            margin-top: 12px;
            margin-bottom: -20%;
        `}
    }
`

export const KnowledgeBase = styled(Link)`
    display: grid;
    grid-template-columns: 24px minmax(0, 1fr) 16px;
    align-items: center;
    align-self: center;
    column-gap: 24px;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    max-width: var(--huma-global-content-tight-width);
    width: 100%;
    padding: 16px 24px;

    svg {
        ${svgStroke()}

        &:last-child {
            ${svgStroke('small')}
        }
    }

    &:hover,
    &:focus {
        text-decoration: none;
    }
`

export const KnowledgeBaseText = styled.div`
    display: flex;
    flex-direction: column;
`

export const KnowledgeBaseTitle = Paragraph

export const Url = styled(CaptionSmall)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${KnowledgeBase}:where(:hover, :focus) & {
        text-decoration: underline;
    }
`
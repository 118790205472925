import React from 'react'
import { Tags as STags, IconWrap, Tag as STag, TagRemoveButton, TagLink, TagRemoveButtonXCircle } from './s'
import { X } from 'styled-icons/feather'
import { cls } from 'utilities/dom'

export const Tags = ({
    tags, icon: Icon = null,
    onClick, onClickIsDelete = true, link = null,
    salt
}) => {
    if(!tags.length) {
        return null
    }

    return (
        <STags>
            {!!Icon && (
                <IconWrap>
                    <Icon size={16} />
                </IconWrap>
            )}
            {tags.map(({ id, name }, index) => (
                <Tag
                    text={name}
                    value={id}
                    onClick={onClick}
                    onClickIsDelete={onClickIsDelete}
                    link={link}
                    key={`${salt}:value:${index}`} />
            ))}
        </STags>
    )
}

export const Tag = ({
    text, value,
    onClick, onClickIsDelete = true, link = null,
    deleteHighlight = false, className,
    ...props
}) => {
    className = cls([
        'edit',
        className,
        (onClick || link) && 'clickable',
        deleteHighlight && 'death-mark',
        (!!onClick && onClickIsDelete) && 'x'
    ])

    const TagElement = link ?
        TagLink :
        TagRemoveButton

    const tagProps = link ?
        { to: link(value) } :
        { onClick: () => onClick(value) }

    return (
        <STag className={className}>
            <TagElement
                {...props}
                {...tagProps}>
                {text}
                {(!!onClick && onClickIsDelete) && (
                    <TagRemoveButtonXCircle>
                        <X size={12} />
                    </TagRemoveButtonXCircle>
                )}
            </TagElement>
        </STag>
    )
}

import React, { Component } from 'react'
import { FormContext } from 'components/form/controller'
import { cls } from 'utilities/dom'
import { Field, Label, Control } from 'components/form/field/s'
import { Flag } from './s'
import CountrySelect from './select'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { empty } from '../'

export default class EditCountry extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const {
            name,
            field = {}
        } = props

        let {
            value,
            required = false,
            include = 'touched'
        } = field

        if(!value && value !== 0) {
            value = empty.value
        }

        this.state = { value }

        context.registerField(name, {
            empty: empty.value,
            required,
            include,
            unset: this.unset,

            validator: value => required ?
                !!value :
                true
        })
    }

    componentDidMount() {
        this.context.triggerChange(this.props.name, { touched: false })
    }

    componentDidUpdate = ({ name, field }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.context.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.context.triggerChange(this.props.name)
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name)
    }

    unset = () => this.setState({ value: empty })

    onChange = ({ target: { value } }) => {
        this.setState({ value })

        const { onChange, name } = this.props
        onChange?.({ [name]: value })
    }

    render() {
        let { value } = this.state

        if(value) {
            value = value.toUpperCase()
        }

        const {
            className,
            controlProps = {},
            salt,
            label,
            name,
            enabled = true,
            loading = false,
            field = {},
            traditional = false,
        } = this.props

        const {
            required,
            softRequired,
            optional
        } = field

        const touched = this.context.touched.includes(name)
        const error = (name in this.context.errors) && touched

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const controlClassName = cls([
            controlProps.className,
            traditional && 'traditional'
        ])

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <Flag>
                        {!!value && getUnicodeFlagIcon(value)}
                        {!value && '🌐'}
                    </Flag>
                    <CountrySelect
                        {...controlProps}
                        {...(controlClassName ? { className: controlClassName } : null)}
                        id={salt}
                        name={name}
                        value={value}
                        onChange={this.onChange}
                        disabled={!enabled} />
                </Control>
            </Field>
        )
    }
}
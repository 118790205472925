import styled, { css } from 'styled-components'
import {
    svgStroke,
    belowTablet, fromPhone
} from 'utilities/styled'
import { Grid, GridColumnHeader as GridColumnHeaderBase } from 'components/grid'
import TutorialBase from 'components/tutorial'
import Paragraph from 'components/typography/paragraph'
import { compact } from 'utilities/array'
import {
    processModes,
    processTemplateModes,
    processDraftModes
} from './'

export const Wrap = styled.div``

export const Header = styled(Grid)`
    width: 100%;
    column-gap: 24px;
    box-shadow: 0 1px var(--huma-color-border-default);

    ${({ theme }) => {
        const columns = compact([
            // Toggle completed or avatar
            [
                ...processTemplateModes,
                ...processDraftModes
            ].includes(theme.mode) ?
                '40px' :
                '20px',

            // Name + description + assignment info in onboarding contexts
            'minmax(auto, 2fr)',

            // Date / expiry status
            processTemplateModes.includes(theme.mode) ?
                '' :
                '1fr',

            // Assigned to
            processModes.includes(theme.mode) ?
                '' :
                'minmax(0, 1fr)',

            // Actions
            !!theme?.tasks?.actions && '40px'
        ])

        return css`
            grid-template-columns: ${columns.join(' ')};
        `
    }}

    ${belowTablet`
        display: none;
    `}
`

const gridColumnHeaderCss = css`
    display: flex;
    align-items: center;
    height: 72px;
    padding: 24px 0;

    ${belowTablet`
        padding: 16px 0;
    `}
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}
`

export const GridColumnActionsHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}
    justify-content: center;

    ${belowTablet`
        display: none;
    `}

    > svg {
        ${svgStroke()}
    }
`

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    position: relative;
`

export const Tutorial = styled(TutorialBase)`
    ${fromPhone`
        margin-top: 64px;
    `}
`

export const EmptyText = styled(Paragraph)`
    margin: 0 0 32px;
`
import React, { forwardRef } from 'react'
import {
    PersonItem,
    PersonColumnsLink, PersonColumnsLabel,
    Avatar, Name, JobTitle,
    ActionButton, ActionFragment
} from './s'
import { FlexChild, FlexChildShrink } from 'components/flex'
import { getFullName, getJobTitle } from 'utilities/person'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'

const Person = forwardRef(({ person, actions, salt }, ref) => {
    const name = getFullName(person)
    const position = getJobTitle(person)?.name

    const boundActions = getBoundActions(actions, person)
    const singleAction = (boundActions?.length === 1) ?
        boundActions[0] :
        null

    const {
        element = 'button',
        onClick,
        link = {},
        icon,
        label,
        effect = 'neutral',
        disabled = false
    } = singleAction ?? {}

    const ActionElement = (element === 'button') ?
        ActionButton :
        ActionFragment

    const PersonColumns = (!!singleAction && (element ==='a' || element === 'link')) ?
        PersonColumnsLink :
        PersonColumnsLabel

    const personProps = {
        ...((!!singleAction && ['a', 'link'].includes(element)) ? link : null),
        ...((!!singleAction && !['a', 'link'].includes(element)) ? {
            onFocus: ({ target }) => manipulateButton(target, 'add', 'focus'),
            onMouseEnter: ({ target }) => manipulateButton(target, 'add', 'hover'),
            onMouseLeave: ({ target }) => manipulateButton(target, 'remove', 'hover'),
            onBlur: ({ target }) => manipulateButton(target, 'remove', 'focus')
        } : null),
        ...(!boundActions?.length ? { className: 'disabled' } : null)
    }

    return (
        <PersonItem ref={ref}>
            <PersonColumns {...personProps}>
                <FlexChildShrink>
                    <Avatar
                        who={person}
                        size={40} />
                </FlexChildShrink>
                <FlexChild>
                    <Name className="compact">{name}</Name>
                    <JobTitle className="caption compact small">{position}</JobTitle>
                </FlexChild>
                {!!boundActions?.length && (
                    <FlexChildShrink>
                        {(boundActions?.length > 1) && (
                            <ContextMenu
                                prebound actions={boundActions}
                                salt={salt} />
                        )}
                        {!!singleAction && (
                            <ActionElement
                                className={effect}
                                {...(onClick ? { onClick } : null)}
                                {...disabled}>
                                {!!icon && icon}
                                {label}
                            </ActionElement>
                        )}
                    </FlexChildShrink>
                )}
            </PersonColumns>
        </PersonItem>
    )
})

const manipulateButton = ($el, method, className) => $el
    ?.querySelector('button')
    ?.classList
    ?.[method](className)

export default Person

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { Expandable, TopicTitle, OpenButton, Drawer } from './s'
import { CheckboxField } from '../s'
import { DisplayEmoji as Emoji } from 'components/symbol'
import TopicContent from './content'
import Status from 'pages/handbook/components/status'

const Topic = ({ category, topic, setTemplate, salt }) => {
    const { handbook } = useHandbook()

    const showStatus = handbook?.status === 'published'

    const { expanded: categoryExpanded } = category

    const {
        id,
        title,
        symbol,
        enabled,
        expanded,
        status = 'draft'
    } = topic

    const animate = expanded ? 'expanded' : 'contracted'

    return (
        <Expandable key={`${salt}:category:topics:${id}`}>
            <CheckboxField
                salt={salt}
                className="compact"
                name={id}
                field={{
                    value: enabled,
                    include: 'always'
                }}
                enabled={category.enabled}
                label={false}
                onChange={state => {
                    const target = state[id]

                    setTemplate(previousTemplate => {
                        const index = previousTemplate.categories.findIndex(({ id: categoryId }) => category.id === categoryId)

                        return {
                            ...previousTemplate,
                            categories: [
                                ...previousTemplate.categories.slice(0, index),
                                {
                                    ...previousTemplate?.categories[index],
                                    topics: previousTemplate?.categories[index]?.topics.map(topic => {
                                        if(topic.id === id) {
                                            return {
                                                ...topic,
                                                enabled: target
                                            }
                                        }

                                        return topic
                                    })
                                },
                                ...previousTemplate.categories.slice(index + 1)
                            ]
                        }
                    })
                }}
                {...(!categoryExpanded ? { tabIndex: -1 } : null)}
                key={`${salt}:checkbox:${id}:${enabled}`} />
            <TopicTitle
                htmlFor={`${salt}:button:expand:${id}`}
                {...((!enabled || !category.enabled) ? { className: 'disabled' } : null)}>
                <Emoji emoji={symbol.emoji} />
                <span className="title">{title}</span>
                {showStatus && (
                    <Status
                        status={status}
                        className="no-hover" />
                )}
            </TopicTitle>
            <OpenButton
                className="constructive"
                id={`${salt}:button:expand:${id}`}
                onClick={() => {
                    setTemplate(previousTemplate => {
                        const index = previousTemplate.categories.findIndex(({ id: categoryId }) => category.id === categoryId)

                        return {
                            ...previousTemplate,
                            categories: [
                                ...previousTemplate.categories.slice(0, index),
                                {
                                    ...previousTemplate?.categories[index],
                                    topics: previousTemplate?.categories[index]?.topics.map(topic => {
                                        if(topic.id === id) {
                                            return {
                                                ...topic,
                                                expanded: !expanded
                                            }
                                        }

                                        return topic
                                    })
                                },
                                ...previousTemplate.categories.slice(index + 1)
                            ]
                        }
                    })
                }}
                {...(!categoryExpanded ? { tabIndex: -1 } : null)}>
                <FormattedMessage
                    id={expanded ? 'action_close' : 'action_open'}
                    defaultMessage={expanded ? 'Close' : 'Open'} />
            </OpenButton>
            <Drawer animate={animate}>
                <TopicContent topic={topic} />
            </Drawer>
        </Expandable>
    )
}

export default Topic

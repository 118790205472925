import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useCompetenceByType } from 'contexts/competence-by-type'
import { useTheme, ThemeProvider } from 'styled-components'
import { clamp } from 'utilities/math'
import { compact, size } from 'utilities/object'
import {
    Wrap, Header,
    GridColumnHeader, GridColumnHeaderSortable,
    LoadingContainer, Tutorial, EmptyText
} from './s'
import Tooltip, { useSingleton } from 'components/tooltip'
import Empty from 'components/empty'
import Link from 'components/link'
import Caption from 'components/typography/caption'
import { SkeletonListRow, SkeletonCell, SkeletonAvatarAndFullName, SkeletonStrings } from 'components/skeleton'
import Loader from 'components/loader'
import { Ghost } from 'components/button'
import Type from './type'
import { getActionsArray } from 'widgets/context-menu'

const CompetenceByType = ({ actions = {}, header = false, fromGroup = null, group = null, className }) => {
    const { formatMessage } = useIntl()

    let theme = useTheme()

    const [source, target] = useSingleton()

    const [skeletonLength, setSkeletonLength] = useState(5)

    const {
        types,
        intersecter,
        loading,
        sorting = {},
        toggleSorting,
        paging = {},
        filter = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched,
        flash,
        clearFlash
    } = useCompetenceByType()

    const hasActions = !!getActionsArray(actions).length
    const { hasNextPage } = paging

    theme = {
        ...theme,
        actions: hasActions
    }

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(types.length, 1, 10))
        }
    }, [hasFetched, types?.length])

    const filtersApplied = !!Object.values(compact(filter)).flatMap(a => a).length

    return (
        <ThemeProvider theme={theme}>
            <Tooltip
                singleton={source}
                placement="top-start"
                delay={[500, 250]} />
            <Wrap {...(className ? { className } : null)}>
                {(header || !!fetching) && (
                    <Header>
                        <GridColumnHeaderSortable
                            field="name"
                            sort={sorting}
                            toggle={toggleSorting}
                            text={formatMessage({
                                id: 'noun_name',
                                defaultMessage: 'Name'
                            })} />
                        <GridColumnHeader>
                            <FormattedMessage
                                id="label_belongs_to"
                                defaultMessage="Belongs to"
                            />
                        </GridColumnHeader>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_status"
                                defaultMessage="Type"
                            />
                        </GridColumnHeader>
                    </Header>
                )}
                {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow
                        columns={3}
                        hasActions={!!size(actions)}
                        key={`list:competence-types:skeleton:${index}`}>
                        <SkeletonCell>
                            <SkeletonAvatarAndFullName
                                size={40}
                                showSecondLine={index % 2 === 0} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings size={20} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings size={20} />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {(!types.length && hasFetched && !fetching) && (
                    <>
                        {!!group && (
                            <Empty which="competence">
                                <EmptyText>
                                    <FormattedMessage
                                        id="competence_group_empty"
                                        defaultMessage="Competencies for the members of {group} will be displayed here"
                                        values={{ group: group.name }} />
                                </EmptyText>
                                <Link
                                    to="path:competence.base"
                                    className="constructive">
                                    <FormattedMessage
                                        id="competence_action_navigate"
                                        defaultMessage="Go to Competence" />
                                </Link>
                            </Empty>
                        )}
                        {(!group && filtersApplied) && (
                            <Empty which="competence">
                                <Caption className="compact">
                                    <FormattedMessage
                                        id="competence_empty_filter_message"
                                        defaultMessage="🤷 Sorry, no competences found." />
                                </Caption>
                            </Empty>
                        )}
                        {(!group && !filtersApplied) && (
                            <Tutorial which="competence" />
                        )}
                    </>
                )}
                {types.map((type, index) => {
                    const last = index + 1 === types.length

                    return (
                        <Type
                            {...{
                                type,
                                actions,
                                fromGroup,
                                ...(last ? { ref: intersecter } : null)
                            }}
                            singleton={target}
                            flash={flash?.current === type ? clearFlash : null}
                            key={type.id} />
                    )
                })}
                {!!hasNextPage && (
                    <LoadingContainer>
                        {(!loading && !autoFetch) && (
                            <Ghost className="constructive" onClick={fetch}>
                                <FormattedMessage
                                    id="action_load_more"
                                    defaultMessage="Load more…"
                                />
                            </Ghost>
                        )}
                        {!!loading && <Loader />}
                    </LoadingContainer>
                )}
            </Wrap>
        </ThemeProvider>
    )
}

export default CompetenceByType

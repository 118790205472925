import styled, { css } from 'styled-components'
import { fromContainer, belowContainer } from 'utilities/styled'
import { containerProps } from '../s'
import Paragraph from 'components/typography/paragraph'
import { Grid } from 'components/grid'
import TextBase from 'components/form/input/text'
import SelectBase from 'components/form/input/select'

export const Row = styled(Grid)`
    min-height: 76px;
    padding-block: 16px;

    &.hidden {
        display: none;
    }

    ${fromContainer(containerProps)`
        grid-template-columns: subgrid;
        grid-column: 1 / -1;
        row-gap: 8px;

        &:not(:last-of-type) {
            box-shadow: 0 1px var(--huma-color-border-default);
        }
    `}

    ${belowContainer(containerProps)`
        position: relative;

        grid-template-columns: minmax(0, 1fr) auto auto;
        row-gap: 16px;
        column-gap: 8px;

        &:first-of-type {
            padding-block-start: 0;
            box-shadow: none;
        }
    `}

    &:is(:hover, :focus-visible) {
        text-decoration: none;
    }
`

export const ChangedIndicator = styled.div`
    position: absolute;
    inset-block-start: 50%;

    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: var(--huma-color-surface-constructive-bold);

    transform: translateY(-50%);

    ${fromContainer(containerProps)`
        inset-inline-start: -24px;
    `}

    ${belowContainer(containerProps)`
        inset-inline-start: -16px;
    `}
`

export const Cell = styled.div`
    position: relative;

    display: flex;

    ${fromContainer(containerProps)`
        flex-direction: column;
        align-items: start;
        justify-content: center;
        grid-column: ${p => p.$cell};

        ${p => p.$cell === 'message' && css`
            grid-row: 2;

            padding-inline-start: 56px; /* Avatar + gap */

            &.no-note {
                grid-column: 1 / -1;
            }
        `}

        ${p => p.$cell === 'note' && css`
            align-items: end;
            grid-row: 2;
        `}
    `}

    ${belowContainer(containerProps)`
        align-items: center;
        justify-content: space-between;
        grid-column: 1 / -1;

        &:nth-of-type(odd):not(:first-child, .actions, .message, .note, .seniority) {
            position: relative;
            isolation: isolate;
            z-index: 1;

            &::before {
                content: "";
                position: absolute;
                z-index: -1;
                inset: -8px -24px;

                background-color: var(--huma-color-surface-minimal);
            }
        }

        ${p => p.$cell === 'name' && css`
            grid-row: 1;
            grid-column: 1;
        `}

        ${p => p.$cell === 'seniority' && css`
            grid-row: 1;
            grid-column: 2;
        `}

        ${p => p.$cell === 'actions' && css`
            grid-row: 1;
            grid-column: 3;
        `}

        &.message {
            flex-direction: column;
            align-items: start;

            &:not(.no-note) {
                grid-row: 7;
            }
        }
    `}
`

export const PriceTruncated = styled.span`
    cursor: help;

    ${fromContainer(containerProps)`
        display: flow;

        max-width: var(--clamp-at);
        overflow: hidden;
        text-overflow: ellipsis;
    `}
`

export const Label = styled(Paragraph).attrs({ className: 'caption small compact' })`
    ${fromContainer(containerProps)`
        display: none;
    `}
`

export const Value = styled.span`
    ${belowContainer(containerProps)`
        display: none;
    `}
`

export const Fields = styled(Grid)`
    grid-template-columns: 70px 100px auto;
    align-items: center;
    column-gap: 8px;

    &.adding {
        grid-template-columns: max-content auto auto;
    }
`

export const Field = styled.div`
    &.has-adornment {
        display: grid;
        grid-template-columns:
            [text] 1fr
            [adornment] min-content;
        column-gap: 8px;
    }

    & > * {
        grid-row: 1;
    }

    & > .adornment {
        position: relative;

        grid-column: adornment;
        align-self: center;

        padding-inline: 8px;
    }
`

export const Text = styled(TextBase)`
    background-color: var(--huma-color-surface-default);

    ${Field}.has-adornment & {
        grid-column: 1 / -1;

        padding-inline-end: 24px;
    }
`

export const Select = styled(SelectBase)`
    background-color: var(--huma-color-surface-default);
`
import React, { useState, useLayoutEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import ContentAssistantProvider, { useContentAssistant } from 'contexts/content-assistant'
import { useLocationStateIntent } from 'hooks/location-intent'
import { sendAppSignal } from 'hooks/app-signal'
import { useUpgradable } from 'hooks/upgradable'
import { Wrapper, Chip } from './s'
import Tooltip, { Trigger } from 'components/tooltip'
import { AI } from 'components/feather'
import { ArrowUpCircle } from 'styled-icons/feather'
import SidePanel from 'panels/side'
import AssistantPanel from './panel'

const ContentAssistant = ({ callback, field, criterion, value, salt, values = {}, upgradable }) => {
    const { formatMessage } = useIntl()

    const {
        settings,

        loading
    } = useContentAssistant()

    const [showing, setShowing] = useState(false)

    useLocationStateIntent({
        intent: 'show-content-assistant',
        action: () => toggleSidePanel(true),
        requirements: [Object.values(values).every(Boolean)]
    })

    const toggleSidePanel = show => {
        setShowing(show)
        sendAppSignal('side-panel.toggle', show)
    }

    useLayoutEffect(() => {
        document.body.classList.add('side-panel-overlay')

        return () => {
            toggleSidePanel(false)
            document.body.classList.remove('side-panel-overlay')
        }
    }, [])

    if((!settings && !upgradable) || !field) {
        return null
    }

    const {
        disabled = false,
        unavailable = false,
        unavailableTooltip = null
    } = criterion ?? {}

    const Icon = upgradable ?
        ArrowUpCircle :
        AI

    return (
        <Wrapper>
            <Chip
                className="ai"
                disabled={!!disabled || !!unavailable || loading || showing}
                onClick={() => toggleSidePanel(true)}>
                <Icon size={16} />
                <FormattedMessage
                    id={value ?
                        'assistant_action_edit' :
                        'assistant_heading_draft'
                    }
                    defaultMessage={value ?
                        'Edit with AI' :
                        'Generate a suggestion'
                    } />
            </Chip>
            {!!unavailable && (
                <Tooltip content={formatMessage(unavailableTooltip)}>
                    <Trigger />
                </Tooltip>
            )}
            <SidePanel
                show={!!showing}
                dismiss={() => toggleSidePanel(false)}>
                <AssistantPanel
                    dismiss={() => toggleSidePanel(false)}
                    autoGenerate={{
                        field,
                        value,
                        values
                    }}
                    callback={(...args) => {
                        toggleSidePanel(false)
                        callback(...args)
                    }}
                    upgradable={upgradable}
                    salt={salt} />
            </SidePanel>
        </Wrapper>
    )
}

export default props => {
    const { checkFeature } = useAccess()
    const checkUpgradable = useUpgradable()

    const access = !!checkFeature('content-assistant')
    const upgradable = checkUpgradable({ feature: 'content-assistant' })

    const contentAssistantAvailable = access && !upgradable

    return (
        <ContentAssistantProvider fetchOnMount={contentAssistantAvailable}>
            <ContentAssistant
                {...props}
                upgradable={upgradable} />
        </ContentAssistantProvider>
    )
}
import React from 'react'
import { EmbedWrapper, Iframe } from './s'
import { CloseButton } from 'components/button'

const Embed = ({ id, aspectRatio, onDone }) => {
    return (
        <EmbedWrapper style={{ '--aspect-ratio': aspectRatio }}>
            <Iframe
                src={`https://veed.io/embed/${id}?watermark=0color=purple&sharing=0&title=1`}
                allowFullScreen={true} />
            <CloseButton onClick={onDone} />
        </EmbedWrapper>
    )
}

export default Embed

import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useEditorMeta } from '../extensions/utilities'
import { map } from 'utilities/object'
import { capitalize } from 'utilities/string'
import { Table, TableColumnHeader } from './s'
import { CloseButton, Plain } from 'components/button'
import { ModalHeading as Heading } from 'components/typography/heading'
import Paragraph from 'components/typography/paragraph'
import { TableColumnGroup, TableHead, TableBody, TableRow } from 'components/table'
import { Markdown } from 'styled-icons/boxicons-logos'
import Item from './item'
import Message from 'components/message'
import Actions from 'components/form/actions'

const salt = 'editor:help'

const EditorHelp = ({ editor, dismiss }) => {
    const { formatMessage } = useIntl()
    const meta = useEditorMeta()

    const anyDisabled = map(meta, (_, name) => getDisabled(editor, name)).some(Boolean)

    return (
        <>
            <CloseButton onClick={dismiss} />
            <Heading>
                <FormattedMessage
                    id="editor_help_heading"
                    defaultMessage="Editor productivity" />
            </Heading>
            <Paragraph className="preserve-line-breaks">
                <FormattedMessage
                    id="editor_help_caption"
                    defaultMessage="Learning which content types are available, a few keyboard shortcuts and maybe some Markdown syntax can go a long way in boosting your productivity and making your content production experience more enjoyable. \n\nLet’s jump right into it!" />
            </Paragraph>
            {!!anyDisabled && (
                <Message
                    type="info"
                    message={formatMessage({
                        id: 'editor_help_disabled_information',
                        defaultMessage: 'The grayed out content types are not available in this context, but may be available in other.'
                    })} />
            )}
            <Table>
                <TableColumnGroup widths={['40px', 'auto', 'auto', 'auto']} />
                <TableHead>
                    <TableRow>
                        <TableColumnHeader />
                        <TableColumnHeader />
                        <TableColumnHeader>
                            <Markdown size={24} />
                            <FormattedMessage
                                id="noun_markdown_syntax"
                                defaultMessage="Markdown syntax" />
                        </TableColumnHeader>
                        <TableColumnHeader>
                            <FormattedMessage
                                id="noun_keyboard_shortcut"
                                defaultMessage="Keyboard shortcut" />
                        </TableColumnHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {map(meta, (getter, name) => {
                        let key = `${salt}:${name.toLowerCase()}`

                        if(name.endsWith('Convert')) {
                            return null
                        }

                        const disabled = getDisabled(editor, name)

                        if(name === 'Heading') {
                            return [2, 3, 4].map(level => {
                                key = `${key}:${level}`

                                return (
                                    <Item
                                        meta={getter({ level })}
                                        name={name}
                                        disabled={disabled}
                                        salt={salt}
                                        key={key} />
                                )
                            })
                        }

                        return (
                            <Item
                                meta={getter()}
                                name={name}
                                disabled={disabled}
                                salt={key}
                                key={key} />
                        )
                    })}
                </TableBody>
            </Table>
            <Actions className="compact centered">
                <Plain onClick={dismiss}>
                    <FormattedMessage
                        id="action_done"
                        defaultMessage="Done" />
                </Plain>
            </Actions>
        </>
    )
}

const getDisabled = (editor, name) => {
    const extension = editor.options.extensions.find(extension => capitalize(extension.name) === name)

    let disabled = !extension

    if('disabled' in (extension?.parent?.config ?? {})) {
        disabled = extension.parent.config.disabled
    }

    if('disabled' in (extension?.config ?? {})) {
        disabled = extension.config.disabled
    }

    return disabled
}

export default EditorHelp
import React  from 'react'
import { createPortal } from 'react-dom'
import {
    SortableItem,
    DragColumn,
    ItemColumn, FavIcon, Name, Url,
    ActionsColumn
} from './s'
import Ellipsify from 'components/ellipsify'
import ContextMenu from 'widgets/context-menu'
import { DragIndicator } from 'styled-icons/material'

const SortableLink = ({ link, actions, count, provided, snapshot, salt }) => {
    const portal = document.getElementById('sortable')
    const shouldUsePortal = snapshot.isDragging

    salt = `${salt}:sortable:${link.name}`

    const child = (
        <SortableItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            $isDragging={snapshot.isDragging}>
            <DragColumn {...provided.dragHandleProps}>
                {(count > 1) && (
                    <DragIndicator size={24} />
                )}
            </DragColumn>
            <ItemColumn>
                <FavIcon
                    size={24}
                    src={link.url} />
                <Name className="compact">
                    <Ellipsify
                        text={link.name}
                        head={5} />
                </Name>
                <Url className="compact">
                    <Ellipsify
                        text={link.url}
                        head={9}
                        tail={9} />
                </Url>
            </ItemColumn>
            <ActionsColumn>
                <ContextMenu
                    salt={salt}
                    context={link}
                    actions={actions} />
            </ActionsColumn>
        </SortableItem>
    )

    if(!shouldUsePortal) {
        return child
    }

    return createPortal(child, portal)
}

export default SortableLink

import styled from 'styled-components'
import { motion } from 'framer-motion'
import { OrderedList, ListItem } from 'components/list'
import { Simple } from 'components/button'
import PersonBase from 'components/person'
import GroupBase from 'components/group'
import { IconColumn as GroupIconColumn } from 'components/group/s'

export const List = OrderedList
export const Item = ListItem

export const Button = styled(Simple)`
    position: relative;

    border: none;
    border-radius: 0;
    width: 100%;
    min-height: 40px;
    height: auto;
    padding: 0 40px 0 12px;

    white-space: nowrap;
    text-align: start;

    ${Item}:first-child & {
        border-radius: 8px 8px 0 0;
    }

    ${Item}:last-child & {
        border-radius: 0 0 8px 8px;
    }

    &.active {
        background-color: var(--huma-color-surface-constructive-transparent-hover);
    }

    & > * {
        pointer-events: none;
    }
`

export const KeyHintInner = styled(motion.div).attrs(() => ({
    variants: {
        inactive: {
            opacity: 0,
            transition: {
                duration: .1
            }
        },
        active: {
            opacity: 1,
            transition: {
                duration: .2
            }
        }
    },
    initial: 'inactive',
    exit: 'inactive'
}))`
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 100%;

    color: var(--huma-color-foreground-default);

    svg {
        stroke-width: 2;
    }
`

export const Person = styled(PersonBase)`
    max-width: 100%;
`

export const Group = styled(GroupBase)`
    max-width: 100%;

    & ${GroupIconColumn} {
        margin-inline-start: 16px;
        width: 24px;
        height: 24px;
        padding: 4px;

        svg {
            display: block;
        }
    }
`
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { svgStroke } from 'utilities/styled'
import { OrderedListColumns, ListItemChildShrink } from 'components/list'
import { Naked } from 'components/button'

export const Tags = styled(OrderedListColumns)`
    align-items: flex-start;
    flex-wrap: wrap;

    min-height: 40px;
    padding-block-start: 4px;

    line-height: 40px;
`

export const IconWrap = styled(ListItemChildShrink)`
    width: 24px;
    height: 32px;
    padding-inline-end: 8px;

    line-height: 32px;
`

export const Tag = styled(ListItemChildShrink)`
    position: relative;

    margin: 0 4px 4px 0;
    border-radius: 4px;
    height: 32px;
    padding: 0 12px;
    background-color: var(--huma-color-surface-minimal);

    font-size: 14px;
    line-height: 32px;

    &.edit {
        border-radius: 0;
        padding: 0;
        background-color: transparent;
    }
`

const tagCss = css`
    cursor: pointer;
    overflow: hidden;

    border-radius: 4px;
    max-width: 100%;
    height: 100%;
    padding-inline: 12px;
    background-color: var(--huma-color-surface-minimal);

    transition: all .1s ease-in-out;

    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 32px;
    color: var(--huma-color-foreground-default);

    ${Tag}.x & {
        padding-inline-end: 32px;
    }

    ${Tag}.x &:where(:hover, :focus),
    ${Tag}.death-mark &,
    ${Tag}.x &:active {
        background-color: var(--huma-color-surface-destructive-minimal);

        color: var(--huma-color-foreground-destructive);;
    }

    ${Tag}.clickable:not(.death-mark):not(.x) &:hover,
    ${Tag}.clickable:not(.death-mark):not(.x) &:focus {
        background-color: var(--huma-color-surface-constructive-minimal);
    }
`

export const TagRemoveButton = styled(Naked)`
    ${tagCss}
`

export const TagLink = styled(Link)`
    ${tagCss}

    display: inline-block;

    text-decoration: none;
`

export const TagRemoveButtonXCircle = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    display: block;

    border-radius: 100%;
    width: 16px;
    height: 16px;
    background-color: transparent;

    transition: background-color .1s ease-in-out;

    ${TagRemoveButton}:where(:hover, :focus, :active) &,
    ${Tag}.death-mark & {
        background-color: var(--huma-color-surface-default);
    }

    svg {
        position: absolute;
        top: 2px;
        left: 2px;

        ${svgStroke()}
    }
`

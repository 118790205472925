import styled from 'styled-components'
import { FlexChildShrink } from 'components/flex'
import TextBase from 'components/form/input/text'

export const flexColumnsStyle = {
    position: 'relative',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    flexDirection: 'row'
}

export const SelectColumn = styled(FlexChildShrink)`
    position: absolute;
    z-index: 1;
    left: 12px;
    overflow: hidden;
    width: 52px;
    height: 40px;
    background-color: transparent;
    background-image: var(--huma-select-arrow-url);
    background-repeat: no-repeat;
    background-position: right 16px top 50%, 0 0;
    background-size: 10px auto, 100%;

    [dir="ltr"] & {
        padding-right: 12px;
    }

    [dir="rtl"] & {
        padding-left: 12px;
    }
`

export const Select = styled.select`
    width: 52px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    opacity: 0;
    cursor: pointer;
`

export const Flag = styled.p`
    display: block;
    width: 52px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 40px;
    pointer-events: none;
`

export const Text = styled(TextBase)`
    padding-inline-start: 56px;
`
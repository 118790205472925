import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSalaryRevisions } from 'contexts/salary-revisions'
import {
    containerProps,
    CardList, CardListHeader,
    SkeletonListRow, SkeletonCell,
    LoadingContainer,
    Tutorial
} from './s'
import {
    useSkeletonLoader,
    SkeletonStrings, SkeletonStatus
} from 'components/skeleton'
import { Ghost } from 'components/button'
import Loader from 'components/loader'
import Revision from './revision'

const SalaryRevisions = ({ salt }) => {
    const {
        revisions = [],

        intersecter,
        loading,
        paging = {},
        fetchSalaryRevisions,
        fetching,
        autoFetch,
        hasFetched
    } = useSalaryRevisions()

    const skeletonLength = useSkeletonLoader(revisions, hasFetched)

    const noRevisions = !!hasFetched && !revisions.length

    if(noRevisions) {
        return <Tutorial which="salary-revision" />
    }

    const revisionsGrouped = getRevisionsGrouped(revisions) ?? []

    return (
        <CardList {...containerProps}>
            {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                <SkeletonListRow
                    columns={4}
                    key={`list:salary-revisions:skeleton:${index}`}>
                    <SkeletonCell $cell="meta">
                        <SkeletonStrings
                            size={20}
                            length={16} />
                    </SkeletonCell>
                    <SkeletonCell $cell="effective">
                        <SkeletonStrings
                            size={20}
                            length={12} />
                    </SkeletonCell>
                    <SkeletonCell $cell="status">
                        <SkeletonStatus />
                    </SkeletonCell>
                </SkeletonListRow>
            ))}
            {revisionsGrouped.map((group, groupIndex) => (
                <Fragment key={`${salt}:group:${group.heading}`}>
                    <CardListHeader className="always-visible">
                        <CardList.Cell $cell="heading">
                            {group.heading}
                        </CardList.Cell>
                    </CardListHeader>
                    {group.revisions.map((revision, index) => {
                        const last = groupIndex + 1 === revisionsGrouped.length && index + 1 === group.revisions.length

                        return (
                            <Revision
                                revision={revision}
                                {...(last ? { ref: intersecter } : null)}
                                key={revision.id} />
                        )
                    })}
                </Fragment>
            ))}
            {!!paging?.hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost
                            className="constructive"
                            onClick={fetchSalaryRevisions}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
        </CardList>
    )
}

export default SalaryRevisions

const getRevisionsGrouped = revisions => revisions.reduce((accumulator, revision) => {
    // Group the revisions by the effective date, in years
    const heading = revision.effectiveDate.split('-')[0]
    const existingGroup = accumulator.find(group => group.heading === heading)

    if(existingGroup) {
        existingGroup.revisions.push(revision)
        return accumulator
    }

    return [
        ...accumulator,
        {
            heading,
            revisions: [revision]
        }
    ]
}, [])
import styled, { css } from 'styled-components'
import {
    svgStroke,
    fromTablet, belowTablet
} from 'utilities/styled'
import FormBase from 'components/form/controller'
import AvatarBase from 'components/avatar'
import AvatarsBase from 'components/avatars'
import { ArrowSortedDown as ArrowDown } from 'styled-icons/typicons'
import { MobileHeader as MobileHeaderBase } from 'utilities/widget'
import { FlexChild, FlexChildShrink } from 'components/flex'
import GenericWidget from 'widgets/generic'
import { Filter as FilterBase } from 'components/button'

export const Form = styled(FormBase)`
    position: relative;

    width: 100%;
`

export const Count = styled.span`
    display: inline-block;

    margin-inline: 8px -4px;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background-color: var(--huma-color-surface-constructive-bold);

    transition: color .15s ease;

    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: var(--huma-color-foreground-on-bold);

    &:empty {
        display: none;
    }
`

const valueCss = css`
    display: inline-block;

    margin-inline: 8px -4px;
    height: 20px;
`

export const Icon = styled.div`
    ${valueCss}

    svg {
        ${svgStroke('small')}
    }
`

export const Avatar = styled(AvatarBase)`
    ${valueCss}

    ${Icon} & {
        margin: 0;
    }
`

export const Avatars = styled(AvatarsBase)`
    ${valueCss}

    display: inline-flex;

    &.capped {
        margin-inline-end: -12px;
    }
`

export const Arrow = styled(ArrowDown)`
    &.active {
        transform: translateY(-50%) rotate(180deg);
    }
`

export const Widget = styled(GenericWidget)`
    ${fromTablet`
        width: 300px;
        padding: 0;
    `}
`

export const WidgetContentScroller = styled(FlexChild)`
    position: relative;

    &:not(.lax) {
        overflow-y: auto;
    }

    ${fromTablet`
        &:not(.flush) {
            padding: 24px 32px;
        }
    `}

    ${belowTablet`
        padding: 0 0 48px;
    `}
`

export const MobileHeader = styled(MobileHeaderBase)`
    ${belowTablet`
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    `}
`

export const ResetWrap = styled(FlexChildShrink)`
    overflow: hidden;

    border-top: 1px solid var(--huma-color-border-default);
    border-radius: 0 0 8px 8px;
    height: 48px;
    background-color: var(--huma-color-surface-default);

    ${belowTablet`
        display: none;
    `}

    > button {
        display: block;

        width: 100%;
        height: 100%;

        text-align: center;
    }
`

export const Filter = styled(FilterBase)`
    .vertical & {
        justify-content: space-between;

        font-size: 16px;
    }
`
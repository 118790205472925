import React, { memo } from 'react'
import { FilterRow, Form, SearchIcon, SearchField } from './s'

const Search = memo(({ controlProps = {}, setSearch, searching, setSearching, salt }) => (
    <FilterRow>
        <Form
            submitOnChange={true}
            onSubmit={setSearch}>
            <SearchField
                salt={salt}
                className="compact"
                label={false}
                name="search"
                field={{ unsettable: true }}
                controlProps={{
                    ...controlProps,
                    autoFocus: searching
                }}
                onFocus={() => setSearching?.(true)}
                onBlur={() => setSearching?.(false)} />
            <SearchIcon size={16} />
        </Form>
    </FilterRow>
))

export default Search

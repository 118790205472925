import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'

const ViewFile = ({ field = {}, className, label }) => {
    let { value } = field
    if(!value) {
        value = null
    }

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <ValueDisplay {...(!value ? { className: 'empty' } : null)}>
                    {!!value && value}
                    {!value && (
                        <FormattedMessage
                            id="empty_not_set"
                            defaultMessage="Not set" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewFile

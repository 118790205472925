import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useTheme, ThemeProvider } from 'styled-components'
import { useChart } from 'contexts/chart'
import { first, last, sum } from 'utilities/array'
import { map, reduce } from 'utilities/object'
import { cls } from 'utilities/dom'
import { Title, reduceLabels, formatValue, getColor, CustomLegend, CustomTooltip } from 'components/charts'
import {
    ResponsiveContainer,
    BarChart as ReBarChart, Bar, Rectangle,
    XAxis, YAxis, LabelList,
    CartesianGrid, Tooltip, Legend
} from 'recharts'
import { Container } from 'components/charts/s'
import { Sizer, VerticalLabel } from './s'

const BarChart = () => {
    const { formatMessage } = useIntl()
    const { territories } = useI18n()
    const theme = useTheme()

    const [activeBarLabel, setActiveBarLabel] = useState(null)

    const { chart } = useChart()

    const valueFormatter = formatValue({
        format: chart.valueFormat,
        formatMessage,
        territories
    })

    let layout = chart.layout || 'vertical'
    if(!chart.layout && chart.data.sets.length <= 6 && !chart.data.labels.some(label => 'i18n' in label).length) {
        layout = 'horizontal'
    }

    const horizontal = layout === 'horizontal'
    const vertical = layout === 'vertical'

    const labelReducer = (short = false) => reduceLabels({
        formatMessage,
        territories,
        short
    })

    const primaryLabels = chart.labels.reduce(labelReducer(horizontal), {})
    const secondaryLabels = chart.data.labels.reduce(labelReducer(), {})

    const unspecifiedIndex = Object.keys(secondaryLabels).indexOf('unspecified')

    const bars = chart.data.sets.reduce((accumulator, { data, key }) => [
        ...accumulator,
        {
            label: primaryLabels[key],
            ...reduce(data, (accumulator, value, key) => ({
                ...accumulator,
                [secondaryLabels[key]]: value
            }), {})
        }
    ], [])

    const edgeSegments = bars.reduce((accumulator, { label, ...rest }) => {
        const labelsInOrder = Object.values(secondaryLabels)
        const labels = Object.keys(rest)
            .sort((one, two) => labelsInOrder.indexOf(one) - labelsInOrder.indexOf(two))

        return {
            ...accumulator,
            [label]: [first(labels), last(labels)]
        }
    }, {})

    return (
        <ThemeProvider theme={{ ...theme, bars: bars.length }}>
            <Container className={layout}>
                <Title id={chart.id} />
                <Sizer className={layout}>
                    <ResponsiveContainer
                        debounce={1}
                        width="100%"
                        {...(horizontal ? { aspect: 1 } : null)}>
                        <ReBarChart
                            data={bars}
                            layout={layout}
                            margin={{
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0
                            }}
                            stackOffset="expand">
                            <CartesianGrid
                                stroke="var(--huma-color-border-default)"
                                strokeDasharray="4 4"
                                horizontal={horizontal}
                                vertical={false} />
                            <XAxis
                                dataKey={horizontal ? 'label' : null}
                                type={horizontal ? 'category' : 'number'}
                                domain={[0, 'dataMax']}
                                hide={vertical}
                                height={24}
                                padding={{ left: vertical ? 0 : 24 }}
                                axisLine={horizontal ? {
                                    stroke: 'var(--huma-color-border-default)'
                                } : false}
                                tick={{ fill: 'var(--huma-color-foreground-default)' }}
                                tickLine={false}
                                tickSize={8} />
                            <YAxis
                                dataKey={vertical ? 'label' : null}
                                type={vertical ? 'category' : 'number'}
                                domain={[0, 'dataMax']}
                                width={vertical ? 0 : 40}
                                mirror={true}
                                axisLine={horizontal ? {
                                    stroke: 'var(--huma-color-border-default)'
                                } : false}
                                tick={{
                                    fill: 'var(--huma-color-foreground-default)',
                                    ...(vertical ? { dy: -20 } : null)
                                }}
                                tickLine={false}
                                tickSize={4}
                                minTickGap={vertical ? 24 : 8}
                                interval="preserveEnd" />
                            <Tooltip
                                content={(
                                    <CustomTooltip
                                        valueFormatter={valueFormatter}
                                        salt={chart.id} />
                                )}
                                wrapperStyle={{ outline: 'none' }}
                                cursor={false} />
                            <Legend
                                content={(
                                    <CustomLegend
                                        className="horizontal"
                                        salt={chart.id} />
                                )}
                                wrapperStyle={{
                                    minHeight: '48px',
                                    paddingBottom: '24px'
                                }}
                                color="var(--huma-color-foreground-default)"
                                align="left"
                                verticalAlign="top" />
                            {map(secondaryLabels, (label, key, index) => {
                                const color = getColor({
                                    theme: chart.colors,
                                    index,
                                    unspecifiedIndex,
                                    total: secondaryLabels.length
                                })

                                return (
                                    <Bar
                                        layout={layout}
                                        dataKey={label}
                                        stackId={horizontal ? 'x' : 'y'}
                                        fill={color}
                                        barSize={24}
                                        isAnimationActive={false}
                                        activeBar={false}
                                        shape={props => {
                                            const [firstSegment, lastSegment] = edgeSegments[props.payload.label]
                                            const active = props.label === activeBarLabel
                                            const size = horizontal ?
                                                active ? 26 : 24 :
                                                active ? 10 : 8

                                            props = {
                                                ...props,
                                                strokeAlignment: 'inner',
                                                stroke: 'var(--huma-color-surface-default)',
                                                style: { paintOrder: 'stroke' }
                                            }

                                            if(horizontal) {
                                                props = {
                                                    ...props,
                                                    width: size,
                                                    x: props.x - (active ? 1 : 0),
                                                    radius: [
                                                        (lastSegment === label) ? 4 : 0,
                                                        (lastSegment === label) ? 4 : 0,
                                                        0,
                                                        0
                                                    ],
                                                    strokeWidth: (firstSegment === label) ? 0 : 4
                                                }
                                            }

                                            if(vertical) {
                                                props = {
                                                    ...props,
                                                    height: size,
                                                    y: props.y - (active ? 1 : 0),
                                                    radius: [
                                                        (firstSegment === label) ? 4 : 0,
                                                        (lastSegment === label) ? 4 : 0,
                                                        (lastSegment === label) ? 4 : 0,
                                                        (firstSegment === label) ? 4 : 0
                                                    ],
                                                    strokeWidth: 4
                                                }
                                            }

                                            return <Rectangle {...props} />
                                        }}
                                        onMouseEnter={({ label }) => setActiveBarLabel(label)}
                                        onMouseLeave={() => setActiveBarLabel(null)}
                                        key={label}>
                                        {vertical && (
                                            <LabelList
                                                position="inside"
                                                offset={0}
                                                content={(
                                                    <TotalLabel
                                                        sets={chart.data.sets}
                                                        total={bars.length} />
                                                )}
                                                valueAccessor={({ payload, value }) => {
                                                    const [, lastSegment] = edgeSegments[payload.label]

                                                    if(lastSegment === label) {
                                                        return {
                                                            label: payload.label,
                                                            value: valueFormatter(value[1])
                                                        }
                                                    }

                                                    return null
                                                }} />
                                        )}
                                    </Bar>
                                )
                            })}
                        </ReBarChart>
                    </ResponsiveContainer>
                </Sizer>
            </Container>
        </ThemeProvider>
    )
}

const TotalLabel = ({ viewBox, value, sets, index, total }) => {
    if(!value) {
        return null
    }

    const summed = sum(Object.values(sets[index].data))

    return (
        <foreignObject
            y={viewBox.y - 28}
            x="0"
            width="100%"
            height="24px"
            style={{ overflow: 'visible' }}>
            <VerticalLabel className={cls([
                (index + 1 === total) && 'last',
                'compact'
            ])}>
                <span>{value.label}</span>
                <strong>{summed}</strong>
            </VerticalLabel>
        </foreignObject>
    )
}

export default BarChart
import styled, { css } from 'styled-components'
import {
    flashKeyframes,
    belowTablet
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { H3 } from 'components/typography/heading'
import { Columns } from 'components/flex'
import { Button } from 'components/button'
import { compact } from 'utilities/array'

export const Domain = styled(Grid)`
    width: 100%;
    padding: 24px 0;
    border-bottom: 1px solid var(--huma-color-border-default);

    ${({ theme: { domains: { actions } } }) => css`
        grid-template-columns: ${compact(['1fr', '1fr', actions && '40px']).join(' ')};
        column-gap: 24px;

        ${belowTablet`
            grid-template: 1fr 1fr / ${compact(['1fr', actions && '40px']).join(' ')};
            row-gap: 8px;
            column-gap: 16px;
            padding: 16px 0;
        `}
    `}

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }
`

export const NameCell = styled(Columns)`
    ${belowTablet`
        grid-column: span 2;
    `}
`

export const StatusCell = styled(Columns)`
    justify-content: space-between;
    align-items: center;
`

export const ActionsCell = Columns

export const Name = styled(H3)`
    font-size: 16px;
    line-height: 40px;
    font-weight: 400;
    margin: 0;
`

export const VerifyButton = Button

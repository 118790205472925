import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'

const ViewRadio = ({ field = {}, options, className, label }) => {
    let { value } = field

    if(value || value === 0 || value === false) {
        value = options.find(option => option.value === value)?.label ?? value
    }

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <ValueDisplay {...(!value ? { className: 'empty' } : null)}>
                    {!!value && value}
                    {!value && (
                        <FormattedMessage
                            id="empty_not_chosen"
                            defaultMessage="Not chosen" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewRadio

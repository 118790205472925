import React from 'react'
import { useEditorMeta } from '../extensions/utilities'
import { map, size } from 'utilities/object'
import Tooltip from './tooltip'
import { Section, Button } from './s'

const ToolbarInlines = ({ editor, every, activate, getTrigger, setMarking, setPicking, active, tooltipsEnabled, tooltipTarget, groupingOverrides, salt }) => {
    const meta = useEditorMeta()

    const inlines = {}

    const showNativeBold = every('Bold') && (!!groupingOverrides?.Bold ?
        groupingOverrides.Bold === 'inlines' :
        true
    )

    const showNativeItalic = every('Italic') && (!!groupingOverrides?.Italic ?
        groupingOverrides.Italic === 'inlines' :
        true
    )

    const showNativeUnderline = every('Underline') && (!!groupingOverrides?.Underline ?
        groupingOverrides.Underline === 'inlines' :
        true
    )

    const showNativeLink = every('Link') && (!!groupingOverrides?.Link ?
        groupingOverrides.Link === 'inlines' :
        true
    )

    const showNativeEmoji = every('Emoji') && (!!groupingOverrides?.Emoji ?
        groupingOverrides.Emoji === 'inlines' :
        true
    )

    if(showNativeBold) {
        inlines.Bold = {
            onClick: getTrigger('toggleBold'),
            disabled: !editor.can().toggleBold?.() ?? true,
            ...activate(active.bold)
        }
    }

    if(showNativeItalic) {
        inlines.Italic = {
            onClick: getTrigger('toggleItalic'),
            disabled: !editor.can().toggleItalic?.() ?? true,
            ...activate(active.italic)
        }
    }

    if(showNativeUnderline) {
        inlines.Underline = {
            onClick: getTrigger('toggleUnderline'),
            disabled: !editor.can().toggleUnderline?.() ?? true,
            ...activate(active.underline)
        }
    }

    if(showNativeLink) {
        inlines.Link = {
            onClick: () => {
                const link = editor.getAttributes('link')

                setMarking({
                    type: 'link',
                    ...(size(link) ? { link } : null)
                })
            },
            disabled: !editor.can().toggleLink?.() ?? true,
            ...activate(active.link)
        }
    }

    if(showNativeEmoji) {
        inlines.Emoji = {
            onClick: () => setPicking('emoji'),
            // disabled: !editor.can().setEmoji?.() ?? true
        }
    }

    const showForeignCode = every('Code') && (!!groupingOverrides?.Code ?
        groupingOverrides.Code === 'inlines' :
        false
    )

    const showForeignHighlight = every('Highlight') && (!!groupingOverrides?.Highlight ?
        groupingOverrides.Highlight === 'inlines' :
        false
    )

    const showForeignStrike = every('Strike') && (!!groupingOverrides?.Strike ?
        groupingOverrides.Strike === 'inlines' :
        false
    )

    const showForeignSubscript = every('Subscript') && (!!groupingOverrides?.Subscript ?
        groupingOverrides.Subscript === 'inlines' :
        false
    )

    const showForeignSuperscript = every('Superscript') && (!!groupingOverrides?.Superscript ?
        groupingOverrides.Superscript === 'inlines' :
        false
    )

    if(showForeignCode) {
        inlines.Code = {
            onClick: getTrigger('toggleCode'),
            disabled: !editor.can().toggleCode?.() ?? true,
            ...activate(active.code)
        }
    }

    if(showForeignHighlight) {
        inlines.Highlight = {
            onClick: getTrigger('toggleHighlight'),
            disabled: !editor.can().toggleHighlight?.() ?? true,
            ...activate(active.highlight)
        }
    }

    if(showForeignStrike) {
        inlines.Strike = {
            onClick: getTrigger('toggleStrike'),
            disabled: !editor.can().toggleStrike?.() ?? true,
            ...activate(active.strike)
        }
    }

    if(showForeignSubscript) {
        inlines.Subscript = {
            onClick: getTrigger('toggleSubscript'),
            disabled: !editor.can().toggleSubscript?.() ?? true,
            ...activate(active.subscript)
        }
    }

    if(showForeignSuperscript) {
        inlines.Superscript = {
            onClick: getTrigger('toggleSuperscript'),
            disabled: !editor.can().toggleSuperscript?.() ?? true,
            ...activate(active.superscript)
        }
    }

    if(!size(inlines)) {
        return null
    }

    return (
        <Section>
            {map(inlines, (attributes, name) => {
                const {
                    label,
                    shortcut,
                    icon
                } = meta[name]()

                const key = `${salt}:inline:${name}`

                return (
                    <Tooltip
                        content={label}
                        shortcut={shortcut}
                        enabled={tooltipsEnabled}
                        singleton={tooltipTarget}
                        salt={key}
                        key={key}>
                        <Button
                            {...attributes}
                            tabIndex={-1}>
                            {icon}
                        </Button>
                    </Tooltip>
                )
            })}
        </Section>
    )
}

export default ToolbarInlines
import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Rows } from 'components/flex'
import { SkeletonAvatarAndFullName, SkeletonStrings } from 'components/skeleton'

export const Container = styled.div`
    overflow: hidden;
    position: relative;

    border-radius: 8px;
    height: 320px;
    box-shadow: inset 0 0 0 1px var(--huma-color-border-default);

    ${belowTablet`
        scroll-snap-align: start;

        width: 240px;
    `}
`

export const Content = styled(Rows)`
    position: relative;
    align-items: stretch;
    justify-content: flex-end;

    height: 100%;
    padding: 16px;

    ${belowTablet`
        margin: 0;
    `}
`

export const Byline = styled(SkeletonAvatarAndFullName)`
    margin-block-end: 8px;
`

export const Heading = styled(SkeletonStrings)`
    margin-block-end: 16px;
`
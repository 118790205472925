import styled, { css } from 'styled-components'
import { Control as ControlBase } from 'components/form/field/s'
import { flexColumnsCss, FlexChildShrink, flexChildCss } from 'components/flex'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'
import { Plain } from 'components/button'

export const Control = styled(ControlBase)`
    &.framed {
        border: 1px solid var(--huma-color-border-default);
        border-radius: 8px;
    }
`

export const TextColumn = styled.div`
    ${flexChildCss}
`

export const LabelText = Paragraph

export const LabelHelptext = styled(CaptionSmall)`
    line-height: 20px;
`

export const RadiobuttonColumn = styled(FlexChildShrink)`
    padding: 2px 0;
`

export const UncapButton = styled(Plain)`
    height: 32px;
    font-size: 12px;
    line-height: 32px;
`

export const OptionColumns = styled.label`
    ${flexColumnsCss}
    align-items: start;
    min-height: 40px;
    padding: 8px 0;
    cursor: pointer;

    ${p => (p.$direction === 'ltr') && css`
        & ${RadiobuttonColumn} {
            margin-inline-end: 16px;
        }
    `}

    ${p => (p.$direction === 'rtl') && css`
        & ${RadiobuttonColumn} {
            order: 2;
        }

        & ${TextColumn} {
            margin-inline-end: 16px;
        }
    `}

    &.disabled {
        cursor: not-allowed;

        color: var(--huma-color-foreground-disabled);
    }

    &.centered {
        align-items: center;
    }

    &.align-with-input ${RadiobuttonColumn} {
        padding-top: 10px;
    }

    ${Control}.framed & {
        position: relative;
        align-items: center;

        padding: 16px;

        &:not(.last):after {
            content: "";
            position: absolute;
            bottom: 0;

            width: calc(100% - 32px);
            height: 1px;
            background-color: var(--huma-color-border-default);
        }
    }
`
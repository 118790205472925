import styled from 'styled-components'

export const LoadingContainer = styled.div`
    height: 120px;
    position: relative;

    &:not(:last-child) {
        margin-bottom: 32px;
    }
`

import styled, { css } from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Columns } from 'components/flex'
import { flexChildGrowCss } from 'components/flex'
import { Grid } from 'components/grid'
import Paragraph from 'components/typography/paragraph'
import Caption from 'components/typography/caption'
import FavIconBase from 'components/favicon'

export const SortableItem = styled(Columns)`
    align-items: center;

    margin-bottom: 16px;
    border-radius: 8px;
    height: 44px;

    background-color: var(--huma-color-surface-default);

    ${p => !!p.$isDragging && css`
        box-shadow: var(--huma-shadow-lowest);
    `}
`

export const DragColumn = styled.div`
    color: var(--huma-color-foreground-minimal);

    svg {
        margin-inline-end: 16px;
    }
`

export const ItemColumn = styled(Grid)`
    ${flexChildGrowCss}

    grid-template: 24px 20px / 40px calc(100% - 40px);
    align-items: center;
`

export const FavIcon = styled(FavIconBase)`
    margin-inline-end: 16px;

    ${ItemColumn} & {
        grid-row: 1 / 3;
    }
`

export const Name = Paragraph

export const Url = styled(Caption)`
    font-size: 14px;
`

export const ActionsColumn = styled.div`
    position: relative;

    margin-inline-start: 16px;

    .actions-button {
        margin-block: -8px;

        ${belowTablet`
            margin-block: 0;
        `}
    }
`

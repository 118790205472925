import React, { useState } from 'react'
import View from './view'
import Edit from './edit'
import { compact } from 'utilities/array'

const PaymentCardField = ({ field = {}, salt, name, mode = 'edit', ...props }) => {
    const {
        value = {},
        editable = true
    } = field

    const [hasValue, setHasValue] = useState(!!compact(Object.values(value)).length)

    props = {
        ...props,
        field,
        name,
        salt: `${salt}:${name}`
    }

    if(editable && (mode === 'edit' || !hasValue)) {
        return <Edit {...props} />
    }

    return (
        <View
            {...props}
            edit={() => setHasValue(false)} />
    )
}

export default PaymentCardField

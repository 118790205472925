import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { Grid as GridBase } from 'components/grid'

export const Grid = styled(GridBase)`
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    grid-gap: 32px;
    margin: 32px 0;

    ${belowPhone`
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    `}
`
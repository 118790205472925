import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { usePayment } from 'contexts/payment'
import { useGetBundleChangeMeta, refreshPayment } from 'utilities/payment'
import { sendAppSignal } from 'hooks/app-signal'
import {
    Bundles, Bundle, BundleHeader, BundleHeading, BundleCTA,
    CenteredParagraph, ContactSalesWrap,
    Message
} from './s'
import { ModalHeader } from 'modals/generic'
import { Benjamin, Ghost, InlineButton } from 'components/button'
import { BundleName } from 'components/payment/plan'
import Paragraph from 'components/typography/paragraph'
import { ContactSalesEnriched as ContactSales } from './contact'
import { Scrollable as Modal } from 'modals/generic'
import Manage from 'pages/settings/pages/subscription/main/manage'

const Content = ({ module, heading, bundleDescriptions, dismiss, children, salt }) => {
    const { formatMessage } = useIntl()

    const getBundleChangeMeta = useGetBundleChangeMeta()

    const { bundlesByTrack } = useAccess()
    const { account } = usePayment()

    const {
        track,
        bundle
    } = account?.subscription ?? {}

    const [managing, setManaging] = useState(null)

    if(!track || !bundle) {
        return null
    }

    const bundles = bundlesByTrack?.[track] ?? []

    return (
        <>
            <ModalHeader
                heading={heading}
                dismiss={dismiss} />
            {children}
            {(!!bundles?.length && !!account?.subscription?.public) && (
                <>
                    <Bundles>
                        {bundles.map((bundle, index) => {
                            const meta = getBundleChangeMeta({
                                track: bundle.track,
                                bundle: bundle.bundle
                            })

                            if(meta.change === 'downgrade') {
                                return null
                            }

                            const isCurrentPlan = bundle.track === account?.subscription?.track && bundle.bundle === account?.subscription?.bundle
                            const expired = !!account?.subscription?.trial?.expired

                            const CTAChange = !meta.swap && ['upgrade', 'purchase'].includes(meta.change) ?
                                Benjamin :
                                Ghost

                            let ctaText = (
                                <FormattedMessage
                                    id="payment_plan_action_continue"
                                    defaultMessage="Continue on {plan}"
                                    values={{
                                        plan: (
                                            <BundleName
                                                bundle={bundle}
                                                trial={false} />
                                        )
                                    }} />
                            )

                            if(!expired) {
                                ctaText = (
                                    <FormattedMessage
                                        id={(!expired && isCurrentPlan) ?
                                            'payment_plan_heading_current' :
                                            `payment_plan_cta_${meta.change}`
                                        }
                                        defaultMessage={(!expired && isCurrentPlan) ?
                                            'Current plan' :
                                            formatMessage({
                                                id: 'action_pick',
                                                defaultMessage: 'Pick'
                                            })
                                        } />
                                )
                            }

                            return (
                                <Bundle
                                    key={`${bundle.track}:${bundle.bundle}:${index}`}
                                    className={isCurrentPlan ? 'active' : ''}>
                                    <BundleHeader>
                                        <BundleHeading className="compact">
                                            <BundleName bundle={bundle} />
                                        </BundleHeading>
                                        {bundleDescriptions?.[bundle.bundle] && (
                                            <Paragraph className="compact">
                                                {bundleDescriptions[bundle.bundle]}
                                            </Paragraph>
                                        )}
                                    </BundleHeader>
                                    <BundleCTA>
                                        <CTAChange
                                            onClick={() => setManaging(meta)}
                                            disabled={isCurrentPlan}>
                                            {ctaText}
                                        </CTAChange>
                                    </BundleCTA>
                                </Bundle>
                            )
                        })}
                    </Bundles>
                    <CenteredParagraph>
                        <InlineButton
                            onClick={() => sendAppSignal('value.show', { module })}
                            className="constructive">
                            <FormattedMessage
                                id="value_proposition_cta"
                                defaultMessage="Explore the value" />
                        </InlineButton>
                    </CenteredParagraph>
                </>
            )}
            {!account?.subscription?.public && (
                <Message
                    type="info"
                    message={formatMessage({
                        id: 'payment_subscription_custom_information',
                        defaultMessage: 'You are on a custom plan. Contact sales if you want to make any changes to your subscription.'
                    })} />
            )}
            <ContactSalesWrap>
                <ContactSales
                    dismiss={dismiss}
                    salt={`${salt}:feedback-questions`} />
            </ContactSalesWrap>
            <Modal
                show={!!managing}
                dismiss={() => setManaging(null)}
                salt={`${salt}:subscription:manage`}>
                <Manage
                    meta={managing}
                    onDone={() => {
                        refreshPayment()
                        setManaging(null)
                        dismiss()
                    }}
                    salt={salt} />
            </Modal>
        </>
    )
}

export default Content

import styled from 'styled-components'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import { Header as ExpandableHeader } from 'components/expandable/s'

export const Heading = styled.span`
    &.disabled {
        text-decoration: line-through;
    }
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(ExpandableHeader)}
`
import React, { forwardRef } from 'react'
import { useOrganization } from 'contexts/organization'
import View from './view'
import Edit from './edit'

const ShareField = forwardRef((props, forwardedRef) => {
    const { organization } = useOrganization()
    const publicOption = optionize('organization')(organization)

    let {
        name,
        field = {},
        picker = {},
        mode = 'edit',
        enabled = true,
        salt,
        ...rest
    } = props

    let {
        value = [],
        editable = true
    } = field

    let {
        types = ['organization', 'user', 'team', 'location'],
        locked = []
    } = picker

    if(types?.length === 1 && types.includes('organization')) {
        value = [publicOption]
        enabled = false
    }

    const valueToShares = value => {
        if(value.type === 'organization') {
            return publicOption
        }

        return optionize(value.type)(value)
    }

    if(types?.includes?.('organization') && !value?.length && !locked?.length) {
        value = [publicOption]
    } else {
        value = value?.map(valueToShares) ?? []
    }

    locked = locked.map(valueToShares)

    props = {
        ...rest,
        name,
        publicOption,
        field: {
            ...field,
            value
        },
        picker: {
            ...picker,
            types,
            locked
        },
        enabled,
        salt: `${salt}:${name}`,
        forwardedRef
    }

    if(mode === 'edit' && editable) {
        return <Edit {...props} />
    }

    return <View {...props} />
})

export default ShareField

export const empty = []

export const optionize = type => result => ({
    [type]: result,
    type
})

export const unoptionize = ({ type, ...option }) => ({ ...option?.[type], type })
export const getOptionId = (...args) => unoptionize(...args)?.id
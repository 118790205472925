import styled from 'styled-components'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'

export const Wrap = styled.div`
    padding-top: 8px;
    text-align: left;
`

export const Name = styled(Paragraph).attrs(attributes => ({
    ...attributes,
    as: 'span'
}))`
    display: block;
`

export const Meta = styled(CaptionSmall).attrs(attributes => ({
    ...attributes,
    as: 'span'
}))`
    display: block;
`
import React from 'react'
import Mention from '@tiptap/extension-mention'
import { ReactRenderer, ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react'
import { PluginKey } from '@tiptap/pm/state'
import Node from './node'
import Suggestions from './suggestions'
import tippy, { sticky } from 'tippy.js'
import { getTippySuggestionsConfiguration } from '../utilities'

export const SuggestionPluginKey = new PluginKey('variable')

const Variable = Mention.extend({
    name: 'variable',

    marks: '',
    exitable: true,

    addAttributes() {
        return {
            sourceType: {
                default: null,
                parseHTML: element => element.getAttribute('data-source-type'),
                renderHTML: attributes => ({ 'data-source-type': attributes.sourceType })
            },
            sourceName: {
                default: null,
                parseHTML: element => element.getAttribute('data-source-name'),
                renderHTML: attributes => ({ 'data-source-name': attributes.sourceName })
            },
            fieldName: {
                default: null,
                parseHTML: element => element.getAttribute('data-field-name'),
                renderHTML: attributes => ({ 'data-field-name': attributes.fieldName })
            },
            id: {
                default: null,
                parseHTML: element => element.getAttribute('data-id'),
                renderHTML: attributes => ({ 'data-id': attributes.id })
            },
            cursor: {
                default: null,
                parseHTML: element => element.getAttribute('data-cursor'),
                renderHTML: attributes => ({ 'data-cursor': attributes.cursor })
            },
            detached: {
                default: null,
                parseHTML: element => element.getAttribute('data-detached'),
                renderHTML: attributes => ({ 'data-detached': attributes.detached })
            },

            // Should never be persisted, but will be interpolated into existence
            value: {
                default: null,
                parseHTML: element => element.getAttribute('data-value'),
                renderHTML: attributes => ({ 'data-value': attributes.value })
            }
        }
    },

    parseHTML() {
        return [{
            tag: 'code.variable[data-source-type][data-source-name][data-field-name]'
        }]
    },

    renderHTML({ HTMLAttributes }) {
        const attributes = mergeAttributes(
            { class: this.name },
            this.options.HTMLAttributes,
            HTMLAttributes
        )

        return [
            'code',
            attributes,
            this.options.renderHTML?.(attributes) ?? attributes?.value ?? ''
        ]
    },

    renderText({ node }) {
        return this.options.renderHTML?.(node.attrs) ?? node.attrs.value ?? ''
    },

    addNodeView() {
        return ReactNodeViewRenderer(props => (
            <Node
                {...props}
                variable={this} />
        ))
    },

    addOptions() {
        const options = this.parent?.()
        const char = '$'

        return {
            getLabel: element => element.innerText.replace(char, ''),
            renderHTML: ({ value, descriptor, sourceName, fieldName }) => value ?
                value :
                descriptor ?
                    descriptor :
                    `${char}${sourceName}.${fieldName}`,

            suggestion: {
                ...(options?.suggestion ?? null),
                pluginKey: SuggestionPluginKey,
                char,
                allowSpaces: true,
                decorationTag: 'code',
                decorationClass: 'variable suggestion',

                items: async ({ query }) => {
                    if(!query || query.length < 2 || typeof query !== 'string') {
                        return { query: null }
                    }

                    return { query: query.toLowerCase() }
                },

                render: () => {
                    let suggestions
                    let popup

                    return {
                        onStart: props => {
                            suggestions = new ReactRenderer(Suggestions, {
                                props,
                                editor: props.editor
                            })

                            if(!props.clientRect) {
                                return
                            }

                            popup = tippy('body', getTippySuggestionsConfiguration(props, suggestions, {
                                sticky: true,
                                plugins: [sticky],
                                placement: 'bottom-start',

                                // Ensure that it appears beneath the sticky header
                                appendTo: () => document.querySelector('#content'),
                                zIndex: 3,

                                popperOptions: {
                                    // A bit weird when scrolling sometimes, but way better than 'absolute'
                                    strategy: 'fixed',

                                    modifiers: [
                                        {
                                            name: 'flip',
                                            options: {
                                                fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
                                                boundary: props.editor.view.dom
                                            }
                                        }
                                    ]
                                }
                            }))
                        },

                        onUpdate: props => {
                            suggestions?.updateProps(props)

                            if(!props.clientRect) {
                                return
                            }

                            popup?.[0]?.setProps({ getReferenceClientRect: props.clientRect })
                        },

                        onKeyDown: props => {
                            if(props.event.key === 'Escape') {
                                popup?.[0]?.hide()
                                return true
                            }

                            return suggestions?.ref?.onKeyDown(props)
                        },

                        onExit: () => {
                            suggestions?.destroy()
                            popup?.[0]?.destroy()
                        }
                    }
                },

                command: ({ editor, range, props }) => {
                    if(!props.attributes) {
                        return
                    }

                    const hasSelection = editor.view.state.selection.$from.pos < editor.view.state.selection.$to.pos

                    const marksBefore = editor.view.state.selection.$from.nodeBefore?.marks?.map?.(mark => ({ type: mark.type.name })) ?? null

                    const marksAfter = hasSelection ?
                        (editor.view.state.selection.$to.nodeAfter?.marks?.map?.(mark => ({ type: mark.type.name })) ?? null) :
                        marksBefore

                    const variableMarks = hasSelection ?
                        (marksBefore?.filter(markBefore => marksAfter?.find(markAfter => markBefore.type === markAfter.type)) ?? null) :
                        marksBefore

                    const overrideSpace = editor.view.state.selection.$to.nodeAfter?.text?.startsWith(' ')
                    if(overrideSpace) {
                        range.to += 1
                    }

                    editor
                        .chain()
                        .focus()
                        .insertContentAt(range, [
                            {
                                type: this.name,
                                attrs: props.attributes,
                                ...(!!variableMarks?.length ? { marks: variableMarks } : null)
                            },
                            {
                                type: 'text',
                                text: ' ',
                                ...(marksAfter ? { marks: marksAfter } : null)
                            }
                        ])
                        .run()

                    global.getSelection()?.collapseToEnd()
                }
            }
        }
    }
})

export { useGetFieldDefinitions } from './fields'

export {
    useGetValuesInterpolator,
    getContentWithoutInterpolatedProperties, getContentWithoutFalsyValues,
    getContentSources, renameContentSource, detachContentSource, attachContentSource
} from './utilities'

export default Variable
import styled, { css } from 'styled-components'
import { fromPhone, belowPhone } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Naked } from 'components/button'
import Link from 'components/link'

export const aspectRatioFallback = {
    width: 16,
    height: 10
}

export const Videos = styled(Grid)`
    row-gap: 16px;
`

export const Wrapper = styled(Grid)`
    gap: 24px;
    align-items: center;

    ${fromPhone`
        grid-template-columns: 128px 1fr;
    `}

    ${belowPhone`
        grid-template-rows: repeat(2, auto);
    `}
`

const thumbnailWrapperCss = css`
    aspect-ratio: var(--aspect-ratio, ${aspectRatioFallback.width} / ${aspectRatioFallback.height});
`

export const ThumbnailLink = styled(Link)`
    ${thumbnailWrapperCss}
`

export const ThumbnailButton = styled(Naked)`
    ${thumbnailWrapperCss}
`

const thumbnailCss = css`
    border-radius: 8px;
    width: 100%;
    height: 100%;
`

export const Thumbnail = styled.img`
    ${thumbnailCss}

    transition: filter 0.1s ease-in-out;

    * :is(
        ${ThumbnailLink}:hover,
        ${ThumbnailLink}:focus,
        ${ThumbnailButton}:hover,
        ${ThumbnailButton}:focus
    ) & {
        filter: contrast(0.8);
    }
`

export const ThumbnailFallback = styled.div`
    ${thumbnailCss}

    background-color: var(--huma-color-border-default);

    transition: all .1s ease-in-out;

    * :is(
        ${ThumbnailLink}:hover,
        ${ThumbnailLink}:focus,
        ${ThumbnailButton}:hover,
        ${ThumbnailButton}:focus
    ) & {
        background-color: var(--huma-color-border-default-focus);
        box-shadow: var(--huma-shadow-lowest);
    }
`
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useTheme, ThemeProvider } from 'styled-components'
import { useChart } from 'contexts/chart'
import { map } from 'utilities/object'
import { cls } from 'utilities/dom'
import { Title, reduceLabels, formatValue, CustomSingleDimensionTooltip } from 'components/charts'
import {
    ResponsiveContainer,
    BarChart as ReBarChart, Bar, Rectangle,
    XAxis, YAxis, LabelList,
    CartesianGrid, Tooltip
} from 'recharts'
import { Container } from 'components/charts/s'
import { Sizer, VerticalLabel } from './s'

const BarChart = () => {
    const { formatMessage } = useIntl()
    const { territories } = useI18n()
    const theme = useTheme()

    const [activeBar, setActiveBar] = useState(null)

    const { chart } = useChart()

    const valueFormatter = formatValue({
        format: chart.valueFormat,
        formatMessage,
        territories
    })

    let layout = chart.layout || 'horizontal'
    if((!chart.layout || layout === 'horizontal') && Object.keys(chart.data).length > 6) {
        layout = 'vertical'
    }

    const horizontal = layout === 'horizontal'
    const vertical = layout === 'vertical'

    const labelReducer = reduceLabels({
        formatMessage,
        territories
    })

    const labels = chart.labels.reduce(labelReducer, {})
    const labelsInOrder = Object.values(labels)

    const bars = map(chart.data, (value, key) => ({
        label: labels[key],
        value
    }))
        .sort(({ label: one }, { label: two }) => {
            return labelsInOrder.indexOf(one) - labelsInOrder.indexOf(two)
        })

    return (
        <ThemeProvider theme={{ ...theme, bars: bars.length }}>
            <Container className={layout}>
                <Title id={chart.id} />
                <Sizer className={layout}>
                    <ResponsiveContainer
                        debounce={1}
                        width="100%"
                        {...(horizontal ? { aspect: 1 } : null)}>
                        <ReBarChart
                            data={bars}
                            layout={layout}
                            margin={{
                                top: 16,
                                right: 0,
                                bottom: 0,
                                left: 0
                            }}>
                            <CartesianGrid
                                stroke="var(--huma-color-border-default)"
                                strokeDasharray="4 4"
                                horizontal={horizontal}
                                vertical={false} />
                            <XAxis
                                dataKey={horizontal ? 'label' : 'value'}
                                type={horizontal ? 'category' : 'number'}
                                domain={[0, 'dataMax']}
                                hide={vertical}
                                height={24}
                                padding={{ left: vertical ? 0 : 24 }}
                                axisLine={horizontal ? {
                                    stroke: 'var(--huma-color-border-default)'
                                } : false}
                                tick={{ fill: 'var(--huma-color-foreground-default)' }}
                                tickLine={false}
                                tickSize={8} />
                            <YAxis
                                dataKey={vertical ? 'label' : 'value'}
                                type={vertical ? 'category' : 'number'}
                                domain={[0, 'dataMax']}
                                width={vertical ? 0 : 40}
                                mirror={true}
                                axisLine={horizontal ? {
                                    stroke: 'var(--huma-color-border-default)'
                                } : false}
                                tick={{
                                    fill: 'var(--huma-color-foreground-default)',
                                    ...(vertical ? { dy: -20 } : null)
                                }}
                                tickLine={false}
                                tickSize={4}
                                minTickGap={vertical ? 24 : 8}
                                interval="preserveEnd" />
                            <Tooltip
                                content={(
                                    <CustomSingleDimensionTooltip
                                        valueFormatter={valueFormatter}
                                        salt={chart.id} />
                                )}
                                wrapperStyle={{ outline: 'none' }}
                                cursor={false} />
                            <Bar
                                dataKey="value"
                                fill="var(--huma-color-chart-intensity-subtle)"
                                barSize={24}
                                isAnimationActive={false}
                                activeBar={false}
                                shape={props => {
                                    const active = props.label === activeBar?.label
                                    const size = horizontal ?
                                        active ? 26 : 24 :
                                        active ? 10 : 8

                                    props = {
                                        ...props,
                                        stroke: 'var(--huma-color-surface-default)',
                                        strokeAlignment: 'inner',
                                        style: { paintOrder: 'stroke' }
                                    }

                                    if(horizontal) {
                                        props = {
                                            ...props,
                                            width: size,
                                            x: props.x - (active ? 1 : 0),
                                            radius: [4, 4, 0, 0],
                                            strokeWidth: 4
                                        }
                                    }

                                    if(vertical) {
                                        props = {
                                            ...props,
                                            height: size,
                                            y: props.y - (active ? 1 : 0),
                                            radius: [4, 4, 4, 4],
                                            strokeWidth: 4
                                        }
                                    }

                                    return <Rectangle {...props} />
                                }}
                                onMouseEnter={({ label }) => setActiveBar({ label })}
                                onMouseLeave={() => setActiveBar(null)}>
                                {vertical && (
                                    <LabelList
                                        position="inside"
                                        offset={0}
                                        content={(
                                            <TotalLabel
                                                data={bars}
                                                total={bars.length} />
                                        )}
                                        valueAccessor={({ payload, value }) => ({
                                            label: payload.label,
                                            value: valueFormatter(value)
                                        })} />
                                )}
                            </Bar>
                        </ReBarChart>
                    </ResponsiveContainer>
                </Sizer>
            </Container>
        </ThemeProvider>
    )
}

const TotalLabel = ({ viewBox, value, index, total }) => {
    if(!value) {
        return null
    }

    return (
        <foreignObject
            y={viewBox.y - 28}
            x="0"
            width="100%"
            height="24px"
            style={{ overflow: 'visible' }}>
            <VerticalLabel className={cls([
                (index + 1 === total) && 'last',
                'compact'
            ])}>
                <span>{value.label}</span>
                <strong>{value.value}</strong>
            </VerticalLabel>
        </foreignObject>
    )
}

export default BarChart
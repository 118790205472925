import styled, { css } from 'styled-components'
import { belowTablet, belowPhone } from 'utilities/styled'
import { Link as LinkBase } from 'react-router-dom'
import { Naked } from 'components/button'
import { Rows, flexRowsCss, FlexChildShrink, flexChildShrinkCss } from 'components/flex'
import Person from 'components/person'
import StatusLabelBase from 'components/status-label'
import { Units as UnitsBase } from 'components/units'
import { H3 } from 'components/typography/heading'

export const Container = styled.article`
    overflow: hidden;
    position: relative;

    border-radius: 8px;
    height: 320px;

    transition: all .15s ease-in-out;

    &.no-image {
        box-shadow: inset 0 0 0 1px var(--huma-color-border-default);
    }

    /* Prevent children flicker on hover */
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transform-style: preserve-3d;
    -webkit-transform: perspective(1000px);

    > * {
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-transform-style: preserve-3d;
        -webkit-transform: perspective(1000px);
        -webkit-font-smoothing: subpixel-antialiased;
    }

    &[class]:has(> a:hover),
    &[class]:has(> button:hover) {
        transform: translate3d(0, 0, 0) scale(1.015);

        ${belowTablet`
            transform: none;
        `}
    }

    &[class]:has(> a:focus-within),
    &[class]:has(> button:focus-within) {
        box-shadow:
            0 0 0 2px var(--huma-color-surface-default),
            0 0 0 4px var(--huma-color-border-constructive-bold);
    }

    ${belowTablet`
        scroll-snap-align: start;

        width: 240px;
    `}
`

const triggerCss = css`
    ${flexRowsCss}
    width: 100%;
    height: 100%;
    color: var(--huma-color-foreground-default);
    text-align: left;
    text-decoration: none;

    ${Container}.image & {
        color: var(--huma-palette-white);
    }
`

export const Link = styled(LinkBase)`
    ${triggerCss}
`

export const Button = styled(Naked)`
    ${triggerCss}
`

export const Image = styled(FlexChildShrink)`
    position: absolute;

    overflow: hidden;
    width: 100%;
    height: 240px;
    background:
        var(--huma-color-surface-default)
        var(--__huma-component-news-card-image)
        50% no-repeat;
    background-size: cover;
`

const overlayColor = 'var(--__huma-component-news-card-color, var(--huma-palette-neutral-10))'

export const Overlay = styled(Rows)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;

    background-image: linear-gradient(
        0deg,
        ${overlayColor} 0%,
        ${overlayColor} 25%,
        color-mix(in srgb, ${overlayColor}, transparent 60%) 50%, /* As of Safari 17.5, color-mix() is needed to avoid correct rendering */
        transparent 70%
    );

    &::before {
        content: "";
        pointer-events: none;

        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-image: linear-gradient(
            0deg,
            rgba(0, 0, 0, .2) 0%,
            rgba(0, 0, 0, .2) 50%,
            transparent 100%
        );
    }
`

export const Content = styled(Rows)`
    justify-content: flex-end;
    align-items: flex-start;
    padding: 16px;
    position: relative;

    ${belowTablet`
        margin: 0;
    `}
`

export const StatusLabel = styled(StatusLabelBase)`
    margin-block-end: 16px;

    ${belowTablet`
        position: absolute;
        top: 20px;
        right: 56px;

        margin: 0;
    `}
`

export const Byline = styled(Person)`
    ${flexChildShrinkCss}

    margin-block-end: 8px;

    ${Container}.image & {
        color: rgb(from var(--huma-palette-white) r g b / .85);
    }
`

export const Heading = styled(H3)`
    ${flexChildShrinkCss}

    --__huma-component-news-heading-max-lines: 5;
    --__huma-component-news-heading-line-height: 24px;

    overflow: hidden;
    position: relative;
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;

    max-height: calc(var(--__huma-component-news-heading-max-lines) * var(--__huma-component-news-heading-line-height));

    line-height: var(--__huma-component-news-heading-line-height);
    -webkit-line-clamp: var(--__huma-component-news-heading-max-lines);

    ${Container}.image & {
        text-shadow: 1px 1px 1px rgb(from var(--huma-palette-neutral-10) r g b / .5);
    }

    ${Link}:where(:hover, :focus) & {
        text-decoration: underline;
    }
`

export const Meta = styled.div`
    display: flex;
    flex-wrap: wrap;

    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${Container}.image & {
        color: rgb(from var(--huma-palette-white) r g b / .85);

        svg {
            color: rgb(from var(--huma-palette-white) r g b / .85);
        }
    }

    > * {
        max-width: inherit;
    }
`

export const Units = styled(UnitsBase)`
    ${belowPhone`
        min-width: 100%;
    `}
`

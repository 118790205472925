import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { sendAppSignal } from 'hooks/app-signal'
import { InlineMessage } from 'components/message'
import { Plain } from 'components/button'
import { Technical } from './s'

const TooltipContentDetached = ({ context, source }) => {
    const { formatMessage } = useIntl()

    const handler = () => sendAppSignal('smart-editor.data-source.attach', `${source.type}.${source.name}`)

    // TODO: Context will instruct us whether to show a different message

    return (
        <>
            <InlineMessage
                type="error"
                className="compact"
                message={formatMessage({
                    id: 'editor_variable_source_detached',
                    defaultMessage: 'Data source not attached.'
                })} />
            <Plain
                onMouseDown={handler}
                onClick={handler}
                className="constructive small">
                <span>
                    <FormattedMessage
                        id="editor_variable_source_action_attach"
                        defaultMessage="Attach a data source to {name}"
                        values={{ name: <Technical>{source.name}</Technical> }} />
                </span>
            </Plain>
        </>
    )
}

export default TooltipContentDetached
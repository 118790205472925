import React, { Component } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormContext } from 'components/form/controller'
import PubSub from 'pubsub-js'
import { filter, omit } from 'utilities/object'
import { compact } from 'utilities/array'
import { cls } from 'utilities/dom'
import { PaymentInputsContainer } from 'modules/react-payment-inputs'
import { Field, Label, Control } from 'components/form/field/s'
import { MatchedCardLogo, NumberInput, Input, Columns, SupportedCardLogos } from './s'
import CardLogo from 'components/card-logo'
import SupportedCardLogo from 'components/card-logo'
import isEqual from 'react-fast-compare'

const empty = {
    number: '',
    expiry: '',
    cvc: ''
}

class EditPaymentCard extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const { value } = getFieldFromProps(props)
        this.state = { value }

        this.register()
    }

    componentDidMount() {
        this.context.triggerChange(this.props.name, { touched: false })
    }

    componentDidUpdate = ({ name, field }, { value }) => {
        const nameChanged = name !== this.props.name
        const valueChanged = !isEqual(value, this.state.value)
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include

        if(nameChanged) {
            this.context.unregisterField(name)
            this.register()
        }

        if(valueChanged) {
            this.context.triggerChange(this.props.name)
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name)
        PubSub.unsubscribe('form.field.payment-card')
    }

    register = (update = false) => {
        const {
            required,
            include
        } = getFieldFromProps(this.props)

        this.context.registerField(this.props.name, {
            empty,
            required,
            include,
            unset: this.unset,

            validator: () => {
                const { meta } = this.props
                const touchedInputs = omit(meta.touchedInputs, 'zip')
                const erroredInputs = omit(meta.erroredInputs, 'zip')

                const touchedCount = compact(Object.values(touchedInputs)).length
                const totalCount = Object.keys(touchedInputs).length

                if(touchedCount < totalCount) {
                    return !required
                }

                const erroredKeys = Object.keys(filter(erroredInputs, message => !!message))
                return !erroredKeys.length
            }
        }, update)

        PubSub.unsubscribe('form.field.payment-card')
        PubSub.subscribe('form.field.payment-card.touch', () => {
            this.context.triggerChange(this.props.name, { touched: true })
        })
    }

    onChange = (e, field) => {
        this.setState(({ value }) => ({
            value: {
                ...value,
                [field]: e.target.value
            }
        }), () => {
            const { name, onChange } = this.props
            onChange?.({ [name]: this.state.value })
        })
    }

    unset = () => this.setState({ value: empty })

    render() {
        const { value } = this.state

        const {
            className,
            name,
            controlProps = {},
            salt,
            enabled = true,
            loading = false,
            display,

            meta,
            getCardNumberProps,
            getExpiryDateProps,
            getCVCProps,

            formatMessage
        } = this.props

        const {
            required,
            softRequired
        } = getFieldFromProps(this.props)

        const { touchedInputs, erroredInputs } = meta

        const numberClassName = cls([
            className,
            !!touchedInputs.cardNumber && 'touched',
            (!erroredInputs.cardNumber && loading) && 'loading',
            (!!touchedInputs.cardNumber && !!erroredInputs.cardNumber) && 'error'
        ])

        const expiryClassName = cls([
            className,
            !!touchedInputs.expiryDate && 'touched',
            (!erroredInputs.expiryDate && loading) && 'loading',
            (!!touchedInputs.expiryDate && !!erroredInputs.expiryDate) && 'error'
        ])

        const cvcClassName = cls([
            className,
            !!touchedInputs.cvc && 'touched',
            (!erroredInputs.cvc && loading) && 'loading',
            (!!touchedInputs.cvc && !!erroredInputs.cvc) && 'error'
        ])

        const controlClassName = cls([
            controlProps.className,
            'monospace',
            display
        ])

        return (
            <>
                <Field {...(numberClassName ? { className: numberClassName } : null)}>
                    <Label
                        htmlFor={`${salt}:number`}
                        required={required || softRequired}>
                        <FormattedMessage
                            id="payment_label_card_number"
                            defaultMessage="Card number" />
                    </Label>
                    <Control>
                        <MatchedCardLogo>
                            <CardLogo
                                size={24}
                                type={meta?.cardType?.type} />
                        </MatchedCardLogo>
                        <NumberInput
                            {...(controlClassName ? { className: controlClassName } : null)}
                            {...getCardNumberProps({
                                onInput: e => this.onChange(e, 'number'),
                                onChange: e => this.onChange(e, 'number')
                            })}
                            name={`${name}[number]`}
                            value={value?.number ?? ''}
                            placeholder="1111 2222 3333 4444"
                            maxLength={19}
                            data-1p-ignore={false}
                            id={`${salt}:number`}
                            disabled={!enabled} />
                    </Control>
                </Field>
                <Columns>
                    <Field {...(expiryClassName ? { className: expiryClassName } : null)}>
                        <Label
                            htmlFor={`${salt}:expiry`}
                            required={required || softRequired}>
                            <FormattedMessage
                                id="payment_label_expiry_date"
                                defaultMessage="Expiry date" />
                        </Label>
                        <Control>
                            <Input
                                {...(controlClassName ? { className: controlClassName } : null)}
                                {...getExpiryDateProps({
                                    onInput: e => this.onChange(e, 'expiry'),
                                    onChange: e => this.onChange(e, 'expiry')
                                })}
                                name={`${name}[expiry]`}
                                value={value?.expiry ?? ''}
                                placeholder={formatMessage({
                                    id: 'payment_placeholder_expiry_date',
                                    defaultMessage: 'MM / YY'
                                })}
                                data-1p-ignore={false}
                                id={`${salt}:expiry`}
                                disabled={!enabled} />
                        </Control>
                    </Field>
                    <Field {...(cvcClassName ? { className: cvcClassName } : null)}>
                        <Label
                            htmlFor={`${salt}:cvc`}
                            required={required || softRequired}>
                            <FormattedMessage
                                id="payment_label_cvc"
                                defaultMessage="CVC" />
                        </Label>
                        <Control>
                            <Input
                                {...(controlClassName ? { className: controlClassName } : null)}
                                {...getCVCProps({
                                    onInput: e => this.onChange(e, 'cvc'),
                                    onChange: e => this.onChange(e, 'cvc')
                                })}
                                name={`${name}[cvc]`}
                                value={value?.cvc ?? ''}
                                placeholder="123"
                                data-1p-ignore={false}
                                id={`${salt}:cvc`}
                                disabled={!enabled} />
                        </Control>
                    </Field>
                </Columns>
                <SupportedCardLogos>
                    <SupportedCardLogo size={32} type="visa" />
                    <SupportedCardLogo size={32} type="mastercard" />
                    <SupportedCardLogo size={32} type="amex" />
                </SupportedCardLogos>
            </>
        )
    }
}

const getFieldFromProps = ({ field = {} }) => {
    let {
        value = empty,
        required = false,
        softRequired = false,
        include = 'touched',
        ...rest
    } = field

    if(!value && value !== 0) {
        value = empty
    }

    return {
        value,
        required,
        softRequired,
        include,
        ...rest
    }
}

export default props => {
    const { formatMessage } = useIntl()

    return (
        <PaymentInputsContainer onTouch={() => PubSub.publish('form.field.payment-card.touch')}>
            {utilities => (
                <EditPaymentCard
                    {...props}
                    {...utilities}
                    formatMessage={formatMessage} />
            )}
        </PaymentInputsContainer>
    )
}
import React, { useState, useEffect, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { Columns } from './s'
import Label from 'components/typography/caption'
import Checkbox from 'components/form/input/checkbox'
import { pruneBy } from 'utilities/array'

const ids = (array = []) => array.map(({ id }) => id).join('+')

const ToggleAll = ({ loaded, prepicked, locked, picked, setPicked, method, salt }) => {
    const getUnpickableIdsInLoaded = useCallback(() => {
        const loadedIds = loaded.map(({ id }) => id)

        const unpickables = [...locked]
        if(method === 'add') {
            unpickables.push(...prepicked)
        }

        return pruneBy(unpickables)
            .filter(({ id }) => loadedIds.includes(id))
            .map(({ id }) => id)
    }, [ids(loaded), ids(locked), ids(prepicked), method])

    const [allChecked, setAllChecked] = useState(false)
    const [pickableCount, setPickableCount] = useState(0)
    const [disabled, setDisabled] = useState(loaded.length === getUnpickableIdsInLoaded().length)

    useEffect(() => {
        if(!loaded.length) {
            setAllChecked(false)
            setPickableCount(0)
            setDisabled(false)
        } else {
            const unpickableIds = getUnpickableIdsInLoaded()
            const pickables = loaded.filter(({ id }) => !unpickableIds.includes(id))

            setAllChecked(pickables.every(pickable => picked.find(({ id }) => id === pickable.id)))
            setPickableCount(pickables.length)
            setDisabled(loaded.length === unpickableIds.length)
        }
    }, [ids(loaded), ids(picked)])

    const toggle = useCallback(() => {
        const unpickableIds = getUnpickableIdsInLoaded()

        if(allChecked) {
            setPicked([])
        } else {
            setPicked(loaded.filter(({ id }) => !unpickableIds.includes(id)))
        }
    }, [allChecked, ids(loaded)])

    return (
        <Columns>
            <Label className="compact">
                {!allChecked && (
                    <FormattedMessage
                        id={(pickableCount > 0) ?
                            'action_select_all_count' :
                            'action_select_all'
                        }
                        defaultMessage={(pickableCount > 0) ?
                            'Select all ({count})' :
                            'Select all'
                        }
                        values={{ count: pickableCount }} />
                )}
                {!!allChecked && (
                    <FormattedMessage
                        id={(pickableCount > 0) ?
                            'action_deselect_all_count' :
                            'action_deselect_all'
                        }
                        defaultMessage={(pickableCount > 0) ?
                            'Deselect all ({count})' :
                            'Deselect all'
                        }
                        values={{ count: pickableCount }} />
                )}
            </Label>
            <Checkbox
                id={`${salt}:toggle-all`}
                checked={allChecked}
                autoFocus={false}
                onChange={toggle}
                onClick={e => void e.preventDefault()}
                disabled={disabled}
                key={`${salt}:toggle-all:${allChecked}`} />
        </Columns>
    )
}

export default ToggleAll

import styled, { css } from 'styled-components'
import { flashKeyframes } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Plain } from 'components/button'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'
import CompetenceProfileStatus from 'pages/competence/components/profile-status'

export const profileGrid = css`
    grid-template-columns: minmax(0, 2fr) minmax(0, 2fr) 40px;
    column-gap: 24px;
`

export const CompetenceProfileWrapper = styled(Grid)`
    ${profileGrid}

    padding-block: 16px;
    box-shadow: 0 -1px var(--huma-color-border-default);

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }
`

const profileCellCss = css`
    display: grid;
    grid-template-rows: 24px 16px;
    grid-template-columns: 40px minmax(0, 1fr);
    column-gap: 16px;
    align-items: center;

    min-height: 40px;
    text-align: start;
`

export const ProfileCellClickable = styled(Plain)`
    ${profileCellCss}

    &:not([disabled]) {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
`

export const ProfileCellWrapper = styled.div`
    ${profileCellCss}
`

export const Symbol = styled(SymbolBase)`
    grid-row: 1 / -1;

    ${ancestorHover(ProfileCellClickable)}
`

export const Name = styled(Paragraph)`
    ${ProfileCellClickable}:where(:focus, :hover) & {
        text-decoration: underline;
    }
`

export const Count = styled(CaptionSmall)`
    grid-column: 2;
`

export const StatusCell = styled.div`
    display: flex;
`

export const ProfileStatus = styled(CompetenceProfileStatus)`
    align-self: center;
`

export const ActionsCell = styled.div``
import React, { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import { capitalize } from 'utilities/string'
import {
    CommentWrapper,
    CategoryCell, Category,
    CommentCell,
    ActionsCell
} from './s'
import ContextMenu, { getActionsArray } from 'widgets/context-menu'

const Comment = forwardRef(({ comment, actions }, ref) => {
    const { formatMessage } = useIntl()

    const {
        category,
        comment: text
    } = comment

    const hasActions = !!getActionsArray(actions).length

    return (
        <CommentWrapper ref={ref}>
            <CategoryCell>
                <Category
                    label={formatMessage({
                        id: `noun_${category}`,
                        defaultMessage: capitalize(category)
                    })}
                    category={category} />
            </CategoryCell>
            <CommentCell className="compact">{text}</CommentCell>
            {!!hasActions && (
                <ActionsCell>
                    <ContextMenu
                        salt={comment.id}
                        context={comment}
                        actions={actions} />
                </ActionsCell>
            )}
        </CommentWrapper>
    )
})

export default Comment

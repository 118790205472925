import React, { useState, Children, cloneElement, isValidElement } from 'react'
import { isStyledComponent } from 'styled-components'
import { cls } from 'utilities/dom'
import {
    modalify, Wrapper, Veil,
    Modal, Container, ScrollableContainer
} from 'utilities/modal'
import { ModalErrorBoundary } from 'components/error-boundary'
import { ModalHeaderWrapper, CloseButton, Big, Medium, Small, Caption } from './s'

const GenericCentered = ({ salt, className, children, ...props }) => {
    const {
        show = false,
        outer = true,
        enrichChildren = true,
        dismiss,
        ...modal
    } = props

    const [showing, setShowing] = useState(show)
    const [options, setOptions] = useState({})

    const animate = show ? 'in' : 'out'

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    {!!outer && <Veil animate={animate} />}
                    <Modal
                        {...modal}
                        {...options}
                        dismiss={dismiss}
                        key={`modal:generic:${salt}`}>
                        <ModalErrorBoundary
                            dismiss={dismiss}
                            key={`modal:${salt}`}>
                            <Container
                                {...(className ? { className } : null)}
                                animate={animate}
                                afterOpen={() => setShowing(true)}
                                afterClose={() => setShowing(false)}>
                                {Children.map(children, child => {
                                    if(enrichChildren && isValidElement(child) && !isStyledComponent(child)) {
                                        return cloneElement(child, {
                                            modal: {
                                                showing,
                                                setOptions: options => setOptions(previousOptions => ({
                                                    ...previousOptions,
                                                    ...options
                                                })),
                                                dismiss
                                            }
                                        })
                                    }

                                    return child
                                })}
                            </Container>
                        </ModalErrorBoundary>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

const GenericScrollable = ({ children, className, salt, ...props }) => {
    const {
        show = false,
        outer = true,
        fixed = false,
        enrichChildren = true,
        dismiss,
        ...modal
    } = props

    const [showing, setShowing] = useState(show)
    const [options, setOptions] = useState({})

    const animate = show ? 'in' : 'out'

    className = cls([
        className,
        fixed && 'fixed'
    ])

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    {!!outer && <Veil animate={animate} />}
                    <Modal
                        {...modal}
                        {...options}
                        {...(fixed ? { className: 'fixed' } : null)}
                        dismiss={dismiss}
                        key={`modal:generic:${salt}`}>
                        <ModalErrorBoundary
                            dismiss={dismiss}
                            key={`modal:${salt}`}>
                            <ScrollableContainer
                                {...(className ? { className } : null)}
                                animate={animate}
                                afterOpen={() => setShowing(true)}
                                afterClose={() => setShowing(false)}>
                                {Children.map(children, child => {
                                    if(enrichChildren && isValidElement(child) && !isStyledComponent(child)) {
                                        return cloneElement(child, {
                                            modal: {
                                                showing,
                                                setOptions: options => setOptions(previousOptions => ({
                                                    ...previousOptions,
                                                    ...options
                                                })),
                                                dismiss
                                            }
                                        })
                                    }

                                    return child
                                })}
                            </ScrollableContainer>
                        </ModalErrorBoundary>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

export const ModalHeader = ({
    heading, caption,
    dismiss,
    medium = false, small = false,
    compact = false, flipped = false, closeButtonOffset = 24,
    headingClassName
}) => {
    const Heading = medium ?
        Medium :
        small ?
            Small :
            Big

    const wrapperClassName = cls([
        !!flipped && 'flipped',
        (!!compact || !!caption) && 'compact'
    ])

    return (
        <ModalHeaderWrapper {...(wrapperClassName ? { className: wrapperClassName } : null)}>
            {!!dismiss && (
                <CloseButton
                    onClick={dismiss}
                    className="close"
                    $offset={closeButtonOffset} />
            )}
            <Heading {...(headingClassName ? { className: headingClassName } : null)}>
                {heading}
            </Heading>
            {!!caption && (
                <Caption className={cls(['caption', compact && 'compact'])}>
                    {caption}
                </Caption>
            )}
        </ModalHeaderWrapper>
    )
}

export default GenericCentered
export const Scrollable = GenericScrollable
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useContentAssistant } from 'contexts/content-assistant'
import { Footer, Actions } from './s'
import { Chip } from 'components/content-assistant/s'
import ContextMenu from 'widgets/context-menu'
import CustomPrompt from './custom-prompt'
import { AlignLeft, Smile, Mic, Globe, Check } from 'styled-icons/feather'
import { AlignLeftShort } from 'components/feather'

const AssistantPanelFooter = ({ addPrompt, cleanPrompt, currentLocale, setCurrentLocale, upgradable, salt }) => {
    const {
        formatMessage,
        formatDisplayName
    } = useIntl()

    const {
        settings,
        messages,

        loading
    } = useContentAssistant()

    const chipProps = {
        disabled: loading || !messages?.length || messages?.every(({ error }) => !!error) || upgradable
    }

    return (
        <Footer>
            {!!settings?.actions?.length && (
                <Actions>
                    {settings.actions.map(settingAction => {
                        const {
                            icon,
                            prompt,
                            actions = [],
                            values = [],
                            subKey,
                            key
                        } = settingAction

                        const Icon = iconToFeatherMap[icon] ?? null

                        if(!!actions?.length) {
                            const contextActions = actions.map(action => {
                                const Icon = iconToFeatherMap[action.icon]

                                return () => ({
                                    salt: `${salt}:${action.key}`,
                                    icon: Icon ? <Icon size={24} /> : null,
                                    label: formatMessage({ id: action.key }),
                                    effect: 'neutral',
                                    onClick: () => {
                                        addPrompt({
                                            prompt: cleanPrompt({ prompt: action.prompt }),
                                            displayPrompt: formatMessage({ id: action.key })
                                        })
                                    }
                                })
                            })

                            if(!contextActions?.length) {
                                return null
                            }

                            return (
                                <ContextMenu
                                    salt={salt}
                                    actions={contextActions}
                                    trigger={(
                                        <Chip {...chipProps}>
                                            {!!Icon && <Icon size={16} />}
                                            <FormattedMessage id={key} />
                                        </Chip>
                                    )}
                                    placement="top-end"
                                    key={`${salt}:setting-action:${key}`} />
                            )
                        }

                        if(!!values?.length) {
                            const contextActions = values.map(value => () => ({
                                salt: `${salt}:${key}:${value}`,
                                icon: Icon ? <Icon size={24} /> : null,
                                label: formatMessage({ id: subKey }, { language: formatDisplayName(value, { type: 'language' }) }),
                                effect: 'neutral',
                                onClick: () => {
                                    addPrompt({
                                        prompt: cleanPrompt({ prompt, key, value }),
                                        displayPrompt: formatMessage({ id: subKey }, {
                                            language: formatDisplayName(value, { type: 'language' })
                                        }),
                                        fromLocale: currentLocale
                                    })

                                    setCurrentLocale(value)
                                }
                            }))

                            if(!contextActions?.length) {
                                return null
                            }

                            return (
                                <ContextMenu
                                    salt={salt}
                                    actions={contextActions}
                                    trigger={(
                                        <Chip {...chipProps}>
                                            {!!Icon && <Icon size={16} />}
                                            <FormattedMessage id={key} />
                                        </Chip>
                                    )}
                                    placement="top-end"
                                    key={`${salt}:setting-action:${key}`} />
                            )
                        }

                        return (
                            <Chip
                                {...chipProps}
                                onClick={() => {
                                    if(prompt) {
                                        addPrompt({
                                            prompt: cleanPrompt({ prompt }),
                                            displayPrompt: formatMessage({ id: key })
                                        })
                                    }
                                }}
                                key={`${salt}:setting-action:${key}`}>
                                {!!Icon && <Icon size={16} />}
                                <FormattedMessage id={key} />
                            </Chip>
                        )
                    })}
                </Actions>
            )}
            <CustomPrompt
                addPrompt={addPrompt}
                upgradable={upgradable}
                salt={salt} />
        </Footer>
    )
}

const iconToFeatherMap = {
    'align-left-short': props => <AlignLeftShort {...props} />,
    'align-left': props => <AlignLeft {...props} />,
    'smile': props => <Smile {...props} />,
    'mic': props => <Mic {...props} />,
    'globe': props => <Globe {...props} />,
    'check': props => <Check {...props} />
}

export default AssistantPanelFooter

import React from 'react'
import { FlexChildShrink } from 'components/flex'
import ActionColumns from 'components/form/actions'
import { Plain, Button } from 'components/button'

const Actions = ({ cancel, done, dismiss, changed, unsettable, emoji }) => (
    <FlexChildShrink>
        <ActionColumns>
            <Plain
                className={cancel.effect}
                onClick={cancel.onClick}>
                {cancel.label}
            </Plain>
            <Button
                className={done.effect}
                onClick={() => {
                    done.onClick()
                    dismiss()
                }}
                disabled={!changed || (!unsettable && !emoji)}>
                {done.label}
            </Button>
        </ActionColumns>
    </FlexChildShrink>
)

export default Actions
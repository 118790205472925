import React from 'react'
import { FormattedMessage } from 'react-intl'
import { format } from 'date-fns'
import { compact } from 'utilities/array'
import { getFullName, getEmailAddress } from 'utilities/person'
import { ListItem, IconWrap } from './s'
import { Calendar, ArrowUpRight } from 'styled-icons/feather'
import Paragraph from 'components/typography/paragraph'

const Desktop = ({ start, end, title, description = '', organizer, attendees = [] }) => {
    const link = compact([
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `DTSTART:${format(start, 'yyyyMMdd')}`,
        `DTEND:${format(end, 'yyyyMMdd')}`,
        `SUMMARY:${title}`,
        `DESCRIPTION:${description.replaceAll(/\n/g, '\\n')}`,
        organizer && `ORGANIZER;CN="${getFullName(organizer)}":mailto:${getEmailAddress(organizer)}`,
        attendees?.map(attendee =>
            `ATTENDEE;CN="${getFullName(attendee)}";CUTYPE=INDIVIDUAL;PARTSTAT=NEEDS-ACTION;ROLE=REQ-PARTICIPANT;RSVP=TRUE:mailto:${getEmailAddress(attendee)}`
        ).join('\n'),
        'END:VEVENT',
        'END:VCALENDAR',
    ])

    return (
        <ListItem
            href={encodeURI(`data:text/calendar;charset=utf8,${link.join('\n')}`)}
            target="_blank">
            <IconWrap>
                <Calendar size={24} />
            </IconWrap>
            <Paragraph className="compact">
                <FormattedMessage
                    id="noun_desktop"
                    defaultMessage="Desktop" />
            </Paragraph>
            <IconWrap>
                <ArrowUpRight size={24} />
            </IconWrap>
        </ListItem>
    )
}

export default Desktop
import React from 'react'
import { useSalaryRevisionGroups } from 'contexts/salary-revision-groups'
import Group from './group'

const SidePanelRevisionGroups = ({ salt }) => {
    const {
        groups = [],
        hasFetched
    } = useSalaryRevisionGroups()

    if(!hasFetched || !groups.length) {
        return null
    }

    return (
        <div>
            {groups.map(group => (
                <Group
                    group={group}
                    key={`${salt}:${group.id}`} />
            ))}
        </div>
    )
}

export default SidePanelRevisionGroups
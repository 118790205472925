import React, { Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import paths from 'app/paths'
import { Container as Modal } from 'utilities/modal'
import { Title } from 'components/typography/heading'
import { ModalHeader } from 'modals/generic'
import Message from 'components/message'
import { Button, Plain } from 'components/button'
import { RefreshCw } from 'styled-icons/feather'
import Actions from 'components/form/actions'

const ErrorContent = ({ context, message, dismiss, reset }) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()

    const [Wrapper, wrapperProps = null] = (context === 'modal') ?
        [Modal, { animate: 'in' }] :
        [Fragment]

    return (
        <Wrapper {...wrapperProps}>
            {['page', 'component'].includes(context) && (
                <Title>
                    <FormattedMessage
                        id="error_heading"
                        defaultMessage="An error occurred" />
                </Title>
            )}
            {(context === 'modal') && (
                <ModalHeader
                    heading={formatMessage({
                        id: 'error_heading',
                        defaultMessage: 'An error occurred'
                    })}
                    {...(!!dismiss ? { dismiss } : null)} />

            )}
            {!!message && (
                <Message
                    type="error"
                    className="compact"
                    message={message} />
            )}
            {['page', 'component'].includes(context) && (
                <Actions>
                    {(context === 'page') && (
                        <Button
                            className="neutral"
                            icon={RefreshCw}
                            onClick={() => global.location.reload()}>
                            <FormattedMessage
                                id="action_reload"
                                defaultMessage="Reload" />
                        </Button>
                    )}
                    {(context === 'component' && !!reset) && (
                        <Button
                            className="neutral"
                            icon={RefreshCw}
                            onClick={reset}>
                            <FormattedMessage
                                id="action_reset"
                                defaultMessage="Reset" />
                        </Button>
                    )}
                    {(context === 'page') && (
                        <Plain onClick={() => navigate(paths.root, { replace: true })}>
                            <FormattedMessage
                                id="action_go_dashboard"
                                defaultMessage="Go to the dashboard" />
                        </Plain>
                    )}
                </Actions>
            )}
        </Wrapper>
    )
}

export default ErrorContent
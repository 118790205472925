import React, { forwardRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { withTheme, ThemeProvider } from 'styled-components'
import { getNewsArticleUrl } from 'utilities/url'
import {
    ArticleColumns,
    Link,
    ArticleImage,
    ArticleSummary, StatusLabel, Avatar, Title,
    Meta, DateTime, SharedTo, ReadPercentage
} from './s'
import { FlexChildShrink } from 'components/flex'
import RelativeTime from 'components/time-relative'
import { BarChart } from 'styled-icons/feather'

const Article = forwardRef(({ article, theme }, ref) => {
    const { formatMessage } = useIntl()

    const {
        status,
        title,
        featuredImage,
        publishedDate,
        author,
        shares = [],
        statistics: s
    } = article

    theme = {
        ...theme,
        image: !!featuredImage
    }

    return (
        <ThemeProvider theme={theme}>
            <ArticleColumns
                aria-live="polite"
                ref={ref}>
                <ArticleSummary>
                    {(status === 'draft') &&
                        <StatusLabel className="passive">
                            <FormattedMessage
                                id="article_status_draft"
                                defaultMessage="Draft" />
                        </StatusLabel>
                    }
                    <Avatar who={author} />
                    <Title as="h2" itemProp="title">
                        <Link to={getNewsArticleUrl(article)}>
                            {title}
                        </Link>
                    </Title>
                    <Meta>
                        {!!publishedDate && (
                            <FlexChildShrink>
                                <DateTime
                                    dateTime={publishedDate}
                                    itemProp="pubdate">
                                    <RelativeTime date={publishedDate} />
                                </DateTime>
                            </FlexChildShrink>
                        )}
                        <SharedTo
                            units={shares}
                            publicWhenEmpty={true}
                            inline={true}
                            max={1} />
                        {!!s && (
                            <FlexChildShrink>
                                <ReadPercentage className="compact">
                                    <BarChart size={16} />
                                    <FormattedMessage
                                        id="news_statistics_read_percentage"
                                        defaultMessage="{percent} have read"
                                        values={{
                                            percent: formatMessage({
                                                id: 'value_percent',
                                                defaultMessage: '{percent} %'
                                            }, { percent: Math.round(s.audienceReadCount / s.audienceCount * 100) })
                                        }} />
                                </ReadPercentage>
                            </FlexChildShrink>
                        )}
                    </Meta>
                </ArticleSummary>
                {!!featuredImage && (
                    <ArticleImage>
                        <img
                            src={featuredImage.original.url}
                            itemProp="image" />
                    </ArticleImage>
                )}
            </ArticleColumns>
        </ThemeProvider>
    )
})

export default withTheme(Article)

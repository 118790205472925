import styled, { css } from 'styled-components'
import {
    svgStroke, interpointDivider, flashKeyframes,
    belowPhone
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { Plain } from 'components/button'
import NoteBase from 'components/note'
import Paragraph from 'components/typography/paragraph'
import { InlineMessage as InlineMessageBase } from 'components/message'
import ExpiryStatusBase from 'components/expiry-status'
import { ancestorHover as avatarAncestorHover } from 'components/avatar/s'

export const CompetenceRecordWrapper = styled(Grid)`
    position: relative;

    grid-template-columns: minmax(0, 2fr) repeat(2, minmax(0, 1fr)) 40px;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    row-gap: 16px;
    column-gap: 24px;

    width: 100%;
    padding: 24px 0;
    box-shadow: 0 -1px var(--huma-color-border-default);

    ${belowPhone`
        grid-template: repeat(3, auto) / minmax(0, 1fr) 40px;
        grid-gap: 8px 16px;
        padding: 16px 0;
    `}

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }

    svg {
        ${svgStroke()}
    }
`

const personCellCss = css`
    display: grid;
    align-items: center;

    height: 44px;

    text-align: start;
`

export const PersonCellClickable = styled(Plain)`
    ${personCellCss}
    ${avatarAncestorHover()}

    justify-self: start;

    &:not([disabled]) {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    ${belowPhone`
        grid-row: 1 / 2;
    `}
`

export const PersonCellWrapper = styled.div`
    ${personCellCss}
`

const cellCss = css`
    display: grid;
    align-items: start;
    padding: 8px 0;

    font-size: 14px;
    line-height: 20px;
`

export const Cell = styled.div`
    ${cellCss}

    ${belowPhone`
        grid-column: 1 / 2;

        padding: 0 0 0 56px;
    `}
`

export const DateCell = styled.div`
    ${cellCss}

    color: var(--huma-color-foreground-subtle);

    ${belowPhone`
        grid-column: 1 / 2;
        grid-template-columns: auto 1fr;
        align-items: center;

        padding: 0 0 0 56px;
    `}
`

export const PeriodCell = styled.div`
    ${cellCss}

    align-items: center;

    ${belowPhone`
        grid-column: 1 / 2;

        padding: 0 0 0 56px;
    `}
`

export const StatusCell = styled.div`
    ${cellCss}

    & > :last-child {
        margin-bottom: 0;
    }

    ${belowPhone`
        grid-column: 1 / 2;

        padding: 0 0 0 56px;

        &:empty {
            display: none;
        }
    `}
`

export const LevelCell = styled.div`
    ${cellCss}

    align-items: center;

    ${belowPhone`
        grid-column: 1 / 2;

        padding: 0 0 0 56px;
    `}
`

export const LevelValue = styled(Paragraph)`
    ${interpointDivider()}
`

export const InlineMessage = styled(InlineMessageBase)`
    & p {
        line-height: 20px;
    }
`

export const ExpiryStatus = styled(ExpiryStatusBase)`
    & p {
        line-height: 20px;
    }
`

export const ActionsCell = styled.div`
    justify-content: center;

    ${belowPhone`
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    `}

    svg {
        ${svgStroke()}
    }
`

export const Note = styled(NoteBase)`
    grid-row: 2;
    grid-column: 1;

    margin-inline-start: 56px;
`
import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { mergeRefs } from 'react-merge-refs'
import { useI18n } from 'contexts/i18n'
import { useFlash } from 'hooks/flash'
import { getMeetingRoundUrl, getPeopleProfileUrl } from 'utilities/url'
import { getFullName } from 'utilities/person'
import { safeFormat } from 'utilities/date-time'
import {
    RoundItem,
    MeetingCell, RoundLink, Symbol, Title, Created,
    ProgressCell,
    DueDateCell,
    ArrowCell
} from './s'
import { fallbackSymbol } from 'pages/meetings'
import Link from 'components/link'
import Progress from 'components/progress'
import MobileLabel from 'components/typography/mobile-label'
import { ArrowRight } from 'styled-icons/feather'

const MeetingRound = forwardRef(({ round, flash }, ref) => {
    const { dateLocale: locale } = useI18n()

    const flasher = useFlash(flash)

    const {
        id,
        symbol,
        title,
        count,
        closedCount,
        dueAt,
        createdBy,
        createdAt
    } = round ?? {}

    return (
        <RoundItem ref={mergeRefs([ref, flasher])}>
            <MeetingCell>
                <RoundLink to={getMeetingRoundUrl({ id })}>
                    <Symbol
                        symbol={symbol ?? fallbackSymbol}
                        className="hover-effect" />
                    <Title className="compact">{title}</Title>
                </RoundLink>
                <Created className="compact">
                    <FormattedMessage
                        id={createdBy ? 'meta_started_at_by' : 'meta_started_at'}
                        defaultMessage={createdBy ? 'Started {date} by {user}' : 'Started {date}'}
                        values={{
                            date: safeFormat(createdAt, 'PPP', { locale }),
                            user: (
                                <Link
                                    to={getPeopleProfileUrl(createdBy)}
                                    target="_blank">
                                    {getFullName(createdBy)}
                                </Link>
                            )
                        }} />
                </Created>
            </MeetingCell>
            <ProgressCell>
                <Progress
                    completed={closedCount}
                    total={count}
                    className="compact" />
            </ProgressCell>
            <DueDateCell>
                <MobileLabel>
                    <FormattedMessage
                        id="noun_due_date"
                        defaultMessage="Due date" />
                </MobileLabel>
                <span>{safeFormat(dueAt, 'PPP', { locale })}</span>
            </DueDateCell>
            <ArrowCell>
                <RoundLink to={getMeetingRoundUrl({ id })}>
                    <ArrowRight size={24} />
                </RoundLink>
            </ArrowCell>
        </RoundItem>
    )
})

export default MeetingRound

import React from 'react'
import { ContainerQuery, Layout, Item, Heading, Value, Label, Content } from './s'
import { compact } from 'utilities/array'
import { cls } from 'utilities/dom'

const StatisticsGroup = ({ items, salt, ...props }) => (
    <ContainerQuery>
        <Layout {...props}>
            {compact(items ?? []).map(({ value, label, content, className }, index) => {
                className = cls(className)

                return (
                    <Item
                        {...(className ? { className } : null)}
                        key={`${salt}:statistic:${index}:${label}:${value}`}>
                        {((!!value || value === 0) || !!label) && (
                            <Heading>
                                {(!!value || value === 0) && <Value>{value}</Value>}
                                {!!label && <Label className="compact">{label}</Label>}
                            </Heading>
                        )}
                        {!!content && (
                            <Content>
                                {content}
                            </Content>
                        )}
                    </Item>
                )
            })}
        </Layout>
    </ContainerQuery>
)

export default StatisticsGroup
// export const format = ({ name, ...item }) => ({
//     ...item,
//     name: name.replace(/-/g, '_')
// })

export const format = item => {
    if(item.name.startsWith('flag-')) {
        item.name = item.name.replace(/-/g, '_')
    }

    if(item.name.startsWith('male_')) {
        item.name = item.name.replace(/^male/g, 'man')
    }

    if(item.name.startsWith('female_')) {
        item.name = item.name.replace(/^female/g, 'woman')
    }

    return item
}
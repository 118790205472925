import { useState } from 'react'
import { useI18n } from 'contexts/i18n'
import { useInterval } from 'hooks/interval'
import { useIntl } from 'react-intl'
import { isofy } from 'utilities/date-time'
import { differenceInSeconds, formatDistanceToNow, format as formatDate } from 'date-fns'
import { capitalize } from 'utilities/string'

export default ({ date, addSuffix = true, format = 'PPP', short = true }) => {
    const {
        dateLocale,
        locale
    } = useI18n()

    const { formatMessage } = useIntl()

    const [now, setNow] = useState(new Date)
    useInterval(() => setNow(new Date), 15000)

    if(!date) {
        return null
    }

    date = isofy(date)

    const difference = differenceInSeconds(now, date)
    let formatted = formatDistanceToNow(date, { locale: dateLocale, addSuffix })

    if(difference < 90) {
        formatted = formatMessage({
            id: 'time_justnow',
            defaultMessage: 'Just now'
        })
    }

    // date-fns’ breakpoint for ‘1 day’
    if(difference > (60 * 60 * 42)) {
        formatted = formatDate(date, format, { locale: dateLocale })
    } else if(short) {
        const aboutWord = about[locale]
        const aboutRegex = new RegExp(`^${aboutWord}\\s`, 'i')

        const withoutAbout = formatted
            .replace(aboutRegex, '')
            .trim()

        formatted = `~ ${withoutAbout}`
    }

    return capitalize(formatted)
}

const about = {
    en: 'about',
    nb: 'omtrent',
    sv: 'ungefär',
    fi: 'noin',
    da: 'cirka',
    nl: 'ongeveer',
    pl: 'około',
    ee: 'umbes',
    lv: 'pirms apmēram'
}
import React, { Component, createRef } from 'react'
import { FormContext } from 'components/form/controller'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { Field, Label, Control } from 'components/form/field/s'
import Input from './input'

const empty = ''

export default class EditVerificationCode extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        this.input = createRef()

        const {
            value,
            required,
            include
        } = getFieldFromProps(props)

        this.state = { value }

        context.registerField(this.props.name, {
            empty,
            required,
            include,
            unset: this.unset,

            validator: value => {
                if(!value) {
                    return false
                }

                return value.length === 6
            }
        })
    }

    componentDidMount() {
        this.context.triggerChange(this.props.name, { touched: false })
    }

    componentDidUpdate = ({ name }, { value }) => {
        if(value !== this.state.value) {
            this.context.triggerChange(name)
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name)
    }

    onChange = value => {
        const { name, onChange } = this.props

        this.setState({ value })
        onChange?.({ [name]: value })
    }

    unset = () => this.setState({ value: empty })

    render() {
        const { value } = this.state

        const {
            className,
            controlProps = {},
            label,
            name,
            enabled = true,
            loading = false,
            display,
            ...props
        } = this.props

        const salt = `${props.salt}:${name}`

        const touched = this.context.touched.includes(name)
        const error = (name in this.context.errors) && touched

        const { required } = getFieldFromProps(this.props)

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            // error && 'error'
        ])

        const controlClassName = cls([
            controlProps.className,
            display
        ])

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required}>
                        {label}
                    </Label>
                )}
                <Control>
                    <input
                        name={name}
                        value={value}
                        type="hidden" />
                    <Input
                        {...controlProps}
                        {...(controlClassName ? { className: controlClassName } : null)}
                        {...omit(props, 'field')}
                        {...{ value, salt, id: salt }}
                        onChange={this.onChange}
                        disabled={!enabled}
                        ref={this.input} />
                </Control>
            </Field>
        )
    }
}

const getFieldFromProps = ({ field = {} }) => {
    let {
        value = empty,
        required = true,
        include = 'touched',
        ...rest
    } = field

    return {
        value,
        required,
        include,
        ...rest
    }
}

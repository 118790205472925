import styled, { css } from 'styled-components'
import { textCss } from 'components/form/input/text'

export const textareaCss = css`
    ${textCss}
    display: block;
    min-width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 88px;
    max-height: 184px;
    padding-block: 8px 7px;
    overflow-y: auto;
    line-height: 24px;
    resize: vertical;

    -webkit-appearance: none;

    .burj & {
        max-height: none;
    }

    &.huge {
        min-height: 64px;
    }

    &.growable {
        resize: none;
        overflow-y: hidden;
        line-height: 1.5;
    }
`

/* Credit to @shshaw: https://codepen.io/shshaw/pen/bGNJJBE */
export const InputSizer = styled.div`
    position: relative;

    display: inline-grid;
    align-items: stretch;
    vertical-align: top;

    width: 100%;

    &::after {
        content: attr(data-value) ' ';
        visibility: hidden;
        white-space: pre-wrap;

        min-height: 88px;
        padding: 9px 14px 8px; // Compensate for textarea's border on padding

        line-height: 24px;
    }

    &::after,
    input,
    textarea {
        resize: none;

        grid-area: 2 / 1;
    }
`

export default styled.textarea.attrs(({
    tabIndex = 0,
    spellCheck = false,
    autoFocus = false,
    ...attributes
}) => ({
    tabIndex,
    spellCheck,
    autoFocus,
    ...attributes
}))`
    ${textareaCss}
`

import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import {
    svgStroke,
    belowTablet
} from 'utilities/styled'
import { flexChildCss, flexChildShrinkCss } from 'components/flex'
import {
    Field as FieldBase,
    Label as LabelBase,
    Control as ControlBase
} from 'components/form/field/s'
import CheckboxBase, { CheckboxInput } from 'components/form/input/checkbox'
import { loaderCss } from 'components/loader'

export const Field = styled(FieldBase)`
    display: flex;

    *:where(form:not(.horizontal)) &,
    &.vertical {
        column-gap: 16px;
    }

    ${({ $direction }) => $direction === 'rtl' && css`
        *:where(form.horizontal) &,
        &.horizontal {
            flex-direction: row-reverse;
            column-gap: 24px;
        }
    `}
`

export const Label = styled(LabelBase)`
    ${Field}.disabled & {
        cursor: not-allowed;
    }

    ${Field}.brave & {
        color: var(--huma-color-foreground-default);
    }

    *:where(form.horizontal) &.for-switch,
    ${Field}.horizontal &.for-switch,
    &.horizontal.for-switch {
        min-height: auto;
        padding-block: 4px;
        padding-inline-end: 0;

        ${belowTablet`
            padding-block: 4px;
            margin-bottom: 0;
        `}
    }

    form.horizontal ${Field}.horizontal &.for-switch,
    form.horizontal &.horizontal.for-switch {
        padding-inline-end: 40px;
    }

    *:where(form:not(.horizontal)) ${Field}.horizontal &.for-switch {
        width: auto;
    }

    *:where(form:not(.horizontal)) &,
    ${Field}.vertical &,
    &.vertical {
        ${flexChildCss}
        width: auto;
        height: auto;

        &.for-switch {
            padding-block: 4px;
            margin-bottom: 0;
        }
    }
`

export const Control = styled(ControlBase)`
    flex: 0 0 auto;

    ${Field}:not(.suffixed).loading &.switch:after {
        display: none;
    }
`

export const Checkbox = styled(CheckboxBase)`
    ${Label} + ${Control}.checkbox & {
        margin-top: 8px;
    }
`

export const Switch = styled.label`
    ${flexChildShrinkCss}
    cursor: pointer;
    position: relative;

    display: block;

    border-radius: 8px;
    width: 48px;
    height: 32px;
    padding: 0 2px;
    box-shadow: none;

    transition:
        background-color .2s ease-in-out,
        box-shadow .1s ease-in-out;

    &.disabled-off {
        background-color: var(--huma-color-surface-disabled);
    }

    &.disabled-on {
        background-color: var(--huma-color-surface-constructive-bold-selected-disabled);
    }

    &.off {
        background-color: var(--huma-color-surface-subtle);
    }

    &.on {
        background-color: var(--huma-color-surface-constructive-bold);
    }

    ${Field}.disabled & {
        cursor: not-allowed;
    }

    /* Hover */
    ${Field}:not(.disabled):has(${CheckboxInput}:checked) ${Label}:hover ~ ${Control} &,
    ${Field}:not(.disabled):has(${CheckboxInput}:checked) &:hover {
        background-color: var(--huma-color-surface-constructive-bold-hover) !important;
    }

    ${Field}:not(.disabled):has(${CheckboxInput}:not(:checked)) ${Label}:hover ~ ${Control} &,
    ${Field}:not(.disabled):has(${CheckboxInput}:not(:checked)) &:hover {
        background-color: var(--huma-color-surface-minimal-hover) !important;
    }

    /* Focus */
    ${Field}:not(.disabled) &:has(${CheckboxInput}:focus) {
        box-shadow:
            0 0 0 1px var(--huma-color-surface-default),
            0 0 0 3px var(--huma-color-border-constructive-focus);
    }
`

export const State = styled.span`
    pointer-events: none;
    position: absolute;
    top: 2px;
    display: grid;
    place-items: center;

    border-radius: 6px;
    width: 28px;
    height: 28px;
    box-shadow: var(--huma-shadow-lowest);

    transition: all .2s cubic-bezier(0.22, 1.02, 0.25, 1);

    ${Switch}.disabled-off & {
        border: 1px solid var(--huma-color-border-default);
        background-color: var(--huma-color-surface-disabled);

        transform: translateX(0);

        color: var(--huma-color-foreground-minimal);
    }

    ${Switch}.disabled-on & {
        background-color: var(--huma-color-surface-constructive-selected-disabled);

        transform: translateX(16px);

        color: var(--huma-color-foreground-constructive-disabled);
    }

    ${Switch}.off & {
        background-color: var(--huma-color-surface-default);

        transform: translateX(0);
    }

    ${Switch}.on & {
        background-color: var(--huma-color-surface-default);

        color: var(--huma-color-foreground-constructive-bold);

        transform: translateX(16px);
    }

    ${Field}:not(.disabled) ${Switch}.on:hover & {
        color: var(--huma-color-foreground-constructive-bold-active);
    }

    svg {
        ${svgStroke()}
    }
`

export const Loader = styled(motion.span).attrs(({ initial = 'off' }) => ({
    variants: {
        off: {
            left: '16px'
        },
        on: {
            left: '32px'
        },
        transition: {
            type: 'spring',
            mass: 0.25,
            stiffness: 200,
            velocity: 4,
            damping: 50
        }
    },
    initial
}))`
    ${loaderCss}

    --__huma-component-loader-color: var(--huma-color-surface-constructive-bold);

    top: 16px;

    width: 20px;
`
import React from 'react'
import SmartEntityProvider from 'contexts/smart-entity'
import { BlobProvider, Document, Page, View, Image, Text } from '@react-pdf/renderer'
import { styles } from './s'
import Content from './content'

const Render = ({ content, interpolate, getVariableData, frontPage = null, logo = null, id, salt }) => content.map((section, index) => {
    if(typeof interpolate === 'function') {
        section.content = interpolate(section.content).content
    }

    return (
        <Page
            size="A4"
            style={styles.page}
            key={`${salt}:${id}:${index}`}>
            {(logo && !frontPage) && (
                <View style={styles.logoWrapper}>
                    <Image
                        style={styles.logo}
                        src={logo['4:1-medium'].url} />
                </View>
            )}
            <Content
                {...section}
                getVariableData={getVariableData} />
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed={true} />
        </Page>
    )
})

const PDFContent = props => (
    <Document style={{ marginVertical: 20 }}>
        {props?.frontPage}
        <Render {...props} />
    </Document>
)

export const SmartProvidedPDFContent = ({ id, resolve, reject, ...props }) => (
    <SmartEntityProvider
        id={id}
        context="document"
        mode="fill">
        {({ entity, instated, interpolate, getVariableData }) => {
            if(!instated) {
                return null
            }

            return (
                <BlobProvider document={(
                    <PDFContent
                        {...props}
                        {...entity}
                        interpolate={interpolate}
                        getVariableData={getVariableData}
                        id={id}
                        salt="smart" />
                )}>
                    {({ blob, error }) => {
                        if(blob) {
                            resolve(blob)
                        }

                        if(error) {
                            reject()
                        }

                        return null
                    }}
                </BlobProvider>
            )
        }}
    </SmartEntityProvider>
)

export default PDFContent
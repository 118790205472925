import styled, { css } from 'styled-components'

export const listCss = css`
    position: relative;
    list-style: none;

    padding: 0 0 0 40px;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--huma-color-foreground-default);

    &:not(.compact) {
        margin: 0 0 24px;
    }

    > li:empty {
        height: 24px;
    }
`

export const listSmallCss = css`
    ${listCss}

    font-size: 14px;
    line-height: 20px;

    &:not(.compact) {
        margin: 0 0 16px;
    }

    > li:empty {
        height: 20px;
    }
`

export const unorderedCss = css`
    & > li:before {
        content: "\u2022";
        font-size: 32px;
    }
`

export const orderedCss = css`
    counter-reset: list;

    & > li:before {
        content: counter(list) ".";
        -webkit-font-feature-settings: "liga" on, "lnum" on;
        -moz-font-feature-settings: "liga" on, "lnum" on;
        font-feature-settings: "liga" on, "lnum" on;
        counter-increment: list;
    }
`

export const unorderedListCss = css`
    ${listCss}
    ${unorderedCss}
`

export const unorderedListSmallCss = css`
    ${listSmallCss}
    ${unorderedCss}

`

export const NeutralUnorderedList = styled.ul`
    ${listCss}
    padding: 0;
`

export const NeutralUnorderedListSmall = styled.ul`
    ${listSmallCss}
    padding: 0;
`

export const UnorderedList = styled.ul`
    ${unorderedListCss}
`

export const UnorderedListSmall = styled.ul`
    ${unorderedListSmallCss}
`

export const orderedListCss = css`
    ${listCss}
    ${orderedCss}
`

export const orderedListSmallCss = css`
    ${listSmallCss}
    ${orderedCss}
`

export const NeutralOrderedList = styled.ol`
    ${listCss}
    padding: 0;
`

export const NeutralOrderedListSmall = styled.ol`
    ${listSmallCss}
    padding: 0;
`

export const OrderedList = styled.ol`
    ${orderedListCss}
`

export const OrderedListSmall = styled.ol`
    ${orderedListSmallCss}
`

export const listItemCss = css`
    padding: 4px 0;

    &::before {
        position: absolute;
        left: 0;
        display: inline-block;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        text-align: end;
    }
`

export const ListItem = styled.li`
    ${listItemCss}
`
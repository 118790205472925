import React, { Fragment } from 'react'
import { useIntl } from 'react-intl'

const WordList = ({ words = [], values = [], join = 'and', wordRenderer = null }) => {
    const { locale } = useIntl()

    if(!words?.length) {
        return null
    }

    if(typeof words === 'string') {
        words = words.split(',')
    }

    if(words?.length === 1) {
        if(wordRenderer) {
            return wordRenderer(words[0], values[0])
        }

        return words[0]
    }

    let formattedList = new Intl.ListFormat([locale, 'en'], {
        type: joinToType(join) ?? 'unit'
    }).format(words)

    if(wordRenderer) {
        const parts = words.reduce((accumulator, word) => {
            const regex = new RegExp(`${word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}(.*)`, 's')
            const pair = accumulator.remainder.split(regex)

            return {
                pairs: [
                    ...accumulator.pairs,
                    pair
                ],
                remainder: pair[1]
            }
        }, {
            pairs: [],
            remainder: formattedList
        })

        formattedList = (
            <>
                {parts.pairs.map((pair, index) => (
                    <Fragment key={`word-list:${words[index]}:${index}`}>{pair[0]}{wordRenderer(words[index], values[index])}</Fragment>
                ))}{parts.remainder}
            </>
        )
    }

    return formattedList
}

const joinToType = join => ({
    and: 'conjunction',
    or: 'disjunction'
})[join]

export default WordList
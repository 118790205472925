import React, { Component, createRef } from 'react'
import { useIntl } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { useForm } from 'components/form/controller'
import { empty, getOptionId, unoptionize } from 'components/form/field/share'
import { jsonMapper, multipartMapper } from 'components/form/field/share/edit'
import { compact } from 'utilities/array'
import { cls } from 'utilities/dom'
import { omit } from 'utilities/object'
import { isInRange } from 'utilities/math'
import isEqual from 'react-fast-compare'
import { Field, Label, Control } from 'components/form/field/s'
import OneOfField from 'components/form/field/one-of'
import ShareField from 'components/form/field/share'

class EditShareOrEveryone extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ...this.valueStateFromProps(props),
            focus: false
        }

        this.register()
        this.shares = createRef()
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ name, field, whistle }, { which, shares }) => {
        const nameChanged = name !== this.props.name
        const whichChanged = which !== this.state.which
        const sharesChanged = !isEqual(shares, this.state.shares)
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && (whichChanged || sharesChanged)) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        let {
            value,
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(!value) {
            value = empty
        }

        const {
            min = 0,
            max = Infinity
        } = this.props.picker ?? {}

        const everyone = isEveryone(value, this.props.everyoneValue)

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                which: this.props.name,
                shares: [],
                focus: false
            }),

            validator: value => required ?
                (everyone || isInRange({ min, max, value: value.length })) :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => {
        const value = props.field?.value || empty
        let which = props.field?.which || null
        const everyone = isEveryone(value, props.everyoneValue)

        if(!which) {
            if(!value) {
                which = 'none'
            } else if(everyone) {
                which = 'everyone'
            } else {
                which = props.name
            }
        }

        return {
            which,
            shares: (everyone || which === 'none') ?
                [] :
                value
        }
    }

    onChange = () => {
        const value = {
            none: empty,
            everyone: [this.props.everyoneValue]
        }[this.state.which] ?? this.state.shares

        this.props.onChange?.({
            [this.props.name]: value,
            which: this.state.which
        })
    }

    render() {
        const { which } = this.state

        const {
            className,
            label,
            salt,
            name,
            enabled = true,
            loading = false,
            field = {},
            controlProps = {},
            picker = {},
            oneOfProps = {},
            unsetOption,
            everyoneFirst = false,
            everyoneValue,
            everyoneLabel = this.props.formatMessage({
                id: 'organization_everyone_in',
                defaultMessage: 'Everyone in {name}'
            }, this.props.organization),
            everyoneContent = null,
            shareEnabled = true,
            shareLabel,
            shareContent = null,
            shareHidden = false,
            shareUpgradable = false,
            forwardedRef
        } = this.props

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const oneOfFieldClassName = cls([
            'compact',
            oneOfProps?.className
        ])

        const valueMapperContext = { name, salt }
        const mapValues = this.props.form.mode === 'json' ?
            jsonMapper(valueMapperContext) :
            multipartMapper(valueMapperContext)

        const {
            required,
            softRequired,
            optional
        } = field

        let { locked = [] } = picker

        const shares = ({
            none: empty,
            everyone: [everyoneValue]
        }[which] ?? this.state.shares.filter(({ type }) => type !== 'organization'))
            .map(unoptionize)

        const everyoneOption = {
            value: 'everyone',
            label: everyoneLabel,
            content: everyoneContent
        }

        locked = locked.map(unoptionize)

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {mapValues(shares).map(({ key, ...share }) => (
                    <input
                        type="hidden"
                        {...share}
                        key={key} />
                ))}
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <OneOfField
                        {...omit(oneOfProps, 'className')}
                        salt={salt}
                        id={salt}
                        className={oneOfFieldClassName}
                        label={false}
                        name="which"
                        field={{
                            value: which,
                            include: 'never',
                            options: compact([
                                !!unsetOption && {
                                    ...unsetOption,
                                    value: 'none'
                                },
                                !!everyoneFirst && everyoneOption,
                                {
                                    value: name,
                                    disabled: !shareEnabled,
                                    label: shareLabel,
                                    controlRef: this.shares,
                                    upgradable: shareUpgradable,
                                    content: (
                                        <>
                                            {!shareHidden && (
                                                <ShareField
                                                    salt={salt}
                                                    {...(!shareContent ? { className: 'compact' } : null)}
                                                    label={false}
                                                    name="shares"
                                                    field={{
                                                        value: shares,
                                                        include: 'never'
                                                    }}
                                                    enabled={shareEnabled}
                                                    controlProps={{
                                                        ...controlProps,
                                                        ...(which === 'everyone' ? { inert: 'true' } : null)
                                                    }}
                                                    picker={{
                                                        ...picker,
                                                        locked
                                                    }}
                                                    onChange={({ shares }) => void this.setState({ shares }, this.onChange)}
                                                    ref={this.shares}
                                                    key={`${salt}:${JSON.stringify(shares)}`} />
                                            )}
                                            {shareContent}
                                        </>
                                    )
                                },
                                !everyoneFirst && everyoneOption
                            ])
                        }}
                        onChange={({ which }) => {
                            this.setState({ which }, this.onChange)
                            this.props.form.triggerChange(name)
                        }}
                        disabled={!enabled}
                        ref={forwardedRef} />
                </Control>
            </Field>
        )
    }
}

const isEveryone = (value, everyoneValue) => value.length === 1 && getOptionId(value[0]) === getOptionId(everyoneValue)

export default props => {
    const { formatMessage } = useIntl()
    const { organization } = useOrganization()
    const form = useForm()

    return (
        <EditShareOrEveryone
            {...props}
            formatMessage={formatMessage}
            organization={organization}
            form={form} />
    )
}
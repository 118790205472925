import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useReveal } from 'hooks/reveal'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { formatDataTime } from 'components/form/field/time'
import { cls } from 'utilities/dom'

const ViewTimeOfDay = ({ className, label, ...props }) => {
    const {
        field = {},
        controlProps = {},
        display,
        obscure = false
    } = props

    const { value = null } = field

    const dataTimeFormatter = formatDataTime('time')

    const [node, reveal] = useReveal(obscure)

    let displayClassName = []

    if(value) {
        displayClassName = [
            display,
            obscure && 'obscured',
            reveal && 'revealed'
        ]
    } else {
        displayClassName = ['empty']
    }

    displayClassName = cls(displayClassName)

    const controlClassName = cls([
        controlProps.className,
        display
    ])

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control {...(controlClassName ? { className: controlClassName } : null)}>
                <ValueDisplay
                    {...(displayClassName ? { className: displayClassName } : null)}
                    ref={node}>
                    {!!value && dataTimeFormatter(value)}
                    {!value && (
                        <FormattedMessage
                            id="empty_not_set"
                            defaultMessage="Not set" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewTimeOfDay

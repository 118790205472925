import styled from 'styled-components'
import { svgStroke, interpointDivider, symbolHoverCss } from 'utilities/styled'
import Link from 'components/link'
import AvatarBase from 'components/avatar'
import NoteBase from 'components/note'
import { Trigger as TriggerBase } from 'components/tooltip'
import Caption from 'components/typography/caption'
import { DisplayEmoji } from 'components/symbol'
import AbsenceDateBase from 'pages/absence/components/date'
import { AvatarColumnsBlock, AvatarAnimation, AvatarFallbackBlock } from 'components/avatar/s'
import { Plain } from 'components/button'
import ApprovalActionsBase from 'pages/absence/components/approval-actions'

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 1fr) 40px;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    row-gap: 8px;

    border-bottom: 1px solid var(--huma-color-border-default);
    padding: 24px 0;

    &:first-of-type {
        margin-top: -24px;
    }
`

export const AbsenceLink = styled(Link)`
    display: grid;
    grid-template-rows: repeat(2, 20px);
    grid-template-columns: 40px 1fr;
    align-items: center;
    column-gap: 16px;

    width: 100%;
    height: auto;

    text-align: start;

    &:not([disabled]) {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    & ${AvatarColumnsBlock} {
        overflow: revert;
    }

    & ${AvatarAnimation} {
        position: absolute;
        inset: 0;

        &:before {
            border: 2px solid var(--huma-color-border-default);
        }
    }

    & ${AvatarFallbackBlock} {
        position: relative;

        &:before {
            border: 2px solid var(--avatar-background);
        }
    }

    & :where(${AvatarAnimation}, ${AvatarFallbackBlock}) {
        transition-property: background-color, filter;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;

            border-radius: 50%;
            width: 100%;
            height: 100%;

            transform: scale(1);
            transform-origin: center;
            transition: transform .2s cubic-bezier(0, .85, .40, 1.45);
        }
    }

    &:is(:hover, :focus) :where(${AvatarAnimation}, ${AvatarFallbackBlock}) {
        ${symbolHoverCss}

        &::before {
            transform: scale(1.2);
        }
    }
`

export const Avatar = styled(AvatarBase)`
    grid-row: 1 / 3;
    height: 40px;
`

export const NamePosition = styled.div`
    grid-row: 1 / 2;
    grid-column: 2 / 3;

    max-width: 220px;

    line-height: 24px;

    ${AbsenceLink}:hover &,
    ${AbsenceLink}:focus & {
        text-decoration: underline;
    }
`

export const Type = styled(Caption)`
    grid-row: 2 / 3;
    grid-column: 2 / 3;

    font-size: 14px;
    line-height: 20px;
`

export const Emoji = styled(DisplayEmoji)`
    display: inline-block;

    margin-inline-end: 4px;
    height: 20px;
`

export const AbsenceMeta = styled.div`
    grid-row: 2 / 3;
    grid-column: 1 / 3;

    padding-inline-start: 56px;
`

export const AbsenceDateWrapper = styled.div`
    display: flex;
    align-items: center;

    &.with-divider {
        ${interpointDivider()}
    }
`

export const AbsenceDate = styled(AbsenceDateBase)`
    grid-row: 3 / 4;
    grid-column: 2 / 3;

    margin-top: 4px;
`

export const Note = styled(NoteBase)`
    margin-top: 8px;
`

export const Trigger = styled(TriggerBase)`
    padding-inline: 8px;
`

export const Status = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;

    &:not(:empty) {
        margin-top: 8px;
    }
`

export const ChangeButton = styled(Plain)`
    height: 24px;

    font-size: 14px;
`

export const ApprovalActions = styled(ApprovalActionsBase)`
    padding-inline-start: 56px;
`

export const ActionsCell = styled.div`
    grid-row: 1 / 2;
    grid-column: 2 / 3;

    width: 40px;
    height: 40px;

    svg {
        ${svgStroke()}
    }
`
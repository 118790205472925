import React from 'react'
import { useIntegrations } from 'contexts/integrations'
import { Layout } from './s'
import Item from './item'

const Minintegrations = props => {
    const { integrations } = useIntegrations()

    return (
        <Layout {...props}>
            {integrations.map(integration => (
                <Item
                    {...integration}
                    key={`minintegrations:${integration.type}`} />
            ))}
        </Layout>
    )
}

export default Minintegrations
import React, { useState, useCallback, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import { useLocation, useNavigate } from 'react-router-dom'
import { get } from 'api'
import PubSub from 'pubsub-js'
import { getListRepresentationFromProfile } from 'utilities/person'
import { omit } from 'utilities/object'
import { compact } from 'utilities/array'
import { cls } from 'utilities/dom'
import { unoptionize } from 'components/form/field/share'
import { Plain } from 'components/button'
import { Filter, Avatar, Arrow, Widget, WidgetContentScroller, MobileHeader, ResetWrap } from '../s'
import Form from 'components/form/controller'
import Field from 'components/form/field/share'
import { QuickSelectButton } from './s'

const ShareUserField = ({ filter, setFilter, allResetAt, name, field = null, label, quickSelectMe = false, context, salt }) => {
    const getValue = () => filter[name] ?? field?.defaultValue ?? null

    const {
        me,
        isItMyOwnId
    } = useMe()

    const location = useLocation()
    const navigate = useNavigate()

    const [previousAllResetAt, setPreviousAllResetAt] = useState(allResetAt)
    const [configuring, setConfiguring] = useState(false)
    const [hasInteracted, setHasInteracted] = useState(false)

    const [user, setUser] = useState(null)
    const [fetching, setFetching] = useState(false)

    const resolveUser = useCallback(id => {
        if(!id) {
            return void setUser(null)
        }

        const isMe = isItMyOwnId(id)

        if(isMe && me && (!user || !isItMyOwnId(user?.id))) {
            return void setUser({
                ...getListRepresentationFromProfile(me),
                type: 'user'
            })
        }

        if(!isMe && (!user || !!isItMyOwnId(user?.id))) {
            const fetch = async () => {
                setFetching(true)
                const { ok, response: user } = await get({ path: `/users/${id}` })
                setFetching(false)

                if(ok && user) {
                    setUser({
                        ...getListRepresentationFromProfile(user),
                        type: 'user'
                    })
                }
            }

            fetch()
        }
    }, [me, user])

    useEffect(() => {
        const subscription = PubSub.subscribe('filter:updated', (_, data = {}) => {
            if(!!context && context === data?.context && name === data?.name) {
                setFilter({ [name]: data?.value })
                resolveUser(data?.value)
            }
        })

        return () => PubSub.unsubscribe(subscription)
    }, [])

    // Resolve user on page load
    useEffect(() => {
        resolveUser(getValue())
    }, [isItMyOwnId(getValue()), user])

    // Unset the user when value is unset
    useEffect(() => {
        (!getValue() && !!user) && setUser(null)
    }, [getValue(), user])

    useEffect(() => {
        if(allResetAt !== previousAllResetAt) {
            reset({ silent: true })
            setPreviousAllResetAt(allResetAt)
        }
    }, [allResetAt, previousAllResetAt])

    const control = useRef()
    const widget = useRef()

    const reset = (options = {}) => {
        if(name in (location.state?.filter ?? {})) {
            navigate({
                ...location,
                state: {
                    ...location.state,
                    filter: omit(location.state.filter, name)
                },
                replace: true
            })
        }

        const { silent = false } = options
        !silent && setFilter({ [name]: null })

        setUser(null)
    }

    const filterClassName = cls([
        'constructive',
        configuring && 'active'
    ])

    return (
        <>
            <Filter
                className={filterClassName}
                onClick={() => {
                    setConfiguring(configuring => !configuring)
                    setHasInteracted(true)
                }}
                ref={control}>
                {label}
                {!!user && (
                    <Avatar
                        who={user}
                        size={20}
                        key={`${salt}:${name}:user:avatar:${user?.id}`} />
                )}
                <Arrow
                    {...(configuring ? { className: 'active' } : null)}
                    size={16} />
            </Filter>
            <Widget
                show={configuring}
                clickOutside={{
                    inside: [control],
                    action: () => setConfiguring(false)
                }}
                position={{
                    origin: control,
                    direction: {
                        x: {
                            where: 'inside',
                            to: 'right'
                        },
                        y: {
                            where: 'outside',
                            to: 'down',
                            adjust: 8
                        }
                    }
                }}
                closeButton={false}
                constrain={true}
                blocking={true}
                scrollable={false}
                salt={`${salt}:${name}:widget`}
                ref={widget}>
                <MobileHeader>
                    <Plain
                        className="constructive"
                        onClick={() => setConfiguring(false)}>
                        <FormattedMessage
                            id="action_done"
                            defaultMessage="Done" />
                    </Plain>
                    <Plain
                        className="destructive"
                        onClick={reset}
                        disabled={!getValue()}>
                        <FormattedMessage
                            id="action_reset"
                            defaultMessage="Reset" />
                    </Plain>
                </MobileHeader>
                <WidgetContentScroller className="lax">
                    <Form layout="vertical">
                        <Field
                            salt={salt}
                            name={name}
                            className="compact"
                            label={false}
                            field={{
                                ...field,
                                value: compact([user])
                            }}
                            enabled={!fetching}
                            controlProps={{ autoFocus: true }}
                            picker={{
                                types: ['user'],
                                single: true
                            }}
                            onChange={({ [name]: users = [] }) => {
                                if(!hasInteracted) {
                                    return
                                }

                                const [user] = users.map(unoptionize)

                                setFilter({ [name]: user?.id ?? null })
                                setUser(user ?? null)
                            }}
                            key={[
                                salt,
                                name,
                                user?.id ?? 'empty',
                                allResetAt,
                                configuring,
                                fetching
                            ].join(':')} />
                    </Form>
                    {(!!quickSelectMe && !isItMyOwnId(getValue())) && (
                        <QuickSelectButton
                            onClick={() => {
                                setFilter({ [name]: me.id })

                                setUser({
                                    ...getListRepresentationFromProfile(me),
                                    type: 'user'
                                })
                            }}>
                            <FormattedMessage
                                id="action_select_me"
                                defaultMessage="Select me" />
                        </QuickSelectButton>
                    )}
                </WidgetContentScroller>
                {!!getValue() && (
                    <ResetWrap>
                        <Plain
                            className="destructive"
                            onClick={reset}>
                            <FormattedMessage
                                id="action_reset"
                                defaultMessage="Reset" />
                        </Plain>
                    </ResetWrap>
                )}
            </Widget>
        </>
    )
}

export default ShareUserField

import React, { useState, useEffect, useRef } from 'react'
import Text from 'components/form/input/text'
import { useClickOutside } from 'hooks/click-outside'
import { SuggestionContainer, SuggestionList, SuggestionItem, SuggestionButton } from './s'

const Suggestions = ({ name, value: initialValue, suggestions, onChange, show: initialShow, id }) => {
    const [active, setActive] = useState(0)
    const [filtered, setFiltered] = useState([])
    const [show, setShow] = useState(initialShow)
    const [value, setValue] = useState(initialValue ?? '')
    const [userSelected, setUserSelected] = useState(false)

    const input = useRef()
    const suggestionContainer = useRef()

    useClickOutside({
        callback: () => setShow(false),
        refs: [input, suggestionContainer]
    })

    useEffect(() => {
        setFiltered(suggestions.filter(({ label }) =>
            label.toLowerCase().includes(value?.toLowerCase())
        ))

        setActive(0)
        if(!!filtered.length) {
            setShow(true)
        }
    }, [value])

    const onSuggestionClicked = (value, e) => {
        setActive(0)
        setFiltered([])
        setShow(false)
        setValue(value)
        setUserSelected(true)

        requestAnimationFrame(() => {
            onChange(e)
            input.current.focus()
        })
    }

    const onFocus = () => {
        if(!userSelected) {
            setShow(true)
        }

        setUserSelected(false)
    }

    const onKeyDown = e => {
        const { keyCode } = e

        if([13, 38, 40].includes(keyCode)) {
            e.preventDefault()
        }

        if(keyCode === 13) { // Enter
            onSuggestionClicked(filtered[active]?.value, e)
        }

        if(keyCode === 38 && active > 0) { // Up
            setActive(active - 1)
        }

        if(keyCode === 40) { // Down
            if(active < filtered.length - 1) {
                setActive(active + 1)
            }

            if(value === '') {
                setFiltered(suggestions)
                setShow(true)
            }
        }
    }

    const displayValue = !!suggestions?.length ?
        suggestions.find(s => s.value === value)?.label ?? value :
        value

    const actualValue = !!suggestions?.length ?
        suggestions.find(s => s.label === displayValue)?.value ?? value :
        value

    return (
        <>
            <input
                name={name}
                type="hidden"
                value={actualValue ?? ''} />
            <Text
                type="text"
                value={displayValue}
                onChange={e => {
                    onChange(e)
                    setValue(e.currentTarget.value)
                }}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                id={id}
                className="suggestive"
                ref={input} />
            {(!!show && !!filtered.length) && (
                <SuggestionContainer ref={suggestionContainer}>
                    <SuggestionList>
                        {filtered.map(({ label, value }, index) => (
                            <SuggestionItem key={`${id}:suggestion:${value}`}>
                                <SuggestionButton
                                    value={value}
                                    onClick={e => onSuggestionClicked(value, e)}
                                    className={`neutral${(index === active) ? ' active' : ''}`}>
                                    {label}
                                </SuggestionButton>
                            </SuggestionItem>
                        ))}
                    </SuggestionList>
                </SuggestionContainer>
            )}
        </>
    )
}

export default Suggestions

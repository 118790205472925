import React from 'react'
import { FlexChildShrink } from 'components/flex'
import ActionColumns from 'components/form/actions'
import { Plain, Button } from 'components/button'

const Actions = ({
    cancel, done, dismiss,
    dirty,
    range, allowOpenEnded, isWithinBlockedDateRange,
    selectedTime, selectedTimeEnd
}) => {
    const singleSubmissible = !range && selectedTime
    const rangeSubmissible = range && selectedTime && (allowOpenEnded ? true : (!isWithinBlockedDateRange ?? selectedTimeEnd))

    return (
        <FlexChildShrink>
            <ActionColumns>
                <Plain
                    className={cancel.effect}
                    onClick={cancel.onClick}>
                    {cancel.label}
                </Plain>
                <Button
                    className={done.effect}
                    onClick={() => {
                        done.onClick()
                        dismiss()
                    }}
                    disabled={!dirty || (!singleSubmissible && !rangeSubmissible)}>
                    {done.label}
                </Button>
            </ActionColumns>
        </FlexChildShrink>
    )
}

export default Actions
import styled from 'styled-components'
import {
    svgStroke,
    belowPhone, belowTablet
} from 'utilities/styled'
import { H4 } from 'components/typography/heading'
import FileNameIconBase from 'components/file-name-icon'
import Caption from 'components/typography/caption'
import ContextMenuBase from 'widgets/context-menu/flat'

export const Aside = styled.aside`
    overflow-y: auto;
    overflow-x: clip;

    border-radius: 0 8px 8px 0;
    padding: 32px;
    background-color: var(--huma-color-surface-default);

    .no-select > & {
        user-select: none;
    }

    ${belowTablet`
        border-radius: 0;
        padding: 24px 24px 48px;
        background-color: var(--huma-color-surface-elevated);
    `}
`

export const Kicker = styled(H4)`
    margin-block-end: 16px;
`

export const Meta = styled.div`
    margin-block-end: 32px;
`

export const FileNameIcon = styled(FileNameIconBase)`
    font-size: 20px;
    font-weight: 500;

    svg.feather {
        ${svgStroke()}
    }
`

export const FakeLabel = styled(Caption)`
    &.indent {
        padding-inline-start: 40px;
    }
`

export const ContextMenu = styled(ContextMenuBase)`
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    overflow: hidden;

    ${belowPhone`
        position: relative;
        left: -24px;

        border-left: none;
        border-right: none;
        border-radius: 0;
        width: calc(100% + 48px);

        & > ol > li > * {
            padding: 0 24px;
        }
    `}
`
import React, { useState } from 'react'
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Link2 as Link } from 'styled-icons/feather'

const FavIcon = styled.img`
    width: ${p => p.$size}px;
    height: ${p => p.$size}px;
`

const FallbackWrapper = styled.div`
    svg {
        ${svgStroke()}
    }
`

const googleSharedFavicon = 'https://www.google.com/s2/favicons?sz=64&domain_url='

export default ({ size = 16, src, fallback: Fallback = Link, ...props }) => {
    const [error, setError] = useState(false)

    const strippedSrc = src.replace(/^https?:\/\//, '')

    if(error) {
        return (
            <FallbackWrapper {...props}>
                <Fallback
                    size={size} />
            </FallbackWrapper>
        )
    }

    return (
        <FavIcon
            {...props}
            $size={size}
            onError={() => setError(true)}
            src={`${googleSharedFavicon}${strippedSrc}`} />
    )
}
import React, { forwardRef } from 'react'
import { PersonItem, PersonColumns, User } from './s'
import { FlexChild, FlexChildShrink } from 'components/flex'
import Checkbox from 'components/form/input/checkbox'

const Person = forwardRef(({ person, prepicked, locked, picked, method, toggle, salt }, ref) => {
    const isPrepicked = !!prepicked.find(({ id }) => id === person.id)
    const isLocked = !!locked.find(({ id }) => id === person.id)
    const isPicked = !!picked.find(({ id }) => id === person.id)

    const disabled = isLocked ?
        true :
        (method === 'add') ?
            isPrepicked :
            false

    const checked = (method === 'add') ?
        disabled || isPicked :
        isPicked

    return (
        <PersonItem ref={ref}>
            <PersonColumns
                {...(method === 'view') ? { as: 'div' } : null}
                {...(disabled ? { className: 'disabled' } : null)}>
                <FlexChild>
                    <User
                        who={person}
                        showPosition={true}
                        truncate={true}
                        size={40} />
                </FlexChild>
                {(method !== 'view') && (
                    <FlexChildShrink>
                        <Checkbox
                            id={`${salt}:${person.id}:toggle`}
                            checked={checked}
                            locked={isLocked}
                            disabled={disabled}
                            onChange={() => toggle(person)} />
                    </FlexChildShrink>
                )}
            </PersonColumns>
        </PersonItem>
    )
})

export default Person

import styled, { css } from 'styled-components'
import { fontSerif } from 's'
import { belowPhone } from 'utilities/styled'
import { Grid } from 'components/grid'
import RadioFieldBase from 'components/form/field/radio'

export const containerProps = {
    name: 'payment',
    breakpoint: 750
}

export const ContainerQuery = styled.div`
    container-type: inline-size;
    container-name: ${containerProps.name};

    flex: 1;
`

export const priceCss = css`
    ${fontSerif}

    .currency-nok,
    .currency-sek,
    .currency-eur {
        &.decimal {
            display: none;
        }

        &.fraction {
            position: relative;
            top: -0.5em;
            vertical-align: baseline;

            line-height: 0;
            font-size: 60%;
        }
    }
`

export const OldPrice = styled.span`
    text-decoration: line-through;
`

export const CurrentPrice = styled.strong`
    color: var(--huma-color-foreground-default);
`

export const ToggleLayout = styled(Grid)`
    grid-template-columns: repeat(2, minmax(min-content, auto));
    gap: 24px;
    align-items: center;
    justify-content: end;

    ${belowPhone`
        display: flex;
        flex-direction: column-reverse;
        align-items: start;
    `}
`

export const RadioField = styled(RadioFieldBase)`
    ${belowPhone`
        flex: unset;
        align-items: end;
    `}
`
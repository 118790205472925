import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { empty, getOptionId } from 'components/form/field/share'
import { Units } from 'components/units'
import AvatarsBase from 'components/avatars'
import Person from 'components/person'

const Avatars = styled(AvatarsBase)`
    height: 40px;
`

const ViewShareOrEveryone = ({ className, label, salt, ...props }) => {
    const {
        name,
        field = {},
        picker = {},
        display = {},
        everyoneValue
    } = props

    const {
        types,
        locked = empty
    } = picker

    const {
        empty: emptyDisplay = (
            <ValueDisplay className="empty">
                <FormattedMessage
                    id="empty_none_picked"
                    defaultMessage="None picked" />
            </ValueDisplay>
        )
    } = display

    let { value } = field
    if(!value) {
        value = empty
    }

    salt = `${salt}:${name}`

    const everyone = value.length === 1 && getOptionId(value[0]) === getOptionId(everyoneValue)

    const shares = everyone ?
        [everyoneValue] :
        [...locked, ...value]

    const userAllowed = types.includes('user')

    // Organization always implicitly allowed;
    const onlyUsers = !everyone && (types?.length <= 2 && userAllowed)

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                {(!!shares.length && !onlyUsers) && (
                    <Units
                        units={shares}
                        salt={salt} />
                )}
                {(shares.length > 1 && !!onlyUsers) && (
                    <Avatars
                        people={shares.map(({ user }) => user)}
                        sortPeople={false}
                        size={24}
                        max={10}
                        salt={salt} />
                )}
                {(shares.length === 1 && !!onlyUsers) && (
                    <Person
                        who={shares[0].user}
                        size={24} />
                )}
                {(!everyone && !shares.length) && emptyDisplay}
            </Control>
        </Field>
    )
}

export default ViewShareOrEveryone
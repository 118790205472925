import { getFullName } from 'utilities/person'
import { pick } from 'utilities/object'

export const format = item => {
    if(item.type === 'user') {
        item.name = getFullName(item)
    }

    item.uuid = item.id

    return pick(item, 'type', 'uuid', 'name')
}
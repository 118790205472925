import React from 'react'
import View from './view'
import Edit from './edit'

const ArrayField = props => {
    let {
        name,
        field = {},
        picker,
        mode = 'edit',
        salt,
        ...rest
    } = props

    let {
        value = [],
        editable = true
    } = field

    props = {
        ...rest,
        name,
        field: {
            ...field,
            value
        },
        ...(picker ? {
            picker: {
                ...picker,
                options: picker?.options ?? []
            }
        } : null),
        salt: `${salt}:${name}`
    }

    if(mode === 'edit' && editable) {
        return <Edit {...props} />
    }

    return <View {...props} />
}

export default ArrayField


import Document from '@tiptap/extension-document'

export const DefaultDocument = Document.extend({
    content: 'block+'
})

export const SummaryDocument = Document.extend({
    content: 'paragraph'
})

export const TitledDocument = Document.extend({
    content: 'title block+'
})
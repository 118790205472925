import styled from 'styled-components'
import {
    svgStroke,
    belowPhone
} from 'utilities/styled'
import DrawablePanelBase from 'components/drawable-panel'
import { Grid } from 'components/grid'
import LocalePickerBase from 'components/locale-picker'
import { MyProfileCompletion as MyProfileCompletionBase } from 'components/profile-completion'

export const DrawablePanel = styled(DrawablePanelBase)`
    &.last {
        margin-block-end: 0;
    }
`

export const DoneCircle = styled(Grid)`
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    width: 20px;
    height: 20px;
    background-color: var(--huma-color-surface-constructive-bold);

    color: var(--huma-color-foreground-on-bold);

    > svg {
        ${svgStroke('small')}
    }
`

export const Content = styled(Grid)`
    grid-auto-rows: auto;
    grid-auto-flow: row;
    grid-row-gap: 24px;

    max-width: 696px;
    padding-inline-start: 40px;

    ${belowPhone`
        padding-inline-start: 0;
    `}
`

export const LocalePicker = styled(LocalePickerBase)`
    width: 240px;

    ${belowPhone`
        width: 100%;
    `}
`

export const MyProfileCompletion = styled(MyProfileCompletionBase)`
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    width: 264px;
    padding: 16px;
`

export const Actions = styled(Grid)`
    grid-auto-columns: minmax(0, auto);
    grid-auto-flow: column;
    column-gap: 24px;
    justify-content: start;

    ${belowPhone`
        grid-auto-rows: minmax(0, auto);
        grid-auto-flow: row;
        align-items: start;
        row-gap: 8px;
    `}

    svg {
        ${svgStroke('small')}

        margin-inline: -8px 8px;
    }

    > * {
        justify-self: start;
    }
`
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useClipboard } from 'hooks/clipboard'
import { Container, Paragraph, Copy } from './s'
import { Plain } from 'components/button'

export default ({ text, displayText, truncate = false, className }) => {
    const { ref, copy, copied } = useClipboard({ text })

    return (
        <Container {...(className ? { className } : null)}>
            <Paragraph {...(truncate ? { className: 'truncate' } : null)}>
                {displayText ?? text}
            </Paragraph>
            <Copy>
                <Plain
                    className={copied ? 'neutral' : 'constructive'}
                    onClick={copy}
                    ref={ref}>
                    <FormattedMessage
                        id={copied ?
                            'noun_copied' :
                            'action_copy'
                        }
                        defaultMessage={copied ?
                            'Copied' :
                            'Copy'
                        } />
                </Plain>
            </Copy>
        </Container>
    )
}

import React from 'react'
import { useI18n } from 'contexts/i18n'
import { FormattedMessage } from 'react-intl'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { compact } from 'utilities/array'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'

const ViewCountry = ({ label, picker, className, ...props }) => {
    const {
        territories,
        holidayTerritories
    } = useI18n()

    const { mode = 'territories' } = picker ?? {}

    let value = props.field?.value ?? null
    let resolved = false
    let country = null
    let region = null
    let flag = null

    if(value) {
        value = value.toUpperCase()
    }

    if(mode === 'holidayTerritories') {
        region = holidayTerritories
            .filter(({ regions = [] }) => !!regions.length)
            .map(({ regions }) => regions)
            .flat()
            .find(({ code }) => code === value)?.name ?? null
        const territory = region ?
            holidayTerritories.find(({ regions = [] }) =>
                regions.find(({ code }) => code === value))
            : {
                code: value,
                name: territories[value]
            }

        if(territory) {
            resolved = true
            flag = getUnicodeFlagIcon(territory.code)
            country = territory.name
        }
    }

    if(mode === 'territories' && value in territories) {
        resolved = true
        country = territories[value]
        flag = getUnicodeFlagIcon(value)
    }

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <ValueDisplay {...(!value ? { className: 'empty' } : null)}>
                    {resolved && compact([
                        flag,
                        region && '›',
                        region,
                        !region && country
                    ]).join(' ')}
                    {!resolved && (
                        <FormattedMessage
                            id="empty_not_chosen"
                            defaultMessage="Not chosen" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewCountry
import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { aspectRatio as a } from '../s'

export const EmbedWrapper = styled.div`
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;

    margin: 0 auto;
    width: calc(100% - 10em);
    height: 0;
    padding-top: calc((100% - 10em) * ${a.height / a.width});

    transform: translate(0, 0);

    @media (min-aspect-ratio: ${`${a.width} / ${a.height}`}) {
        width: 0;
        height: calc(100vh - 10em);
        padding-top: 0;
        padding-left: calc((100vh - 10em) * ${a.width / a.height});
    }

    ${belowPhone`
        width: calc(100% - 48px);
        padding-top: calc((100% - 48px) * ${a.height / a.width});
    `}
`

export const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;

    border: 0;
    width: 100%;
    height: 100%;
`
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'

export const IconColumn = styled.span``

export const Columns = styled.span`
    display: grid;
    grid-template-columns: minmax(0, auto);
    align-items: center;

    border-radius: 8px;
    height: 56px;
    padding: 16px;
    box-shadow: inset 0 0 0 1px var(--huma-color-border-default);

    transition: box-shadow ease-out .3s;

    color: var(--huma-color-foreground-default);
    text-align: start;
    text-decoration: none;

    &.has-actions {
        grid-template-columns: minmax(0, auto) 40px;
        grid-column-gap: 16px;

        padding-block: 8px;

        > svg {
            ${svgStroke()}
        }
    }

    a > & {
        cursor: pointer;

        grid-template-columns: minmax(0, auto) 24px;
        grid-column-gap: 16px;

        svg {
            ${svgStroke()}
        }
    }

    a::where(:hover, :focus) > & {
        transition: box-shadow ease-in .15s;
    }

    a.constructive:where(:hover, :focus) > & {
        box-shadow: inset 0 0 0 1px var(--huma-color-border-constructive-bold);

        ${IconColumn} {
            color: var(--huma-color-foreground-constructive-bold);
        }
    }

    a.destructive:where(:hover, :focus) > & {
        box-shadow: inset 0 0 0 1px var(--huma-color-border-destructive);

        ${IconColumn} {
            color: var(--huma-color-foreground-destructive);
        }
    }
`

export const DetailsColumn = styled.span`
    a > ${Columns}:hover & {
        color: var(--huma-color-foreground-default);
    }
`
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStorage } from 'hooks/storage'
import { useAppSignal, sendAppSignal } from 'hooks/app-signal'
import { useSalty } from 'hooks/salty'
import { cls } from 'utilities/dom'
import { compact, omit, pick } from 'utilities/object'
import { basicApplied, advancedApplied, getDefaultValues, getEmptyValues } from './utilities'
import {
    FilterRows, Count,
    BasicRow, BasicRowLayout,
    AdvancedRow, AdvancedExpandable, AdvancedLayout, AdvancedHeadingLayout, AdvancedHeading,
    Action
} from './s'
import SearchField from './basic/search'
import PickUsersField from './basic/pick-users'
import CheckboxesField from './advanced/checkboxes'
import RadiobuttonsField from './advanced/radiobuttons'
import ShareUserField from './advanced/share-user'
import ShareUsersField from './advanced/share-users'
import SharesField from './advanced/shares'
import ShareOrEveryoneField from './advanced/share-or-everyone'
import SupervisorField from './advanced/supervisor'
import TimeField from './advanced/time'
import { Filter as ToggleFilterButton, Plain } from 'components/button'
import { Filter as FilterIcon } from 'styled-icons/fluentui-system-regular'

const Filter = ({
    namespace,
    filter = {}, setFilter,
    basicFields = [], advancedFields = [],
    basicValues = null, advancedValues = null,
    items, fetching, hasFetched,
    resettable = true, showerride = false, onReset = null, preferDefaultValue = false,
    expandable: expandableIntent = false, expanded = false,
    memoryFilterOptions = null,
    className, context, salt
}) => {
    salt = `${salt}:filter`

    useSalty(salt)

    const location = useLocation()
    const navigate = useNavigate()

    const [basicResetAt, setBasicResetAt] = useState({})
    const [advancedResetAt, setAdvancedResetAt] = useState(Date.now())
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false)
    const [isExpanded, setIsExpanded] = useState(expanded)

    const [memoryFilter, setMemoryFilter] = useStorage({
        key: salt,
        defaultValue: {
            ...basicValues,
            ...advancedValues,
            ...filter
        },
        type: 'session',
        merge: true,
        preferDefaultValue,
        ...memoryFilterOptions
    })

    const [basicFilter, setBasicFilter] = useState({
        ...basicValues,
        ...pick(memoryFilter, ...basicFields.map(({ name }) => name))
    })

    const [advancedFilter, setAdvancedFilter] = useState({
        ...advancedValues,
        ...pick(memoryFilter, ...advancedFields.map(({ name }) => name))
    })

    const updateBasicFilter = update => setBasicFilter(compact({
        ...basicFilter,
        ...update
    }))

    const updateAdvancedFilter = update => setAdvancedFilter(compact({
        ...advancedFilter,
        ...update
    }))

    const resetBasicFilterField = name => () => {
        if(name in (location.state?.filter ?? {})) {
            navigate({
                ...location,
                state: omit(location.state.filter, name),
                replace: true
            })
        }

        setBasicFilter(omit(basicFilter, name))

        onReset?.()

        setBasicResetAt(basicResetAt => ({
            ...basicResetAt,
            [name]: Date.now()
        }))
    }

    const resetAdvancedFilter = () => {
        if('filter' in (location.state ?? {})) {
            navigate({
                ...location,
                state: omit(location.state.filter, ...advancedFields.map(({ name }) => name)),
                replace: true
            })
        }

        setAdvancedFilter({
            ...advancedValues,
            ...getEmptyValues(advancedFields),
            ...getDefaultValues(advancedFields)
        })

        onReset?.()

        setAdvancedResetAt(Date.now())
    }

    useEffect(() => {
        const combined = {
            ...basicFilter,
            ...advancedFilter
        }

        setFilter(combined)
        setMemoryFilter(combined)
    }, [JSON.stringify(basicFilter), JSON.stringify(advancedFilter)])

    useAppSignal({
        namespace: 'side-panel.toggle',
        action: setIsSidePanelOpen
    })

    useAppSignal({
        namespace: `filter.${namespace}.set`,
        action: ({ filters = [], method = 'set' }) => {
            const basicFilters = filters.filter(({ name }) => basicFields.map(({ name }) => name).includes(name))
            const advancedFilters = filters.filter(({ name }) => advancedFields.map(({ name }) => name).includes(name))

            if(!basicFilters.length && !advancedFilters.length) {
                return
            }

            if(!!basicFilters.length) {
                const refilter = method === 'update' ?
                    updateBasicFilter
                    : setBasicFilter

                refilter(basicFilters.reduce((accumulator, { name, value }) => ({
                    ...accumulator,
                    [name]: value
                }), {}))

                setBasicResetAt(basicResetAt => ({
                    ...basicResetAt,
                    ...basicFilters.reduce((accumulator, { name }) => ({
                        ...accumulator,
                        [name]: Date.now()
                    }), {})
                }))
            }

            if(!!advancedFilters.length) {
                const refilter = method === 'update' ?
                    updateAdvancedFilter
                    : setAdvancedFilter

                refilter(advancedFilters.reduce((accumulator, { name, value }) => ({
                    ...accumulator,
                    [name]: value
                }), {}))

                setAdvancedResetAt(`${Date.now()}:update`)
            }
        }
    })

    const areBasicFiltersApplied = useCallback(
        basicApplied(basicFields.map(({ name }) => name)),
        [basicFields.map(({ name }) => name).join('+')]
    )

    const areAdvancedFiltersApplied = useCallback(
        advancedApplied(advancedFields.map(({ name }) => name)),
        [advancedFields.map(({ name }) => name).join('+')]
    )

    const getCountOfAdvancedFiltersApplied = useCallback(
        advancedApplied(advancedFields.map(({ name }) => name), 'count'),
        [advancedFields.map(({ name }) => name).join('+')]
    )

    const basicFiltersApplied = !!basicFields.length && areBasicFiltersApplied(filter)
    const advancedFiltersApplied = !!advancedFields.length && areAdvancedFiltersApplied(
        filter,
        advancedFields
    )

    useEffect(() => {
        !!namespace && sendAppSignal(`${namespace}:advanced-applied`, advancedFiltersApplied)
    }, [namespace, advancedFiltersApplied])

    const countOfAdvancedFiltersApplied = !!advancedFields.length ?
        getCountOfAdvancedFiltersApplied(
            filter,
            advancedFields
        ) :
        0

    const getCommonBasicProps = useCallback(name => ({
        filter: basicFilter,
        setFilter: updateBasicFilter,
        resetFilterField: resetBasicFilterField(name),
        resetAt: basicResetAt[name] ?? 'never-reset',
        name,
        context,
        salt: `${salt}:field`,
        key: `${salt}:field:${name}:${basicResetAt[name] ?? 'never-reset'}`
    }), [basicFilter, Object.values(basicResetAt).join('+')])

    const getCommonAdvancedProps = useCallback(name => ({
        filter: advancedFilter,
        setFilter: updateAdvancedFilter,
        allResetAt: advancedResetAt,
        name,
        context,
        salt: `${salt}:field`,
        key: `${salt}:field:${name}`
    }), [advancedFilter, advancedResetAt])

    if(!showerride && !items.length && hasFetched && !fetching && (!basicFiltersApplied && !advancedFiltersApplied)) {
        return null
    }

    const expandable = expandableIntent && !!advancedFields?.length

    className = cls([
        className,
        expandableIntent && 'vertical',
        expandable && 'expandable',
        isSidePanelOpen && 'side-panel'
    ])

    const showResetButton = !!advancedFiltersApplied && resettable && !expandable

    const [Expandable, expandableProps = null] = expandable ?
        [AdvancedExpandable, isExpanded ? { className: 'expanded' } : null] :
        [Fragment]

    return (
        <FilterRows {...(className ? { className } : null)}>
            {!!basicFields?.length && (
                <BasicRow>
                    <BasicRowLayout>
                        {basicFields.map(({ type, name, ...rest }) => {
                            const Field = typeToField(type)

                            if(!Field) {
                                return null
                            }

                            const { key, ...props } = getCommonBasicProps(name)

                            return (
                                <Field
                                    {...props}
                                    {...rest}
                                    key={key} />
                            )
                        })}
                        {!!expandable && (
                            <ToggleFilterButton
                                {...(isExpanded ? { className: 'active' } : null)}
                                onClick={() => setIsExpanded(isExpanded => !isExpanded)}>
                                {!!countOfAdvancedFiltersApplied && <Count>{countOfAdvancedFiltersApplied}</Count>}
                                {!countOfAdvancedFiltersApplied && <FilterIcon size={16} />}
                                <FormattedMessage
                                    id="action_filter"
                                    defaultMessage="Filter" />
                            </ToggleFilterButton>
                        )}
                    </BasicRowLayout>
                </BasicRow>
            )}
            {!!advancedFields?.length && (
                <AdvancedRow {...(expandable ? { className: `expandable${isExpanded ? ' expanded' : ''}` } : null)}>
                    <Expandable {...expandableProps}>
                        <AdvancedLayout {...(expandable ? { className: 'vertical' } : null)}>
                            {!!expandable && (
                                <AdvancedHeadingLayout>
                                    <AdvancedHeading className="compact">
                                        <FormattedMessage
                                            id="noun_filters"
                                            defaultMessage="Filters" />
                                    </AdvancedHeading>
                                    <Plain
                                        className="destructive"
                                        onClick={resetAdvancedFilter}
                                        disabled={!advancedFiltersApplied}>
                                        <FormattedMessage
                                            id="action_reset"
                                            defaultMessage="Reset" />
                                    </Plain>
                                </AdvancedHeadingLayout>
                            )}
                            {advancedFields.map(({ type, name, ...rest }) => {
                                const Field = typeToField(type)

                                if(!Field) {
                                    return null
                                }

                                const { key, ...props } = getCommonAdvancedProps(name)
                                let fieldSalt = key

                                if(type === 'radiobuttons') {
                                    const checkeds = rest.field.options?.map(({ checked = false }) => `${checked}`)

                                    if(checkeds?.length) {
                                        fieldSalt = `${fieldSalt}:${checkeds.join('+')}`
                                    }
                                }

                                return (
                                    <Field
                                        {...props}
                                        {...rest}
                                        fieldSalt={fieldSalt}
                                        key={key} />
                                )
                            })}
                            {showResetButton && (
                                <Action
                                    className="destructive"
                                    onClick={resetAdvancedFilter}>
                                    <FormattedMessage
                                        id="action_reset_filters"
                                        defaultMessage="Reset filters" />
                                </Action>
                            )}
                            {expandable && (
                                <Action
                                    className="neutral"
                                    onClick={() => setIsExpanded(false)}>
                                    <FormattedMessage
                                        id="action_close"
                                        defaultMessage="Close" />
                                </Action>
                            )}
                        </AdvancedLayout>
                    </Expandable>
                </AdvancedRow>
            )}
        </FilterRows>
    )
}

const typeToField = type => ({
    // Basic
    search: SearchField,
    pickUsers: PickUsersField,

    // Advanced
    checkboxes: CheckboxesField,
    radiobuttons: RadiobuttonsField,
    shareUser: ShareUserField,
    shareUsers: ShareUsersField,
    shares: SharesField,
    shareOrEveryone: ShareOrEveryoneField,
    supervisor: SupervisorField,
    time: TimeField
})[type]

export default Filter
import React, { forwardRef } from 'react'
import { useOrganization } from 'contexts/organization'
import { optionize } from 'components/form/field/share'
import View from './view'
import Edit from './edit'

const ShareOrEveryoneField = forwardRef((props, forwardedRef) => {
    const { organization } = useOrganization()
    const everyoneValue = optionize('organization')(organization)

    let {
        name,
        field = {},
        picker = {},
        mode = 'edit',
        enabled = true,
        salt,
        ...rest
    } = props

    let {
        value = [],
        editable = true,
        which = 'everyone'
    } = field

    let {
        types = ['user', 'team', 'location'],
        locked = []
    } = picker

    if(!types?.length) {
        value = [everyoneValue]
        enabled = false
    }

    const valueToShares = value => {
        if(value.type === 'organization') {
            return everyoneValue
        }

        return optionize(value.type)(value)
    }

    if(!value?.length && !locked?.length && which === 'everyone') {
        value = [everyoneValue]
    } else {
        value = value?.map(valueToShares) ?? []
    }

    locked = locked.map(valueToShares)

    props = {
        ...rest,
        name,
        everyoneValue,
        field: {
            ...field,
            value
        },
        picker: {
            ...picker,
            types,
            locked
        },
        enabled,
        salt: `${salt}:${name}`,
        forwardedRef
    }

    if(mode === 'edit' && editable) {
        return <Edit {...props} />
    }

    return <View {...props} />
})

export default ShareOrEveryoneField
import React from 'react'
import styled from 'styled-components'
import { Field, Label } from 'components/form/field/s'

const Fieldset = styled(Field)`
    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    padding: 24px;

    > *:is(:last-child) {
        margin-bottom: 0;
    }
`

export default ({ label, children, ...props }) => (
    <>
        {!!label && <Label {...label} />}
        <Fieldset {...props}>
            {children}
        </Fieldset>
    </>
)
import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { FlexChildShrink } from 'components/flex'

export const FilterRow = styled(FlexChildShrink)`
    padding: 0 32px 8px;

    ${belowTablet`
        padding: 0 24px 8px;
    `}
`
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useClickOutside } from 'hooks/click-outside'
import { useDebounce } from 'hooks/debounce'
import { useSize } from 'hooks/viewport'
import { mergeRefs } from 'react-merge-refs'
import { get } from 'api'
import { cls } from 'utilities/dom'
import { getOptionId } from 'components/form/field/share'
import {
    Container,
    Search,
    Results, List, Item, ItemDivider, Button, KeyHintInner,
    Person, Organization, Group
} from './s'
import { Loader } from 'components/filter/basic/search/s'
import { AnimatePresence } from 'framer-motion'
import { CornerDownLeft as Enter } from 'styled-icons/feather'

const EditShareSearch = ({
    value,
    placeholder,
    exclude,
    publicOption,
    types,
    single,
    params = {},
    id,
    addShare,
    onFocus,
    onBlur,
    forwardedRef,
    ...props
}) => {
    const { formatMessage } = useIntl()

    const control = useRef()
    const input = useRef()

    const height = useSize({ dimension: 'height' })

    useClickOutside({
        callback: () => {
            setShow(false)
            onBlur()
        },
        refs: [control]
    })

    const [fetching, setFetching] = useState(false)
    const [active, setActive] = useState(0)
    const [search, setSearch, { signal, setValueImmediately: setSearchImmediately }] = useDebounce('')
    const [searchValid, setSearchValid] = useState(false)
    const [results, setResults] = useState([])
    const [resultSpace, setResultSpace] = useState(0)
    const [filtered, setFiltered] = useState([])
    const [show, setShow] = useState(false)

    const addUnit = unit => {
        addShare(unit)
        setSearchImmediately('')
        setShow(false)
        setResults([])
        setFiltered([])
        onBlur()
    }

    useEffect(() => {
        const bottom = input?.current?.getBoundingClientRect()?.bottom

        if(bottom > 0 && height > 0) {
            setResultSpace(height - bottom)
        }
    }, [input?.current, height])

    useEffect(() => {
        setSearchValid(search.length >= 2)
    }, [search])

    useEffect(() => {
        const fetch = async () => {
            setFetching(true)

            const { ok, response } = await get({
                path: '/units',
                params: {
                    ...params,
                    search,
                    types
                }
            })

            setFetching(false)

            if(ok && !!response?.items?.length) {
                setResults(response.items
                    .filter(({ id }) => !params?.excludeIds?.includes(id))
                    .map(({ type, ...item }) => ({
                        type,
                        [type]: item
                    }))
                )
            }
        }

        if(searchValid) {
            fetch()
        } else {
            setResults(types?.includes?.('organization') ? [publicOption] : [])
        }
    }, [signal])

    useEffect(() => {
        const excludeIds = [...value, ...exclude].map(getOptionId)
        const filteredResults = results.filter(share => {
            const id = getOptionId(share)
            return !excludeIds.includes(id)
        })

        const filtered = filteredResults.filter(({ user }) => user?.status?.active !== false)

        if(!!params?.includeDeactivated) {
            const dividedFiltered = filteredResults.filter(({ user }) => user?.status?.active === false)

            if(!!dividedFiltered.length) {
                filtered.push({ divider: true })
                filtered.push(...dividedFiltered)
            }
        }

        setFiltered(filtered)
    }, [results.map(getOptionId).join(':'), value.map(getOptionId).join(':')])

    const cyclePrevious = useCallback(() => {
        if(active > 0) {
            const previousActive = filtered.slice(0, active).reverse().findIndex(({ divider }) => !divider)

            if(previousActive > -1) {
                setActive(active - 1 - previousActive)
            } else {
                setActive(0)
            }
        } else {
            setActive(filtered.length - 1)
        }
    }, [active, filtered.map(getOptionId).join(':')])

    const cycleNext = useCallback(() => {
        if(active < filtered.length - 1) {
            const nextActive = filtered.slice(active + 1).findIndex(({ divider }) => !divider)

            if(nextActive > -1) {
                setActive(active + 1 + nextActive)
            } else {
                setActive(filtered.length - 1)
            }
        } else {
            setActive(0)
        }
    }, [active, filtered.map(getOptionId).join(':')])

    const onKeyDown = e => {
        const { keyCode, shiftKey } = e
        const key = keys[keyCode]

        if(keyCode in keys) {
            if((key === 'tab' && show && searchValid) || key !== 'tab') {
                e.preventDefault()
            }
        }

        if(key === 'enter' && filtered[active]) {
            addUnit(filtered[active])
        }

        if(key === 'up') {
            cyclePrevious()
        }

        if(key === 'down') {
            cycleNext()
        }

        if(shiftKey && key === 'tab' && show) {
            cyclePrevious()
        }

        if(!shiftKey && key === 'tab' && show) {
            cycleNext()
        }

        if(key === 'esc') {
            setShow(false)
        } else if(key === 'tab' && !searchValid) {
            setShow(false)
        } else if(key !== 'tab' && searchValid) {
            setShow(true)
        }

        input?.current?.focus()
    }

    if(!placeholder) {
        placeholder = getPlaceholder(types, single)

        if(placeholder) {
            placeholder = formatMessage(placeholder)
        }
    }

    const {
        className,
        ...searchProps
    } = props

    const searchClassName = cls([
        className,
        fetching && 'loading'
    ])

    return (
        <Container ref={control}>
            <Search
                {...searchProps}
                {...(searchClassName ? { className: searchClassName } : null)}
                value={search}
                id={id}
                placeholder={placeholder}
                onFocus={() => {
                    setActive(0)
                    setShow(true)
                    onFocus()
                }}
                onKeyDown={onKeyDown}
                onChange={e => setSearch(e.currentTarget.value)}
                ref={mergeRefs([input, forwardedRef])} />
            {!!fetching && <Loader className="s" />}
            {(!!show && !!filtered?.length) && (
                <Results>
                    <List $space={resultSpace}>
                        {filtered.map((result, index) => {
                            const {
                                type,
                                divider = false
                            } = result ?? {}
                            const entity = result?.[type]
                            const isActive = index === active

                            if(divider) {
                                return (
                                    <ItemDivider key={`${id}:divider:${index}`}>
                                        <FormattedMessage
                                            id="noun_account_status_deactivated_plural"
                                            defaultMessage="Deactivated accounts" />
                                    </ItemDivider>
                                )
                            }

                            return (
                                <Item key={`${id}:result:${type}:${entity.id}`}>
                                    <Button
                                        onClick={() => addUnit(result)}
                                        className={`neutral${isActive ? ' active' : ''}`}
                                        tabIndex={-1}>
                                        {!!isActive && (
                                            <AnimatePresence>
                                                <KeyHintInner animate={isActive ? 'active' : 'inactive'}>
                                                    <Enter size={16} />
                                                </KeyHintInner>
                                            </AnimatePresence>
                                        )}
                                        {(type === 'organization') && <Organization size={16} />}
                                        {['team', 'location'].includes(type) && (
                                            <Group
                                                group={entity}
                                                type={type}
                                                size={16} />
                                        )}
                                        {(type === 'user') && (
                                            <Person
                                                who={entity}
                                                showPosition={true}
                                                size={24}
                                                className="large" />
                                        )}
                                    </Button>
                                </Item>
                            )
                        })}
                    </List>
                </Results>
            )}
        </Container>
    )
}

export const keys = {
    9: 'tab',
    13: 'enter',
    27: 'esc',
    38: 'up',
    40: 'down'
}

export const getPlaceholder = (types, single) => {
    if(types?.length === 1 && types?.includes('organization')) {
        return null
    }

    const person = types?.includes('user')
    const team = types?.includes('team')
    const location = types?.includes('location')

    if(single) {
        // TODO ? Missing person+team & person+location
        if(person && team && location) {
            return {
                id: 'share_action_find_person_or_team_or_location',
                defaultMessage: 'Find a person, team or location…'
            }
        } else if (team && location) {
            return {
                id: 'share_action_find_team_or_location',
                defaultMessage: 'Find a team or a location…'
            }
        } else if(person) {
            return {
                id: 'share_action_find_person',
                defaultMessage: 'Find a person…'
            }
        } else if(team) {
            return {
                id: 'share_action_find_team',
                defaultMessage: 'Find a team…'
            }
        } else if(location) {
            return {
                id: 'share_action_find_location',
                defaultMessage: 'Find a location…'
            }
        }
    } else {
        if(person && team && location) {
            return {
                id: 'share_action_find_people_teams_locations',
                defaultMessage: 'Find persons, teams and locations…'
            }
        } else if(person && team) {
            return {
                id: 'share_action_find_people_teams',
                defaultMessage: 'Find persons and teams…'
            }
        } else if(person && location) {
            return {
                id: 'share_action_find_people_locations',
                defaultMessage: 'Find persons and locations…'
            }
        } else if (team && location) {
            return {
                id: 'share_action_find_teams_locations',
                defaultMessage: 'Find teams and locations…'
            }
        } else if(person) {
            return {
                id: 'share_action_find_people',
                defaultMessage: 'Find persons…'
            }
        } else if(team) {
            return {
                id: 'share_action_find_teams',
                defaultMessage: 'Find teams…'
            }
        } else if(location) {
            return {
                id: 'share_action_find_locations',
                defaultMessage: 'Find locations…'
            }
        }
    }

    return null
}

export default EditShareSearch
import React from 'react'
import { PersonItem } from './s'
import User from 'components/person'

const Person = ({ person }) => (
    <PersonItem>
        <User
            who={person}
            showPosition={true}
            asLink={true}
            size={40} />
    </PersonItem>
)

export default Person

import styled from 'styled-components'

export const Link = styled.a`
    cursor: pointer;
    display: block;

    margin-bottom: 8px;
    width: 100%;

    text-decoration: none;

    &:focus {
        outline: none;
    }

    &.spacious {
        margin-bottom: 24px;
    }
`

export const NoLink = styled.span`
    display: block;
    margin-bottom: 8px;

    &.spacious {
        margin-bottom: 24px;
    }
`
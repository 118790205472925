import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme, ThemeProvider } from 'styled-components'
import { useChart } from 'contexts/chart'
import { Title, CustomTooltip, reduceLabels, formatValue } from 'components/charts'
import { Container } from 'components/charts/s'
import {
    ResponsiveContainer,
    LineChart as ReLineChart, CartesianGrid, XAxis, YAxis, Line,
    Tooltip
} from 'recharts'

const LineChart = ({ showTitle = true }) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()

    const { chart } = useChart()

    if(!chart?.labels?.length) {
        return null
    }

    const labelReducer = reduceLabels({ formatMessage })

    const labels = chart.labels.reduce(labelReducer, {})
    const labelsInOrder = Object.values(labels)

    const valueFormatter = formatValue({
        format: chart.valueFormat,
        formatMessage
    })

    return (
        <ThemeProvider theme={theme}>
            <Container>
                {showTitle && <Title id={chart.id} />}
                <ResponsiveContainer
                    width="100%"
                    height={264}>
                    <ReLineChart
                        data={chart.data}
                        margin={{
                            top: 8,
                            right: 8,
                            bottom: 0,
                            left: 10
                        }}>
                        <CartesianGrid
                            stroke="var(--huma-color-border-default)"
                            strokeDasharray="4 4"
                            vertical={false} />
                        <XAxis
                            dataKey="name"
                            tickLine={false}
                            tickSize={8} />
                        <YAxis
                            tickLine={false}
                            tickSize={4}
                            interval="preserveEnd" />
                        <Tooltip
                            content={<CustomTooltip valueFormatter={valueFormatter} />}
                            wrapperStyle={{ outline: 'none' }}
                            cursor={false} />
                        {labelsInOrder.map(label => (
                            <Line
                                dataKey="value"
                                name={label}
                                key={label}
                                dot={{
                                    stroke: 'var(--huma-color-chart-intensity-subtle)',
                                    strokeWidth: 2,
                                    r: 6
                                }}
                                fill="var(--huma-color-chart-intensity-subtle)"
                                stroke="var(--huma-color-chart-intensity-subtle)"
                                strokeWidth={4} />
                        ))}
                    </ReLineChart>
                </ResponsiveContainer>
            </Container>
        </ThemeProvider>
    )
}

export default LineChart

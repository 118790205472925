import React from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useEnvironment } from 'contexts/environment'
import { useMe } from 'contexts/me'
import { identifyAndSend } from 'utilities/hubspot'
import Form from 'components/form/controller'
import SelectField from 'components/form/field/select'
import { map } from 'utilities/object'

const LocalePicker = ({ label = false, className }) => {
    const { formatMessage } = useIntl()

    const {
        locale,
        defaultLocale,
        locales,
        dateLocale,

        setLocale
    } = useI18n()

    const { integrations } = useEnvironment()
    const { authorized } = useMe()

    if(!locale) {
        return null
    }

    const { weekStartsOn } = dateLocale.options

    const update = ({ locale }) => {
        setLocale({
            locale,
            dateLocaleOverrides: {
                weekStartsOn
            }
        }, { store: authorized })

        if(integrations?.enableHubspotContactRegistration) {
            identifyAndSend({
                huma_webclient_locale: locale,
                salt: Date.now()
            })
        }
    }

    return (
        <Form>
            <SelectField
                {...(className ? { className } : null)}
                label={label}
                name="locale"
                field={{
                    value: locale || defaultLocale,
                    include: 'always',
                    allowEmptyOption: false
                }}
                optgroupLabel={formatMessage({
                    id: 'action_language_choose_options',
                    defaultMessage: 'Choose your language…'
                })}
                options={map(locales, ({ name, flag }, locale) => ({
                    key: locale,
                    value: locale,
                    text: `${flag} ${name}`
                }))}
                onChange={update}
                salt="language:picker"
                key={`language:picker:${locale}`} />
        </Form>
    )
}

export default LocalePicker
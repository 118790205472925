import styled, { css } from 'styled-components'
import { Grid } from 'components/grid'
import CheckboxFieldBase from 'components/form/field/checkbox'
import { CheckboxInput } from 'components/form/input/checkbox'
import { Label } from 'components/form/field/checkbox/edit/s'
import { TopicTitle } from './topic/s'

export const CheckboxGrid = styled(Grid)`
    grid-template-columns: minmax(0, max-content) auto;
    justify-content: space-between;
    align-items: center;
    padding-block: 12px;

    &:not(:last-of-type) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }
`

export const CheckboxField = styled(CheckboxFieldBase)`
    justify-content: flex-end;

    &.horizontal {
        flex-direction: row-reverse;
    }

    &:has(${CheckboxInput}:focus) ${Label},
    & ${Label}:hover {
        ${p => p.interaction !== 'switch' && css`
            ${TopicTitle} > span {
                text-decoration: underline;
            }
        `}
    }

    & ${Label}.for-switch {
        width: auto;
        padding-block: 8px;
        padding-inline: 24px 40px;

        color: var(--huma-color-foreground-default);
    }
`
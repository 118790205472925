import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { useAbortController } from 'hooks/abort-controller'
import { useClickOutside } from 'hooks/click-outside'
import { useDebounce } from 'hooks/debounce'
import { useSize } from 'hooks/viewport'
import { mergeRefs } from 'react-merge-refs'
import { get } from 'api'
import { pick } from 'utilities/object'
import { cls } from 'utilities/dom'
import { getPlaceholder, keys } from 'components/form/field/share/edit/search'
import {
    Container,
    Search,
    Results, List, Item, ItemDivider, Button, KeyHintInner,
    Person, Organization, Group
} from './s'
import { Loader } from 'components/filter/basic/search/s'
import { AnimatePresence } from 'framer-motion'
import { CornerDownLeft as Enter } from 'styled-icons/feather'

const EditAccessUnitsSearch = ({
    value,
    placeholder,
    types,
    params = {},
    id,
    addAccessUnit,
    onFocus,
    onBlur,
    forwardedRef,
    ...props
}) => {
    const { formatMessage } = useIntl()

    const { organization } = useOrganization()

    const organizationUnit = useMemo(() => ({
        ...pick(organization, 'id', 'name'),
        type: 'organization'
    }), [organization?.id])

    const control = useRef()
    const input = useRef()

    const { resetAbortController } = useAbortController()

    const height = useSize({ dimension: 'height' })

    useClickOutside({
        callback: () => {
            setShow(false)
            onBlur()
        },
        refs: [control]
    })

    const [fetching, setFetching] = useState(false)
    const [active, setActive] = useState(0)

    const [search, setSearch, { signal, setValueImmediately: setSearchImmediately }] = useDebounce({
        value: '',
        valid: false
    })

    const [results, setResults] = useState([])
    const [resultSpace, setResultSpace] = useState(0)
    const [filtered, setFiltered] = useState([])
    const [show, setShow] = useState(false)

    const addUnit = unit => {
        addAccessUnit(unit)
        setSearchImmediately({ value: '', valid: false })
        setShow(false)
        setResults([])
        setFiltered([])
        onBlur()
    }

    useEffect(() => {
        const bottom = input?.current?.getBoundingClientRect()?.bottom

        if(bottom > 0 && height > 0) {
            setResultSpace(height - bottom)
        }
    }, [input?.current, height])

    const valueUnitIds = useMemo(
        () => value.map(({ unit }) => unit.id),
        [value.map(({ unit }) => unit.id).join('+')]
    )

    const valueHasOrganization = value.find(({ unit }) => unit.type === 'organization')

    const performSearch = useCallback(async () => {
        if(!search.valid) {
            const fallback = (!valueHasOrganization && types?.includes?.('organization')) ?
                [organizationUnit] :
                []

            resetAbortController()
            return void setResults(fallback)
        }

        setFetching(true)

        const { ok, response } = await get({
            path: '/units',
            params: {
                ...params,
                search: search.value,
                types
            },
            signal: resetAbortController().signal
        })

        setFetching(false)

        if(ok && !!response?.items?.length) {
            setResults(response.items.filter(({ id }) => ![
                ...(params?.excludeIds ?? []),
                ...valueUnitIds
            ]?.includes(id)))
        }
    }, [search.value, search.valid, fetching, params, valueHasOrganization, types, organizationUnit, valueUnitIds])

    useEffect(() => {
        performSearch()
    }, [signal])

    useEffect(() => {
        const filtered = results.filter(({ status }) => status?.active !== false)

        if(!!params?.includeDeactivated) {
            const deactivated = results.filter(({ status }) => status?.active === false)
            if(!!deactivated.length) {
                filtered.push({ divider: true }, ...deactivated)
            }
        }

        setFiltered(filtered)
    }, [results.map(({ id }) => id).join('+')])

    const cyclePrevious = useCallback(() => {
        if(active > 0) {
            const previousActive = filtered.slice(0, active).reverse().findIndex(({ divider }) => !divider)

            if(previousActive > -1) {
                setActive(active - 1 - previousActive)
            } else {
                setActive(0)
            }
        } else {
            setActive(filtered.length - 1)
        }
    }, [active, filtered.map(({ id }) => id).join('+')])

    const cycleNext = useCallback(() => {
        if(active < filtered.length - 1) {
            const nextActive = filtered.slice(active + 1).findIndex(({ divider }) => !divider)

            if(nextActive > -1) {
                setActive(active + 1 + nextActive)
            } else {
                setActive(filtered.length - 1)
            }
        } else {
            setActive(0)
        }
    }, [active, filtered.map(({ id }) => id).join('+')])

    const onKeyDown = e => {
        const { keyCode, shiftKey } = e
        const key = keys[keyCode]

        if(keyCode in keys) {
            if((key === 'tab' && show && search.valid) || key !== 'tab') {
                e.preventDefault()
            }
        }

        if(key === 'enter' && filtered[active]) {
            addUnit(filtered[active])
        }

        if(key === 'up') {
            cyclePrevious()
        }

        if(key === 'down') {
            cycleNext()
        }

        if(shiftKey && key === 'tab' && show) {
            cyclePrevious()
        }

        if(!shiftKey && key === 'tab' && show) {
            cycleNext()
        }

        if(key === 'esc') {
            setShow(false)
        } else if(key === 'tab' && !search.valid) {
            setShow(false)
        } else if(key !== 'tab' && search.valid) {
            setShow(true)
        }

        input?.current?.focus()
    }

    if(!placeholder) {
        placeholder = getPlaceholder(types, false)

        if(placeholder) {
            placeholder = formatMessage(placeholder)
        }
    }

    const {
        className,
        ...searchProps
    } = props

    const searchClassName = cls([
        className,
        fetching && 'loading'
    ])

    return (
        <Container ref={control}>
            <Search
                {...searchProps}
                {...(searchClassName ? { className: searchClassName } : null)}
                value={search.value}
                id={id}
                placeholder={placeholder}
                onFocus={() => {
                    setActive(0)
                    setShow(true)
                    onFocus()
                }}
                onKeyDown={onKeyDown}
                onChange={e => setSearch(search => ({
                    value: e.target.value,
                    valid: e.target.value.length >= 2 && e.target.value !== search.value
                }))}
                ref={mergeRefs([input, forwardedRef])} />
            {!!fetching && <Loader className="s" />}
            {(!!show && !!filtered?.length) && (
                <Results>
                    <List $space={resultSpace}>
                        {filtered.map(({ divider = false, ...unit }, index) => {
                            const isActive = index === active

                            if(divider) {
                                return (
                                    <ItemDivider key={`${id}:divider:${index}`}>
                                        <FormattedMessage
                                            id="noun_account_status_deactivated_plural"
                                            defaultMessage="Deactivated accounts" />
                                    </ItemDivider>
                                )
                            }

                            return (
                                <Item key={`${id}:result:${unit.type}:${unit.id}`}>
                                    <Button
                                        onClick={() => addUnit(unit)}
                                        className={`neutral${isActive ? ' active' : ''}`}
                                        tabIndex={-1}>
                                        {!!isActive && (
                                            <AnimatePresence>
                                                <KeyHintInner animate={isActive ? 'active' : 'inactive'}>
                                                    <Enter size={16} />
                                                </KeyHintInner>
                                            </AnimatePresence>
                                        )}
                                        {(unit.type === 'organization') && (
                                            <Organization
                                                nameOnly
                                                company
                                                size={20} />
                                        )}
                                        {['team', 'location'].includes(unit.type) && (
                                            <Group
                                                group={unit}
                                                type={unit.type}
                                                size={16} />
                                        )}
                                        {(unit.type === 'user') && (
                                            <Person
                                                who={unit}
                                                showPosition={true}
                                                size={24}
                                                className="large" />
                                        )}
                                    </Button>
                                </Item>
                            )
                        })}
                    </List>
                </Results>
            )}
        </Container>
    )
}

export default EditAccessUnitsSearch
import styled from 'styled-components'
import { fromContainer, belowContainer } from 'utilities/styled'
import { Grid } from 'components/grid'
import { FlexChildShrink } from 'components/flex'
import { H4 } from 'components/typography/heading'
import { ParagraphSmall } from 'components/typography/paragraph'

export const containerProps = {
    name: 'statistics-group-modern',
    breakpoint: 600
}

export const ContainerQuery = styled.div`
    container-type: inline-size;
    container-name: ${containerProps.name};
`

export const Layout = styled(Grid)`
    grid-auto-columns: minmax(auto, 1fr);
    grid-auto-flow: column;
    align-items: start;

    margin-block-end: 24px;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    padding: 24px 8px;

    &.compact {
        margin-block-end: 0;
    }

    &.centered {
        text-align: center;
    }

    &.warning {
        border-color: var(--huma-color-border-warning);
    }

    &.error {
        border-color: var(--huma-color-border-destructive);
    }

    ${belowContainer(containerProps)`
        --gap: 16px;

        grid-auto-flow: row;
        row-gap: calc(var(--gap) * 2);

        padding: var(--gap) 0;
    `}
`

export const Heading = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;

    ${Layout}.centered & {
        justify-content: center;
    }
`

export const Value = styled(H4)`
    flex: none;
    display: block;

    min-width: 20px;

    text-align: center;
`

export const Label = styled(ParagraphSmall)`
    position: relative;
`

export const Item = styled(FlexChildShrink)`
    display: grid;
    grid-auto-rows: minmax(0, min-content);
    grid-auto-flow: row;
    align-items: start;
    row-gap: 16px;

    min-height: 100%;
    padding-inline: 24px;

    ${Layout}.tighter & {
        row-gap: 8px;
    }

    ${Layout}.centered & {
        align-content: center;
    }

    ${Layout}:has(> &:nth-child(5)) & {
        padding-inline: 16px;
    }

    &.text-start {
        text-align: start;
    }

    ${belowContainer(containerProps)`
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: calc(var(--gap) * 0.5);

        padding-inline: 16px;

        ${Layout}.rows & {
            flex-direction: column;
            align-items: start;
        }

        ${Layout}:not(.rows) &:has(> :nth-child(2)) {
            flex-direction: row-reverse;
        }

        &:not(:last-of-type) {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                inset-block-end: calc(var(--gap) * -1);
                inset-inline: 0;

                border-bottom: 1px solid var(--huma-color-border-default);
            }
        }
    `}

    ${fromContainer(containerProps)`
        &:not(:last-of-type) {
            border-right: 1px solid var(--huma-color-border-default);
        }
    `}

    &.info,
    &.action {
        & ${Value} {
            color: var(--huma-color-foreground-info-bold);
        }

        &.loud ${Label} {
            color: var(--huma-color-foreground-info-bold);
        }
    }

    &.success {
        & ${Value} {
            color: var(--huma-color-foreground-success);
        }

        &.loud ${Label} {
            color: var(--huma-color-foreground-success);
        }
    }

    &.warning {
        & ${Value} {
            color: var(--huma-color-foreground-warning);
        }

        &.loud ${Label} {
            color: var(--huma-color-foreground-warning);
        }
    }

    &.error {
        & ${Value} {
            color: var(--huma-color-foreground-error);
        }

        &.loud ${Label} {
            color: var(--huma-color-foreground-error);
        }
    }
`

export const Content = styled.div`
    line-height: 24px;

    ${belowContainer(containerProps)`
        font-size: 14px;
        color: var(--huma-color-foreground-subtle);

        &::first-letter {
            text-transform: uppercase;
        }
    `}
`
import React from 'react'
import styled from 'styled-components'
import { Columns, FlexChild, FlexChildShrink } from 'components/flex'
import { ArrowSortedDown as Descending, ArrowSortedUp as Ascending } from 'styled-icons/typicons'
import { cls } from 'utilities/dom'

export const Grid = styled.div`
    display: grid;
`

export const GridCell = styled.div``

export const GridColumnHeader = styled(GridCell)`
    color: var(--huma-color-foreground-subtle);
`

export const GridRowHeader = styled(GridCell)`
    font-weight: 500;
`

export const SortOrderIcon = ({ direction }) => {
    const Icon = direction === 'asc' ? Ascending : Descending
    return <Icon size={16} />
}

const GridColumnHeaderClickable = styled(GridColumnHeader)`
    cursor: pointer;
    user-select: none;
    transition: color .15s ease-in-out;

    svg {
        opacity: 0.2;
        transition: opacity .15s ease-in-out;
    }

    &:hover,
    &.active {
        svg {
            opacity: 1;
        }
    }

    &.active {
        color: var(--huma-color-foreground-default);
    }
`

const GridColumnHeaderText = styled(FlexChildShrink)`
    margin-right: 16px;
`

export const GridColumnHeaderSortable = ({ field, toggle, text, sort, className, ...props }) => {
    const active = field === sort.by

    className = cls([
        className,
        active && 'active'
    ])

    return (
        <GridColumnHeaderClickable
            {...props}
            {...(className ? { className } : null)}
            onClick={() => toggle(field)}>
            <Columns>
                <GridColumnHeaderText>
                    {text}
                </GridColumnHeaderText>
                <FlexChild>
                    <SortOrderIcon direction={sort.direction} />
                </FlexChild>
            </Columns>
        </GridColumnHeaderClickable>
    )
}

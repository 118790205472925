import React, { forwardRef } from 'react'
import { Columns } from './s'
import { FlexChild } from 'components/flex'
import Avatars from 'components/avatars'

export default forwardRef(({ people, max = 5, size = 24, showTooltip = true, salt, ...props }, ref) => {
    if(!people.length) {
        return null
    }

    return (
        <Columns
            {...props}
            ref={ref}>
            <FlexChild>
                <Avatars {...{ people, max, size, showTooltip, salt }} />
            </FlexChild>
        </Columns>
    )
})
import React, { useState, Fragment } from 'react'
import { useIntl, FormattedMessage, FormattedNumber } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { usePayment } from 'contexts/payment'
import { v4 } from 'uuid'
import { getPlanFrequency } from 'contexts/access'
import { prune } from 'utilities/array'
import { currencies } from 'utilities/payment'
import Value from 'components/typography/paragraph'
import FormContext, { useForm } from 'components/form/controller'
import { ContainerQuery, ToggleLayout, RadioField, OldPrice, CurrentPrice } from './s'
import { InlineMessage } from 'components/message'
import { NewReleases as Offer } from 'styled-icons/material'
import RadiobuttonsField from 'components/form/field/radiobuttons'
import { Columns, FlexChildGrow, FlexChildShrink } from 'components/flex'
import { Plain } from 'components/button'
import StatusLabel from 'components/status-label'

const getFormattedPrice = ({ value, currency, locale, options, key }) => {
    if(Intl?.NumberFormat) {
        const parts = Intl.NumberFormat(locale, options).formatToParts(value)

        return parts.map(({ type, value: part }, index) => (
            <span
                className={`${type} currency-${currency.toLowerCase()}`}
                key={`${value}:${currency}:${index}:${key}`}>
                {part}
            </span>
        ))
    }

    return (
        <FormattedNumber
            {...options}
            value={value} />
    )
}

export const Price = ({ value, currency: overrideCurrency, currencyDisplay, options = {}, old = false, current = false }) => {
    const { locale } = useI18n()
    const { currency: originalCurrency } = usePayment()

    const [key] = useState(`${v4()}:${Date.now()}`)

    const currency = overrideCurrency ?? originalCurrency

    if(!currencyDisplay) {
        currencyDisplay = currencies[currency] || 'code'
    }

    options = {
        style: 'currency',
        currency,
        currencyDisplay,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options
    }

    const price = getFormattedPrice({ value, currency, locale, options, key })

    const Wrapper = {
        old: OldPrice,
        current: CurrentPrice,
        fragment: Fragment
    }[old ? 'old' : current ? 'current' : 'fragment']

    return (
        <Wrapper>
            {price}
        </Wrapper>
    )
}

export const TermSelect = ({
    bundles, value, setValue,
    interaction = 'toggle', editable, setEditable,
    salt
}) => {
    const { formatMessage } = useIntl()
    const { currency } = usePayment()
    const existingFormContext = !!useForm()

    if(!currency || !bundles?.length) {
        return null
    }

    let terms = prune(bundles.flatMap(bundle => bundle.chargePlans?.map(({ term }) => term) ?? []))
    if(!terms?.length) {
        return null
    }

    if(terms.length === 1) {
        return <TermDisplay term={value} />
    }

    terms = terms.reverse()

    const Form = existingFormContext ?
        Fragment :
        FormContext

    const radio = bundles.length === 1 && interaction === 'radio'
    const toggle = bundles.length > 1 || interaction === 'toggle'

    return (
        <ContainerQuery>
            <Form>
                {(radio && !editable) && (
                    <Columns>
                        <FlexChildGrow>
                            <TermDisplay term={value} />
                        </FlexChildGrow>
                        {!!setValue && (
                            <FlexChildShrink>
                                <Plain
                                    className="constructive text-aligned"
                                    onClick={() => setEditable(true)}>
                                    <FormattedMessage
                                        id="action_change"
                                        defaultMessage="Change" />
                                </Plain>
                            </FlexChildShrink>
                        )}
                    </Columns>
                )}
                {(radio && editable) && (
                    <RadiobuttonsField
                        salt={salt}
                        label={false}
                        name="term"
                        className="compact"
                        framed
                        direction="rtl"
                        field={{
                            value,
                            include: 'never'
                        }}
                        options={terms.map(term => {
                            const frequency = getPlanFrequency({ term })

                            let name = formatMessage({
                                id: `frequency_${frequency}_payment`,
                                defaultMessage: `${frequency} billing`
                            })

                            const [monthly, yearly] = bundles[0].chargePlans
                                .flatMap(({ mainCharge }) => mainCharge.currencies?.[currency]?.[0]?.price)
                                .sort((one, two) => one - two)

                            if(frequency === 'yearly' && !!monthly && !!yearly) {
                                const percent = Math.abs(Math.floor((yearly - monthly) / yearly * 100))

                                if(percent > 0) {
                                    name = (
                                        <Columns>
                                            <FlexChildGrow>
                                                <Value className="compact">
                                                    {name}
                                                </Value>
                                            </FlexChildGrow>
                                            <FlexChildShrink>
                                                <StatusLabel className="active">
                                                    <FormattedMessage
                                                        id="payment_information_save_percent"
                                                        defaultMessage="Save {percent} %"
                                                        values={{ percent }} />
                                                </StatusLabel>
                                            </FlexChildShrink>
                                        </Columns>
                                    )
                                }
                            }

                            return {
                                value: term,
                                name
                            }
                        })}
                        onChange={({ term }) => setValue(term)} />
                )}
                {toggle && (
                    <ToggleLayout>
                        <InlineMessage
                            type="info"
                            className="compact"
                            size="regular"
                            icon={Offer}
                            message={formatMessage({
                                id: 'payment_information_save_longer_term',
                                defaultMessage: 'Save about 20 % with yearly billing'
                            })} />
                        <RadioField
                            salt={salt}
                            label={false}
                            className="compact"
                            name="term"
                            field={{
                                value,
                                unsettable: false
                            }}
                            options={terms.map(term => {
                                const frequency = getPlanFrequency({ term })

                                return {
                                    value: term,
                                    label: formatMessage({
                                        id: `frequency_${frequency}`,
                                        defaultMessage: frequency
                                    })
                                }
                            })}
                            onChange={({ term }) => setValue(term)} />
                    </ToggleLayout>
                )}
            </Form>
        </ContainerQuery>
    )
}

export const TermDisplay = ({ term, as }) => {
    const frequency = getPlanFrequency({ term })
    const Wrapper = as ?? Value

    return (
        <Wrapper className="compact">
            <FormattedMessage
                id={`frequency_${frequency}_payment`}
                defaultMessage={`${frequency} billing`} />
        </Wrapper>
    )
}
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { paragraphSmallCss } from 'components/typography/paragraph'
import { Columns as ColumnsBase } from 'components/flex'

export const Error = styled(motion.p).attrs(() => ({
    className: 'destructive',
    variants: {
        hide: {
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        reveal: {
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .5
            }
        }
    },
    initial: 'hide'
}))`
    position: absolute;
    top: calc(100% + 8px);
    left: auto;
    ${paragraphSmallCss}
`

export const Columns = styled(ColumnsBase)`
    column-gap: 8px;
`
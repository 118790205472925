import React, { forwardRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { mergeRefs } from 'react-merge-refs'
import { useFlash } from 'hooks/flash'
import { getEquipmentTypeUrl } from 'utilities/url'
import {
    PieceWrapper,
    EquipmentCell, Symbol, EquipmentTypeTitle, EquipmentMessage,
    StatusCell,
    AssignedToCell,
    ActionsCell
} from './s'
import MobileLabel from 'components/typography/mobile-label'
import Ellipsify from 'components/ellipsify'
import ContextMenu, { getActionsArray } from 'widgets/context-menu'

const EquipmentType = forwardRef(({ type, actions = [], fromGroup = null, flash }, ref) => {
    const { formatMessage } = useIntl()

    const flasher = useFlash(flash)

    const {
        id,
        name,
        symbol,
        missingRequiredDocumentation,
        missingRequiredSerialNumber,
        userCount = 0,
        unassignedCount = 0,
        deactivatedUserCount = 0,
        expired,
        expireSoon
    } = type

    const salt = `equipment:type:${id}`

    return (
        <PieceWrapper ref={mergeRefs([ref, flasher])}>
            <EquipmentCell
                to={getEquipmentTypeUrl({ id })}
                {...(fromGroup ? { state: { fromGroup } } : null)}>
                <Symbol
                    symbol={symbol}
                    className="hover-effect" />
                <EquipmentTypeTitle>
                    <Ellipsify text={name} />
                </EquipmentTypeTitle>
            </EquipmentCell>
            <AssignedToCell>
                <MobileLabel>
                    <FormattedMessage
                        id="label_assigned_to"
                        defaultMessage="Assigned to" />
                </MobileLabel>
                {(!!userCount || !!deactivatedUserCount) && (
                    <div>
                        <span>
                            <FormattedMessage
                                id="people_count"
                                defaultMessage="{count, plural, =0 {No people} =1 {One person} other {{count} people}}"
                                values={{ count: userCount + deactivatedUserCount }} />
                        </span>
                        {!!deactivatedUserCount && (
                            <span>
                                <FormattedMessage
                                    id="deactivated_count"
                                    defaultMessage="{count, plural, =0 {0 deactivated} =1 {1 deactivated} other {{count} deactivated}}"
                                    values={{ count: deactivatedUserCount }} />
                            </span>
                        )}
                    </div>
                )}
                {!!unassignedCount && (
                    <div>
                        <FormattedMessage
                            id="unassigned_count"
                            defaultMessage="{count, plural, =0 {0 unassigned} =1 {1 unassigned} other {{count} unassigned}}"
                            values={{ count: unassignedCount }} />
                    </div>
                )}
                {(!userCount && !unassignedCount) && '–'}
            </AssignedToCell>
            <StatusCell>
                {!!expired && (
                    <EquipmentMessage
                        message={formatMessage({
                            id: 'item_status_expired_count',
                            defaultMessage: '{count, plural, =0 {} =1 {One has expired} other {{count} have expired}}'
                        }, { count: expired })}
                        type="error" />
                )}
                {!!expireSoon && (
                    <EquipmentMessage
                        message={formatMessage({
                            id: 'item_status_expiring_count',
                            defaultMessage: '{count, plural, =0 {} =1 {One expiring soon} other {{count} expiring soon}}'
                        }, { count: expireSoon })}
                        type="warning" />
                )}
                {!!missingRequiredDocumentation && (
                    <EquipmentMessage
                        message={formatMessage({
                            id: 'item_status_missing_documentation_count',
                            defaultMessage: '{count, plural, =0 {} =1 {Documentation missing} other {{count} are missing documentation}}'
                        }, { count: missingRequiredDocumentation })}
                        type="error" />
                )}
                {!!missingRequiredSerialNumber && (
                    <EquipmentMessage
                        message={formatMessage({
                            id: 'item_status_missing_serial_number_count',
                            defaultMessage: '{count, plural, =0 {} =1 {Serial number missing} other {{count} are missing serial number}}'
                        }, { count: missingRequiredSerialNumber })}
                        type="error" />
                )}
            </StatusCell>
            {!!getActionsArray(actions)?.length && (
                <ActionsCell>
                    <ContextMenu
                        salt={salt}
                        context={type}
                        actions={actions} />
                </ActionsCell>
            )}
        </PieceWrapper>
    )
})

export default EquipmentType

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { empty } from './'
import { pruneBy } from 'utilities/array'
import { TriggerBlobs as Entities } from 'components/entities'
import { Columns, IconColumn } from './s'
import { getEntityIcon } from 'components/entity'

const ViewEntities = ({ className, label, salt, ...props }) => {
    const {
        name,
        field = {},
        picker = {},
        entity = {},
        displayProps = {}
    } = props

    const { locked = empty } = picker

    let { value } = field
    if(!value) {
        value = empty
    }

    salt = `${salt}:${name}`

    const entities = [...locked, ...value]
    const differentTypes = pruneBy(entities, 'type').length > 1

    const EntityIcon = differentTypes ?
        getEntityIcon(entity.type) :
        null

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <Columns>
                    <div>
                        {!!entities.length && (
                            <Entities
                                {...displayProps}
                                entities={entities}
                                type={entity.type}
                                salt={salt} />
                        )}
                        {!entities && (
                            <ValueDisplay className="empty">
                                <FormattedMessage
                                    id="empty_none_picked"
                                    defaultMessage="None picked" />
                            </ValueDisplay>
                        )}
                    </div>
                    {!!EntityIcon && (
                        <IconColumn>
                            <EntityIcon size={20} />
                        </IconColumn>
                    )}
                </Columns>
            </Control>
        </Field>
    )
}

export default ViewEntities

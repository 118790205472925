import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { H2 } from 'components/typography/heading'
import { OrderedList } from 'components/list'
import Link from 'components/link'

export const Heading = H2

export const CalendarListWrapper = styled(OrderedList)`
    &.bordered {
        border-radius: 8px;
        border: 1px solid var(--huma-color-border-default);
        padding: 0 16px;
    }
`

export const ListItem = styled(Link)`
    display: grid;
    grid-template-columns: 40px 1fr 24px;
    column-gap: 16px;

    padding: 16px 0;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }
`

export const IconWrap = styled.div`
    ${svgStroke()}
`

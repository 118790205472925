import React, { Component, createRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { FormattedMessage, injectIntl } from 'react-intl'
import { FormContext } from 'components/form/controller'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { Field, Label, Control, Placeholder, Error } from 'components/form/field/s'
import Toggler from 'components/form/input/toggler'
import { formatDataTime, DisplayTime } from './'
import TimePicker from 'modals/time-picker'

const getEmptyValue = range => range ?
    [] :
    null

class EditTime extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const {
            field = {},
            forwardedRef
        } = props

        let {
            value,
            range = false
        } = field

        const empty = getEmptyValue(range)

        if(!value) {
            value = empty
        }

        this.state = {
            value: this.getDataFormattedTime(value),
            picking: false
        }

        this.register()

        this.field = createRef()
        this.control = createRef()

        this.combinedControlRef = mergeRefs([
            this.control,
            forwardedRef
        ])
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.context.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ forwardedRef, name, field }, { value }) => {
        const forwardedRefChanged = forwardedRef !== this.props.forwardedRef
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const rangeChanged = field?.range !== this.props.field?.range
        const allowOpenEndedChanged = field?.allowOpenEnded !== this.props.field?.allowOpenEnded
        const includeChanged = field?.include !== this.props.field?.include

        if(forwardedRefChanged) {
            this.combinedControlRef = mergeRefs([
                this.control,
                this.props.forwardedRef
            ])
        }

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.context.unregisterField(name)
            this.register()
        }

        if(requiredChanged || rangeChanged || allowOpenEndedChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.context.triggerChange(this.props.name)
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.context.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            name,
            field = {},
        } = this.props

        let {
            value,
            range = false,
            allowOpenEnded = false,
            required = false,
            include = 'touched'
        } = field

        if(include === 'never') {
            return
        }

        const empty = getEmptyValue(range)

        if(!value) {
            value = empty
        }

        this.context.registerField(name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                value: empty,
                picking: false
            }, () => this.props.onChange?.({ [name]: empty })),

            validator: value => {
                if(required) {
                    if(range && Array.isArray(value)) {
                        return value[allowOpenEnded ? 'some' : 'every'](Boolean)
                    }

                    return !!value
                }

                return true
            }
        }, update)
    }

    openPicker = () => this.setState({ picking: true })
    closePicker = () => this.setState({ picking: false })
    unset = () => this.onPicked(getEmptyValue(this.props.field?.range ?? false))

    getDataFormattedTime = (time = '') => {
        const { mode = 'date' } = this.props
        const range = this.props.field?.range ?? false

        const dataTimeFormatter = formatDataTime(mode)

        if(range && Array.isArray(time)) {
            return time.map(dataTimeFormatter)
        } else if(time) {
            return dataTimeFormatter(time)
        }

        return time
    }

    onPicked = value => {
        value = this.getDataFormattedTime(value)
        this.setState({ value })

        const { onChange, name } = this.props
        onChange?.({ [name]: value })

        this.context.triggerChange(name)
    }

    render() {
        const {
            value = '',
            picking
        } = this.state

        const {
            className,
            controlProps = {},
            controlContent = null,
            label,
            salt,
            name,
            mode = 'date',
            enabled = true,
            loading = false,
            field = {},
            picker = {},
            intl: { formatMessage },
            error: outsideError,
            ...props
        } = this.props

        const touched = this.context.touched.includes(name)
        const error = ((name in this.context.errors) && touched) || !!outsideError

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const id = salt

        const {
            required,
            softRequired,
            optional,
            unsettable = true,
            placeholder,
            range = false,
            allowOpenEnded = false
        } = field

        const hasValue = (range && Array.isArray(value)) ?
            value.some(Boolean) :
            !!value

        return (
            <Field
                {...(fieldClassName ? { className: fieldClassName } : null)}
                ref={this.field}>
                {!!label && (
                    <Label
                        htmlFor={id}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    {(range && Array.isArray(value)) && (
                        <>
                            <input
                                name={`${name}[0]`}
                                defaultValue={value[0]}
                                type="hidden"
                                key={`${id}:input:range:from:${value[0]}`} />
                            <input
                                name={`${name}[1]`}
                                defaultValue={value[1]}
                                type="hidden"
                                key={`${id}:input:range:to:${value[1]}`} />
                        </>
                    )}
                    {!range && (
                        <input
                            name={name}
                            defaultValue={value}
                            type="hidden"
                            key={`${id}:input:${value}`} />
                    )}
                    <Toggler
                        {...controlProps}
                        {...omit(props, 'field')}
                        id={id}
                        onClick={this.openPicker}
                        active={picking}
                        unset={(unsettable && hasValue) && this.unset}
                        type={mode === 'time' ? 'time' : 'date'}
                        disabled={!enabled}
                        ref={this.combinedControlRef}>
                        {hasValue && (
                            <DisplayTime
                                time={value}
                                mode={mode}
                                short={range} />
                        )}
                        {(range && !hasValue && !placeholder) && (
                            <Placeholder>
                                <FormattedMessage
                                    id="date_range_empty"
                                    defaultMessage="From – till date" />
                            </Placeholder>
                        )}
                        {(!range && !hasValue && placeholder) && (
                            <Placeholder>
                                {placeholder}
                            </Placeholder>
                        )}
                    </Toggler>
                    <TimePicker
                        show={picking}
                        mode={mode}
                        salt={id}
                        label={label}
                        time={value}
                        {...picker}
                        range={range}
                        allowOpenEnded={allowOpenEnded}
                        dismiss={this.closePicker}
                        cancelAction={() => ({
                            label: formatMessage({
                                id: 'action_cancel',
                                defaultMessage: 'Cancel'
                            }),
                            effect: 'neutral',
                            onClick: this.closePicker
                        })}
                        doneAction={({ picked }) => ({
                            label: formatMessage({
                                id: 'action_done',
                                defaultMessage: 'Done'
                            }),
                            effect: 'constructive',
                            onClick: () => this.onPicked(picked)
                        })}
                        key={`${id}:picker:${value}`} />
                    <Error animate={outsideError ? 'reveal' : 'hide'}>
                        {outsideError}
                    </Error>
                    {controlContent}
                </Control>
            </Field>
        )
    }
}

export default injectIntl(EditTime)
import React from 'react'
import { useIntl } from 'react-intl'
import { FilterRow, Form, SearchIcon, SearchField } from './s'

const Search = ({ search, setSearch, salt }) => {
    const { formatMessage } = useIntl()

    salt = `${salt}:search`

    return (
        <FilterRow>
            <Form>
                <SearchField
                    salt={salt}
                    label={false}
                    placeholder={formatMessage({
                        id: 'person_action_find',
                        defaultMessage: 'Find person'
                    })}
                    name="search"
                    field={{
                        value: search,
                        unsettable: true
                    }}
                    controlProps={{ autoFocus: true }}
                    onChange={({ search }) => setSearch(search)} />
                <SearchIcon size={16} />
            </Form>
        </FilterRow>
    )
}

export default Search

import React from 'react'
import { Links } from './s'
import Link from 'components/link'
import AppStore from 'static/gfx/app-badges/app-store.svg'
import GooglePlay from 'static/gfx/app-badges/google-play.svg'

const AppLinks = props => (
    <Links {...props}>
        <Link
            href="https://apps.apple.com/app/huma-as/id1513880094"
            target="_blank">
            <img
                src={AppStore}
                height="40" />
        </Link>
        <Link
            href="https://play.google.com/store/apps/details?id=com.huma.app"
            target="_blank">
            <img
                src={GooglePlay}
                height="40" />
        </Link>
    </Links>
)

export default AppLinks
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { splitName } from 'utilities/file'
import { size } from 'utilities/object'
import FileSize from 'components/file-size'
import { Aside, Kicker, FileNameIcon, Meta, FakeLabel, ContextMenu } from './s'
import Ellipsify from 'components/ellipsify'
import MetaUploaded from 'components/meta'
import {
    Download,
    Trash2 as Delete
} from 'styled-icons/feather'

const PreviewDocumentDetails = ({ file = {}, uploadedBy = null, uploadedAt = null, download, remove, dismiss, salt }) => {
    const { formatMessage } = useIntl()

    let {
        name,
        extension
    } = file ?? {}

    if(name && !extension) {
        const split = splitName(name)
        name = split.name
        extension = split.extension
    }

    const actions = {
        download: () => {
            if(!download) {
                return
            }

            return {
                element: 'downloader',
                salt: `${salt}:download`,
                icon: <Download size={24} />,
                label: formatMessage({
                    id: 'action_download',
                    defaultMessage: 'Download'
                }),
                effect: 'neutral',
                download
            }
        },
        remove: () => {
            if(!remove) {
                return
            }

            return {
                salt: `${salt}:remove`,
                icon: <Delete size={24} />,
                label: formatMessage({
                    id: 'action_delete',
                    defaultMessage: 'Delete'
                }),
                effect: 'destructive',
                onClick: async () => {
                    await remove()
                    dismiss()
                },
                prompt: {
                    question: formatMessage({
                        id: 'document_action_delete_prompt_question',
                        defaultMessage: 'Confirm deleting {document}'
                    }, {
                        document: (
                            <Ellipsify
                                text={`${name}.${extension}`}
                                head={9}
                                tail={9}
                                tooltipProps={{ maxWidth: 'none' }} />
                        )
                    }),
                    confirmText: formatMessage({
                        id: 'action_delete',
                        defaultMessage: 'Delete'
                    })
                }
            }
        }
    }

    return (
        <Aside>
            <Kicker>
                <FormattedMessage
                    id="noun_details"
                    defaultMessage="Details" />
            </Kicker>
            <Meta>
                <FileNameIcon
                    name={name}
                    extension={extension}
                    ellipsify={false} />
                {!!file && (
                    <FakeLabel className="compact indent">
                        <FileSize bytes={file.size} />
                    </FakeLabel>
                )}
            </Meta>
            {!!size(actions) && (
                <ContextMenu
                    actions={actions}
                    context={file}
                    salt={salt} />
            )}
            {uploadedBy && (
                <MetaUploaded
                    type="uploadedBy"
                    by={uploadedBy}
                    {...uploadedAt ? { at: uploadedAt } : null}
                    time="fixed" />
            )}
            {(!uploadedBy && uploadedAt) && (
                <MetaUploaded
                    type="uploaded"
                    at={uploadedAt}
                    time="fixed" />
            )}
        </Aside>
    )
}


export default PreviewDocumentDetails

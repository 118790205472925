import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { mergeRefs } from 'react-merge-refs'
import { useFlash } from 'hooks/flash'
import { getMeetingTemplateUrl } from 'utilities/url'
import { getFullName } from 'utilities/person'
import {
    Template,
    TemplateCell, Symbol, MetaCell, Name,
    UpdatedCell, Byline,
    ActionsCell
} from './s'
import { CaptionSmall as Meta } from 'components/typography/caption'
import RelativeTime from 'components/time-relative'
import { Plain } from 'components/button'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'
import { fallbackSymbol } from 'pages/meetings'

const TemplateItem = forwardRef(({ template, actions, setPreviewing = () => {}, flash }, ref) => {
    const flasher = useFlash(flash)

    const {
        id,
        title,
        description,
        symbol,
        name,
        updatedAt,
        updatedBy,
        agenda = []
    } = template

    const preview = {
        agenda,
        name: title,
        description,
        symbol
    }

    const startMeetingAction = actions?.use?.(template)
    const boundActions = getBoundActions(actions, template)

    const salt = `meeting:template:${id}`

    return (
        <Template ref={mergeRefs([ref, flasher])}>
            <TemplateCell
                {...((!!updatedAt && updatedBy) ?
                    { to: getMeetingTemplateUrl({ id }) } :
                    { onClick: () => setPreviewing?.(preview) }
                )}>
                <Symbol
                    symbol={symbol ?? fallbackSymbol}
                    className="hover-effect" />
                <MetaCell>
                    <Name className="compact">{name}</Name>
                    <Meta className="compact">
                        <FormattedMessage
                            id="meeting_talking_points_count"
                            defaultMessage="{count, plural, =0 {0 talking points} =1 {1 talking point} other {{count} talking points}}"
                            values={{ count: agenda.length }} />
                    </Meta>
                </MetaCell>
            </TemplateCell>
            <UpdatedCell>
                {updatedBy && (
                    <>
                        <span>
                            <RelativeTime date={updatedAt} />
                        </span>
                        <Byline name={getFullName(updatedBy)} />
                    </>
                )}
            </UpdatedCell>
            {(!!startMeetingAction || !!boundActions?.length) && (
                <ActionsCell>
                    {!!startMeetingAction && (
                        <Plain
                            onClick={startMeetingAction.onClick}
                            className="constructive">
                            {startMeetingAction.label}
                        </Plain>
                    )}
                    {!!boundActions?.length && (
                        <ContextMenu
                            prebound actions={boundActions}
                            salt={salt} />
                    )}
                </ActionsCell>
            )}
        </Template>
    )
})

export default TemplateItem
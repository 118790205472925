import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'

export const Table = styled.table`
    margin: -9px;
    border: none;
    border-collapse: collapse;
    width: calc(100% + 18px);

    svg {
        ${svgStroke('small')}
    }
`

export const TableHead = styled.thead``

export const TableBody = styled.tbody``

export const TableRow = styled.tr`
    ${TableBody} &:nth-child(odd) {
        background-color: color-mix(in srgb, var(--huma-color-surface-subtle), transparent 75%);
    }
`

export const TableHeader = styled.th`
    vertical-align: middle;

    border-top: 1px solid transparent;
    border-bottom: 1px solid var(--huma-color-border-default);
    height: 28px;

    font-weight: 400;

    ${TableHead} & {
        border-right: 1px solid var(--huma-color-border-default);

        &:last-of-type {
            border-right-color: transparent;
        }
    }

    ${TableBody} & {
        width: 250px; /* This tooltip doesn’t show up on smaller screens, so we can hardcode this */
        padding-inline: 8px;

        text-align: start;
        color: var(--huma-color-foreground-default);
    }

    ${TableBody} ${TableRow}:last-child & {
        border-bottom-color: transparent;
    }

    ${TableRow}.label & {
        padding-inline-start: 32px;

        color: var(--huma-color-foreground-subtle);
    }
`

export const TableCell = styled.td`
    border: 1px solid var(--huma-color-border-default);
    width: 28px;
    height: 28px;

    text-align: center;

    &:last-of-type {
        border-right-color: transparent;
    }

    ${TableRow}:last-child & {
        border-bottom-color: transparent;
    }

    &.positive {
        color: var(--huma-color-foreground-success);
    }

    &.negative {
        color: var(--huma-color-foreground-error);
    }
`

export const EntityWrapper = styled.div`
    display: grid;
    grid-template-columns: 16px minmax(0, 1fr);
    align-items: center;
    column-gap: 8px;

    > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Plain } from 'components/button'

export const Wrap = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    align-items: center;
    justify-content: start;
    column-gap: 4px;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    height: 40px;
    padding-inline-end: 8px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-high);
`

export const Icon = styled(Grid)`
    place-items: center;

    margin-inline-end: 8px;
    border-right: 1px solid var(--huma-color-border-default);
    border-radius: 8px 0 0 8px;
    width: 40px;
    height: 100%;
    background-color: var(--huma-color-surface-elevated-sh);

    svg {
        ${svgStroke('small')}
    }
`

export const Button = styled(Plain)`
    height: 24px;
    padding: 0 8px;

    line-height: 24px;
`
import { useState, useLayoutEffect, useMemo } from 'react'
import { createSingleton } from 'tippy.js'
import { useMutableBox } from 'hooks/mutable-box'

const useSingletonCreator = ({ disabled = false, overrides = [] } = {}) => {
    const [mounted, setMounted] = useState(false)
    const [rendered, setRendered] = useState(false)

    const mutableBox = useMutableBox({ children: [] })

    useLayoutEffect(() => {
        if(!mounted) {
            setMounted(true)
            return
        }

        const {
            children,
            sourceData
        } = mutableBox

        if(!sourceData) {
            return
        }

        const instance = createSingleton(
            children?.map(child => child.instance),
            {
                ...sourceData.props,
                popperOptions: sourceData.instance.props.popperOptions,
                overrides,
                plugins: [
                    ...sourceData.instance.props.plugins,
                    {
                        fn: () => ({
                            onMount() {
                                setRendered(true)
                            }
                        })
                    }
                ]
            }
        )

        mutableBox.instance = instance

        if(disabled) {
            instance.disable()
        }

        return () => {
            instance.destroy()
            mutableBox.children = children?.filter(({ instance }) => !instance.state.isDestroyed)
        }
    }, [mounted, disabled, overrides])

    useLayoutEffect(() => {
        if(!mounted) {
            return
        }

        if(!rendered) {
            setRendered(true)
            return
        }

        const {
            children,
            instance,
            sourceData
        } = mutableBox

        if(!(instance && sourceData)) {
            return
        }

        const { ...props } = sourceData.props

        instance.setProps({
            ...props,
            overrides
        })

        instance.setInstances(children?.map(child => child.instance))

        if(disabled) {
            instance.disable()
        } else {
            instance.enable()
        }
    }, [mounted, rendered, disabled, overrides])

    return useMemo(() => {
        const source = {
            data: mutableBox,
            hook(data) {
                mutableBox.sourceData = data
                mutableBox.setSingletonContent = data.setSingletonContent
            },
            cleanup() {
                mutableBox.sourceData = null
                mutableBox.setSingletonContent = null
            }
        }

        const target = {
            hook(data) {
                mutableBox.children = mutableBox.children?.filter(({ instance }) => data.instance !== instance)

                mutableBox.children?.push(data)

                if(
                    mutableBox.instance?.state.isMounted &&
                    mutableBox.instance?.state.$$activeSingletonInstance == data.instance
                ) {
                    mutableBox.setSingletonContent?.(data.content)
                }

                if(mutableBox.instance && !mutableBox.instance.state.isDestroyed) {
                    mutableBox.instance.setInstances(mutableBox.children?.map(child => child.instance))
                }
            },
            cleanup(instance) {
                mutableBox.children = mutableBox.children?.filter(({ instance: childInstance }) => childInstance !== instance)

                if(mutableBox.instance && !mutableBox.instance.state.isDestroyed) {
                    mutableBox.instance.setInstances(mutableBox.children.map(child => child.instance))
                }
            }
        }

        return [source, target]
    }, [mutableBox])
}

export default useSingletonCreator
import React, { useMemo, useCallback, Fragment } from 'react'
import { useIntl } from 'react-intl'
import { useExtensions } from '../hooks/extensions'
import { useSmartEntity } from 'contexts/smart-entity'
import { getExtensionAvailability, getValue } from '../'
import { generateHTML } from '@tiptap/core'
import { discardMarksForDisallowedTypes } from '../extensions/variable/utilities'
import Matcher from './matcher'
import { parseFragment } from 'parse5'
import { slugify, camelize } from 'utilities/string'
import { getReactAttribute } from 'utilities/react'
import { Sheriff as H1, H2, H3, H4 } from 'components/typography/heading'
import { StylesContainer, Paragraph, Blockquote, CodeBlock, CodeInline, Variable, Video, Iframe, VideoIframe, Emoji, HorizontalRule } from './s'
import { UnorderedList, OrderedList, ListItem } from 'components/typography/list'
import Link from 'components/link'
import Mark, { Highlight } from 'components/typography/mark'
import { getPeopleProfileUrl, getPeopleTeamUrl, getPeopleLocationUrl } from 'utilities/url'

const EditorOutput = ({
    content,
    element: Container = 'div',
    matchTerms,
    overrides = null,
    dependencies = [],
    salt,
    ...props
}) => {
    const { every, some } = getExtensionAvailability({ preset: 'maximal' })

    const extensions = useExtensions({
        mode: 'view',
        every,
        some,
        output: true
    })

    const value = getValue(discardMarksForDisallowedTypes({ content }).content)

    const html = useMemo(() => {
        return generateHTML(value, extensions)
    }, [value, extensions, dependencies])

    const parseAndAnnotate = useCallback(html => {
        if(!!matchTerms?.join('+')?.length) {
            const matcher = new Matcher(html, matchTerms)
            html = matcher.render()
        }

        return parseFragment(html).childNodes
    }, [matchTerms?.join('+') ?? ''])

    if(!html) {
        return null
    }

    const getElement = elementMapper({ overrides, extensions })

    return (
        <StylesContainer
            as={Container}
            {...props}
            key={salt}>
            {htmlToJsx(parseAndAnnotate(html), {
                getElement,
                parentTagName: 'root',
                key: salt
            })}
        </StylesContainer>
    )
}

const htmlToJsx = (nodes, { getElement, parentTagName, key = '' }) => nodes.map((node, index) => {
    if(node.nodeName === '#text') {
        return (
            <Fragment key={`text:${index}`}>
                {decode(node.value)}
            </Fragment>
        )
    }

    key = `${key}-${node.nodeName}:${index}`

    // Complex React components that need to be rerendered
    if(['mark'].includes(node.nodeName)) {
        key = `${key}:${Date.now()}`
    }

    let children = node.childNodes.length ?
        htmlToJsx(node.childNodes, {
            getElement,
            parentTagName: node.nodeName,
            key
        }) :
        null

    let attributes = node.attrs.reduce((accumulator, { name, value }) => ({
        ...accumulator,
        [getReactAttribute(name)]: booleanStringToBoolean(name, value)
    }), {})

    if(node.nodeName === 'mark' && attributes.className === 'match') {
        node.nodeName = 'search'
    }

    if(node.nodeName === 'pre') {
        const encoded = node.childNodes?.[0]?.value

        const code = encoded ?
            decode(encoded) :
            ' '

        attributes.language = attributes['data-language']
        attributes.code = code

        delete attributes['data-language']
        delete attributes.childNodes
    }

    if(node.nodeName === 'code' && attributes.className === 'variable') {
        node.nodeName = 'variable'
    }

    let [Element, props = null] = getElement({
        type: node.nodeName,
        node,
        attributes
    })

    if('data-youtube-video' in attributes) {
        delete attributes['data-youtube-video']
    }

    // Emoji
    if(attributes?.['data-type'] === 'emoji') {
        const emoji = getElement({
            type: 'emoji',
            node,
            attributes
        })

        Element = emoji[0]
        props = emoji[1]
        attributes = null
    }

    // Mention
    if(!!attributes?.['data-type'] && !!attributes?.['data-uuid'] && !!attributes?.['data-name']) {
        const mention = getElement({
            type: 'mention',
            node,
            attributes
        })

        Element = mention[0]
        props = mention[1]
        attributes = null
    }

    const {
        content,
        ...domProps
    } = props ?? {}

    return (
        <Element
            {...((Element !== Fragment) ? {
                ...attributes,
                ...domProps,
                'data-child-of': parentTagName
            } : null)}
            key={key}>
            {content ?? children}
        </Element>
    )
})

const decode = (() => {
    const textarea = document.createElement('textarea')

    return encoded => {
        textarea.innerHTML = encoded
        return textarea.value
    }
})()

const useGetAnchoredElement = () => {
    const { formatMessage } = useIntl()

    return Element => ({ attributes, node }) => {
        let text = node.childNodes.map(({ value }) => value).join('')
        if(!text) {
            text = formatMessage({
                id: 'toc_heading_empty',
                defaultMessage: '<Empty heading>'
            })
        }

        const id = attributes?.['data-id'] ?? 'huma'

        return [Element, {
            ...attributes,
            id: hashify(text, id)
        }]
    }
}

const useGetDefaultElements = () => {
    const smartEntityContext = useSmartEntity()
    const getAnchoredElement = useGetAnchoredElement()

    return ({
        h1: getAnchoredElement(H1),
        h2: getAnchoredElement(H2),
        h3: getAnchoredElement(H3),
        h4: getAnchoredElement(H4),

        p: () => [Paragraph],

        ul: () => [UnorderedList],
        ol: () => [OrderedList],
        li: () => [ListItem],

        a: () => [Link, { className: 'constructive' }],
        code: () => [CodeInline],
        mark: () => [Mark],
        search: () => [Highlight],

        emoji: () => [Emoji],

        mention: ({ attributes }) => [Link, {
            className: 'neutral bold',
            to: getUrl(attributes['data-type'])({ id: attributes['data-uuid'] }),
            target: '_blank',
            content: `@${attributes['data-name']}`
        }],

        variable: ({ node }) => {
            const sanitizedNode = {
                ...node,
                attrs: node.attrs.reduce((accumulator, { name, value }) => ({
                    ...accumulator,
                    [camelize(name.replace('data-', ''))]: value
                }), {})
            }

            const {
                variable,
                state,
                attributes,
                descriptor,
                Element: as
            } = smartEntityContext.getVariableData(sanitizedNode)

            const content = variable.options.renderHTML?.({
                ...sanitizedNode.attrs,
                descriptor
            }) ?? sanitizedNode.attrs.value ?? ''

            if(state.filled) {
                return [Fragment, { content }]
            }

            return [Variable, {
                className: attributes.className,
                as,
                content: (
                    <span className="content">
                        {content}
                    </span>
                )
            }]
        },

        pre: () => [CodeBlock],
        blockquote: () => [Blockquote],
        iframe: ({ attributes }) => {
            if(attributes?.src?.includes('youtube') || attributes?.src?.includes('vimeo')) {
                return [VideoIframe]
            }

            return [Iframe]
        },
        div: ({ attributes }) => {
            if('data-youtube-video' in (attributes ?? {})) {
                return [Video, { className: 'video youtube' }]
            }

            if('data-vimeo-video' in (attributes ?? {})) {
                return [Video, { className: 'video vimeo' }]
            }

            return [Fragment]
        },

        hr: () => [HorizontalRule]
    })
}

const elementMapper = ({ overrides = {}, extensions }) => ({ type, attributes = {}, node }) => {
    const elements = useGetDefaultElements()
    const getAnchoredElement = useGetAnchoredElement()

    let override = overrides?.[type]
    if(override) {
        if(typeof override === 'function') {
            override = override(attributes)
        }

        let [
            OverrideElement = null,
            overrideAttributes = null,
            options = {}
        ] = override

        const { merge = true } = options

        const [
            DefaultElement,
            defaultAttributes = null
        ] = elements[type]?.({ attributes, node, extensions }) ?? [type]

        if(OverrideElement && ['h1', 'h2', 'h3', 'h4'].includes(type)) {
            const [
                AnchoredElement,
                anchorAttributes = null
            ] = getAnchoredElement(OverrideElement)({ attributes, node, extensions })

            OverrideElement = AnchoredElement

            overrideAttributes = {
                ...overrideAttributes,
                ...anchorAttributes
            }
        }

        elements[type] = ({ attributes }) => [
            OverrideElement ?? DefaultElement,
            {
                ...attributes,
                ...(merge ? defaultAttributes : null),
                ...overrideAttributes
            }
        ]
    }

    return elements[type]?.({ attributes, node, extensions }) ?? [type]
}

const getUrl = type => ({
    user: getPeopleProfileUrl,
    team: getPeopleTeamUrl,
    location: getPeopleLocationUrl
})[type]

const booleanStringToBoolean = (key, value) => {
    if(value === 'true') {
        return true
    }

    if(value === 'false') {
        return false
    }

    return value
}

export const hashify = (text, id) => {
    let hash = `${slugify(text)}-${id.substring(0, 4)}`
    if(/^[0-9]/g.test(hash)) {
        hash = `ref-${hash}`
    }

    return hash
}

export { default as TableOfContents } from './toc'
export default EditorOutput

import React, { useState, useEffect } from 'react'
import { useI18n } from 'contexts/i18n'
import { Select } from './s'
import { omit } from 'utilities/object'
import { compact } from 'utilities/array'
import { empty as emptyOption } from '../'

const CountrySelect = ({ value = '', allowEmptyOption = true, salt, ...props }) => {
    const {
        locale,
        territories
    } = useI18n()

    const [options, setOptions] = useState([])

    useEffect(() => {
        const countries = omit(territories, 'ZZ')

        const options = compact([
            allowEmptyOption && emptyOption,
            ...Object.entries(countries)
                .reduce(optionizeCountries, [])
        ])

        setOptions(options)
    }, [locale, territories, allowEmptyOption])

    return (
        <Select
            {...props}
            value={value}>
            {options.map(({ value, label }, index) => (
                <option
                    value={value}
                    key={`${salt}:${locale}:${value}:${index}`}>
                    {label}
                </option>
            ))}
        </Select>
    )
}

export const optionizeCountries = (accumulator, [value, label]) => [
    ...accumulator,
    { value, label }
]

export default CountrySelect
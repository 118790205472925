import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getResponsibleLabelTranslation } from 'pages/processes/utilities'
import { Meta } from './s'

const AssignedToDynamicResponsible = ({ type }) => (
    <Meta className="compact">
        <FormattedMessage
            id="employee_onboarding_template_assigned_to"
            defaultMessage="<misty>Assigned to</misty> {who}"
            values={{
                misty: text => text,
                who: (
                    <span>
                        <FormattedMessage {...getResponsibleLabelTranslation(type)} />
                    </span>
                )
            }} />
    </Meta>
)

export default AssignedToDynamicResponsible
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useTheme, ThemeProvider } from 'styled-components'
import { useSurvey } from 'contexts/survey'
import { getActionsArray } from 'widgets/context-menu'
import {
    Wrap, Header,
    GridColumnHeader, GridColumnHeaderSortable,
    LoadingContainer
} from './s'
import Comment from './comment'
import Loader from 'components/loader'
import { Ghost } from 'components/button'

const SurveyComments = ({ actions = [], className }) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()

    const {
        survey,
        intersecter,
        fetchingComments,
        sorting = {},
        toggleSorting,
        paging = {},
        fetchComments,
        autoFetchComments
    } = useSurvey()

    const {
        comments = [],
        totalComments = 0
    } = survey ?? {}

    const hasActions = !!getActionsArray(actions).length
    const { hasNextPage } = paging

    theme.surveyComments = {
        actions: hasActions
    }

    return (
        <ThemeProvider theme={theme}>
            <Wrap {...(className ? { className } : null)}>
                <Header>
                    <GridColumnHeaderSortable
                        field="score"
                        sort={sorting}
                        toggle={toggleSorting}
                        text={formatMessage({
                            id: 'noun_category',
                            defaultMessage: 'Category'
                        })} />
                    <GridColumnHeader>
                        <FormattedMessage
                            id="noun_comment"
                            defaultMessage="Comment" />
                    </GridColumnHeader>
                </Header>
            </Wrap>
            {comments?.map((comment, index) => {
                const last = index + 1 === totalComments

                return (
                    <Comment
                        {...{
                            comment,
                            actions,
                            ...((!!intersecter && last) ? { ref: intersecter } : null)
                        }}
                        key={comment.id} />
                )
            })}
            {(!!intersecter && !!hasNextPage) && (
                <LoadingContainer>
                    {(!fetchingComments && !autoFetchComments) && (
                        <Ghost
                            className="constructive"
                            onClick={() => fetchComments()}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!fetchingComments && <Loader />}
                </LoadingContainer>
            )}
        </ThemeProvider>
    )
}

export default SurveyComments

import React, { useRef, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Check } from 'styled-icons/feather'
import { cls } from 'utilities/dom'

export const RadiobuttonInput = styled.input.attrs({
    tabIndex: 0,
    type: 'radio'
})`
    position: absolute;
    opacity: 0;
    pointer-events: none;
`

export const radioCss = css`
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    border: 2px solid var(--huma-color-border-subtle);
    width: 20px;
    height: 20px;

    transition: all .1s ease-in-out;

    color: transparent;

    ${RadiobuttonInput}:not(:disabled):not(:checked) + &:hover,
    ${RadiobuttonInput}:not(:disabled):not(:checked):focus + & {
        border-color: var(--huma-color-border-subtle-hover);
    }

    ${RadiobuttonInput}:not(:disabled):focus + & {
        box-shadow:
            0 0 0 1px var(--huma-color-surface-default),
            0 0 0 3px var(--huma-color-border-constructive-focus);
    }

    ${RadiobuttonInput}:not(:disabled):checked + & {
        border-color: var(--huma-color-surface-constructive-bold);
        background-color: var(--huma-color-surface-constructive-bold);

        color: var(--huma-color-foreground-inverted);
    }

    ${RadiobuttonInput}:disabled:checked + & {
        background-color: var(--huma-color-surface-selected-disabled);

        color: var(--huma-color-foreground-on-selected-disabled);
    }

    ${RadiobuttonInput}:disabled + & {
        cursor: default;

        border-width: 1px;
    }

    &.check svg {
        ${svgStroke()}
    }
`

export const RadiobuttonLabel = styled.label`
    ${radioCss}

    &::after {
        content: "";
        display: flex;

        border-radius: 2px;
        width: 4px;
        height: 4px;
        background-color: var(--huma-color-foreground-inverted);

        opacity: 0;
        transition: .1s ease-in-out;
        transition-property: opacity, background-color;
    }

    ${RadiobuttonInput}:checked + &::after {
        opacity: 1;
    }

    ${RadiobuttonInput}:disabled + & {
        cursor: not-allowed;

        border-color: var(--huma-color-surface-disabled);
        border-width: 1px;
        background-color: var(--huma-color-surface-disabled);
    }
`

export default forwardRef(({ id, className, controlProps = {}, ...props }, forwardedRef) => {
    className = cls([
        className,
        'dot'
    ])

    return (
        <>
            <RadiobuttonInput
                {...props}
                id={id}
                ref={forwardedRef} />
            <RadiobuttonLabel
                {...controlProps}
                {...(className ? { className } : null)}
                htmlFor={id} />
        </>
    )
})

export const Radiobutton = ({ id, className, controlProps = {}, ...props }) => {
    const input = useRef()

    className = cls([
        className,
        'check'
    ])

    return (
        <>
            <RadiobuttonInput
                {...props}
                id={id}
                ref={input} />
            <RadiobuttonLabel
                {...controlProps}
                {...(className ? { className } : null)}
                htmlFor={id}
                onClick={() => requestAnimationFrame(() => input.current.blur())}>
                <Check size={16} />
            </RadiobuttonLabel>
        </>
    )
}

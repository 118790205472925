import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { reduce } from 'utilities/object'
import { getFullName } from 'utilities/person'
import { Columns, FlexChild, FlexChildShrink } from 'components/flex'
import {
    LoadingContainer,
    Hero, Heading,
    ContentRow, Section, SectionHeading, Empty,
    Actions
} from './s'
import { Name as RoleName } from 'components/role'
import Loader from 'components/loader'
import Caption from 'components/typography/caption'
import Search from './search'
import People from './people'
import { Plain } from 'components/button'

const GroupPersonPickerContent = ({ salt, ...props }) => {
    const { locale, formatMessage } = useIntl()

    const [search, setSearch] = useState('')
    const [grantees, setGrantees] = useState([])
    const [members, setMembers] = useState([])

    useEffect(() => {
        if(props.group) {
            setGrantees(getGranteesByRole(props.group.grants, locale, search))
            setMembers(filterUsers(props.group.members, search))
        }
    }, [props.group, search])

    let {
        group,
        picked,
        dismiss,
        doneAction,
        cancelAction,
        heading,
        caption
    } = props

    if(!group) {
        return (
            <LoadingContainer>
                <Loader />
            </LoadingContainer>
        )
    }

    if(!heading) {
        heading = formatMessage({
            id: 'group_person_action_pick',
            defaultMessage: 'Pick a person in {name}'
        }, group)
    }

    const pick = person => {
        doneAction({ picked: person }).onClick()
        dismiss()
    }

    const cancel = cancelAction()

    const peopleProps = {
        picked,
        pick,
        salt
    }

    return (
        <>
            <Hero>
                <Columns>
                    <FlexChild>
                        <Heading>{heading}</Heading>
                    </FlexChild>
                    {!!caption && (
                        <FlexChildShrink>
                            <Caption className="compact">{caption}</Caption>
                        </FlexChildShrink>
                    )}
                </Columns>
            </Hero>
            {(!!group.grants.length || !!group.members.length) && (
                <Search
                    search={search}
                    setSearch={setSearch}
                    salt={salt} />
            )}
            <ContentRow>
                {grantees?.map(({ id, name, deletable, people }) => (
                    <Section key={`${salt}:role:${id}`}>
                        <SectionHeading className="compact">
                            <RoleName
                                name={name}
                                deletable={deletable} />
                        </SectionHeading>
                        <People
                            {...peopleProps}
                            people={people} />
                    </Section>
                ))}
                {!!grantees?.length && (
                    <Section>
                        <SectionHeading className="compact">
                            <FormattedMessage
                                id="noun_members"
                                defaultMessage="Members" />
                        </SectionHeading>
                        <People
                            {...peopleProps}
                            people={members} />
                    </Section>
                )}
                {!grantees?.length && (
                    <People
                        {...peopleProps}
                        people={members} />
                )}
                {(!group.grants.length && !group.members.length) && (
                    <Empty className="compact">
                        <FormattedMessage
                            id="group_person_picker_empty"
                            defaultMessage="No people in {name}"
                            values={group} />
                    </Empty>
                )}
            </ContentRow>
            <FlexChildShrink>
                <Actions className="centered">
                    <Plain
                        className={cancel.effect}
                        onClick={cancel.onClick}>
                        {cancel.label}
                    </Plain>
                </Actions>
            </FlexChildShrink>
        </>
    )
}

const filterUsers = (users, search) => users.filter(user => {
    search = search
        .trim()
        .toLowerCase()

    if(search.length < 2) {
        return true
    }

    const name = getFullName(user).toLowerCase()
    return name.includes(search)
})

const getGranteesByRole = (grants = [], locale, search) => {
    const granteesByRole = grants.reduce((accumulator, { role: { name, id, deletable }, users }) => ({
        ...accumulator,
        [id]: {
            name,
            deletable,
            people: filterUsers(users, search)
        }
    }), {})

    return reduce(granteesByRole, (accumulator, data, id) => [
        ...accumulator,
        { ...data, id }
    ], [])
        .filter(({ people }) => !!people.length)
        .sort(({ name: one }, { name: two }) => {
            return one.localeCompare(two, locale, { sensitivity: 'base' })
        })
}

export default GroupPersonPickerContent

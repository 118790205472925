import React, { useState, useEffect } from 'react'
import { useHandbookTemplate } from 'contexts/handbook-template'
import { clamp } from 'utilities/math'
import {
    Wrap,
    SkeletonListRow
} from './s'
import Category from './category'
import { SkeletonCell, SkeletonCircle, SkeletonStrings } from 'components/skeleton'

const HandbookCategories = ({ template, setTemplate, ...props }) => {
    const {
        fetching,
        hasFetched
    } = useHandbookTemplate()

    const [skeletonLength, setSkeletonLength] = useState(5)

    useEffect(() => {
        if (hasFetched && !!template?.categories?.length) {
            setSkeletonLength(clamp(template?.categories.length, 1, 10))
        }
    }, [hasFetched, template?.categories?.length])

    return (
        <Wrap {...props}>
            {((!!fetching && !hasFetched) || !template?.categories?.length) && [...Array(skeletonLength).keys()].map(index => (
                <SkeletonListRow
                    columns={3}
                    key={`list:handbook:categories:skeleton:${index}`}>
                    <SkeletonCell>
                        <SkeletonCircle size={40} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings length={25} />
                        <SkeletonStrings
                            size={24}
                            length={40} />
                    </SkeletonCell>
                </SkeletonListRow>
            ))}
            {template?.categories?.map(category => (
                <Category
                    category={category}
                    template={template}
                    setTemplate={setTemplate}
                    salt={`handbook-template:category:${category.id}`}
                    key={`handbook-template:category:${category.id}`} />
            ))}
        </Wrap>
    )
}

export default HandbookCategories

import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { modalify, Wrapper, Veil, Modal } from 'utilities/modal'
import PeopleProvider from 'contexts/people'
import { useAppSignal } from 'hooks/app-signal'
import { cls } from 'utilities/dom'
import { FlexChildShrink } from 'components/flex'
import {
    Container, Hero, Herows, Heading,
    ContentRow, Status, EmptyContainer,
    Actions
} from './s'
import Paragraph from 'components/typography/paragraph'
import Message from 'components/message'
import Filter from './filter'
import People from './people'
import { Plain } from 'components/button'

const PersonPicker = ({ className, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const namespace = `${salt}:person-picker:filter`

    const [picked, setPicked] = useState(null)
    const [resettable, setResettable] = useState(false)
    const [filterResetAt, setFilterResetAt] = useState(Date.now())

    useAppSignal({
        namespace: `${namespace}:advanced-applied`,
        action: advancedApplied => setResettable(advancedApplied)
    })

    const {
        params = {},
        filterOverrides = {},
        dismiss,
        doneAction,
        cancelAction,
        heading = formatMessage({
            id: 'person_action_pick',
            defaultMessage: 'Pick person'
        }),
        caption,
        message,
        heroContent,
        emptyText = formatMessage({
            id: 'empty_filter',
            defaultMessage: '🤷 Sorry, nothing was found with your current filter configuration.'
        }),
        enableIsActiveFilter = false,
        ...modal
    } = props

    const {
        show = false,
        outer = true
    } = modal

    useEffect(() => {
        setPicked(props.picked)
        return () => setPicked(null)
    }, [show, props.picked])

    const pick = person => {
        doneAction({ picked: person }).onClick()
        dismiss()
    }

    // TODO: Remove this logic when the API supports combining permission
    // filters with other filters supported by UI components
    const filterable = !params?.permission

    const cancel = cancelAction()
    const animate = show ? 'in' : 'out'

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    {!!outer && <Veil animate={animate} />}
                    <Modal
                        dismiss={dismiss}
                        key={`modal:person-picker:${salt}`}>
                        <PeopleProvider
                            filter={params}
                            autoFetch={true}>
                            {({ people, loading, hasFetched, autoFetch, paging, setPeopleFilter }) => (
                                <Container
                                    {...(className ? { className } : null)}
                                    animate={animate}>
                                    <Hero>
                                        <Herows>
                                            <FlexChildShrink>
                                                <Heading>{heading}</Heading>
                                            </FlexChildShrink>
                                            {!!caption && (
                                                <FlexChildShrink>
                                                    <Paragraph className="caption compact">{caption}</Paragraph>
                                                </FlexChildShrink>
                                            )}
                                            {!!message && (
                                                <FlexChildShrink>
                                                    <Message
                                                        {...message}
                                                        className={cls(['compact', message?.className])} />
                                                </FlexChildShrink>
                                            )}
                                            {!!heroContent && (
                                                <FlexChildShrink>
                                                    {heroContent}
                                                </FlexChildShrink>
                                            )}
                                        </Herows>
                                    </Hero>
                                    {filterable && (
                                        <Filter
                                            params={params}
                                            overrides={filterOverrides}
                                            enableIsActiveFilter={enableIsActiveFilter}
                                            namespace={namespace}
                                            salt={`${salt}:filter`}
                                            key={filterResetAt} />
                                    )}
                                    <ContentRow>
                                        {(!!people.length && !loading && autoFetch) && (
                                            <Status className="caption small compact">
                                                <FormattedMessage
                                                    id={!!paging?.hasNextPage ?
                                                        'picker_status_scroll_to_load_more' :
                                                        'picker_status_all_loaded'
                                                    }
                                                    defaultMessage={!!paging?.hasNextPage ?
                                                        '{count} loaded – scroll to load more' :
                                                        'All {count} are loaded'
                                                    }
                                                    values={{ count: people.length }} />
                                            </Status>
                                        )}
                                        {(!people.length && !loading && hasFetched) && (
                                            <EmptyContainer>
                                                <Paragraph className="caption small">{emptyText}</Paragraph>
                                                {resettable && (
                                                    <Plain
                                                        className="destructive"
                                                        onClick={() => {
                                                            setPeopleFilter({ filter: params })
                                                            setFilterResetAt(Date.now())
                                                        }}>
                                                        <FormattedMessage
                                                            id="action_reset_filters"
                                                            defaultMessage="Reset filters" />
                                                    </Plain>
                                                )}
                                            </EmptyContainer>
                                        )}
                                        <People {...{ picked, pick, salt }} />
                                    </ContentRow>
                                    <FlexChildShrink>
                                        <Actions className="centered">
                                            <Plain
                                                className={cancel.effect}
                                                onClick={cancel.onClick}>
                                                {cancel.label}
                                            </Plain>
                                        </Actions>
                                    </FlexChildShrink>
                                </Container>
                            )}
                        </PeopleProvider>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

export default PersonPicker
import React, { forwardRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { safeFormat } from 'utilities/date-time'
import { currencies } from 'utilities/payment'
import { getFractionDigits } from 'pages/people/pages/profile/panes/about/salary/utilities'
import { periodUnitToPeriodShortMap } from 'pages/people/pages/profile/panes/about/salary'
import {
    Row, Cell,
    Label
} from './s'
import User from './user'
import Paragraph from 'components/typography/paragraph'
import YearsAndMonthsSince from 'components/time-years-and-months-since'
import ContextMenu from 'widgets/context-menu'

const Person = forwardRef(({ person, showingSalaryDevelopment, setShowingSalaryDevelopment, actions = {}, salt }, ref) => {
    const {
        formatNumber,
        formatMessage
    } = useIntl()
    const { dateLocale: locale } = useI18n()

    const {
        employmentStartDate,
        currentSalary
    } = person

    const {
        amount,
        currency,
        periodUnit,
        fromDate,
        toDate
    } = currentSalary ?? {}

    const currencyDisplay = currencies[currency] || 'code'

    return (
        <Row ref={ref}>
            <Cell $cell="name">
                <User
                    who={person}
                    showingSalaryDevelopment={showingSalaryDevelopment}
                    setShowingSalaryDevelopment={setShowingSalaryDevelopment} />
            </Cell>
            <Cell $cell="seniority">
                <Label>
                    <FormattedMessage
                        id="person_label_employment_seniority"
                        defaultMessage="Seniority" />
                </Label>
                <Paragraph className="small compact">
                    {employmentStartDate && <YearsAndMonthsSince start={employmentStartDate} />}
                    {!employmentStartDate && '–'}
                </Paragraph>
            </Cell>
            <Cell $cell="current">
                <Label>
                    <FormattedMessage
                        id="salary_revision_heading_current"
                        defaultMessage="Current" />
                </Label>
                {currentSalary && (
                    <Paragraph className="small compact">
                        <FormattedMessage
                            id="salary_value_frequency"
                            defaultMessage="{salary} per {periodUnit}"
                            values={{
                                salary: formatNumber(amount, {
                                    style: 'currency',
                                    currency,
                                    currencyDisplay,
                                    minimumFractionDigits: getFractionDigits(amount),
                                    maximumFractionDigits: getFractionDigits(amount)
                                }),
                                periodUnit: formatMessage(periodUnitToPeriodShortMap?.[periodUnit] ?? periodUnitToPeriodShortMap.default)
                            }} />
                    </Paragraph>
                )}
                {!currentSalary && '–'}
            </Cell>
            <Cell $cell="effective">
                <Label>
                    <FormattedMessage
                        id="noun_effective_period"
                        defaultMessage="Effective period" />
                </Label>
                <Paragraph className="small compact">
                    {(fromDate || toDate) && (
                        <FormattedMessage
                            id="date_range"
                            defaultMessage="{from} – {to}"
                            values={{
                                from: fromDate ?
                                    safeFormat(fromDate, 'PP', { locale })
                                    : '',
                                to: toDate ?
                                    safeFormat(toDate, 'PP', { locale })
                                    : ''
                            }} />
                    )}
                    {(!fromDate && !toDate) && '–'}
                </Paragraph>
            </Cell>
            <Cell $cell="actions">
                <ContextMenu
                    salt={salt}
                    context={person}
                    actions={actions} />
            </Cell>
        </Row>
    )
})

export default Person
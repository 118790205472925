import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePayment } from 'contexts/payment'
import { openChat } from 'hooks/voiceflow'
import { ContactSales, ContactSupport } from 'components/contact'
import { PromptLink, Channels, ChannelLink } from './s'
import { /*Phone, */Mail as Email } from 'styled-icons/feather'

const ContactEnriched = ({ component: Component, data, dismiss, salt, ...props }) => (
    <Component
        {...props}
        prompt={(
            <FormattedMessage
                id="prompt_contact_sales_or_chat"
                defaultMessage="Got feedback or questions?\nContact our experts by <link>chat<\/link> or…"
                values={{
                    link: text => (
                        <PromptLink
                            className="neutral"
                            onClick={() => {
                                dismiss?.()
                                openChat()
                            }}>
                            {text}
                        </PromptLink>
                    )
                }} />
        )}
        cta={(
            <Channels>
                {/* <ChannelLink
                    className="constructive"
                    href={`tel:${data.phone}`}
                    onClick={dismiss}>
                    <Phone size={16} /> {data.phone}
                </ChannelLink> */}
                <ChannelLink
                    className="constructive"
                    href={`mailto:${data.email}`}>
                    <Email size={16} />
                    {data.email}
                </ChannelLink>
            </Channels>
        )}
        salt={`${salt}:feedback-questions`} />
)

export const ContactSalesEnriched = props => {
    const { locale } = useIntl()
    const { sales } = usePayment()

    const data = {
        ...(sales?.nb ?? null),
        ...(sales?.[locale] ?? null)
    }

    return (
        <ContactEnriched
            {...props}
            component={ContactSales}
            data={data} />
    )
}

export const ContactSupportEnriched = props => {
    const { locale } = useIntl()
    const { support } = usePayment()

    const data = {
        ...(support?.nb ?? null),
        ...(support?.[locale] ?? null)
    }

    return (
        <ContactEnriched
            {...props}
            component={ContactSupport}
            data={data} />
    )
}
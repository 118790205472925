import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useServiceOnboarding } from 'contexts/service-onboarding'
import { useDurationFormatter } from 'utilities/date-time'
import { cls } from 'utilities/dom'
import { omit } from 'utilities/object'
import { Expandable, ProgressTimeEstimate } from './s'
import Progress from 'components/progress'
import Tooltip from 'components/tooltip'
import Step from './step'

const ServiceOnboardingCategory = ({ category, mode, index, activeIndex, last, onExpand, onBeforeAction, salt, ...props }) => {
    const { formatMessage } = useIntl()
    const { locale } = useI18n()
    const { progress } = useServiceOnboarding()
    const formatDuration = useDurationFormatter()

    const [activeStepIndex, setActiveStepIndex] = useState(0)

    useEffect(() => {
        if(mode === 'sequential') {
            setActiveStepIndex(0)
        }
    }, [mode, progress])

    const steps = (mode === 'sequential') ?
        category.todos :
        category.steps

    const title = category.title?.[locale] ?? category.title.en
    const className = cls([props.className, 'service-onboarding', last && 'last'])

    const minutes = Math.floor(category.seconds / 60)
    const seconds = category.seconds % 60

    const stepsLeft = !!category.todos.length ?
        formatMessage({
            id: 'service_onboarding_steps_left',
            defaultMessage: '{count, plural, =0 {} =1 {1 step left} other {{count} steps left}}'
        }, { count: category.todos.length }) :
        null

    return (
        <Expandable
            {...omit(props, 'id')}
            {...(className ? { className } : null)}
            icon={category.emoji}
            heading={title}
            action={(
                <Progress
                    completed={category.steps.length - category.todos.length}
                    total={category.steps.length}
                    mode="children"
                    className="compact">
                    {!!category.todos.length && (
                        <ProgressTimeEstimate
                            className="compact"
                            as="span">
                            {!!category.seconds && (
                                <Tooltip content={formatMessage({
                                    id: 'about_time',
                                    defaultMessage: 'About {time}'
                                }, { time: formatDuration({ minutes, seconds }) })}>
                                    <span>{stepsLeft}</span>
                                </Tooltip>
                            )}
                            {!category.seconds && stepsLeft}
                        </ProgressTimeEstimate>
                    )}
                </Progress>
            )}
            {...((mode === 'sequential') ? {
                expanded: index === activeIndex,
                callback: expanded => expanded && onExpand()
            } : null)}
            salt={salt}
            key={`${salt}:${index}`}>
            {steps.map(({ key, ...step }, index) => {
                const itemSalt = `${salt}:step:${key}`

                return (
                    <Step
                        {...step}
                        id={key}
                        mode={mode}
                        index={index}
                        activeIndex={activeStepIndex}
                        last={index === steps.length - 1}
                        onOpen={() => setActiveStepIndex(index)}
                        onBeforeAction={onBeforeAction}
                        salt={itemSalt}
                        key={itemSalt} />
                )
            })}
        </Expandable>
    )
}

export default ServiceOnboardingCategory
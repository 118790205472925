import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useEquipmentPieces } from 'contexts/equipment-pieces'
import { clamp } from 'utilities/math'
import { size } from 'utilities/object'
import {
    Wrap,
    Header, GridColumnHeader,
    LoadingContainer, SkeletonCell
} from './s'
import Tooltip, { useSingleton } from 'components/tooltip'
import { SkeletonListRow, SkeletonAvatarAndFullName, SkeletonStrings } from 'components/skeleton'
import Paragraph from 'components/typography/paragraph'
import Empty from 'components/empty'
import Loader from 'components/loader'
import { Ghost } from 'components/button'
import Piece from './piece'

const EquipmentTypePieces = ({ actions = {}, className }) => {
    const {
        pieces,
        intersecter,
        loading,
        paging = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched,
        filter = {},
        flash,
        clearFlash
    } = useEquipmentPieces()

    const { hasNextPage } = paging

    const [source, target] = useSingleton()

    const [skeletonLength, setSkeletonLength] = useState(5)

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(pieces.length, 1, 10))
        }
    }, [pieces?.length])

    const filtersApplied = !!size(filter)

    const emptyPieces = (!pieces?.length && hasFetched && !fetching)

    return (
        <Wrap {...(className ? { className } : null)}>
            <Tooltip
                singleton={source}
                placement="top-start"
                delay={[500, 250]} />
            {((!!pieces?.length && hasFetched) || fetching) && (
                <Header>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="label_assigned_to"
                            defaultMessage="Assigned to" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="equipment_label_variant"
                            defaultMessage="Variant" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="noun_status"
                            defaultMessage="Status" />
                    </GridColumnHeader>
                </Header>
            )}
            {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                <SkeletonListRow
                    columns={3}
                    hasActions={true}
                    key={`list:competence-types:skeleton:${index}`}>
                    <SkeletonCell>
                        <SkeletonAvatarAndFullName size={24} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings size={20} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings size={20} />
                    </SkeletonCell>
                </SkeletonListRow>
            ))}
            {(emptyPieces && !filtersApplied) && (
                <Empty which="equipment">
                    <Paragraph className="compact">
                        <FormattedMessage
                            id="equipment_empty_message"
                            defaultMessage="No equipment has been added yet." />
                    </Paragraph>
                </Empty>
            )}
            {(emptyPieces && !!filtersApplied) && (
                <Empty which="equipment">
                    <Paragraph className="compact">
                        <FormattedMessage
                            id="equipment_empty_filter_message"
                            defaultMessage="🤷 Sorry, no equipment found." />
                    </Paragraph>
                </Empty>
            )}
            {pieces.map((piece, index) => {
                const last = index + 1 === pieces.length

                return (
                    <Piece
                        piece={piece}
                        actions={actions}
                        {...(last ? { ref: intersecter } : null)}
                        singleton={target}
                        flash={flash?.current === piece ? clearFlash : null}
                        key={piece.id} />
                )
            })}
            {!!hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost
                            className="constructive"
                            onClick={fetch}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
        </Wrap>
    )
}

export default EquipmentTypePieces

import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSalaryRevision } from 'contexts/salary-revision'
import { isNil } from 'lodash'
import { getSalaryRevisionGroupUrl } from 'utilities/url'
import { roundedToFixed } from 'utilities/math'
import { cls } from 'utilities/dom'
import {
    Row, Cell, LinkCell,
    Label, Percentage
} from './s'
import StatusGroup from 'pages/salary/components/status-group'
import SalaryRevisionGroupStatus from 'pages/salary/components/revision-group-status'
import { revisionGroupStatusLabels } from 'pages/salary/constants'
import Paragraph from 'components/typography/paragraph'
import Avatars from 'components/avatars'
import { Price } from 'components/payment'
import { Plain } from 'components/button'
import { ArrowRight } from 'styled-icons/feather'
import { AlertOctagonFilled as Error } from 'components/feather'

const RevisionGroup = forwardRef(({ group, setEditingDetails, setEditingBudget, ...props }, ref) => {
    const { revision } = useSalaryRevision()

    const {
        currency,
        status: revisionStatus
    } = revision

    const {
        id,
        revisionId,
        name,
        userCount = 0,
        reviewers = [],
        budget,
        currentSalariesAmount,
        newSalariesAmount,
        status
    } = group

    const adjustment = newSalariesAmount - currentSalariesAmount
    const allocation = (budget - adjustment) ?? 0
    const negativeAllocation = !!(budget && allocation < 0)
    const budgetPercentage = roundedToFixed((adjustment / budget) * 100)

    const activeStatus = {
        id: status,
        node: SalaryRevisionGroupStatus
    }

    return (
        <Row
            {...props}
            ref={ref}>
            <LinkCell
                to={getSalaryRevisionGroupUrl({ id: revisionId, group: id })}
                $cell="name"
                className="name">
                <Paragraph className="compact link-hover">{name}</Paragraph>
                <Paragraph className="compact small caption">
                    <FormattedMessage
                        id="people_count"
                        defaultMessage="{count, plural, =0 {No people} =1 {1 person} other {{count} people}}"
                        values={{ count: userCount }} />
                </Paragraph>
            </LinkCell>
            <Cell $cell="reviewers">
                <Label>
                    <FormattedMessage
                        id="salary_revision_label_reviewers"
                        defaultMessage="Reviewers" />
                </Label>
                {!!reviewers.length && (
                    <Avatars
                        people={reviewers}
                        size={24}
                        max={5} />
                )}
                {!reviewers.length && (
                    <Plain
                        className="constructive small"
                        disabled={revisionStatus === 'completed' || status === 'approved'}
                        onClick={() => setEditingDetails({
                            focus: 'reviewers',
                            groupId: id,
                            revisionId: revisionId,
                            provide: true
                        })}>
                        <FormattedMessage
                            id="action_add"
                            defaultMessage="Add" />
                    </Plain>
                )}
            </Cell>
            <Cell $cell="budget">
                <Label>
                    <FormattedMessage
                        id="noun_budget"
                        defaultMessage="Budget" />
                </Label>
                {!!budget && (
                    <>
                        <Paragraph className="compact small">
                            <Price
                                value={budget}
                                currency={currency}
                                options={{
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                }} />
                        </Paragraph>
                        <Paragraph className={cls([
                            'compact',
                            'small',
                            'interpoint-divider',
                            budgetPercentage <= 100 ? 'caption' : 'error'
                        ])}>
                            <Percentage>
                                {budgetPercentage > 100 && <Error size={16} />}
                                <FormattedMessage
                                    id="value_percent"
                                    defaultMessage="{percent} %"
                                    values={{ percent: budgetPercentage }} />
                            </Percentage>
                            <span>
                                <FormattedMessage
                                    id={negativeAllocation ?
                                        'salary_revision_budget_allocation_negative_short'
                                        : 'salary_revision_budget_allocation_positive_short'
                                    }
                                    defaultMessage={negativeAllocation ?
                                        '{allocation} over'
                                        : '{allocation} left'
                                    }
                                    values={{
                                        allocation: (
                                            <Price
                                                value={Math.abs(allocation)}
                                                currency={currency}
                                                options={{
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }} />
                                        )
                                    }} />
                            </span>
                        </Paragraph>
                    </>
                )}
                {!budget && (
                    <>
                        <Plain
                            className="constructive small"
                            disabled={revisionStatus === 'completed' || status === 'approved'}
                            onClick={() => setEditingBudget({
                                groupId: id,
                                revisionId: revisionId,
                                provide: true
                            })}>
                            <FormattedMessage
                                id="action_set_budget"
                                defaultMessage="Set budget" />
                        </Plain>
                        {!isNil(allocation) && (
                            <Paragraph className="compact small caption">
                                <FormattedMessage
                                    id="allocated_amount"
                                    defaultMessage="Allocated: {amount}"
                                    values={{
                                        amount: (
                                            <Price
                                                value={roundedToFixed(Math.abs(allocation))}
                                                currency={currency}
                                                options={{
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                }} />
                                        )
                                    }} />
                            </Paragraph>
                        )}
                    </>
                )}
            </Cell>
            <Cell $cell="status">
                <StatusGroup
                    statuses={revisionGroupStatusLabels}
                    active={activeStatus}
                    salt={`salary-revision:${revisionId}:group:${id}:status`} />
            </Cell>
            <LinkCell
                to={getSalaryRevisionGroupUrl({ id: revisionId, group: id })}
                $cell="action"
                className="action">
                <ArrowRight size={24} />
            </LinkCell>
        </Row>
    )
})

export default RevisionGroup
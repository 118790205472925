import styled from 'styled-components'
import { containerCss } from 'utilities/widget'
import { Rows } from 'components/flex'
import { OrderedList, ListItem } from 'components/list'
import { Simple } from 'components/button'

export const SuggestionContainer = styled(Rows)`
    ${containerCss}
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    overflow: hidden;
    z-index: 10;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
`

export const SuggestionList = OrderedList

export const SuggestionItem = styled(ListItem)`
    &:not(:last-child) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }
`

export const SuggestionButton = styled(Simple)`
    border-radius: 0;
    width: 100%;

    white-space: nowrap;
    text-align: left;

    &.active {
        background: var(--huma-color-surface-neutral-transparent-hover);
    }
`

import React, { Component, createRef } from 'react'
import { mergeRefs } from 'react-merge-refs'
import { FormContext } from 'components/form/controller'
import { cls } from 'utilities/dom'
import { omit } from 'utilities/object'
import { megabytesToBytes } from 'utilities/file'
import { Control, Field, Label } from 'components/form/field/s'
import File, { Invisible } from 'components/form/input/file'

const empty = null
const tenMegabytesAsBytes = megabytesToBytes(10)

export default class EditFile extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const {
            field = {},
            forwardedRef
        } = props

        let { value } = field
        if(!value) {
            value = empty
        }

        this.state = { value }

        this.register()

        this.fieldRef = createRef()
        this.inputRef = createRef()

        this.combinedInputRef = mergeRefs([
            this.inputRef,
            forwardedRef
        ])
    }

    componentDidUpdate = ({ name, field }, { value }) => {
        const nameChanged = name !== this.props.name
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include

        if(nameChanged) {
            this.context.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(value !== this.state.value) {
            this.context.triggerChange(this.props.name)
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name, true)
    }

    register = (update = false) => {
        const {
            name,
            field = {},
            maxSize = tenMegabytesAsBytes
        } = this.props

        let {
            required = false,
            include = 'touched'
        } = field

        this.context.registerField(name, {
            empty,
            required,
            include,
            unset: this.unset,
            file: true,

            validator: () => {
                if(!!this.inputRef?.current) {
                    const [file] = [...(this.inputRef.current?.files ?? [])]

                    if(file && file.size > maxSize) {
                        return false
                    }

                    return required ?
                        !!file :
                        true
                }

                return true
            }
        }, update)
    }

    unset = () => this.setState({ value: empty })

    onChange = data => {
        const { name, onChange } = this.props
        const { [name]: value } = data

        this.setState({ value })
        onChange?.({ [name]: value })
    }

    render() {
        const { value } = this.state

        const {
            className,
            controlProps = {},
            salt,
            label,
            name,
            enabled = true,
            loading = false,
            accept,
            multiple,
            maxSize = tenMegabytesAsBytes,
            invisible = false,
            field = {},
            onClear,
            ...props
        } = this.props

        const touched = this.context.touched.includes(name)
        const error = (name in this.context.errors) && touched

        controlProps.loading = !error && loading

        const fieldClassName = cls([
            className,
            touched && 'touched',
            error && 'error'
        ])

        const FileInput = invisible ?
            Invisible :
            File

        const {
            required,
            softRequired,
            optional
        } = field

        return (
            <Field
                {...(fieldClassName ? { className: fieldClassName } : null)}
                ref={this.fieldRef}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <FileInput
                        {...omit(props, 'field')}
                        name={name}
                        value={value}
                        id={salt}
                        accept={accept}
                        multiple={multiple}
                        maxSize={maxSize}
                        controlProps={controlProps}
                        onClear={onClear}
                        onChange={this.onChange}
                        disabled={!enabled || !!controlProps.loading}
                        ref={this.combinedInputRef} />
                </Control>
            </Field>
        )
    }
}

import styled, { css } from 'styled-components'

// Rows and Columns…
export const flexParentCss = css`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: stretch;
`

export const flexColumnsCss = css`
    ${flexParentCss}
    flex-direction: row;
`

export const flexRowsCss = css`
    ${flexParentCss}
    flex-direction: column;
`

export const Columns = styled.div`
    ${flexColumnsCss}
`

export const Rows = styled.div`
    ${flexRowsCss}
`



// Children…
export const flexChildCss = css`
    flex: 1;
    min-width: 0;
`

export const flexChildShrinkCss = css`
    ${flexChildCss}
    flex: 0 0 auto;
    max-width: 100%;
`

export const flexChildGrowCss = css`
    ${flexChildCss}
    flex: 1 100 100%;
`

export const FlexChild = styled.div`
    ${flexChildCss}
`

export const FlexChildShrink = styled.div`
    ${flexChildShrinkCss}
`

export const FlexChildGrow = styled.div`
    ${flexChildGrowCss}
`

import React from 'react'
import { usePeople } from 'contexts/people'
import { compact } from 'utilities/array'
import { LoadingContainer } from './s'
import Loader from 'components/loader'
import { OrderedList } from 'components/list'
import Person from './person'

const People = ({ prepicked, locked, picked, method, togglePicked, salt }) => {
    const {
        people,
        intersecter,
        loading,
        fetching,
        paging = {},
        hasFetched
    } = usePeople()

    return (
        <>
            {(!!fetching && !hasFetched) && <Loader />}
            <OrderedList>
                {people.map((person, index) => {
                    const last = index + 1 === people.length

                    return (
                        <Person
                            person={person}
                            prepicked={prepicked}
                            locked={locked}
                            picked={picked}
                            method={method}
                            toggle={togglePicked}
                            {...((!!intersecter && last) ? { ref: intersecter } : null)}
                            salt={`${salt}:people`}
                            key={compact([
                                salt,
                                'person',
                                person.id,
                                prepicked.map(({ id }) => id).includes(person.id) && 'prepicked',
                                locked.map(({ id }) => id).includes(person.id) && 'locked',
                                picked.map(({ id }) => id).includes(person.id) && 'picked'
                            ]).join(':')} />
                    )
                })}
                {(!!intersecter && !!paging?.hasNextPage && !!loading) && (
                    <LoadingContainer>
                        <Loader />
                    </LoadingContainer>
                )}
            </OrderedList>
        </>
    )
}

export default People
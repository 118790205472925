import styled from 'styled-components'
import { fromContainer, belowContainer } from 'utilities/styled'
import { GridColumnHeader } from 'components/grid'
import { SkeletonListRow as SkeletonListRowBase, SkeletonCell as SkeletonCellBase } from 'components/skeleton'

export const container = {
    name: 'case',
    breakpoint: 650
}

export const Container = styled.div`
    container-type: inline-size;
    container-name: ${container.name};
`

export const Wrap = styled.div`
    ${fromContainer(container)`
        display: grid;
        grid-template-columns:
            [meta] minmax(0, 1fr)
            [status] minmax(100px, max-content)
            [${p => p.$personCell}] minmax(max-content, 0.5fr)
            [message] minmax(max-content, 0.5fr)
            [action] 24px;
        column-gap: 16px;
    `}

    ${belowContainer(container)`
        display: flex;
        flex-direction: column;
    `}
`

export const Header = styled.div`
    display: grid;
    grid-template-columns: subgrid;
    grid-column: 1 / -1;

    padding-block: 16px;

    ${belowContainer(container)`
        display: none;
    `}
`

export const ColumnHeader = styled(GridColumnHeader)`
    grid-column: ${p => p.cell};
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    display: grid;
    grid-template-columns: subgrid;
    grid-column: 1 / -1;
    align-items: center;
    column-gap: 16px;

    padding-block: 16px;
    min-height: 76px;
    box-shadow: 0 -1px var(--huma-color-border-default);

    ${belowContainer(container)`
        display: flex;
        flex-direction: column;
        align-items: start;
    `}
`

export const SkeletonCell = styled(SkeletonCellBase)`
    grid-column: ${p => p.$cell};
`

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    position: relative;
`
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getTerritoryHeading } from 'pages/absence/utilities'
import {
    Schedule,
    NameCell, NameButton, Name,
    AssignedCell, ManageButton,
    StatusCell, StatusLabel,
    ActionsCell
} from './s'
import Paragraph from 'components/typography/paragraph'
import MobileLabel from 'components/typography/mobile-label'
import ContextMenu, { getActionsArray } from 'widgets/context-menu'

const WorkSchedule = ({ schedule, actions, salt }) => {
    const {
        name,
        territory,
        default: isDefault,
        workdayNamesFormatted,
        userCount
    } = schedule

    const hasActions = !!getActionsArray(actions).length

    return (
        <Schedule>
            <NameCell>
                <NameButton onClick={actions.edit(schedule).onClick}>
                    <Name className="compact">{name}</Name>
                    <Paragraph className="caption small interpoint-divider compact">
                        <span>{getTerritoryHeading(territory)}</span>
                        <span>{workdayNamesFormatted}</span>
                    </Paragraph>
                </NameButton>
            </NameCell>
            <AssignedCell>
                <MobileLabel>
                    <FormattedMessage
                        id="label_assigned_to"
                        defaultMessage="Assigned to" />
                </MobileLabel>
                {!!userCount && (
                    <span>
                        <FormattedMessage
                            id="people_count"
                            defaultMessage="{count, plural, =0 {No people} =1 {One person} other {{count} people}}"
                            values={{ count: userCount }} />
                    </span>
                )}
                {!userCount && (
                    <span>
                        <FormattedMessage
                            id="noun_no_one"
                            defaultMessage="No one" />
                    </span>
                )}
                <ManageButton
                    onClick={actions.manage(schedule).onClick}
                    className="constructive">
                    <FormattedMessage
                        id="action_manage"
                        defaultMessage="Manage" />
                </ManageButton>
            </AssignedCell>
            <StatusCell>
                {!!isDefault && (
                    <StatusLabel className="neutral">
                        <FormattedMessage
                            id="work_schedule_status_default"
                            defaultMessage="Default" />
                    </StatusLabel>
                )}
            </StatusCell>
            {hasActions && (
                <ActionsCell>
                    <ContextMenu
                        salt={salt}
                        context={schedule}
                        actions={actions} />
                </ActionsCell>
            )}
        </Schedule>
    )
}

export default WorkSchedule

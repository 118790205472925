import React, { useEffect, forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useBelowLaptop } from 'hooks/media-query'
import { useStorage } from 'hooks/storage'
import { cls } from 'utilities/dom'
import {
    Container, InnerContainer, InlineContainer, GlobalContainer,
    IconWrap,
    Paragraph, ParagraphSmall,
    Dismiss, DismissButton
} from './s'
import { FlexChild, FlexChildShrink } from 'components/flex'
import { SkeletonStrings } from 'components/skeleton'
import {
    CheckCircleFilled as Success,
    InfoCircleFilled as Info,
    AlertTriangleFilled as Warning,
    AlertOctagonFilled as Error,
    CrossCircleFilled as Caution
} from 'components/feather'
import { X, ChevronDown as Expand } from 'styled-icons/feather'
import { Lightbulb as Idea } from 'styled-icons/material-outlined'

export const InlineMessage = forwardRef(({
    type, message, icon = null,
    children = null, className, textClassName, size = 'small', expandable = false,
    ...props
}, ref) => {
    const Icon = icon ?? typeToSmallIconMap[type]

    className = cls([
        type,
        className,
        ('onClick' in props) && 'clickable'
    ])

    const Text = (size === 'small') ?
        ParagraphSmall :
        Paragraph

    let iconSize

    if(['success', 'caution'].includes(type)) {
        iconSize = size === 'small' ? 14 : 20
    } else {
        iconSize = size === 'small' ? 16 : 24
    }

   return (
        <InlineContainer
            {...props}
            className={cls(className, `size-${size}`)}
            ref={ref}>
            {!!Icon && (
                <IconWrap $size={size}>
                    <Icon size={iconSize} />
                </IconWrap>
            )}
            <FlexChild>
                <Text className={cls(['compact', textClassName])}>{message}</Text>
                {children}
            </FlexChild>
            {!!expandable && (
                <FlexChildShrink>
                    <Expand
                        size={16}
                        className="expand-hint" />
                </FlexChildShrink>
            )}
        </InlineContainer>
   )
})

export const GlobalMessage = ({
    type, message, icon = null, dismiss = null,
    children = null, className, isMenuOpen, isSidePanelOpen,
    ...props
}) => {
    const belowLaptop = useBelowLaptop()

    const Icon = icon ?? typeToIconMap[type]

    className = cls([
        type,
        className,
        !!dismiss && 'dismissable',
        ((belowLaptop && isMenuOpen) || isSidePanelOpen) && 'overhide'
    ])

    return (
        <GlobalContainer
            {...props}
            {...(className ? { className } : null)}>
            {!!Icon && (
                <IconWrap>
                    <Icon size={24} />
                </IconWrap>
            )}
            <FlexChildShrink>
                <Paragraph className="compact">{message}</Paragraph>
                {children}
            </FlexChildShrink>
            {(typeof dismiss === 'function') && (
                <Dismiss>
                    <DismissButton
                        className={!!type ? 'neutral' : 'constructive'}
                        onClick={dismiss}>
                        <X
                            size={24}
                            className="x" />
                    </DismissButton>
                </Dismiss>
            )}
        </GlobalContainer>
    )
}

const Message = forwardRef(({
    type, message, messageAsHeading = false, title, textClassName,
    defaultDismissed = false, dismissOnUnmount = false, dismissedProps = null,
    loading = false, icon = null, name: salt = null,
    ...props
}, ref) => {
    const { locale } = useI18n()

    const [dismissed, setDismissed] = useStorage({
        key: `dismissed:info:${locale}:${salt}`,
        defaultValue: defaultDismissed ?? false
    })

    useEffect(() => {
        return () => dismissOnUnmount ?
            setDismissed(true) :
            {}
    }, [dismissOnUnmount])

    if(dismissed) {
        if(title || messageAsHeading) {
            return (
                <InlineMessage
                    {...props}
                    {...dismissedProps}
                    type={type}
                    icon={icon}
                    message={title ?? message}
                    onClick={() => setDismissed(false)}
                    children={null} // eslint-disable-line
                    expandable />
            )
        }

        return null
    }

    let {
        children = null,
        className,
        x = false,
        ...attributes
    } = props

    const Icon = icon ?? typeToIconMap[type]

    className = cls([
        className,
        type
    ])

    return (
        <Container
            {...attributes}
            {...(className ? { className } : null)}
            ref={ref}>
            <InnerContainer>
                {!!Icon && (
                    <IconWrap>
                        <Icon size={24} />
                    </IconWrap>
                )}
                <FlexChild>
                    {!loading && (
                        <>
                            {!!message && (
                                <Paragraph className={cls([
                                    textClassName,
                                    messageAsHeading && 'heading',
                                    !children && 'compact'
                                ])}>
                                    {message}
                                </Paragraph>
                            )}
                            {children}
                        </>
                    )}
                    {!!loading && (
                        <SkeletonStrings
                            length={20}
                            className={type} />
                    )}
                </FlexChild>
                {(!!salt && !!x) && (
                    <Dismiss>
                        <DismissButton
                            className={!!type ? 'neutral' : 'constructive'}
                            onClick={() => setDismissed(true)}>
                            <X
                                size={24}
                                className="x" />
                        </DismissButton>
                    </Dismiss>
                )}
            </InnerContainer>
            {(!!salt && !x) && (
                <InnerContainer className="dismiss">
                    <Dismiss>
                        <DismissButton
                            className={!!type ? 'neutral' : 'constructive'}
                            onClick={() => setDismissed(true)}>
                            <FormattedMessage
                                id="action_dismiss_colorful"
                                defaultMessage="Got it!" />
                        </DismissButton>
                    </Dismiss>
                </InnerContainer>
            )}
        </Container>
    )
})

export default Message

const typeToSmallIconMap = {
    success: ({ size = 16, ...props }) => <Success {...props} size={size} />,
    info: ({ size = 18, ...props }) => <Info {...props} size={size} />,
    warning: ({ size = 20, ...props }) => <Warning {...props} size={size} />,
    error: ({ size = 18, ...props }) => <Error {...props} size={size} />,
    caution: ({ size = 16, ...props }) => <Caution {...props} size={size} />,
    idea: ({ size = 18, ...props }) => <Idea {...props} size={size} />
}

export const typeToIconMap = {
    success: ({ size = 20, ...props }) => <Success {...props} size={size} />,
    info: ({ size = 20, ...props }) => <Info {...props} size={size} />,
    warning: ({ size = 24, ...props }) => <Warning {...props} size={size} />,
    error: ({ size = 20, ...props }) => <Error {...props} size={size} />,
    caution: ({ size = 20, ...props }) => <Caution {...props} size={size} />,
    idea: ({ size = 20, ...props }) => <Idea {...props} size={size} />
}
import {
    image as imageExtensions,
    spreadsheet as spreadsheetExtensions,
    plaintext as plaintextExtensions
} from 'utilities/file'

export const allowedFileExtensions = [
    ...imageExtensions,
    ...spreadsheetExtensions,
    ...plaintextExtensions,
    'pdf'
]
import React from 'react'
import styled from 'styled-components'
import PersonBase from 'components/person'
import AvatarsBase from 'components/avatars'

const Person = styled(PersonBase)`
    margin: 8px 0;
`

const Avatars = styled(AvatarsBase)`
    height: 40px;
`

const DisplayPeople = ({ people, salt }) => {
    if(!people?.length) {
        return null
    }

    return (
        <>
            {(people.length === 1) && (
                <Person
                    who={people[0]}
                    size={24} />
            )}
            {(people.length > 1) && (
                <Avatars
                    people={people}
                    max={5}
                    salt={salt} />
            )}
        </>
    )
}

export default DisplayPeople
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useFlash } from 'hooks/flash'
import {
    Domain,
    NameCell, Name,
    StatusCell, VerifyButton,
    ActionsCell
} from './s'
import StatusLabel from 'components/status-label'
import { FlexChildShrink } from 'components/flex'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'

const DomainItem = ({ domain, actions, verifying, upgradable, flash }) => {
    const flasher = useFlash(flash)

    const {
        name,
        verified
    } = domain

    const {
        verify,
        ...remainingActions
    } = actions

    const verifyAction = verify?.(domain)
    actions = getBoundActions(remainingActions, domain)

    const id = `domain:${domain.name}`

    const statusClassName = upgradable ?
        'disabled' :
        verified ?
            'active' :
            'passive'

    return (
        <Domain ref={flasher}>
            <NameCell>
                <Name>{name}</Name>
            </NameCell>
            <StatusCell>
                <FlexChildShrink>
                    <StatusLabel className={statusClassName}>
                        {upgradable && (
                            <FormattedMessage
                                id="noun_disabled"
                                defaultMessage="Disabled" />
                        )}
                        {!upgradable && (
                            <FormattedMessage
                                id={verified ?
                                    'noun_verified' :
                                    'noun_unverified'
                                }
                                defaultMessage={verified ?
                                    'Verified' :
                                    'Unverified'
                                } />
                        )}
                    </StatusLabel>
                </FlexChildShrink>
                {!!verifyAction && (
                    <FlexChildShrink>
                        <VerifyButton
                            onClick={verifyAction.onClick}
                            className={verifyAction.effect}
                            disabled={verifying}>
                            {verifyAction.label}
                        </VerifyButton>
                    </FlexChildShrink>
                )}
            </StatusCell>
            {!!actions?.length && (
                <ActionsCell>
                    <ContextMenu
                        salt={id}
                        prebound actions={actions} />
                </ActionsCell>
            )}
        </Domain>
    )
}

export default DomainItem
import styled, { css } from 'styled-components'
import {
    svgStroke, flashKeyframes,
    fromPhone, belowPhone
} from 'utilities/styled'
import { compact } from 'utilities/array'
import { Grid } from 'components/grid'
import { Plain } from 'components/button'
import { InlineMessage } from 'components/message'
import ExpiryStatusBase from 'components/expiry-status'
import SymbolBase from 'components/symbol'
import { Background as SymbolBackground, ancestorHover } from 'components/symbol/s'
import { ancestorHover as avatarAncestorHover } from 'components/avatar/s'

export const PieceWrapper = styled(Grid)`
    grid-template-columns: minmax(0, 2fr) repeat(2, 1fr) 40px;
    column-gap: 24px;

    width: 100%;
    padding-block: 16px;
    box-shadow: 0 -1px var(--huma-color-border-default);

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }

    ${({ theme: { equipment: { variant } } }) => belowPhone`
        grid-template-columns: 1fr 40px;
        grid-template-rows: ${compact(['32px', variant && '20px', 'auto']).join(' ')};
        grid-auto-rows: auto;
        row-gap: 8px;
    `}
`

export const EquipmentMessage = styled(InlineMessage)`
    & p {
        line-height: 20px;
    }
`

const personCellCss = css`
    min-height: 40px;

    text-align: start;
`

export const PersonCellClickable = styled(Plain)`
    ${personCellCss}
    ${avatarAncestorHover()}

    justify-self: start;

    &:not([disabled]):where(:hover, :focus) {
        text-decoration: none;
    }

    ${belowPhone`
        grid-row: 1 / 2;
    `}
`

export const PersonCellFragment = styled.div`
    ${personCellCss}
`

export const Unassigned = styled.div`
    display: flex;
    align-items: center;
    column-gap: 16px;
`

export const Symbol = styled(SymbolBase)`
    isolation: isolate;

    & > ${SymbolBackground} {
        background-color: transparent;

        &:after {
            content: "";
            z-index: -1;
            position: absolute;
            inset: 0;

            border-radius: 50%;
            border: 1px dashed var(--symbol-color);
        }

        &:before {
            opacity: 0;
            transition-property: transform, opacity !important;
        }
    }

    ${Unassigned}:hover & > ${SymbolBackground} {
        &:before {
            opacity: 1;
        }
    }

    ${ancestorHover(Unassigned)}
`

export const UnassignedLabel = styled.div``

const cellCss = css`
    display: grid;
    align-items: start;
    padding: 8px 0;
`

export const VariantCell = styled.div`
    ${cellCss}

    color: var(--huma-color-foreground-subtle);

    ${fromPhone`
        line-height: 24px;
    `}

    ${belowPhone`
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        grid-template-columns: auto 1fr;
        align-items: center;

        padding-inline-start: 56px;

        font-size: 14px;
    `}
`

export const StatusCell = styled.div`
    ${cellCss}

    & > :last-child {
        margin-block-end: 0;
    }
    ${fromPhone`
        & > :first-child {
            margin-block-start: 4px;
        }
    `}

    ${belowPhone`
        grid-column: 1 / 2;

        ${({ theme: { equipment: { variant } } }) => {
            if(variant) {
                return css`
                    grid-row: 3 / 4;
                `
            }

            return css`
                grid-row: 2 / 4;
            `
        }}

        padding-inline-start: 56px;

        &:empty {
            display: none;
        }
    `}
`

export const ExpiryStatus = styled(ExpiryStatusBase)`
    align-items: center;

    & p {
        line-height: 20px;
    }
`

export const ActionsCell = styled.div`
    justify-content: center;

    ${belowPhone`
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    `}

    svg {
        ${svgStroke()}
    }
`

import React, { useState, useLayoutEffect } from 'react'
import { getFullName } from 'utilities/person'
import { Wrapper, ContentRow, LoadingContainer } from './s'
import Loader from 'components/loader'
import Search from './search'
import People from './people'

const PersonList = ({ salt, actions, loading, people, search: searchConfig, ...props }) => {
    const [search, setSearch] = useState('')
    const [filtered, setFiltered] = useState(people)

    useLayoutEffect(() => {
        const filterPeople = search => {
            search = search
                ?.trim()
                ?.toLowerCase() ?? ''

            if(search.length < 2) {
                return people
            }

            return people.filter(person => {
                const name = getFullName(person, { fallback: false }) ?? ''
                return name.toLowerCase().includes(search)
            })
        }

        if(!!searchConfig?.shouldClear) {
            setSearch('')
            setFiltered([])
        } else if(!people?.length) {
            setFiltered([])
        } else {
            setFiltered(filterPeople(search))
        }
    }, [!!searchConfig?.shouldClear, people, search])

    return (
        <>
            {(!!searchConfig && !!people?.length) && (
                <Search
                    {...searchConfig}
                    setSearch={setSearch}
                    salt={salt} />
            )}
            <Wrapper {...props}>
                <ContentRow>
                    {!!loading && (
                        <LoadingContainer>
                            <Loader />
                        </LoadingContainer>
                    )}
                    <People
                        people={filtered}
                        actions={actions}
                        salt={salt} />
                </ContentRow>
            </Wrapper>
        </>
    )
}

export default PersonList

import styled, { keyframes, css } from 'styled-components'
import { fromLaptop } from 'utilities/styled'

const loaderKeyframes = keyframes`
    0% { scale: 0; }

    100% {
        scale: 1;
        opacity: 0;
    }
`

export const loaderCss = css`
    pointer-events: none;
    position: absolute;
    inset: 50%;
    display: block;

    border-radius: 100%;
    width: 40px;
    aspect-ratio: 1;
    background-color: var(--__huma-component-loader-color, var(--huma-color-surface-neutral-bold));

    translate: -50% -50%;
    animation: ${loaderKeyframes} 1s infinite ease-in-out;
`

const Loader = styled.div`
    ${loaderCss}

    &.constructive {
        --__huma-component-loader-color: var(--huma-color-surface-constructive-bold);
    }

    &.destructive {
        --__huma-component-loader-color: var(--huma-color-surface-destructive-bold);
    }

    &.inverted {
        --__huma-component-loader-color: var(--huma-color-surface-inverted);
    }

    &.fixed {
        position: fixed;

        ${fromLaptop`
            inset-inline: calc(50% + var(--huma-global-nav-width) / 2);
        `}
    }

    &.xs {
        width: 16px;
    }

    &.s {
        width: 24px;
    }

    &.m {
        width: 32px;
    }

    &.l {
        width: 64px;
    }

    &.xl {
        width: 120px;
    }
`

export const LoaderWrap = styled.div`
    position: relative;

    height: ${({ height = 40 }) => height}px;
`

export default Loader
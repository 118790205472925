import React from 'react'
import { getIntegrationUrl } from 'utilities/url'
import { Link, LogoWrapper, Logo } from './s'
import Beta from 'components/beta'
import data from 'pages/integrations/pages/integration/data'

const Integration = ({ type, beta, name }) => {
    const {
        logo,
        logoDark
    } = data?.[type]?.() ?? {}

    return (
        <Link
            to={getIntegrationUrl({ type })}
            className="constructive">
            {!!logo && (
                <LogoWrapper>
                    {!!logoDark && (
                        <Logo
                            src={logoDark}
                            alt={name}
                            className="dark" />
                    )}
                    <Logo
                        src={logo}
                        alt={name} />
                </LogoWrapper>
            )}
            {!!beta && (
                <span>
                    <Beta showTooltip={false} />
                </span>
            )}
        </Link>
    )
}

export default Integration

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { HeroColumns, Heading } from './s'

const RespondHero = () => (
    <HeroColumns>
        <Heading>
            <FormattedMessage
                id="employee_satisfaction_heading_response"
                defaultMessage="Survey response" />
        </Heading>
    </HeroColumns>
)

export default RespondHero
import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Sheriff } from 'components/typography/heading'
import { Naked, buttonStyles } from 'components/button'

export const Layout = styled(Grid)`
    /* Container CSS */
    position: relative;

    grid-template-rows: minmax(0, min-content) minmax(0, 1fr);
    grid-row-gap: 16px;

    border-radius: 8px;
    padding: 32px 0 0;
    background-color: var(--huma-color-surface-default);
    box-shadow:
        0 -4px 16px 0 var(--huma-color-shadow),
        var(--huma-shadow-high);

    color: var(--huma-color-foreground-default);

    ${belowTablet`
        padding: 24px 0 0;
    `}
`

export const HeaderRow = styled.div`
    position: relative;

    margin-inline: 32px;

    ${belowTablet`
        margin-inline: 24px;
    `}
`

export const Heading = styled(Sheriff)`
    margin: 0;
    width: calc(100% - 40px);

    font-size: 32px;
    font-weight: 500;
    line-height: 40px;

    ${belowTablet`
        font-size: 24px;
    `}
`

export const CloseButton = styled(Naked)`
    ${buttonStyles.closeButtonCss({ offset: 0 })}
`

export const Iframe = styled.iframe`
    border: 0;
    border-radius: 0 0 8px 8px;
    width: 100%;
    height: 100%;
`
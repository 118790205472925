import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Paragraph from 'components/typography/paragraph'
import { Inline as Code } from 'components/code'

const Wrapper = styled.div`
    margin-inline: auto;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    max-width: var(--huma-global-content-tight-width);
    padding-inline: 24px;
`

const Item = styled.div`
    padding-block: 24px;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }
`

const Question = styled(Paragraph)`
    margin-block-end: 8px;

    font-weight: 500;
`

const Answer = styled(Paragraph)`
    white-space: pre-line;
`

const values = {
    code: chunks => <Code className="variable">{chunks}</Code>
}

const Faq = ({ items, className, ...props }) => {
    if(!items?.length) {
        return null
    }

    return (
        <Wrapper
            className={className}
            {...props}>
            {items.map(([question, answer]) => (
                <Item key={`faq:${question}`}>
                    <Question>
                        <FormattedMessage
                            id="TEMPLATING_ENGINE_ONLY"
                            defaultMessage={question}
                            values={values} />
                    </Question>
                    <Answer className="compact">
                        <FormattedMessage
                            id="TEMPLATING_ENGINE_ONLY"
                            defaultMessage={answer}
                            values={values} />
                    </Answer>
                </Item>
            ))}
        </Wrapper>
    )
}

export default Faq

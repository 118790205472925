import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import {
    Rows, Columns,
    FlexChild, FlexChildShrink, FlexChildGrow,
    flexColumnsCss, flexRowsCss
} from 'components/flex'
import { paragraphCss, captionCss } from 'components/typography/paragraph'

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`

export const UnorderedList = List

export const UnorderedListColumns = styled(UnorderedList)`
    ${flexColumnsCss}
`

export const UnorderedListRows = styled(UnorderedList)`
    ${flexRowsCss}
`

export const OrderedList = styled(List).attrs({ as: 'ol' })`
    &.decimals {
        list-style: decimal inside;
        margin: 0 0 24px;
        border-bottom: 1px solid #f3f3f5;
    }
`

export const OrderedListColumns = styled(OrderedList)`
    ${flexColumnsCss}
`

export const OrderedListRows = styled(OrderedList)`
    ${flexRowsCss}
`

export const ListItem = styled.li`
    ${OrderedList}.decimals & {
        border-top: 1px solid #f3f3f5;
        padding: 16px 0;

        line-height: 24px;

        p:first-child {
            display: inline;
        }
    }
`

export const ListItemRows = styled(Rows).attrs({ as: 'li' })``
export const ListItemColumns = styled(Columns).attrs({ as: 'li' })``

export const ListItemChild = styled(FlexChild).attrs({ as: 'li' })``
export const ListItemChildShrink = styled(FlexChildShrink).attrs({ as: 'li' })``
export const ListItemChildGrow = styled(FlexChildGrow).attrs({ as: 'li' })``

export const DefinitionList = styled.dl`
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    column-gap: 40px;
    row-gap: 24px;

    ${belowPhone`
        grid-template-columns: none;
        row-gap: 0;
    `}

    &.compact {
        margin-block-end: 0;
    }
`

export const DefinitionTerm = styled.dt`
    ${captionCss}
`

export const DefinitionDescription = styled.dd`
    ${paragraphCss}
    margin: 0;

    ${DefinitionList}.stretch & {
        text-align: end;
    }

    ${belowPhone`
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
    `}
`
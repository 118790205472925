import React from 'react'
import { Custom } from 'components/svg'
import AmexSvg from 'static/gfx/icon/payment-card/amex.csvg'
import MastercardSvg from 'static/gfx/icon/payment-card/mastercard.csvg'
import VisaSvg from 'static/gfx/icon/payment-card/visa.csvg'
// import {
//     CcAmex as Amex,
//     CcMastercard as MasterCard,
//     CcVisa as Visa
// } from '@styled-icons/fa-brands'
import { CreditCard } from 'styled-icons/feather'

const CardLogo = ({ type, className, size, ...props }) => {
    const Logo = typeToCardLogoMap[type] || CreditCard

    const modifiedSize = !(type in typeToCardLogoMap) ?
        size * 5/6 :
        size

    return (
        <Logo
            size={modifiedSize}
            {...props}
            {...(className ? { className } : null)} />
    )
}

export default CardLogo


// https://github.com/muffinresearch/payment-icons/tree/master/svg/flat

export const Amex = props => (
    <Custom
        {...props}
        svg={AmexSvg} />
)

export const Mastercard = props => (
    <Custom
        {...props}
        svg={MastercardSvg} />
)

export const Visa = props => (
    <Custom
        {...props}
        svg={VisaSvg} />
)

const typeToCardLogoMap = {
    amex: Amex,
    mastercard: Mastercard,
    visa: Visa
}
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { isofy } from 'utilities/date-time'
import { addDays } from 'date-fns'
import { Heading, CalendarListWrapper } from './s'
import Google from './google'
import Office365 from './office-365'
import Desktop from './desktop'
import Actions from 'components/form/actions'
import { Plain } from 'components/button'

const AddToCalendar = ({ close, ...props }) => (
    <>
        <Heading>
            <FormattedMessage
                id="action_add_to_calendar"
                defaultMessage="Add to calendar" />
        </Heading>
        <CalendarList {...props} />
        <Actions className="centered">
            <Plain
                className="neutral"
                onClick={close}>
                <FormattedMessage
                    id="action_done"
                    defaultMessage="Done" />
            </Plain>
        </Actions>
    </>
)

export const CalendarList = ({ start, end, title, description, organizer, attendees = [], className, ...props }) => {
    const oneWeekAhead = addDays(new Date(), 7)

    start = start ?
        isofy(start) :
        oneWeekAhead

    end = end ?
        isofy(end) :
        oneWeekAhead

    props = {
        ...props,
        start,
        end,
        title,
        description,
        organizer,
        attendees
    }

    return (
        <CalendarListWrapper className={className}>
            <Google {...props} />
            <Office365 {...props} />
            <Desktop {...props} />
        </CalendarListWrapper>
    )
}

export default AddToCalendar

import Highlight from '@tiptap/extension-highlight'

const CustomHighlight = Highlight.extend({
    addKeyboardShortcuts() {
        return {
            'Mod-Shift-m': () => this.editor.commands.toggleHighlight()
        }
    }
})

export default CustomHighlight
import React from 'react'
import { getHandbookCategoryUrl } from 'utilities/url'
import { onClink } from 'utilities/event'
import { hashify } from 'pages/handbook/utilities'
import { Topic, Link } from './s'
import { DisplayEmoji as Emoji } from 'components/symbol'
import { Plain } from 'components/button'
import { X } from 'styled-icons/feather'

const RecentSearchesTopic = ({ topic, category, recentSearches, setRecentSearches, size, setActingTopic }) => {
    const {
        id,
        title,
        symbol
    } = topic

    const remove = () => {
        setRecentSearches(recentSearches =>
            (recentSearches ?? []).filter(search => search !== id)
        )
    }

    if(!recentSearches.includes(id)) {
        return null
    }

    const sizes = {
        normal: 24,
        small: 16
    }

    return (
        <Topic>
            <Link
                to={`${getHandbookCategoryUrl(category)}#${hashify(topic.id, 'topic')}`}
                onClick={onClink(() => setActingTopic({
                    topic,
                    mode: 'view'
                }))}>
                <Emoji
                    emoji={symbol.emoji}
                    size={sizes[size]} />
                <span>{title}</span>
            </Link>
            <Plain onClick={remove}>
                <X size={sizes[size]} />
            </Plain>
        </Topic>
    )
}

export default RecentSearchesTopic

import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import Paragraph from 'components/typography/paragraph'
import { Naked, buttonStyles } from 'components/button'

export const Wrapper = styled(Naked)`
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    column-gap: 16px;
    align-items: center;

    padding: 16px 0;
    text-align: start;

    &:not(:last-child) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }

    &:last-child {
        padding-bottom: 0;
    }
`

export const Symbol = styled(SymbolBase)`
    isolation: isolate;

    grid-row: 1 / -1;
    grid-column: 1 / 2;

    ${ancestorHover(Wrapper)}
`

export const Title = styled(Paragraph).attrs({ as: 'span' })`
    grid-row: 1 / 2;
    grid-column: 2 / 3;
`

export const AddButton = styled.div`
    ${buttonStyles.plainCss}
    grid-row: 1 / -1;
    grid-column: 3 / 4;
    justify-content: center;

    ${Wrapper}:where(:hover, :focus) & {
        color: var(--huma-color-foreground-constructive-bold-active);
    }

    svg {
        ${svgStroke()}
    }
`

import { useIntl } from 'react-intl'
import { bytesToKilobytes, bytesToMegabytes } from 'utilities/file'

const FileSize = ({ bytes }) => {
    const { locale, formatMessage } = useIntl()

    const MB = bytesToMegabytes(bytes)
    const kB = bytesToKilobytes(bytes)

    const megabytes = Intl.NumberFormat(locale).format(MB)
    const kilobytes = Intl.NumberFormat(locale).format(kB)

    return formatMessage({
        id: MB > 1 ? 'file_size_megabytes' : 'file_size_kilobytes',
        defaultMessage: MB > 1 ? '{megabytes}MB' : '{kilobytes}kB'
    }, { [MB > 1 ? 'megabytes' : 'kilobytes']: MB > 1 ? megabytes : kilobytes })
}

export default FileSize
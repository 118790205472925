import styled from 'styled-components'
import { Grid } from 'components/grid'
import { SkeletonListRow as SkeletonListRowBase } from 'components/skeleton'

export const Wrap = styled(Grid)`
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-row-gap: 24px;

    margin-block-start: 24px;
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    grid-template-columns: 40px 1fr;
    align-items: center;

    border-radius: 8px;
    padding-inline: 24px;
    box-shadow: inset 0 0 0 1px var(--huma-color-border-default);
`
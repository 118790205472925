import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'

export const Columns = styled.span`
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
`

export const IconColumn = styled.span`
    display: grid;
    justify-items: end;
    width: 24px;

    svg {
        ${svgStroke()}
    }
`
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Label, Control, ValueDisplay as EmptyDisplay } from 'components/form/field/s'
import { Field, ValueDisplay, CardDetails } from './s'
import CardLogo from 'components/card-logo'
import { Plain } from 'components/button'
import { cls } from 'utilities/dom'
import { getScrambledNumber, getExpiry } from 'utilities/payment'

const ViewPaymentCard = ({ className, label, edit, ...props }) => {
    const {
        field = {},
        controlProps = {},
        display
    } = props

    const {
        value,
        editable = true
    } = field

    const fieldClassName = cls([
        className,
        !label && 'label-less'
    ])

    const controlClassName = cls([
        controlProps.className,
        display
    ])

    const displayClassName = cls([
        !!value && display,
        !value && 'empty'
    ])

    const {
        cardType,
        expMonth,
        expYear,
        lastFour
    } = value

    const Display = !!value ?
        ValueDisplay :
        EmptyDisplay

    return (
        <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control {...(controlClassName ? { className: controlClassName } : null)}>
                <Display {...(displayClassName ? { className: displayClassName } : null)}>
                    {!!value && (
                        <CardDetails>
                            <CardLogo
                                size={24}
                                type={cardType?.toLowerCase?.()} />
                            <span>
                                {getScrambledNumber(lastFour)}
                            </span>
                            <span>
                                {getExpiry(expMonth, expYear)}
                            </span>
                        </CardDetails>
                    )}
                    {!value && (
                        <FormattedMessage
                            id="empty_not_entered"
                            defaultMessage="Not entered" />
                    )}
                    {!!editable && (
                        <Plain
                            className="constructive"
                            onClick={edit}>
                            <FormattedMessage
                                id="action_change"
                                defaultMessage="Change" />
                        </Plain>
                    )}
                </Display>
            </Control>
        </Field>
    )
}

export default ViewPaymentCard
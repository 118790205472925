import React, { forwardRef, Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { mergeRefs } from 'react-merge-refs'
import { useTheme, ThemeProvider } from 'styled-components'
import { useFlash } from 'hooks/flash'
import {
    PieceWrapper,
    EquipmentCellClickable, EquipmentCellWrapper, Symbol, EquipmentMessage,
    StatusCell, ExpiryStatus,
    VariantCell,
    ActionsCell
} from './s'
import Tooltip from 'components/tooltip'
import Ellipsify from 'components/ellipsify'
import MobileLabel from 'components/typography/mobile-label'
import MissingDates from 'pages/equipment/components/missing-dates'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'

const EquipmentPiece = forwardRef(({ piece, actions = {}, singleton, flash }, ref) => {
    const { formatMessage } = useIntl()

    const theme = useTheme()

    const flasher = useFlash(flash)

    const {
        id,
        type,
        attachments,
        links,
        fromDate,
        toDate,
        serialNumber,
        variant
    } = piece

    const {
        requireFromDate,
        requireToDate,
        requireDocumentation,
        requireSerialNumber
    } = type ?? {}

    const missingDocumentation = !!requireDocumentation && (!attachments?.length && !links?.length)
    const missingSerialNumber = !!requireSerialNumber && !serialNumber

    const view = actions?.view?.(piece)

    const [EquipmentWrapper, equipmentWrapperProps] = view ?
        [Tooltip, {
            content: formatMessage({
                id: 'action_view_details',
                defaultMessage: 'View details'
            }),
            singleton
        }] :
        [Fragment, null]

    const [EquipmentCell, equipmentCellProps] = view ?
        [EquipmentCellClickable, { onClick: view.onClick }] :
        [EquipmentCellWrapper, null]

    theme.equipment = {
        variant: !!variant
    }

    const boundActions = getBoundActions(actions, piece)

    const salt = `equipment:piece:${id}`

    return (
        <ThemeProvider theme={theme}>
            <PieceWrapper ref={mergeRefs([ref, flasher])}>
                <EquipmentWrapper {...equipmentWrapperProps}>
                    <EquipmentCell {...equipmentCellProps}>
                        <Symbol
                            symbol={type.symbol}
                            className="hover-effect" />
                        <Ellipsify text={type.name} />
                    </EquipmentCell>
                </EquipmentWrapper>
                <VariantCell>
                    {!!variant && (
                        <>
                            <MobileLabel className="endspace">
                                <FormattedMessage
                                    id="equipment_label_variant"
                                    defaultMessage="Variant" />
                            </MobileLabel>
                            <span>{variant}</span>
                        </>
                    )}
                </VariantCell>
                <StatusCell>
                    <ExpiryStatus
                        from={fromDate}
                        to={toDate}
                        requireEnd={requireToDate} />
                    <MissingDates
                        requireStart={requireFromDate}
                        requireEnd={requireToDate}
                        start={fromDate}
                        end={toDate} />
                    {!!missingDocumentation && (
                        <EquipmentMessage
                            message={formatMessage({
                                id: 'equipment_status_documentation_missing',
                                defaultMessage: 'Documentation missing'
                            })}
                            type="error" />
                    )}
                    {!!missingSerialNumber && (
                        <EquipmentMessage
                            message={formatMessage({
                                id: 'equipment_status_serial_number_missing',
                                defaultMessage: 'Serial number missing'
                            })}
                            type="error" />
                    )}
                </StatusCell>
                {!!boundActions?.length && (
                    <ActionsCell>
                        <ContextMenu
                            prebound actions={boundActions}
                            salt={salt} />
                    </ActionsCell>
                )}
            </PieceWrapper>
        </ThemeProvider>
    )
})

export default EquipmentPiece

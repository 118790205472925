import styled, { css } from 'styled-components'
import {
    svgStroke,
    belowTablet
} from 'utilities/styled'
import { compact } from 'utilities/array'
import { Grid } from 'components/grid'
import CircleBase from 'components/progress-circle'
import { ParagraphSmall } from 'components/typography/paragraph'
import { Wrapper as StatisticsGroupWrapper } from 'components/statistics-group/s'
import { PersonWrapper as ProcessWrapper } from 'pages/processes/lists/processes/xboarding/process/s'

export const Layout = styled(Grid)`
    align-items: center;
    column-gap: 16px;

    ${p => css`
        grid-template-columns: ${compact([
            `${p.$size}px`,
            (p.$mode !== 'compact') && '1fr'
        ]).join(' ')};
    `}

    .service-onboarding & {
        ${belowTablet`
            grid-template-columns: ${p => p.$size}px;
        `}
    }

    ${StatisticsGroupWrapper} & {
        ${belowTablet`
            grid-template-columns: auto ${p => p.$size}px;
            justify-content: start;
        `}
    }

    ${ProcessWrapper} & {
        display: flex;

        ${belowTablet`
            grid: none;
        `}
    }
`

export const Circle = styled(CircleBase)`
    ${StatisticsGroupWrapper} & {
        ${belowTablet`
            order: 2;
        `}
    }
`

export const Completed = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100%;
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    background-color: var(--huma-color-surface-constructive-bold);

    color: var(--huma-color-foreground-on-bold);

    svg {
        ${svgStroke()}
    }

    ${StatisticsGroupWrapper} & {
        ${belowTablet`
            order: 2;
        `}
    }
`

export const Details = styled(ParagraphSmall)`
    white-space: nowrap;
`
import React, { forwardRef } from 'react'
import {
    Assignee,
    PersonCell,
    Cell,
    ActionsCell
} from './s'
import Person from 'components/person'
import { TriggerTexts as Entities } from 'components/entities'
import ContextMenu, { getActionsArray } from 'widgets/context-menu'

const PolicyAssignee = forwardRef(({ assignee, actions = {}, salt }, ref) => {
    const {
        locations,
        teams
    } = assignee

    const hasActions = !!getActionsArray(actions).length

    return (
        <Assignee ref={ref}>
            <PersonCell>
                <Person
                    who={assignee}
                    asLink={true}
                    showPosition={true}
                    truncate={true}
                    size={40} />
            </PersonCell>
            <Cell>
                <Entities
                    entities={locations}
                    preview={true}
                    salt={`${salt}:locations`} />
            </Cell>
            <Cell>
                <Entities
                    entities={teams}
                    preview={true}
                    salt={`${salt}:teams`} />
            </Cell>
            {hasActions && (
                <ActionsCell>
                    <ContextMenu
                        salt={salt}
                        context={assignee}
                        actions={actions} />
                </ActionsCell>
            )}
        </Assignee>
    )
})

export default PolicyAssignee

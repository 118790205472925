import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Columns as ColumnsBase, FlexChild } from 'components/flex'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import FileIconBase from 'components/file-icon'

export const Columns = styled(ColumnsBase)`
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(Columns)}
`

export const FileIcon = styled(FileIconBase)`
    display: flex;
    margin-inline-end: 16px;

    svg.feather {
        ${svgStroke()}
    }
`

export const NameColumn = styled(FlexChild).attrs(({ as = 'p', ...rest }) => ({
    ...rest,
    as
}))`
    :where(a, button):is(:hover, :focus-within) & {
        &, & span {
            text-decoration: underline;
        }
    }
`

export const FreeFlow = styled.span`
    display: block;
    width: 100%;
    position: relative;
    word-break: break-word;
    overflow-wrap: anywhere;
`

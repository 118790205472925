import { Extension } from '@tiptap/core'
import { AllSelection, TextSelection } from '@tiptap/pm/state'
import { clamp } from 'utilities/math'

const Indent = Extension.create({
    name: 'indent',

    addOptions() {
        return {
            types: ['heading', 'paragraph'],
            minLevel: 0,
            maxLevel: 5
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    indent: {
                        renderHTML: attributes => {
                            return attributes?.indent > this.options.minLevel ? { 'data-indent': attributes.indent } : null
                        },
                        parseHTML: element => {
                            const level = Number(element.getAttribute('data-indent'))
                            return level && level > this.options.minLevel ? level : null
                        }
                    }
                }
            }
        ]
    },

    addCommands() {
        const setNodeIndentMarkup = (transaction, position, delta) => {
            const node = transaction?.doc?.nodeAt(position)

            if(node) {
                const nextLevel = (node.attrs.indent || 0) + delta
                const indent = clamp(nextLevel, this.options.minLevel, this.options.maxLevel)

                if(indent !== node.attrs.indent) {
                    const { indent: oldIndent, ...currentAttrs } = node.attrs // eslint-disable-line no-unused-vars

                    return transaction.setNodeMarkup(position, node.type, {
                        ...currentAttrs,
                        ...((indent > this.options.minLevel) ? { indent } : null)
                    }, node.marks)
                }
            }

            return transaction
        }

        const updateIndentLevel = (transaction, delta) => {
            const { doc, selection } = transaction

            if(doc && selection && (selection instanceof TextSelection || selection instanceof AllSelection)) {
                doc.nodesBetween(selection.from, selection.to, (node, position) => {
                    if(this.options.types.includes(node.type.name)) {
                        transaction = setNodeIndentMarkup(transaction, position, delta)
                        return false
                    }

                    return true
                })
            }

            return transaction
        }

        const xdent = direction => (backspace = false) => ({ tr: transaction, state, dispatch }) => {
            // Do not indent if the cursor is in a list
            if(['bulletList', 'orderedList'].some(t => this.editor.isActive(t))) {
                return false
            }

            let maxOutdented = true

            const { doc, selection } = transaction
            if(doc && selection && (selection instanceof TextSelection || selection instanceof AllSelection)) {
                doc.nodesBetween(selection.from, selection.to, node => {
                    maxOutdented = !node.attrs.indent
                })
            }

            if(backspace && maxOutdented) {
                return false
            }

            // Do not outdent if the cursor isn’t at the beginning of the line, or if some text is selected
            if(backspace && (state.selection.$anchor.parentOffset > 0 || state.selection.from !== state.selection.to)) {
                return false
            }

            transaction = transaction.setSelection(state.selection)
            transaction = updateIndentLevel(transaction, direction)

            if(transaction.docChanged) {
                dispatch?.(transaction)
            }

            return true
        }

        return {
            indent: xdent(1),
            outdent: xdent(-1)
        }
    },

    addKeyboardShortcuts() {
        return {
            Tab: () => this.editor.commands.indent(),
            'Shift-Tab': () => this.editor.commands.outdent(),
            Backspace: () => this.editor.commands.outdent(true)
        }
    }
})

export default Indent
import React, { Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { map, omit, filter } from 'utilities/object'
import {
    ListHeading, DefinitionList, DefinitionTerm, DefinitionDescription,
    FieldStatus, AlertIcon
} from './s'
import { ModalHeader } from 'modals/generic'
import { hasValue } from '../'
import Caption from 'components/typography/caption'

const Summary = ({ meta, person, dismiss }) => {
    const { formatMessage } = useIntl()

    person = structuredClone(person)

    const importantFields = omit(
        filter(person, ({ completeness }) => !!completeness && completeness === 'important'),
        'employmentStartDate' // TODO: Remove this once backend doesn't return this field
    )
    const otherFields = filter(person, ({ completeness }) => !!completeness && completeness === 'details')

    if(!!importantFields.jobTitle?.value?.description) {
        otherFields.jobDescription.value = importantFields.jobTitle.value.description
    }

    return (
        <>
            <ModalHeader
                heading={formatMessage({
                    id: 'profile_completion_heading',
                    defaultMessage: 'Profile completion'
                })}
                dismiss={dismiss} />
            <Caption>
                {formatMessage({
                    id: `profile_completion_me_helper_tier_${meta.tier}`,
                    defaultMessage: 'A stronger profile will help others.'
                }, {
                    info: formatMessage({
                        id: `profile_completion_incomplete_link_tier_${meta.tier}`,
                        defaultMessage: 'important information'
                    })
                })}
            </Caption>
            <ListHeading className="compact">
                <FormattedMessage
                    id="profile_completion_field_heading_important"
                    defaultMessage="Important information" />
            </ListHeading>
            <DefinitionList>
                {map(importantFields, ({ value, editable }, field) => (
                    <Fragment key={`profile-completion:summary:${field}`}>
                        <DefinitionTerm>
                            <FormattedMessage
                                id={`profile_completion_field_name_${field}`}
                                defaultMessage={field} />
                        </DefinitionTerm>
                        <DefinitionDescription>
                            {hasValue(value) ?
                                <AlertIcon type="success" /> :
                                (
                                    <FieldStatus className={editable ? 'empty' : 'no-access'}>
                                        <FormattedMessage
                                            id={`profile_completion_field_status_${editable ? 'empty' : 'no_access'}`}
                                            defaultMessage={editable ? 'Needs updating' : 'No access'} />
                                    </FieldStatus>
                                )
                            }
                        </DefinitionDescription>
                    </Fragment>
                ))}
            </DefinitionList>
            <ListHeading className="compact">
                <FormattedMessage
                    id="profile_completion_field_heading_other"
                    defaultMessage="Details" />
            </ListHeading>
            <DefinitionList>
                {map(otherFields, ({ value }, field) => (
                    <Fragment key={`profile-completion:summary:${field}`}>
                        <DefinitionTerm>
                            <FormattedMessage
                                id={`profile_completion_field_name_${field}`}
                                defaultMessage={field} />
                        </DefinitionTerm>
                        <DefinitionDescription>
                            {hasValue(value) ?
                                <AlertIcon type="success" /> :
                                (
                                    <FormattedMessage
                                        id="profile_completion_field_status_not_set"
                                        defaultMessage="Not set" />
                                )
                            }
                        </DefinitionDescription>
                    </Fragment>
                ))}
            </DefinitionList>
        </>
    )
}

export default Summary

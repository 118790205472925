import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import PubSub from 'pubsub-js'
import { useAccess } from 'contexts/access'
import EntitiesProvider from 'contexts/entities'
import { useAppSignal } from 'hooks/app-signal'
import { modalify, Wrapper, Veil, Modal } from 'utilities/modal'
import { size, omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { FlexChildShrink } from 'components/flex'
import {
    Container, Hero, Herows, Heading,
    ContentRow, Status, EmptyContainer, CreateButton,
    Actions
} from './s'
import Paragraph from 'components/typography/paragraph'
import Message from 'components/message'
import Filter from './filter'
import Search from './search'
import Entities from './entities'
import { Plain } from 'components/button'

// Creation. TODO: Consider lazifying
import { Plus } from 'styled-icons/feather'
import AddGroup from 'pages/people/modals/add-group'
import AddJobTitle from 'pages/settings/pages/job-titles/content/edit'
import AddCompetenceType from 'pages/competence/modals/type/edit'
import AddEquipmentType from 'pages/equipment/modals/edit-type'
import AddEquipmentTypeVariant from 'pages/equipment/modals/add-type-variant'

const EntityPicker = ({ className, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const namespace = `${salt}:entity-picker:filter`

    const [createdEntities, setCreatedEntities] = useState([])
    const [picked, setPicked] = useState(null)
    const [creating, setCreating] = useState(false)
    const [resettable, setResettable] = useState(false)
    const [filterResetAt, setFilterResetAt] = useState(Date.now())

    const {
        dismiss,
        doneAction,
        cancelAction,
        type,
        path,
        entities,
        emptyOption,
        sticky = [],
        params = {},
        creator = {},
        mapper,
        creatable = false,
        refreshOnDismiss = false,
        search: searchConfig,
        heading = formatMessage({
            id: 'action_pick',
            defaultMessage: 'Pick'
        }),
        caption,
        message,
        heroContent,
        emptyText = formatMessage({
            id: 'empty_filter',
            defaultMessage: '🤷 Sorry, nothing was found with your current filter configuration.'
        }),
        ...modal
    } = props

    useAppSignal({
        namespace: `${namespace}:advanced-applied`,
        action: advancedApplied => setResettable(advancedApplied)
    })

    const { check } = useAccess()

    let typeOverride
    let createAccess = (typeToPermissionsMap[type] ?? []).some(check)
    let Creator = typeToCreator[type]

    if(!type && params.types?.length === 2 && params.types?.includes('team') && params.types?.includes('location')) {
        typeOverride = 'group'
        createAccess = typeToPermissionsMap.group.some(check)
        Creator = typeToCreator.group
    }

    const {
        show = false,
        outer = true
    } = modal

    useEffect(() => {
        setPicked(props.picked ?? null)
        return () => setPicked(null)
    }, [show, props.picked])

    const pick = entity => {
        doneAction({ picked: entity }).onClick()
        dismiss()
    }

    const cancel = cancelAction()

    const animate = show ? 'in' : 'out'

    className = cls([
        className,
        creating && 'creating'
    ])

    const searchLocally = setSearch => ({ search }) => setSearch(search)

    salt = `modal:entity-picker:${salt}`

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    {!!outer && <Veil animate={animate} />}
                    <Modal
                        dismiss={dismiss}
                        key={salt}>
                        <EntitiesProvider
                            entities={entities}
                            path={path}
                            filter={params}
                            entityMapper={mapper}
                            autoFetch={true}>
                            {({ entities, fixed, filtered, loading, fetching, hasFetched, paging, setLocalSearch, setEntitiesFilter }) => {
                                entities = filtered ?? entities
                                const showStatus = !!entities.length && !loading

                                return (
                                    <Container
                                        {...(className ? { className } : null)}
                                        animate={animate}>
                                        {!creating && (
                                            <Hero>
                                                <Herows>
                                                    <FlexChildShrink>
                                                        <Heading>{heading}</Heading>
                                                    </FlexChildShrink>
                                                    {!!caption && (
                                                        <FlexChildShrink>
                                                            <Paragraph className="caption compact">{caption}</Paragraph>
                                                        </FlexChildShrink>
                                                    )}
                                                    {!!message && (
                                                        <FlexChildShrink>
                                                            <Message
                                                                {...message}
                                                                className={cls(['compact', message?.className])} />
                                                        </FlexChildShrink>
                                                    )}
                                                    {!!heroContent && (
                                                        <FlexChildShrink>
                                                            {heroContent}
                                                        </FlexChildShrink>
                                                    )}
                                                </Herows>
                                            </Hero>
                                        )}
                                        {(!!path && !creating && !fixed) && (
                                            <Filter
                                                {...(size(searchConfig ?? {}) ? {
                                                    overrides: {
                                                        search: {
                                                            ...searchConfig,
                                                            autoFocus: true
                                                        }
                                                    }
                                                } : null)}
                                                salt={`${salt}:filter`}
                                                key={filterResetAt} />
                                        )}
                                        {(!!searchConfig && !path && !creating) && (
                                            <Search
                                                {...searchConfig}
                                                setSearch={searchLocally(setLocalSearch)}
                                                salt={salt} />
                                        )}
                                        <ContentRow>
                                            {(!showStatus && (loading || fetching) && !fixed && !creating) && (
                                                <Status className="caption small compact">
                                                    <FormattedMessage
                                                        id="message_loading"
                                                        defaultMessage="Loading…" />
                                                </Status>
                                            )}
                                            {(!!showStatus && !creating) && (
                                                <Status className="caption small compact">
                                                    <FormattedMessage
                                                        id={(!fixed && !!paging?.hasNextPage) ?
                                                            'picker_status_scroll_to_load_more' :
                                                            'picker_status_all_loaded'
                                                        }
                                                        defaultMessage={(!fixed && !!paging?.hasNextPage) ?
                                                            '{count} loaded – scroll to load more' :
                                                            'All {count} are loaded'
                                                        }
                                                        values={{ count: entities.length }} />
                                                </Status>
                                            )}
                                            {(!creating && !entities.length && !loading && hasFetched) && (
                                                <EmptyContainer>
                                                    <Paragraph className="caption small">{emptyText}</Paragraph>
                                                    {resettable && (
                                                        <Plain
                                                            className="destructive"
                                                            onClick={() => {
                                                                setEntitiesFilter(params)
                                                                setFilterResetAt(Date.now())
                                                            }}>
                                                            <FormattedMessage
                                                                id="action_reset_filters"
                                                                defaultMessage="Reset filters" />
                                                        </Plain>
                                                    )}
                                                </EmptyContainer>
                                            )}
                                            {(!!creatable && !!createAccess && !!Creator) && (
                                                <>
                                                    {!creating && (
                                                        <CreateButton
                                                            className="constructive"
                                                            onClick={() => setCreating(true)}>
                                                            <Plus size={20} />
                                                            {creator?.heading ?? formatMessage(typeToCreateTextMap[typeOverride ?? type])}
                                                        </CreateButton>
                                                    )}
                                                    {!!creating && (
                                                        <Creator
                                                            {...omit(creator, 'transform', 'onCreated')}
                                                            doneLabel={formatMessage({
                                                                id: 'action_add_and_pick',
                                                                defaultMessage: 'Add and pick'
                                                            })}
                                                            onDone={entity => {
                                                                if(entity) {
                                                                    const transform = creator?.transform ?? (e => e)
                                                                    entity = transform(entity)

                                                                    setCreatedEntities(createdEntities => [
                                                                        entity,
                                                                        ...createdEntities
                                                                    ])

                                                                    creator?.onCreated?.(entity)
                                                                    pick(entity)
                                                                }

                                                                if(refreshOnDismiss) {
                                                                    PubSub.publish('entities.refresh')
                                                                }

                                                                setCreating(false)
                                                            }}
                                                            salt={`${salt}:create:entity`} />
                                                    )}
                                                </>
                                            )}
                                            {!creating && (
                                                <Entities
                                                    {...creator}
                                                    type={type}
                                                    emptyOption={emptyOption}
                                                    sticky={sticky}
                                                    created={createdEntities}
                                                    picked={picked}
                                                    pick={pick}
                                                    isFiltering={!!filtered}
                                                    salt={salt} />
                                            )}
                                        </ContentRow>
                                        {!creating && (
                                            <FlexChildShrink>
                                                <Actions className="centered">
                                                    <Plain
                                                        className={cancel.effect}
                                                        onClick={cancel.onClick}>
                                                        {cancel.label}
                                                    </Plain>
                                                </Actions>
                                            </FlexChildShrink>
                                        )}
                                    </Container>
                                )
                            }}
                        </EntitiesProvider>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

export default EntityPicker

const typeToPermissionsMap = {
    group: ['groups:manage', 'teams:manage', 'locations:manage'],
    team: ['groups:manage', 'teams:manage'],
    location: ['groups:manage', 'locations:manage'],
    jobTitle: ['users:manage'],
    competenceType: ['competence:manage'],
    equipmentType: ['equipment:manage'],
    equipmentTypeVariant: ['equipment:manage']
}

const typeToCreateTextMap = {
    group: {
        id: 'group_action_add',
        defaultMessage: 'Add group'
    },
    team: {
        id: 'team_action_add',
        defaultMessage: 'Add team'
    },
    location: {
        id: 'location_action_add',
        defaultMessage: 'Add location'
    },
    jobTitle: {
        id: 'jobtitle_action_add',
        defaultMessage: 'Add job title'
    },
    competenceType: {
        id: 'competence_type_action_add',
        defaultMessage: 'Add competence type'
    },
    equipmentType: {
        id: 'equipment_type_action_add',
        defaultMessage: 'Add equipment type'
    },
    equipmentTypeVariant: {
        id: 'equipment_variant_action_add',
        defaultMessage: 'Add variant'
    }
}

const typeToCreator = {
    group: props => <AddGroup {...props} />,
    team: props => <AddGroup {...props} type="team" />,
    location: props => <AddGroup {...props} type="locaton" />,
    jobTitle: props => <AddJobTitle {...props} provide />,
    competenceType: props => <AddCompetenceType {...props} prepopulate={false} />,
    equipmentType: props => <AddEquipmentType {...props} />,
    equipmentTypeVariant: props => <AddEquipmentTypeVariant {...props} />
}
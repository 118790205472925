import styled from 'styled-components'
import {
    svgStroke,
    belowPhone
} from 'utilities/styled'
import { Grid } from 'components/grid'
import { H4 } from 'components/typography/heading'
import FlapsBase from 'components/flaps'
import MatrixBase from 'components/matrix'
import { paragraphCss } from 'components/typography/paragraph'
import { fontSans } from 's'

export const Main = styled.main`
    container-type: inline-size;
    container-name: smart-preview;

    position: relative;
    overflow: auto;

    border-radius: 8px 0 0 8px;
    background-color: var(--huma-color-surface-minimal);

    .no-select > & {
        user-select: none;
    }

    ${belowPhone`
        border-radius: 16px 16px 0 0;
        width: 100%;
    `}
`

export const Content = styled(Grid)`
    place-items: center;

    border-radius: 8px 0 0 8px;
    width: 100%;
    height: 100%;
    padding: 32px;

    &.pdf {
        padding: 0;
    }

    ${belowPhone`
        overflow: hidden;
        border-radius: 0;
        height: calc(100dvh - 160px);
        padding: 56px 16px 16px;

        &.loading {
            min-height: 240px;
        }

        &.pdf {
            padding: 56px 16px 16px;
        }

        &.unsupported {
            height: 88px;
            padding: 16px;
        }
    `}
`

export const Heading = styled(H4)`
    margin-block-end: 16px;

    &.sticky {
        position: sticky;
        left: 0;
    }
`

export const Objekt = styled.object`
    overflow: hidden;
    object-fit: scale-down;
    border-radius: 8px 0 0 8px;
    width: 100%;
    height: 100%;
`

export const Embed = styled.embed`
    width: 100%;
    height: 100%;
    object-fit: scale-down;
`

export const Img = styled.img`
    max-width: 100%;
    max-height: calc(100dvh - 48px - 64px);
`

export const Flaps = styled(FlapsBase)`
    grid-template-columns: minmax(0, 1fr);

    overflow: hidden;
    width: 100%;
    height: 100%;
`

export const FlapContent = styled.div`
    overflow: scroll;
    width: 100%;
    height: 100%;
`

export const Matrix = styled(MatrixBase)`
    width: auto;
    min-width: 100%;
`

export const PlaintextContent = styled.div`
    width: 100%;
    height: 100%;
`

export const Preintext = styled.pre`
    ${paragraphCss}
    ${fontSans}

    white-space: pre-wrap;
    overflow-wrap: break-word;
    overflow: auto;
    margin: 0;
`

export const UnsupportedWrap = styled(Grid)`
    place-items: center;
    grid-auto-rows: auto;
    grid-auto-flow: row;
    grid-row-gap: 8px;

    svg {
        ${svgStroke()}

        color: var(--huma-color-foreground-subtle);
    }
`
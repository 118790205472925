import React, { useState, useCallback, useEffect, useLayoutEffect } from 'react'
import {
    Labels, Label,
    Boolean, State, BooleanLabel
} from './s'
import { RadiobuttonInput } from 'components/form/input/radiobutton'
import { Columns } from 'components/flex'

const ToggleSwitch = ({ value: passedValue = false, no, yes, controlled = false, onChange, ...props }) => {
    const [value, setValue] = useState(passedValue)

    const setValueConditionally = useCallback((next, notify = true) => {
        if(value !== next) {
            setValue(next)
            notify && onChange?.(next)
        }
    }, [value])

    useEffect(() => {
        controlled && setValueConditionally(passedValue, false)
    }, [controlled, setValueConditionally, passedValue])

    return (
        <Labels {...props}>
            <Label>
                <RadiobuttonInput
                    name="toggle"
                    value="no"
                    checked={!value}
                    onChange={() => setValueConditionally(false)} />
                <span>{no}</span>
            </Label>
            <Label>
                <RadiobuttonInput
                    name="toggle"
                    value="yes"
                    checked={value}
                    onChange={() => setValueConditionally(true)} />
                <span>{yes}</span>
            </Label>
        </Labels>
    )
}

export const BooleanSwitch = ({ value: initialValue = false, currentValue, onChange, label, ...props }) => {
    const [value, setValue] = useState(initialValue)

    const flipValue = useCallback(() => {
        onChange?.(!value)
        setValue(!value)
    }, [value])

    useLayoutEffect(() => {
        if(typeof currentValue !== 'undefined' && currentValue !== value) {
            flipValue()
        }
    }, [currentValue])

    const animate = `${value}`

    return (
        <Columns
            {...props}
            onClick={flipValue}>
            <BooleanLabel>
                {label}
            </BooleanLabel>
            <Boolean animate={animate}>
                <State animate={animate} />
            </Boolean>
        </Columns>
    )
}

export default ToggleSwitch
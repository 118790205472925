import React, { useState, useEffect } from 'react'
import { useTheme, ThemeProvider } from 'styled-components'
import {
    Container,
    Header, IconCell, HeadingCell, ActionCell, SummaryCell, ToggleCell,
    Heading, Summary,
    Drawer, Content
} from './s'
import { ArrowSortedDown as ToggleArrow } from 'styled-icons/typicons'

const Expandable = ({ children = null, defer = false, expanded: outsideExpanded = false, callback = () => {}, ...props }) => {
    let theme = useTheme()

    const [ready, setReady] = useState(!defer)
    const [expanded, setExpanded] = useState(outsideExpanded)

    useEffect(() => {
        expanded && setReady(true)
    }, [expanded])

    // React to prop changes
    useEffect(() => {
        setExpanded(outsideExpanded)
    }, [outsideExpanded, setExpanded])

    const {
        icon = null,
        heading = '',
        summary = '',
        action = null,
        salt, // eslint-disable-line no-unused-vars
        ...attributes
    } = props

    const toggleExpanded = () => setExpanded(expanded => {
        callback?.(!expanded)
        return !expanded
    })

    theme = {
        ...theme,
        expandable: {
            icon: !!icon,
            action: !!action,
            summary: !!summary,
            content: !!children
        }
    }

    const animate = (ready && expanded) ? 'expanded' : 'contracted'

    return (
        <ThemeProvider theme={theme}>
            <Container {...attributes}>
                <Header
                    {...(!!children ? {
                        onClick: toggleExpanded,
                        tabIndex: 0,
                        role: 'button'
                    } : null)}>
                    {!!icon && (
                        <IconCell>
                            {icon}
                        </IconCell>
                    )}
                    {!!heading && (
                        <HeadingCell>
                            <Heading>
                                {heading}
                            </Heading>
                        </HeadingCell>
                    )}
                    {action && (
                        <ActionCell>{action}</ActionCell>
                    )}
                    {!!summary && (
                        <SummaryCell>
                            <Summary className="compact">
                                {summary}
                            </Summary>
                        </SummaryCell>
                    )}
                    <ToggleCell animate={animate}>
                        <Indicator />
                    </ToggleCell>
                </Header>
                <Drawer animate={animate}>
                    <Content>
                        {!!ready && children}
                    </Content>
                </Drawer>
            </Container>
        </ThemeProvider>
    )
}

export const Indicator = () => <ToggleArrow size={16} />

export default Expandable
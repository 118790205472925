import styled from 'styled-components'
import { fromTablet, belowTablet } from 'utilities/styled'
import { Grid } from 'components/grid'
import { FlexChildShrink } from 'components/flex'
import { H4 } from 'components/typography/heading'
import { CaptionSmall } from 'components/typography/caption'

export const Wrapper = styled(Grid)`
    grid-auto-columns: minmax(max-content, 1fr);
    grid-auto-flow: column;
    align-items: center;

    margin-block-end: 32px;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    padding: 16px 8px;

    ${belowTablet`
        grid-auto-flow: row;

        padding: 8px 0;
    `}
`

export const Value = styled(H4)`
    display: block;
    flex: none;
`

export const Label = styled(CaptionSmall)`
    position: relative;
`

export const Item = styled(FlexChildShrink)`
    padding-inline: 24px;

    &:not(.free) {
        display: flex;
        align-items: center;
        column-gap: 8px;

        height: 40px;
        padding: 8px 24px;
    }

    ${fromTablet`
        &:not(:last-of-type) {
            border-right: 1px solid var(--huma-color-border-default);
        }
    `}

    ${belowTablet`
        &,
        &:not(.free) {
            padding: 8px 16px;
        }
    `}

    &.info,
    &.action {
        & ${Value},
        & ${Label} {
            color: var(--huma-color-foreground-constructive-bold);
        }
    }

    &.warning {
        & ${Value},
        & ${Label} {
            color: var(--huma-color-foreground-warning);
        }
    }

    &.error {
        & ${Value},
        & ${Label} {
            color: var(--huma-color-foreground-destructive);
        }
    }
`
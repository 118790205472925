import styled from 'styled-components'
import { Grid } from 'components/grid'

export const Layout = styled(Grid)`
    grid-template-rows: minmax(0, 1fr) min-content;
    grid-row-gap: 24px;
`

export const Content = styled.div``
export const Menu = styled.div``

export const Flaps = styled(Grid).attrs({ as: 'nav' })`
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    grid-column-gap: 8px;
    user-select: none;
`

export const Flap = styled.a`
    cursor: pointer;

    border-radius: 16px;
    padding: 0 12px;

    transition: color .1s ease-in-out;

    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    text-align: right;
    text-decoration: none;
    white-space: nowrap;
    color: rgb(from var(--huma-color-foreground-default) r g b / .8);

    &:where(:hover, .current) {
        background-color: var(--huma-color-surface-subtle);

        color: var(var(--huma-color-foreground-default));
    }
`

import React from 'react'
import { FlexChildShrink } from 'components/flex'
import { Pills, PillCoating, Pill } from './s'
import { isSameDay } from 'date-fns'

const Shortcuts = ({
    shortcuts, salt,
    selectedTime, selectedTimeEnd,
    setSelectedTime, setSelectedTimeEnd, setNavigationState, setPickingTimeEnd, setDirty
}) => {
    if(!shortcuts?.length) {
        return null
    }

    return (
        <FlexChildShrink>
            <Pills>
                {shortcuts.map(({ label, range: [start, end] }) => {
                    const sameStart = !!start && isSameDay(start, selectedTime)
                    const sameEnd = !!end && isSameDay(end, selectedTimeEnd)
                    const active = sameStart && sameEnd

                    return (
                        <PillCoating key={`${salt}:shortcut:${label}`}>
                            <Pill
                                className={`neutral${active ? ' active' : ''}`}
                                onClick={() => {
                                    setSelectedTime(start)
                                    setSelectedTimeEnd(end)
                                    setNavigationState(end)
                                    setPickingTimeEnd(true)
                                    setDirty(true)
                                }}>
                                {label}
                            </Pill>
                        </PillCoating>
                    )
                })}
            </Pills>
        </FlexChildShrink>
    )
}

export default Shortcuts
import styled from 'styled-components'
import {
    interpointDivider,
    belowTablet
} from 'utilities/styled'
import { Grid } from 'components/grid'

export const Summary = styled.span`
    ${interpointDivider()}
`

export const Actions = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 32px;
    justify-content: start;

    margin-bottom: 24px;

    ${belowTablet`
        grid-auto-flow: row;
        grid-template: 1fr / auto;
        row-gap: 8px;

        margin-bottom: 0;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--huma-color-border-default);
    `}
`
import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useEquipmentPieces } from 'contexts/equipment-pieces'
import { useTheme, ThemeProvider } from 'styled-components'
import { clamp } from 'utilities/math'
import { size, omit } from 'utilities/object'
import {
    Wrap, Header,
    GridColumnHeaderSortable, GridColumnHeader,
    LoadingContainer, Tutorial, SkeletonListRow, SkeletonCell,
    EmptyText
} from './s'
import Tooltip, { useSingleton } from 'components/tooltip'
import Empty from 'components/empty'
import Link from 'components/link'
import Caption from 'components/typography/caption'
import { SkeletonAvatarAndFullName, SkeletonStrings } from 'components/skeleton'
import Piece from './piece'
import Loader from 'components/loader'
import { Ghost } from 'components/button'
import { getActionsArray } from 'widgets/context-menu'

const EquipmentPieces = ({ actions = {}, header = false, group = null, className }) => {
    const { formatMessage } = useIntl()

    let theme = useTheme()

    const [source, target] = useSingleton()

    const [skeletonLength, setSkeletonLength] = useState(5)

    const {
        pieces,
        intersecter,
        loading,
        sorting = {},
        toggleSorting,
        paging = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched,
        filter = {},
        flash,
        clearFlash
    } = useEquipmentPieces()

    const hasActions = !!getActionsArray(actions).length

    theme = {
        ...theme,
        actions: hasActions
    }

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(pieces.length, 1, 10))
        }
    }, [hasFetched, pieces?.length])

    const filtersApplied = !!size(omit(filter, 'assignedToIds'))

    return (
        <ThemeProvider theme={theme}>
            <Tooltip
                singleton={source}
                placement="top-start"
                delay={[500, 250]} />
            <Wrap {...(className ? { className } : null)}>
                {(header || fetching) && (
                    <Header>
                        <GridColumnHeaderSortable
                            field="type_name"
                            sort={sorting}
                            toggle={toggleSorting}
                            text={formatMessage({
                                id: 'noun_name',
                                defaultMessage: 'Name'
                            })} />
                        <GridColumnHeaderSortable
                            field="variant"
                            sort={sorting}
                            toggle={toggleSorting}
                            text={formatMessage({
                                id: 'equipment_label_variant',
                                defaultMessage: 'Variant'
                            })} />
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_status"
                                defaultMessage="Status" />
                        </GridColumnHeader>
                    </Header>
                )}
                {(!pieces.length && hasFetched && !fetching && !filtersApplied) && (
                    <Tutorial which="equipment" />
                )}
                {(!pieces.length && hasFetched && !fetching && !!filtersApplied) && (
                    <Empty which="equipment">
                        {!!group && (
                            <>
                                <EmptyText>
                                    <FormattedMessage
                                        id="equipment_group_empty"
                                        defaultMessage="Equipment for the members of {group} will be displayed here."
                                        values={{ group: group.name }} />
                                </EmptyText>
                                <Link
                                    to="path:equipment.base"
                                    className="constructive">
                                    <FormattedMessage
                                        id="equipment_action_navigate"
                                        defaultMessage="Go to equipment" />
                                </Link>
                            </>
                        )}
                        {!group && (
                            <Caption className="compact">
                                <FormattedMessage
                                    id="equipment_empty_filter_message"
                                    defaultMessage="🤷 Sorry, no equipment found." />
                            </Caption>
                        )}
                    </Empty>
                )}
                {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow
                        columns={3}
                        hasActions={true}
                        key={`list:equipment:pieces:skeleton:${index}`}>
                        <SkeletonCell>
                            <SkeletonAvatarAndFullName size={40} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings
                                size={16}
                                length={12} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings
                                size={16}
                                length={12} />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {pieces.map((piece, index) => {
                    const last = index + 1 === pieces.length

                    return (
                        <Piece
                            piece={piece}
                            actions={actions}
                            {...(last ? { ref: intersecter } : null)}
                            singleton={target}
                            flash={flash?.current === piece ? clearFlash : null}
                            key={piece.id} />
                    )
                })}
                {!!paging?.hasNextPage && (
                    <LoadingContainer>
                        {(!loading && !autoFetch) && (
                            <Ghost
                                className="constructive"
                                onClick={fetch}>
                                <FormattedMessage
                                    id="action_load_more"
                                    defaultMessage="Load more…"
                                />
                            </Ghost>
                        )}
                        {!!loading && <Loader />}
                    </LoadingContainer>
                )}
            </Wrap>
        </ThemeProvider>
    )
}

export default EquipmentPieces

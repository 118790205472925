import styled from 'styled-components'
import { flexColumnsCss, flexChildCss, flexChildShrinkCss } from 'components/flex'

export const Wrap = styled.span`
    ${flexColumnsCss}
    display: inline-flex;
    max-width: 100%;

    text-decoration: inherit;

    &.truncated {
        cursor: help;

        &:hover {
            text-decoration: underline dotted;
        }
    }

    :where(a, button) &.truncated {
        cursor: pointer;

        &.no-underline:hover {
            text-decoration: unset;
        }
    }
`

export const Rigid = styled.span`
    ${flexChildShrinkCss}
    white-space: pre;
`

export const Flexible = styled.span`
    ${flexChildCss}
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
`
import React from 'react'
import { usePeople } from 'contexts/people'
import { LoadingContainer } from './s'
import Loader from 'components/loader'
import { OrderedList } from 'components/list'
import Person from './person'

const People = ({ picked, pick, salt }) => {
    const {
        people,
        intersecter,
        loading,
        fetching,
        paging = {},
        hasFetched
    } = usePeople()

    const { hasNextPage } = paging

    return (
        <>
            {(!!fetching && !hasFetched) && <Loader />}
            <OrderedList>
                {people.map((person, index) => {
                    const last = index + 1 === people.length

                    return (
                        <Person
                            {...{
                                person,
                                picked,
                                pick,
                                ...((!!intersecter && last) ? { ref: intersecter } : null)
                            }}
                            salt={`${salt}:people`}
                            key={`${salt}:people:${person.id}:${picked?.id || 'empty'}`} />
                    )
                })}
                {(!!intersecter && !!hasNextPage && !!loading) && (
                    <LoadingContainer>
                        <Loader />
                    </LoadingContainer>
                )}
            </OrderedList>
        </>
    )
}

export default People
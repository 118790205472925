import React from 'react'
import styled from 'styled-components'

const Important = styled.div`
    border-radius: 8px;
    padding: 8px;

    &:empty {
        display: none;
    }

    &.neutral {
        background-color: var(--huma-color-surface-minimal);
    }

    &.passive {
        background-color: var(--huma-color-surface-warning-minimal);
    }

    &.active {
        background-color: var(--huma-color-surface-success-minimal);
    }

    &.failed {
        background-color: var(--huma-color-surface-destructive-minimal);
    }

    &.informative {
        background-color: var(--huma-color-surface-constructive-minimal);
    }

    &.creative {
        background-color: var(--huma-color-surface-brand-minimal);
    }
`

export const ImportantInner = styled.div`
    position: relative;
    overflow: hidden;

    border-radius: 4px;
    padding: 16px;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-lower);

    &:empty {
        display: none;
    }
`

export default ({ children, ...props }) => (
    <Important {...props}>
        <ImportantInner>
            {children}
        </ImportantInner>
    </Important>
)
import styled from 'styled-components'
import {
    SkeletonListRow as SkeletonListRowBase,
    SkeletonCell as SkeletonCellBase
} from 'components/skeleton'

export const Wrap = styled.div``

export const SkeletonListRow = styled(SkeletonListRowBase)`
    grid-template-columns: repeat(3, minmax(0, 2fr)) minmax(0, min-content);
    align-items: center;
`

export const SkeletonCell = styled(SkeletonCellBase)`
    padding: 4px 0;
`
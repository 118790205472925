import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccess } from 'contexts/access'
import { useMe } from 'contexts/me'
import { isOngoing } from 'pages/absence/utilities'
import { StatusLabel, ApprovalStatus } from './s'

const Status = ({ status, start, end, user, type, permissionLevel, reviewedBy, showApprovedStatus }) => {
    const { check } = useAccess()
    const { isItMyOwnId } = useMe()
    const absenceAdmin = check('absence:manage')

    const statusAccess = (absenceAdmin || isItMyOwnId(user.id) || (permissionLevel === 'approve'))

    if(!start && !status) {
        return null
    }

    const { requireApproval } = type

    const ongoing = isOngoing({ start, end }, new Date())

    if(!!ongoing && status === 'approved') {
        return (
            <StatusLabel className="positive">
                <FormattedMessage
                    id="absence_status_ongoing"
                    defaultMessage="Ongoing" />
            </StatusLabel>
        )
    }

    if(
        !!status &&
        statusAccess &&
        (!!showApprovedStatus || (!showApprovedStatus && status !== 'approved')) &&
        (!!reviewedBy || (!reviewedBy && status !== 'approved') || !!requireApproval)
    ) {
        return (
            <ApprovalStatus status={status} />
        )
    }

    return null
}

export default Status

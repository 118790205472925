import React from 'react'
import Modal from 'modals/fullscreen'
import Content from './content'

const FilePreview = ({ meta = {}, preview, download, remove, show, dismiss, salt }) => {
    const { id, extension } = meta
    salt = `${salt}:${id}:${extension}`

    return (
        <Modal
            show={show}
            dismiss={dismiss}
            salt={salt}>
            {!!show && (
                <Content
                    meta={meta}
                    preview={preview}
                    download={download}
                    remove={remove}
                    dismiss={dismiss}
                    salt={salt} />
            )}
        </Modal>
    )
}

export default FilePreview
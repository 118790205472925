import React, { useState, useEffect, Component, createRef } from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useForm } from 'components/form/controller'
import { mergeRefs } from 'react-merge-refs'
import { cls } from 'utilities/dom'
import { omit } from 'utilities/object'
import { Field, Label, Control, Placeholder, ValueDisplay } from 'components/form/field/s'
import Toggler from 'components/form/input/toggler'
import EntityPicker from 'modals/entity-picker'

const empty = null

class EditLanguage extends Component {
    constructor(props) {
        super(props)

        this.state = this.valueStateFromProps(props)
        this.register()

        this.control = createRef()
        this.combinedControlRef = mergeRefs([
            this.control,
            props.forwardedRef
        ])
    }

    componentDidMount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.triggerChange(this.props.name, { touched: false })
        }
    }

    componentDidUpdate = ({ forwardedRef, name, field, whistle }, { value }) => {
        const forwardedRefChanged = forwardedRef !== this.props.forwardedRef
        const nameChanged = name !== this.props.name
        const valueChanged = value !== this.state.value
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include
        const whistleReceived = whistle !== this.props.whistle

        if(forwardedRefChanged) {
            this.combinedControlRef = mergeRefs([
                this.control,
                this.props.forwardedRef
            ])
        }

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.props.form.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && valueChanged) {
            this.props.form.triggerChange(this.props.name)
        }

        if(whistleReceived) {
            this.setState(this.valueStateFromProps())
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.props.form.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        let {
            required = false,
            include = 'touched'
        } = this.props.field ?? {}

        if(include === 'never') {
            return
        }

        this.props.form.registerField(this.props.name, {
            empty,
            required,
            include,

            unset: () => this.setState({
                value: empty,
                picking: false
            }),

            validator: value => required ?
                !!value :
                true
        }, update)
    }

    valueStateFromProps = (props = this.props) => ({
        value: props.field?.value || empty
    })

    openPicker = () => this.setState({ picking: true })
    closePicker = () => this.setState({ picking: false })
    unset = () => this.onPicked(empty)

    onPicked = value => {
        value = value?.id ?? empty

        this.setState({ value })
        this.props.onChange?.({ [this.props.name]: value })
    }

    render() {
        let {
            value = empty,
            picking
        } = this.state

        const {
            className,
            controlProps = {},
            picker = {},
            label,
            salt,
            name,
            enabled = true,
            loading = false,
            field = {},
            formatMessage,
            entities,
            ...props
        } = this.props

        const { outer = true } = picker

        const {
            required,
            softRequired,
            optional,
            unsettable = true
        } = field

        const touched = this.props.form.touched.includes(name)
        const error = (name in this.props.form.errors) && touched

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const togglerClassName = cls([
            controlProps.className
        ])

        const placeholder = formatMessage({
            id: 'language_action_pick',
            defaultMessage: 'Pick language'
        })

        picker.search = picker.search ?? {
            controlProps: {
                placeholder: formatMessage({
                    id: 'placeholder_find_language',
                    defaultMessage: 'Find language'
                })
            }
        }

        const id = `${salt}:${name}`
        const language = value ? entities.find(({ id }) => id === value) : null

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={salt}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <input
                        type="hidden"
                        name={name}
                        value={value || ''} />
                    <Toggler
                        {...controlProps}
                        {...(togglerClassName ? { className: togglerClassName } : null)}
                        {...omit(props, 'field', 'languages', 'form', 'whistle')}
                        id={id}
                        onClick={this.openPicker}
                        active={picking}
                        unset={(!!language && unsettable) && this.unset}
                        unsettable={unsettable}
                        type="language"
                        disabled={!enabled}
                        ref={this.combinedControlRef}>
                        {!!language && (
                            <ValueDisplay {...(!enabled ? { className: 'soft' } : null)}>
                                {language.name}
                            </ValueDisplay>
                        )}
                        {(!language && placeholder) && (
                            <Placeholder>
                                {placeholder}
                            </Placeholder>
                        )}
                    </Toggler>
                    <EntityPicker
                        {...picker}
                        type="language"
                        entities={entities}
                        creatable={false}
                        heading={placeholder}
                        outer={outer}
                        show={picking}
                        picked={language}
                        dismiss={this.closePicker}
                        cancelAction={() => ({
                            label: formatMessage({
                                id: 'action_cancel',
                                defaultMessage: 'Cancel'
                            }),
                            effect: 'neutral',
                            onClick: this.closePicker
                        })}
                        doneAction={({ picked }) => ({
                            label: formatMessage({
                                id: 'action_done',
                                defaultMessage: 'Done'
                            }),
                            effect: 'constructive',
                            onClick: () => this.onPicked(picked)
                        })}
                        salt={salt} />
                </Control>
            </Field>
        )
    }
}

export default props => {
    const { formatMessage } = useIntl()

    const {
        locale,
        languages
    } = useI18n()

    const form = useForm()

    const [entities, setEntities] = useState([])

    useEffect(() => {
        setEntities(Object.entries(languages).map(([id, name]) => ({
            id,
            name
        })))
    }, [locale, languages])

    return (
        <EditLanguage
            {...props}
            formatMessage={formatMessage}
            languages={languages}
            entities={entities}
            form={form} />
    )
}
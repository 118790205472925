import React, { Children, cloneElement } from 'react'
import {
    Font,
    Text, Link, View, Svg, Circle,
    StyleSheet
} from '@react-pdf/renderer'
import { white, monochrome, blue, yellow, purple } from 'constants/colors'
import ApercuRegularPro from 'static/fonts/apercu-pro/regular.woff'
import ApercuItalicPro from 'static/fonts/apercu-pro/italic.woff'
import ApercuMediumPro from 'static/fonts/apercu-pro/medium.woff'
import ApercuMediumItalicPro from 'static/fonts/apercu-pro/medium-italic.woff'
import ApercuBoldPro from 'static/fonts/apercu-pro/bold.woff'
import ApercuBoldItalicPro from 'static/fonts/apercu-pro/bold-italic.woff'
import RecoletaBoldWoff from 'static/fonts/recoleta/bold.woff'
import { emojiIndex } from 'emoji-mart'

Font.register({
    family: 'Apercu',
    fonts: [
        {
            src: ApercuRegularPro,
            fontStyle: 'normal',
            fontWeight: 400
        },
        {
            src: ApercuItalicPro,
            fontStyle: 'italic',
            fontWeight: 400
        },
        {
            src: ApercuMediumPro,
            fontWeight: 500
        },
        {
            src: ApercuMediumItalicPro,
            fontStyle: 'italic',
            fontWeight: 500
        },
        {
            src: ApercuBoldPro,
            fontWeight: 700
        },
        {
            src: ApercuBoldItalicPro,
            fontStyle: 'italic',
            fontWeight: 700
        }
    ]
})

Font.register({
    family: 'Recoleta',
    fonts: [
        {
            src: RecoletaBoldWoff,
            fontStyle: 'normal',
            fontWeight: 700
        }
    ]
})

Font.registerEmojiSource({
    format: 'png',
    url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/'
})

const paragraphMargin = 15
const pagePadding = {
    vertical: 40,
    horizontal: 30
}
const sectionPadding = 10
const listWidth = 595 - (pagePadding.horizontal * 2) - (sectionPadding * 2) - 80

export const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: `${pagePadding.vertical}pt ${pagePadding.horizontal}pt`,
        backgroundColor: white.c
    },
    frontPage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        padding: `${pagePadding.vertical}pt ${pagePadding.horizontal}pt`,
        backgroundColor: white.c
    },
    pageFooter: {
        position: 'absolute',
        bottom: `${pagePadding.vertical}pt`,
        left: `${pagePadding.horizontal + 20}pt`,
        maxWidth: 595 - (pagePadding.horizontal * 2) - 40,
        fontFamily: 'Apercu'
    },
    pageFooterText: {
        marginBottom: 0,
        lineHeight: 1.5,
        fontSize: 10
    },
    pageNumber: {
        position: 'absolute',
        right: 0,
        bottom: '20pt',
        left: 0,
        fontFamily: 'Apercu',
        fontSize: 12,
        textAlign: 'center',
        color: monochrome.twentyfive.c
    },
    section: {
        margin: 10,
        padding: sectionPadding,
        fontFamily: 'Apercu',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 1.5,
        color: monochrome.base.c
    },
    title: {
        marginBottom: 30,
        fontFamily: 'Apercu',
        fontSize: 20
    },
    h1: {
        marginBottom: 10,
        fontFamily: 'Recoleta',
        fontSize: 24,
        fontWeight: 700
    },
    h2: {
        marginBottom: 7.5,
        fontSize: 18,
        fontWeight: 700
    },
    h3: {
        marginBottom: 5,
        fontSize: 15,
        fontWeight: 500
    },
    h4: {
        marginBottom: 0,
        fontSize: 14,
        fontWeight: 500
    },
    paragraph: {
        marginBottom: paragraphMargin
    },
    strong: {
        fontWeight: 500
    },
    italic: {
        fontStyle: 'italic'
    },
    underline: {
        textDecoration: 'underline'
    },
    strikethrough: {
        textDecoration: 'line-through'
    },
    superscript: {
        fontSize: 8,
        verticalAlign: 'super'
    },
    subscript: {
        fontSize: 8,
        verticalAlign: 'sub'
    },
    annotation: {
        backgroundColor: yellow.light.c
    },
    variable: {
        // borderRadius: 4,
        // border: `1pt solid ${monochrome.fifteen.c}`,
        // padding: '2pt 4pt 0',
        backgroundColor: monochrome.shy.c,
        fontFamily: 'Courier',
        fontSize: 10
    },
    variableLabeled: {
        backgroundColor: monochrome.light.c
    },
    link: {
        color: blue.base.c
    },
    blockquote: {
        marginBottom: paragraphMargin,
        borderLeft: `3pt solid ${monochrome.ten.c}`,
        paddingTop: 5,
        paddingLeft: 10
    },
    code: {
        marginBottom: paragraphMargin,
        borderRadius: 4,
        border: `1pt solid ${monochrome.fifteen.c}`,
        padding: '2pt 4pt 0',
        backgroundColor: monochrome.shy.c,
        fontFamily: 'Courier',
        fontSize: 10,
        color: purple.base.c
    },
    unorderedList: {
        flexDirection: 'column',
        marginBottom: paragraphMargin,
        maxWidth: listWidth
    },
    orderedList: {
        marginBottom: paragraphMargin,
        maxWidth: listWidth
    },
    listItem: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingVertical: 5
    },
    listItemBullet: {
        minWidth: 20
    },
    logoWrapper: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: 24,
        marginLeft: 20,
        width: 135,
        height: 30
    },
    logo: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        objectPosition: '0% 0%'
    }
})

export const Title = ({ children }) => (
    <Text style={styles.h1}>{children}</Text>
)

export const Heading2 = ({ children }) => (
    <Text style={styles.h2}>{children}</Text>
)

export const Heading3 = ({ children }) => (
    <Text style={styles.h3}>{children}</Text>
)

export const Heading4 = ({ children }) => (
    <Text style={styles.h4}>{children}</Text>
)

export const Paragraph = ({ children, compact = false, indent = 0 }) => (
    <Text style={[
        styles.paragraph,
        compact ? { marginBottom: 0 } : null,
        indent ? { marginLeft: indent * 15 } : null
    ]}>
        {children}
    </Text>
)

export const Strong = ({ children }) => (
    <Text style={styles.strong}>{children}</Text>
)

export const Italic = ({ children }) => (
    <Text style={styles.italic}>{children}</Text>
)

export const Underline = ({ children }) => (
    <Text style={styles.underline}>{children}</Text>
)

export const Strikethrough = ({ children }) => (
    <Text style={styles.strikethrough}>{children}</Text>
)

export const Superscript = ({ children }) => (
    <Text style={styles.superscript}>{children}</Text>
)

export const Subscript = ({ children }) => (
    <Text style={styles.subscript}>{children}</Text>
)

export const Annotation = ({ children }) => (
    <Text style={styles.annotation}>{children}</Text>
)

export const Mention = ({ children }) => (
    <Text style={styles.strong}>{children}</Text>
)

export const Variable = ({ children }) => (
    <Text style={styles.variable}>{children}</Text>
)

export const VariableLabeled = ({ children }) => (
    <Text style={styles.variableLabeled}>{children}</Text>
)

export const Anchor = ({ children, ...props }) => (
    <Link
        {...props}
        style={styles.link}>
        {children}
    </Link>
)

export const Blockquote = ({ children }) => (
    <View style={styles.blockquote}>
        {children}
    </View>
)

export const Code = ({ style = {}, children }) => (
    <Text style={{
        ...styles.code,
        ...style
    }}>
        {children}
    </Text>
)

export const UnorderedList = ({ children, compact = false }) => (
    <View style={[
        styles.unorderedList,
        compact ? { marginBottom: 0 } : null
    ]}>
        {children}
    </View>
)

export const OrderedList = ({ children, compact = false }) => (
    <View style={[
        styles.orderedList,
        compact ? { marginBottom: 0 } : null
    ]}>
        {Children.map(children, (child, index) => cloneElement(child, { index: index + 1 }))}
    </View>
)

export const ListItem = ({ children, index }) => {
    const isNumbered = typeof index === 'number'
    const bullet = isNumbered ? `${index}.` : '•'

    return (
        <View style={styles.listItem}>
            <Text style={styles.listItemBullet}>{bullet}</Text>
            <View style={{ flexDirection: 'column' }}>
                {children}
            </View>
        </View>
    )
}

export const Hr = () => {
    const circleSize = 1.5
    const offset = 4
    const totalWidth = ((circleSize * 2) * 3) + (offset * 2) // Total width of 3 circles and 2 offsets
    const startX = (595 - (totalWidth * 2) - (circleSize + offset) - (pagePadding.horizontal * 2) - (sectionPadding * 2)) / 2 // 595 pt equals roughly 210 mm

    return (
        <Svg width="100%" height={36}>
            <Circle cx={startX + circleSize} cy="50%" r={circleSize} fill={monochrome.base.c} />
            <Circle cx={startX + circleSize * 3 + offset} cy="50%" r={circleSize} fill={monochrome.base.c} />
            <Circle cx={startX + circleSize * 5 + offset * 2} cy="50%" r={circleSize} fill={monochrome.base.c} />
        </Svg>
    )
}

export const Br = () => '\n'

const getEmojiUnicode = id => {
    const [match] = emojiIndex.search(id)

    if(!match?.native) {
        return id
    }

    return match.native
}

export const EmojiText = ({ emoji: id }) => {
    const emoji = getEmojiUnicode(id)
    if(!emoji) {
        return null
    }

    return <Text>{emoji}</Text>
}
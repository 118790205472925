import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { ListItem } from 'components/list'
import { flexColumnsCss, flexChildCss, FlexChildShrink } from 'components/flex'
import SymbolBase from 'components/symbol'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'

export const EntityItem = styled(ListItem)`
    &:not(:last-child) {
        margin: 0 32px 16px;

        &.large {
            margin-bottom: 32px;
        }
    }

    &:last-child {
        margin: 0 32px 32px;
    }

    svg {
        ${svgStroke()}
    }
`

export const EntityColumns = styled.label`
    ${flexColumnsCss}
    align-items: center;
    height: 40px;
    cursor: pointer;
    column-gap: 24px;
`

export const IconColumn = styled(FlexChildShrink)`
    font-size: 20px;
`

export const Symbol = styled(SymbolBase)`
    ${p => !!p.size && css`
        transform: scale(${p.size / 40});
        line-height: ${p.size}px;
    `}
`

export const NameColumn = styled.div`
    ${flexChildCss}
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
`

export const Name = styled(Paragraph).attrs({ as: 'h3' })`
    line-height: 24px;
`

export const SubName = styled(CaptionSmall).attrs({ as: 'h4' })``

import styled from 'styled-components'

export const ProgressBar = styled.div`
    position: absolute;

    border-radius: ${({ theme: { steps, at } }) => steps === at ? '24px' : '24px 0 0 24px'};
    width: ${({ theme: { steps, at } }) => 100 / steps * at}%;
    height: 100%;
    background-color: var(--huma-color-surface-brand-subtle);

    transform-origin: top left;
    transition: width .25s cubic-bezier(.4, 0, .6, 1) 0s;
`

export const Buffer = styled.div`
    position: relative;
    overflow: hidden;

    border-radius: 24px;
    width: 100%;
    height: 8px;

    background-color: rgb(from var(--huma-color-surface-inverted) r g b / .1);
`

export const Step = styled.div`
    pointer-events: none;
    position: absolute;
    top: 0;
    left: ${p => (100 / p.theme.steps * p.$index)}%;

    width: 2px;
    height: 8px;
    background-color: var(--huma-color-surface-default);
`
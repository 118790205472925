import styled, { css } from 'styled-components'
import { H4 } from 'components/typography/heading'
import Paragraph from 'components/typography/paragraph'
import { Columns } from 'components/flex'

export const Heading = styled(H4)`
    margin-block-end: 16px;
`

export const WidgetParagraph = styled(Paragraph)`
    color: var(--huma-color-foreground-subtle);
`

export const Actions = styled.div`
    display: flex;
    justify-content: center;
`

export const DragDropWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const IndexColumn = styled.div`
    display: flex;
    align-items: center;

    width: 32px;
    height: 40px;
    padding-inline-start: 8px;
`

export const Sortables = styled.div`
    flex-grow: 1;

    margin-block-end: 16px;
`

export const SortableItem = styled(Columns)`
    align-items: center;

    margin-block-end: 16px;
    border-radius: 8px;
    height: 40px;

    background-color: var(--huma-color-surface-default);

    ${p => !!p.$isDragging && css`
        box-shadow: var(--huma-shadow-lowest);
    `}
`

export const DragColumn = styled.div`
    color: var(--huma-color-foreground-minimal);
`
import React, { Component } from 'react'
import { FormContext } from 'components/form/controller'
import { map } from 'utilities/object'

export default class HiddenField extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        this.state = {
            mode: context.mode
        }

        this.register(true)
    }

    componentDidUpdate(props, { mode }) {
        if(this.context.mode !== mode) {
            this.setState({ mode: this.context.mode }, () => this.register(true))
        }
    }

    componentWillUnmount() {
        this.context.unregisterField(this.props.name)
    }

    register = (update = false) => this.context.registerField(this.props.name, {
        empty: this.state.mode === 'json' ? null : '',
        required: true,
        include: 'always',
        unset: () => {},
        validator: () => true
    }, update)

    render() {
        const {
            salt,
            name,
            field = {}
        } = this.props

        const { value } = field

        if(typeof value === 'object') {
            return map(value, (part, key) => (
                <input
                    type="hidden"
                    name={`${name}[${key}]`}
                    defaultValue={part || ''}
                    key={`${salt}:${name}:${key}:hidden`} />
            ))
        }

        return (
            <input
                type="hidden"
                name={name}
                defaultValue={value}
                key={`${salt}:${name}:input`} />
        )
    }
}

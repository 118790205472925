import React, { useRef, useEffect } from 'react'
import { random } from './'
import { Balloon, BalloonString } from './s'

const CelebrationItem = ({ color, onReady, index, ...props }) => {
    const item = useRef()

    useEffect(() => {
        if(!!item?.current) {
            setTimeout(
                () => onReady(item?.current),
                (index * 2000) + random({ min: 500, max: 1000 })
            )
        }
    }, [item?.current])

    return (
        <Balloon
            {...props}
            className={color}
            ref={item}>
            <BalloonString />
        </Balloon>
    )
}

export default CelebrationItem
import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

const Label = styled.span`
    display: inline-flex;

    border: 1px solid var(--huma-color-border-subtle);
    border-radius: 4px;
    height: 20px;
    padding-inline: 4px;

    font-size: 14px;
    line-height: 20px;
`

const PercentageLabel = forwardRef(({ decimal = 0, ...props }, ref) => {
    const percentage = decimal * 100

    return (
        <Label
            {...props}
            ref={ref}>
            <FormattedMessage
                id="value_percent"
                defaultMessage="{percent} %"
                values={{ percent: percentage.toFixed(0) }} />
        </Label>
    )
})

export default PercentageLabel

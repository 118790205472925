import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { motion } from 'framer-motion'
import { Rows } from 'components/flex'
import { OrderedList, ListItem } from 'components/list'
import { Simple } from 'components/button'
import Caption from 'components/typography/caption'
import { Organization as GroupBase, IconColumn as GroupIconColumn } from 'components/group/s'
import HighlighterBase from 'react-highlight-words'

export const Results = styled(Rows)`
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    overflow: hidden;
    z-index: 10;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    width: 100%;
    background-color: var(--huma-color-surface-default);
    box-shadow: var(--huma-shadow-high);
`

export const List = styled(OrderedList)`
    overflow-y: auto;
    max-height: ${p => (p.$space && p.$space < (5.5 * 64)) ? (p.$space - 64) : (5.5 * 64)}px;

    ${belowTablet`
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    `}
`
export const Item = ListItem

export const Button = styled(Simple)`
    position: relative;

    border-radius: 0;
    width: 100%;
    min-height: 40px;
    height: auto;
    padding: 0 40px 0 12px;

    white-space: nowrap;
    text-align: left;

    ${Item}:first-of-type & {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    ${Item}:last-of-type & {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    &.active {
        background-color: var(--huma-color-surface-constructive-transparent-hover);
    }

    & > * {
        pointer-events: none;
    }
`

export const NewTag = styled(Caption).attrs(() => ({ as: 'span' }))`
    margin-inline-start: 4px;

    &::before {
        content: "(";
    }

    &::after {
        content: ")";
    }
`

export const KeyHintInner = styled(motion.div).attrs(() => ({
    variants: {
        inactive: {
            opacity: 0,
            transition: {
                duration: .1
            }
        },
        active: {
            opacity: 1,
            transition: {
                duration: .2
            }
        }
    },
    initial: 'inactive',
    exit: 'inactive'
}))`
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 100%;

    color: var(--huma-color-foreground-default);

    svg {
        stroke-width: 2;
    }
`

export const Group = styled(GroupBase)`
    max-width: 100%;

    & ${GroupIconColumn} {
        margin-inline-end: 4px;
    }
`

export const Highlighter = styled(HighlighterBase)`
    span {
        color: var(--huma-color-foreground-subtle);
    }

    mark {
        background: none;

        color: var(--huma-color-foreground-default);
    }
`
import React from 'react'
import { useIntl } from 'react-intl'
import Message from 'components/message'

// status === initial GET request from web server
// error === subsequent POST / PATCH request from web client

const ResponseResultMessage = ({ success, error, status }) => {
    const { formatMessage } = useIntl()

    if(success) {
        return (
            <Message
                type="success"
                message={formatMessage(messages.success)} />
        )
    }

    if(status === 'RESPONDED' || error?.code === 'object:duplicate') {
        return (
            <Message
                type="info"
                message={formatMessage(messages.responded)} />
        )
    }

    if(status === 'EXPIRED' || error?.code === 'token:expired') {
        return (
            <Message
                type="warning"
                message={formatMessage(messages.expired)} />
        )
    }

    if(error?.code === 'token:invalid') {
        return (
            <Message
                type="error"
                message={formatMessage(messages.invalid)} />
        )
    }

    if(error?.code === 'object:not-found') {
        if(error?.type === 'user') {
            return (
                <Message
                    type="error"
                    message={formatMessage(messages.notFoundUser)} />
            )
        }

        return (
            <Message
                type="error"
                message={formatMessage(messages.notFoundSurvey)} />
        )
    }

    return null
}

const messages = {
    success: {
        id: 'employee_satisfaction_survey_response_success',
        defaultMessage: 'Thank you for your response!'
    },
    responded: {
        id: 'employee_satisfaction_survey_response_error_object_duplicate',
        defaultMessage: 'Nice to see your engagement, but your response has already been submitted.'
    },
    expired: {
        id: 'employee_satisfaction_survey_response_error_survey_expired',
        defaultMessage: 'The response period for this survey has ended.'
    },
    invalid: {
        id: 'employee_satisfaction_survey_response_error_token_invalid',
        defaultMessage: 'The link you followed doesn’t look right. Check it and try again.'
    },
    notFoundUser: {
        id: 'error_user_deactivated_deleted',
        defaultMessage: 'Deactivated or deleted user'
    },
    notFoundSurvey: {
        id: 'employee_satisfaction_survey_response_error_object_not-found',
        defaultMessage: 'Invalid survey ID'
    }
}

export default ResponseResultMessage
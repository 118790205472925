import styled, { css } from 'styled-components'
import { interpointDivider } from 'utilities/styled'

export const captionCss = css`
    @layer caption {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: var(--huma-color-foreground-subtle);

        &.preserve-line-breaks {
            white-space: pre-line;
        }

        &.interpoint-divider {
            ${interpointDivider()}
        }

        &.constructive,
        &.action {
            color: var(--huma-color-foreground-constructive-bold);
        }

        &.destructive,
        &.error {
            color: var(--huma-color-foreground-destructive);
        }
    }
`

export const captionSmallCss = css`
    @layer caption-small {
        ${captionCss}

        font-size: 14px;
        line-height: 20px;
    }
`

const Caption = styled.p`
    ${captionCss}

    margin-block-end: 24px;

    &.compact {
        margin-block-end: 0;
    }
`

export const CaptionSmall = styled.p`
    ${captionSmallCss}

    margin-block-end: 16px;

    &.compact {
        margin-block-end: 0;
    }
`

export default Caption
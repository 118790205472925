import styled, { css } from 'styled-components'
import { fromPhone, belowPhone } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Plain } from 'components/button'
import AbsenceDateBase from 'pages/absence/components/date'
import SymbolBase, { DisplayEmoji } from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import StatusLabelBase from 'components/status-label'
import ApprovalStatusBase from 'pages/absence/components/approval-status'
import { InlineMessage as InlineMessageBase } from 'components/message'
import { ancestorHover as avatarAncestorHover } from 'components/avatar/s'

export const EntryItem = styled(Grid)`
    ${({ theme: { absence: { person } } }) => css`
        grid-template-columns: 1fr minmax(100px, max-content) 40px;

        ${belowPhone`
            ${person ? css`
                grid-template-columns: auto 40px;
                row-gap: 8px;
            ` : css`
                grid-template-columns: minmax(0, 2fr) 40px;
            `}
        `}
    `}
    column-gap: 16px;

    padding: 16px 0;
    box-shadow: 0 -1px var(--huma-color-border-default);

    &.compact {
        grid-auto-flow: row;
        grid-auto-rows: auto;
        grid-template-columns: none;

        &:last-of-type {
            padding-bottom: 0;
        }
    }

    &:first-of-type {
        padding-top: 0;
        box-shadow: none;
    }
`

const personEntryCellCss = css`
    display: grid;
    grid-template-columns: minmax(0, 1.5fr) 1fr;
    column-gap: 16px;
    align-items: start;

    height: auto;

    text-align: start;

    ${belowPhone`
        row-gap: 8px;
    `}
`

export const PersonEntryClickable = styled(Plain)`
    ${personEntryCellCss}
    ${avatarAncestorHover()}

    &:not([disabled]) {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
`

export const PersonEntryFragment = styled.div`
    ${personEntryCellCss}
`

const entryCellCss = css`
    display: grid;
    column-gap: 16px;
    align-items: start;

    height: auto;

    text-align: start;

    ${({ theme: { absence: { person } } }) => {
        if(person) {
            return belowPhone`
                grid-row: 2 / 3;
                grid-column: 1 / 4;

                padding-inline-start: 56px;
            `
        }

        return css`
            grid-template-columns: 40px auto;

            ${belowPhone`
                grid-column: 1 / 2
            `}
        `
    }}
`

export const EntryCellClickable = styled(Plain)`
    ${entryCellCss}

    border: 0;

    &:not([disabled]) {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
`

export const EntryCellFragment = styled.div`
    ${entryCellCss}
`

export const PersonCell = styled.div`
    ${belowPhone`
        grid-row: 1 / 2;
        grid-column: 1 / 3;
    `}
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(EntryCellClickable)}
    align-self: center;
`

export const MetaCell = styled.div`
    line-height: 24px;

    & > p {
        white-space: break-spaces;
    }
`

export const AbsenceDate = styled(AbsenceDateBase)`
    ${PersonEntryClickable}:hover &,
    ${PersonEntryClickable}:focus &,
    ${EntryCellClickable}:hover &,
    ${EntryCellClickable}:focus & {
        text-decoration: underline;
    }

    ${MetaCell}.strike & {
        text-decoration: line-through;
    }
`

export const Emoji = styled(DisplayEmoji)`
    &:not(:only-child) {
        margin-inline-end: 4px;
    }
`

export const MetaStatus = styled.div`
    display: flex;
    column-gap: 8px;

    ${fromPhone`
        &:not(:empty) {
            margin-top: 4px;
            margin-inline-start: -2px;
        }
    `}

    ${belowPhone`
        &:not(:empty) {
            margin-top: 8px;
        }
    `}
`

export const InlineMessage = styled(InlineMessageBase)`
    margin-bottom: 0;
`

export const CommentCell = styled.div`
    margin-top: 8px;
    margin-inline-start: 56px;

    ${fromPhone`
        grid-row: 2;
        grid-column: 1 / 2;
    `}

    ${belowPhone`
        grid-column: 1 / 3;
    `}
`

export const NoteCell = styled.div`
    margin-top: 8px;
    margin-inline-start: 56px;

    ${fromPhone`
        grid-row: 2;
        grid-column: 1 / 2;
    `}

    ${belowPhone`
        grid-row: 2 / 3;
        grid-column: 1 / 3;
    `}
`


export const StatusCell = styled.div`
    ${EntryItem}.compact & {
        margin-inline-start: 56px;
    }

    ${belowPhone`
        grid-row: 2 / 3;
        grid-column: 1 / 2;

        ${NoteCell} + & {
            grid-row: 3 / 4;
        }

        &:not(:empty) {
            margin-top: 8px;
            margin-inline-start: 56px;
        }
    `}
`

export const StatusLabel = styled(StatusLabelBase)`
    ${fromPhone`
        margin-top: 8px;

        ${MetaStatus} & {
            display: none;
        }
    `}
`

export const ApprovalStatus = styled(ApprovalStatusBase)`
    ${fromPhone`
        margin-top: 8px;

        ${MetaStatus} & {
            display: none;
        }
    `}
`

export const ActionsCell = styled.div`
    ${belowPhone`
        grid-row: 1 / 3;
        grid-column: 2 / 3;
    `}
`
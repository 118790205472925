import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { FormContext } from 'components/form/controller'
import { first } from 'utilities/array'
import { cls } from 'utilities/dom'
import { Label, Control } from 'components/form/field/s'
import {
    Switch, Field,
    PredefinedItems, Options, Option, RadioInput, RadioLabel,
    CustomItem, ResetButton
} from './s'
import Text from 'components/form/input/text'

export default class EditRadio extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const {
            name,
            options,
            field = {}
        } = props

        let {
            value,
            required = false,
            include = 'touched',
            allowCustomValue = false,
            type
        } = field

        this.empty = allowCustomValue ? '' : null

        if(!value && value !== 0 && !(type === 'boolean' && (value === true || value === false))) {
            value = required ?
                first(options)?.value :
                this.empty
        }

        this.state = { value }

        if(include !== 'never') {
            context.registerField(name, {
                empty: this.empty,
                required,
                include,
                ...(type === 'boolean' ? { type: 'trilean' } : null),
                unset: this.unset,

                validator: value => {
                    if(required) {
                        if(type === 'boolean') {
                            return value === true || value === false
                        }

                        return !!value || value === 0
                    }

                    return true
                }
            })
        }
    }

    componentWillUnmount() {
        const { include = 'touched' } = this.props.field
        if(include !== 'never') {
            this.context.unregisterField(this.props.name)
        }
    }

    unset = () => {
        const {
            name,
            onChange
        } = this.props

        this.setState({ value: this.empty })

        this.context.triggerChange(name)
        onChange?.({ [name]: this.empty })
    }

    onChange = ({ target: { value } }) => {
        const {
            name,
            onChange,
            field = {}
        } = this.props

        if(field?.type === 'boolean' && ['true', 'false'].includes(value)) {
            value = JSON.parse(value)
        }

        this.setState({ value })

        this.context.triggerChange(name)
        onChange?.({ [name]: value })
    }

    tokenize = value => `${value}`
        .replace(/\s+/g, '-')
        .toLowerCase()

    render() {
        const { value } = this.state

        const {
            className,
            salt,
            label,
            name,
            options,
            enabled = true,
            loading = false,
            customPlaceholder,
            field = {}
        } = this.props

        const {
            allowCustomValue = false,
            required,
            softRequired,
            optional,
            unsettable = true,
            type
        } = field

        const boolean = type === 'boolean'
        const touched = this.context.touched.includes(name)
        const error = (name in this.context.errors) && touched

        const fieldClassName = cls([
            className,
            boolean && 'boolean',
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const customValue = options
            .map(({ value }) => value)
            .includes(value) ? '' : value

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={`${salt}:option:${this.tokenize(options[0].label)}`}
                        className="accent"
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <Switch>
                        <PredefinedItems>
                            <Options>
                                {options.map(({ value: optionValue, label }) => {
                                    const id = `${salt}:option:${this.tokenize(label)}`
                                    const checked = `${optionValue}` === `${value}`

                                    return (
                                        <Option
                                            {...(checked ? { className: 'checked' } : null)}
                                            key={id}>
                                            <RadioInput
                                                {...(!customValue ? { name } : null)}
                                                value={optionValue}
                                                checked={checked}
                                                onChange={this.onChange}
                                                id={id}
                                                disabled={!enabled}
                                                tabIndex="0"
                                                key={`${id}:${value}`} />
                                            <RadioLabel htmlFor={id}>{label}</RadioLabel>
                                        </Option>
                                    )
                                })}
                            </Options>
                        </PredefinedItems>
                        {!!allowCustomValue && (
                            <CustomItem>
                                <Text
                                    type="text"
                                    {...(customValue ? { name } : null)}
                                    value={customValue}
                                    placeholder={customPlaceholder}
                                    onChange={this.onChange}
                                    disabled={!enabled} />
                            </CustomItem>
                        )}
                        {(!!unsettable && (!!value || value === 0 || (boolean && (value === true || value === false)))) && (
                            <ResetButton
                                className="destructive"
                                onClick={this.unset}
                                disabled={!enabled}>
                                <FormattedMessage
                                    id="action_reset"
                                    defaultMessage="Reset" />
                            </ResetButton>
                        )}
                    </Switch>
                </Control>
            </Field>
        )
    }
}

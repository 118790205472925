import React, { useState, Children, cloneElement, isValidElement } from 'react'
import { modalify, Wrapper, Modal, Veil } from 'utilities/modal'
import { Container } from './s'

const Fullscreen = ({ salt, className, children, ...props }) => {
    const {
        show = false,
        outer = true,
        dismiss,
        ...modal
    } = props

    const [showing, setShowing] = useState(show)

    const animate = show ? 'in' : 'out'

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    {!!outer && <Veil animate={animate} />}
                    <Modal
                        {...modal}
                        dismiss={dismiss}
                        key={`modal:fullscreen:${salt}`}>
                        <Container
                            {...(className ? { className } : null)}
                            animate={animate}
                            afterOpen={() => setShowing(true)}
                            afterClose={() => setShowing(false)}>
                            {Children.map(children, child => {
                                if(isValidElement(child)) {
                                    return cloneElement(child, {
                                        modal: {
                                            showing,
                                            dismiss
                                        }
                                    })
                                }

                                return child
                            })}
                        </Container>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

export default Fullscreen

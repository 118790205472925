import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'

export const Container = styled.div`
    --huma-component-buffer-color: var(--huma-color-surface-subtle);
    --huma-component-progress-color: var(--huma-color-surface-brand-bold);
    --huma-component-value-color: var(--huma-color-foreground-default);

    position: relative;

    width: ${p => p.$width}px;

    &.success {
        --huma-component-buffer-color: var(--huma-color-surface-success-minimal);
        --huma-component-progress-color: var(--huma-color-border-success);
        --huma-component-value-color: var(--huma-color-foreground-success);
    }

    &.info {
        --huma-component-buffer-color: var(--huma-color-surface-info-minimal);
        --huma-component-progress-color: var(--huma-color-border-constructive-bold);
        --huma-component-value-color: var(--huma-color-foreground-info-bold);
    }

    &.warning {
        --huma-component-buffer-color: var(--huma-color-surface-warning-minimal);
        --huma-component-progress-color: var(--huma-color-border-warning);
        --huma-component-value-color: var(--huma-color-foreground-warning);
    }

    &.error {
        --huma-component-buffer-color: var(--huma-color-surface-destructive-minimal);
        --huma-component-progress-color: var(--huma-color-destructive-error);
        --huma-component-value-color: var(--huma-color-foreground-error);
    }

    &.white {
        --huma-component-buffer-color: rgb(var(--huma-palette-white) / .1);
        --huma-component-progress-color: var(--huma-palette-white);
        --huma-component-value-color: var(--huma-palette-white);
    }
`

export const SvgWrapper = styled.svg`
    transform: rotate(-90deg);
`

export const Buffer = styled.circle`
    fill: transparent;
    stroke: var(--huma-component-buffer-color);
`

export const ProgressBar = styled.circle`
    fill: transparent;
    stroke: var(--huma-component-progress-color);
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    transition-property: stroke-dashoffset, stroke-dasharray;
`

export const Value = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    svg {
        ${svgStroke('small')}

        color: var(--huma-component-value-color);
    }
`
import React from 'react'
import { FlexChildShrink } from 'components/flex'
import { Columns, Symbol, FileIcon, NameColumn, FreeFlow } from './s'
import Ellipsify from 'components/ellipsify'

const FileNameIcon = props => {
    const {
        name,
        extension,
        displayName,
        size = 24,
        ellipsify = true,
        withSymbol = false,
        head = 5,
        tail = 4,
        inline = false,
        ...attributes
    } = props

    if((!name || !extension) && !displayName) {
        return null
    }

    const fileName = displayName ?? `${name}.${extension}`

    const fileIcon = (
        <FileIcon
            extension={extension}
            size={size} />
    )

    return (
        <Columns
            {...attributes}
            {...(inline ? { as: 'span' } : null)}>
            <FlexChildShrink {...(inline ? { as: 'span' } : null)}>
                {withSymbol && (
                    <Symbol
                        icon={fileIcon}
                        className="hover-effect" />
                )}
                {!withSymbol && fileIcon}
            </FlexChildShrink>
            <NameColumn {...(inline ? { as: 'span' } : null)}>
                {!!ellipsify && (
                    <Ellipsify
                        text={fileName}
                        head={head}
                        tail={extension.length + 1 + tail}
                        tooltipProps={{ maxWidth: 'none' }} />
                )}
                {!ellipsify && (
                    <FreeFlow>{fileName}</FreeFlow>
                )}
            </NameColumn>
        </Columns>
    )
}

export default FileNameIcon
import styled from 'styled-components'

export const Value = styled.div`
    display: grid;
    align-items: center;

    margin-block-end: 8px;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 8px;
    padding: 16px;

    & label {
        font-size: 14px;
        line-height: 20px;
    }

    &.empty {
        color: var(--huma-color-foreground-minimal);

        @media (var(--contrast-boost)) {
            color: var(--huma-color-foreground-subtle);
        }
    }
`
import React, { useState, useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAccess } from 'contexts/access'
import { usePayment } from 'contexts/payment'
import { ContactContainer, Info/*, ChannelLink*/ } from './s'
import Divider from 'components/divider'
import Avatars from 'components/avatars'
import { ParagraphSmall } from 'components/typography/paragraph'

const Contact = ({ data, defaultPrompt, children = null, prompt = null, cta = null, salt = '', ...props }) => {
    const { check } = useAccess()

    const [team, setTeam] = useState([])

    useLayoutEffect(() => {
        if(data.people?.length) {
            setTeam(data.people
                .map(value => ({
                    value,
                    sort: Math.random()
                }))
                .sort(({ sort: a }, { sort: b }) => a - b)
                .map(({ value }) => ({
                    ...value,
                    id: value.name
                }))
            )
        }
    }, [data.people?.map(({ name }) => name).join('+')])

    if(!check('organization:manage')) {
        return null
    }

    return (
        <ContactContainer {...props}>
            {children}
            {!!children && <Divider />}
            <Info {...cta ? { className: 'cta' } : null}>
                <ParagraphSmall className="compact">
                    {prompt ?? defaultPrompt}
                </ParagraphSmall>
                {cta}
                {/* {!cta && (
                    <ParagraphSmall className="compact">
                        <ChannelLink
                            className="constructive"
                            href={`tel:${salesData.phone}`}>
                            {salesData.phone}
                        </ChannelLink>
                    </ParagraphSmall>
                )} */}
                <Avatars
                    people={team}
                    sortPeople={false}
                    salt={`contact:${salt}`} />
            </Info>
        </ContactContainer>
    )
}

export const ContactSales = props => {
    const {
        formatMessage,
        locale
    } = useIntl()

    const { sales } = usePayment()

    const data = {
        ...(sales?.nb ?? null),
        ...(sales?.[locale] ?? null)
    }

    const defaultPrompt = formatMessage({
        id: 'payment_subscription_trial_contact_sales',
        defaultMessage: 'Have any questions? Contact our sales team'
    })

    return (
        <Contact
            {...props}
            data={data}
            defaultPrompt={defaultPrompt} />
    )
}

export const ContactSupport = props => {
    const {
        formatMessage,
        locale
    } = useIntl()

    const { support } = usePayment()

    const data = {
        ...(support?.nb ?? null),
        ...(support?.[locale] ?? null)
    }

    const defaultPrompt = formatMessage({
        id: 'payment_subscription_trial_contact_support',
        defaultMessage: 'Have any questions? Contact our support team'
    })

    return (
        <Contact
            {...props}
            data={data}
            defaultPrompt={defaultPrompt} />
    )
}
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import Paragraph from 'components/typography/paragraph'
import StringField from 'components/form/field/string'
import CheckboxField from 'components/form/field/checkbox'

const Acknowledge = ({ type, ...props }) => {
    const AcknowledgeType = {
        text: Text,
        checkbox: Checkbox
    }[type]

    if (!AcknowledgeType) {
        return null
    }

    return <AcknowledgeType {...props} />
}

const Text = ({ text, salt }) => {
    const { formatMessage } = useIntl()

    text ||= formatMessage({
        id: 'delete_prompt_word',
        defaultMessage: 'Delete'
    })

    return (
        <>
            <Paragraph>
                <FormattedMessage
                    id="action_delete_prompt"
                    defaultMessage="Type ”{word}” to verify that you understand the consequences of deleting"
                    values={{ word: text.toLowerCase() }}
                    tagName="strong" />
            </Paragraph>
            <StringField
                label={false}
                name="validate"
                field={{
                    match: { value: text.toLowerCase() },
                    required: true
                }}
                controlProps={{ autoFocus: true }}
                salt={salt} />
        </>
    )
}

const Checkbox = ({ label, salt }) => (
    <CheckboxField
        name="validate"
        label={label}
        field={{ required: true }}
        salt={salt} />
)

export default Acknowledge
import styled from 'styled-components'
import { svgStroke, fromContainer, belowContainer } from 'utilities/styled'
import { H3 } from 'components/typography/heading'
import { paragraphCss } from 'components/typography/paragraph'
import Caption from 'components/typography/caption'
import Link from 'components/link'
import AvatarsBase from 'components/avatars'
import { containerQuery } from '../s'

export const GridCell = styled(Link)`
    position: relative;

    display: grid;

    height: 100%;
    padding: 24px;

    svg {
        ${svgStroke()}
    }

    &:is(:hover, :focus-visible) {
        text-decoration: none;
    }

    ${fromContainer(containerQuery)`
        grid-template-areas:
            "heading heading"
            "count count"
            "address address"
            "avatars arrow";
        grid-template-rows: min-content;
        grid-template-columns: 1fr auto;

        border: 2px solid var(--huma-color-border-default);
        border-radius: 8px;
    `}

    ${belowContainer(containerQuery)`
        grid-template-areas:
            "icon heading avatars"
            "icon count avatars";
        grid-template-columns: auto 1fr auto;
        align-items: center;
        column-gap: 16px;

        padding: 16px 0;

        &:not(:last-child) {
            border-bottom: 1px solid var(--huma-color-border-default);
        }
    `}
`

export const IconWrapper = styled.div`
    grid-area: icon;

    ${fromContainer(containerQuery)`
        display: none;
    `}
`

export const Heading = styled.div`
    display: flex;
    grid-area: heading;
    align-items: flex-start;
`

export const Name = styled(H3)`
    line-height: 28px;

    ${GridCell}:is(:hover, :focus-visible) & {
        text-decoration: underline;
    }

    ${belowContainer(containerQuery)`
        ${paragraphCss}

        margin: 0;
    `}
`

export const MemberCount = styled(Caption)`
    grid-area: count;

    margin-block-end: 16px;

    ${belowContainer(containerQuery)`
        margin: 0;

        font-size: 14px;
    `}
`

export const Address = styled(Caption)`
    grid-area: address;

    margin-block-end: 16px;

    ${belowContainer(containerQuery)`
        display: none;
    `}
`

export const Avatars = styled(AvatarsBase)`
    grid-area: avatars;
    align-self: end;

    padding-block: 4px;
`

export const ArrowWrapper = styled.div`
    display: grid;
    grid-area: arrow;
    place-items: center;
    align-self: end;

    width: 40px;
    aspect-ratio: 1;

    svg {
        transition: transform .1s ease-in-out;
    }

    ${GridCell}:is(:hover, :focus-visible) & svg {
        transform: translateX(4px);
    }

    ${belowContainer(containerQuery)`
        display: none;
    `}
`

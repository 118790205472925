import styled, { css } from 'styled-components'
import {
    flashKeyframes,
    fromTablet, belowTablet
} from 'utilities/styled'
import { Grid } from 'components/grid'

export const SurveyRun = styled(Grid)`
    position: relative;

    width: 100%;
    padding: 24px 0;
    box-shadow: 0 -1px var(--huma-color-border-default);

    ${fromTablet`
        grid-template-columns: repeat(5, 1fr);
        column-gap: 24px;
    `}

    ${belowTablet`
        grid-auto-rows: auto;
        grid-auto-flow: row;
        row-gap: 8px;
    `}

    &.ongoing {
        padding: 16px 0;
    }

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }
`

const cellCss = css`
    display: grid;
    align-items: center;
`

export const DateCell = styled.div`
    ${cellCss}
    max-width: 100%;
`

export const ScoreCell = styled.div`
    ${cellCss}
`

export const SentToCell = styled.div`
    ${cellCss}

    ${belowTablet`
        display: none;
    `}
`

export const ResponsesCell = styled.div`
    ${cellCss}

    ${belowTablet`
        display: none;
    `}
`

export const ResponseRateCell = styled.div`
    ${cellCss}

    ${belowTablet`
        display: none;
    `}
`
import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import { Grid } from 'components/grid'

export const Layout = styled(Grid)`
    grid-template-columns: minmax(150px, max-content) 1fr auto;

    border-top: 1px solid var(--huma-color-border-default);
    border-bottom: 1px solid var(--huma-color-border-default);
    padding-block: 24px;

    ${belowPhone`
        grid-template-columns: none;
        row-gap: 0;

        border: none;
        padding: 0;
    `}
`

export const Content = styled.div`
    position: relative;

    ${belowPhone`
        padding-block: 8px;
    `}
`

export const Action = styled(Grid)`
    justify-content: end;

    ${belowPhone`
        justify-content: start;

        padding-block-end: 16px;
    `}
`
import styled, { css } from 'styled-components'
import { svgStroke, belowTablet, fromTablet, interpointDivider } from 'utilities/styled'
import ImportantBase, { ImportantInner } from 'components/important'
import { Grid } from 'components/grid'
import Paragraph, { ParagraphSmall } from 'components/typography/paragraph'

export const Important = styled(ImportantBase)`
    margin-bottom: 16px;

    &.compact {
        margin-bottom: 0;
    }

    &.spacious {
        margin-bottom: 24px;
    }

    &.airy {
        margin-bottom: 40px;
    }

    & ${ImportantInner} {
        display: grid;
        grid-template-columns: 24px 1fr;
        gap: 16px;
        padding-right: 24px;

        ${fromTablet`
            gap: 24px;
        `}

        > svg {
            ${svgStroke()}
        }
    }
`

const vertical = css`
    grid-template-rows: auto;
    justify-items: start;
    gap: 16px;
`

export const Layout = styled(Grid)`
    &:not(.responsive) {
        ${vertical}
    }

    &.responsive {
        ${belowTablet`
            ${vertical}
        `}

        ${fromTablet`
            grid-template-columns: 1fr min-content;
            gap: 24px;

            a {
                line-height: 40px;
            }
        `}
    }

    > svg {
        ${svgStroke()}
    }
`

export const Heading = styled(Paragraph)`
    font-weight: 500;
    line-height: 20px;
`

export const Caption = styled(ParagraphSmall)`
    ${interpointDivider()}
`
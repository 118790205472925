import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import Link from 'components/link'
import Paragraph from 'components/typography/paragraph'
import SalaryRevisionGroupStatus from 'pages/salary/components/revision-group-status'
import { ArrowRight as ArrowRightBase } from 'styled-icons/feather'
import { InlineMessage } from 'components/message'

export const Wrapper = styled(Link)`
    display: grid;
    grid-template-columns:
        [meta] auto
        [status] min-content
        [action] 24px;
    align-items: center;
    column-gap: 16px;

    padding-block: 16px;

    &:is(:hover, :focus) {
        text-decoration: none;
    }

    &:not(:last-child) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }
`

export const Name = styled(Paragraph)`
    grid-row: 1;
    grid-column: meta;

    line-height: 24px;

    ${Wrapper}:is(:hover, :focus) & {
        text-decoration: underline;
    }
`

export const UserCount = styled(Paragraph)`
    grid-row: 2;
    grid-column: meta;
`

export const Status = styled(SalaryRevisionGroupStatus)`
    grid-row: 1 / span 2;
    grid-column: status;
`

export const ArrowRight = styled(ArrowRightBase)`
    grid-row: 1 / span 2;
    grid-column: action;

    ${svgStroke()}

    transition: transform 0.2s ease-in-out;

    ${Wrapper}:is(:hover, :focus) & {
        transform: translateX(2px);
    }
`

export const Message = styled(InlineMessage)`
    grid-column: 1 / -1;

    margin-block-start: 8px;
`
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAbsenceEntries } from 'contexts/absence-entries'
import { clamp } from 'utilities/math'
import { Wrap, Grid, LoadingContainer, SkeletonListRow, EmptyText } from './s'
import EmptyContainer from 'components/empty'
import Link from 'components/link'
import Loader from 'components/loader'
import Entry from './entry'
import { Ghost } from 'components/button'
import { SkeletonCell, SkeletonAvatarAndFullName, SkeletonStrings } from 'components/skeleton'

const AbsenceEntries = ({ actions = {}, className, ...props }) => {
    const {
        entries,
        intersecter,
        loading,
        paging = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched
    } = useAbsenceEntries()

    const [skeletonLength, setSkeletonLength] = useState(5)

    const { hasNextPage } = paging

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(entries.length, 1, 10))
        }
    }, [hasFetched, entries?.length])

    return (
        <Wrap {...(className ? { className } : null)}>
            <Grid>
                {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow key={`list:absence:entries:skeleton:${index}`} columns={2}>
                        <SkeletonCell>
                            <SkeletonAvatarAndFullName
                                size={40}
                                showSecondLine={index % 2 === 0} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings
                                size={20}
                                count={index % 2 === 0 ? 2 : 1} />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {entries.map((entry, index) => {
                    const last = index + 1 === entries.length

                    return (
                        <Entry
                            {...props}
                            entry={entry}
                            actions={actions}
                            {...(last ? { ref: intersecter } : null)}
                            key={`absence:entries:entry:${entry.id}`} />
                    )
                })}
            </Grid>
            {!!hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost
                            className="constructive"
                            onClick={fetch}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
        </Wrap>
    )
}

export const Empty = ({ title: group, children = null }) => (
    <EmptyContainer which="absence">
        {group && (
            <>
                <EmptyText>
                    <FormattedMessage
                        id="absence_group_empty"
                        defaultMessage="Absence for the members of {group} will be displayed here"
                        values={{ group }} />
                </EmptyText>
                <Link
                    to="path:absence.base"
                    className="constructive">
                    <FormattedMessage
                        id="absence_action_navigate"
                        defaultMessage="Go to absence" />
                </Link>
            </>
        )}
        {children}
    </EmptyContainer>
)

export default AbsenceEntries

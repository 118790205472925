import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TableHead, TableBody, TableRow, TableFoot } from 'components/table'
import {
    Table,
    CornerCell, TableColumnHeader,
    TableRowHeader, TableCell, DontWrap,
    TableFooter
} from './s'

const Matrix = ({
    rows,
    headers = true,
    firstRowIsColumnHeaders = null,
    showRowHeaders = true,
    capAt = 100,
    dontWrapBelow = 50,
    salt,
    ...props
}) => {
    if(!rows?.length) {
        return null
    }

    rows = [...rows]

    const cappedRows = rows.length === capAt + 1 ?
        rows :
        rows.slice(0, capAt)

    const longestRowLength = cappedRows
        .map(row => row.length)
        .reduce((longest, length) => length > longest ? length : longest, 0)

    const rowTemplate = [...new Array(longestRowLength)]

    let headerRow = null
    if(headers) {
        if(firstRowIsColumnHeaders) {
            headerRow = cappedRows.shift()

            // Update `rows[]` accordingly so it doesn’t deviate in length
            // because of the header row, causing the overflow footer to show
            rows.shift()
        } else {
            headerRow = rowTemplate.map((_, index) => {
                const primary = alphabet[index % alphabet.length]
                const secondary = ((index + 1) > alphabet.length) ?
                    alphabet[Math.floor(index / alphabet.length) - 1] :
                    ''

                return `${primary}${secondary}`
            })
        }
    }

    return (
        <Table {...props}>
            {!!headerRow?.length && (
                <TableHead>
                    <TableRow>
                        {!!showRowHeaders && <CornerCell />}
                        {headerRow.map((header, index) => (
                            <TableColumnHeader
                                {...(firstRowIsColumnHeaders ? { className: 'important' } : null)}
                                key={`matrix:${salt}:${index}:header`}>
                                {(header.length < dontWrapBelow) && <DontWrap>{header}</DontWrap>}
                                {(header.length >= dontWrapBelow) && header}
                            </TableColumnHeader>
                        ))}
                    </TableRow>
                </TableHead>
            )}
            <TableBody>
                {cappedRows.map((row, rowIndex) => {
                    if(row.length < longestRowLength) {
                        row = rowTemplate.map((value, index) => row[index] ?? '')
                    }

                    return (
                        <TableRow key={`matrix:${salt}:${rowIndex}:row`}>
                            {!!showRowHeaders && (
                                <TableRowHeader>
                                    {rowIndex + 1}
                                </TableRowHeader>
                            )}
                            {row.map((value, columnIndex) => (
                                <TableCell key={`matrix:${salt}:${rowIndex}:${columnIndex}:cell`}>
                                    {(value.length < dontWrapBelow) && <DontWrap>{value}</DontWrap>}
                                    {(value.length >= dontWrapBelow) && value}
                                </TableCell>
                            ))}
                        </TableRow>
                    )
                })}
            </TableBody>
            {(rows.length > cappedRows.length) && (
                <TableFoot>
                    <TableRow>
                        <TableRowHeader>+</TableRowHeader>
                        <TableFooter colSpan={longestRowLength}>
                            <FormattedMessage
                                id="document_preview_spreadsheet_information_rows_capped"
                                defaultMessage="{count, plural, =0 {} =1 {Download to see one additional row} other {Download to see {count} additional rows}}"
                                values={{ count: rows.length - cappedRows.length }} />
                        </TableFooter>
                    </TableRow>
                </TableFoot>
            )}
        </Table>
    )
}

export default Matrix

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
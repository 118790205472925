import styled from 'styled-components'
import { Columns, FlexChildShrink } from 'components/flex'
import AvatarBase from 'components/avatar'

export const Container = styled(Columns)`
    align-items: center;
`

export const AvatarColumn = styled(FlexChildShrink)`
    position: relative;
    isolation: isolate;

    &:not(:first-child) {
        margin-left: ${p => !p.$compact ? '-4px' : `-${Math.ceil(p.$compact / 2)}px`};
    }
`

export const Avatar = styled(AvatarBase)`
    box-shadow: 0 0 0 2px var(--huma-color-surface-default);
    border-radius: 100%;
`

export const Rest = styled(FlexChildShrink).attrs({ as: 'p' })`
    padding: 0 8px;
    font-size: 12px;
    color: var(--huma-color-foreground-subtle);

    &.small {
        padding: 0 2px;
    }
`
import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { FlexChildGrow, FlexChildShrink } from 'components/flex'
import { H3 } from 'components/typography/heading'
import Caption, { CaptionSmall } from 'components/typography/caption'
import ActionsBase from 'components/form/actions'

export const LoadingContainer = styled.div`
    height: 240px;
`

export const Hero = styled(FlexChildShrink)`
    padding: 32px 32px 24px;

    ${belowTablet`
        padding: 24px 24px 16px;
    `}
`

export const Heading = styled(H3)`
    margin: 0;
`

export const ContentRow = styled(FlexChildGrow)`
    position: relative;
    overflow-y: auto;
`

export const Section = styled.div`
    &:not(:last-child) {
        margin-block-end: 24px;
    }
`

export const SectionHeading = styled(CaptionSmall)`
    margin-block-end: 8px;
    padding-inline: 32px;

    ${belowTablet`
        padding-inline: 24px;
    `}
`

export const Empty = styled(Caption)`
    padding-inline: 32px;

    ${belowTablet`
        padding-inline: 24px;
    `}
`

export const Actions = styled(ActionsBase)`
    padding: 24px 32px;
    border-top: 2px solid var(--huma-color-border-default);

    ${belowTablet`
        padding: 16px 24px;
    `}
`

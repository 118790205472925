import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useEnvironment } from 'contexts/environment'
import { useStorage } from 'hooks/storage'
import { Important, Heading, DismissButton } from './s'
import { CaptionSmall } from 'components/typography/caption'
import Link from 'components/link'
import { X } from 'styled-icons/feather'
import { cls } from 'utilities/dom'

const FunctionalityNotice = ({ access, storageKey, icon: Icon, heading, caption, knowledgebaseSlug, link = null, className, ...props }) => {
    const { locale } = useI18n()

    const { environment } = useEnvironment()

    const [dismissed, setDismissed] = useStorage({
        key: `dismissed:banner:${storageKey}:${locale}`,
        defaultValue: false
    })

    if(dismissed || !access) {
        return null
    }

    className = cls([
        className,
        'active'
    ])

    return (
        <Important
            {...props}
            className={className}>
            <Icon size={20} />
            <div>
                <Heading>{heading}</Heading>
                <CaptionSmall>{caption}</CaptionSmall>
                {(!!knowledgebaseSlug || !!link) && (
                    <Link
                        {...link}
                        {...(knowledgebaseSlug ? {
                            href: `https://www.${environment.tld}/knowledge/${knowledgebaseSlug}`,
                            target: '_blank'
                        } : null)}
                        className="constructive">
                        <FormattedMessage
                            id="knowledge_base_action_navigate"
                            defaultMessage="Read more in our Knowledge Base" />
                    </Link>
                )}
            </div>
            <DismissButton
                className="neutral"
                onClick={() => setDismissed(true)}>
                <X size={24} />
            </DismissButton>
        </Important>
    )
}

export default FunctionalityNotice
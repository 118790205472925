import styled, { css, keyframes } from 'styled-components'
import { belowTablet, darkMode } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Columns, FlexChild } from 'components/flex'

const roundMultiple = (value, divider = 1, multiple = 8) => Math.round((value * divider) / multiple) * multiple

const loader = keyframes`
    0% {
        background-position: 100% 50%;
    }
    50%, 100% {
        background-position: 0% 50%;
    }
`

export const skeletonBackground = css`
    --skeleton-box-shadow: var(--huma-color-surface-default);
    --skeleton-primary-color: var(--huma-palette-neutral-90);
    --skeleton-secondary-color: var(--huma-palette-neutral-100);

    background-attachment: fixed;
    background-color: var(--skeleton-primary-color);
    background-image: linear-gradient(
        90deg,
        var(--skeleton-primary-color),
        var(--skeleton-secondary-color),
        var(--skeleton-primary-color)
    );
    background-size: 400% 400%;
    animation: ${loader} 2s linear infinite;

    &.success,
    .success & {
        --skeleton-primary-color: var(--huma-palette-green-80);
        --skeleton-secondary-color: var(--huma-palette-green-90);
    }

    &.info,
    .info & {
        --skeleton-primary-color: var(--huma-palette-blue-80);
        --skeleton-secondary-color: var(--huma-palette-blue-90);
    }

    &.warning,
    .warning & {
        --skeleton-primary-color: var(--huma-palette-yellow-80);
        --skeleton-secondary-color: var(--huma-palette-yellow-90);
    }

    &.error,
    .error & {
        --skeleton-primary-color: var(--huma-palette-red-80);
        --skeleton-secondary-color: var(--huma-palette-red-90);
    }

    ${darkMode`
        --skeleton-primary-color: var(--huma-palette-neutral-30);
        --skeleton-secondary-color: var(--huma-palette-neutral-20);

        &.success,
        .success & {
            --skeleton-primary-color: var(--huma-palette-green-30);
            --skeleton-secondary-color: var(--huma-palette-green-20);
        }

        &.info,
        .info & {
            --skeleton-primary-color: var(--huma-palette-blue-30);
            --skeleton-secondary-color: var(--huma-palette-blue-20);
        }

        &.warning,
        .warning & {
            --skeleton-primary-color: var(--huma-palette-yellow-30);
            --skeleton-secondary-color: var(--huma-palette-yellow-20);
        }

        &.error,
        .error & {
            --skeleton-primary-color: var(--huma-palette-red-30);
            --skeleton-secondary-color: var(--huma-palette-red-20);
        }
    `}
`

// Containers
export const ListRow = styled(Grid)`
    position: relative;

    grid-template-columns: ${p => p.$columns && `minmax(0, 2fr) repeat(${p.$columns - 1}, minmax(0, 1fr)) ${p.$hasActions ? '40px' : ''}`};
    column-gap: 24px;

    width: 100%;
    padding: 24px 0;
    box-shadow: 0 -1px var(--huma-color-border-default);

    ${belowTablet`
        grid-template-columns: 1fr;

        padding: 16px 0;
    `}
`

export const RadioListRow = styled(ListRow)`
    grid-template-columns: ${p => p.$columns && `20px minmax(0, 2fr) repeat(${p.$columns - 1}, minmax(0, 1fr)) ${p.$hasActions ? '40px' : ''}`};
    align-items: center;
`

export const Cell = styled(Columns)`
    flex-direction: column;
    align-items: flex-start;

    &.center {
        justify-content: center;
    }

    ${belowTablet`
        ${ListRow} & {
            &:not(:first-child) {
                display: none;
            }
        }
    `}
`

// Strings
export const Strings = styled.p`
    width: 100%;

    > span {
        display: block;

        border-radius: 4px;
        margin-bottom: 8px;
        height: ${p => p.$size}px;
        box-shadow: inset 0 0 0 1px var(--skeleton-box-shadow-color);

        ${p => !!p.$length && css`width: ${p.$length}ex !important;`}

        ${skeletonBackground}

        &:nth-child(3n+1) {
            width: 85%;
        }

        &:nth-child(3n+2) {
            width: 65%;
        }

        &:nth-child(3n+3) {
            width: 75%;
        }

        &:only-child {
            width: 72.5%;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.flip > span {
        margin-inline-start: auto;
    }
`

// Shapes
export const Circle = styled.span`
    display: block;

    border-radius: 100%;
    width: ${p => p.$size}px;
    height: ${p => p.$size}px;
    min-width: ${p => p.$size}px;
    min-height: ${p => p.$size}px;

    ${skeletonBackground}
`

export const Square = styled.span`
    display: block;

    border-radius: 4px;
    width: ${p => p.$size}px;
    height: ${p => p.$size}px;
    min-width: ${p => p.$size}px;
    min-height: ${p => p.$size}px;

    ${skeletonBackground}
`

export const Rectangle = styled.span`
    display: block;

    border-radius: 4px;
    width: 100%;
    height: ${p => p.$size}px;
    min-height: ${p => p.$size}px;

    ${skeletonBackground}
`

export const Logo = styled.span`
    display: block;

    border-radius: 4px;
    width: ${p => (p.$size * 5)}px;
    height: ${p => p.$size}px;
    min-width: ${p => (p.$size * 5)}px;
    min-height: ${p => p.$size}px;

    ${skeletonBackground}
`

export const Status = styled.div`
    display: block;

    border-radius: 4px;
    width: 60px;
    height: 24px;

    ${skeletonBackground}
`

// Person and Category
export const AvatarAndFullName = styled.div`
    display: flex;
    align-items: center;
`

export const SymbolAndMeta = styled.div`
    display: flex;
    align-items: center;
`

export const Avatar = styled.span`
    display: block;

    margin-inline-end: ${p => `${roundMultiple(p.size, 0.6)}px`};
    border-radius: 100%;
    width: ${p => p.$size}px;
    height: ${p => p.$size}px;

    ${skeletonBackground}

    ${AvatarAndFullName} &,
    ${SymbolAndMeta} & {
        margin-inline-end: 16px;
    }
`

export const Category = styled.div`
    display: flex;
    align-items: flex-start;

    width: 100%;
`

export const Icon = styled.span`
    display: block;

    margin-inline-end: ${p => p.$size >= 24 ? p.$size : roundMultiple(p.$size, 0.6)}px;
    border-radius: 4px;
    width: ${p => p.$size}px;
    height: ${p => p.$size}px;

    ${skeletonBackground}
`

export const DetailsColumn = styled(FlexChild)`
    > span {
        display: block;

        border-radius: 4px;
        margin-bottom: 8px;
        height: ${p => p.$size}px;
        max-height: 20px;
        box-shadow: inset 0 0 0 1px var(--skeleton-box-shadow-color);

        ${p => !!p.$length && css`width: ${p.$length}ex !important;`}

        ${skeletonBackground}

        &:first-child {
            width: 16ch; /* Most common first and family name in the world: Muhammed Wang 🤓 */
            max-width: 85%;
        }

        &:last-child:not(:only-child) {
            margin-bottom: 0;
            width: 72.5%;
            height: 16px;
        }

        &:only-child {
            margin-bottom: 0;
            width: 145px;
        }
    }
`

// Avatars
export const Avatars = styled(Columns)`
    > ${Avatar} {
        margin-inline-end: 0;
        box-shadow: 0 0 0 2px var(--skeleton-box-shadow-color);

        &:not(:first-child) {
            margin-left: -4px;
        }
    }
`
import React from 'react'
import { Form } from '../s'
import { SearchIcon, Field, Loader } from './s'

// eslint-disable-next-line no-unused-vars
const SearchField = ({ filter, setFilter, resetFilterField, name, field = null, loading = false, salt, resetAt, fieldSalt, context, ...props }) => {
    const getValue = () => filter?.[name] ?? field?.defaultValue ?? field?.value ?? null

    return (
        <Form
            wait={{ [name]: 500 }}
            submitOnChange={true}
            onSubmit={setFilter}>
            <>
                <SearchIcon size={16} />
                <Field
                    className="compact wide"
                    {...props}
                    salt={salt}
                    label={false}
                    name={name}
                    field={{
                        ...field,
                        value: getValue(),
                        unsettable: !loading
                    }}
                    onUnset={() => {
                        resetFilterField()
                        props.onUnset?.()
                    }} />
                {!!loading && <Loader className="s" />}
            </>
        </Form>
    )
}

export default SearchField

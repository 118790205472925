import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { usePageView } from 'hooks/page-view'
import ArticleProvider, { useArticle } from 'contexts/article'
import { useNavigate } from 'react-router-dom'
import { getNewsArticleUrl } from 'utilities/url'
import Loader from 'components/loader'
import {
    Menu, MenuColumns, MenuColumn, CloseButton,
    Article, Statistics, FeaturedImage,
    Content, Heading, Byline, Body
} from './s'
import TiptapOutput, { TableOfContents } from 'components/tiptap/output'
import Meta from 'pages/news/pages/article/meta'
import { Columns, FlexChildShrink, FlexChild } from 'components/flex'
import StatusLabel from 'components/status-label'
import ContextMenu from 'widgets/context-menu'

const ArticleDetails = ({ close }) => {
    const { formatMessage } = useIntl()

    const {
        article,
        deleted,
        editable,

        readArticle
    } = useArticle()

    usePageView({
        name: 'article',
        articleId: article?.id
    }, [!!article?.id])

    usePageView({
        name: 'article_read',
        articleId: article?.id
    }, [!!article?.id])

    const [imageLoading, setImageLoading] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false)

    useEffect(() => {
        setImageLoading(!!article?.featuredImage?.original?.url && !imageLoaded)
    }, [article?.featuredImage?.original?.url, imageLoaded])

    useEffect(() => { readArticle() }, [])

    useEffect(() => {
        if(deleted && close) {
            close()
        }
    }, [deleted, close])

    const navigate = useNavigate()

    const {
        id,
        title,
        status,
        featuredImage,
        author,
        toc = false,
        content,
        updatedDate
    } = article ?? {}

    const salt = `news:article:${id}`

    const editAction = () => ({
        salt: `${salt}:edit`,
        label: formatMessage({
            id: 'article_action_edit',
            defaultMessage: 'Edit article'
        }),
        effect: 'neutral',
        onClick: () => navigate(getNewsArticleUrl({
            ...article,
            mode: 'edit'
        }))
    })

    const actions = [
        editAction
    ]

    return (
        <>
            <FlexChildShrink>
                <Menu>
                    <FlexChildShrink>
                        <MenuColumns>
                            <MenuColumn>
                                <CloseButton onClick={close} />
                            </MenuColumn>
                            {(status === 'draft') && (
                                <MenuColumn>
                                    <StatusLabel className="passive">
                                        <FormattedMessage
                                            id="article_status_draft"
                                            defaultMessage="Draft" />
                                    </StatusLabel>
                                </MenuColumn>
                            )}
                        </MenuColumns>
                    </FlexChildShrink>
                    {!!editable && (
                        <FlexChildShrink>
                            <Columns>
                                <FlexChildShrink>
                                    <ContextMenu
                                        salt={salt}
                                        context={article}
                                        actions={actions} />
                                </FlexChildShrink>
                            </Columns>
                        </FlexChildShrink>
                    )}
                </Menu>
            </FlexChildShrink>
            <FlexChild>
                <Article>
                    {!article && (
                        <Loader />
                    )}
                    {!!article && (
                        <>
                            <Statistics />
                            {!!featuredImage?.original?.url && (
                                <FeaturedImage animate={imageLoading ? 'hide' : 'show'}>
                                    <img
                                        onLoad={() => setImageLoaded(true)}
                                        src={featuredImage.original.url}
                                        itemProp="image" />
                                </FeaturedImage>
                            )}
                            <Content>
                                <Byline who={author} />
                                <Heading itemProp="title">{title}</Heading>
                                {!!content?.length && (
                                    <>
                                        {!!toc && (
                                            <TableOfContents
                                                content={content}
                                                salt={salt} />
                                        )}
                                        <TiptapOutput
                                            content={content}
                                            element={Body}
                                            dependencies={[updatedDate]} />
                                    </>
                                )}
                                <Meta />
                            </Content>
                        </>
                    )}
                </Article>
            </FlexChild>
        </>
    )
}

export default ({ id, ...props }) => (
    <ArticleProvider id={id}>
        <ArticleDetails {...props} />
    </ArticleProvider>
)
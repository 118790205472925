import React, { Fragment } from 'react'
import { useEntities } from 'contexts/entities'
import { compact, pruneBy } from 'utilities/array'
import { LoadingContainer } from './s'
import Loader from 'components/loader'
import { OrderedList } from 'components/list'
import Entity from './entity'
import { Team, SmartDocumentTemplate } from 'components/feather'
import {
    MapPin as Location,
    Shield as JobTitle,
    UserCheck as Role,
    Menu as ProcessTemplate
} from 'styled-icons/feather'

const Entities = ({ type, emptyOption, sticky, created, picked, pick, isFiltering = false, salt }) => {
    let {
        entities,
        filtered,
        intersecter,
        loading,
        fetching,
        paging = {},
        hasFetched
    } = useEntities()

    entities = filtered ?? entities

    const { hasNextPage } = paging

    return (
        <>
            {(!!fetching && !hasFetched) && <Loader />}
            <OrderedList>
                {pruneBy([
                    ...sticky,
                    emptyOption,
                    ...created,
                    ...entities
                ]).map((entity, index) => {
                    const last = index + 1 - sticky.length - created.length === entities.length
                    type = entity?.type ?? type
                    const icon = typeToIconMap[type]

                    return (
                        <Entity
                            entity={entity}
                            type={type}
                            index={index}
                            icon={icon}
                            picked={picked}
                            pick={pick}
                            isEmptyOption={!!entity.id && entity.id === emptyOption?.id}
                            isFiltering={isFiltering}
                            {...((!!intersecter && last) ? { ref: intersecter } : null)}
                            salt={`${salt}:entities`}
                            key={compact([
                                salt,
                                'entities',
                                entity.id,
                                (picked?.id === entity.id) && 'picked'
                            ]).join(':')} />
                    )
                })}
                {(!!intersecter && !!hasNextPage && !!loading) && (
                    <LoadingContainer>
                        <Loader />
                    </LoadingContainer>
                )}
            </OrderedList>
        </>
    )
}

const typeToIconMap = {
    team: props => <Team {...props} />,
    location: props => <Location {...props} />,
    jobTitle: props => <JobTitle {...props} />,
    role: props => <Role {...props} />,
    processTemplate: props => <ProcessTemplate {...props} />,
    smartTemplate: props => <SmartDocumentTemplate {...props} />
}

export default Entities
import React from 'react'
import styled from 'styled-components'
import {
    svgStroke,
    retina, belowPhone
} from 'utilities/styled'
import { FlexChildShrink } from 'components/flex'
import ImportantBase from 'components/important'
import { Trigger as TriggerBase } from 'components/tooltip'
import { Grid } from 'components/grid'
import { ArrowRight as ArrowRightBase } from 'styled-icons/feather'
import { Naked, InlineButton } from 'components/button'
import { ParagraphSmall } from 'components/typography/paragraph'
import { Wrapper as PersonPaneWrapper } from 'pages/people/pages/profile/panes/about/s'

export const ImportantColumn = styled(FlexChildShrink)`
    ${belowPhone`
        flex-basis: 100%;
    `}
`

export const Important = styled(ImportantBase)`
    &:empty {
        display: none;
    }
`

export const Wrapper = styled(Grid)`
    grid-template: 24px repeat(2, auto) / auto 24px;
    grid-gap: 8px;

    background-color: var(--huma-color-surface-default);

    p {
        align-self: center;
    }

    ${PersonPaneWrapper} & {
        border-radius: 6px;
        padding: 16px;
        box-shadow: var(--huma-shadow-lower);
    }
`

export const ArrowRight = styled(ArrowRightBase)`
    ${svgStroke()}
`

export const DismissButton = styled(Naked)`
    cursor: pointer;

    transition: all .1s ease-in-out;

    color: var(--huma-color-foreground-default);

    svg {
        ${svgStroke()}
    }
`

const ShowDetailsWrapper = styled(InlineButton)`
    margin-inline: 4px;
`

const Trigger = styled(TriggerBase)`
    padding-inline: unset;
`

export const ShowDetails = props => (
    <ShowDetailsWrapper {...props}>
        <Trigger size={16} />
    </ShowDetailsWrapper>
)

export const ProgressWrapper = styled.div`
    position: relative;

    grid-column: 1 / 3;
    margin-inline-start: 12px;
    width: calc(100% - 24px);
    height: 24px;

    ${PersonPaneWrapper} & {
        width: calc(clamp(150px, 100%, 350px) - 24px);
    }
`

export const Buffer = styled.div`
    position: absolute;
    top: 8px;
    left: -12px;
    overflow: hidden;

    border-radius: 32px;
    width: calc(100% + 24px);
    height: 8px;
    background-color: var(--huma-color-surface-subtle);
`

export const ProgressBar = styled.div`
    position: absolute;

    border-radius: 32px;
    width: ${p => p.$value ?? 0}%;
    height: 8px;
    background-color: var(--huma-color-surface-brand-bold);

    transform-origin: top left;
    transition: width .25s cubic-bezier(.4, 0, .6, 1) 0s;
`

export const Emoji = styled.div`
    pointer-events: none;
    position: absolute;
    top: 0;
    left: ${p => p.$flush ?? 0}%;

    margin-left: -12px;
    border-radius: 48px;
    width: 24px;
    height: 24px;
    background-color: var(--huma-color-surface-default);

    text-align: center;
    line-height: 24px;

    transform-origin: top left;
    transition: left .25s cubic-bezier(.4, 0, .6, 1) 0s;

    ${retina`
        width: 48px;
        height: 48px;

        transform: scale(0.5);

        line-height: 48px;
        font-size: 32px;
    `}
`

export const TierMessage = styled(ParagraphSmall)`
    grid-column: 1 / 3;
`

export const ImportantMessage = styled.span`
    display: inline-block;

    margin-inline: 4px;
    border-radius: 4px;
    padding-block: 4px;
    background-color: var(--huma-color-surface-highlight-subtle);
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    box-shadow:
        4px 0 0 var(--huma-color-surface-highlight-subtle),
        -4px 0 0 var(--huma-color-surface-highlight-subtle);

    color: var(--huma-color-foreground-default);
`
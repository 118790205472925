import React from 'react'
import { get } from 'api'
import Mention from '@tiptap/extension-mention'
import { ReactRenderer, ReactNodeViewRenderer, NodeViewWrapper, NodeViewContent, mergeAttributes } from '@tiptap/react'
import Suggestions from './suggestions'
import tippy from 'tippy.js'
import { omit } from 'utilities/object'
import { datafyAttributes, getTippySuggestionsConfiguration } from '../utilities'

const CustomMention = Mention.extend({
    marks: '',
    exitable: true,

    addAttributes() {
        return {
            type: {
                default: null,
                parseHTML: element => element.getAttribute('data-type'),
                renderHTML: attributes => ({ 'data-type': attributes.type })
            },
            uuid: {
                default: null,
                parseHTML: element => element.getAttribute('data-uuid'),
                renderHTML: attributes => ({ 'data-uuid': attributes.uuid })
            },
            name: {
                default: null,
                parseHTML: element => element.getAttribute('data-name'),
                renderHTML: attributes => ({ 'data-name': attributes.name })
            }
        }
    },

    parseHTML() {
        return [{
            tag: 'strong.mention[data-type][data-uuid][data-name]'
        }]
    },

    renderHTML({ HTMLAttributes }) {
        const attributes = mergeAttributes(
            { class: this.name },
            this.options.HTMLAttributes,
            HTMLAttributes
        )

        return [
            'strong',
            attributes,
            this.options.renderText(attributes.name)
        ]
    },

    renderText({ node }) {
        return this.options.renderText(node.attrs.name)
    },

    addNodeView() {
        return ReactNodeViewRenderer(({ node }) => {
            const attributes = mergeAttributes(
                { className: this.name },
                this.options.HTMLAttributes,
                ...datafyAttributes(node.attrs)
            )

            return (
                <NodeViewWrapper as="span">
                    <NodeViewContent
                        {...attributes}
                        as="strong">
                        {this.options.renderText(node.attrs.name)}
                    </NodeViewContent>
                </NodeViewWrapper>
            )
        })
    },

    addOptions() {
        const char = '@'

        return {
            ...omit(Mention.options, 'HTMLAttributes'),

            getLabel: element => element.innerText.replace(char, ''),
            renderText: name => `${char}${name}`,

            suggestion: {
                ...(Mention.options?.suggestion ?? null),
                char,
                allowSpaces: true,
                decorationTag: 'strong',
                decorationClass: 'mention',

                items: async ({ query }) => {
                    if(!query) {
                        return []
                    }

                    const { ok, response } = await get({
                        path: '/units',
                        params: { search: query }
                    })

                    return ok ?
                        response.items :
                        []
                },

                render: () => {
                    let suggestions
                    let popup

                    return {
                        onStart: props => {
                            suggestions = new ReactRenderer(Suggestions, {
                                props,
                                editor: props.editor
                            })

                            if(!props.clientRect) {
                                return
                            }

                            popup = tippy('body', getTippySuggestionsConfiguration(props, suggestions))
                        },

                        onUpdate: props => {
                            suggestions?.updateProps(props)

                            if(!props.clientRect) {
                                return
                            }

                            popup?.[0]?.setProps({
                                getReferenceClientRect: props.clientRect
                            })
                        },

                        onKeyDown: props => {
                            if(props.event.key === 'Escape') {
                                popup?.[0]?.hide()
                                return true
                            }

                            return suggestions?.ref?.onKeyDown(props)
                        },

                        onExit: () => {
                            suggestions?.destroy()
                            popup?.[0]?.destroy()
                        }
                    }
                },

                command: ({ editor, range, props }) => {
                    if(!props) {
                        return
                    }

                    const overrideSpace = editor.view.state.selection.$to.nodeAfter?.text?.startsWith(' ')
                    if(overrideSpace) {
                        range.to += 1
                    }

                    editor
                        .chain()
                        .focus()
                        .insertContentAt(range, [
                            {
                                type: this.name,
                                attrs: props
                            },
                            {
                                type: 'text',
                                text: ' '
                            }
                        ])
                        .run()

                    global.getSelection()?.collapseToEnd()
                }
            }
        }
    }
})

export default CustomMention
import React from 'react'
import { useIntl } from 'react-intl'
import { InlineMessage } from 'components/message'
import { Technical } from './s'

const TooltipContentMismatched = ({ field }) => {
    const { formatMessage } = useIntl()

    const translation = translations[field.type]
    if(!translation) {
        return null
    }

    return (
        <InlineMessage
            type="info"
            className="compact"
            message={formatMessage(
                translation,
                { field: <Technical>{field.name}</Technical> }
            )} />
    )
}

const translations = {
    user: {
        id: 'editor_variable_source_user_mismatched_field',
        defaultMessage: 'There’s no {field} field for users.'
    },
    team: {
        id: 'editor_variable_source_team_mismatched_field',
        defaultMessage: 'There’s no {field} field for teams.'
    },
    location: {
        id: 'editor_variable_source_location_mismatched_field',
        defaultMessage: 'There’s no {field} field for locations.'
    }
}

export default TooltipContentMismatched
import styled, { css, keyframes } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { codeNeutralCss } from 'components/code/s'
import { Grid } from 'components/grid'

const sectionCss = css`
    &:not(:last-child, :empty) {
        position: relative;
        left: -8px;

        margin-block-end: 8px;
        width: calc(100% + 16px);
        padding: 0 8px 4px;
        box-shadow: inset 0 -1px 0 0 var(--huma-color-shadow);
    }
`

export const Section = styled.div`
    ${sectionCss}
`

export const Heading = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    align-items: center;

    &.caption {
        color: var(--huma-color-foreground-subtle);
    }

    &:has(svg, .avatar) {
        grid-template-columns: 20px 1fr;
    }

    svg {
        ${svgStroke('small')}

        margin-inline: 2px;
    }
`

const rotate = keyframes`
    from { rotate: 0deg; }
    to { rotate: 360deg; }
`

export const IconWrapper = styled.span`
    display: grid;
    place-items: center;

    &.dynamic {
        animation: ${rotate} 10s linear infinite;
    }
`

export const Label = styled.span`
    &.warning,
    &.error {
        font-weight: 500;
    }

    &.warning {
        color: var(--huma-color-foreground-warning);
    }

    &.error {
        color: var(--huma-color-foreground-destructive);
    }
`

export const Technical = styled.code`
    ${codeNeutralCss}

    font-weight: 700;
`

export const Options = styled.div``

export const Option = styled(Grid)`
    position: relative;
    left: -8px;

    grid-template-columns: minmax(0, max-content) minmax(0, min-content);
    justify-content: space-between;
    align-items: center;
    grid-gap: 16px;

    width: calc(100% + 16px);
    padding: 16px;

    &:first-child {
        padding-block-start: 8px;
    }

    &:last-child {
        padding-block-end: 8px;
    }

    &:not(:last-child) {
        box-shadow: inset 0 -1px 0 0 var(--huma-color-shadow);
    }
`
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Value } from './s'
import { Field, Label } from 'components/form/field/s'

const ViewContractableSelect = ({ field = {}, className, label }) => {
    let {
        valueRenderer,
        ...fieldProps
    } = field

    if(!valueRenderer) {
        valueRenderer = ({ value }) => {
            if(!value) {
                return (
                    <Value className="empty">
                        <FormattedMessage
                            id="empty_not_picked"
                            defaultMessage="Not picked" />
                    </Value>
                )
            }

            return (
                <Value>{value}</Value>
            )
        }
    }

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            {valueRenderer(fieldProps)}
        </Field>
    )
}

export default ViewContractableSelect

import styled from 'styled-components'
import { belowContainer, fromPhone } from 'utilities/styled'
import CardListBase from 'components/card-list'
import { SkeletonListRow as SkeletonListRowBase, SkeletonCell as SkeletonCellBase } from 'components/skeleton'
import TutorialBase from 'components/tutorial'

export const containerProps = {
    name: 'salary-revisions',
    breakpoint: 650
}

export const CardList = styled(CardListBase)`
    grid-template-columns:
        [wrap-start meta] minmax(0, 2fr)
        [effective] minmax(0, 1fr)
        [status] minmax(0, 1fr)
        [action] 24px [wrap-end];
`

export const CardListHeader = styled(CardListBase.Header)`
    &:not(:first-of-type) {
        margin-block-start: 24px;
    }
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    display: grid;
    grid-template-columns: subgrid;
    grid-column: wrap;
    align-items: center;
    column-gap: 16px;

    padding-block: 16px;
    min-height: 72px;

    &:not(:last-child) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    ${belowContainer(containerProps)`
        display: flex;
        flex-direction: column;
        align-items: start;
    `}
`

export const SkeletonCell = styled(SkeletonCellBase)`
    grid-column: ${p => p.$cell};
`

export const LoadingContainer = styled.div`
    position: relative;

    display: grid;
    place-items: center;
    grid-column: 1 / -1;

    height: 96px;
`



export const Tutorial = styled(TutorialBase)`
    ${fromPhone`
        margin-block-start: 64px;
    `}
`
import { pick, compact, filter as filterObject, size } from 'utilities/object'
import isEqual from 'react-fast-compare'

const sortIfArray = value => Array.isArray(value) ? value.sort() : value

const convertEmptyToNull = value => {
    if([[], {}, '', undefined].some(falsy => isEqual(falsy, value))) {
        return null
    }

    return value
}

const checkLooseEquality = (one, two) => isEqual(sortIfArray(convertEmptyToNull(one)), sortIfArray(convertEmptyToNull(two)))

const appliedDetailed = (filter, fields) => {
    const emptyValues = getEmptyValues(fields)
    const defaultValues = getDefaultValues(fields)

    const emptyFieldsNotEmpty = filterObject(emptyValues, (value, name) => !checkLooseEquality(filter?.[name], value))
    const defaultFieldsNotDefault = filterObject(defaultValues, (value, name) => !checkLooseEquality(filter?.[name], value))

    return !!size(emptyFieldsNotEmpty) || !!size(defaultFieldsNotDefault)
}

const countAppliedDetailed = (filter, fields) => {
    const emptyValues = getEmptyValues(fields)
    const defaultValues = getDefaultValues(fields)

    const emptyFieldsNotEmpty = filterObject(emptyValues, (value, name) => !checkLooseEquality(filter?.[name], value))
    const defaultFieldsNotDefault = filterObject(defaultValues, (value, name) => !checkLooseEquality(filter?.[name], value))

    return size({
        ...emptyFieldsNotEmpty,
        ...defaultFieldsNotDefault
    })
}

export const basicApplied = names => (filter, fields = []) => {
    if(fields?.length) {
        return appliedDetailed(filter, fields)
    }

    filter = compact(pick(filter, ...names))

    return !!Object
        .values(filter)
        .filter(Boolean)
        .length
}

export const advancedApplied = (names, mode = 'boolean') => (filter, fields = []) => {
    if(fields?.length) {
        return (mode === 'count') ?
            countAppliedDetailed(filter, fields) :
            appliedDetailed(filter, fields)
    }

    filter = compact(pick(filter, ...names))

    const count = Object
        .values(filter)
        .flatMap(a => a)
        .length

    return (mode === 'count') ?
        count :
        !!count
}

export const getEmptyValues = fields => fields
    .filter(({ field = {} }) => !('defaultValue' in field))
    .reduce((accumulator, { name, empty = null }) => ({
        ...accumulator,
        [name]: empty
    }), {})

export const getDefaultValues = fields => fields
    .filter(({ field = {} }) => ('defaultValue' in field))
    .reduce((accumulator, { name, field }) => ({
        ...accumulator,
        [name]: field.defaultValue
    }), {})
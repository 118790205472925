import React, { forwardRef } from 'react'
import View from './view'
import Edit from './edit'

const AccessUnitsField = forwardRef((props, forwardedRef) => {
    let {
        name,
        field = {},
        picker = {},
        mode = 'edit',
        enabled = true,
        salt,
        ...rest
    } = props

    let {
        value = [],
        editable = true
    } = field

    let {
        types = ['organization', 'team', 'location', 'user'], // company?
    } = picker

    props = {
        ...rest,
        name,
        field: {
            ...field,
            value
        },
        picker: {
            ...picker,
            types
        },
        enabled,
        salt: `${salt}:${name}`,
        forwardedRef
    }

    if(mode === 'edit' && editable) {
        return <Edit {...props} />
    }

    return <View {...props} />
})

export default AccessUnitsField
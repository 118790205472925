import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useInvoices } from 'contexts/invoices'
import { clamp } from 'utilities/math'
import {
    Wrap,
    SkeletonListRow, SkeletonCell,
    Header, GridColumnHeader, LoadingContainer
} from './s'
import Invoice from './invoice'
import InvoicePreview from 'modals/file-preview'
import { Ghost } from 'components/button'
import Loader from 'components/loader'
import { SkeletonStrings } from 'components/skeleton'

const Invoices = ({ header = true, className }) => {
    const {
        invoices,
        intersecter,
        loading,
        paging = {},
        fetchInvoices,
        fetching,
        autoFetch,
        hasFetched
    } = useInvoices()

    const [skeletonLength, setSkeletonLength] = useState(5)
    const [previewing, setPreviewing] = useState(null)

    const { hasNextPage } = paging

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(invoices.length, 1, 10))
        }
    }, [hasFetched, invoices?.length])

    return (
        <Wrap {...(className ? { className } : null)}>
            {header && (
                <Header>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="payment_subscription_invoice_label_plan"
                            defaultMessage="Plan" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="payment_subscription_invoice_label_invoice_date"
                            defaultMessage="Invoice date" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="payment_subscription_invoice_label_due_at"
                            defaultMessage="Due at" />
                    </GridColumnHeader>
                    <GridColumnHeader>
                        <FormattedMessage
                            id="payment_subscription_invoice_label_total"
                            defaultMessage="Total" />
                    </GridColumnHeader>
                </Header>
            )}
            {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                <SkeletonListRow
                    columns={4}
                    hasActions={true}
                    key={`list:invoices:skeleton:${index}`}>
                    <SkeletonCell>
                        <SkeletonStrings size={16} count={2} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings size={16} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings size={16} />
                    </SkeletonCell>
                    <SkeletonCell>
                        <SkeletonStrings size={16} />
                    </SkeletonCell>
                </SkeletonListRow>
            ))}
            {invoices.map((invoice, index) => {
                const last = index + 1 === invoices.length

                return (
                    <Invoice
                        invoice={invoice}
                        setPreviewing={setPreviewing}
                        {...(last ? { ref: intersecter } : null)}
                        key={invoice.id} />
                )
            })}
            {!!hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost className="constructive" onClick={fetchInvoices}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
            <InvoicePreview
                show={!!previewing}
                {...previewing}
                dismiss={() => setPreviewing(null)}
                salt="invoice:preview" />
        </Wrap>
    )
}

export default Invoices

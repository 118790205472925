import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Container as ContainerBase } from 'utilities/modal'
import { Rows, FlexChildGrow, FlexChildShrink } from 'components/flex'
import Paragraph from 'components/typography/paragraph'
import { ListItem } from 'components/list'
import { H3 } from 'components/typography/heading'
import { Plain } from 'components/button'
import ActionsBase from 'components/form/actions'

export const Container = styled(ContainerBase)`
    height: 704px;
    max-height: 80vh;
    padding: 0 !important;
    user-select: none;

    &.creating {
        height: auto;
    }
`

export const Hero = styled(FlexChildShrink)`
    padding: 32px 32px 24px;

    ${belowTablet`
        padding: 24px 24px 16px;
    `}
`

export const Herows = styled(Rows)`
    row-gap: 16px;
`

export const Heading = styled(H3)`
    margin: 0;
`

export const ContentRow = styled(FlexChildGrow)`
    position: relative;
    overflow-y: auto;

    ${Container}.creating & {
        padding: 32px 32px 24px;

        ${belowTablet`
            padding: 24px 24px 16px;
        `}
    }
`

export const Status = styled(Paragraph)`
    padding-block-end: 8px;
    padding-inline: 32px;

    text-align: center;
    line-height: 40px;

    ${belowTablet`
        padding-inline: 24px;
    `}
`

export const EmptyContainer = styled.div`
    padding: 16px 32px;
    text-align: center;

    > * {
        margin: 0 auto;
    }
`

export const CreateButton = styled(Plain)`
    margin: 0 32px 16px;

    > svg {
        stroke-width: 2;
        margin-inline-end: 20px;
    }
`

export const LoadingContainer = styled(ListItem)`
    position: relative;
    margin: 0 32px 32px;
    height: 40px;
`

export const Actions = styled(ActionsBase)`
    padding: 24px 32px;
    border-top: 2px solid var(--huma-color-border-default);

    ${belowTablet`
        padding: 16px 24px;
    `}
`
import React, { useState, useEffect } from 'react'
import { useStorage } from 'hooks/storage'
import { useTriggerOnPropsChange } from 'hooks/trigger-on-props-change'
import { useTheme, ThemeProvider } from 'styled-components'
import { cls } from 'utilities/dom'
import {
    Container, NakedContainer,
    Header, NakedHeader, ToggleCell, ActionsCell, StatusCell,
    HeadingCell, Heading, Subheading,
    Drawer, Content, NakedContent
} from './s'
import { Plain, ButtonSubmit } from 'components/button'
import { FlexChildShrink } from 'components/flex'
import { ChevronDown as Chevron } from 'styled-icons/feather'
import { ArrowSortedDown as Arrow } from 'styled-icons/typicons'

const DrawablePanel = ({ children, salt, ...props }) => {
    const {
        defer = false,
        drawable = true,
        open = false,
        force = false,
        completed = false,
        heading = '',
        subheading = '',
        actions = [],
        status = null,
        onOpen = () => {},
        onClose = () => {},
        indicator = 'chevron',
        persistState = true,
        ...attributes
    } = props

    const [ready, setReady] = useState(defer)
    const [expanded, setExpanded] = useStorage({
        key: `drawable:expanded:${salt}`,
        defaultValue: force ? open : (drawable ? open : true),
        enabled: persistState && !force && !!salt
    })

    const toggle = () => setExpanded(expanded => {
        !expanded && onOpen?.()
        !!expanded && onClose?.()
        return !expanded
    })

    useEffect(() => {
        expanded && setReady(true)
    }, [expanded])

    useTriggerOnPropsChange({
        props,
        defaults: {
            force: false,
            open: false,
            drawable: true
        },
        keys: ['force', 'open', 'drawable'],
        onChange: ({ force, open, drawable }) => setExpanded(force ? open : (drawable ? open : true))
    })

    const containerClassName = cls([
        attributes?.className,
        expanded && 'expanded'
    ])

    const headerClassName = cls([
        !!actions?.length && 'has-actions',
        !!status && 'has-status',
        !!children && 'has-content',
        !!drawable && 'interactive',
        (!!drawable || force) && 'has-toggle'
    ])

    let animate = 'contracted'
    if(expanded) {
        animate = 'expanded'
    } else if(force) {
        animate = 'queued'
    }

    const Indicator = getIndicator(indicator)

    return (
        <Container
            {...attributes}
            {...(containerClassName ? { className: containerClassName } : null)}>
            <Header {...(headerClassName ? { className: headerClassName } : null)}>
                {(!!drawable || force) && (
                    <ToggleCell
                        animate={animate}
                        initial={open ? 'expanded' : 'contracted'}
                        {...((!!children && drawable) ? { onClick: toggle } : null)}>
                        <Indicator />
                    </ToggleCell>
                )}
                {!!heading && (
                    <HeadingCell {...((!!children && drawable) ? { onClick: toggle } : null)}>
                        <Heading {...(!!completed ? { className: 'strike' } : null)}>
                            {heading}
                            {!!subheading && (
                                <Subheading {...(!!completed ? { className: 'strike' } : null)}>
                                    {subheading}
                                </Subheading>
                            )}
                        </Heading>
                    </HeadingCell>
                )}
                {!!actions?.length && (
                    <ActionsCell>
                        {actions.map(action => {
                            const {
                                element = 'button',
                                text,
                                effect = 'neutral',
                                className,
                                salt: actionSalt
                            } = action

                            const ActionElement = {
                                button: Plain,
                                submit: ButtonSubmit
                            }[element]

                            const actionClassName = cls([
                                className,
                                effect
                            ])

                            return (
                                <FlexChildShrink key={`${salt}:${actionSalt}`}>
                                    <ActionElement
                                        {...action}
                                        {...(actionClassName ? { className: actionClassName } : null)}>
                                        {text}
                                    </ActionElement>
                                </FlexChildShrink>
                            )
                        })}
                    </ActionsCell>
                )}
                {!!status && (
                    <StatusCell>
                        {status}
                    </StatusCell>
                )}
            </Header>
            <Drawer
                animate={animate}
                initial={open ? 'expanded' : 'contracted'}>
                <Content>
                    {!!ready && children}
                </Content>
            </Drawer>
        </Container>
    )
}

export default DrawablePanel

export const Naked = ({ children, callback, salt, ...props }) => {
    const {
        defer = false,
        drawable = true,
        open = false,
        force = false,
        header = null,
        layout = null,
        trigger = 'indicator',
        indicator = 'chevron',
        controlled = false,
        persistState = true,
        ...attributes
    } = props

    const [ready, setReady] = useState(defer)
    const [expanded, setExpanded] = useStorage({
        key: `drawable:expanded:${salt}`,
        defaultValue: force ? open : (drawable ? open : true),
        enabled: persistState && !force && !!salt
    })

    const toggle = () => setExpanded(expanded => !expanded)

    useEffect(() => {
        expanded && setReady(true)

        if(callback) {
            callback(expanded)
        }
    }, [expanded])

    useTriggerOnPropsChange({
        props,
        defaults: {
            force: false,
            open: false,
            drawable: true
        },
        keys: ['force', 'open', 'drawable'],
        onChange: ({ force, open, drawable }) => setExpanded(force ? open : (drawable ? open : true))
    })

    const showToggleCell = !controlled && (!!drawable || force)
    const headerless = !header && !showToggleCell

    const containerClassName = cls([
        attributes?.className,
        headerless && 'headerless',
        expanded && 'expanded'
    ])

    let animate = 'contracted'
    if(expanded) {
        animate = 'expanded'
    } else if(force) {
        animate = 'queued'
    }

    let theme = useTheme()
    theme = {
        ...theme,
        layout
    }

    const Indicator = getIndicator(indicator)

    return (
        <ThemeProvider theme={theme}>
            <NakedContainer
                {...attributes}
                {...(containerClassName ? { className: containerClassName } : null)}>
                {!headerless && (
                    <NakedHeader {...((!!children && drawable && trigger === 'header') ? {
                        onClick: toggle,
                        onKeyDown: event => {
                            if([' ', 'Enter', 'Space'].includes(event.key)) {
                                toggle()
                            }
                        },
                        className: 'trigger',
                        role: 'button',
                        tabIndex: 0
                    } : null)}>
                        {(typeof header === 'function') && header(animate)}
                        {(typeof header !== 'function') && header}
                        {showToggleCell && (
                            <ToggleCell
                                className="naked"
                                animate={animate}
                                initial={open ? 'expanded' : 'contracted'}
                                {...((!!children && drawable && trigger === 'indicator') ? { onClick: toggle } : null)}>
                                <Indicator />
                            </ToggleCell>
                        )}
                    </NakedHeader>
                )}
                <Drawer
                    animate={animate}
                    initial={open ? 'expanded' : 'contracted'}>
                    <NakedContent {...(animate === 'contracted' ? { inert: 'true' } : null)}>
                        {!!ready && children}
                    </NakedContent>
                </Drawer>
            </NakedContainer>
        </ThemeProvider>
    )
}

export const IndicatorChevron = () => <Chevron size={24} />
export const IndicatorArrow = () => <Arrow size={16} />

const getIndicator = type => ({
    chevron: IndicatorChevron,
    arrow: IndicatorArrow
})[type] ?? (() => null)
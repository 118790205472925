import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'

const ViewSelect = ({ label, className, ...props }) => {
    const { field = {} } = props
    const { value } = field

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <ValueDisplay {...(!value ? { className: 'empty' } : null)}>
                    {(!!value || value === 0) && value}
                    {!value && (
                        <FormattedMessage
                            id="empty_not_chosen"
                            defaultMessage="Not chosen" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewSelect

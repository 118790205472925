import styled, { css } from 'styled-components'
import { fromTablet, belowTablet } from 'utilities/styled'
import { compact } from 'utilities/array'
import { Grid } from 'components/grid'
import Paragraph from 'components/typography/paragraph'
import StatusLabelBase from 'components/status-label'

export const policyGridCss = css`
    ${({ theme: { absencePolicies: { actions } } }) => css`
        grid-template-columns: ${compact(['3fr', '2fr', '2fr', 'minmax(0, 1fr)', actions && '40px']).join(' ')};
        column-gap: 24px;

        ${belowTablet`
            grid-template-columns: auto 40px;
            grid-auto-flow: row;
            grid-auto-rows: auto;
            row-gap: 16px;
        `}
    `}
`

export const Policy = styled(Grid)`
    ${policyGridCss}

    width: 100%;
    padding-top: 16px;

    &:not(:last-of-type) {
        padding-bottom: 16px;
        box-shadow: 0 1px var(--huma-color-border-default);
    }
`

const cellCss = css`
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    column-gap: 16px;

    ${belowTablet`
        column-gap: 12px;

        font-size: 14px;

        &:empty {
            display: none;
        }
    `}
`

export const NameCell = styled(Grid)`
    align-items: start;
`

export const Name = Paragraph

export const QuotaCell = styled(Grid)`
    ${cellCss}

    ${belowTablet`
        > p {
            font-size: 14px;
        }
    `}
`

export const AssignedCell = styled.div`
    ${cellCss}

    ${belowTablet`
        grid-column: 1 / -1;
    `}
`

export const StatusCell = styled.div`
    ${cellCss}

    ${fromTablet`
        justify-content: end;
    `}

    ${belowTablet`
        grid-column: 1 / -1;
    `}
`

export const StatusLabel = styled(StatusLabelBase)`
    align-self: center;
`

export const ActionsCell = styled.div`
    ${cellCss}

    ${belowTablet`
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        align-self: start;
    `}

    .actions-button {
        ${belowTablet`
            position: relative;
            top: -8px;
        `}
    }
`
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useReveal } from 'hooks/reveal'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { DisplayTime } from './'
import { cls } from 'utilities/dom'

const ViewTime = ({ className, label, ...props }) => {
    const {
        field = {},
        controlProps = {},
        display,
        view = {},
        obscure = false,
        mode = 'date',
        zoned = false
    } = props

    const {
        value = null,
        range = false
    } = field

    const { yearsDiff = false } = view

    const [node, reveal] = useReveal(obscure)

    let displayClassName = []

    if(value) {
        displayClassName = [
            display,
            obscure && 'obscured',
            reveal && 'revealed'
        ]
    } else {
        displayClassName = ['empty']
    }

    displayClassName = cls(displayClassName)

    const controlClassName = cls([
        controlProps.className,
        display,
        zoned && 'zoned'
    ])

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control {...(controlClassName ? { className: controlClassName } : null)}>
                <ValueDisplay
                    {...(displayClassName ? { className: displayClassName } : null)}
                    ref={node}>
                    {(!!value && !zoned) && (
                        <DisplayTime
                            time={value}
                            mode={mode}
                            short={range}
                            yearsDiff={yearsDiff} />
                    )}
                    {/* {(!!value && zoned) && (
                        <>Zoned time yo</>
                    )} */}
                    {!value && (
                        <FormattedMessage
                            id="empty_not_set"
                            defaultMessage="Not set" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewTime

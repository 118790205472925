import styled, { css } from 'styled-components'
import CardListBase from 'components/card-list'

export const containerProps = {
    name: 'salary-revision-group-users',
    breakpoint: 900
}

export const CardList = styled(CardListBase)`
    grid-template-columns:
        [wrap-start name message-start] minmax(0, 2.5fr)
        [seniority] minmax(0, 1fr)
        [current note-start message-end] minmax(0, 1fr)
        [change] minmax(0, 3fr)
        [new] minmax(0, 1fr)
        [notes] min-content
        [actions note-end] 40px [wrap-end];
`

export const Cell = styled(CardListBase.Cell)`
    ${({ $cell }) => {
        if($cell === 'notes') {
            return css `
                grid-column: notes / span 2;
            `
        }
    }}
`
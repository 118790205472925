import React from 'react'
import { FormattedMessage } from 'react-intl'
import { cls } from 'utilities/dom'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import WordList from 'components/word-list'

const ViewOptions = ({ className, label, ...props }) => {
    const {
        field = {},
        controlProps = {},
        display
    } = props

    let { value } = field

    let hasValue = !!value?.length

    const displayClassName = cls([
        hasValue && display,
        !hasValue && 'empty'
    ])

    const controlClassName = cls([
        controlProps.className,
        display
    ])

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control {...(controlClassName ? { className: controlClassName } : null)}>
                <ValueDisplay {...(displayClassName ? { className: displayClassName } : null)}>
                    {!!hasValue && (
                        <WordList
                            words={value}
                            join={null} />
                    )}
                    {!hasValue && (
                        <FormattedMessage
                            id="empty_not_entered"
                            defaultMessage="Not entered" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewOptions

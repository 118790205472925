import styled from 'styled-components'
import { Field as FieldBase } from 'components/form/field/s'
import { Grid } from 'components/grid'

export const Field = styled(FieldBase)``

export const ValueDisplay = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    justify-content: space-between;
    height: 40px;

    ${Field}.label-less & {
        height: auto;
    }
`

export const CardDetails = styled(Grid).attrs(() => ({ as: 'p' }))`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    column-gap: 16px;
    height: 32px;
    padding: 4px 8px;
    margin-block: 4px;
    line-height: 24px;
    box-shadow: inset 0 0 0 1px var(--huma-color-border-default);
    border-radius: 8px;

    ${Field}.label-less & {
        margin: 0;
    }
`
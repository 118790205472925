import React from 'react'
import { useMe } from 'contexts/me'
import { SkeletonCircle } from 'components/skeleton'
import Avatar from 'components/avatar'
import { AvatarColumnsBlock } from 'components/avatar/s'

export default ({ size = 32, ...props }) => {
    const { me } = useMe()

    if(!me) {
        return (
            <AvatarColumnsBlock>
                <SkeletonCircle size={size} />
            </AvatarColumnsBlock>
        )
    }

    return (
        <Avatar
            {...props}
            who={me}
            size={size} />
    )
}

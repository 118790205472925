import React from 'react'
import { splitName } from 'utilities/file'
import { size as objectSize } from 'utilities/object'
import { Columns, DetailsColumn, IconColumn } from './s'
import FileNameIcon from 'components/file-name-icon'
import ContextMenu from 'widgets/context-menu'
import {
    Eye as Preview,
    Download,
    Trash2 as Delete
} from 'styled-icons/feather'

const FileDisplay = ({ name, action, actions = {}, size = 24, meta, ...props }) => {
    const Icon = {
        preview: Preview,
        download: Download,
        delete: Delete
    }[action]

    return (
        <Columns {...!!objectSize(actions) ? { className: 'has-actions' } : null}>
            <DetailsColumn>
                <FileNameIcon
                    {...splitName(name)}
                    {...props}
                    inline={true} />
            </DetailsColumn>
            {!!Icon && (
                <IconColumn>
                    <Icon size={size} />
                </IconColumn>
            )}
            {!!objectSize(actions) && (
                <ContextMenu
                    salt={props.salt}
                    context={meta}
                    actions={actions} />
            )}
        </Columns>
    )
}

export default FileDisplay
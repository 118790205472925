import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useMeetingTemplates } from 'contexts/meeting-templates'
import { useI18n } from 'contexts/i18n'
import { pick, map } from 'utilities/object'
import { capitalize } from 'utilities/string'
import { clamp } from 'utilities/math'
import { cls } from 'utilities/dom'
import { getActionsArray } from 'widgets/context-menu'
import {
    Wrap, Header,
    GridColumnHeader, GridColumnActionsHeader,
    Form,
    LoadingContainer, Tutorial, SkeletonListRow
} from './s'
import { SkeletonCell, SkeletonSymbolAndMeta, SkeletonStrings } from 'components/skeleton'
import Template from './template'
import SelectField from 'components/form/field/select'
import Loader from 'components/loader'
import { Ghost } from 'components/button'
import DrawablePanel from 'components/drawable-panel'
import { Scrollable as Modal } from 'modals/generic'
import Preview from 'pages/meetings/modals/template/preview'
import UpgradeCTA from 'components/upgrade-cta'

const templatesCut = 10

const MeetingTemplates = ({ customActions = [], humaActions = [], upgradable, header = false, className }) => {
    const {
        formatMessage,
        formatDisplayName
    } = useIntl()

    const {
        locale,
        locales
    } = useI18n()

    const {
        templates = [],
        total,
        templateLocales,
        humaTemplates = [],
        intersecter,
        loading,
        paging = {},
        fetch,
        fetching: fetchingInternal,
        autoFetch,
        hasFetched: hasFetchedInternal,
        fetchHumaTemplates,
        fetchingHumaTemplates,
        hasFetchedHumaTemplates,
        flash,
        clearFlash
    } = useMeetingTemplates()

    const [skeletonLength, setSkeletonLength] = useState(5)
    const [showAllHumaTemplates, setShowAllHumaTemplates] = useState(false)
    const [previewing, setPreviewing] = useState(null)

    const hasCustomActions = !!getActionsArray(customActions).length

    const hasFetched = hasFetchedInternal && hasFetchedHumaTemplates
    const fetching = fetchingInternal && fetchingHumaTemplates

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(templates.length, 1, 10))
        }
    }, [hasFetched, templates?.length])

    className = cls([
        className,
        upgradable && 'compact'
    ])

    return (
        <>
            <Wrap {...(className ? { className } : null)}>
                {(!templates.length && !humaTemplates.length && hasFetched) && (
                    <Tutorial which="meetings" />
                )}
                {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow
                        columns={2}
                        hasActions={true}
                        key={`list:meeting:templates:skeleton:${index}`}>
                        <SkeletonCell>
                            <SkeletonSymbolAndMeta
                                size={40}
                                showSecondLine={true} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings
                                size={20}
                                length={index & 2 === 0 ? 12 : 16}
                                count={2} />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {!!upgradable && (
                    <UpgradeCTA
                        feature="meeting-templates"
                        responsive
                        useUpgradeIcon
                        useFeatureOrModuleIcon
                        useFeatureTitle
                        useFeatureDescription
                        className="airy"
                        salt="meeting-templates" />
                )}
                {(!upgradable && !!templates?.length) && (
                    <DrawablePanel
                        heading={[
                            formatMessage({
                                id: 'noun_your_templates',
                                defaultMessage: 'Your templates'
                            }),
                            `(${total})`
                        ].join(formatMessage({
                            id: 'sentence_separator',
                            defaultMessage: ' '
                        }))}
                        open={true}>
                        {(header || fetching) && (
                            <Header>
                                <GridColumnHeader>
                                    <FormattedMessage
                                        id="noun_name"
                                        defaultMessage="Name" />
                                </GridColumnHeader>
                                <GridColumnHeader>
                                    <FormattedMessage
                                        id="status_updated"
                                        defaultMessage="Updated" />
                                </GridColumnHeader>
                                {!!hasCustomActions && <GridColumnActionsHeader />}
                            </Header>
                        )}
                        {templates.map((template, index) => {
                            const last = index + 1 === templates.length

                            return (
                                <Template
                                    template={template}
                                    actions={customActions}
                                    {...(last ? { ref: intersecter } : null)}
                                    flash={flash?.current === template ? clearFlash : null}
                                    key={template.id} />
                            )
                        })}
                        {!!paging.hasNextPage && (
                            <LoadingContainer>
                                {(!loading && !autoFetch) && (
                                    <Ghost
                                        className="constructive"
                                        onClick={fetch}>
                                        <FormattedMessage
                                            id="action_load_more"
                                            defaultMessage="Load more…" />
                                    </Ghost>
                                )}
                                {!!loading && <Loader />}
                            </LoadingContainer>
                        )}
                    </DrawablePanel>
                )}
                {!!humaTemplates?.length && (
                    <DrawablePanel
                        heading={[
                            formatMessage({
                                id: 'noun_huma_meeting_templates',
                                defaultMessage: 'Meeting templates created by Huma'
                            }),
                            `(${humaTemplates.length})`
                        ].join(formatMessage({
                            id: 'sentence_separator',
                            defaultMessage: ' '
                        }))}
                        open={true}>
                        <Form>
                            <SelectField
                                label={false}
                                name="locale"
                                field={{
                                    value: templateLocales.includes(locale) ?
                                        locale :
                                        templateLocales[0],
                                    allowEmptyOption: false
                                }}
                                options={map(pick(locales, ...templateLocales), ({ flag }, locale) => ({
                                    key: locale,
                                    value: locale,
                                    text: `${flag} ${capitalize(formatDisplayName(locale, { type: 'language' }))}`
                                }))}
                                onChange={({ locale }) => {
                                    setShowAllHumaTemplates(false)
                                    fetchHumaTemplates(locale)
                                }} />
                        </Form>
                        {humaTemplates
                            .slice(0, (showAllHumaTemplates ? Infinity : templatesCut))
                            .map(template => (
                                <Template
                                    template={template}
                                    actions={humaActions}
                                    setPreviewing={setPreviewing}
                                    flash={flash?.current === template ? clearFlash : null}
                                    key={template.id} />
                            )
                        )}
                        {(humaTemplates?.length > templatesCut && !showAllHumaTemplates) && (
                            <LoadingContainer>
                                <Ghost
                                    className="constructive"
                                    onClick={() => setShowAllHumaTemplates(true)}>
                                    <FormattedMessage
                                        id="action_show_all"
                                        defaultMessage="Show all" />
                                </Ghost>
                            </LoadingContainer>
                        )}
                    </DrawablePanel>
                )}
            </Wrap>
            <Modal
                show={previewing}
                dismiss={() => setPreviewing(false)}>
                <Preview
                    {...previewing}
                    dismiss={() => setPreviewing(false)}
                    salt={`meeting:template:${previewing?.id}`} />
            </Modal>
        </>
    )
}

export default MeetingTemplates
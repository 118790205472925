import React, { forwardRef, Fragment } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { useMe } from 'contexts/me'
import { useFlash } from 'hooks/flash'
import { v4 as uuid } from 'uuid'
import { cls } from 'utilities/dom'
import {
    TaskWrapper,
    CheckboxCell, AvatarCell,
    TaskCell, TaskHeading, ActionButton, Title, Description, Meta, DotColumns, PlainSmall,
    DeadlineCell, Deadline,
    AssignedCell, Entities, Person,
    ActionsCell
} from './s'
import { Checklistbox } from 'components/form/input/checkbox'
import {
    AvatarButton, AvatarAddButton, AvatarConcernsButton,
    AvatarResponsibleButton, AvatarBuddyButton, AvatarSupervisorButton,
    AvatarGroupButton
} from 'components/avatar'
import Link from 'components/link'
import ConcernsUnit from 'pages/tasks/components/concerns-unit'
import AssignedToDynamicConcerns from './assigned-to-dynamic-concerns'
import AssignedToDynamicResponsible from './assigned-to-dynamic-responsible'
import AssignedToDynamicBuddy from './assigned-to-dynamic-buddy'
import AssignedToDynamicSupervisor from './assigned-to-dynamic-supervisor'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'
import { parseISO, isYesterday, isToday, isTomorrow, format, isAfter, endOfDay } from 'date-fns'
import { getAvatarUrl, getFullName } from 'utilities/person'
import { getPeopleProfileUrl, getGroupUrl } from 'utilities/url'
import { mergeRefs } from 'react-merge-refs'
import {
    processModes,
    processTemplateModes,
    processDraftModes,
    processStartedModes
} from 'lists/tasks'
import { getParentCaptionTranslation } from 'pages/tasks/modals/task/edit'
import { Lock } from 'styled-icons/feather'

const Task = forwardRef(({ task, toggleTaskCompleted, actions, header, mode = 'default', context = 'default', flash, ...props }, ref) => {
    const { formatMessage } = useIntl()

    const { dateLocale: locale } = useI18n()
    const { isItMyOwnId } = useMe()

    const flasher = useFlash(flash)

    const boundActions = getBoundActions(actions, task)

    const {
        title,
        description,
        dueAt,
        completedAt,
        createdBy,
        parent,
        assignedGroup,
        assignedTo
    } = task

    const completed = !!completedAt
    const displayDate = getDisplayDate(completedAt, dueAt, header, locale, formatMessage)
    const createdByMe = isItMyOwnId(createdBy?.id)
    const assignedToMe = isItMyOwnId(assignedTo?.id)

    const id = `person:task:${task?.id ?? uuid()}`
    const combinedRef = mergeRefs([flasher, ref])

    const primaryAction = actions?.view?.(task) ?? actions?.edit?.(task)

    const Action = !!primaryAction ?
        ActionButton :
        TaskHeading

    const viewProps = !!primaryAction ?
        { onClick: () => primaryAction.onClick() } :
        null

    const editProps = (assign = true) => !!actions?.edit ?
        { onClick: () => actions.edit(task).onClick(assign) } :
        null

    const canToggle = (!!actions?.toggleCompleted?.(task) && toggleTaskCompleted)

    const AssignedToPredefinedRole = {
        concerns: () => <AssignedToDynamicConcerns type={parent.type} />,
        responsible: () => <AssignedToDynamicResponsible type={parent.type} />,
        buddy: () => <AssignedToDynamicBuddy type={parent.type} />,
        supervisor: () => <AssignedToDynamicSupervisor type={parent.type} />
    }[assignedTo] ?? null

    const AvatarPredefinedRole = {
        concerns: () => <AvatarConcernsButton {...editProps()} />,
        responsible: () => <AvatarResponsibleButton {...editProps()} />,
        buddy: () => <AvatarBuddyButton {...editProps()} />,
        supervisor: () => <AvatarSupervisorButton {...editProps()} />
    }[assignedTo] ?? null

    return (
        <TaskWrapper
            {...props}
            ref={combinedRef}>
            {(
                [...processStartedModes, 'default'].includes(mode) ||
                context === 'meeting-event'
            ) && (
                <CheckboxCell>
                    {!!canToggle && (
                        <Checklistbox
                            checked={completed}
                            id={`${id}:toggle:completed`}
                            onChange={() => toggleTaskCompleted(task.id, !!(context === 'dashboard'))} />
                    )}
                    {!canToggle && <Lock size={20} />}
                </CheckboxCell>
            )}
            {[
                ...processTemplateModes,
                ...processDraftModes
            ].includes(mode) && (
                <AvatarCell>
                    {(!assignedGroup && !assignedTo) && (
                        <AvatarAddButton {...editProps()} />
                    )}
                    {(!!assignedGroup?.id && !assignedTo?.id) && (
                        <AvatarGroupButton
                            {...editProps()}
                            group={assignedGroup} />
                    )}
                    {((typeof assignedTo === 'string') && AvatarPredefinedRole) && (
                        <AvatarPredefinedRole />
                    )}
                    {(!!assignedTo?.id && !getAvatarUrl(assignedTo) && (parent?.concerns?.id === assignedTo?.id)) && (
                        <AvatarConcernsButton {...editProps()} />
                    )}
                    {(!!assignedTo?.id && !!getAvatarUrl(assignedTo) && (parent?.concerns?.id === assignedTo?.id)) && (
                        <AvatarButton
                            {...editProps()}
                            who={assignedTo} />
                    )}
                    {(!!assignedTo?.id && parent?.concerns?.id !== assignedTo?.id) && (
                        <AvatarButton
                            {...editProps()}
                            who={assignedTo} />
                    )}
                </AvatarCell>
            )}
            <TaskCell>
                <Action {...viewProps}>
                    <Title {...(completed ? { className: 'done' } : null)}>
                        {title}
                    </Title>
                    {!!description && (
                        <Description className="compact">
                            {description.replace(/\s+/, ' ')}
                        </Description>
                    )}
                </Action>
                {(displayDate && context === 'meeting-event') && (
                    <Deadline
                        className={cls([
                            displayDate?.overdue && 'overdue',
                            'compact'
                        ])}>
                        {displayDate.date}
                    </Deadline>
                )}
                {(!!createdBy && !createdByMe && !parent && context === 'default') && (
                    <Meta className="compact">
                        <FormattedMessage
                            id="task_created_by_title"
                            defaultMessage="Created by {user}"
                            values={{
                                user: (
                                    <Link
                                        to={getPeopleProfileUrl(createdBy)}
                                        target="_blank">
                                        {getFullName(createdBy)}
                                    </Link>
                                )
                            }} />
                    </Meta>
                )}
                {(!!createdBy && !!createdByMe && !parent && context === 'profile:colleague') && (
                    <Meta className="compact">
                        <FormattedMessage
                            id="task_created_by_me_title"
                            defaultMessage="Created by you" />
                    </Meta>
                )}
                {(!!parent && mode === 'default') && (
                    <Meta className="compact">
                        <FormattedMessage
                            {...getParentCaptionTranslation(parent, isItMyOwnId)}
                            values={{
                                user: <ConcernsUnit concerns={parent.concerns} />,
                                title: parent.title
                            }} />
                    </Meta>
                )}
                {(
                    !!parent &&
                    (processModes.includes(mode) || context === 'meeting-event') &&
                    (!!assignedGroup?.id || !!assignedTo?.id)
                ) && (
                    <Meta className="compact">
                        <FormattedMessage
                            id="employee_onboarding_template_assigned_to"
                            defaultMessage="<misty>Assigned to</misty> {who}"
                            values={{
                                misty: text => text,
                                who: (
                                    <DotColumns>
                                        <Link
                                            to={assignedGroup ?
                                                getGroupUrl(assignedGroup) :
                                                getPeopleProfileUrl(assignedTo)
                                            }
                                            target="_blank">
                                            {assignedGroup?.name ?? getFullName(assignedTo)}
                                        </Link>
                                        {(!!assignedGroup?.id && !!assignedTo?.id) && (
                                            <>
                                                <span>·</span>
                                                <Link
                                                    to={getPeopleProfileUrl(assignedTo)}
                                                    target="_blank">
                                                    {getFullName(assignedTo)}
                                                </Link>
                                            </>
                                        )}
                                    </DotColumns>
                                )
                            }} />
                    </Meta>
                )}
                {(
                    !!parent &&
                    processTemplateModes.includes(mode) &&
                    AssignedToPredefinedRole
                ) && <AssignedToPredefinedRole />}
                {(
                    !!parent &&
                    [
                        ...processTemplateModes,
                        ...processDraftModes
                    ].includes(mode) &&
                    !assignedGroup &&
                    !assignedTo
                ) && (
                    <Meta className="compact">
                        <PlainSmall
                            {...editProps()}
                            className="constructive">
                            <FormattedMessage
                                id="employee_boarding_action_assign_task"
                                defaultMessage="Assign task" />
                        </PlainSmall>
                    </Meta>
                )}
            </TaskCell>
            {(
                !processTemplateModes.includes(mode) &&
                context !== 'meeting-event'
            ) && (
                <DeadlineCell>
                    <Deadline {...(displayDate?.overdue ? { className: 'overdue' } : null)}>
                        {!!displayDate && displayDate.date}
                        {(!displayDate && context !== 'dashboard') && '—'}
                    </Deadline>
                </DeadlineCell>
            )}
            {(
                !processModes.includes(mode) &&
                !['dashboard', 'meeting-event'].includes(context)
            ) && (
                <AssignedCell>
                    {!!assignedGroup && (
                        <Entities
                            entities={[assignedGroup]}
                            preview={true}
                            showAggregateIcon={true}
                            className="small compact-aggregate"
                            salt={`${id}:${assignedGroup.type}s:group`} />
                    )}
                    {!!assignedTo && (
                        <Person
                            who={assignedTo}
                            asLink={true}
                            shortName={assignedToMe}
                            truncate={true}
                            size={16}
                            className="compact" />
                    )}
                </AssignedCell>
            )}
            {(!!boundActions?.length && context !== 'dashboard') && (
                <ActionsCell>
                    <ContextMenu
                        prebound actions={boundActions}
                        salt={id} />
                </ActionsCell>
            )}
        </TaskWrapper>
    )
})

const getDisplayDate = (completedAt, dueAt, header, locale, formatMessage) => {
    let date = completedAt || dueAt || null

    if(!date) {
        return date
    }

    if(typeof date === 'string') {
        date = parseISO(date)
    }

    const overdue = !!dueAt && isAfter(new Date(), endOfDay(date)) && !completedAt
    let formatted = format(date, 'PPP', { locale })

    if(isYesterday(date)) {
        formatted = formatMessage({
            id: 'yesterday',
            defaultMessage: 'Yesterday'
        })
    }

    if(isToday(date)) {
        formatted = formatMessage({
            id: 'today',
            defaultMessage: 'Today'
        })
    }

    if(isTomorrow(date)) {
        formatted = formatMessage({
            id: 'tomorrow',
            defaultMessage: 'Tomorrow'
        })
    }

    if(!header && !!completedAt) {
        formatted = formatMessage({
            id: 'task_completed_date',
            defaultMessage: `Completed: ${formatted}`
        }, { date: formatted })
    }

    return {
        date: formatted,
        overdue
    }
}

export default Task
import styled, { css } from 'styled-components'
import {
    svgStroke,
    belowPhone
} from 'utilities/styled'
import {
    Grid,
    GridColumnHeader as GridColumnHeaderBase,
    GridColumnHeaderSortable as GridColumnHeaderSortableBase
} from 'components/grid'
import TutorialBase from 'components/tutorial'

export const Wrap = styled.div``

export const Header = styled(Grid)`
    grid-template-columns: 2fr repeat(2, 1fr) 40px;
    column-gap: 24px;
    width: 100%;
    border-bottom: 1px solid var(--huma-color-border-default);

    ${belowPhone`
        justify-content: start;
        column-gap: 16px;
        grid-template-columns: repeat(4, 1fr);
    `}
`

const gridColumnHeaderCss = css`
    display: flex;
    align-items: center;

    height: 72px;
    padding: 24px 0;

    ${belowPhone`
        padding: 16px 0;
    `}
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}

    ${belowPhone`
        display: none;
    `}
`

export const GridColumnHeaderSortable = styled(GridColumnHeaderSortableBase)`
    ${gridColumnHeaderCss}

`

export const GridColumnActionsHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}
    justify-content: center;

    ${belowPhone`
        display: none;
    `}

    > svg {
        ${svgStroke()}
    }
`

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    position: relative;
`

export const Tutorial = styled(TutorialBase)`
    margin-top: 80px;

    ${belowPhone`
        margin-top: 40px;
    `}
`
import React from 'react'
import { useTheme, ThemeProvider } from 'styled-components'
import { Wrapper } from './s'
import Entry from './entry'

const Timeline = ({ entries = [], size = 24, salt, ...props }) => {
    let theme = useTheme()

    if(!entries.length) {
        return null
    }

    theme = {
        ...theme,
        policyTimeline: {
            size
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Wrapper {...props}>
                {entries.map(({ className, ...entry }, index) => (
                    <Entry
                        className={className}
                        entry={entry}
                        salt={`${salt}:entry:${entry.id}`}
                        key={entry.id ?? `${salt}:entry:empty:${index}`} />
                ))}
            </Wrapper>
        </ThemeProvider>
    )
}

export default Timeline

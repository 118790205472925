import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import LinkBase from 'components/link'
import Paragraph from 'components/typography/paragraph'
import { ancestorHover } from 'components/avatar/s'
import { Plain } from 'components/button'

export const Wrapper = styled(Grid)`
    grid-template-columns:
        [avatar] 40px
        [details] 1fr;
    grid-template-rows:
        [start] min-content
        [end] min-content;
    align-items: center;
    column-gap: 16px;
`

export const Link = styled(LinkBase)`
    ${ancestorHover()}

    display: grid;
    grid-template: subgrid / subgrid;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    gap: inherit;
    align-items: center;

    &:is(:hover, :focus-visible) {
        text-decoration: none;
    }
`

export const AvatarWrapper = styled.div`
    position: relative;
    isolation: isolate;

    grid-column: avatar;
    grid-row: 1 / -1;

    height: 40px;
    aspect-ratio: 1;

    svg {
        ${svgStroke('small')}
    }
`

export const Name = styled(Paragraph)`
    grid-column: details;
    grid-row: start;

    ${Link}:hover & {
        text-decoration: underline;
    }

    &.deactivated {
        color: var(--huma-color-foreground-subtle);
    }
`

export const Meta = styled(Paragraph)`
    grid-column: details;
    grid-row: end;
`

export const SidePanelButton = styled(Plain)`
    display: inline-block;
`
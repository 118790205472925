import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import { useStorage } from 'hooks/storage'
import { getPeopleProfileUrl } from 'utilities/url'
import { getShortName, getEmailAddress } from 'utilities/person'
import { size } from 'utilities/object'
import { compact } from 'utilities/array'
import queryString from 'query-string'
import {
    ImportantColumn, Important,
    Wrapper, ArrowRight, DismissButton, ShowDetails,
    ProgressWrapper, Buffer, ProgressBar,
    Emoji, TierMessage, ImportantMessage
} from './s'
import Link from 'components/link'
import Paragraph from 'components/typography/paragraph'
import { Scrollable as Modal } from 'modals/generic'
import Summary from './summary'
import { X } from 'styled-icons/feather'

const ProfileCompletion = ({ person, lookingAtProfile = false, important = false, ...props }) => {
    const { formatMessage } = useIntl()

    const {
        id,
        lastLoggedInAt
    } = person

    const { isItMyOwnId, me } = useMe()
    const isMe = id ? isItMyOwnId(id) : true

    const [dismissed, setDismissed] = useStorage({
        key: `dismissed:profile-completion:${id ?? 'me'}`,
        defaultValue: false
    })

    const [completionMeta, setCompletionMeta] = useState(null)
    const [showingDetails, setShowingDetails] = useState(false)

    useEffect(() => {
        if(isMe && !!person) {
            setCompletionMeta(calculateCompletionMeta(person))
        } else if (!!person?.completeness) {
            setCompletionMeta(getCompletionMeta(person.completeness))
        }
    }, [person])

    if(!person || !completionMeta || dismissed) {
        return null
    }

    const getReminderLink = () => {
        const subject = formatMessage({
            id: 'profile_completion_fill_huma_profile_subject',
            defaultMessage: 'Please fill out your Huma profile'
        })
        const body = formatMessage({
            id: 'profile_completion_fill_huma_profile_body'
        }, {
            recipient: getShortName(person),
            sender: getShortName(me)
        })

        return queryString.stringifyUrl({
            url: `mailto:${getEmailAddress(person)}`,
            query: {
                subject,
                body
            }
        })
    }

    const wrap = content => !!important ? (
        <ImportantColumn>
            <Important {...((typeof important === 'string') ? { className: important } : null)}>
                {content}
            </Important>
        </ImportantColumn>
    ) : content

    return wrap(
        <Wrapper {...props}>
            <Paragraph className="compact">
                <FormattedMessage
                    id="profile_completion_heading"
                    defaultMessage="Profile completion" />
                <ShowDetails onClick={() => setShowingDetails(true)} />
            </Paragraph>
            {(lookingAtProfile && completionMeta.important === 'complete') && (
                <DismissButton onClick={() => setDismissed(true)}>
                    <X size={24} />
                </DismissButton>
            )}
            {(isMe && !lookingAtProfile) && (
                <Link to={getPeopleProfileUrl({ id: 'me' })}>
                    <ArrowRight size={24} />
                </Link>
            )}
            <ProgressWrapper>
                <Buffer role="progressbar">
                    <ProgressBar $value={completionMeta.progress} />
                </Buffer>
                <Emoji $flush={completionMeta.progress}>
                    <FormattedMessage
                        id={`profile_completion_emoji_tier_${completionMeta.tier}`}
                        defaultMessage="🙁" />
                </Emoji>
            </ProgressWrapper>
            {!!lastLoggedInAt && (
                <TierMessage className="compact">
                    <FormattedMessage
                        id={compact([
                            'profile_completion',
                            isMe ? 'me' : 'manager',
                            'helper_tier',
                            completionMeta.tier,
                            (isMe && completionMeta.tier === 2) && 'web'
                        ]).join('_')}
                        defaultMessage="A stronger profile will help others."
                        values={{
                            info: (
                                <Link
                                    className="constructive"
                                    onClick={() => setShowingDetails(true)}>
                                    {formatMessage({
                                        id: `profile_completion_incomplete_link_tier_${completionMeta.tier}`,
                                        defaultMessage: 'important information'
                                    })}
                                </Link>
                            ),
                            link: (
                                <Link
                                    className="constructive"
                                    onClick={() => setShowingDetails(true)}>
                                    {formatMessage({
                                        id: `profile_completion_incomplete_link_tier_${completionMeta.tier}`,
                                        defaultMessage: 'See what’s left'
                                    })}
                                </Link>
                            ),
                            name: getShortName(person),
                            highlight: chunks => <ImportantMessage>{chunks}</ImportantMessage>
                        }} />
                    {(!isMe && completionMeta.tier < 5) && (
                        <>
                            {' '}
                            <Link
                                className="constructive"
                                href={getReminderLink()}>
                                <FormattedMessage
                                    id="action_send_reminder"
                                    defaultMessage="Send reminder" />
                            </Link>
                        </>
                    )}
                </TierMessage>
            )}
            <Modal
                show={showingDetails}
                dismiss={() => setShowingDetails(false)}>
                <Summary
                    meta={completionMeta}
                    person={person}
                    dismiss={() => setShowingDetails(false)} />
            </Modal>
        </Wrapper>
    )
}

export default ProfileCompletion

export const MyProfileCompletion = props => {
    const { me } = useMe()

    if(!me) {
        return null
    }

    return (
        <ProfileCompletion
            {...props}
            person={me} />
    )
}

const getCompletionMeta = ({ important, details }) => {
    const importantComplete = important.filled === important.available
    const importantIncomplete = important.filled > 0
    const detailsComplete = details.filled === details.available
    const detailsIncomplete = details.filled > 0

    const totalFilled = important.filled + details.filled
    const totalAvailable = important.available + details.available
    const progress = parseInt(totalFilled / totalAvailable * 100)

    const meta = {
        important: importantComplete ? 'complete' : importantIncomplete ? 'partial' : 'missing',
        details: detailsComplete ? 'complete' : detailsIncomplete ? 'partial' : 'missing',
        progress
    }

    if(importantComplete && detailsComplete) {
        return {
            ...meta,
            tier: 5
        }
    }

    if(importantComplete && detailsIncomplete) {
        return {
            ...meta,
            tier: 4
        }
    }

    if(importantComplete && !detailsIncomplete) {
        return {
            ...meta,
            tier: 3
        }
    }

    if(progress >= 40) {
        return {
            ...meta,
            tier: 2
        }
    }

    return {
        ...meta,
        tier: 1
    }
}

const calculateCompletionMeta = profile => {
    profile = structuredClone(profile)

    if(!!profile.jobTitle?.value?.description && !!profile.jobDescription) {
        profile.jobDescription.value = profile.jobTitle.value.description
    }

    const fieldValues = Object.values(profile)
    const fieldValuesICanEdit = fieldValues.filter(field => !!(field.editable))
    const importantFields = fieldValuesICanEdit.filter(field => field?.completeness === 'important')
    const completionDetailsFields = fieldValuesICanEdit.filter(field => field?.completeness === 'details')

    const checkValue = (count, field) => count + (!!hasValue(field.value) ? 1 : 0)
    const count = (array, predicate) => array.reduce(predicate, 0)

    return getCompletionMeta({
        important: {
            filled: count(importantFields, checkValue),
            available: importantFields.length
        },
        details: {
            filled: count(completionDetailsFields, checkValue),
            available: completionDetailsFields.length
        }
    })
}

export const hasValue = value => {
    if(!value && value !== 0) {
        return false
    }

    if(/^\d+$(\.\d+)?/.test(value) && Number.isFinite(Number(value))) {
        value = Number(value)
    }

    if(Array.isArray(value)) {
        return !!value.length
    }

    if(typeof value === 'string') {
        return !!value
    }

    if(typeof value === 'number') {
        if(Number.isFinite(Number(value))) {
            value = Number(value)
        }

        return !!value || value === 0
    }

    if(typeof value === 'object') {
        return !!size(value)
    }
}
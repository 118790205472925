import styled, { css } from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Grid, GridColumnHeader as GridColumnHeaderBase } from 'components/grid'
import { compact } from 'utilities/array'

export const Wrap = styled.div``

export const Header = styled(Grid)`
    width: 100%;
    border-bottom: 1px solid var(--huma-color-border-default);

    ${({ theme: { workSchedules: { actions } } }) => css`
        grid-template-columns: ${compact(['3fr', '2fr', '1fr', actions && '40px']).join(' ')};
        column-gap: 24px;
    `}

    ${belowTablet`
        display: none;
    `}
`

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 96px;
    position: relative;
`

const gridColumnHeaderCss = css`
    padding: 16px 0;
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}
`

export const GridColumnActionsHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}
`

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay, Symbol } from 'components/form/field/s'
import { Columns, IconColumn } from './s'
import { getEntityIcon } from 'components/entity'
import { Name as RoleName } from 'components/role'
import { Type as CustomFieldType } from 'pages/settings/pages/custom-profile-fields/components/type'
import { symbol as competenceFallbackSymbol } from 'pages/competence'
import { symbol as equipmentFallbackSymbol } from 'pages/equipment'

const ViewEntity = ({ className, label, ...props }) => {
    const {
        field = {},
        entity = {}
    } = props

    const { value = null } = field
    const { type } = entity
    const EntityIcon = getEntityIcon(type)

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <ValueDisplay {...(!value ? { className: 'empty' } : null)}>
                    <Columns>
                        <span>
                            {(!!value && type === 'customFieldType') && (
                                <CustomFieldType
                                    field={{
                                        ...value,
                                        type: value?.id
                                    }}
                                    size={40} />
                            )}
                            {(type === 'competenceType') && (
                                <Symbol
                                    symbol={value?.symbol ?? competenceFallbackSymbol}
                                    size={26} />
                            )}
                            {(type === 'equipmentType') && (
                                <Symbol
                                    symbol={value?.symbol ?? equipmentFallbackSymbol}
                                    size={26} />
                            )}
                            {(!!value && type === 'role') && (
                                <RoleName {...value} />
                            )}
                            {(!!value && type !== 'role') && value.name}
                            {(!!value?.emoji && type === 'country') && value.emoji}
                            {!value && (
                                <FormattedMessage
                                    id="empty_not_picked"
                                    defaultMessage="Not picked" />
                            )}
                        </span>
                        {!!EntityIcon && (
                            <IconColumn>
                                <EntityIcon size={20} />
                            </IconColumn>
                        )}
                    </Columns>
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewEntity
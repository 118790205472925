import styled, { css } from 'styled-components'
import AvatarBase from 'components/avatar'

export const ConcernsGroup = styled.div`
    width: 40px;
    height: 40px;
    position: relative;
    isolation: isolate;

    .regarding {
        top: 0;
        left: 0;
    }

    .host {
        z-index: 1;
        bottom: 0;
        right: 0;
    }
`

export const Participants = styled.div`
    width: 40px;
    height: 40px;
    position: relative;
    isolation: isolate;

    .host {
        z-index: 1;
    }

    ${p => (p.$count === 2) ?
        css`
            .host {
                top: 0;
                left: 0;
            }

            .attendee {
                bottom: 0;
                right: 0;
            }
        ` :
        css`
            .host {
                top: 0;
                left: 6px;
            }

            .attendee:nth-of-type(2) {
                bottom: 0;
                left: 0;
            }

            .attendee:last-of-type {
                right: 0;
                bottom: 6px;
            }
        `
    }
`

export const Avatar = styled(AvatarBase)`
    overflow: visible;

    ${ConcernsGroup} &,
    ${Participants} & {
        height: auto;
        position: absolute;

        .avatar {
            box-shadow: 0 0 0 2px var(--huma-color-surface-default);
        }
    }
`
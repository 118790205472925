import React from 'react'
import styled, { css } from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { Grid } from 'components/grid'
import Paragraph from 'components/typography/paragraph'
import { Columns } from 'components/flex'

export const CommentWrapper = styled(Grid)`
    column-gap: 24px;

    padding: 16px 0;
    box-shadow: 0 1px var(--huma-color-border-default);

    ${({ theme }) => {
        if(!!theme?.tasks?.actions) {
            return css`
                grid-template-columns: 1fr 3fr 40px;
            `
        }

        return css`
            grid-template-columns: 1fr 3fr;
        `
    }}

    ${belowTablet`
        grid-template-columns: none;
        grid-auto-rows: auto;
        grid-auto-flow: row;
    `}
`

export const CategoryCell = styled.div``

const CategoryWrapper = styled.div`
    position: relative;
    justify-self: start;

    height: 24px;
    padding-inline-start: 16px;

    color: var(--huma-color-foreground-default);

    span {
        line-height: 24px;
    }
`

const Indicator = styled.div`
    position: absolute;
    top: 8px;
    left: 0;

    border-radius: 100%;
    width: 8px;
    height: 8px;

    &.promoter {
        background-color: var(--huma-color-surface-success-bold);
    }

    &.passive {
        background-color: var(--huma-color-surface-warning-bold);
    }

    &.detractor {
        background-color: var(--huma-color-surface-error-bold);
    }
`

export const Category = ({ label, category }) => (
    <CategoryWrapper>
        <Indicator className={`${category}`} />
        <span>{label}</span>
    </CategoryWrapper>
)

export const CommentCell = Paragraph

export const ActionsCell = styled(Columns)`
    ${belowTablet`
        grid-area: actions;
    `}

    .actions-button {
        margin-block: -8px;

        ${belowTablet`
            margin-block: 0;
        `}
    }
`

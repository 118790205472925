import styled, { css } from 'styled-components'

export const constrainerCss = css`
    display: grid;
    grid-template-columns:
        [full-start] 1fr
        [center-start] min(60ch, calc(100% - 32px)) [center-end]
        1fr [full-end];

    > * {
        grid-column: full;
    }

    &.centered > * {
        grid-column: center;

        &.blowout {
            display: grid;
            grid-template-columns: subgrid;
            grid-column: full;
        }
    }

    &.disabled {
        opacity: 0.7;
    }
`

export default styled.div`
    ${constrainerCss}
`

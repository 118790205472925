import styled from 'styled-components'
import { belowTablet, fromTablet } from 'utilities/styled'

export const BelowTablet = styled.div`
    ${fromTablet`
        display: none;
    `}
`

export const FromTablet = styled.div`
    ${belowTablet`
        display: none;
    `}
`
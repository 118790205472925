import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Wrap, CaptionSmall, Colors, Color } from './s'
import { Check } from 'styled-icons/feather'

const ColorPicker = ({ colorToken: currentColorToken, pickColor, salt }) => {
    salt = `${salt}:color-picker`

    return (
        <Wrap>
            <CaptionSmall>
                <FormattedMessage
                    id="symbol_picker_color_caption"
                    defaultMessage="Pick a background color" />
            </CaptionSmall>
            <Colors>
                {colorTokens.map(token => {
                    const selected = token === currentColorToken?.toLowerCase()

                    return (
                        <Color
                            {...(selected ? { className: 'selected' } : null)}
                            onClick={() => pickColor(token)}
                            style={{ '--huma-component-color-picker-token': `var(--huma-color-${token})` }}
                            key={`${salt}:color:${token}`}>
                            {!!selected && <Check size={16} />}
                        </Color>
                    )
                })}
            </Colors>
        </Wrap>
    )
}

const colorTokens = [
    'symbol-yellow-1',
    'symbol-skin-1',
    'symbol-skin-2',
    'symbol-green-1',
    'symbol-blue-1',
    'symbol-red-1',
    'symbol-purple-1',
    'symbol-neutral-1',
    'symbol-neutral-2'
]

export default ColorPicker
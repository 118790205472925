import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDeviationCases } from 'contexts/deviation-cases'
import { size } from 'utilities/object'
import {
    containerProps,
    CardList,
    SkeletonListRow, SkeletonCell,
    LoadingContainer
} from './s'
import Case from './case'
import {
    useSkeletonLoader,
    SkeletonStrings, SkeletonStatus, SkeletonAvatarAndFullName
} from 'components/skeleton'
import { Static as Empty } from 'components/empty'
import { Ghost } from 'components/button'
import Loader from 'components/loader'
import { Scrollable as Modal } from 'modals/generic'
import EditHandler from 'pages/deviation/pages/case/reported/fields/edit/handler'

const DeviationCases = () => {
    const {
        kases = [],

        intersecter,
        loading,
        paging = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched,
        filter = {}
    } = useDeviationCases()

    const skeletonLength = useSkeletonLoader(kases, hasFetched)
    const [editingHandler, setEditingHandler] = useState(null)

    const filtersApplied = !!size(filter)
    const noCases = !!hasFetched && !kases.length
    const noFilteredCases = !!noCases && !!filtersApplied

    if(noFilteredCases) {
        return (
            <Empty>
                <FormattedMessage
                    id="incident_cases_empty_filter_message"
                    defaultMessage="🤷 Sorry, no cases were found." />
            </Empty>
        )
    }

    if(noCases) {
        return (
            <Empty>
                <FormattedMessage
                    id="incidents_unresolved_message_empty"
                    defaultMessage="No cases have been reported." />
            </Empty>
        )
    }

    return (
        <>
            <CardList {...containerProps}>
                <CardList.Header>
                    <CardList.Cell $cell="meta">
                        <FormattedMessage
                            id="noun_category"
                            defaultMessage="Category" />
                    </CardList.Cell>
                    <CardList.Cell $cell="status">
                        <FormattedMessage
                            id="noun_status"
                            defaultMessage="Status" />
                    </CardList.Cell>
                    <CardList.Cell $cell="risk">
                        <FormattedMessage
                            id="incident_label_severity"
                            defaultMessage="Severity" />
                    </CardList.Cell>
                    <CardList.Cell $cell="handler">
                        <FormattedMessage
                            id="incidents_label_assigned_to"
                            defaultMessage="Assigned to" />
                    </CardList.Cell>
                </CardList.Header>
                {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow
                        columns={4}
                        key={`list:deviation:case:skeleton:${index}`}>
                        <SkeletonCell $cell="meta">
                            <SkeletonStrings
                                size={20}
                                length={16} />
                        </SkeletonCell>
                        <SkeletonCell $cell="status">
                            <SkeletonStatus />
                        </SkeletonCell>
                        <SkeletonCell $cell="risk">
                            <SkeletonStatus />
                        </SkeletonCell>
                        <SkeletonCell $cell="handler">
                            <SkeletonAvatarAndFullName size={16} />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {kases.map((kase, index) => {
                    const last = index + 1 === kases.length

                    return (
                        <Case
                            kase={kase}
                            setEditingHandler={setEditingHandler}
                            {...(last ? { ref: intersecter } : null)}
                            key={kase.id} />
                    )
                })}
            </CardList>
            {!!paging?.hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost
                            className="constructive"
                            onClick={fetch}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
            <Modal
                show={!!editingHandler}
                dismiss={() => setEditingHandler(null)}>
                <EditHandler
                    caseId={editingHandler}
                    dismiss={() => setEditingHandler(null)}
                    onDone={() => setEditingHandler(null)} />
            </Modal>
        </>
    )
}

export default DeviationCases

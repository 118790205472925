import styled from 'styled-components'
import { belowTablet } from 'utilities/styled'
import { policyGridCss } from './policy/s'
import { Grid, GridColumnHeader as GridColumnHeaderBase } from 'components/grid'

export const LoadingContainer = styled.div`
    position: relative;

    height: 72px;
`

export const Wrap = styled.div``

export const Header = styled(Grid)`
    ${policyGridCss}

    width: 100%;
    box-shadow: 0 1px var(--huma-color-border-default);

    ${belowTablet`
        display: none;
    `}
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    padding: 16px 0;
`
import React, { forwardRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { mergeRefs } from 'react-merge-refs'
import { useI18n } from 'contexts/i18n'
import { useMe } from 'contexts/me'
import { useFlash } from 'hooks/flash'
import { useNavigate } from 'react-router-dom'
import { getMeetingEventUrl } from 'utilities/url'
import { getFullName } from 'utilities/person'
import { dateFormatter } from 'components/date-range'
import {
    EventItem,
    MeetingCell, MeetingLink, MeetingFragment, Symbol, Meta, Title,
    StatusCell,
    DateCell,
    ActionsCell
} from './s'
import Link from 'components/link'
import { fallbackSymbol } from 'pages/meetings'
import CompleteAtStatus from 'pages/meetings/components/complete-at-status'
import Avatars from './avatars'
import Participants from './participants'
import MeetingStatus from 'pages/meetings/components/status'
import { Plain } from 'components/button'
import ContextMenu, { getActionsArray } from 'widgets/context-menu'

const MeetingEvent = forwardRef(({ event, actions = {}, from, flash }, ref) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    const { isItMyOwnId } = useMe()

    const flasher = useFlash(flash)

    const navigate = useNavigate()

    const {
        id,
        symbol,
        title: meetingTitle,
        date,
        host,
        concerns,
        attendees = [],
        status
    } = event

    const participants = {
        host,
        concerns,
        attendees
    }

    const salt = `meeting:event:${id}`

    const amITheHost = isItMyOwnId(host.id)
    const amIAttending = attendees.some(({ id }) => isItMyOwnId(id))
    const canAccessMeeting = amITheHost || (amIAttending && status !== 'draft')

    const showSymbol = (!concerns && (!attendees?.length || attendees.length > 3))

    const title = concerns ?
        `${meetingTitle} · ${getFullName(concerns)}` :
        meetingTitle

    const MeetingWrapper = canAccessMeeting ?
        MeetingLink :
        MeetingFragment

    const meetingProps = canAccessMeeting ?
        {
            to: {
                pathname: getMeetingEventUrl({ id }),
                ...(from ? { state: { from } } : null)
            }
        } :
        null

    return (
        <EventItem ref={mergeRefs([ref, flasher])}>
            <MeetingCell>
                <MeetingWrapper {...meetingProps}>
                    {showSymbol && (
                        <Symbol
                            symbol={symbol ?? fallbackSymbol}
                            className="hover-effect" />
                    )}
                    {!showSymbol && <Avatars {...participants} />}
                    <Meta>
                        <Title className="compact">{title}</Title>
                        <Participants {...participants} />
                        {amITheHost && <CompleteAtStatus {...event} />}
                    </Meta>
                </MeetingWrapper>
            </MeetingCell>
            <StatusCell>
                <MeetingStatus status={status} />
            </StatusCell>
            <DateCell>
                {!date && (
                    <>
                        {(status !== 'closed' && amITheHost) && (
                            <Plain
                                className="constructive"
                                size="small"
                                onClick={() => navigate(getMeetingEventUrl({ id }), {
                                    state: {
                                        intent: 'set-date',
                                        ...(from ? { from } : null)
                                    }
                                })}>
                                <FormattedMessage
                                    id="meeting_action_set_date"
                                    defaultMessage="Set meeting date" />
                            </Plain>
                        )}
                        {!amITheHost && (
                            <span>
                                <FormattedMessage
                                    id="empty_date_not_set"
                                    defaultMessage="Date not set" />
                            </span>
                        )}
                        {canAccessMeeting && (
                            <Link
                                to={getMeetingEventUrl({ id })}
                                className="constructive"
                                {...(from ? { state: { from } } : null)}>
                                <FormattedMessage
                                    id="meeting_action_navigate"
                                    defaultMessage="Go to meeting" />
                            </Link>
                        )}
                    </>
                )}
                {!!date && dateFormatter(formatMessage, locale)({
                    date,
                    dateFormat: 'PPP'
                })}
            </DateCell>
            {(!!getActionsArray(actions)?.length && amITheHost) && (
                <ActionsCell>
                    <ContextMenu
                        salt={salt}
                        context={event}
                        actions={actions} />
                </ActionsCell>
            )}
        </EventItem>
    )
})

export default MeetingEvent

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useOrganization } from 'contexts/organization'
import { useGetCompanyDomain } from 'utilities/url'
import { Layout, Content, Action } from './s'
import Label from 'components/typography/caption'
import Text from 'components/typography/paragraph'
import Link from 'components/link'

const HumaDomain = () => {
    const { organization = {} } = useOrganization()

    const humaDomain = useGetCompanyDomain()(organization?.key)
    const [companyDomain, ...host] = humaDomain.split('.')

    return (
        <Layout>
            <Label className="compact">
                <FormattedMessage
                    id="noun_huma_domain"
                    defaultMessage="Huma domain" />
            </Label>
            <Content>
                <Text className="compact">
                    <strong>{companyDomain}</strong>
                    {`.${host.join('.')}`}
                </Text>
            </Content>
            <Action>
                <Text className="compact">
                    <Link
                        to="path:settings.base"
                        state={{ intent: 'change-organization-details' }}
                        className="constructive">
                        <FormattedMessage
                            id="action_change"
                            defaultMessage="Change" />
                    </Link>
                </Text>
            </Action>
        </Layout>
    )
}

export default HumaDomain

import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useSmartEntity } from 'contexts/smart-entity'
import { prune } from 'utilities/array'

export const useItemSearchPhraseEnricher = () => {
    const { formatMessage } = useIntl()

    const {
        values,
        getValue
    } = useSmartEntity()

    return useCallback(({ source, field }) => {
        const sourceLabel = (typeof source.label === 'string') ?
            source.label :
            formatMessage?.(source.label)

        const fieldLabel = (typeof field.label === 'string') ?
            field.label :
            formatMessage?.(field.label)

        const value = getValue(source)
        const id = value?.id ?? null

        const attributes = {
            sourceType: source.type,
            sourceName: source.name,
            fieldName: field.name
        }

        if(value) {
            attributes.id = id
            attributes.value = field.formatter(value)

            if(!!field.cursor && !attributes.cursor) {
                const options = field.getOptions?.(value)
                if(options?.length === 1) {
                    attributes.cursor = options[0][field.cursor]
                }
            }
        }

        const sourceDisplayName = (source.name === 'responsible') ?
            'loggedInUser' :
            source.name

        return {
            source,
            sourceDisplayName,
            field,
            value,
            attributes,
            label: {
                source: sourceLabel,
                field: fieldLabel
            },
            indexing: {
                compoundLabel: `${sourceLabel.replace(fieldLabel, '')} ${fieldLabel.replace(sourceLabel, '')}`,
                words: prune(prune([
                    field.label?.defaultMessage,
                    source.label?.defaultMessage,
                    field.name,
                    sourceDisplayName,
                    source.type
                ]).map(word => word.toLowerCase()))
            }
        }
    }, [formatMessage, values])
}
import React from 'react'
import { useIntl } from 'react-intl'
import { useReveal } from 'hooks/reveal'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { FormattedAddress } from 'components/address'
import { cls } from 'utilities/dom'
import { size } from 'utilities/object'

const ViewAddress = ({ className, label, salt, ...props }) => {
    const { formatMessage } = useIntl()

    const {
        field = {},
        obscure = false
    } = props

    const { value = {} } = field
    let fallback = null
    const [node, reveal] = useReveal(obscure)

    let displayClassName = ['single']

    if(size(value ?? {})) {
        displayClassName = cls([
            ...displayClassName,
            obscure && 'obscured',
            reveal && 'revealed'
        ])
    } else {
        fallback = formatMessage({
            id: 'empty_not_entered',
            defaultMessage: 'Not entered'
        })

        displayClassName = cls([
            ...displayClassName,
            'empty'
        ])
    }

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <ValueDisplay
                    className={displayClassName}
                    ref={node}>
                    {!fallback && (
                        <FormattedAddress
                            address={value}
                            salt={salt} />
                    )}
                    {!!fallback && fallback}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewAddress

import styled from 'styled-components'
import { Grid as GridBase } from 'components/grid'
import { SkeletonListRow as SkeletonListRowBase } from 'components/skeleton'
import Paragraph from 'components/typography/paragraph'

export const Wrap = styled.div``

export const Grid = GridBase

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 176px;
    position: relative;
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    grid-template-columns: 3fr 4fr minmax(100px,max-content) 40px;

    &:first-child {
        box-shadow: none;
    }
`

export const EmptyText = styled(Paragraph)`
    margin: 0 0 32px;
`
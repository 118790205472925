import React, { useState, useRef, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import { useLocation, useNavigate } from 'react-router-dom'
import { get } from 'api'
import { getFullName, getListRepresentationFromProfile } from 'utilities/person'
import { omit } from 'utilities/object'
import { compact, pruneBy } from 'utilities/array'
import { cls } from 'utilities/dom'
import { Plain } from 'components/button'
import { Filter, Avatar, Icon, Arrow, Widget, WidgetContentScroller, MobileHeader, ResetWrap } from '../s'
import { Slash } from 'styled-icons/feather'
import Form from 'components/form/controller'
import Field from 'components/form/field/radiobuttons'
import { FieldValue } from './s'
import Loader, { LoaderWrap } from 'components/loader'
import ListAvatar from 'components/avatar'

const SupervisorField = ({ filter, setFilter, allResetAt, name, label, field = null, salt }) => {
    const { formatMessage } = useIntl()

    const getDefaultValue = () => field?.defaultValue ?? null
    const getValue = () => filter[name] ?? getDefaultValue()

    const {
        me,
        isItMyOwnId
    } = useMe()

    const location = useLocation()
    const navigate = useNavigate()

    const [previousAllResetAt, setPreviousAllResetAt] = useState(allResetAt)
    const [configuring, setConfiguring] = useState(false)
    const [hasInteracted, setHasInteracted] = useState(false)

    const [supervisors, setSupervisors] = useState([])
    const [fetching, setFetching] = useState(false)
    const [hasFetched, setHasFetched] = useState(false)

    const [supervisor, setSupervisor] = useState((!!me && getValue() === 'me') ?
        getListRepresentationFromProfile(me) :
        null
    )

    const [noSupervisor, setNoSupervisor] = useState(getValue() === 'none')

    const { resettable = true } = field ?? {}

    useEffect(() => {
        if(allResetAt !== previousAllResetAt) {
            reset({ silent: true })
            setPreviousAllResetAt(allResetAt)
        }
    }, [allResetAt, previousAllResetAt])

    const control = useRef()
    const widget = useRef()

    // Resolve my avatar on page load
    useEffect(() => {
        if(!!me && filter[name] === 'me' && !supervisor) {
            setSupervisor(getListRepresentationFromProfile(me))
        }
    }, [me?.id, filter[name], supervisor])

    // Resolve another user’s avatar on page load
    useEffect(() => {
        if(!!filter[name] && !['me', 'none'].includes(filter[name]) && !supervisor) {
            const id = filter[name]

            const fetch = async () => {
                const { ok, response: supervisor } = await get({ path: `/users/${id}` })

                if(ok && supervisor) {
                    setSupervisor(getListRepresentationFromProfile(supervisor))
                }
            }

            fetch()
        }
    }, [filter[name], supervisor])

    useEffect(() => {
        if(configuring && !hasFetched) {
            const fetch = async supervisors => {
                setFetching(true)

                const { ok, response } = await get({
                    path: '/users',
                    params: {
                        offset: supervisors.length,
                        isSupervisor: true
                    }
                })

                setFetching(false)

                if(ok && response) {
                    supervisors = pruneBy([
                        ...supervisors,
                        ...response.items
                    ])

                    setSupervisors(supervisors)

                    if(supervisors.length < response.total) {
                        await fetch(supervisors)
                    } else {
                        setHasFetched(true)
                    }
                }
            }

            fetch([])
        }
    }, [configuring, hasFetched])

    const reset = (options = {}) => {
        if(name in (location.state?.filter ?? {})) {
            navigate({
                ...location,
                state: {
                    ...location.state,
                    filter: omit(location.state.filter, name)
                },
                replace: true
            })
        }

        const { silent = false } = options
        !silent && setFilter({ [name]: null })

        setSupervisor(null)
        setNoSupervisor(false)
    }

    const filterClassName = cls([
        'constructive',
        configuring && 'active'
    ])

    return (
        <>
            <Filter
                className={filterClassName}
                onClick={() => {
                    setConfiguring(configuring => !configuring)
                    setHasInteracted(true)
                }}
                ref={control}>
                {label}
                {!!supervisor && (
                    <Icon>
                    <Avatar
                        who={supervisor}
                        size={20}
                        key={`${salt}:supervisor:avatar:${supervisor.id}`} />
                    </Icon>
                )}
                {!!noSupervisor && (
                    <Icon>
                        <Slash size={20} />
                    </Icon>
                )}
                <Arrow
                    {...(configuring ? { className: 'active' } : null)}
                    size={16} />
            </Filter>
            <Widget
                show={configuring}
                clickOutside={{
                    inside: [control],
                    action: () => setConfiguring(!configuring)
                }}
                position={{
                    origin: control,
                    direction: {
                        x: {
                            where: 'inside',
                            to: 'right'
                        },
                        y: {
                            where: 'outside',
                            to: 'down',
                            adjust: 8
                        }
                    }
                }}
                closeButton={false}
                constrain={true}
                blocking={true}
                salt={`${salt}:${salt}:widget`}
                ref={widget}>
                <MobileHeader>
                    <Plain
                        className="constructive"
                        onClick={() => setConfiguring(false)}>
                        <FormattedMessage
                            id="action_done"
                            defaultMessage="Done" />
                    </Plain>
                    <Plain
                        className="destructive"
                        onClick={reset}
                        disabled={!resettable || !getValue()}>
                        <FormattedMessage
                            id="action_reset"
                            defaultMessage="Reset" />
                    </Plain>
                </MobileHeader>
                <WidgetContentScroller>
                    <Form layout="vertical">
                        {!!supervisors.length && (
                            <Field
                                salt={salt}
                                name={name}
                                className="compact"
                                label={false}
                                direction="rtl"
                                field={{
                                    ...field,
                                    value: getValue()
                                }}
                                options={compact([
                                    {
                                        value: 'none',
                                        name: (
                                            <FieldValue>
                                                <Slash size={24} />
                                                <span>
                                                    <FormattedMessage
                                                        id="people_filter_no_supervisor"
                                                        defaultMessage="No supervisor" />
                                                </span>
                                            </FieldValue>
                                        ),
                                        checked: filter[name] === 'none'
                                    },
                                    !!supervisors.find(({ id }) => isItMyOwnId(id)) && ({
                                        value: 'me',
                                        name: (
                                            <FieldValue>
                                                <ListAvatar
                                                    who={me}
                                                    size={24} />
                                                <span>
                                                    {`${getFullName(me)} (${formatMessage({
                                                        id: 'noun_you',
                                                        defaultMessage: 'You'
                                                    })})`}
                                                </span>
                                            </FieldValue>
                                        ),
                                        checked: filter[name] === 'me'
                                    }),
                                    ...supervisors
                                        .filter(({ id }) => !isItMyOwnId(id))
                                        .map(supervisor => ({
                                            value: supervisor.id,
                                            name: (
                                                <FieldValue>
                                                    <ListAvatar
                                                        who={supervisor}
                                                        size={24} />
                                                    <span>{getFullName(supervisor)}</span>
                                                </FieldValue>
                                            ),
                                            checked: filter[name] === supervisor.id
                                        }))
                                ])}
                                onChange={({ [name]: value }) => {
                                    if(!hasInteracted) {
                                        return
                                    }

                                    setFilter({ [name]: value })

                                    const supervisor = (value === 'me') ?
                                        getListRepresentationFromProfile(me) :
                                        supervisors.find(({ id }) => id === value)

                                    setSupervisor(supervisor)
                                    setNoSupervisor(value === 'none')
                                }}
                                whistle={getValue()}
                                key={[
                                    salt,
                                    name,
                                    getValue(),
                                    allResetAt,
                                    configuring,
                                    hasFetched
                                ].join(':')} />
                        )}
                        {!!fetching && (
                            <LoaderWrap>
                                <Loader />
                            </LoaderWrap>
                        )}
                    </Form>
                </WidgetContentScroller>
                {(!!getValue() && resettable) && (
                    <ResetWrap>
                        <Plain
                            className="destructive"
                            onClick={reset}>
                            <FormattedMessage
                                id="action_reset"
                                defaultMessage="Reset" />
                        </Plain>
                    </ResetWrap>
                )}
            </Widget>
        </>
    )
}

export default SupervisorField
import styled from 'styled-components'
import {
    buttonOutlineProps,
    bp, belowTablet, fromPhone, print
} from 'utilities/styled'
import { Rows, FlexChild } from 'components/flex'
import { Count as CountBase } from './advanced/s'
import { Grid } from 'components/grid'
import { Plain } from 'components/button'
import Caption from 'components/typography/caption'

export const FilterRows = styled(Rows)`
    max-width: 360px;
    width: 100%;
    margin-inline-end: 16px;
    position: relative;

    &.expandable {
        margin-block-end: 16px;
    }

    &:not(.vertical) {
        margin-bottom: 16px;
    }

    ${fromPhone`
        &.stacked {
            flex-wrap: wrap;
            max-width: unset;
        }
    `}

    ${belowTablet`
        flex-wrap: wrap;
        margin-bottom: 0;
    `}

    ${print`
        display: none;
    `}
`

export const BasicRow = styled(FlexChild)`
    max-width: 100%;
    position: relative;
`

export const BasicRowLayout = styled(Grid)`
    grid-auto-columns: auto;
    grid-auto-flow: column;
    gap: 16px;

    ${FilterRows}.expandable & {
        grid-template-columns: 1fr minmax(auto, min-content);
    }
`

export const Count = styled(CountBase)`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    margin: 0;
`

export const AdvancedRow = styled(FlexChild)`
    overflow: clip;
    overflow-clip-margin: ${buttonOutlineProps.width + buttonOutlineProps.offset}px;

    &.expandable {
        max-width: 100%;
        margin-top: 16px;
        transition: margin .2s ease-in;

        &:not(.expanded) {
            margin-top: 0;
        }
    }

    &:not(.expandable) {
        &:where(:not(:only-child)) {
            margin-top: 16px;
        }

        ${bp.between('tablet', 'laptop')`
            ${FilterRows}:where(:not(.stacked)) & {
                width: calc(100vw - (var(--huma-global-main-layout-gutter) * 2));
            }
        `}

        ${bp.between('laptop', 'desktop')`
            ${FilterRows}:where(:not(.stacked)) & {
                width: calc(100vw - 384px);
            }
        `}

        ${bp.between('desktop', 1520)`
            ${FilterRows}:where(:not(.stacked)) & {
                width: calc(100vw - 494px);
            }
        `}

        ${bp.from(1520)`
            ${FilterRows}.side-panel & {
                width: var(--content-max-width);
            }

            ${FilterRows}:not(.side-panel) & {
                width: calc(1200px - 176px);
            }
        `}

        ${belowTablet`
            position: relative;

            margin-inline-start: -24px;
            max-width: 100vw;
            width: 100vw;
        `}
    }
`

export const AdvancedExpandable = styled(Grid)`
    grid-template-rows: 0fr;
    transition: grid-template-rows .2s ease-in;

    &.expanded {
        grid-template-rows: 1fr;
    }
`

export const AdvancedLayout = styled(Grid)`
    gap: 8px;

    ${AdvancedRow}.expandable & {
        padding: 0 16px;
        overflow-x: auto;
        border: 1px solid transparent;
        border-radius: 8px;
        transition: all .2s ease-in;
    }

    ${AdvancedRow}.expandable.expanded & {
        padding: 16px;
        border: 1px solid var(--huma-color-border-default);
    }

    &:not(.vertical) {
        grid-auto-columns: auto;
        grid-auto-flow: column;
        justify-content: start;
    }

    &.vertical {
        grid-auto-rows: auto;
        grid-auto-flow: row;
        align-items: stretch;
    }

    ${AdvancedRow}:not(.expandable) & {
        ${bp.between('laptop', 1520)`
            overflow-x: auto;

            /* Added to avoid clipping of the :focus outline */
            margin: -${buttonOutlineProps.width + buttonOutlineProps.offset}px;
            padding: ${buttonOutlineProps.width + buttonOutlineProps.offset}px;
        `}

        ${bp.from(1520)`
            ${FilterRows}.side-panel & {
                overflow-x: auto;
            }
        `}

        ${fromPhone`
            ${FilterRows}.stacked & {
                display: flex;
                flex-wrap: wrap;
            }
        `}

        ${belowTablet`
            position: relative;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;

            &:before,
            &:after {
                content: "";
                width: 16px;
            }
        `}
    }
`

export const AdvancedHeadingLayout = styled(Grid)`
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    padding-inline: 16px;
`

export const AdvancedHeading = Caption

export const Action = styled(Plain)`
    margin-inline-start: 8px;

    ${AdvancedRow}.expandable & {
        display: block;
        margin-block-start: 8px;
        margin-inline: 0;
    }
`
import styled from 'styled-components'
import { fontMonospace } from 's'
import {
    svgStroke,
    fromContainer, belowContainer, interpointDivider
} from 'utilities/styled'
import { container } from '../s'
import Link from 'components/link'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'

export const Row = styled(Link)`
    min-height: 76px;
    padding-block: 16px;
    box-shadow: 0 -1px var(--huma-color-border-default);

    ${fromContainer(container)`
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / -1;
    `}

    ${belowContainer(container)`
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        &:first-of-type {
            padding-block-start: 0;
            box-shadow: none;
        }
    `}

    &:hover,
    &:focus-visible {
        text-decoration: none;

        time {
            text-decoration: underline;
        }
    }
`

export const Cell = styled.div`
    display: flex;

    ${fromContainer(container)`
        align-items: center;
        grid-column: ${p => p.$cell};
    `}

    ${belowContainer(container)`
        flex-direction: column;

        &.action {
            display: none;
        }

        ${p => p.$cell === 'status' && `
            order: 1;
        `}

        ${p => p.$cell === 'message' && `
            order: 2;
        `}

        ${p => p.$cell === 'meta' && `
            order: 3;
        `}

        ${p => p.$cell === 'last-action' && `
            order: 4;
        `}
    `}

    &.action {
        svg {
            ${svgStroke()}

            transition: translate .1s ease-in-out;

            ${Row}:hover &,
            ${Row}:focus-visible & {
                translate: 4px;
            }
        }
    }
`

export const ContainedLabel = styled(CaptionSmall).attrs({ as: 'span' })`
    display: block;

    margin-block-end: 4px;

    &::after {
        content: ":";
    }

    ${fromContainer(container)`
        display: none;
    `}
`

export const Time = styled(Paragraph).attrs({ as: 'time' })`
    line-height: 20px;
`

export const MetaCellInner = styled.div`
    min-width: 0;
`

export const CaseMeta = styled(CaptionSmall).attrs({ as: 'span' })`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${interpointDivider()}
`

export const CaseNumber = styled.span`
    ${fontMonospace}
    font-size: 12px;
`
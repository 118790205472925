import styled, { css } from 'styled-components'
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter'
import { svgStroke } from 'utilities/styled'
import { fontMonospace } from 's'
import { Ghost } from 'components/button'

export const preCss = css`
    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    padding: 1ch;
    background-color: var(--huma-color-surface-minimal);

    word-wrap: normal;
`

export const Pre = styled.pre`
    ${preCss}

    margin-block-end: 24px;
`

export const PreWithoutMargin = styled(Pre)`
    ${preCss}
`

export const codeTextCss = css`
    ${fontMonospace}
    font-size: .875em;

    &.bigger {
        font-size: 1em;
    }
`

export const codeCssBase = css`
    display: inline;
    overflow: visible;
    white-space: pre;
    word-break: normal;
    word-wrap: normal;

    ${codeTextCss}
`

export const codeNeutralCss = css`
    ${codeCssBase}
    white-space: pre-wrap;
    overflow-wrap: break-word;
    overflow: auto;
`

export const codeCss = css`
    ${codeNeutralCss}

    display: inline;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    padding: 2px 0.5ch;
    background-color: var(--huma-color-surface-minimal);

    color: var(--huma-color-foreground-on-elevated-brand);
`

export const NeutralCode = styled.code`
    ${codeNeutralCss}
`

export const Code = styled.code`
    ${codeCss}

    &.variable {
        color: var(--huma-color-foreground-default);
        text-shadow: 0 0 .5px var(--huma-color-foreground-default);
        font-size: 80%;
    }
`

// TODO: Finish the layout
const huma = {
    'hljs': {
        'display': 'block',
        'overflow': 'auto',
        'padding': '16px',
        'border': '1px solid var(--huma-color-border-default-focus)',
        'borderRadius': '4px',
        'background': 'var(--huma-color-surface-minimal)',
        'color': 'var(--huma-color-foreground-default)',
    },
    'hljs-comment': {
        'color': 'var(--huma-color-foreground-minimal)',
        'fontStyle': 'italic'
    },
    'hljs-quote': {
        'color': 'var(--huma-color-foreground-minimal)',
        'fontStyle': 'italic'
    },
    'hljs-keyword': {
        'color': 'var(--huma-color-hljs-keyword)',
        'fontWeight': 'bold'
    },
    'hljs-selector-tag': {
        'color': 'var(--huma-color-hljs-keyword)'
    },
    'hljs-section': {
        'color': 'var(--huma-color-hljs-keyword)'
    },
    'hljs-title': {
        'color': 'var(--huma-color-foreground-on-elevated-brand)'
    },
    'hljs-name': {
        'color': 'var(--huma-color-hljs-keyword)'
    },
    'hljs-variable': {
        'color': '#660'
    },
    'hljs-template-variable': {
        'color': '#660'
    },
    'hljs-string': {
        'color': 'var(--huma-color-hljs-string)'
    },
    'hljs-selector-attr': {
        'color': 'var(--huma-color-hljs-string)'
    },
    'hljs-selector-pseudo': {
        'color': 'var(--huma-color-hljs-string)'
    },
    'hljs-regexp': {
        'color': 'var(--huma-color-hljs-string)'
    },
    'hljs-literal': {
        'color': 'var(--huma-color-foreground-constructive-bold)',
        'fontWeight': 'bold'
    },
    'hljs-symbol': {
        'color': 'var(--huma-color-foreground-constructive-bold)'
    },
    'hljs-bullet': {
        'color': 'var(--huma-color-foreground-constructive-bold)'
    },
    'hljs-meta': {
        'color': 'var(--huma-color-foreground-constructive-bold)'
    },
    'hljs-number': {
        'color': 'var(--huma-color-foreground-constructive-bold)'
    },
    'hljs-link': {
        'color': 'var(--huma-color-foreground-constructive-bold)'
    },
    'hljs-doctag': {
        'color': 'var(--huma-color-foreground-on-elevated-brand)',
        'fontWeight': 'bold'
    },
    'hljs-type': {
        'color': 'var(--huma-color-foreground-on-elevated-brand)'
    },
    'hljs-attr': {
        'color': 'var(--huma-color-foreground-on-elevated-brand)'
    },
    'hljs-built_in': {
        'color': 'var(--huma-color-foreground-on-elevated-brand)'
    },
    'hljs-builtin-name': {
        'color': 'var(--huma-color-foreground-on-elevated-brand)'
    },
    'hljs-params': {
        'color': 'var(--huma-color-foreground-on-elevated-brand)'
    },
    'hljs-attribute': {
        'color': 'var(--huma-color-foreground-default)'
    },
    'hljs-subst': {
        'color': 'var(--huma-color-foreground-default)'
    },
    'hljs-formula': {
        'backgroundColor': '#eee',
        'fontStyle': 'italic'
    },
    'hljs-selector-id': {
        'color': '#9B703F'
    },
    'hljs-selector-class': {
        'color': '#9B703F'
    },
    'hljs-addition': {
        'backgroundColor': '#baeeba'
    },
    'hljs-deletion': {
        'backgroundColor': '#ffc8bd'
    },
    'hljs-strong': {
        'fontWeight': 'bold'
    },
    'hljs-emphasis': {
        'fontStyle': 'italic'
    }
}

export const BlockWrapper = styled.div`
    position: relative;

    margin-block: 8px 32px;
`

export const Highlighter = styled(SyntaxHighlighter).attrs({
    style: huma,
    customStyle: {
        margin: 0,
        padding: 0
    }
})`
    code {
        ${codeCssBase}

        display: block;
        padding-block: 8px;

        line-height: 20px;
    }

    &:not(.show-line-numbers) code {
        padding-inline: 16px;
    }

    &.show-line-numbers {
        code {
            &:first-of-type {
                background-image: linear-gradient(
                    to right,
                    var(--huma-color-surface-subtle),
                    var(--huma-color-surface-subtle) calc(2ch + 16px),
                    transparent calc(2ch + 16px),
                    transparent
                );
            }

            .linenumber {
                margin-inline-end: 8px;
                min-width: calc(2ch + 16px) !important;
                padding-inline: 8px;

                font-style: normal !important;
                color: var(--huma-color-foreground-constructive-bold-active);
            }
        }
    }
`

export const CopyCode = styled(Ghost)`
    position: absolute;
    top: 8px;
    right: 8px;
    justify-content: center;

    width: 32px;
    height: 32px;
    padding: 0;
    background-color: var(--huma-color-surface-default);

    opacity: 0;
    transition: opacity 0.2 ease-in-out;

    ${BlockWrapper}:hover & {
        opacity: 1;
    }

    &.success::after,
    &::before {
        pointer-events: none;
        content: "";
        position: absolute;
        right: 100%;

        background-color: var(--huma-color-surface-success-minimal);

        opacity: inherit;
        transition: inherit;
    }

    &.success::after {
        content: attr(data-tooltip);

        margin-inline-end: 6px;
        border-radius: 4px;
        padding: 4px 8px;

        font-size: 14px;
    }

    &.success::before {
        width: 6px;
        height: 8px;
        clip-path: polygon(0 0, 100% 50%, 0 100%);
    }

    &.success {
        border-color: var(--huma-color-border-success);

        color: var(--huma-color-foreground-success);

        &:where(:hover, :focus) {
            border-color: var(--huma-color-foreground-success-active);
            background-color: var(--huma-color-surface-success-minimal);

            color: var(--huma-color-foreground-success-active);
        }

        &:active {
            border-color: var(--huma-color-border-success-focus);

            color: var(--huma-color-foreground-success-active);
        }
    }

    svg {
        ${svgStroke()}
    }
`
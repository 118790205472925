import React from 'react'
import { useI18n } from 'contexts/i18n'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'

const ViewLanguage = ({ label, className, ...props }) => {
    const { languages } = useI18n()

    let value = props.field?.value ?? null

    if(value) {
        value = value.toLowerCase()
    }

    if(value in languages) {
        value = languages[value]
    }

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <ValueDisplay {...(!value ? { className: 'empty' } : null)}>
                    {!!value && value}
                    {!value && (
                        <FormattedMessage
                            id="empty_not_chosen"
                            defaultMessage="Not chosen" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewLanguage
import React from 'react'
import styled from 'styled-components'

const Link = styled.a`
text-decoration: none;
color: var(--huma-color-foreground-constructive-bold);

    &:hover {
        text-decoration: underline;
    }
`

export default ({ number, ...props }) => (
    <Link
        {...props}
        href={`tel:${number.replace(/\s+/g, '')}`}>
        {number}
    </Link>
)

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHandbook } from 'contexts/handbook'
import { useStorage } from 'hooks/storage'
import { useAppSignal } from 'hooks/app-signal'
import { local } from 'utilities/storage'
import { RecentSearches } from './s'
import Topic from './topic'
import { Plain } from 'components/button'

const salt = 'handbook:recent-searches'

const HandbookRecentSearches = ({ size = 'normal', setActingTopic }) => {
    const { handbook } = useHandbook()
    const { categories = [] } = handbook ?? {}

    const [recentSearches, setRecentSearches] = useStorage({
        key: 'handbook:recent-searches',
        defaultValue: []
    })

    useAppSignal({
        namespace: 'handbook:recent-searches:updated',
        action: () => setRecentSearches(local.get('handbook:recent-searches'))
    })

    if(!recentSearches?.length) {
        return null
    }

    const topics = recentSearches.map(id => {
        const topicFilter = topic => id === topic.id

        const category = categories.find(({ topics = [] }) => topics.find(topicFilter))
        if(!category) {
            return null
        }

        const topic = category.topics?.find(topicFilter)
        if(!topic) {
            return null
        }

        return {
            ...topic,
            category
        }
    }).filter(Boolean)

    return (
        <>
            <RecentSearches className={size}>
                {topics.map(({ category, ...topic }) => (
                    <Topic
                        topic={topic}
                        category={category}
                        recentSearches={recentSearches}
                        setRecentSearches={setRecentSearches}
                        size={size}
                        setActingTopic={setActingTopic}
                        key={`${salt}:${topic.id}`} />
                ))}
            </RecentSearches>
            <Plain
                className="constructive"
                onClick={() => setRecentSearches([])}>
                <FormattedMessage
                    id="handbooks_search_recent_action_clear"
                    defaultMessage="Clear recent searches" />
            </Plain>
        </>
    )
}

export default HandbookRecentSearches

import styled from 'styled-components'
import { motion } from 'framer-motion'
import {
    svgStroke,
    belowContainer, profileContainer
} from 'utilities/styled'
import { Tags } from 'components/tags/s'
import { Tag as TagBase } from 'components/tags'
import { ListItemChildShrink } from 'components/list'
import { flexRowsCss, flexChildShrinkCss } from 'components/flex'
import { captionSmallCss } from 'components/typography/caption'

// Edit
export const FakeText = styled(Tags)`
    position: relative;
    flex-wrap: wrap;

    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    width: 100%;
    padding: 8px 8px 4px;
    background: none;

    &.disabled {
        cursor: default;

        background-color: var(--huma-color-surface-disabled);
    }

    &:not(.disabled) {
        &:hover {
            border-color: var(--huma-color-border-default-focus);
        }

        &.focus {
            border-color: var(--huma-color-border-default-focus);
            box-shadow:
                0 0 0 1px var(--huma-color-surface-default),
                0 0 0 3px var(--huma-color-border-constructive-focus);
        }

        &.error {
            border-color: var(--huma-color-border-destructive);

            &.focus {
                box-shadow:
                    0 0 0 1px var(--huma-color-surface-default),
                    0 0 0 3px var(--huma-color-border-destructive-focus);
            }
        }
    }

    &.vertical {
        ${flexRowsCss}
        align-items: flex-start;
        flex-wrap: nowrap;

        padding: 8px 12px;
    }

    ${belowContainer(profileContainer)`
        ${flexRowsCss}
        align-items: flex-start;
        flex-wrap: nowrap;

        padding: 8px 12px;
    `}

    &.adder {
        margin-bottom: 8px;
    }
`

export const Tag = styled(TagBase)`
    ${FakeText}.vertical & {
        display: block;
        clear: both;
    }

    ${belowContainer(profileContainer)`
        ${FakeText} & {
            display: block;
            clear: both;
        }
    `}
`

export const TextWrapper = styled(ListItemChildShrink)`
    position: relative;
    flex: 1 1 100%;

    margin-block-end: 4px;
    height: 32px;

    line-height: 32px;

    ${FakeText}.vertical & {
        ${flexChildShrinkCss}
        align-self: stretch;

        margin-block-end: 0;
    }

    ${belowContainer(profileContainer)`
        ${FakeText} & {
            ${flexChildShrinkCss}
            align-self: stretch;

            margin-block-end: 0;
        }
    `}
`

export const Text = styled.input`
    width: 100%;
    min-width: 110px;
    height: 32px;
    padding: 0 24px 0 4px;
    line-height: 32px;
    background: none;
    border: none;

    ${FakeText}.vertical & {
        height: 32px;
        padding: 0;

        line-height: 32px;
    }

    ${FakeText}.empty & {
        padding: 0;
    }

    ${belowContainer(profileContainer)`
        ${FakeText} & {
            height: 32px;
            padding: 0;

            line-height: 32px;
        }
    `}

    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: default;
    }
`

export const KeyHintInner = styled(motion.div).attrs(() => ({
    variants: {
        inactive: {
            opacity: 0,
            scale: .5
        },
        active: {
            opacity: 1,
            scale: 1
        }
    },
    transition: {
        duration: .1
    },
    initial: 'inactive',
    exit: 'inactive'
}))`
    pointer-events: none;
    position: absolute;
    right: 4px;
    bottom: 8px;
    display: inline-flex;

    color: var(--huma-color-foreground-default);

    svg {
        ${svgStroke()}
    }
`

export const Helper = styled(motion.p).attrs(() => ({
    variants: {
        hide: {
            opacity: 0,
            pointerEvents: 'none'
        },
        reveal: {
            opacity: 1,
            pointerEvents: 'auto'
        }
    },
    transition: {
        duration: .2
    },
    initial: 'hide'
}))`
    position: absolute;
    top: 100%;
    left: auto;
    ${captionSmallCss}
`

// View
export const Values = styled(Tags)`
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    min-height: 40px;
`
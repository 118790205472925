import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import { FormattedMessage } from 'react-intl'
import { FormContext } from 'components/form/controller'
import { v4 as uuid } from 'uuid'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import { isInRange } from 'utilities/math'
import { Field, Label, Control } from 'components/form/field/s'
import { DragDropWrapper, Sortables, SortableItem, DragColumn, Text, DeleteButton, AddButton, Error } from './s'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { DragIndicator } from 'styled-icons/material'
import { Trash2 as Delete, Plus } from 'styled-icons/feather'

const empty = []

export default class EditOptions extends Component {
    static contextType = FormContext

    constructor(props, context) {
        super(props, context)

        const { value } = getFieldFromProps(props)
        this.state = { value }

        this.register()
    }

    componentDidMount() {
        this.context.triggerChange(this.props.name, { touched: false })
    }

    componentDidUpdate = ({ name, field }, { value }) => {
        const nameChanged = name !== this.props.name
        const requiredChanged = field?.required !== this.props.field?.required
        const includeChanged = field?.include !== this.props.field?.include

        if(this.props.field?.include !== 'never' && nameChanged) {
            this.context.unregisterField(name)
            this.register()
        }

        if(requiredChanged || includeChanged) {
            this.register(true)
        }

        if(this.props.field?.include !== 'never' && value !== this.state.value) {
            this.context.triggerChange(this.props.name)
        }
    }

    componentWillUnmount() {
        const { include } = getFieldFromProps(this.props)
        if(include !== 'never') {
            this.context.unregisterField(this.props.name)
        }
    }

    register = (update = false) => {
        const {
            required,
            include,
            min,
            max
        } = getFieldFromProps(this.props)

        if(include === 'never') {
            return
        }

        this.context.registerField(this.props.name, {
            empty,
            required,
            include,
            type: 'options',
            unset: this.unset,

            validator: value => {
                if(required) {
                    let pruned = value.filter(({ value }) => !!value)
                    return isInRange({ min, max, value: pruned.length })
                }

                return true
            }
        }, update)
    }

    onChange = ({ target }, index) => {
        const {
            name,
            onChange
        } = this.props

        let { value: option = '' } = target

        this.setState(({ value }) => ({
            value: [
                ...value.slice(0, index),
                {
                    ...value[index],
                    value: option
                },
                ...value.slice(index + 1, value.length)
            ]
        }), () => onChange?.({ [name]: this.state.value }))
    }

    addItem = () => this.setState(({ value }) => ({
        value: [
            ...value,
            {
                key: uuid(),
                value: ''
            }
        ]
    }))

    removeItem = index => this.setState(({ value }) => ({
        value: value.filter(option => option.key !== value[index].key)
    }))

    onDragEnd = ({ destination, source }) => {
        if(!destination || destination.index === source.index) {
            return
        }

        const options =  [...this.state.value]
        const [movee] = options.splice(source.index, 1)
        options.splice(destination.index, 0, movee)

        this.setState({ value: options })
        this.props.onChange?.({ [this.props.name]: options })
    }

    unset = () => void this.setState({ value: empty }, () => {
        this.props.onChange?.({ [this.props.name]: empty })
        this.props.onUnset?.()
    })

    render() {
        const { value } = this.state

        const {
            className,
            controlProps = {},
            controlContent = null,
            salt,
            label,
            name,
            enabled = true,
            loading = false,
            display,
            error: outsideError,
            ...props
        } = this.props

        const touched = this.context.touched.includes(name)
        const error = ((name in this.context.errors) && touched) || !!outsideError

        const {
            required,
            softRequired,
            optional
        } = getFieldFromProps(this.props)

        const fieldClassName = cls([
            className,
            touched && 'touched',
            (!error && loading) && 'loading',
            error && 'error'
        ])

        const controlClassName = cls([
            controlProps.className,
            display
        ])

        return (
            <Field {...(fieldClassName ? { className: fieldClassName } : null)}>
                {!!label && (
                    <Label
                        htmlFor={`${salt}:${name}:0`}
                        required={required || softRequired}
                        optional={optional}>
                        {label}
                    </Label>
                )}
                <Control>
                    <DragDropWrapper>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {provided => (
                                    <Sortables
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        {value.map(({ key, value: optionValue }, index) => {
                                            const identifier = `${salt}:${name}:${index}`
                                            const last = index === value.length - 1

                                            const optionClassName = cls([
                                                controlClassName,
                                                last && 'last',
                                                !optionValue && 'empty'
                                            ])

                                            return (
                                                <Draggable
                                                    draggableId={identifier}
                                                    isDragDisabled={false}
                                                    disableInteractiveElementBlocking={true}
                                                    index={index}
                                                    key={identifier}>
                                                    {(provided, snapshot) => {
                                                        const $portal = document.getElementById('sortable')
                                                        const shouldUsePortal = snapshot.isDragging

                                                        const draggable = (
                                                            <SortableItem
                                                                {...provided.draggableProps}
                                                                $isDragging={snapshot.isDragging}
                                                                ref={provided.innerRef}>
                                                                <DragColumn {...provided.dragHandleProps}>
                                                                    <DragIndicator size={24} />
                                                                </DragColumn>
                                                                <Text
                                                                    {...controlProps}
                                                                    {...(optionClassName ? { className: optionClassName } : null)}
                                                                    {...omit(props, 'field', 'onUnset')}
                                                                    name={`${name}[${key}]`}
                                                                    value={optionValue}
                                                                    autoFocus={last && !optionValue}
                                                                    id={identifier}
                                                                    onChange={e => this.onChange(e, index)}
                                                                    disabled={!enabled} />
                                                                <DeleteButton onClick={() => this.removeItem(index)}>
                                                                    <Delete size={20} />
                                                                </DeleteButton>
                                                            </SortableItem>
                                                        )

                                                        if(shouldUsePortal) {
                                                            return createPortal(draggable, $portal)
                                                        }

                                                        return draggable
                                                    }}
                                                </Draggable>
                                            )}
                                        )}
                                        {provided.placeholder}
                                    </Sortables>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </DragDropWrapper>
                    {(!!value[value.length - 1]?.value || value.length === 0) && (
                        <AddButton
                            className="constructive"
                            onClick={this.addItem}>
                            <Plus size={24} />
                            <FormattedMessage
                                id="option_action_add"
                                defaultMessage="Add option" />
                        </AddButton>
                    )}
                    <Error animate={outsideError ? 'reveal' : 'hide'}>
                        {outsideError}
                    </Error>
                    {controlContent}
                </Control>
            </Field>
        )
    }
}

const getFieldFromProps = ({ field = {}, picker = {} }) => {
    let {
        value,
        required = false,
        softRequired = false,
        include = 'touched',
        ...rest
    } = field

    if(!value || !Array.isArray(value)) {
        value = empty
    }

    let {
        min = 0,
        max = Infinity
    } = picker

    return {
        ...rest,
        value,
        min,
        max,
        required,
        softRequired,
        include
    }
}

import React from 'react'
import { Wrapper as SWrapper, Item as SItem, Value, Label } from './s'
import { cls } from 'utilities/dom'

export const Wrapper = SWrapper

export const Item = ({ children, value, label, ...props }) => {
    if(children) {
        const className = cls([
            props.className,
            'free'
        ])

        return (
            <SItem
                {...props}
                {...(className ? { className } : null)}>
                {children}
            </SItem>
        )
    }

    return (
        <SItem {...props}>
            <Value>{value}</Value>
            {!!label && <Label className="compact">{label}</Label>}
        </SItem>
    )
}
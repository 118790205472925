import styled, { keyframes } from 'styled-components'
import {
    svgStroke,
    belowTablet
} from 'utilities/styled'
import { Columns } from 'components/flex'
import { Grid } from 'components/grid'
import { Naked } from 'components/button'
import GenericWidget from 'widgets/generic'
import Paragraph from 'components/typography/paragraph'

const stickyShadow = keyframes`
    100% {
        box-shadow: var(--editor-box-shadow);
    }
`

export const Wrap = styled(Columns)`
    word-break: normal;
    overflow-wrap: normal;

    justify-content: start;
    align-items: center;
    column-gap: 16px;

    margin-block-end: 8px;

    &.sticky {
        --editor-box-shadow: 0 3px 0 0 var(--huma-color-border-default);

        position: sticky;
        isolation: isolate;
        z-index: 2;
        inset-block-start: var(--huma-global-main-layout-header-height);
        border-top: var(--sticky-spacing, 0) solid var(--huma-color-surface-default);

        margin-inline-start: -4px;
        width: calc(100% + 8px);
        padding-inline: 4px;
        background-color: var(--huma-color-surface-default);

        transition:
            border-color .1s ease-in-out,
            box-shadow .1s ease-in-out;

        &::after {
            content: "";
            display: block;
            position: absolute;
            inset-block-end: 3px;
            inset-inline: 4px;

            width: calc(100% - 8px);
            height: 1px;

            @supports (animation-timeline: view()) {
                animation: ${stickyShadow} linear forwards;
                animation-duration: 1ms; /* Firefox requires this to apply the animation */
                animation-timeline: view();
                animation-range-start: calc(100vh - var(--huma-global-main-layout-header-height) - 24px);
                animation-range-end: calc(100vh - var(--huma-global-main-layout-header-height) - 12px);

                .sticky-menu ~ * & {
                    animation-range-start: calc(100vh - var(--huma-global-main-layout-header-height) - var(--huma-global-sticky-menu-height) - 24px);
                    animation-range-end: calc(100vh - var(--huma-global-main-layout-header-height) - var(--huma-global-sticky-menu-height) - 12px);
                }
            }
        }

        .sticky-menu ~ * & {
            inset-block-start: calc(var(--huma-global-main-layout-header-height) + var(--huma-global-sticky-menu-height));
        }

        &:has(+ * [contenteditable="true"]:hover) {
            --editor-box-shadow: 0 3px 0 0 var(--huma-color-border-default);
        }

        &:has(+ * [contenteditable="true"]:focus) {
            --editor-box-shadow:
                0 0 0 2px var(--huma-color-surface-default),
                0 2px 0 2px var(--huma-color-border-constructive-focus);
        }
    }

    ${belowTablet`
        overflow-x: auto;
    `}
`

export const Section = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: auto;
    column-gap: 4px;
    align-items: center;
    justify-content: start;

    &.extras {
        margin-inline-start: auto;
    }
`

export const Button = styled(Naked)`
    border-radius: 4px;
    width: 32px;
    height: 32px;
    padding: 0;

    transition: all .1s ease-out;

    font-size: 16px;

    &.dynamic {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: min-content;
        align-items: center;
        gap: 8px;

        width: auto;
        padding-inline: 8px;

        svg {
            margin-inline: -4px;
        }
    }

    &.active:not([disabled]) {
        background-color: var(--huma-color-surface-constructive-minimal);
        box-shadow: none;

        &:hover {
            background-color: var(--huma-color-surface-constructive-minimal-hover);
        }
    }

    &:not(.active):not([disabled]):hover {
        background-color: rgb(from var(--huma-color-surface-constructive-minimal) r g b / .5);
    }

    &[disabled] {
        color: var(--huma-color-foreground-disabled);
    }

    .box {
        display: block;

        border-radius: 4px;
        width: 24px;
        height: 20px;
        box-shadow: inset 0 0 0 1px var(--huma-color-border-bold);
    }

    em {
        margin-inline-start: -2px;
    }

    small {
        vertical-align: text-bottom;

        font-size: 12px;
    }

    svg {
        ${svgStroke('small')}
    }
`

export const Widget = styled(GenericWidget)`
    width: 352px;
`

export const Icon = styled(Grid)`
    place-items: center;

    width: 16px;
    height: 16px;

    font-size: 14px;
`

export const RichLabel = styled(Grid)`
    grid-auto-flow: column;
    grid-auto-columns: minmax(min-content, auto);
    grid-column-gap: 16px;
    align-items: center;

    line-height: 16px;
`

export const ContextMenuText = styled(Paragraph)`
    overflow: hidden;

    text-overflow: ellipsis;
    color: inherit;
`
import { htmlToDomNodes } from 'utilities/dom'
import { pick } from 'utilities/object'

// Based on https://observablehq.com/@daformat/highlighting-html-text

class Matcher {
    constructor(html, terms) {
        this.state = {}

        this.html = htmlToDomNodes(html, true)

        this.terms = terms
            .filter(term => !!term?.length)
            .map(term => term.toLowerCase())
            .sort((a, b) => b.length - a.length)

        this.process()
    }

    process() {
        this.terms.forEach(term => {
            this.state[term] = {
                matches: []
            }

            this.walk(this.html, term)

            this.state[term].matches
                .map(match => ({
                    match,
                    range: this.createRange(match)
                }))
                .reverse()
                .forEach(({ range, match }) => this.mark(range, match))
        })
    }

    walk(node, term) {
        let currentParent

        const treeWalker = document.createTreeWalker(
            node,
            NodeFilter.SHOW_TEXT
        )

        while(treeWalker.nextNode()) {
            const current = treeWalker.currentNode
            let alreadyMarked = false

            if(current.parentElement) {
                const parent = current.parentElement

                alreadyMarked = parent.tagName === 'MARK' && parent.classList.contains('match')
                const inline = ['', 'contents', 'inline', 'inline-block'].includes(getComputedStyle(parent).display)

                if((alreadyMarked || !inline) && currentParent !== parent) {
                    this.resetState(term)
                    currentParent = parent
                }
            }

            if(!alreadyMarked) {
                this.search(current, term)
            }
        }
    }

    search(node, term) {
        const text = node.textContent

        for(let index = 0; index < text.length; index++) {
            const next = `${this.state[term].current ?? ''}${text[index].toLowerCase()}`.replace(/\s+/g, ' ')

            if(next === term) {
                const isSingleCharacter = term.length === 1

                const startNode = isSingleCharacter ?
                    node :
                    this.state[term].startNode

                const startOffset = isSingleCharacter ?
                    index :
                    this.state[term].startOffset

                this.state[term].matches.push({
                    term,
                    startNode,
                    startOffset,
                    endNode: node,
                    endOffset: index + 1
                })

                this.resetState(term)
            } else {
                if(term.indexOf(next) === 0) {
                    if(!this.state[term].current || this.state[term].current.length === 0) {
                        this.state[term].startNode = node
                        this.state[term].startOffset = index
                    }

                    this.state[term].current = next
                } else {
                    this.resetState(term)
                }
            }
        }
    }

    createRange({ startNode, startOffset, endNode, endOffset }) {
        const range = new Range()

        range.setStart(startNode, startOffset)
        range.setEnd(endNode, endOffset)

        return range
    }

    mark(range, { startNode, endNode }) {
        const clonedStartNode = startNode.cloneNode(true)
        const clonedEndNode = endNode.cloneNode(true)

        const mark = document.createElement('mark')
        mark.classList.add('match')

        const selectedText = range.extractContents()
        mark.appendChild(selectedText)

        range.insertNode(mark)
        this.removeEmptyDirectSiblings(mark, clonedStartNode, clonedEndNode)
    }

    resetState(term) {
        if(!(term in this.state)) {
            return
        }

        this.state[term] = pick(this.state[term], 'matches')
    }

    render() {
        return this.html.innerHTML
    }

    // Helpers
    mergeAdjacentSimilarNodes(parent) {
        if(!parent?.childNodes) {
            return
        }

        Array.from(parent.childNodes).reduce((accumulator, value) => {
            if(!(value instanceof Element)) {
                accumulator && this.mergeAdjacentSimilarNodes(accumulator)
                accumulator = undefined

                return accumulator
            }

            if(accumulator && accumulator?.tagName?.toLowerCase() === value.tagName.toLowerCase()) {
                accumulator.append(...Array.from(value.childNodes))
                parent.removeChild(value)
                accumulator && this.mergeAdjacentSimilarNodes(accumulator)
            } else {
                accumulator && this.mergeAdjacentSimilarNodes(accumulator)
                accumulator = value
            }

            return accumulator
        }, undefined)
    }

    removeEmptyDirectSiblings(element, clonedStartNode, clonedEndNode) {
        const remove = (element, originalNode) => {
            let keepRemoving = true
            while(keepRemoving) {
                keepRemoving = this.removeEmptyElement(element, originalNode)
            }
        }

        remove(element.previousElementSibling, clonedStartNode)
        remove(element.nextElementSibling, clonedEndNode)
    }

    removeEmptyElement(element, originalNode) {
        if(!element) {
            return false
        }

        const isInOriginalNode = element => originalNode.childNodes && Array.from(originalNode.childNodes)
            .some(child => (child instanceof Element) && child.outerHTML === element.outerHTML)

        if(element.parentNode && !isInOriginalNode(element) && !element.textContent) {
            element.parentNode.removeChild(element)
            return true
        }

        if(element.childNodes[0] === element.children[0]) {
            return this.removeEmptyElement(element.children[0], originalNode)
        }
    }
}

export default Matcher
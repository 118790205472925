import React, { forwardRef, useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useMe } from 'contexts/me'
import { useNavigate } from 'react-router-dom'
import paths from 'app/paths'
import { compact } from 'utilities/array'
import { getFullName, getJobTitle } from 'utilities/person'
import { useTypeNameFormatter } from 'pages/absence/components/type-name'
import { getDaysCount } from 'pages/absence/utilities'
import {
    Wrapper,
    AbsenceLink, Avatar, NamePosition, Type, Emoji,
    AbsenceMeta, AbsenceDateWrapper, AbsenceDate, Note, Trigger,
    Status, ChangeButton, ApprovalActions,
    ActionsCell
} from './s'
import Tooltip from 'components/tooltip'
import PercentageLabel from 'components/percentage-label'
import Ellipsify from 'components/ellipsify'
import StatusLabel from 'pages/absence/components/approval-status'
import ContextMenu, { getBoundActions } from 'widgets/context-menu'

const RequestEntry = forwardRef(({ entry, actions = {}, salt, className }, ref) => {
    const { formatMessage } = useIntl()

    const [changing, setChanging] = useState(false)

    const { me } = useMe()

    const navigate = useNavigate()

    const {
        id,
        user,
        start,
        end,
        status,
        reviewedBy,
        type,
        grade = 1,
        note
    } = entry

    const days = getDaysCount(entry)

    useEffect(() => {
        if(status === 'pending') {
            setChanging(true)
        }
    }, [status, reviewedBy])

    const typeName = useTypeNameFormatter()(type)

    const name = getFullName(user)
    const jobTitle = getJobTitle(user)?.name

    const namePosition = compact([
        name,
        jobTitle
    ]).join(formatMessage({
        id: 'list_separator',
        defaultMessage: ', '
    }))

    const dateProps = {
        user,
        start,
        end
    }

    const boundActions = getBoundActions(actions, entry)

    const CountTooltip = () => (
        <Tooltip
            content={formatMessage({
                id: 'absence_count_days',
                defaultMessage: '{count, plural, =0 {Counts as 0 days} =1 {Counts as 1 day} other {Counts as {count} days}}'
            }, {
                count: days
            })}>
            <Trigger />
        </Tooltip>
    )

    return (
        <Wrapper
            className={className}
            ref={ref}>
            <AbsenceLink onClick={() => navigate(paths.absence.calendar, {
                state: {
                    start,
                    entryFocus: id
                },
                replace: true
            })}>
                <Avatar
                    who={user}
                    size={40} />
                <NamePosition>
                    <Ellipsify
                        text={namePosition}
                        head={5} />
                </NamePosition>
                <Type className="compact">
                    <Emoji
                        emoji={type.symbol.emoji}
                        size={14} />
                    <span>{typeName}</span>
                </Type>
            </AbsenceLink>
            <AbsenceMeta>
                <AbsenceDateWrapper {...(grade < 1 ? { className: 'with-divider' } : null)}>
                    <AbsenceDate {...dateProps} />
                    {grade < 1 && (
                        <span>
                            <PercentageLabel decimal={grade} />
                            <CountTooltip />
                        </span>
                    )}
                    {grade >= 1 && (
                        <CountTooltip />
                    )}
                </AbsenceDateWrapper>
                {!!note && <Note>{note}</Note>}
                <Status>
                    {(status !== 'pending') && (
                        <>
                            <StatusLabel status={status} />
                            <ChangeButton
                                className="constructive"
                                onClick={() => setChanging(!changing)}>
                                <FormattedMessage
                                    id={changing ? 'action_cancel' : 'action_change'}
                                    defaultMessage={changing ? 'Cancel' : 'Change'} />
                            </ChangeButton>
                        </>
                    )}
                    {(!changing && !!entry?.comment) && (
                        <Note
                            user={reviewedBy ?? me}
                            from="end">
                            {entry.comment}
                        </Note>
                    )}
                </Status>
            </AbsenceMeta>
            {!!boundActions?.length && (
                <ActionsCell>
                    <ContextMenu
                        prebound actions={boundActions}
                        salt={salt} />
                </ActionsCell>
            )}
            {!!changing && (
                <ApprovalActions
                    context="order"
                    entry={entry}
                    onDone={status => (status !== 'pending') && setChanging(false)}
                    salt={salt} />
            )}
        </Wrapper>
    )
})

export default RequestEntry

import React from 'react'
import Text from 'components/typography/paragraph'
import { TableRow, TableRowHeader, TableCell, Markdowns, Markdown } from './s'
import Shortcut from './shortcut'

const EditorHelpItem = ({ meta, name, disabled, salt }) => {
    const key = `${salt}:${name.toLowerCase()}`

    const {
        icon,
        label,
        markdown,
        shortcut,
        instructions
    } = meta

    return (
        <TableRow {...(disabled ? { className: 'disabled' } : null)}>
            <TableCell>{icon}</TableCell>
            <TableRowHeader>
                <Text className="compact">{label}</Text>
            </TableRowHeader>
            {!!instructions && (
                <TableCell colSpan="2">
                    <Text className="compact">{instructions}</Text>
                </TableCell>
            )}
            {!instructions && (
                <>
                    <TableCell>
                        {markdown?.syntax?.length && (
                            <Markdowns>
                                {markdown?.syntax.map(syntax => (
                                    <Markdown key={`${key}:${syntax}`}>
                                        {syntax}
                                        {(markdown?.trigger === 'surround') && [
                                            'abc',
                                            syntax
                                        ]}
                                        {(markdown?.trigger === 'space') && ' abc'}
                                    </Markdown>
                                ))}
                            </Markdowns>
                        )}
                        {(markdown?.trigger === 'enter') && (
                            <Shortcut
                                shortcut={['enter']}
                                salt={key} />
                        )}
                    </TableCell>
                    <TableCell>
                        <Shortcut
                            shortcut={shortcut}
                            salt={salt} />
                    </TableCell>
                </>
            )}
        </TableRow>
    )
}

export default EditorHelpItem
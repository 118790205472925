import styled from 'styled-components'
import { motion } from 'framer-motion'
import { OrderedList, ListItem } from 'components/list'
import { Simple } from 'components/button'

export const List = OrderedList
export const Item = ListItem

export const Button = styled(Simple)`
    position: relative;
    display: grid;
    grid-template-columns: min-content minmax(auto, 1fr) min-content;

    border: none;
    border-radius: 0;
    width: 100%;
    min-height: 40px;
    height: auto;
    padding: 0 40px 0 12px;

    white-space: nowrap;
    text-align: start;

    ${Item}:first-child & {
        border-radius: 8px 8px 0 0;
    }

    ${Item}:last-child & {
        border-radius: 0 0 8px 8px;
    }

    &.active {
        background-color: var(--huma-color-surface-constructive-transparent-hover);
    }

    & > * {
        pointer-events: none;
    }
`

export const EmojiCode = styled.span`
    margin-inline-start: 8px;

    text-align: start;

    &:where(::before, ::after) { content: ":"; }
`

export const KeyHintInner = styled(motion.div).attrs(() => ({
    variants: {
        inactive: {
            opacity: 0,
            transition: {
                duration: .1
            }
        },
        active: {
            opacity: 1,
            transition: {
                duration: .2
            }
        }
    },
    initial: 'inactive',
    exit: 'inactive'
}))`
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 100%;

    color: var(--huma-color-foreground-default);

    svg {
        stroke-width: 2;
    }
`
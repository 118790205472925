import React, { useState, useRef, useEffect, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAbortController } from 'hooks/abort-controller'
import { get } from 'api'
import { omit } from 'utilities/object'
import { cls } from 'utilities/dom'
import isEqual from 'react-fast-compare'
import { Plain } from 'components/button'
import { Filter, Count, Arrow, Widget, WidgetContentScroller, MobileHeader, ResetWrap } from './s'
import Form from 'components/form/controller'
import Field from 'components/form/field/checkboxes'

const CheckboxesField = ({ filter, setFilter, allResetAt, name, label, field = null, content = null, salt }) => {
    const getDefaultValue = () => field?.defaultValue ?? []
    const getValue = () => filter[name] ?? getDefaultValue()

    const location = useLocation()
    const navigate = useNavigate()

    const [previousAllResetAt, setPreviousAllResetAt] = useState(allResetAt)
    const [configuring, setConfiguring] = useState(false)
    const [hasInteracted, setHasInteracted] = useState(false)
    const [count, setCount] = useState(getValue().length)
    const [isResettable, setIsResettable] = useState(!isEqual(filter[name], getDefaultValue()) && !!filter[name])

    useEffect(() => {
        if(allResetAt !== previousAllResetAt) {
            if(allResetAt.endsWith?.(':update')) {
                setCount(getValue().length)
                setIsResettable(!isEqual(
                    getValue().sort(),
                    getDefaultValue().sort()
                ))
            } else {
                reset({ silent: true })
            }

            setPreviousAllResetAt(allResetAt)
        }
    }, [allResetAt, previousAllResetAt])

    const control = useRef()
    const widget = useRef()

    const { options } = field

    const update = update => {
        if(name in update) {
            setFilter(update)
            setCount(update[name]?.length)
            setIsResettable(!isEqual(
                update[name].sort(),
                getDefaultValue().sort()
            ))
        }
    }

    const reset = (options = {}) => {
        if(name in (location.state?.filter ?? {})) {
            navigate({
                ...location,
                state: {
                    ...location.state,
                    filter: omit(location.state.filter, name)
                },
                replace: true
            })
        }

        const { silent = false } = options
        !silent && setFilter({ [name]: getDefaultValue() })

        setCount(getDefaultValue().length)
        setIsResettable(false)
    }

    const filterClassName = cls([
        'constructive',
        configuring && 'active'
    ])

    return (
        <>
            <Filter
                className={filterClassName}
                onClick={() => {
                    setConfiguring(configuring => !configuring)
                    setHasInteracted(true)
                }}
                ref={control}>
                {label}
                <Count {...(configuring ? { className: 'active' } : null)}>
                    {count || ''}
                </Count>
                <Arrow
                    {...(configuring ? { className: 'active' } : null)}
                    size={16} />
            </Filter>
            <Widget
                show={configuring}
                clickOutside={{
                    inside: [control],
                    action: () => setConfiguring(false)
                }}
                position={{
                    origin: control,
                    direction: {
                        x: {
                            where: 'inside',
                            to: 'right'
                        },
                        y: {
                            where: 'outside',
                            to: 'down',
                            adjust: 8
                        }
                    }
                }}
                closeButton={false}
                constrain={true}
                blocking={true}
                scrollable={false}
                salt={`${salt}:${name}:widget`}
                ref={widget}>
                <MobileHeader>
                    <Plain
                        className="constructive"
                        onClick={() => setConfiguring(false)}>
                        <FormattedMessage
                            id="action_done"
                            defaultMessage="Done" />
                    </Plain>
                    <Plain
                        className="destructive"
                        onClick={reset}
                        disabled={!isResettable}>
                        <FormattedMessage
                            id="action_reset"
                            defaultMessage="Reset" />
                    </Plain>
                </MobileHeader>
                <WidgetContentScroller>
                    {content}
                    <Form layout="vertical">
                        <Field
                            salt={salt}
                            name={name}
                            className="compact"
                            label={false}
                            field={{
                                ...field,
                                value: getValue(),
                                reversed: true
                            }}
                            options={options}
                            onChange={({ [name]: options }) => {
                                if(!hasInteracted) {
                                    return
                                }

                                update({ [name]: options.map(({ id }) => id) })
                            }}
                            ellipsify={true}
                            key={[
                                salt,
                                name,
                                allResetAt,
                                configuring,
                                count
                            ].join(':')} />
                    </Form>
                </WidgetContentScroller>
                {!!isResettable && (
                    <ResetWrap>
                        <Plain
                            className="destructive"
                            onClick={reset}>
                            <FormattedMessage
                                id="action_reset"
                                defaultMessage="Reset" />
                        </Plain>
                    </ResetWrap>
                )}
            </Widget>
        </>
    )
}

const CheckboxesFieldFetcher = ({
    query,
    filter,
    setFilter,
    name,
    field = null,
    optionMapper,
    hideWhenEmpty = false,
    ...props
}) => {
    const { options: initialOptions = [] } = (field ?? {})

    const [options, setOptions] = useState(initialOptions)

    const { resetAbortController } = useAbortController()

    const fetch = useCallback(async () => {
        if(!query) {
            return void resetAbortController()
        }

        const { ok, response } = await get({
            ...query,
            signal: resetAbortController().signal
        })

        if(ok) {
            const update = {}

            const items = Array.isArray(response) ?
                response :
                (response?.items ?? [])

            if(items.length) {
                setOptions(items.map(optionMapper ?? defaultOptionMapper))

                const itemIds = items.map(({ id }) => id)
                update[name] = (filter[name] ?? []).filter(id =>
                    !!itemIds.includes(id)
                )
            }

            if(items.length !== update[name]) {
                setFilter(update)
            }
        }
    }, [query])

    useEffect(() => {
        fetch()
    }, [fetch])

    if(hideWhenEmpty && !options.length) {
        return null
    }

    return (
        <CheckboxesField
            {...props}
            filter={filter}
            setFilter={setFilter}
            name={name}
            field={{
                ...field,
                options
            }} />
    )
}

const defaultOptionMapper = option => option

export default CheckboxesFieldFetcher
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ValueDisplay } from './s'
import { Field, Label, Control } from 'components/form/field/s'

const ViewPillsField = ({ label, className, ...props }) => {
    const {
        field = {},
        options
    } = props

    let { value } = field

    const displayValue = options?.find(option => option.value === value)?.label

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <ValueDisplay {...(!value ? { className: 'empty' } : null)}>
                    {displayValue}
                    {!displayValue && (
                        <FormattedMessage
                            id="empty_not_chosen"
                            defaultMessage="Not chosen" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewPillsField

import React from 'react'
import { modalify, Wrapper, Veil, Modal } from 'utilities/modal'
import GroupProvider from 'contexts/group'
import { Container } from './s'
import Content from './content'

const GroupPersonPicker = ({ className, salt, ...props }) => {
    const {
        type,
        id,
        show = false,
        outer = true,
        dismiss,
        ...content
    } = props

    const animate = show ? 'in' : 'out'

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    {!!outer && <Veil animate={animate} />}
                    <Modal
                        dismiss={dismiss}
                        key={`modal:group-person-picker:${salt}`}>
                        <Container
                            {...(className ? { className } : null)}
                            animate={animate}>
                            <GroupProvider
                                type={type}
                                id={id}>
                                {({ group }) => (
                                    <Content
                                        {...content}
                                        group={group}
                                        dismiss={dismiss}
                                        salt={salt} />
                                )}
                            </GroupProvider>
                        </Container>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

export default GroupPersonPicker

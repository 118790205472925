import React, { useCallback, Fragment } from 'react'
import { useI18n } from 'contexts/i18n'

export const fields = [
    'line1',
    'line2',
    // 'line3',
    'postalCode',
    'city',
    'region',
    'country'
]

export const getValues = (address = {}) => ({
    br: <br />,
    ...fields.reduce((accumulator, name) => {
        const value = address[name] || ''

        return {
            ...accumulator,
            [name]: value,
            [`${name}length`]: value.length
        }
    }, {})
})

const separatorsByLocale = {
    en: ', ',
    nb: ', ',
    sv: ', ',
    da: ', ',
    pl: ', '
}

const compose = ({ address, locale = 'en', territories, multiline = false }) => {
    let {
        line1,
        line2,
        // line3,
        postalCode,
        city,
        region,
        country
    } = address

    if(country) {
        country = country.toUpperCase()
    }

    const separator = separatorsByLocale[locale] ?? ', '

    if(country in territories) {
        country = territories[country]
    }

    // TODO: Move these translations to Lokalise
    const compositions = {
        en: {
            single: [
                `${line1}${separator}${line2}${separator}${postalCode}${separator}${city}${separator}${country}`
            ],
            multi: [
                `${line1}`,
                `${line2}`,
                // `${line3}`,
                `${city} ${postalCode}`,
                `${region}`,
                `${country}`
            ]
        },
        nb: {
            single: [
                `${line1}${separator}${line2}${separator}${postalCode} ${city}${separator}${country}`
            ],
            multi: [
                `${line1}`,
                `${line2}`,
                // `${line3}`,
                `${postalCode} ${city}`,
                `${region}`,
                `${country}`
            ]
        },
        sv: {
            single: [
                `${line1}${separator}${line2}${separator}${postalCode} ${city}${separator}${country}`
            ],
            multi: [
                `${line1}`,
                `${line2}`,
                // `${line3}`,
                `${postalCode} ${city}`,
                `${region}`,
                `${country}`
            ]
        },
        da: {
            single: [
                `${line1}${separator}${line2}${separator}${postalCode} ${city}${separator}${country}`
            ],
            multi: [
                `${line1}`,
                `${line2}`,
                // `${line3}`,
                `${postalCode} ${city}`,
                `${region}`,
                `${country}`
            ]
        },
        pl: {
            single: [
                `${line1}${separator}${line2}${separator}${postalCode} ${city}${separator}${country}`
            ],
            multi: [
                `${line1}`,
                `${line2}`,
                // `${line3}`,
                `${postalCode} ${city}`,
                `${region}`,
                `${country}`
            ]
        }
    }

    const composition = (locale in compositions) ?
        compositions[locale][multiline ? 'multi' : 'single'] :
        compositions.en[multiline ? 'multi' : 'single']

    return composition
        .map(part => part.trim())
        .filter(part => !!part)
        .map(part => part.replace(/[ ]{2,}/g, ' '))
        .map(part => part
            .split(separator)
            .filter(fragment => !!fragment)
            .join(separator)
        )
}

export const formatAddress = (address = {}) => {
    const {
        locale,
        territories
    } = useI18n()

    if(!Object.values(address).join('').length) {
        return null
    }

    const lines = compose({
        address: getValues(address),
        locale,
        territories
    })

    return lines
}

export const useAddressFormatter = () => {
    const { locale, territories } = useI18n()

    return useCallback(({ i18n: i18nOverride, address = {}, multiline = false }) => {
        if(!Object.values(address ?? {}).join('').length) {
            return null
        }

        return compose({
            address: getValues(address),
            locale: i18nOverride?.locale ?? locale,
            territories: i18nOverride?.territories ?? territories,
            multiline
        })[0]
    }, [locale, territories])
}

export const FormattedAddress = ({ address = {}, multiline = false, as: Wrapper = Fragment, salt, ...props }) => {
    const {
        locale,
        territories
    } = useI18n()

    if(!Object.values(address ?? {}).join('').length) {
        return null
    }

    const lines = compose({
        address: getValues(address),
        locale,
        territories,
        multiline
    })

    return (
        <Wrapper {...props}>
            {lines.map((line, index) => (
                <Fragment key={`${salt}:address:${index}`}>
                    {(index > 0) && <br />}
                    {line}
                </Fragment>
            ))}
        </Wrapper>
    )
}

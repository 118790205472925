import React, { Fragment, useState } from 'react'
import { Layout, Content, Menu, Flaps, Flap } from './s'

const FlapsAndContents = ({ items, salt, contentWrapper: ContentWrap = Fragment, ...props }) => {
    const [currentIndex, setCurrentIndex] = useState(0)

    return (
        <Layout {...props}>
            <Content className="content">
                <ContentWrap>
                    {items[currentIndex].content}
                </ContentWrap>
            </Content>
            {(items.length > 1) && (
                <Menu className="menu">
                    <Flaps>
                        {items.map(({ label }, index) => {
                            return (
                                <Flap
                                    onClick={() => setCurrentIndex(index)}
                                    {...((index === currentIndex) ? { className: 'current' } : null)}
                                    key={`${salt}:flap:${index}`}>
                                    {label}
                                </Flap>
                            )
                        })}
                    </Flaps>
                </Menu>
            )}
        </Layout>
    )
}

export default FlapsAndContents
import React from 'react'
import { useOrganization } from 'contexts/organization'
import { FormattedMessage } from 'react-intl'
import { OrganizationWrap, IconColumn, DetailsColumn, Name, EmployeeCount } from './s'
import { Users } from 'styled-icons/feather'
import { Company } from 'components/feather'

const Organization = props => {
    const { organization } = useOrganization()
    if(!organization) {
        return null
    }

    const {
        size = 24,
        nowrap = true,
        nameOnly = false,
        company = false,
        ...attributes
    } = props

    const {
        name,
        userCount: count
    } = organization

    return (
        <OrganizationWrap {...attributes}>
            <IconColumn $iconSize={size}>
                {!!company && <Company size={size} />}
                {!company && <Users size={size} />}
            </IconColumn>
            <DetailsColumn>
                <Name {...(nowrap ? { className: 'nowrap' } : null)}>
                    {nameOnly && name}
                    {!nameOnly && (
                        <FormattedMessage
                            id="organization_everyone_in"
                            defaultMessage="Everyone in {name}"
                            values={{ name }} />
                    )}
                </Name>
                <EmployeeCount {...(nowrap ? { className: 'nowrap' } : null)}>
                    <FormattedMessage
                        id="people_count"
                        defaultMessage="{count, plural, =0 {No people} =1 {One person} other {{count} people}}"
                        values={{ count }} />
                </EmployeeCount>
            </DetailsColumn>
        </OrganizationWrap>
    )
}

export default Organization
import styled from 'styled-components'

export const Wrapper = styled.div`
    &:not(.compact) {
        margin-block-start: 24px;
    }
`

export const Name = styled.span`
    color: var(--huma-color-foreground-default);
`
import styled, { css } from 'styled-components'
import { belowTablet } from 'utilities/styled'
import {
    Grid,
    GridColumnHeader as GridColumnHeaderBase
} from 'components/grid'

export const Wrap = styled.div``

export const Header = styled(Grid)`
    width: 100%;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 24px;
    border-bottom: 1px solid var(--huma-color-border-default);

    ${belowTablet`
        grid-template-columns: auto;
        justify-content: start;
    `}
`

const gridColumnHeaderCss = css`
    align-items: center;
    display: flex;
    height: 72px;
    padding: 24px 0;

    ${belowTablet`
        height: 48px;
        padding: 0;
    `}
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}

    ${belowTablet`
        &:not(:first-of-type) {
            display: none;
        }
    `}
`
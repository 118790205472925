import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { compact } from 'utilities/array'
import { Options, Option } from './s'
import Paragraph from 'components/typography/paragraph'
import { Plain } from 'components/button'

const TooltipContentOptions = ({ node, options, cursor, updateAttributes, salt }) => {
    const { territories } = useI18n()

    return (
        <Options>
            {options.map(({ value, ...option }) => {
                const { [cursor]: valueCursor } = option
                const picked = node?.attrs?.cursor === valueCursor

                const handler = () => updateAttributes({
                    cursor: valueCursor,
                    value
                })

                return (
                    <Option key={`${salt}:${valueCursor}`}>
                        <div>
                            <Paragraph className="compact">
                                {value}
                            </Paragraph>
                            {!!option.country && (
                                <Paragraph className="caption small compact">
                                    {compact([
                                        getUnicodeFlagIcon(option.country),
                                        territories?.[option.country]
                                    ]).join(' ')}
                                </Paragraph>
                            )}
                        </div>
                        <Plain
                            onMouseDown={handler}
                            onClick={handler}
                            className="constructive"
                            disabled={picked}>
                            <FormattedMessage
                                id={picked ?
                                    'noun_picked' :
                                    'action_pick'
                                }
                                defaultMessage={picked ?
                                    'Picked' :
                                    'Pick'
                                } />
                        </Plain>
                    </Option>
                )
            })}
        </Options>
    )
}

export default TooltipContentOptions
import React from 'react'
import { modalify, Wrapper, Modal, Veil } from 'utilities/modal'
import { Container } from './s'

const Fullscreen = ({ salt, className, children, ...modal }) => {
    const {
        show = false,
        dismiss
    } = modal

    const animate = show ? 'in' : 'out'

    return modalify(
        <Wrapper>
            {!!show && (
                <>
                    <Veil animate={animate} />
                    <Modal
                        dismiss={dismiss}
                        key={`modal:fullscreen:${salt}`}>
                        <Container
                            {...(className ? { className } : null)}
                            animate={animate}>
                            {children}
                        </Container>
                    </Modal>
                </>
            )}
        </Wrapper>
    )
}

export default Fullscreen

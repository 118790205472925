import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import {
    svgStroke,
    belowTablet, belowPhone
} from 'utilities/styled'
import { cls } from 'utilities/dom'
import { Columns, FlexChildShrink, FlexChild, flexChildShrinkCss, flexColumnsCss } from 'components/flex'
import { NavLink as VanillaNavLink } from 'react-router-dom'
import { Naked, buttonStyles } from 'components/button'
import { Container, List, Item, actionLinkCss } from 'widgets/context-menu/s'

const NavLink = forwardRef(({ className, activeClassName, ...props }, ref) => (
    <VanillaNavLink
        {...props}
        className={({ isActive }) => cls([
            className,
            isActive && activeClassName
        ])}
        ref={ref} />
))

const itemStatesCss = css`
    color: var(--huma-color-foreground-subtle);

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 16px;
        right: 16px;
        display: block;

        height: 2px;
        background: transparent;

        transition: background .1s ease-in-out;
    }

    &:hover,
    &[aria-current] {
        color: var(--huma-color-foreground-default);

        &::before {
            background-color: var(--huma-color-foreground-default);
        }
    }
`

export const Menu = styled(FlexChildShrink)`
    order: 1;
`

export const Tabs = styled(Columns).attrs({ as: 'nav' })`
    user-select: none;
    position: relative;

    margin-block: 0 40px;
    margin-inline: -16px;
    max-width: calc(100% + 32px);
    width: calc(100% + 32px);

    ${belowPhone`
        margin-block-end: 24px;
    `}

    &::before {
        content: "";
        position: absolute;
        inset-inline: 16px;
        inset-block-end: 0;

        display: block;

        height: 2px;
        background: var(--huma-color-border-default);
    }
`

export const Tab = styled(NavLink).attrs({ activeClassName: 'current' })`
    ${flexChildShrinkCss}

    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    column-gap: 8px;

    padding: 0 16px;

    transition: color .1s ease-in-out;

    white-space: nowrap;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    text-align: right;

    &.top {
        ${itemStatesCss}

        padding-block-end: 2px;

        line-height: 46px;
    }

    &:not(.top) {
        ${buttonStyles.simpleCss}
        ${actionLinkCss}

        ${belowTablet`
            padding: 0 16px !important;

            &.with-upgrade {
                padding: 0 48px 0 16px !important;
            }
        `}

        &[aria-current]::after,
        &:hover::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;

            width: 2px;
            height: 100%;
            background-color: var(--huma-color-border-bold);
        }
    }

    &.hide {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }

    svg.upgrade {
        ${svgStroke()}

        color: var(--huma-color-foreground-subtle);
    }
`

export const Overflow = styled(FlexChildShrink)`
    ${itemStatesCss}

    position: relative;

    padding-inline: 16px;

    &.hide {
        pointer-events: none;
        position: absolute;

        visibility: hidden;
        opacity: 0;
    }
`

export const OverflowToggler = styled(Naked)`
    ${flexColumnsCss}

    width: 100%;
    height: 100%;

    line-height: 46px;
`

export const OverflowArrow = styled(motion.div).attrs(() => ({
    variants: {
        collapsed: {
            transform: 'rotate(0deg)'
        },
        expanded: {
            transform: 'rotate(-180deg)'
        }
    },
    transition: {
        duration: .15
    },
    initial: 'collapsed'
}))`
    ${flexChildShrinkCss}

    margin-inline-start: 4px;
`

export const OverflowListWrap = styled(Container)`
    width: fit-content;
    position: absolute;
    top: calc(100% + 8px);
    right: 8px;
    z-index: 1;
    isolation: isolate;

    border-radius: 8px;
    box-shadow: var(--huma-shadow-lowest);
    background-color: var(--huma-color-surface-default);

    &:not(.show) {
        display: none;
    }
`

export const OverflowList = List
export const OverflowItem = Item

export const Content = styled(FlexChild)`
    order: 2;
`
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAbsencePolicyAssignees } from 'contexts/absence-policy-assignees'
import { useTheme, ThemeProvider } from 'styled-components'
import { clamp } from 'utilities/math'
import { getActionsArray } from 'widgets/context-menu'
import {
    Wrap,
    Header, GridColumnHeader, GridColumnActionsHeader,
    SkeletonListRow,
    LoadingContainer
} from './s'
import Assignee from './assignee'
import Loader from 'components/loader'
import { Plain, Ghost } from 'components/button'
import { SkeletonCell, SkeletonAvatarAndFullName, SkeletonStrings } from 'components/skeleton'

const AbsenceAssignees = ({ actions = {}, header = false, policy, setReassigning, className }) => {
    const {
        assignees,
        total,
        intersecter,
        loading,
        paging = {},
        fetchAbsencePolicyAssignees,
        fetching,
        autoFetch,
        hasFetched
    } = useAbsencePolicyAssignees()

    const [skeletonLength, setSkeletonLength] = useState(5)

    const { hasNextPage } = paging

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(assignees.length, 1, 10))
        }
    }, [hasFetched, assignees?.length])

    let theme = useTheme()

    const hasActions = !!getActionsArray(actions).length

    theme = {
        ...theme,
        policyAssignees: {
            actions: hasActions
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Wrap {...(className ? { className } : null)}>
                {!!header && (
                    <Header>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="noun_name"
                                defaultMessage="Name" />
                        </GridColumnHeader>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="person_label_locations"
                                defaultMessage="Locations" />
                        </GridColumnHeader>
                        <GridColumnHeader>
                            <FormattedMessage
                                id="person_label_teams"
                                defaultMessage="Teams" />
                        </GridColumnHeader>
                        <GridColumnActionsHeader>
                            <Plain
                                className="constructive compact"
                                onClick={() => setReassigning({
                                    users: assignees,
                                    total,
                                    policy
                                })}>
                                <FormattedMessage
                                    id="action_reassign_everyone"
                                    defaultMessage="Reassign everyone" />
                            </Plain>
                        </GridColumnActionsHeader>
                    </Header>
                )}
                {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow key={`list:absence:policy:assignees:skeleton:${index}`}
                        columns={3}
                        hasActions={hasActions}>
                        <SkeletonCell>
                            <SkeletonAvatarAndFullName
                                size={40}
                                showSecondLine={index % 2 === 0} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings size={20} />
                        </SkeletonCell>
                        <SkeletonCell>
                            <SkeletonStrings size={20} />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {assignees.map((assignee, index) => {
                    const last = index + 1 === assignees.length

                    return (
                        <Assignee
                            assignee={assignee}
                            actions={actions}
                            {...(last ? { ref: intersecter } : null)}
                            salt={`list:absence:policy:assignees:assignee:${assignee.id}`}
                            key={`list:absence:policy:assignees:assignee:${assignee.id}`} />
                    )
                })}
                {!!hasNextPage && (
                    <LoadingContainer>
                        {(!loading && !autoFetch) && (
                            <Ghost
                                className={`constructive${fetching ? ' loading' : ''}`}
                                onClick={() => fetchAbsencePolicyAssignees()}>
                                <FormattedMessage
                                    id="action_load_more"
                                    defaultMessage="Load more…"
                                />
                            </Ghost>
                        )}
                        {!!loading && <Loader />}
                    </LoadingContainer>
                )}
            </Wrap>
        </ThemeProvider>
    )
}

export default AbsenceAssignees

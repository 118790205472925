import React, {
    useLayoutEffect, useState, forwardRef,
    cloneElement, isValidElement
} from 'react'
import { createPortal } from 'react-dom'
import tippy from 'tippy.js'
import { useMutableBox } from 'hooks/mutable-box'
import { has, pick } from 'utilities/object'
import { compact } from 'utilities/array'
import { Wrapper, TriggerWrapper, TriggerIcon } from './s'
import useSingletonGenerator from './singleton'
import { classNamePlugin } from './plugins/className'

const Tippy = ({
    children,
    content,
    visible,
    singleton,
    render,
    reference,
    delay = 0,
    animation = 'scale-subtle',
    duration = [100, 0],
    disabled = false,
    wrapperClassName,
    ...props // https://atomiks.github.io/tippyjs/v6/all-props/
}) => {
    const isControlledMode = visible !== undefined
    const isSingletonMode = singleton !== undefined

    const [mounted, setMounted] = useState(false)
    const [rendered, setRendered] = useState(false)
    const [attributes, setAttributes] = useState({})
    const [singletonContent, setSingletonContent] = useState(null)

    const mutableBox = useMutableBox({
        instance: null,
        container: null,
        ref: null
    })

    const toggleInstance = (instance, show) => {
        if(!isControlledMode) {
            return
        }

        if(show) {
            instance?.show()
        } else {
            instance?.hide()
        }
    }

    if(isSingletonMode) {
        disabled = true
    }

    props = {
        ...filterTippyProps(props),
        delay,
        animation,
        duration,
        content: mutableBox.container
    }

    if(render) {
        props = {
            ...props,
            render: () => ({ popper: mutableBox.container }),
            plugins: compact([
                ...(props.plugins ?? []),
                (isSingletonMode && singleton?.data !== null) && {
                    fn: () => ({
                        onTrigger(instance, event) {
                            const node = singleton.data?.children.find(({ instance }) => instance.reference === event.currentTarget)

                            instance.state.$$activeSingletonInstance = node?.instance

                            setSingletonContent(node?.content)
                        }
                    })
                }
            ])
        }
    }

    const dependencies = [reference].concat(children ? [children.type] : [])

    // Create the tippy instance
    useLayoutEffect(() => {
        let element = reference

        if(has(reference, 'current')) {
            element = reference.current
        }

        if(!element) {
            if(!mutableBox.ref) {
                element = document.createElement('div')
            } else {
                element = mutableBox.ref
            }
        }

        const instance = tippy(element, {
            ...props,
            plugins: [
                ...(props.plugins ?? []),
                classNamePlugin
            ]
        })

        mutableBox.instance = instance

        if(disabled) {
            instance.disable()
        }

        toggleInstance(instance, visible)

        if(isSingletonMode) {
            singleton?.hook({
                instance,
                content,
                props,
                setSingletonContent
            })
        }

        if(!mounted) {
            setMounted(true)
        }

        return () => {
            instance.destroy()
            singleton?.cleanup(instance)
        }
    }, dependencies)

    // Update the tippy instance
    useLayoutEffect(() => {
        if(!rendered) {
            const container = document.createElement('div')
            mutableBox.container = container

            return void setRendered(true)
        }

        const { instance } = mutableBox

        instance?.setProps({
            ...instance.props,
            ...props
        })

        instance?.popperInstance?.forceUpdate()

        if(disabled) {
            instance?.disable()
        } else {
            instance?.enable()
        }

        toggleInstance(instance, visible)

        if(isSingletonMode) {
            singleton?.hook({
                instance,
                content,
                props,
                setSingletonContent
            })
        }
    }, [props, ...dependencies])

    useLayoutEffect(() => {
        const { instance } = mutableBox

        instance.setProps({
            popperOptions: {
                ...instance.props.popperOptions,
                strategy: 'fixed',
                modifiers: [
                    ...(instance.props.popperOptions?.modifiers ?? []).filter(({ name }) => name !== 'react'),
                    {
                        name: 'react',
                        enabled: true,
                        phase: 'beforeWrite',
                        requires: ['computeStyles'],
                        fn: ({ state }) => {
                            const hideData = state.modifiersData?.hide

                            if(
                                attributes.placement !== state.placement ||
                                attributes.referenceHidden !== hideData?.referenceHidden ||
                                attributes.escaped !== hideData?.escaped
                            ) {
                                setAttributes({
                                    placement: state.placement,
                                    referenceHidden: hideData?.referenceHidden,
                                    escaped: hideData?.escaped
                                })
                            }

                            state.attributes.popper = {}
                        }
                    }
                ]
            }
        })
    }, [attributes?.placement, attributes?.referenceHidden, attributes?.escaped, ...dependencies])

    const Output = () => {
        if(render) {
            return render({
                ...attributes,
                ...singletonContent,
                ...mutableBox.instance
            })
        }

        return (
            <Wrapper {...wrapperClassName ? { className: wrapperClassName } : null}>
                {content}
            </Wrapper>
        )
    }

    return (
        <>
            {isValidElement(children) ?
                cloneElement(children, {
                    ref(node) {
                        mutableBox.ref = node
                        preserveRef(children.ref, node)
                    }
                }) :
                null
            }
            {mounted && createPortal(
                <Output />,
                mutableBox.container
            )}
        </>
    )
}

const filterTippyProps = props => pick(props,
    'allowHTML',
    'animateFill',
    'animation',
    'appendTo',
    'aria',
    'arrow',
    'className',
    'content',
    'delay',
    'duration',
    'followCursor',
    'getReferenceClientRect',
    'hideOnClick',
    'ignoreAttributes',
    'inertia',
    'inlinePositioning',
    'interactive',
    'interactiveBorder',
    'interactiveDebounce',
    'maxWidth',
    'moveTransition',
    'offset',
    'onAfterUpdate',
    'onBeforeUpdate',
    'onClickOutside',
    'onCreate',
    'onDestroy',
    'onHidden',
    'onHide',
    'onMount',
    'onShow',
    'onShown',
    'onTrigger',
    'onUntrigger',
    'placement',
    'plugins',
    'popperOptions',
    'render',
    'role',
    'showOnCreate',
    'sticky',
    'theme',
    'touch',
    'trigger',
    'triggerTarget',
    'zIndex'
)

const preserveRef = (ref, node) => {
    if(ref) {
        if(typeof ref === 'function') {
            ref(node)
        } else {
            ref.current = node
        }
    }
}

export const Trigger = forwardRef(({ lineHeight: $lineHeight, size = 16, icon: Icon = TriggerIcon, ...props }, ref) => (
    <TriggerWrapper
        {...props}
        $lineHeight={$lineHeight}
        ref={ref}>
        {!!Icon && <Icon size={size} />}
    </TriggerWrapper>
))

export default forwardRef((props, ref) => (
    <Tippy
        {...props}
        ref={ref} />
))

export const useSingleton = useSingletonGenerator
import React, { forwardRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { parseISO, format, formatDistanceToNowStrict, addDays } from 'date-fns'
import { getSurveyRunUrl } from 'utilities/url'
import { capitalize } from 'utilities/string'
import {
    SurveyRun,
    DateCell, ScoreCell, SentToCell, ResponsesCell, ResponseRateCell
} from './s'
import Link from 'components/link'
import Paragraph from 'components/typography/paragraph'
import { CaptionSmall } from 'components/typography/caption'
import MobileLabel from 'components/typography/mobile-label'

const EmployeeSatisfactionRun = forwardRef(({ run }, ref) => {
    const { dateLocale: locale } = useI18n()

    const {
        startDate,
        score,
        userCount,
        total,
        open
    } = run

    return (
        <SurveyRun
            ref={ref}
            className={open ? 'ongoing' : ''}>
            <DateCell>
                <Link to={getSurveyRunUrl(run)}>
                    <Paragraph className="compact">
                        {format(parseISO(startDate), 'PP', { locale })}
                    </Paragraph>
                    {open && (
                        <CaptionSmall className="compact">
                            <FormattedMessage
                                id="employee_satisfaction_statistic_time_remaining"
                                defaultMessage="{time} remaining"
                                values={{
                                    time: capitalize(
                                        formatDistanceToNowStrict(addDays(parseISO(startDate), 7), { locale })
                                    )
                                }} />
                        </CaptionSmall>
                    )}
                </Link>
            </DateCell>
            <ScoreCell>
                <Paragraph className="compact">
                    <MobileLabel className="endspace">
                        <FormattedMessage
                            id="employee_satisfaction_label_score"
                            defaultMessage="Score" />
                    </MobileLabel>
                    {total === 0 ? '-' : score}
                </Paragraph>
            </ScoreCell>
            <SentToCell>{userCount}</SentToCell>
            <ResponsesCell>{total}</ResponsesCell>
            <ResponseRateCell>
                <FormattedMessage
                    id="value_percent"
                    defaultMessage={`{percent} %`}
                    values={{ percent: parseInt(total / userCount * 100) }} />
            </ResponseRateCell>
        </SurveyRun>
    )
})

export default EmployeeSatisfactionRun

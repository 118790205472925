import React from 'react'
import { useIntl } from 'react-intl'
import { useI18n } from 'contexts/i18n'
import { safeFormat } from 'utilities/date-time'
import { Wrapper } from './s'
import Paragraph from 'components/typography/paragraph'
import YearsAndMonthsSince from 'components/time-years-and-months-since'

const CustomTooltip = ({ start }) => {
    const { formatMessage } = useIntl()
    const { dateLocale: locale } = useI18n()

    if(!start) {
        return null
    }

    const seniorityLabel = formatMessage({
        id: 'person_label_employment_seniority',
        defaultMessage: 'Seniority'
    })

    const dateLabel = formatMessage({
        id: 'person_label_employment_contract_date_start',
        defaultMessage: 'Employment start date'
    })

    return (
        <Wrapper>
            <Paragraph className="compact small">
                {seniorityLabel}: <YearsAndMonthsSince start={start} />
            </Paragraph>
            <Paragraph className="caption compact small">
                {dateLabel}: {safeFormat(start, 'PPP', { locale })}
            </Paragraph>
        </Wrapper>
    )
}

export default CustomTooltip
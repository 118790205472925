import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSalaryPeople } from 'contexts/salary-people'
import { getDate } from 'utilities/date-time'
import {
    containerProps, CardList,
    SkeletonListRow, SkeletonCell,
    LoadingContainer
} from './s'
import {
    useSkeletonLoader,
    SkeletonAvatarAndFullName, SkeletonStrings
} from 'components/skeleton'
import Tutorial from 'components/tutorial'
import Person from './person'
import { Ghost } from 'components/button'
import Loader from 'components/loader'

const SalaryPeople = props => {
    const {
        people = [],

        intersecter,
        loading,
        paging = {},
        filter = {},
        fetchPeople,
        fetching,
        autoFetch,
        hasFetched
    } = useSalaryPeople()

    const skeletonLength = useSkeletonLoader(people, hasFetched)

    const noPeople = !!hasFetched && !people.length

    if(noPeople) {
        return <Tutorial which="salary-revision" />
    }

    const today = getDate()

    return (
        <>
            <CardList {...containerProps}>
                <CardList.Header>
                    <CardList.Cell $cell="name">
                        <FormattedMessage
                            id="noun_name"
                            defaultMessage="Name" />
                    </CardList.Cell>
                    <CardList.Cell $cell="seniority">
                        <FormattedMessage
                            id="person_label_employment_seniority"
                            defaultMessage="Seniority" />
                    </CardList.Cell>
                    <CardList.Cell $cell="current">
                        <FormattedMessage
                            id={filter?.onDate === today ?
                                'salary_label_current_salary'
                                : 'noun_salary'
                            }
                            defaultMessage={filter?.onDate === today ?
                                'Current salary'
                                : 'Salary'
                            } />
                    </CardList.Cell>
                    <CardList.Cell $cell="effective">
                        <FormattedMessage
                            id="noun_effective_period"
                            defaultMessage="Effective period" />
                    </CardList.Cell>
                </CardList.Header>
                {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow
                        columns={4}
                        key={`list:salary:people:skeleton:${index}`}>
                        <SkeletonCell $cell="name">
                            <SkeletonAvatarAndFullName />
                        </SkeletonCell>
                        <SkeletonCell $cell="seniority">
                            <SkeletonStrings />
                        </SkeletonCell>
                        <SkeletonCell $cell="current">
                            <SkeletonStrings />
                        </SkeletonCell>
                        <SkeletonCell $cell="effective">
                            <SkeletonStrings />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {people.map((person, index) => {
                    const last = index + 1 === people.length

                    return (
                        <Person
                            {...props}
                            person={person}
                            {...(last ? { ref: intersecter } : null)}
                            key={person.id} />
                    )
                })}
            </CardList>
            {!!paging?.hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost
                            className="constructive"
                            onClick={fetchPeople}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
        </>
    )
}

export default SalaryPeople
import React from 'react'
import { useRoles } from 'contexts/roles'
import { Grid } from './s'
import Role from './role'

const Roles = ({ className }) => {
    const { roles } = useRoles()

    return (
        <Grid {...(className ? { className } : null)}>
            {roles.map(role => (
                <Role
                    role={role}
                    key={role.id} />
            ))}
        </Grid>
    )
}

export default Roles
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'

const ViewCheckbox = ({ field = {}, className, label }) => {
    let { value } = field

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                <ValueDisplay>
                    <FormattedMessage
                        id={value ? 'boolean_true' : 'boolean_false'}
                        defaultMessage={value ? 'Yes' : 'No'} />
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewCheckbox

import OriginalYoutube from '@tiptap/extension-youtube'
import { mergeAttributes, nodePasteRule } from '@tiptap/core'
import { getEmbedUrlFromYoutubeUrl, isValidYoutubeUrl, YOUTUBE_REGEX_GLOBAL } from './utils'
import { pick } from 'utilities/object'

// We’re extending the original Youtube extension for a couple of reasons:
// 1. To limit the number of attributes ending up in the final HTML
// 2. To be able to add our override params to more types of YouTube URLs

export const Youtube = OriginalYoutube.extend({
    addCommands() {
        return {
            setYoutubeVideo: options => ({ commands }) => {
                if(!isValidYoutubeUrl(options.src)) {
                    return false
                }

                return commands.insertContent({
                    type: this.name,
                    attrs: options
                })
            }
        }
    },

    addPasteRules() {
        if(!this.options.addPasteHandler) {
            return []
        }

        return [
            nodePasteRule({
                find: YOUTUBE_REGEX_GLOBAL,
                type: this.type,
                getAttributes: match => {
                    return { src: match.input }
                }
            })
        ]
    },

    renderHTML({ HTMLAttributes }) {
        const embedUrl = getEmbedUrlFromYoutubeUrl({
            url: HTMLAttributes.src,
            allowFullscreen: this.options.allowFullscreen,
            autoplay: this.options.autoplay,
            ccLanguage: this.options.ccLanguage,
            ccLoadPolicy: this.options.ccLoadPolicy,
            controls: this.options.controls,
            disableKBcontrols: this.options.disableKBcontrols,
            enableIFrameApi: this.options.enableIFrameApi,
            endTime: this.options.endTime,
            interfaceLanguage: this.options.interfaceLanguage,
            ivLoadPolicy: this.options.ivLoadPolicy,
            loop: this.options.loop,
            modestBranding: this.options.modestBranding,
            nocookie: this.options.nocookie,
            origin: this.options.origin,
            playlist: this.options.playlist,
            progressBarColor: this.options.progressBarColor,
            startAt: HTMLAttributes.start || 0
        })

        HTMLAttributes.src = embedUrl

        const iframeAttributes = pick(mergeAttributes(
            this.options.HTMLAttributes,
            {
                width: this.options.width,
                height: this.options.height,
                allowFullscreen: this.options.allowFullscreen
            },
            HTMLAttributes
        ), 'src', 'width', 'height', 'allow', 'allowFullscreen')

        return [
            'div',
            { 'data-youtube-video': '' },
            ['iframe', iframeAttributes]
        ]
    }
})
import styled from 'styled-components'
import { Tags } from 'components/tags/s'
import { Ghost } from 'components/button'
import { flexChildShrinkCss } from 'components/flex'
import FavIconBase from 'components/favicon'

export const Wrapper = styled.div`
    &:not(.compact) {
        margin-bottom: 24px;
    }
`

export const Links = styled(Tags)`
    flex-wrap: wrap;
    align-items: center;

    width: 100%;
    min-height: 40px;
`

export const Chip = styled(Ghost)`
    ${flexChildShrinkCss}
    display: grid;
    grid-template-columns: 32px calc(100% - 32px);
    align-items: center;

    margin: 0 8px 8px 0;
    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    height: 40px;
    padding: 8px 16px;

    line-height: 24px;

    -webkit-appearance: none;

    &:hover,
    &:focus {
        border-color: var(--huma-color-border-default);
        text-decoration: none;
    }
`

export const FavIcon = styled(FavIconBase)`
    margin-inline-end: 16px;
`
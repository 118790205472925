import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import PersonBase from 'components/person'

const Person = styled(PersonBase)`
    margin: 8px 0;
`

const ViewPerson = ({ className, label, size = 24, showPosition = false, ...props }) => {
    const { field = {} } = props
    const { value = null } = field

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                {!!value && (
                    <Person
                        who={value}
                        showPosition={showPosition}
                        size={size} />
                )}
                {!value && (
                    <ValueDisplay className="empty">
                        <FormattedMessage
                            id="empty_not_picked"
                            defaultMessage="Not picked" />
                    </ValueDisplay>
                )}
            </Control>
        </Field>
    )
}

export default ViewPerson

import styled from 'styled-components'
import { belowPhone } from 'utilities/styled'
import Paragraph from 'components/typography/paragraph'
import { DefinitionList as DefinitionListBase, DefinitionTerm as DefinitionTermBase, DefinitionDescription as DefinitionDescriptionBase } from 'components/list'
import AlertIconBase from 'components/alert-icon'

export const ListHeading = styled(Paragraph)`
    font-weight: 500;
`

export const DefinitionList = styled(DefinitionListBase)`
    gap: 0;

    &:not(:last-of-type) {
        margin-block-end: 48px;
    }

    ${belowPhone`
        grid-template-columns: auto 1fr;
    `}
`

export const DefinitionTerm = styled(DefinitionTermBase)`
    padding-block: 16px;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }
`

export const DefinitionDescription = styled(DefinitionDescriptionBase)`
    display: flex;
    justify-content: flex-end;

    padding-block: 16px;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--huma-color-border-default);
    }

    ${belowPhone`
        &:not(:last-of-type) {
            margin-block-end: 0;
        }
    `}
`

export const FieldStatus = styled.span`
    display: inline-block;

    border-radius: 4px;
    padding-inline: 8px;

    font-size: 14px;
    line-height: 24px;

    &.empty {
        background-color: var(--huma-color-surface-warning-minimal);

        color: var(--huma-color-foreground-default);
    }

    &.no-access {
        background-color: var(--huma-color-surface-minimal);

        color: var(--huma-color-foreground-default);
    }
`

export const AlertIcon = styled(AlertIconBase)`
    margin-inline-end: 0;
`
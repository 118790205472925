import styled from 'styled-components'
import { fromContainer, fromPhone } from 'utilities/styled'
import { Grid as GridBase } from 'components/grid'
import TutorialBase from 'components/tutorial'

export const containerQuery = { name: 'locations', breakpoint: 650 }

export const ContainerQuery = styled.div`
    container-type: inline-size;
    container-name: ${containerQuery.name};
`

export const Grid = styled(GridBase)`
    ${fromContainer(containerQuery)`
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 32px;

        margin-block-start: 16px;
    `}
`

export const LoadingContainer = styled.div`
    position: relative;
    height: 176px;
`

export const EternalLoadingContainer = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 96px;
`

export const Tutorial = styled(TutorialBase)`
    ${fromPhone`
        margin-block-start: 64px;
    `}
`
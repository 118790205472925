import styled, { css } from 'styled-components'

export const markCss = css`
    --__huma-component-mark-background-color: var(--huma-color-surface-highlight-bold);

    position: relative;
    z-index: 1;

    background-color: var(--__huma-component-mark-background-color);
    box-shadow:
        0 4px var(--__huma-component-mark-background-color),
        0 -4px var(--__huma-component-mark-background-color);

    color: var(--huma-color-foreground-on-highlight);

    .sub-label &::after {
        content: "";
        position: absolute;
        z-index: -1;
        inset: -4px 0;
    }
`

export const highlightCss = css`
    --__huma-component-mark-background-color: var(--huma-color-surface-highlight-subtle);

    background-color: var(--__huma-component-mark-background-color);
    box-shadow:
        0 4px var(--__huma-component-mark-background-color),
        0 -4px var(--__huma-component-mark-background-color);

    color: inherit;
`

export default styled.mark`
    ${markCss}
`

export const Highlight = styled.mark`
    ${highlightCss}
`
import React from 'react'
import { Content, Objekt, Embed } from './s'

const PortableDocumentFormat = ({ file, url }) => (
    <Content className="pdf">
        <Objekt data={url} type={file.type} name={file.name}>
            <Embed src={url} type={file.type} />
        </Objekt>
    </Content>
)

export default PortableDocumentFormat
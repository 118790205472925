import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { motion } from 'framer-motion'
import { Grid } from 'components/grid'
import TextBase from 'components/form/input/text'
import { Naked, Plain } from 'components/button'
import { paragraphSmallCss } from 'components/typography/paragraph'

export const DragDropWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const Sortables = styled.div`
    flex-grow: 1;

    &:not(:empty) {
        margin-block-start: 4px;
    }
`

export const SortableItem = styled(Grid)`
    grid-auto-flow: column;
    grid-template-columns: minmax(0, 24px) minmax(0, 1fr) minmax(0, 24px);
    grid-column-gap: 8px;
    align-items: center;

    margin-block-end: 16px;
    border-radius: 8px;
    height: 40px;
    background-color: var(--huma-color-surface-default);

    ${p => !!p.$isDragging && css`
        box-shadow: var(--huma-shadow-lowest);
    `}
`

export const DragColumn = styled.div`
    place-items: center;

    color: var(--huma-color-foreground-minimal);

    svg {
        ${svgStroke}
    }
`

export const Text = styled(TextBase)`
    &.last.empty:not(:focus) {
        border-style: dashed;
    }
`

export const DeleteButton = styled(Naked)`
    display: grid;
    place-items: center;

    width: 24px;
    height: 40px;

    transition: color .1s ease-in-out;

    color: var(--huma-color-foreground-on-disabled);

    &:is(:hover, :focus) {
        color: var(--huma-color-foreground-destructive) !important;
    }

    svg {
        ${svgStroke}
    }
`

export const AddButton = styled(Plain)`
    margin-block-start: -8px;

    svg {
        ${svgStroke()}
        margin-inline-end: 20px; /* Aligns with the inputs above */
    }
`

export const Error = styled(motion.p).attrs(() => ({
    className: 'destructive',
    variants: {
        hide: {
            opacity: 0,
            pointerEvents: 'none',
            transition: {
                duration: .2
            }
        },
        reveal: {
            opacity: 1,
            pointerEvents: 'auto',
            transition: {
                duration: .5
            }
        }
    },
    initial: 'hide'
}))`
    position: absolute;
    top: calc(100% + 8px);
    left: auto;
    ${paragraphSmallCss}
`
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { Grid } from 'components/grid'
import { Rows } from 'components/flex'
import { Naked } from 'components/button'
import Paragraph from 'components/typography/paragraph'

export const Invoice = styled(Grid)`
    grid-template-columns: minmax(0, 3fr) repeat(3, minmax(0, 2fr));
    column-gap: 24px;
    width: 100%;
    padding: 24px 0;
    position: relative;
    box-shadow: 0 -1px var(--huma-color-border-default);

    svg {
        ${svgStroke()}
    }
`

export const PlanCell = styled(Rows)`
    align-items: flex-start;
    max-width: 100%;
`

export const PreviewButton = styled(Naked)`
    max-width: 100%;
    text-align: left;
`

export const PlanName = styled(Paragraph)`
    ${PreviewButton}:hover & {
        text-decoration: underline;
    }
`

const Cell = styled.div`
    line-height: 24px;
    color: var(--huma-color-foreground-subtle);
`

export const InvoiceDateCell = Cell
export const DueAtCell = Cell
export const TotalCell = Cell

export const StatusLayout = styled(Grid)`
    grid-template-columns: 16px auto;
    column-gap: 8px;
    align-items: center;
    height: 24px;
`

export const DownloadCell = Cell

export const DownloadLink = styled.a`
    display: grid;
    place-items: center;
    width: 40px;
    height: 40px;
    margin-block: -8px;
    border-radius: 8px;
    color: var(--huma-color-foreground-default);
    text-decoration: none;
    transition: background-color 1s ease;

    &:focus,
    &:hover {
        transition: background-color .2s ease;
    }

    &:hover {
        background-color: var(--huma-color-surface-minimal);
    }

    &:focus {
        background-color: var(--huma-color-surface-minimal-hover);
    }

    svg {
        ${svgStroke()}
    }
`

import React from 'react'
import { useEditorMeta } from '../extensions/utilities'
import { map } from 'utilities/object'
import Tooltip from './tooltip'
import { Section, Button } from './s'

const ToolbarHeadings = ({ editor, every, activate, getTrigger, active, tooltipsEnabled, tooltipTarget, salt }) => {
    const meta = useEditorMeta()

    if(!every('Heading')) {
        return null
    }

    const heading = editor.options.extensions.find(({ name }) => name === 'heading')

    let levels = heading.options.levels
    if(heading.options.titled) {
        levels = levels.filter(level => level !== 1)
    }

    if(!levels.length) {
        return null
    }

    const headings = levels.reduce((accumulator, level) => ({
        ...accumulator,
        [`Heading${level}`]: {
            onClick: getTrigger('toggleHeading', { level }),
            disabled: !editor.can().toggleHeading?.({ level }) ?? true,
            ...activate(active[`h${level}`]),
            level
        }
    }), {})

    return (
        <Section>
            {map(headings, ({ level, ...attributes }) => {
                const {
                    label,
                    shortcut,
                    icon
                } = meta.Heading({ level })

                const key = `${salt}:heading:${level}`

                return (
                    <Tooltip
                        content={label}
                        shortcut={shortcut}
                        enabled={tooltipsEnabled}
                        singleton={tooltipTarget}
                        salt={key}
                        key={key}>
                        <Button
                            {...attributes}
                            tabIndex={-1}>
                            {icon}
                        </Button>
                    </Tooltip>
                )
            })}
        </Section>
    )
}



export default ToolbarHeadings
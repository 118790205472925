import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import DisplaySymbol from 'components/symbol'
import { cls } from 'utilities/dom'

const ViewSymbol = ({ className, label, ...props }) => {
    const {
        field = {},
        controlProps = {},
        display
    } = props

    const { value = null } = field

    const displayClassName = value ?
        display :
        'empty'

    const controlClassName = cls([
        controlProps.className,
        display
    ])

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control {...(controlClassName ? { className: controlClassName } : null)}>
                <ValueDisplay {...(displayClassName ? { className: displayClassName } : null)}>
                    {!!value && <DisplaySymbol symbol={value} />}
                    {!value && (
                        <FormattedMessage
                            id="empty_not_set"
                            defaultMessage="Not set" />
                    )}
                </ValueDisplay>
            </Control>
        </Field>
    )
}

export default ViewSymbol

import styled from 'styled-components'
import { belowTablet, belowPhone, numbers, darkMode } from 'utilities/styled'
import { motion } from 'framer-motion'

const belowPhoneVariants = {
    out: {
        top: '33.33vh',
        opacity: 0,
        pointerEvents: 'none',
        transition: {
            type: 'spring',
            mass: 0.25,
            stiffness: 200,
            velocity: 4,
            damping: 10
        }
    },
    in: {
        top: 0,
        opacity: 1,
        pointerEvents: 'auto',
        transition: {
            type: 'spring',
            mass: 0.5,
            stiffness: 100,
            velocity: 2
        }
    }
}

const fromPhoneVariants = {
    out: {
        opacity: 0,
        pointerEvents: 'none',
        transition: {
            duration: .15,
        }
    },
    in: {
        opacity: 1,
        pointerEvents: 'auto',
        transition: {
            duration: .15
        }
    }
}

export const Container = styled(motion.div).attrs(({ afterOpen, afterClose, ...attrs }) => ({
    ...attrs,

    variants: document.documentElement.offsetWidth < numbers.phone ?
        belowPhoneVariants :
        fromPhoneVariants,
    initial: 'out',
    exit: 'out',

    onChange: e => void e.stopPropagation(),

    onAnimationComplete: variant => {
        if(variant === 'in') {
            afterOpen?.()
        } else {
            afterClose?.()
        }
    }
}))`
    position: relative;
    display: grid;

    margin: 16px;
    border-radius: 8px;
    width: calc(100vw - 32px);
    height: calc(100vh - 32px);
    max-height: calc(100dvh - 32px);

    color: var(--huma-color-foreground-default);

    ${darkMode`
        border: 1px solid var(--huma-color-border-default);
    `}

    ${belowTablet`
        margin: 32px 0 0;
        width: 100vw;
    `}

    ${belowPhone`
        border-radius: 16px;
    `}
`
import styled, { css } from 'styled-components'
import {
    svgStroke,
    belowTablet, fromPhone
} from 'utilities/styled'
import { Grid, GridColumnHeader as GridColumnHeaderBase } from 'components/grid'
import FormBase from 'components/form/controller'
import TutorialBase from 'components/tutorial'
import { SkeletonListRow as SkeletonListRowBase } from 'components/skeleton'
import { templateGrid } from './template/s'

export const Wrap = styled.div`
    &:not(.compact) {
        ${fromPhone`
            margin-block-start: 16px;
        `}
    }
`

export const Header = styled(Grid)`
    grid-template-columns: minmax(0, 7fr) minmax(0, 4fr) 3fr;
    column-gap: 24px;

    border-bottom: 1px solid var(--huma-color-border-default);
    width: 100%;

    ${belowTablet`
        display: none;
    `}
`

const gridColumnHeaderCss = css`
    display: flex;
    align-items: center;

    height: 32px;
    padding-block-end: 16px;
`

export const GridColumnHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}
`

export const GridColumnActionsHeader = styled(GridColumnHeaderBase)`
    ${gridColumnHeaderCss}

    justify-content: center;

    > svg {
        ${svgStroke()}
    }
`

export const Form = styled(FormBase)`
    max-width: 250px;

    ${belowTablet`
        margin-block-end: 24px;
    `}
`

export const LoadingContainer = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 96px;
`

export const Tutorial = styled(TutorialBase)`
    ${fromPhone`
        margin-block-start: 64px;
    `}
`

export const SkeletonListRow = styled(SkeletonListRowBase)`
    ${templateGrid}

    align-items: center;
`
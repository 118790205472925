import React, { useState, useEffect } from 'react'
import { Content, PlaintextContent, Preintext } from './s'
import 'blob-polyfill'

const Plaintext = ({ raw }) => {
    const [text, setText] = useState(null)

    useEffect(() => {
        const convert = async () => {
            const text = await raw.text()
            setText(text)
        }

        convert()
    }, [raw])

    if(!text) {
        return null
    }

    return (
        <Content className="plaintext">
            <PlaintextContent>
                <Preintext>
                    {text}
                </Preintext>
            </PlaintextContent>
        </Content>
    )
}

export default Plaintext
import styled, { css } from 'styled-components'
import {
    svgStroke, flashKeyframes,
    fromPhone, belowPhone
} from 'utilities/styled'
import { Grid } from 'components/grid'
import Link from 'components/link'
import SymbolBase from 'components/symbol'
import { ancestorHover } from 'components/symbol/s'
import { InlineMessage } from 'components/message'

export const PieceWrapper = styled(Grid)`
    grid-template-columns: minmax(0, 2fr) repeat(2, 1fr) 40px;
    column-gap: 24px;

    width: 100%;
    padding-block: 24px;
    box-shadow: 0 -1px var(--huma-color-border-default);

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }

    ${belowPhone`
        grid-template-columns: 1fr 40px;
        grid-auto-flow: row;
        grid-auto-rows: auto;
        row-gap: 8px;
    `}
`

export const EquipmentCell = styled(Link)`
    display: grid;
    grid-template-columns: 40px minmax(0, 1fr);
    column-gap: 16px;
    align-items: center;

    height: 40px;

    text-align: start;

    &:where(:hover, :focus) {
        text-decoration: none;
    }

    ${belowPhone`
        grid-row: 1 / 2;
    `}
`

export const Symbol = styled(SymbolBase)`
    ${ancestorHover(EquipmentCell)}
`

export const EquipmentTypeTitle = styled.div`
    line-height: 24px;

    ${EquipmentCell}:hover &,
    ${EquipmentCell}:focus & {
        text-decoration: underline;
    }
`

export const EquipmentMessage = styled(InlineMessage)`
    & p {
        line-height: 20px;
    }
`

const cellCss = css`
    display: grid;
    align-items: start;

    padding-block: 8px;
`

export const StatusCell = styled.div`
    ${cellCss}

    & > :last-child {
        margin-block-end: 0;
    }

    ${belowPhone`
        grid-column: 1 / 2;
        grid-row: 3 / 4;

        padding-inline-start: 56px;

        &:empty {
            display: none;
        }
    `}
`

export const AssignedToCell = styled.div`
    ${cellCss}

    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4px;

    font-size: 14px;
    color: var(--huma-color-foreground-subtle);

    div {
        span:nth-child(2) {
            margin-inline-start: 4px;

            &::before {
                content: "(";
            }

            &::after {
                content: ")";
            }
        }
    }

    ${fromPhone`
        line-height: 24px;
    `}

    ${belowPhone`
        grid-column: 1 / -1;
        grid-row: 2 / 3;
        flex-wrap: wrap;

        padding-inline-start: 56px;
    `}
`

export const ActionsCell = styled.div`
    justify-content: center;

    ${belowPhone`
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    `}

    svg {
        ${svgStroke()}
    }
`

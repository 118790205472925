import { omit } from 'utilities/object'

export const YOUTUBE_REGEX = /^((?:https?:)?\/\/)?((?:www|m|music)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/
export const YOUTUBE_REGEX_GLOBAL = /^((?:https?:)?\/\/)?((?:www|m|music)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/g

export const isValidYoutubeUrl = url => !!url.match(YOUTUBE_REGEX)

export const getYoutubeEmbedUrl = (nocookie = true) => nocookie ?
    'https://www.youtube-nocookie.com/embed/' :
    'https://www.youtube.com/embed/'

export const getEmbedUrlFromYoutubeUrl = ({
    url,
    allowFullscreen,
    autoplay,
    ccLanguage,
    ccLoadPolicy,
    controls,
    disableKBcontrols,
    enableIFrameApi,
    endTime,
    interfaceLanguage,
    ivLoadPolicy,
    loop,
    modestBranding,
    nocookie,
    origin,
    playlist,
    progressBarColor,
    startAt
}) => {
    if(typeof url === 'string') {
        url = url.replace(/-nocookie/, '')
    }

    if(!isValidYoutubeUrl(url)) {
        return null
    }

    let params = {}

    const setParam = (key, value) => {
        if(key in params) {
            return
        }

        params[key] = value
    }

    if(url.includes('?')) {
        const parsed = new URL(url)
        params = omit(Object.fromEntries(new URLSearchParams(parsed.search)), 'v')
    }

    let id

    // if is a youtu.be url, get the id after the /
    if(url.includes('youtu.be')) {
        id = url.split('/').pop()

        if(!id) {
            return null
        }
    }

    if(!id) {
        const videoIdRegex = /(?:v=|shorts|embed\/)([-\w]+)/gm
        const matches = videoIdRegex.exec(url)

        if(!matches?.[1]) {
            return null
        }

        id = matches[1]
    }

    let outputUrl = `${getYoutubeEmbedUrl(nocookie)}${id}`

    if(allowFullscreen === false) {
        setParam('fs', 0)
    }

    if(autoplay) {
        setParam('autoplay', 1)
    }

    if(ccLanguage) {
        setParam('cc_lang_pref', ccLanguage)
    }

    if(ccLoadPolicy) {
        setParam('cc_load_policy', 1)
    }

    if(!controls) {
        setParam('controls', 0)
    }

    if(disableKBcontrols) {
        setParam('disablekb', 1)
    }

    if(enableIFrameApi) {
        setParam('enablejsapi', 1)
    }

    if(endTime) {
        setParam('end', endTime)
    }

    if(interfaceLanguage) {
        setParam('hl', interfaceLanguage)
    }

    if(ivLoadPolicy) {
        setParam('iv_load_policy', ivLoadPolicy)
    }

    if(loop) {
        setParam('loop', 1)
    }

    if(modestBranding) {
        setParam('modestbranding', 1)
    }

    if(origin) {
        setParam('origin', origin)
    }

    if(playlist) {
        setParam('playlist', playlist)
    }

    if(startAt) {
        setParam('start', startAt)
    }

    if(progressBarColor) {
        setParam('color', progressBarColor)
    }

    if(Object.values(params).length) {
        outputUrl += `?${new URLSearchParams(params)}`
    }

    return outputUrl
}
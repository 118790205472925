import React, { Component, createRef } from 'react'
import { pick, omit } from 'utilities/object'
import { DigitColumns, DigitColumn, DigitInput } from './s'

class VerificationCodeInput extends Component {
    constructor(props) {
        super(props)

        const {
            value = '',
            length = 6,
            isValid = true,
            disabled = false
        } = props

        const data = new Array(length)
            .fill()
            .map((_, index) => value?.[index] ?? '')

        this.state = {
            value,
            data,
            length,
            isValid,
            disabled
        }

        this.digits = new Array(length)
            .fill()
            .map(createRef)
    }

    componentDidUpdate(props) {
        const relevant = pick(this.props, 'value', 'isValid', 'disabled')
        const changed = Object.entries(relevant)
            .filter(([key, value]) => value !== props[key])

        if(changed.length) {
            this.setState(Object.fromEntries(changed))
        }
    }

    onChange = e => {
        const {
            data,
            length
        } = this.state

        const { onChange } = this.props

        const copy = [...data]
        let { value } = e.target

        // Ensure numeric
        value = value
            .replace(/[^\d]/g, '')
            .split('')
            .filter(character => !Object.values(rejectKeymap).includes(character))
            .join('')

        let composite = value

        if(value) {
            const cursor = Number(e.target.dataset.index) ?? 0

            if(value.length > 1) {
                value
                    .split('')
                    .forEach((digit, index) => {
                        const nextIndex = cursor + index

                        if(nextIndex < length) {
                            copy[nextIndex] = digit
                        }
                    })
            } else {
                copy[cursor] = value
            }

            copy.forEach((value, index) => {
                const { current: digit } = this.digits[index]
                digit.value = value
            })

            const { current: next } = this.digits.find(({ current }) => !current?.value) ?? this.digits[this.digits.length - 1]
            this.move(next)

            composite = copy.join('')
        }

        this.setState({
            value: composite,
            data: copy
        }, () => onChange?.(this.state.value))
    }

    onKeyDown = e => {
        const { data } = this.state
        const { onChange } = this.props

        if(Object.keys(rejectKeymap).includes(e.keyCode)) {
            e.preventDefault()
            return
        }

        const current = Number(e.target.dataset.index)
        const { current: next } = this.digits[current + 1] ?? {}
        const { current: previous } = this.digits[current - 1] ?? {}

        const key = keymap[e.keyCode]
        if(!!key) {
            e.preventDefault()
        }

        if(key === 'backspace') {
            const copy = [...data]
            const value = copy[current]

            copy[current] = ''
            e.target.value = ''

            this.setState({
                value: copy.join(''),
                data: copy
            }, () => {
                !value && this.move(previous)
                onChange?.(this.state.value)
            })
        }

        if(key === 'left') {
            this.move(previous)
        }

        if(key === 'right') {
            this.move(next)
        }
    }

    move = to => {
        to?.focus?.()
        to?.select?.()
    }

    render() {
        const {
            className,
            autoFocus = true,
            id,
            salt,
            ...props
        } = this.props

        const {
            data,
            isValid,
            disabled
        } = this.state

        return (
            <DigitColumns {...{ className }}>
                {data.map((value, index) => (
                    <DigitColumn key={`${salt}:digit:${index}`}>
                        <DigitInput
                            {...omit(props, 'value', 'onChange', 'id', 'salt')}
                            {...(id && (index === 0)) ? { id } : {}}
                            value={value}
                            type="number"
                            min={0}
                            max={9}
                            maxLength={1}
                            onKeyDown={this.onKeyDown}
                            onChange={() => {}}
                            onInput={this.onChange}
                            autoFocus={!!autoFocus && (index === 0)}
                            autoComplete="off"
                            data-index={index}
                            data-valid={isValid}
                            disabled={disabled}
                            ref={this.digits[index]} />
                    </DigitColumn>
                ))}
            </DigitColumns>
        )
    }
}

const keymap = {
    8: 'backspace',
    37: 'left',
    38: 'up',
    39: 'right',
    40: 'down',
    69: 'e'
}

const rejectKeymap = {
    189: '-',
    190: '.'
}

export default VerificationCodeInput
import styled from 'styled-components'
import { fontMonospace } from 's'

const Key = styled.kbd`
    ${fontMonospace}

    border: 1px solid var(--huma-color-border-default);
    border-radius: 4px;
    padding: 2px 4px;
    background-color: var(--huma-color-surface-minimal);

    font-size: 0.75em;
`

export default Key

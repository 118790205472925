import styled, { css } from 'styled-components'
import { flexColumnsCss, FlexChild, FlexChildShrink } from 'components/flex'

const containerCss = css`
    align-items: center;

    height: 40px;

    color: var(--huma-color-foreground-default);
`

export const Organization = styled.div`
    ${flexColumnsCss}
    ${containerCss}
`

export const IconColumn = styled(FlexChildShrink)`
    margin-inline-end: 24px;
    padding: ${({ $iconSize }) => $iconSize ? $iconSize * 1/3 : 8}px;

    svg {
        stroke-width: 2;
    }
`

export const DetailsColumn = styled(FlexChild)`
    font-size: 16px;
    line-height: 1;
`

const BaseParagraph = styled.p`
    font-size: 16px;
    line-height: 24px;

    &.nowrap {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`

export const Name = styled(BaseParagraph)`
    color: var(--huma-color-foreground-default);
`

export const EmployeeCount = styled(BaseParagraph)`
    color: var(--huma-color-foreground-subtle);
`

import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { OrderedListColumns, ListItemChildShrink } from 'components/list'
import { Naked } from 'components/button'
import { FlexChildShrink } from 'components/flex'

export const UnitsWrap = styled(OrderedListColumns)`
    flex-wrap: wrap;

    &.blob {
        gap: 4px;

        min-height: 40px;
        padding-block: 4px;

        line-height: 40px;

        > p:last-child {
            margin-inline-start: 4px;
        }
    }

    &.text {
        align-items: center;
        column-gap: 16px;

        min-height: 24px;

        line-height: 24px;
    }
`

export const UnitWrap = styled(ListItemChildShrink)`
    display: grid;
    grid-auto-flow: column;
    column-gap: 4px;

    max-width: 100%;

    &.overflow::after {
        content: '+' attr(data-overflow);

        font-size: 12px;
        color: var(--huma-color-foreground-subtle);
    }

    ${UnitsWrap}.blob & {
        height: 32px;

        &.overflow::after {
            height: inherit;

            line-height: 32px;
        }
    }
`

const unitCss = css`
    display: block;
    position: relative;
    font-size: 14px;

    ${UnitWrap}.blob & {
        border-radius: 4px;
        height: 32px;
        background-color: var(--huma-color-surface-minimal);

        line-height: 32px;
        color: var(--huma-color-foreground-default);
    }

    ${UnitWrap}.text & {
        height: 24px;

        line-height: 24px;
    }

    span {
        color: var(--huma-color-foreground-subtle);
    }
`

export const IrremovableUnit = styled.div`
    ${unitCss}

    overflow: hidden;

    max-width: 100%;
    padding: 0 12px 0 32px;

    text-overflow: ellipsis;
    white-space: nowrap;

    ${UnitWrap}.text & {
        padding: 0 0 0 24px;
    }
`

export const TypeSymbolWrap = styled.span`
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 8px;
    left: 8px;

    ${UnitWrap}.text & {
        top: 4px;
        left: 0;
    }

    > svg {
        position: absolute;
        top: 0;
        left: 0;

        ${svgStroke('small')}
    }
`

export const RemovableUnit = styled(Naked)`
    ${unitCss}

    cursor: pointer;
    overflow: hidden;

    max-width: 100%;
    padding: 0 32px;

    transition: all .1s ease-in-out;

    text-overflow: ellipsis;
    white-space: nowrap;

    ${UnitWrap}.blob & {
        background-color: var(--huma-color-surface-elevated-active);

        &:where(:hover, :focus, :active) {
            background-color: var(--huma-color-surface-destructive-minimal);

            &,
            & ${TypeSymbolWrap} {
                color: var(--huma-color-foreground-on-destructive-minimal);
            }
        }
    }
`

export const XCircle = styled.span`
    position: absolute;
    top: 8px;
    right: 8px;
    display: block;

    border-radius: 100%;
    width: 16px;
    height: 16px;
    background-color: transparent;

    transition: background-color .1s ease-in-out;

    ${RemovableUnit}:where(:hover, :focus, :active) & {
        background-color: var(--huma-color-surface-default);
    }

    svg {
        position: absolute;
        top: 2px;
        left: 2px;

        ${svgStroke('small')}
    }
`

export const Rest = styled(FlexChildShrink).attrs({ as: 'p' })`
    position: relative;

    opacity: .75;

    font-size: 12px;
    line-height: 1;

    ${UnitsWrap}.blob & {
        align-self: center;
    }

    ${UnitsWrap}.text & {
        margin-inline-start: -12px;
        padding-block-start: 2px;
    }
`
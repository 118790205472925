import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Field, Label, Control, ValueDisplay } from 'components/form/field/s'
import { empty, getOptionId } from './'
import { Units } from 'components/units'
import AvatarsBase from 'components/avatars'

const Avatars = styled(AvatarsBase)`
    height: 40px;
`

const ViewShare = ({ className, label, salt, ...props }) => {
    const {
        name,
        field = {},
        picker = {},
        publicOption
    } = props

    const {
        types,
        locked = empty
    } = picker

    let { value } = field
    if(!value) {
        value = empty
    }

    salt = `${salt}:${name}`

    const isPublic = value.length && getOptionId(value[0]) === getOptionId(publicOption)

    const shares = isPublic ?
        value :
        [...locked, ...value]

    const organizationAllowed = types.includes('organization')
    const userAllowed = types.includes('user')

    const onlyUsers = !isPublic && shares.length > 2 && (
        (types?.length === 1 && userAllowed) ||
        (types?.length === 2 && organizationAllowed && userAllowed)
    )

    return (
        <Field {...(className ? { className } : null)}>
            {!!label && <Label>{label}</Label>}
            <Control>
                {(!!shares.length && !onlyUsers) && (
                    <Units
                        units={shares}
                        salt={salt} />
                )}
                {(!!shares.length && !!onlyUsers) && (
                    <Avatars
                        people={shares.map(({ user }) => user)}
                        max={10}
                        salt={salt} />
                )}
                {!shares.length && (
                    <ValueDisplay className="empty">
                        <FormattedMessage
                            id="empty_none_picked"
                            defaultMessage="None picked" />
                    </ValueDisplay>
                )}
            </Control>
        </Field>
    )
}

export default ViewShare
import styled from 'styled-components'
import {
    svgStroke,
    fromContainer, belowContainer
} from 'utilities/styled'
import { containerProps } from '../s'
import Link from 'components/link'
import Paragraph from 'components/typography/paragraph'

export const Row = styled(Link)`
    display: grid;

    min-height: 76px;
    padding-block: 16px;

    &:not(:has(+ div), :last-of-type) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    ${fromContainer(containerProps)`
        grid-template-columns: subgrid;
        grid-column: 1 / -1;
    `}

    ${belowContainer(containerProps)`
        grid-template-columns: 1fr auto;
        row-gap: 8px;
    `}

    &:is(:hover, :focus-visible) {
        text-decoration: none;
    }
`

export const Cell = styled.div`
    display: flex;
    flex-direction: column;

    ${fromContainer(containerProps)`
        align-items: start;
        justify-content: center;
        grid-column: ${p => p.$cell};
    `}

    ${belowContainer(containerProps)`
        ${p => p.$cell === 'meta' && `
            grid-row: 1;
        `}

        ${p => p.$cell === 'effective' && `
            flex-direction: row;
            align-items: center;
            grid-row: 2;

            font-size: 14px;
        `}

        ${p => p.$cell === 'status' && `
            grid-row: 3;
        `}

        ${p => p.$cell === 'action' && `
            justify-content: center;
            grid-row: 1;
            grid-column: 2;
        `}
    `}

    &.action {
        svg {
            ${svgStroke()}

            transition: translate .1s ease-in-out;

            ${Row}:hover &,
            ${Row}:focus-visible & {
                translate: 4px;
            }
        }
    }
`

export const Label = styled(Paragraph).attrs({
    as: 'span',
    className: 'caption small compact'
})`
    &::after {
        content: ":";

        margin-inline-end: 4px;
    }

    ${fromContainer(containerProps)`
        display: none;
    `}
`

export const Name = styled(Paragraph)`
    font-weight: 500;

    ${Row}:where(:hover, :focus-visible) & {
        text-decoration: underline;
    }
`
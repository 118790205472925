import React from 'react'
import Modal from 'modals/fullscreen'
import DumbContent from './content/dumb'

const salt = 'modal:feature-roadmap'

const FeatureRoadmapModal = ({ show = false, dismiss }) => (
    <Modal
        show={show}
        dismiss={dismiss}
        salt={salt}>
        {!!show && (
            <DumbContent
                dismiss={dismiss}
                salt={salt} />
        )}
    </Modal>
)

export default FeatureRoadmapModal
import React from 'react'
import { OrderedList, ListItem, Instruction } from './s'

const Instructions = ({ instructions = [], salt, ...props }) => (
    <OrderedList {...props}>
        {instructions.map(({ text = '', content = null }, index) => (
            <ListItem key={`${salt}:instruction:${index}`}>
                {!!text && (
                    <Instruction className="compact">
                        {text}
                    </Instruction>
                )}
                {content}
            </ListItem>
        ))}
    </OrderedList>
)

export default Instructions

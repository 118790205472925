import styled, { css } from 'styled-components'
import { svgStroke } from 'utilities/styled'
import { ListItem } from 'components/list'
import Link from 'components/link'
import { flexColumnsCss } from 'components/flex'
import AvatarBase from 'components/avatar'
import Paragraph from 'components/typography/paragraph'
import { Simple } from 'components/button'

export const PersonItem = styled(ListItem)`
    margin-inline: 32px;
    padding-block: 16px;
    border-bottom: 1px solid var(--huma-color-border-default);

    &:first-of-type {
        padding-top: 0;
    }

    &:last-of-type {
        padding-bottom: 0;
        border-bottom: 0;
    }

    svg {
        ${svgStroke()}
    }
`

const personColumnsCss = css`
    ${flexColumnsCss}
    align-items: center;
    height: 40px;

    &:not(.disabled) {
        cursor: pointer;
    }
`

export const PersonColumnsLink = styled(Link)`
    ${personColumnsCss}

    &:hover,
    &:focus {
        text-decoration: none;
    }
`

export const PersonColumnsLabel = styled.label`
    ${personColumnsCss}
`

export const Avatar = styled(AvatarBase)`
    margin-right: 24px;
    border-radius: 100%;
`

export const Name = styled(Paragraph).attrs({ as: 'h3' })`
    line-height: 20px;

    ${PersonColumnsLink}:hover &,
    ${PersonColumnsLink}:focus & {
        text-decoration: underline;
    }
`

export const JobTitle = styled(Paragraph)`
    line-height: 20px;
`

export const ActionButton = Simple
export const ActionFragment = styled.span``
import React from 'react'
import { ThemeProvider, useTheme } from 'styled-components'
import { Buffer, ProgressBar, Step } from './s'

const StepsIndicator = ({ steps, at = 1, salt, ...props }) => {
    let theme = useTheme()

    if(!steps) {
        return null
    }

    theme = {
        ...theme,
        steps,
        at
    }

    return (
        <ThemeProvider theme={theme}>
            <Buffer
                {...props}
                role="progressbar">
                <ProgressBar />
                {steps && [...Array(steps - 1).keys()].map(index => (
                    <Step
                        $index={index + 1}
                        key={`${salt}:progress:step:${index}`} />
                ))}
            </Buffer>
        </ThemeProvider>
    )
}

export default StepsIndicator

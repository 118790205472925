import React, { useState } from 'react'
import Hero from './hero'
import Picker from './picker'
import Actions from './actions'

const EmojiPickerContent = ({ emoji: initialEmoji, ...props }) => {
    const [emoji, setEmoji] = useState(initialEmoji ?? null)
    const [changed, setChanged] = useState(false)

    const {
        label,
        promotedEmoji = [],
        dismiss,
        doneAction,
        cancelAction,
        immediate = false,
        unsettable = false
    } = props

    const cancel = cancelAction()
    const done = doneAction({ picked: emoji })

    const onEmojiPicked = emoji => {
        setEmoji(emoji)
        setChanged(true)

        if(immediate) {
            doneAction({ picked: emoji }).onClick()
        }
    }

    return (
        <>
            <Hero
                label={label}
                emoji={emoji}
                unset={unsettable && (() => onEmojiPicked(null))}
                immediate={immediate}
                dismiss={dismiss} />
            <Picker
                emoji={emoji}
                promotedEmoji={promotedEmoji}
                pickEmoji={onEmojiPicked} />
            {!immediate && (
                <Actions
                    cancel={cancel}
                    done={done}
                    dismiss={dismiss}
                    emoji={emoji}
                    changed={changed}
                    unsettable={unsettable} />
            )}
        </>
    )
}

export default EmojiPickerContent
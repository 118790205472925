import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { usePayment } from 'contexts/payment'
import { useMe } from 'contexts/me'
import { unpackField } from 'utilities/person'
import { get } from 'api'
import { size } from 'utilities/object'
import { compact } from 'utilities/array'
import { getShortName } from 'utilities/person'
import { cls } from 'utilities/dom'
import { useAddressFormatter } from 'components/address'
import { FlexChildShrink } from 'components/flex'
import { Pills, PillCoating, Pill } from './s'
import {
    Briefcase as Organization,
    User as Me,
    MapPin as Location
} from 'styled-icons/feather'

const Shortcuts = ({ mode = 'organization', address, setAddress, salt }) => {
    const { formatMessage } = useIntl()

    const { account } = usePayment()
    const { me } = useMe()

    const organizationAddress = account?.address ?? {}
    const meAddress = unpackField(me?.address) ?? {}

    const [locations, setLocations] = useState([])

    const formatAddress = useAddressFormatter()

    useEffect(() => {
        const fetch = async () => {
            const { ok, response: locations } = await get({ path: '/locations' })

            if(ok) {
                setLocations(locations
                    .filter(({ address }) => !!Object.values(address ?? {}).join('').length)
                    .map(({ name, address }) => ({
                        label: name,
                        address
                    }))
                )
            }
        }

        fetch()
    }, [])

    const shortcuts = compact([
        (mode === 'organization' && !!size(organizationAddress)) && {
            label: account.company,
            address: organizationAddress,
            icon: <Organization size={12} />
        },
        (mode === 'me' && !!size(meAddress)) && {
            label: formatMessage({
                id: 'task_assignee_you',
                defaultMessage: '{firstName} (you)'
            }, { firstName: getShortName(me) }),
            address: meAddress,
            icon: <Me size={12} />
        },
        ...locations.map(location => ({
            ...location,
            icon: <Location size={12} />
        }))
    ])

    if(!shortcuts.length) {
        return null
    }

    return (
        <FlexChildShrink>
            <Pills>
                {shortcuts.map(({ label, icon, address: shortcutAddress }) => {
                    const formattedAddress = formatAddress({ address })
                    const formattedShortcutAddress = formatAddress({ address: shortcutAddress })
                    const active = !!formattedAddress && formattedAddress === formattedShortcutAddress

                    return (
                        <PillCoating key={`${salt}:shortcut:${label}`}>
                            <Pill
                                className={cls('neutral', active && 'active')}
                                icon={icon}
                                onClick={() => setAddress(shortcutAddress)}>
                                {label}
                            </Pill>
                        </PillCoating>
                    )
                })}
            </Pills>
        </FlexChildShrink>
    )
}

export default Shortcuts
import React, { useState, useEffect } from 'react'
import { map } from 'utilities/object'
import { first, last } from 'utilities/array'
import { read, utils } from 'xlsx'
import { Content, Heading, Flaps, FlapContent, Matrix } from './s'
import 'blob-polyfill'

const Spreadsheet = ({ raw, salt }) => {
    const [buffer, setBuffer] = useState(null)
    const [sheets, setSheets] = useState(null)

    useEffect(() => {
        const convert = async () => {
            const buffer = await raw.arrayBuffer()
            setBuffer(buffer)
        }

        convert()
    }, [raw])

    useEffect(() => {
        if(buffer) {
            const workbook = read(buffer, { type: 'buffer' })

            const sheets = map(workbook.Sheets, (Sheet, label) => {
                const rows = getRows(Sheet)

                return {
                    label,
                    content: (
                        <>
                            <Heading className="sticky">{label}</Heading>
                            <Matrix
                                rows={rows}
                                salt={salt} />
                        </>
                    )
                }
            })

            setSheets(sheets)
        }
    }, [buffer])

    return (
        <Content className="spreadsheet">
            {!!sheets?.length && (
                <Flaps
                    items={sheets}
                    contentWrapper={FlapContent}
                    salt={salt} />
            )}
        </Content>
    )
}

export default Spreadsheet

const isRowEmpty = (row, now) => {
    if(Array.isArray(row)) {
        return !row
            .map(value => {
                if(value === now) {
                    return ''
                }

                return value
            })
            .join('')
    }

    // It’s probably `undefined`, and since we don’t want the while
    // loop to run forever, we just say it’s not empty and move on
    return false
}

const isCellEmpty = (cell, now) => cell === now

const getRows = sheet => {
    const now = Date.now()

    const json = utils.sheet_to_json(sheet, {
        raw: false,
        header: 1,
        defval: now
    })

    if(!json.length) {
        return json
    }

    // Max 1 consecutive empty row. Also remove leading empty rows.
    const { rows } = json.reduceRight(({ rows, previousRowEmpty, done }, row) => {
        const empty = isRowEmpty(row, now)

        if(empty && previousRowEmpty && !done) {
            return {
                rows,
                previousRowEmpty: true,
                done: false
            }
        }

        return {
            rows: [...rows, row],
            previousRowEmpty: empty,
            done: true
        }
    }, {
        rows: [],
        previousRowEmpty: true,
        done: false
    })

    while(isRowEmpty(first(rows), now)) {
        rows.unshift()
    }

    while(isRowEmpty(last(rows), now)) {
        rows.pop()
    }

    return rows
        .reverse()
        .map(row => {
            const { cells } = row.reduceRight(({ cells, previousCellEmpty, done }, cell) => {
                const empty = isCellEmpty(cell, now)

                if(empty && previousCellEmpty && !done) {
                    return {
                        cells,
                        previousCellEmpty: true,
                        done: false
                    }
                }

                if(empty) {
                    cell = ''
                }

                return {
                    cells: [...cells, cell],
                    previousCellEmpty: empty,
                    done: true
                }
            }, {
                cells: [],
                previousCellEmpty: true,
                done: false
            })

        return cells.reverse()
    })
}
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { hashify } from '../'
import { Container, Heading } from './s'
import { NeutralOrderedList, ListItem } from 'components/typography/list'
import Link from 'components/link'

const TableOfContents = ({
    content,
    salt
}) => {
    const { formatMessage } = useIntl()

    const headings = content
        ?.filter(({ type }) => type === 'heading')
        .map(({ content, attrs }) => {
            const text = content?.map(({ text }) => text).join('') ?? formatMessage({
                id: 'toc_heading_empty',
                defaultMessage: '<Empty heading>'
            })

            const {
                level = 1,
                id = 'huma'
            } = attrs ?? {}

            return {
                text,
                level,
                hash: hashify(text, id)
            }
        })

    if(!headings?.length) {
        return null
    }

    return (
        <Container>
            <Heading className="compact">
                <FormattedMessage
                    id="noun_toc"
                    defaultMessage="Table of contents" />
            </Heading>
            <NeutralOrderedList className="compact">
                {headings.map(({ level, hash, text }) => (
                    <ListItem
                        className={`level-${level}`}
                        key={`${salt}:toc:${hash}`}>
                        <Link
                            href={`#${hash}`}
                            className="constructive">
                            {text}
                        </Link>
                    </ListItem>
                ))}
            </NeutralOrderedList>
        </Container>
    )
}

export default TableOfContents
import styled from 'styled-components'
import { svgStroke } from 'utilities/styled'
import StringFieldBase from 'components/form/field/string'
import { Grid } from 'components/grid'
import { ButtonSubmit as ButtonBase, InlineButton as InlineButtonBase } from 'components/button'

export const Footer = styled.footer`
    grid-row: footer;

    display: flex;
    flex-direction: column;
    row-gap: 24px;
`

export const Actions = styled.div`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
`

export const Field = styled.div`
    position: relative;

    width: 100%;
`

export const StringField = styled(StringFieldBase)`
    input {
        padding-inline-end: 72px;

        &:placeholder-shown {
            padding-inline-end: 40px;
        }
    }
`

export const Commit = styled(Grid)`
    position: absolute;
    isolation: isolate;
    z-index: 1;
    top: 8px;
    right: 8px;

    grid-auto-flow: column;
    grid-auto-columns: auto;
    column-gap: 8px;

    height: 24px;
`

export const KeyHint = styled(Grid)`
    place-items: center;

    width: 24px;
    height: 24px;

    color: var(--huma-color-foreground-minimal);

    svg {
        ${svgStroke()}
    }
`

export const Button = styled(ButtonBase)`
    display: grid;
    place-items: center;

    border-radius: 4px;
    width: 24px;
    height: 24px;
    padding: 0;

    svg {
        ${svgStroke()}
    }
`

export const InlineButton = styled(InlineButtonBase)`
    position: relative;
    inset-inline-start: 4px;
`
import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useJobTitles } from 'contexts/job-titles'
import { clamp } from 'utilities/math'
import { size } from 'utilities/object'
import { containerProps, CardList, SkeletonListRow, SkeletonCell, LoadingContainer } from './s'
import { Generic as FilteredEmpty, Static as Empty } from 'components/empty'
import { SkeletonStrings, SkeletonStatus } from 'components/skeleton'
import JobTitle from './job-title'
import Loader from 'components/loader'
import { Ghost } from 'components/button'

const JobTitles = ({ actions = {} }) => {
    const {
        jobTitles = [],
        intersecter,
        loading,
        paging = {},
        fetch,
        fetching,
        autoFetch,
        hasFetched,
        filter = {},
        flash,
        clearFlash,
    } = useJobTitles()

    const [skeletonLength, setSkeletonLength] = useState(5)

    useEffect(() => {
        if(hasFetched) {
            setSkeletonLength(clamp(jobTitles.length, 1, 10))
        }
    }, [hasFetched, jobTitles?.length])

    const filtersApplied = !!size(filter)
    const noJobTitles = !!hasFetched && !jobTitles.length
    const noFilteredJobTitles = !!noJobTitles && !!filtersApplied

    if(noFilteredJobTitles) {
        return <FilteredEmpty />
    }

    if(noJobTitles) {
        return (
            <Empty>
                <FormattedMessage
                    id="jobtitles_empty"
                    defaultMessage="No job titles have been added yet." />
            </Empty>
        )
    }

    return (
        <>
            <CardList {...containerProps}>
                <CardList.Header>
                    <CardList.Cell cell="name">
                        <FormattedMessage
                            id="noun_name"
                            defaultMessage="name" />
                    </CardList.Cell>
                    <CardList.Cell cell="description">
                        <FormattedMessage
                            id="noun_description"
                            defaultMessage="Description" />
                    </CardList.Cell>
                </CardList.Header>
                {(!!fetching && !hasFetched) && [...Array(skeletonLength).keys()].map(index => (
                    <SkeletonListRow
                        columns={3}
                        hasActions={true}
                        key={`list:job-titles:skeleton:${index}`}>
                        <SkeletonCell $cell="name">
                            <SkeletonStrings
                                size={20}
                                length={16} />
                        </SkeletonCell>
                        <SkeletonCell $cell="description">
                            <SkeletonStatus />
                        </SkeletonCell>
                    </SkeletonListRow>
                ))}
                {jobTitles.map((jobTitle, index) => {
                    const last = index + 1 === jobTitles.length

                    return (
                        <JobTitle
                            jobTitle={jobTitle}
                            actions={actions}
                            {...(last ? { ref: intersecter } : null)}
                            flash={flash?.current === jobTitle ? clearFlash : null}
                            key={jobTitle.id} />
                    )
                })}
            </CardList>
            {!!paging?.hasNextPage && (
                <LoadingContainer>
                    {(!loading && !autoFetch) && (
                        <Ghost
                            className="constructive"
                            onClick={fetch}>
                            <FormattedMessage
                                id="action_load_more"
                                defaultMessage="Load more…"
                            />
                        </Ghost>
                    )}
                    {!!loading && <Loader />}
                </LoadingContainer>
            )}
        </>
    )
}

export default JobTitles
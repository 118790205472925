import styled from 'styled-components'
import {
    flashKeyframes, svgStroke,
    belowContainer, fromContainer
} from 'utilities/styled'
import { SkeletonCell as SkeletonCellBase } from 'components/skeleton'

const containerQuery = p => ({ name: p.$name, breakpoint: p.$breakpoint })

export const ContainerQuery = styled.div`
    container-type: inline-size;
    container-name: ${p => p.$name};
`

export const CardListContainer = styled.div`
    ${p => fromContainer(containerQuery(p))`
        display: grid;
        column-gap: 16px;
    `}

    ${p => belowContainer(containerQuery(p))`
        display: flex;
        flex-direction: column;
    `}

    &.spacious {
        padding: 16px;
    }

    &.framed {
        border: 1px solid var(--huma-color-border-default);
        border-radius: 8px;
    }
`

export const CardListHeader = styled.div`
    display: grid;
    grid-template-columns: subgrid;
    grid-column: 1 / -1;

    padding-block: 16px;
    box-shadow: 0 1px var(--huma-color-border-default);

    line-height: 24px;
    color: var(--huma-color-foreground-subtle);

    ${p => belowContainer(containerQuery(p))`
        &:not(.always-visible) {
            display: none;
        }
    `}
`

export const CardListRow = styled.div`
    min-height: 40px;
    padding-block: 16px;

    ${p => fromContainer(containerQuery(p))`
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1 / -1;
    `}

    ${p => belowContainer(containerQuery(p))`
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        &:first-of-type {
            padding-block-start: 0;
        }

        &:last-of-type {
            padding-block-end: 0;
        }
    `}

    &:not(:last-child) {
        box-shadow: 0 1px var(--huma-color-border-default);
    }

    a& {
        &:hover,
        &:focus-visible {
            text-decoration: none;

            .hover-effect {
                text-decoration: underline;
            }
        }
    }

    &.flash {
        animation: ${flashKeyframes} 2s ease-in-out;
    }
`

export const CardListCell = styled.div`
    display: flex;

    > :last-child {
        margin-block-end: 0;
    }

    ${p => fromContainer(containerQuery(p))`
        grid-column: ${p => p.$cell};
        align-items: center;
    `}

    ${p => belowContainer(containerQuery(p))`
        flex-direction: column;

        &.action {
            display: none;
        }
    `}

    &.rows {
        flex-direction: column;
        align-items: start;
    }

    &.sortable {
        cursor: pointer;
        user-select: none;

        display: grid;
        grid-template-columns: fit-content(calc(100% - 20px)) 16px;
        column-gap: 4px;

        transition: color .15s ease-in-out;

        svg {
            opacity: 0.2;
            transition: opacity .15s ease-in-out;
        }

        &.active {
            color: var(--huma-color-foreground-default);
        }

        &:is(:hover, .active) {
            svg {
                opacity: 1;
            }
        }

        &.desc svg {
            rotate: 0.5turn;
        }

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &.action {
        svg {
            ${svgStroke()}

            transition: translate .1s ease-in-out;

            ${CardListRow}:hover &,
            ${CardListRow}:focus-visible & {
                translate: 4px;
            }
        }
    }
`

export const SkeletonCell = styled(SkeletonCellBase)`
    grid-column: ${p => p.$cell};
    justify-content: center;
`
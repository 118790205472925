import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Success, Warning, Error, Other } from './s'
import Paragraph from 'components/typography/paragraph'

export const Status = ({ status, pastDue, icon, textAs: Text, ...props }) => {
    const [Icon, translation] = getStatus(status, pastDue)

    if(!Text) {
        Text = () => <Paragraph className="compact" />
    }

    return (
        <>
            <Icon {...icon} />
            <Text {...props}>
                <FormattedMessage {...translation} />
            </Text>
        </>
    )
}

const getStatus = (status, pastDue) => {
    if(pastDue && status === 'Posted') {
        return [warning, {
            id: 'payment_subscription_invoice_status_past_due',
            defaultMessage: 'Overdue'
        }]
    }

    if(status === 'Settled') {
        return [success, {
            id: 'payment_subscription_invoice_status_paid',
            defaultMessage: 'Paid'
        }]
    }

    if(status === 'Cancelled') {
        return [error, {
            id: 'payment_subscription_invoice_status_voided',
            defaultMessage: 'Voided'

        }]
    }

    return [other, {
        id: 'payment_subscription_invoice_status_pending',
        defaultMessage: 'Pending'
    }]
}

const success = props => <Success {...props} />
const warning = props => <Warning {...props} />
const error = props => <Error {...props} />
const other = props => <Other {...props} />
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

export const linkCss = css`
    cursor: pointer;

    text-decoration: none;
    color: var(--huma-color-foreground-constructive-bold);

    &:where(:hover, :focus) {
        text-decoration: underline;
    }
`

export const AddLink = styled(Link)`
    display: inline-block;

    font-size: 20px;
    line-height: 40px;
    text-decoration: underline;
    color: var(--huma-color-foreground-default);

    &:hover {
        text-decoration: none;
    }
`
